import { Button } from "components/DesignSystem/Button/Button";
import ChatCard from "components/DesignSystem/ChatCard/ChatCard";
import { Badge } from "components/Task/Badge";
import { OPEN_ITEMS_CARD_OPENED } from "constants/analyticsEvents";
import { OPEN_ITEM_STATUS } from "dictionaries";
import { useAnalytics } from "hooks/useAnalytics";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { openCalrifyModal } from "store/slices/openItem";
import { OpenItemStatus } from "types/Models/openItem";
import OpenItemsHeader from "static/images/OpenItemsHeader.svg";
import TicketResolve from "static/images/TicketResolve.svg";

type IReviewMessageChatCardProps = {
  customData: { status: OpenItemStatus; header: string; csv_id: string };
  messageId: string;
  size?: "regular" | "block";
  closeActionItem?: () => void;
};

export const OpenItemCard: FC<IReviewMessageChatCardProps> = ({
  customData,
  messageId,
  size,
  closeActionItem,
}) => {
  const dispatch = useDispatch();
  const { status, header, csv_id } = customData;
  const { trackEvent } = useAnalytics();
  const isClarified = status === "SUBMITTED" || true;

  const openClarifyModal = () => {
    trackEvent(OPEN_ITEMS_CARD_OPENED);
    dispatch(openCalrifyModal({ csvId: csv_id, messageId: messageId, status }));
  };

  return (
    <ChatCard.Root size={size}>
      <ChatCard.Header>
        <div className="t-flex t-items-center t-gap-1.5">
          {isClarified ? (
            <img
              src={TicketResolve}
              alt="TicketResolve"
              className="t-h-4 t-w-4"
            />
          ) : (
            <img src={OpenItemsHeader} alt="OpenItemsHeader" />
          )}
          <div>{isClarified ? "Clarified" : header}</div>
        </div>
      </ChatCard.Header>
      <ChatCard.Body>
        <Button
          customType={isClarified ? "secondary" : "primary"}
          onClick={openClarifyModal}
          size="small"
        >
          {isClarified ? "View" : "Clarify"}
        </Button>
      </ChatCard.Body>
    </ChatCard.Root>
  );
};
