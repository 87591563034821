import classNames from "classnames";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Table from "components/DesignSystem/Table/V2/Table";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { DeleteIcon } from "components/icons/delete";
import { PriceInput } from "components/PriceInput/PriceInput";
import { Field, FieldProps, useFormikContext } from "formik";
import { useMemo } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { MerchantCSVResponse } from "types/Models/vendors";

const DeleteVendor = ({ vendor }: { vendor: MerchantCSVResponse }) => {
  const { values, setFieldValue } = useFormikContext<{
    merchants: MerchantCSVResponse[];
  }>();

  const deleteMerchantRow = (id: string) => {
    const newValues = values.merchants.filter(
      (merchants) => merchants.id !== id
    );
    setFieldValue("merchants", newValues);
  };

  const disableDelete = values.merchants?.length <= 1;
  return (
    <ConditionalToolTip condition={disableDelete && "One merchant is required"}>
      <div
        className={classNames("t-flex t-items-center", {
          "t-opacity-30": disableDelete,
        })}
      >
        <Button
          customType="transparent"
          onClick={() => deleteMerchantRow(vendor.id)}
          disabled={disableDelete}
        >
          <DeleteIcon color="#706A85" />
        </Button>
      </div>
    </ConditionalToolTip>
  );
};

export const AddMerchantsTable = () => {
  const { values } = useFormikContext<{
    merchants: MerchantCSVResponse[];
  }>();

  const merchants = values.merchants || [];
  const createColumn = createColumnHelper<MerchantCSVResponse>();

  const columns = useMemo(
    () => [
      createColumn.accessor("name", {
        size: 24,
        header: () => (
          <div className="after:t-content-['*'] after:t-text-red">Name</div>
        ),
        cell: (info) => {
          const rowIndex = info.row.index;
          return (
            <TextInput
              name={`merchants[${rowIndex}].name`}
              block
              placeholder="John Doe"
              required
              customSize="small"
            />
          );
        },
      }),

      createColumn.accessor("email", {
        size: 24,
        header: "Email",
        cell: (info) => {
          const rowIndex = info.row.index;
          return (
            <TextInput
              block
              placeholder="vendor@inkle.io"
              type="email"
              customSize="small"
              name={`merchants[${rowIndex}].email`}
            />
          );
        },
      }),

      createColumn.accessor("amount_paid_in_season", {
        size: 24,
        header: () => (
          <div className="t-flex after:t-content-['*'] after:t-text-red">
            Year of Payment
          </div>
        ),
        cell: (info) => {
          const rowIndex = info.row.index;
          return (
            <Field name={`merchants[${rowIndex}].amount_paid_in_season`}>
              {({ field }: FieldProps) => (
                <DateInput
                  {...field}
                  name={field.name}
                  showYearPicker
                  showYearDropdown
                  required
                  dateFormat="yyyy"
                />
              )}
            </Field>
          );
        },
      }),

      createColumn.accessor("total_amount", {
        size: 24,
        header: () => (
          <div className="t-flex t-justify-end after:t-content-['*'] after:t-text-red">
            Amount Paid
          </div>
        ),
        cell: (info) => {
          const rowIndex = info.row.index;
          return (
            <Field name={`merchants[${rowIndex}].total_amount`}>
              {({ field }: FieldProps) => (
                <div>
                  <PriceInput
                    {...field}
                    label=""
                    required
                    rightAlign
                    placeholder="$ 0.00"
                    customSize="small"
                  />
                </div>
              )}
            </Field>
          );
        },
      }),

      createColumn.display({
        size: 4,
        header: "",
        id: "ACTIONS",
        cell: (info) => {
          return <DeleteVendor vendor={info.row.original} />;
        },
      }),
    ],
    [merchants?.length]
  );

  const table = useReactTable({
    data: merchants,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
    },
  });

  return (
    <Table.Container className="t-h-full t-overflow-x-auto" size="small">
      <Table.Content className="md:t-table-fixed">
        <Table.Head className="!t-static">
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Table.HeadCell
                  key={header.id}
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.HeadCell>
              ))}
            </Table.Row>
          ))}
        </Table.Head>
        <Table.Body>
          {table.getRowModel().rows.map((row) => (
            <Table.Row key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Table.Cell
                  key={cell.id}
                  style={{ width: `${cell.column.getSize()}%` }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Content>
    </Table.Container>
  );
};
