import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { Info } from "components/icons/Info";
import SmallPencil from "components/icons/SmallPencil";
import { Form, Formik } from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import {
  useCreateOrUpdateInvoiceSettingsMutation,
  useGetInvoiceSettingsQuery,
} from "store/apis/invoices";
import { ReminderSetting } from "./ReminderSetting";
import { InvoiceSettingModal } from "components/InvoiceSettingModal/InvoiceSettingModal";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";

export const InvoiceSettings = () => {
  const { isOpen, close, open } = useModal();
  const group = useCurrentGroupContext();
  const { alertToast, successToast } = useToast();
  const paymentInfoModal = useModal();
  const entityId = useCurrentEntityId();

  const {
    data: invoiceSetting,
    isLoading: isSettingsLoading,
    isSuccess: isSettingsLoaded,
  } = useGetInvoiceSettingsQuery(
    {
      groupId: group?.uuid!,
      entityId,
    },
    {
      skip: !group?.uuid || !entityId,
    }
  );

  const selectedEntity = group.entities?.find(
    (e) => e.uuid === invoiceSetting?.entity_id
  );

  const [createInvoiceSetting, { isLoading: creatingInvoiceSetting }] =
    useCreateOrUpdateInvoiceSettingsMutation();

  const onPaymentInstrictionsSave = async (values: {
    payment_info: string;
  }) => {
    if (group?.uuid) {
      try {
        const a = await createInvoiceSetting({
          groupId: group.uuid,
          entityId: entityId,
          payload: {
            ...invoiceSetting,
            // @ts-ignore
            address: undefined,
            payment_info: values.payment_info,
          },
        }).unwrap();
        successToast({ message: "Payment instructions updated successfully" });
        paymentInfoModal.close();
      } catch (error: any) {
        alertToast({ message: error?.data?.error?.message });
      }
    }
  };

  return (
    <>
      <Async.Root
        isLoading={isSettingsLoading || !group.uuid}
        isSuccess={isSettingsLoaded}
        isEmpty={false}
      >
        <Async.Success>
          <div className="t-flex t-gap-6 t-flex-col t-w-full md:t-w-2/3 t-relative">
            {!invoiceSetting && (
              <div className="t-bg-blue-0 t-p-3 t-border-solid t-border t-border-blue-10 t-rounded-lg t-flex t-gap-2 t-items-center">
                <span className="t-text-blue t-flex">
                  <Info stroke="1.5" size="20" />
                </span>
                <p className="t-m-0 t-text-text-30 t-text-body-sm">
                  Enter your company information to get started with invoicing
                </p>
              </div>
            )}

            <div>
              <p className="t-mb-2 t-text-subtitle-sm">Company Details</p>
              <div className="t-border-solid t-border-neutral-0 t-rounded-lg t-p-5 t-border">
                <div className="t-grid t-grid-cols-2 t-gap-y-6 t-gap-x-20">
                  <InfoItem label="Entity">
                    {selectedEntity?.name || "-"}
                  </InfoItem>
                  <div className="t-flex t-gap-2 t-justify-between">
                    <InfoItem label="Email">
                      <span className="t-word-break t-hyphens-auto">
                        {invoiceSetting?.email || "-"}
                      </span>
                    </InfoItem>
                    <Button size="small" customType="ghost_icon" onClick={open}>
                      <span className="t-flex t-text-blue-70">
                        <SmallPencil color="currentColor" />
                      </span>
                    </Button>
                  </div>
                  <InfoItem label="Business Address">
                    {invoiceSetting?.address?.address_string || "-"}
                  </InfoItem>
                  <InfoItem label="Email for sending invoice">
                    {invoiceSetting?.connected_gmail || "-"}
                  </InfoItem>
                  <InfoItem label="Logo">
                    {invoiceSetting?.logo_url ? (
                      <img
                        alt="logo"
                        src={invoiceSetting?.logo_url}
                        width={54}
                        height={54}
                        className="t-object-contain"
                      />
                    ) : (
                      "-"
                    )}
                  </InfoItem>

                  <InfoItem label="Invoice Prefix">
                    {invoiceSetting?.invoice_prefix || "-"}
                  </InfoItem>
                </div>
              </div>
            </div>
            <div>
              <p className="t-mb-2 t-text-subtitle-sm">Reminders</p>
              <ReminderSetting invoiceSetting={invoiceSetting} />
            </div>
            <div>
              <p className="t-mb-2 t-text-subtitle-sm">Payment Instructions</p>
              <div className="t-border-solid t-border-neutral-0 t-rounded-lg t-p-4 t-border t-relative">
                <div className="t-flex t-items-center">
                  {invoiceSetting?.payment_info ? (
                    <div className="t-flex t-flex-col t-gap-1">
                      <span className="t-text-body-sm t-text-text-30">
                        Payment Instructions
                      </span>
                      <span className="t-whitespace-pre-wrap t-text-body t-word-break t-hyphens-auto">
                        {invoiceSetting?.payment_info}
                      </span>
                    </div>
                  ) : (
                    <span className="t-text-text-30 t-text-body">
                      No payment instructions added.
                    </span>
                  )}
                  <div className="t-opacity-0 t-invisible t-pointer-events-none">
                    <Button size="small" customType="ghost_icon">
                      <span className="t-flex t-text-blue-70">
                        <SmallPencil color="currentColor" />
                      </span>
                    </Button>
                  </div>
                </div>
                <div>
                  <div className="t-absolute t-top-4 t-right-4">
                    <Modal.Root
                      open={paymentInfoModal.isOpen}
                      onOpenChange={paymentInfoModal.toggle}
                    >
                      <Modal.Trigger asChild>
                        <Button size="small" customType="ghost_icon">
                          <span className="t-flex t-text-blue-70">
                            <SmallPencil color="currentColor" />
                          </span>
                        </Button>
                      </Modal.Trigger>
                      <Modal.Content>
                        <Formik
                          initialValues={{
                            payment_info: invoiceSetting?.payment_info || "",
                          }}
                          onSubmit={onPaymentInstrictionsSave}
                        >
                          {({ submitForm }) => (
                            <>
                              <Modal.Header>
                                <Modal.Title>Payment Instructions</Modal.Title>
                                <Modal.Close />
                              </Modal.Header>
                              <Modal.Body>
                                <p className="t-m-0 t-text-subtext-sm t-mb-4">
                                  Payment Instructions will be added in notes
                                  section for all the invoices and emails{" "}
                                </p>
                                <Form className="t-m-0">
                                  <TextArea
                                    label="Add payment instructions for all your invoices"
                                    name="payment_info"
                                    placeholder="Enter your payment instructions"
                                  />
                                </Form>
                              </Modal.Body>
                              <Modal.FooterButtonGroup>
                                <Modal.RawClose asChild>
                                  <Button>Cancel</Button>
                                </Modal.RawClose>
                                <Button
                                  onClick={submitForm}
                                  customType="primary"
                                  isLoading={creatingInvoiceSetting}
                                >
                                  Save
                                </Button>
                              </Modal.FooterButtonGroup>
                            </>
                          )}
                        </Formik>
                      </Modal.Content>
                    </Modal.Root>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Async.Success>
      </Async.Root>

      <InvoiceSettingModal isOpen={isOpen} close={close} />
    </>
  );
};
