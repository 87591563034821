import classNames from "classnames";
import { FieldBuilder } from "components/Entity/EntityFormEngine/FieldBuilder";
import { EntityDataAttribute } from "types/Models/entity";

const fullWidthFieldType = [
  "CHECKBOX",
  "MULTI_FILE_INPUT",
  "FILE",
  "ADDRESS",
  "RADIO",
  "TEXTAREA",
  "PARENT_FIELD",
];

type Form1099FieldProps = {
  field: EntityDataAttribute & { child_fields?: EntityDataAttribute[] };
  isInprogress?: boolean;
  onChange: ({
    name,
    value,
    fieldId,
  }: {
    name: string;
    value: EntityDataAttribute["value"];
    fieldId: string;
  }) => void;
  entityId: string;
};

export const Form1099Field = ({
  field,
  isInprogress,
  onChange,
  entityId,
}: Form1099FieldProps) => (
  <div
    key={field.uuid}
    className={classNames({
      "t-col-span-2": fullWidthFieldType.includes(field.type),
    })}
  >
    <FieldBuilder
      field={{
        ...field,
        disabled: isInprogress || field.disabled,
      }}
      onChange={(val) =>
        onChange({
          ...val,
          fieldId: field.uuid,
        })
      }
      key={field.uuid}
      entityIdFromProps={entityId}
    />
  </div>
);

export const SectionField = ({
  field,
  isInprogress,
  onChange,
  entityId,
}: Form1099FieldProps) => {
  if (field.type === "PARENT_FIELD") {
    return (
      <div
        key={field.uuid}
        className="t-border t-border-solid t-border-t-0 t-border-r-0 t-border-l-0 t-border-neutral-0 t-pb-6 t-space-y-5 t-col-span-2"
      >
        <div className="t-text-subtext t-text-text-100">{field.label}</div>
        <div className="t-grid t-grid-cols-2 t-gap-y-6 t-gap-x-4 t-w-full">
          {field.child_fields?.map((field: any) => {
            return (
              <Form1099Field
                key={field.uuid}
                field={field}
                isInprogress={isInprogress}
                onChange={onChange}
                entityId={entityId}
              />
            );
          })}
        </div>
      </div>
    );
  }
  return (
    <Form1099Field
      key={field.uuid}
      field={field}
      isInprogress={isInprogress}
      onChange={onChange}
      entityId={entityId}
    />
  );
};
