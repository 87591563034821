import React from "react";

export const QuestionMark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M12 6C12 7.78188 10.4706 9.25688 8.5 9.4725V10C8.5 10.1326 8.44732 10.2598 8.35355 10.3536C8.25979 10.4473 8.13261 10.5 8 10.5C7.86739 10.5 7.74021 10.4473 7.64645 10.3536C7.55268 10.2598 7.5 10.1326 7.5 10V9C7.5 8.86739 7.55268 8.74021 7.64645 8.64645C7.74021 8.55268 7.86739 8.5 8 8.5C9.65438 8.5 11 7.37875 11 6C11 4.62125 9.65438 3.5 8 3.5C6.34562 3.5 5 4.62125 5 6C5 6.13261 4.94732 6.25979 4.85355 6.35355C4.75979 6.44732 4.63261 6.5 4.5 6.5C4.36739 6.5 4.24021 6.44732 4.14645 6.35355C4.05268 6.25979 4 6.13261 4 6C4 4.07 5.79437 2.5 8 2.5C10.2056 2.5 12 4.07 12 6ZM8 12C7.80222 12 7.60888 12.0586 7.44443 12.1685C7.27998 12.2784 7.15181 12.4346 7.07612 12.6173C7.00043 12.8 6.98063 13.0011 7.01921 13.1951C7.0578 13.3891 7.15304 13.5673 7.29289 13.7071C7.43275 13.847 7.61093 13.9422 7.80491 13.9808C7.99889 14.0194 8.19996 13.9996 8.38268 13.9239C8.56541 13.8482 8.72159 13.72 8.83147 13.5556C8.94135 13.3911 9 13.1978 9 13C9 12.7348 8.89464 12.4804 8.70711 12.2929C8.51957 12.1054 8.26522 12 8 12Z"
      fill="currentColor"
    />
  </svg>
);
