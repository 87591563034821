import ConditionalToolTip from "components/design/conditionalToolTip";
import { Divider } from "components/design/Divider";
import { Button } from "components/DesignSystem/Button/Button";
import ChatCard from "components/DesignSystem/ChatCard/ChatCard";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { ESignCardHeader } from "components/icons/ESignCardHeader";
import { Preview } from "components/PreviewModal";
import { useAuth } from "hooks/useAuth";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { parse, stringify } from "qs";
import { useLazyGetSignatureUrlQuery } from "store/apis/eSign";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { BackendError } from "types/utils/error";
import TicketResolve from "static/images/TicketResolve.svg";

type EsignCardProps = {
  customData: {
    header: string;
    cta_data: null;
    metadata: {
      envelope: {
        signer_profile_ids: string[];
        signers?: {
          profile_id: string;
          name: string;
          uuid: string;
          has_signed: boolean;
        }[];
        documents: [
          {
            envelope_document_id: string;
            original_document_id: string; // to get unsigned pdf
            signed_document_id: string | null; // to get signed pdf
            file_type: string;
            name: string;
          }
        ];
        envelope_id: string; // to get docuSign signature_url
        document_count: number;
      };
    };
    card_status: "REQUESTED" | "SUBMITTED" | "PROCESSING";
    description: null;
    is_reviewed: false;
  };
  groupId: string;
  messageId: string;
  size?: "regular" | "block";
};

const Signer = ({
  name,
  has_signed,
}: {
  name: string;
  has_signed: boolean;
}) => (
  <div className="t-flex t-justify-between t-items-center">
    <span className="t-text-subtext t-text-text-60">{name}</span>
    {has_signed ? (
      <Tag tagType="green" size="small" icon={false}>
        Signed
      </Tag>
    ) : (
      <Tag tagType="yellow" size="small" icon={false}>
        Pending
      </Tag>
    )}
  </div>
);

export const EsignCard = ({
  customData,
  groupId,
  messageId,
  size,
}: EsignCardProps) => {
  const { alertToast } = useToast();
  const { uuid } = useAuth();
  const [getPreviewUrl, { isLoading }] = useLazyGetPreviewUrlQuery();
  const [getSignatureUrl, { isLoading: gettingurl }] =
    useLazyGetSignatureUrlQuery();

  const {
    close: closePreviewModal,
    isOpen: isPreviewModalOpen,
    open: openPreviewModal,
  } = useModal();

  const {
    header,
    card_status,
    metadata: {
      envelope: { signer_profile_ids = [], documents, envelope_id, signers },
    },
  } = customData;

  const isSignNotAllowed = signers
    ? !signers.some(({ profile_id }) => profile_id === uuid)
    : !signer_profile_ids?.includes(uuid);

  const isSignedByCurrentUser = signers?.some(
    ({ has_signed, profile_id }) => profile_id === uuid && has_signed
  );

  const { original_document_id, signed_document_id, file_type, name } =
    documents[0];

  const fileId = signed_document_id || original_document_id;

  const onDownload = async () => {
    try {
      const { download_url } = await getPreviewUrl({
        groupId,
        fileId,
      }).unwrap();
      window.open(download_url);
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const getUrl = async ({ envelope_id }: { envelope_id: string }) => {
    try {
      const callBackUrl = new URL(window.location.href);
      callBackUrl.search = stringify({
        ...parse(callBackUrl.search),
        messageId,
      });

      const { signature_url } = await getSignatureUrl({
        groupId,
        envelopeId: envelope_id,
        callbackUrl: callBackUrl.toString(),
      }).unwrap();
      window.open(signature_url, "_self");
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const onPreview = () => {
    if (card_status === "PROCESSING") {
      getUrl({ envelope_id });
    } else {
      openPreviewModal();
    }
  };

  if (signers) {
    const isAllSigned = signers.every((signer) => signer.has_signed);
    return (
      <>
        <ChatCard.Root size={size}>
          <ChatCard.Header>
            <div className="t-flex t-items-center t-gap-1.5">
              {isAllSigned ? (
                <img
                  src={TicketResolve}
                  alt="TinySuccess"
                  className="t-h-4 t-w-4"
                />
              ) : (
                <ESignCardHeader />
              )}
              <div>
                {isAllSigned ? "Signature Done" : "Signature Requested"}
              </div>
            </div>
          </ChatCard.Header>
          <ChatCard.Body>
            <ChatCard.File
              isLoading={isLoading}
              fileType={file_type}
              onDownload={onDownload}
              onFileClick={openPreviewModal}
            >
              <div className="t-max-w-[100px] t-truncate">{name}</div>
            </ChatCard.File>
            <div className="t-border t-border-dashed t-border-neutral-10 t-border-b-0 t-border-l-0 t-border-r-0" />
            <>
              <span className="t-text-subtitle-sm t-text-text-30">Signers</span>
              <div className="t-flex t-flex-col">
                {signers.map(({ name, uuid, has_signed }) => (
                  <div
                    className="t-border t-border-dashed t-border-neutral-10 first:t-border-t-0 t-py-3 t-border-b-0 t-border-l-0 t-border-r-0 first:t-pt-0"
                    key={uuid}
                  >
                    <Signer key={uuid} has_signed={has_signed} name={name} />
                  </div>
                ))}
              </div>
            </>
            {isSignedByCurrentUser ? (
              <Button
                size="small"
                onClick={onPreview}
                disabled={gettingurl}
                isLoading={gettingurl}
                block
              >
                Preview
              </Button>
            ) : (
              <>
                {card_status === "REQUESTED" ? (
                  <ConditionalToolTip
                    condition={
                      isSignNotAllowed && "Only assginee can sign the document."
                    }
                  >
                    <span>
                      <Button
                        customType="primary"
                        size="small"
                        onClick={() => getUrl({ envelope_id })}
                        disabled={isSignNotAllowed || gettingurl}
                        isLoading={gettingurl}
                        block
                      >
                        Sign now
                      </Button>
                    </span>
                  </ConditionalToolTip>
                ) : (
                  <Button
                    onClick={onPreview}
                    disabled={gettingurl}
                    isLoading={gettingurl}
                    block
                    size="small"
                  >
                    Preview
                  </Button>
                )}
              </>
            )}
          </ChatCard.Body>
        </ChatCard.Root>
        {isPreviewModalOpen && (
          <Preview
            showModal={isPreviewModalOpen}
            closeModal={closePreviewModal}
            groupId={groupId}
            previewId={fileId}
          />
        )}
      </>
    );
  }

  return (
    <>
      <ChatCard.Root size={size}>
        <ChatCard.Header>
          <div className="t-flex t-items-center t-gap-1.5">
            <ESignCardHeader />
            <div>{header}</div>
          </div>
        </ChatCard.Header>
        <ChatCard.Body>
          <ChatCard.File
            isLoading={isLoading}
            fileType={file_type}
            onDownload={onDownload}
            onFileClick={openPreviewModal}
          >
            <div className="t-max-w-[100px] t-truncate">{name}</div>
          </ChatCard.File>
          {card_status === "REQUESTED" ? (
            <ConditionalToolTip
              condition={
                isSignNotAllowed && "Only assginee can sign the document."
              }
            >
              <span>
                <Button
                  customType="primary"
                  onClick={() => getUrl({ envelope_id })}
                  disabled={isSignNotAllowed || gettingurl}
                  isLoading={gettingurl}
                  block
                  size="small"
                >
                  Sign now
                </Button>
              </span>
            </ConditionalToolTip>
          ) : (
            <Button
              size="small"
              onClick={onPreview}
              disabled={gettingurl}
              isLoading={gettingurl}
              block
            >
              Preview
            </Button>
          )}
        </ChatCard.Body>
      </ChatCard.Root>
      {isPreviewModalOpen && (
        <Preview
          showModal={isPreviewModalOpen}
          closeModal={closePreviewModal}
          groupId={groupId}
          previewId={fileId}
        />
      )}
    </>
  );
};
