// PDF
export const PDF = "PDF";

// IMAGES
export const IMAGE = "IMAGE";
export const SVG = "SVG";
export const JPG = "JPG";
export const PNG = "PNG";
export const JPEG = "JPEG";
export const GIF = "GIF";

// CSV
export const CSV = "CSV";

// DOC
export const DOC = "DOC";
export const DOCX = "DOCX";
export const TXT = "TXT";
export const PPT = "PPT";
export const PPTX = "PPTX";

// EXCEL
export const EXCEL = "EXCEL";
export const XLSX = "XLSX";
export const XLSM = "XLSM";
export const XLSB = "XLSB";
export const XML = "XML";
export const XLR = "XLR";
export const XLA = "XLA";
export const XLS = "XLS";
export const XLSX1 =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export const XLS1 = "application/vnd.ms-excel";

// FOLDER
export const FOLDER = "FOLDER";
export const LOCKED_FOLDER = "LOCKED_FOLDER";

// MULTI_FILES
export const MULTI_FILES = "MULTI_FILES";
