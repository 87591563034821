import classNames from "classnames";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import ConditionalToolTip from "components/design/conditionalToolTip";
import Loader from "components/design/loader";
import ToolTip from "components/design/toolTip";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import Table from "components/DesignSystem/Table/V2/Table";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { FileInput, FileType } from "components/FileInput/FileInput";
import { ApplyToAllIcon } from "components/icons/ApplyToAllIcon";
import { BankLogo } from "components/icons/BankLogo";
import { Preview } from "components/PreviewModal";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { TRANSACTION_SOURCE } from "dictionaries";
import { useFormikContext } from "formik";
import { Form, Formik } from "formik";
import { clarificationInfoValidation } from "formValidations/requestInfoValidation";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import qs from "qs";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { actionItemsApi } from "store/apis/actionItems";
import {
  useAddClarificationMutation,
  useDeleteOpenItemInvoiceMutation,
  useGetOpenItemsQuery,
  useSubmitClarificationMutation,
  useUploadOpenItemInvoiceMutation,
} from "store/apis/openItem";
import {
  openFloatingChat,
  setMessageIdForTransactionList,
  setPopulateTransactionIDs,
  setToOpenChatId,
} from "store/slices/chat";
import { closeAddRequestInfoModal } from "store/slices/openItem";
import { resetTxnFilters } from "store/slices/transactionFilter";
import { RootState } from "store/store";
import { FileObject } from "types/Models/fileObject";
import { OpenItem } from "types/Models/openItem";
import { BackendError } from "types/utils/error";

type FormValues = {
  clarifications: Record<string, string>;
};

const AddInformation = (info: CellContext<OpenItem, string>) => {
  const { clarification } = info.row.original;

  const { csvId, groupId } = useSelector(
    (state: RootState) => state.openItem.addRequestInfoModal
  );

  const { data: requestedTxn } = useGetOpenItemsQuery(
    { csvId, groupId },
    { skip: !groupId || !csvId, refetchOnMountOrArgChange: true }
  );

  const { status } = requestedTxn || {};

  if (status === "SUBMITTED") {
    return <div className="t-text-subtext t-text-text-60">{clarification}</div>;
  }

  return (
    <div className="t-flex t-items-center t-gap-1.5">
      <TextArea
        name={`clarifications.${info.row.original.uuid}]`}
        cols={200}
        rows={1}
        id={info.row.original.uuid}
        onFocus={() =>
          // @ts-ignore
          info.table.options.meta?.setFocusedItem(info.row.original.uuid)
        }
        placeholder="Enter information..."
      />
    </div>
  );
};

const Invoice = (info: CellContext<OpenItem, FileObject>) => {
  const { invoice, uuid } = info.row.original;
  const { successToast, alertToast } = useToast();
  const previewModal = useModal();

  const [uploadOpenItemInvoice, { isLoading: isUploading }] =
    useUploadOpenItemInvoiceMutation();

  const [deleteOpenItemInvoice, { isLoading: isDeleting }] =
    useDeleteOpenItemInvoiceMutation();

  const { csvId, groupId } = useSelector(
    (state: RootState) => state.openItem.addRequestInfoModal
  );

  const { data: requestedTxn } = useGetOpenItemsQuery(
    { csvId, groupId },
    { skip: !groupId || !csvId, refetchOnMountOrArgChange: true }
  );

  const { status } = requestedTxn || {};

  const uploadInvoice = async ({
    openItemId,
    file,
  }: {
    openItemId: string;
    file: FileType;
  }) => {
    try {
      await uploadOpenItemInvoice({
        groupId,
        openItemId,
        file: file as File,
      }).unwrap();
      successToast({ message: "File Uploaded" });
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const onInvoiceDelete = async ({ openItemId }: { openItemId: string }) => {
    try {
      await deleteOpenItemInvoice({ openItemId, groupId }).unwrap();
      successToast({ message: "File Deleted" });
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  if (status === "SUBMITTED" && !Boolean(invoice)) {
    return "-";
  }

  return (
    <>
      <FileInput
        file={invoice}
        onDrop={(files) => uploadInvoice({ openItemId: uuid, file: files[0] })}
        isUploading={isUploading}
        variant="icon"
        onFileClick={previewModal.open}
        isDeleting={isDeleting}
        onDelete={
          status === "REQUESTED"
            ? (e) => onInvoiceDelete({ openItemId: uuid })
            : undefined
        }
      />
      <Preview
        closeModal={previewModal.close}
        showModal={previewModal.isOpen}
        previewId={invoice?.uuid}
        groupId={groupId}
      />
    </>
  );
};

const ApplyToAll = ({
  info,
}: {
  info: CellContext<OpenItem, string | undefined>;
}) => {
  const { setValues, values } = useFormikContext<FormValues>();

  const copyTextToAll = () => {
    setValues({
      clarifications: Object.fromEntries(
        Object.keys(values.clarifications).map((k) => [
          k,
          values.clarifications[info.row.original.uuid],
        ])
      ),
    });
  };

  return (
    <div className="t-flex t-items">
      <ToolTip text="Apply to all">
        <Button
          type="button"
          customType="ghost_icon"
          size="small"
          onClick={copyTextToAll}
        >
          <span className="t-text-text-30">
            <ApplyToAllIcon />
          </span>
        </Button>
      </ToolTip>
    </div>
  );
};

export const AddRequestInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { alertToast, successToast } = useToast();
  const [focusedItem, setFocusedItem] = useState<string | null>(null);
  const [submitClarification] = useSubmitClarificationMutation();
  const [addRequestInfo] = useAddClarificationMutation();
  const { isCustomer } = useRoleBasedView();

  const { csvId, groupId, messageId, channelId } = useSelector(
    (state: RootState) => state.openItem.addRequestInfoModal
  );

  const { data: requestedTxn, isLoading } = useGetOpenItemsQuery(
    { csvId, groupId },
    { skip: !groupId || !csvId, refetchOnMountOrArgChange: true }
  );

  const { open_items = [], status, entity_id } = requestedTxn || {};

  const columnHelper = createColumnHelper<OpenItem>();

  const onClose = () => {
    dispatch(closeAddRequestInfoModal());
    setTimeout(() => {
      dispatch(
        actionItemsApi.util.invalidateTags([{ type: "ALL_ACTION_ITEMS" }])
      );
    }, 1000);
  };

  const applyToAllColumn =
    status === "REQUESTED"
      ? [
          columnHelper.accessor("clarification", {
            id: "Applytoall",
            header: "",
            cell: (info) =>
              // @ts-ignore
              info.table.options.meta?.focusedItem ===
                info.row.original.uuid && <ApplyToAll info={info} />,
            size: 10,
          }),
        ]
      : [];

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: "Date",
        cell: (info) => {
          const { date } = info.getValue();
          return (
            <div className="t-text-subtext t-text-text-30">
              {dayjs(date).format(DD_MMM_YYYY)}
            </div>
          );
        },
        header: "Date",
        size: 12,
      }),
      columnHelper.accessor("from", {
        id: "source",
        cell: (info) => {
          const from = info.getValue();

          const { bank_account } = from || {};
          const { bank_brand } = bank_account || {};
          const { logo_url } = bank_brand || {};

          const mask = bank_account.mask ? `•••• ${bank_account.mask}` : "";

          return (
            <ConditionalToolTip
              condition={
                from &&
                `${from?.bank_account?.nickname} ${mask} (${
                  TRANSACTION_SOURCE[from?.source!]
                })`
              }
            >
              <span className="t-flex t-justify-center">
                {logo_url ? (
                  <Avatar
                    src={logo_url}
                    alt={from?.bank_account?.mask || "Bank"}
                  />
                ) : (
                  <BankLogo />
                )}
              </span>
            </ConditionalToolTip>
          );
        },
        header: "Source",
        size: 7,
      }),
      columnHelper.accessor("description", {
        id: "description",
        cell: (info) => {
          const description = info.getValue();
          if (!Boolean(description)) {
            return "-";
          }

          return (
            <div className="t-flex t-gap-2 t-items-center">
              <div className="t-text-subtext t-text-text-60">{description}</div>
            </div>
          );
        },
        header: "Description",
        size: 20,
      }),
      columnHelper.accessor((row) => row, {
        id: "Amount",
        cell: (info) => {
          const { amount } = info.getValue();
          return (
            <div className="t-flex t-justify-end t-mr-10 t-text-subtext t-text-text-60">
              <AmountSuperScript amount={Number(amount)!} />
            </div>
          );
        },
        header: () => (
          <div className="t-flex t-justify-end t-mr-10">Amount</div>
        ),
        size: 10,
      }),
      columnHelper.accessor("invoice", {
        id: "Invoice",
        cell: Invoice,
        header: "Invoice",
        size: 7,
      }),
      columnHelper.accessor((row) => row, {
        id: "Information Requested",
        cell: (info) => {
          const { information_request } = info.getValue();
          return (
            <div className="t-text-subtext t-text-text-60">
              {information_request}
            </div>
          );
        },
        header: "Information Requested",
        size: 22,
      }),
      columnHelper.accessor("clarification", {
        id: "Add Information",
        cell: AddInformation,
        header: () => (
          <div
            className={classNames({
              "after:t-font-bold after:t-text-red after:t-content-['_*']":
                status === "REQUESTED",
            })}
          >
            Add Information
          </div>
        ),
        size: 22,
      }),
      ...applyToAllColumn,
    ],
    [open_items, status]
  );

  const table = useReactTable({
    data: open_items || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 5,
      maxSize: 100,
    },
    meta: {
      setFocusedItem,
      focusedItem,
    },
  });

  const openItemsTxnIds = open_items
    .map(({ transaction_id }) => transaction_id)
    .join(",");

  const onSubmit = async (values: FormValues) => {
    try {
      const entries = Object.entries(values.clarifications);
      await Promise.allSettled(
        entries.map(
          async ([uuid, clarification]) =>
            await addRequestInfo({
              groupId,
              openItemId: uuid,
              clarification,
            }).unwrap()
        )
      );
      await submitClarification({ groupId, messageId, csvId }).unwrap();
      dispatch(setToOpenChatId(channelId));
      dispatch(openFloatingChat());
      successToast({ message: "Submitted" });
      onClose();
    } catch (error) {
      alertToast(
        { message: (error as BackendError).data?.error?.message },
        error as Error
      );
    }
  };

  const viewTransactions = () => {
    dispatch(resetTxnFilters());

    let queryParams = qs.stringify(
      {
        company: isCustomer ? null : groupId,
        entity: entity_id,
      },
      { skipNulls: true, addQueryPrefix: true }
    );

    history.push(`/books/transactions${queryParams}`);
    dispatch(setPopulateTransactionIDs(openItemsTxnIds));
    dispatch(setMessageIdForTransactionList(""));
    onClose();
  };

  return (
    <Formik
      initialValues={{
        clarifications: Object.fromEntries(
          open_items.map((i) => [i.uuid, i.clarification || ""])
        ),
      }}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnMount
      validationSchema={clarificationInfoValidation}
    >
      {({ isValid, submitForm, isSubmitting }) => (
        <Modal.Root open={Boolean(csvId)} onOpenChange={onClose}>
          <Modal.Content size="xxl" asChild>
            <Form>
              <>
                <Modal.Header>
                  <Modal.Title>
                    {status === "REQUESTED" ? "Add" : "View"} Info
                  </Modal.Title>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body>
                  {isLoading && open_items.length === 0 && <Loader />}
                  {!isLoading && open_items.length > 0 && (
                    <Table.Container>
                      <Table.Content>
                        <Table.Head>
                          {table.getHeaderGroups().map((headerGroup) => (
                            <Table.Row key={headerGroup.id}>
                              {headerGroup.headers.map((header) => (
                                <Table.HeadCell
                                  key={header.id}
                                  style={{
                                    width: `${header.getSize()}%`,
                                  }}
                                >
                                  {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                </Table.HeadCell>
                              ))}
                            </Table.Row>
                          ))}
                        </Table.Head>
                        <Table.Body>
                          {table.getRowModel().rows.map((row) => (
                            <Table.Row key={row.id}>
                              {row.getVisibleCells().map((cell) => (
                                <Table.Cell
                                  key={cell.id}
                                  style={{
                                    width: `${cell.column.getSize()}%`,
                                  }}
                                >
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </Table.Cell>
                              ))}
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table.Content>
                    </Table.Container>
                  )}
                </Modal.Body>
                <Modal.Footer className="t-flex t-justify-end t-gap-3 t-items-center">
                  <Button
                    customType="text"
                    type="button"
                    onClick={viewTransactions}
                  >
                    View in Transactions
                  </Button>
                  {status === "REQUESTED" && (
                    <Button
                      customType="primary"
                      disabled={!isValid || isSubmitting}
                      onClick={submitForm}
                      isLoading={isSubmitting}
                    >
                      Submit
                    </Button>
                  )}
                </Modal.Footer>
              </>
            </Form>
          </Modal.Content>
        </Modal.Root>
      )}
    </Formik>
  );
};
