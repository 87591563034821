import { Cards } from "components/MessageBubble/Cards";
import { UnstyledChannel } from "components/UnstyledChannel/UnstyledChannel";
import { useMemo } from "react";
import { Channel, useChatContext } from "stream-chat-react";

type CustomCardProps = {
  type: string;
  customData: any;
  messageId: string;
  channelId: string;
};

export const ActionItemCard = (props: CustomCardProps) => {
  const { client } = useChatContext();

  const channel = useMemo(() => {
    return client.channel("messaging", props.channelId);
  }, [props.channelId]);

  return (
    <UnstyledChannel channel={channel}>
      <Cards {...props} size="block" />
    </UnstyledChannel>
  );
};
