/* eslint-disable no-unused-vars */
import { useMemo, useState } from "react";
import "react-table";

//style
import "../../static/styles/components/addTeamMember.css";
import "../../static/styles/components/salesCrm.css";

//components
import TagCreation from "../tagCreation/tagCreation";

//api
import { useGetAllPartnersQuery } from "store/apis/perksCrm";

//constants
import { usePageTitle } from "hooks/usePageTitle";
import { Header } from "components/DesignSystem/Header/Header";
import Table from "components/DesignSystem/Table/Table";
import { useTable } from "react-table";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { useRoleBasedView } from "hooks/useRoleBasedView";

export default function PerksCrm() {
  const { isAdmin } = useRoleBasedView();
  usePageTitle("Perks CRM");
  const [showTagModal, setShowTagModal] = useState(false);
  const [searchPageNumber, setSearchPageNumber] = useState(1);

  const { data: perks } = useGetAllPartnersQuery(
    {
      pageNumber: searchPageNumber,
    },
    { skip: !isAdmin }
  );

  const {
    perks: partners = [],
    total_pages = 1,
    total_count = 1,
    current_page = 1,
    per_page = 25,
  } = perks || {};
  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  const goToFirstPage = () => {
    setSearchPageNumber(1);
  };

  const goToPrevPage = () => {
    const localCurrentPage =
      searchPageNumber < total_pages ? searchPageNumber : total_pages;
    setSearchPageNumber(localCurrentPage - 1);
  };

  const goToNextPage = () => {
    if (searchPageNumber < total_pages) {
      setSearchPageNumber(searchPageNumber + 1);
    }
  };
  const goToLastPage = () => {
    setSearchPageNumber(total_pages);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Partner Name",
        accessor: "partner_name",
        width: "15%",
        textAlign: "left",
        Cell: ({ value }) => <>{value}</>,
      },
      {
        Header: "Details",
        accessor: "details",
        width: "20%",
        textAlign: "left",
        Cell: ({ value }) => <>{value}</>,
      },
      {
        Header: "Partner Link",
        accessor: "perk_link",
        width: "15%",
        textAlign: "left",
        Cell: ({ value }) => <>{value}</>,
      },
      {
        Header: "SPOCs",
        accessor: "spoc",
        width: "15%",
        textAlign: "left",
        Cell: ({ value }) => <>{value}</>,
      },
      {
        Header: "Coupon Code",
        accessor: "coupon",
        width: "10%",
        textAlign: "left",
        Cell: ({ row }) => <>{row.original?.coupon?.coupon_code || "-"}</>,
      },
      {
        Header: "Last Note",
        accessor: "notes",
        width: "25%",
        textAlign: "left",
        Cell: ({ row }) => <>{row.original?.notes?.description || "-"}</>,
      },
    ],
    []
  );

  const data = useMemo(() => partners, [partners]);

  const handleClose = () => {
    setShowTagModal(false);
  };
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      // @ts-ignore
      columns,
      data,
    });

  return (
    <>
      <div className="t-w-full">
        <Header title="Partners"></Header>
        <div className="t-p-5">
          <div className="t-flex t-items-center t-justify-end t-text-subtitle t-font-bold t-text-text-100">
            <Pagination
              {...paginationData}
              goToFirstPage={goToFirstPage}
              goToPrevPage={goToPrevPage}
              goToNextPage={goToNextPage}
              goToLastPage={goToLastPage}
            />
          </div>
          <Table.Container>
            <Table.Content
              {...getTableProps()}
              className="t-w-full t-overflow-auto"
            >
              <Table.Head className="t-inline-table t-w-full !t-rounded-t-none t-bg-neutral-10">
                {headerGroups.map((headerGroup) => (
                  <Table.Row
                    key={headerGroup.id}
                    {...headerGroup.getHeaderGroupProps()}
                    className="t-flex t-items-center t-justify-center t-gap-6 t-px-4 t-py-3"
                  >
                    {headerGroup.headers.map((column) => (
                      <th
                        className="t-text-start"
                        key={column.id}
                        {...column.getHeaderProps({})}
                        style={{ width: column?.width }}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </Table.Row>
                ))}
              </Table.Head>
              <Table.BodyWrapper>
                <Table.Body
                  {...getTableBodyProps()}
                  className="all:unset t-inline-table t-w-full"
                >
                  {rows.map((row) => {
                    prepareRow(row);

                    const {
                      original: { uuid },
                    } = row;
                    return (
                      <Table.Row
                        className="t-flex t-cursor-pointer t-items-center t-justify-center t-gap-6 t-border t-border-b-0 
                      t-border-t-0 t-border-solid t-border-i-neutral-10 t-border-l-surface-transparent t-px-4 t-py-2 hover:t-bg-purple-0"
                        key={row.id}
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td
                              key={cell.value}
                              {...cell.getCellProps({})}
                              style={{ width: cell.column?.width }}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table.BodyWrapper>
            </Table.Content>
          </Table.Container>
          <div className="t-flex t-items-center t-justify-end t-text-subtitle t-font-bold t-text-text-100">
            <Pagination
              {...paginationData}
              goToFirstPage={goToFirstPage}
              goToPrevPage={goToPrevPage}
              goToNextPage={goToNextPage}
              goToLastPage={goToLastPage}
            />
          </div>
        </div>
        <TagCreation show={showTagModal} handleClose={handleClose} />
      </div>
    </>
  );
}
