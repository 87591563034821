import dayjs from "dayjs";
import * as DATE_PERIOD from "constants/dateFilter";

export const ZOHO_BOOKS = "Zoho Books";
export const QUICK_BOOKS = "QuickBooks";

export const ACCOUNTING_METHODS = {
  ACCRUAL: "ACCRUAL",
  CASH: "CASH",
} as const;

export const SAMPLE_CSV =
  "https://inkle-django-files-prod.s3.ap-south-1.amazonaws.com/Sample+Open+Item+File.xlsx";

// COA
export const ASSET = 1;
export const LIABILITY = 2;
export const EQUITY = 3;
export const REVENUE = 4;
export const EXPENSE = 5;

// REPORTS
export const BALANCE_SHEET = "BALANCE_SHEET";
export const INCOME_STATEMENT = "INCOME_STATEMENT";
export const CASH_FLOW_STATEMENT = "CASH_FLOW";
export const SAVED_REPORTS = "SAVED_REPORTS";
export const GENERAL_LEDGER = "GENERAL_LEDGER";
export const TRIAL_BALANCE = "TRIAL_BALANCE";
export const DOWNLOAD_PDF = "DOWNLOAD_PDF";
export const DOWNLOAD_EXCEL = "DOWNLOAD_EXCEL";

// REPORTS_STATE
export const ACTIVE = "ACTIVE";
export const INACTIVE = "INACTIVE";

export const INKLE_DEMO = "https://calendly.com/inklehq/inkle-demo";

// DASHBOARD
export const CONNECTIONS = "Connections";
export const RECONCILIATION = "Reconciliation";
export const PENDING_REVIEW_TRANSACTIONS = "Pending Review Transactions";
export const CHAT_REQUESTS = "Chat Requests";
export const INFO_REQUESTED = "Info Requested";
export const DOCUMENT_REQUESTED = "Document Requested";
export const DOCUMENTS_REQUESTED = "Documents Requested";

//COA
export const STANDARD = "STANDARD";

// Date Filter
export const datePeriod = [
  { label: "Last 30 days", value: DATE_PERIOD.LAST_30_DAYS },
  { label: "Last 90 days", value: DATE_PERIOD.LAST_90_DAYS },
  { label: "Current month", value: DATE_PERIOD.CURRENT_MONTH },
  { label: "Last month", value: DATE_PERIOD.LAST_MONTH },
  { label: "Last 3 months", value: DATE_PERIOD.LAST_3_MONTHS },
  {
    label: `Current year - ${dayjs().get("year")}`,
    value: DATE_PERIOD.CURRENT_YEAR,
  },
  {
    label: `Last year - ${dayjs().subtract(1, "year").get("year")}`,
    value: DATE_PERIOD.LAST_YEAR,
  },
];

export const monthPeriod = [
  { label: "Current month", value: DATE_PERIOD.CURRENT_MONTH },
  { label: "Last month", value: DATE_PERIOD.LAST_MONTH },
  { label: "Last 3 months", value: DATE_PERIOD.LAST_3_MONTHS },
  { label: "Last 6 months", value: DATE_PERIOD.LAST_6_MONTHS },
  { label: "Last 12 months", value: DATE_PERIOD.LAST_12_MONTHS },
];

// Bookkeeping Tracker
export const IN_PROGRESS = "IN_PROGRESS";
export const COMPLETED = "COMPLETED";

export const SERVICE_TEAM_LABEL: { [key: string]: string } = {
  INKLE_TEAM: "Inkle",
  CPA_TEAM: "Tranquility",
};
