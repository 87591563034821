import React from "react";

export const RAndDIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clip-path="url(#clip0_5020_8164)">
      <path
        d="M12.75 7C13.7165 7 14.5 6.2165 14.5 5.25C14.5 4.2835 13.7165 3.5 12.75 3.5C11.7835 3.5 11 4.2835 11 5.25C11 6.2165 11.7835 7 12.75 7Z"
        stroke="currentColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 13H1C0.867392 13 0.740215 12.9473 0.646447 12.8536C0.552678 12.7598 0.5 12.6326 0.5 12.5V10C0.5 9.86739 0.552678 9.74021 0.646447 9.64645C0.740215 9.55268 0.867392 9.5 1 9.5H3"
        stroke="currentColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 10H9L13.1875 9.03688C13.3415 8.99469 13.5031 8.98857 13.6598 9.019C13.8165 9.04943 13.964 9.11559 14.091 9.21233C14.218 9.30907 14.321 9.4338 14.3919 9.5768C14.4628 9.71981 14.4998 9.87725 14.5 10.0369C14.5001 10.2296 14.4464 10.4186 14.3451 10.5825C14.2437 10.7465 14.0987 10.8789 13.9263 10.965L11.5 12L7.5 13H3V9.5L4.5625 7.9375C4.70216 7.79833 4.86788 7.68805 5.0502 7.61298C5.23251 7.53791 5.42784 7.49952 5.625 7.5H8.75C9.08152 7.5 9.39946 7.6317 9.63388 7.86612C9.8683 8.10054 10 8.41848 10 8.75C10 9.08153 9.8683 9.39947 9.63388 9.63389C9.39946 9.86831 9.08152 10 8.75 10H7Z"
        stroke="currentColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.0002 5.33053C10.6605 5.49116 10.2782 5.53822 9.90972 5.46475C9.54126 5.39127 9.20622 5.20116 8.95417 4.92253C8.70212 4.6439 8.54643 4.29155 8.51014 3.91759C8.47384 3.54362 8.55887 3.16791 8.75264 2.84601C8.94641 2.52411 9.23863 2.27313 9.58609 2.13017C9.93354 1.98721 10.3178 1.95987 10.682 2.05219C11.0462 2.14452 11.371 2.35161 11.6084 2.64283C11.8458 2.93405 11.9831 3.29394 12.0002 3.66928"
        stroke="currentColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5020_8164">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
