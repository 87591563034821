export const EmptyReminders = () => {
  return (
    <svg
      width="221"
      height="162"
      viewBox="0 0 221 162"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.31">
        <path
          d="M26.8093 108.425L0.452477 107.947C0.452477 107.947 -5.47387 143.245 25.569 161.466L39.5233 141.469L26.8093 108.425Z"
          fill="#B8B5C2"
        />
        <path
          d="M98.8565 23.3882L26.8089 108.425C18.8582 128.399 39.5229 141.469 39.5229 141.469L146.371 130.066L98.8565 23.3882Z"
          fill="#A09CAD"
        />
        <path
          d="M98.8565 23.3882C98.8565 23.3882 151.942 66.4329 146.371 130.066C146.371 130.066 97.1508 90.0736 98.8565 23.3882Z"
          fill="#888399"
        />
        <g clip-path="url(#clip0_3062_18517)">
          <circle cx="45.7129" cy="66.2977" r="15.2142" fill="#D9D9D9" />
          <path
            d="M45.548 41.8081C40.8206 41.8081 36.1993 43.21 32.2686 45.8364C28.3379 48.4628 25.2743 52.1958 23.4652 56.5634C21.656 60.931 21.1827 65.7369 22.105 70.3735C23.0273 75.0101 25.3037 79.2691 28.6465 82.6119C31.9893 85.9547 36.2483 88.2312 40.8849 89.1535C45.5215 90.0757 50.3275 89.6024 54.695 87.7933C59.0626 85.9842 62.7957 82.9206 65.4221 78.9898C68.0485 75.0591 69.4503 70.4379 69.4503 65.7104C69.4436 59.3732 66.9232 53.2974 62.4421 48.8163C57.961 44.3352 51.8853 41.8148 45.548 41.8081ZM54.2034 71.7641C54.3742 71.935 54.5098 72.1378 54.6022 72.361C54.6947 72.5842 54.7422 72.8234 54.7422 73.065C54.7422 73.3066 54.6947 73.5458 54.6022 73.769C54.5098 73.9922 54.3742 74.195 54.2034 74.3658C54.0326 74.5367 53.8298 74.6722 53.6066 74.7646C53.3834 74.8571 53.1442 74.9046 52.9026 74.9046C52.661 74.9046 52.4218 74.8571 52.1986 74.7646C51.9754 74.6722 51.7726 74.5367 51.6017 74.3658L45.548 68.3098L39.4943 74.3658C39.3235 74.5367 39.1207 74.6722 38.8975 74.7646C38.6743 74.8571 38.435 74.9046 38.1935 74.9046C37.9519 74.9046 37.7127 74.8571 37.4895 74.7646C37.2663 74.6722 37.0634 74.5367 36.8926 74.3658C36.7218 74.195 36.5863 73.9922 36.4938 73.769C36.4014 73.5458 36.3538 73.3066 36.3538 73.065C36.3538 72.8234 36.4014 72.5842 36.4938 72.361C36.5863 72.1378 36.7218 71.935 36.8926 71.7641L42.9486 65.7104L36.8926 59.6567C36.5476 59.3117 36.3538 58.8438 36.3538 58.3559C36.3538 57.868 36.5476 57.4 36.8926 57.055C37.2376 56.71 37.7056 56.5162 38.1935 56.5162C38.6814 56.5162 39.1493 56.71 39.4943 57.055L45.548 63.111L51.6017 57.055C51.7726 56.8842 51.9754 56.7487 52.1986 56.6562C52.4218 56.5638 52.661 56.5162 52.9026 56.5162C53.1442 56.5162 53.3834 56.5638 53.6066 56.6562C53.8298 56.7487 54.0326 56.8842 54.2034 57.055C54.3742 57.2259 54.5098 57.4287 54.6022 57.6519C54.6947 57.8751 54.7422 58.1143 54.7422 58.3559C54.7422 58.5975 54.6947 58.8367 54.6022 59.0599C54.5098 59.2831 54.3742 59.4859 54.2034 59.6567L48.1474 65.7104L54.2034 71.7641Z"
            fill="#41395C"
          />
        </g>
      </g>
      <path
        d="M138.999 12.3541L123.392 38.082L138.353 20.9678L145.21 32.8798L162.064 2.68635L144.53 23.7107L138.999 12.3541Z"
        fill="#D9D9D9"
      />
      <path
        d="M174.191 53.7458L150.786 67.947L168.036 62.8582L167.56 69.2052L200.633 59.8322L173.477 63.2663L174.191 53.7458Z"
        fill="#D9D9D9"
      />
      <path
        d="M190.262 106.925L164.534 109.554L184.935 111.084L182.645 117.295L212.499 120.446L187.383 114.915L190.262 106.925Z"
        fill="#D9D9D9"
      />
      <defs>
        <clipPath id="clip0_3062_18517">
          <rect
            width="58.8365"
            height="58.8365"
            fill="white"
            transform="translate(16.1298 36.292)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
