import { BankAutofill } from "types/Models/bankAutofill";
import { emptyApi } from "./emptyApi";
import { AddressAutofill } from "types/Models/addressAutofill";
import qs from "qs";

type AbaDetails = BankAutofill & {
  routing_number: string;
};

type SwiftDetails = BankAutofill & {
  swift_code: string;
};

type FinancialAddress = {
  aba?: AbaDetails;
  swift?: SwiftDetails;
  supported_networks: string[];
  type: "aba" | "swift";
};

type BankTransfer = {
  country: string;
  financial_addresses: FinancialAddress[];
  type: string;
};

type FundingInstructions = {
  object: "funding_instructions";
  bank_transfer: BankTransfer;
  currency: string;
  funding_type: string;
  livemode: boolean;
};

type BankDetails = {
  account_holder_address: AddressAutofill;
  account_holder_name: string;
  account_number: string;
  account_type: string;
  bank_address: AddressAutofill;
  bank_name: string;
  routing_number: string;
};

type BankTransferDetails = {
  aba: BankDetails;
  supported_networks: string[];
  type: "aba";
};

type Coupon = {
  uuid: string;
  coupon_code: string;
};

type Discount = {
  coupon: Coupon;
  discount_value: number;
};

type CompanyEntity = {
  uuid: string;
  name: string;
  formation_id: string;
  state_id: string;
  state: string;
  country: string;
  country_id: string;
  country_code: string;
};

type Item = {
  uuid: string;
  quantity: number;
  amount: number;
  name: string;
  season: string | null;
};

export type BankTransferOrderType =
  | "CREATED"
  | "PARTIALLY_FUNDED"
  | "SETTLED"
  | "AWAITING_CONFIRMATION"
  | "AWAITING_TRANSFER";
type TimelineEvent = {
  event_type: BankTransferOrderType;
  occurred_at: string;
  amount: number;
};

export type BankTransferOrder = {
  uuid: string;
  status: "PENDING" | "PARTIAL" | "SUCCESS" | "FAILED";
  amount: number;
  currency: string;
  created_at: string;
  payment_summary: {
    items: Item[];
    discount_value: number;
    coupon: { coupon_code: string; uuid: string } | null;
  };
  bank_transfer_details: BankTransferDetails;
  timelines: TimelineEvent[];
  order_id: string;
};

export type BankTransferOrderDetails = {
  uuid: string;
  order_id: string;
  amount: number;
  status: "PENDING" | "PARTIAL" | "SUCCESS" | "FAILED";
  created_at: string;
  items_count: number;
};

type PaginatedBankTransfers = {
  bank_transfer: BankTransferOrderDetails[];
  total_pages: number;
  current_page: number;
  total_count: number;
  per_page: number;
};

export const bankTransferApis = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllBankTransfer: build.query<
      PaginatedBankTransfers,
      { entityId: string; pageNum?: number | null; search?: string | null }
    >({
      query: ({ entityId, pageNum, search }) => {
        let queryUrl = qs.stringify(
          {
            page_num: pageNum,
            search,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/billing/entity/${entityId}/bank_transfers/${queryUrl}`,
        };
      },
      providesTags: ["BANK_TRANSFERS"],
    }),
    getDetailedBankTransfer: build.query<
      BankTransferOrder,
      { entityId: string; bankTransferId: string }
    >({
      query: ({ entityId, bankTransferId }) =>
        `/api/inkle/billing/entity/${entityId}/bank_transfer/${bankTransferId}/`,
      providesTags: ["BANK_TRANSFER_DETAILS"],
    }),
    createBankTransferPaymentInter: build.mutation<
      {
        uuid: string;
        financial_addresses: FinancialAddress | null;
      },
      {
        entityId: string;
        bank_transfer_type: string;
        payment_for: "INVOICE" | "CART";
        invoice_ids?: string[] | null;
      }
    >({
      query: ({ entityId, bank_transfer_type, payment_for, invoice_ids }) => {
        return {
          url: `/api/inkle/billing/entity/${entityId}/bank_transfer/`,
          method: "post",
          body: { bank_transfer_type, payment_for, invoice_ids },
        };
      },
      invalidatesTags: [
        "BANK_TRANSFERS",
        "CART",
        "CustomerChatTickets",
        "FEDERAL_REGISTRATIONS",
        "STATE_REGISTRATIONS",
        "BillingInvoices",
      ],
    }),
    getVACDetails: build.query<FundingInstructions, { entityId: string }>({
      query: ({ entityId }) =>
        `/api/inkle/billing/entity/${entityId}/vac_details/`,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllBankTransferQuery,
  useGetDetailedBankTransferQuery,
  useCreateBankTransferPaymentInterMutation,
  useGetVACDetailsQuery,
} = bankTransferApis;
