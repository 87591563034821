import { AddressFormFields } from "components/AddressFormFields/AddressFormFields";
import { DueDate } from "components/design/DueDate";
import { InvoiceItemGroup } from "components/design/InvoiceItemGroup";
import { ToggleNumbers } from "components/design/ToggleNumbers";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { FormikListener } from "components/FormikListener/FormikListener";
import { CopyIcon } from "components/icons/CopyIcon";
import { DeleteIcon } from "components/icons/delete";
import { EnterIcon } from "components/icons/EnterIcon";
import { InfoFilledSmall } from "components/icons/InfoFilledSmall";
import { NumericInput } from "components/NumericInput/NumericInput";
import { PriceInput } from "components/PriceInput/PriceInput";
import { YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import {
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
  FormikErrors,
  useFormikContext,
} from "formik";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import { UpdatePayload } from "store/apis/vendorBills";
import { VendorBill } from "types/Models/vendorBills";
import { debounce } from "utils/debouncing";
import MerchantSeletor from "./MerchantSeletor";

type BillConsoleProps = {
  bill: VendorBill;
  onChange?: (value: Partial<UpdatePayload>) => void;
};

export const VendorDetails = ({
  bill,
  onChange,
  disabled,
}: BillConsoleProps & { disabled?: boolean }) => {
  const { errors } = useFormikContext<UpdatePayload>();
  const { entity_merchant_data } = errors || {};

  return (
    <InvoiceItemGroup title="Vendor Details" required>
      <MerchantSeletor
        required
        type={
          (
            entity_merchant_data as FormikErrors<
              VendorBill["entity_merchant_data"]
            >
          )?.uuid
            ? "error"
            : undefined
        }
        isDisabled={disabled}
        withForm
        name="entity_merchant_data.uuid"
        isClearable={false}
        defaultValue={
          bill?.entity_merchant_data
            ? {
                label: bill.entity_merchant_data.name,
                value: bill.entity_merchant_data.uuid,
              }
            : undefined
        }
        placeholder="Select your vendor"
        size="small"
      />
    </InvoiceItemGroup>
  );
};

export const VendorAddress = ({ bill, onChange }: BillConsoleProps) => {
  const { setFieldValue } = useFormikContext<UpdatePayload>();

  return (
    <InvoiceItemGroup title="Vendor Address" required>
      <Formik
        initialValues={
          bill.merchant_address || {
            uuid: "",
            city: "",
            country: "",
            state: "",
            street_address: "",
            zipcode: "",
          }
        }
        onSubmit={() => {}}
      >
        {({ values: addressValues }) => (
          <Form className="t-grid t-grid-cols-2 t-gap-4">
            <FormikListener
              debounce
              values={addressValues}
              callback={(v) => {
                setFieldValue("merchant_address", v);
              }}
            />
            <AddressFormFields />
          </Form>
        )}
      </Formik>
    </InvoiceItemGroup>
  );
};

export const BillDetails = ({ bill, onChange }: BillConsoleProps) => {
  const { setValues } = useFormikContext<UpdatePayload>();

  return (
    <InvoiceItemGroup title="Bill Details">
      <div className="t-grid t-gap-4 t-grid-cols-2">
        <TextInput
          customSize="small"
          name="title"
          label="Title"
          placeholder="Enter title"
        />
        <TextInput
          required
          customSize="small"
          name="bill_number_without_prefix"
          label="Bill Number"
          customPrefix={<div className=" t-text-text-30">INV</div>}
        />

        <Field name="bill_date" required>
          {({ field }: FieldProps) => {
            return (
              <DateInput
                {...field}
                name="bill_date"
                customSize="small"
                required
                portalId="bill_date"
                label="Bill Date"
                onDateChange={(date) => {
                  // This will validate form after setting value
                  const savingDate = date ? dayjs(date).format(YYYY_MM_DD) : "";
                  setValues({
                    ...bill,
                    bill_date: savingDate,
                    due_date: "",
                  });
                }}
              />
            );
          }}
        </Field>
        <DueDate invoiceDate={bill.bill_date} dueDate={bill.due_date} />
      </div>
    </InvoiceItemGroup>
  );
};

export const ItemDetails = ({ bill, onChange }: BillConsoleProps) => {
  return (
    <InvoiceItemGroup title="Item Details">
      <div className="t-flex t-flex-col t-gap-6">
        <FieldArray
          name="invoice_items"
          render={({ remove, push }) => (
            <>
              {bill.invoice_items.map((_, index) => (
                <div
                  className="t-grid t-grid-cols-[2fr_1fr_1fr_0.5fr] t-gap-3 t-items-end t-justify-end"
                  key={index}
                >
                  <TextInput
                    required
                    customSize="small"
                    label="Item"
                    name={`invoice_items.${index}.description`}
                    placeholder="Enter Item name"
                  />
                  <NumericInput
                    storeNumeric
                    fieldProps={{
                      name: `invoice_items.${index}.quantity`,
                      type: "number",
                    }}
                    numericProps={{
                      customSize: "small",
                    }}
                    label="Quantity"
                    required
                  />
                  <PriceInput
                    customSize="small"
                    name={`invoice_items.${index}.rate`}
                    label="Rate"
                    required
                  />
                  <Button
                    onClick={() => remove(index)}
                    type="button"
                    customType="icon"
                    disabled={bill?.invoice_items?.length === 1}
                    size="small"
                  >
                    <DeleteIcon color="currentColor" />
                  </Button>
                </div>
              ))}
              <div className="t-flex t-items-center t-gap-2">
                <Button
                  type="button"
                  customType="secondary"
                  size="small"
                  onClick={() =>
                    push({
                      description: "",
                      quantity: 0,
                      rate: 0,
                      uuid: "",
                    })
                  }
                >
                  Add line item
                </Button>
                <span className=" t-text-button t-text-neutral-30">or</span>
                <div className="t-bg-neutral-0 t-justify-center t-items-center  t-rounded-full t-text-neutral-70 t-px-3 t-py-2 t-text-body-sm t-gap-2 t-flex">
                  <EnterIcon />
                  <span>Return</span>
                </div>
              </div>
            </>
          )}
        />
      </div>
    </InvoiceItemGroup>
  );
};

export const TaxAndDiscount = ({ bill, onChange }: BillConsoleProps) => {
  const { setFieldValue } = useFormikContext<UpdatePayload>();
  return (
    <InvoiceItemGroup title="Tax & Discount">
      <div className="t-flex t-gap-4">
        {bill.tax_type === "PERCENT" ? (
          <NumericInput
            fieldProps={{ name: "tax" }}
            storeNumeric
            numericProps={{
              fixedDecimalScale: true,
              decimalScale: 2,
              suffix: " %",
              allowNegative: false,
              rightComponent: (
                <ToggleNumbers
                  value={bill.tax_type}
                  onValueChange={(value) => setFieldValue("tax_type", value)}
                />
              ),
            }}
            label="Tax"
          />
        ) : (
          <PriceInput
            name="tax"
            rightComponent={
              <ToggleNumbers
                value={bill.tax_type}
                onValueChange={(value) => setFieldValue("tax_type", value)}
              />
            }
            label="Tax"
            allowNegative={false}
          />
        )}

        {bill.discount_type === "PERCENT" ? (
          <NumericInput
            label="Discount"
            storeNumeric
            fieldProps={{ name: "discount" }}
            numericProps={{
              fixedDecimalScale: true,
              decimalScale: 2,
              suffix: " %",
              allowNegative: false,
              rightComponent: (
                <ToggleNumbers
                  value={bill.discount_type}
                  onValueChange={(value) =>
                    setFieldValue("discount_type", value)
                  }
                />
              ),
            }}
          />
        ) : (
          <PriceInput
            name="discount"
            allowNegative={false}
            rightComponent={
              <ToggleNumbers
                value={bill.discount_type}
                onValueChange={(value) => setFieldValue("discount_type", value)}
              />
            }
            label="Discount"
          />
        )}

        <PriceInput
          allowNegative={false}
          name="paid_amount"
          label="Advance Paid"
        />
      </div>
    </InvoiceItemGroup>
  );
};

export const PaymentDetails = ({
  bill,
  onChange,
  paymentlink = false,
}: BillConsoleProps & {
  paymentlink?: boolean;
}) => {
  const { copyToClipboard } = useCopyToClipboard();

  return (
    <InvoiceItemGroup title="Payment Details" required>
      <TextArea
        label={
          <div className="t-flex t-items-center t-justify-center t-gap-2">
            Payment Instructions
            <ToolTip
              align="start"
              alignOffset={-10}
              keepOpenOnActivation
              text={
                <div>
                  This will be added as payment instruction
                  <br /> in the invoice.
                </div>
              }
            >
              <span>
                <InfoFilledSmall color="currentColor" size="12" />
              </span>
            </ToolTip>
          </div>
        }
        name="payment_instructions"
        placeholder="Enter payment instructions"
      />
      {paymentlink && (
        <TextInput
          label="Payment Link"
          name="payment_link"
          placeholder="https://example.com/"
          rightComponent={
            <Button
              type="button"
              size="small"
              customType="link"
              disabled={!bill?.payment_link}
              onClick={debounce(() =>
                copyToClipboard(bill?.payment_link || "")
              )}
            >
              <span className="t-flex t-items-center t-gap-2">
                Copy
                <CopyIcon color="currentColor" />
              </span>
            </Button>
          }
        />
      )}
      <TextArea label="Payment Terms" name="terms" />
    </InvoiceItemGroup>
  );
};
