import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";
import React from "react";
import { BooksProfileType } from "types/Models/group";
import { formatDate } from "utils/formatDate";

export const BooksProfile = ({
  booksData,
}: {
  booksData: BooksProfileType;
}) => {
  return (
    <div className="t-rounded-lg t-border-neutral-10 t-border t-border-solid">
      <div className="!t-border-b t-border-0 t-border-neutral-10 t-p-4 t-border-solid t-text-subtitle">
        Books Profile
      </div>
      <div className="t-p-4 t-grid t-grid-cols-2 t-gap-6">
        <InfoItem label="Connected Datasources">
          {booksData.connected_datasources}
        </InfoItem>
        <InfoItem label="Bank Access">
          {booksData.bank_access ? "Yes" : "No"}
        </InfoItem>
        <InfoItem label="Monthly Bookkeeping">
          {booksData.monthly_bookkeeping ? "Yes" : "No"}
        </InfoItem>
        <InfoItem label="Annual Catchup">
          {booksData.annual_catchup ? "Yes" : "No"}
        </InfoItem>
        <InfoItem label="Last Review Date">
          {booksData.last_review_date
            ? formatDate(booksData.last_review_date)
            : "-"}
        </InfoItem>
      </div>
    </div>
  );
};
