import { ConditionalLink } from "components/conditionalLink";
import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import Modal from "components/DesignSystem/Modal/Modal";
import Tab from "components/DesignSystem/Tab/Tab";
import HelpIcon from "components/icons/helpIcon";
import { Redirect } from "components/icons/Redirect";
import { PHONE } from "constants/help";
import { ONBOARDING_LINK } from "constants/meetingRequestUrls";
import {
  ENTITY_DETAILS_STEP,
  US_ENTITY_DETAILS_V2,
} from "constants/onBoarding";
import { CurrentGroupContext } from "contexts/CurrentGroupContext";
import { useContext, useEffect, useState } from "react";
import {
  useGetOnboardingProductsQuery,
  useUpdateProductStatusMutation,
} from "store/apis/productOnboarding";
import { Step } from "types/Models/onboarding";
import { openLink } from "utils/openLink";
import { OnboardingStep } from "./OnboardingStep";
import { OnboardingStepper } from "./OnboardingStepper/OnboardingStepper";
import * as PRODUCT_ONBOARDING from "constants/productOnboardings";

type OnboardingWidgetProps = {
  open: boolean;
  onClose: any;
  currentTab?: string;
};

export const OnboardingWidget = ({
  open,
  onClose,
  currentTab = "Tax",
}: OnboardingWidgetProps) => {
  const [currentProductId, setCurrentProductId] = useState<string>();
  const [currentStep, setCurrentStep] = useState<Step>();
  const group = useContext(CurrentGroupContext);
  const usEntities = group?.entities?.filter((e) => e.country_code === "US");
  const defaultEntity =
    currentStep?.step_metadata?.current_entity_id || usEntities?.[0]?.uuid;
  const [selectedEntity, setSelectedEntity] = useState(defaultEntity);

  const { data: products } = useGetOnboardingProductsQuery(
    {
      groupId: group?.uuid!,
    },
    { skip: !group?.uuid }
  );

  const [updateProductStatus] = useUpdateProductStatusMutation();

  useEffect(() => {
    setCurrentProductId(
      products?.find((product) => product.name === currentTab)?.uuid
    );
  }, [currentTab, products?.length]);

  useEffect(() => {
    setSelectedEntity(defaultEntity);
  }, [defaultEntity]);

  const currentProduct = products?.find((p) => p.uuid === currentProductId);
  const isFirstStepNotStarted =
    currentStep?.order === 0 && currentStep?.status === "NOT_STARTED";
  const isCurrentStepDisabled =
    currentStep?.status === "COMPLETED" &&
    !isFirstStepNotStarted &&
    currentStep.type !== "SUBSCRIPTION_COMPLETE";

  const completeOnboarding = async (
    groupId: string,
    productId: string,
    closeModal: boolean
  ) => {
    await updateProductStatus({
      groupId,
      productId,
      payload: {
        product_status: "COMPLETED",
      },
    });

    if (closeModal) {
      onClose();
    }
  };

  let currentProductName = currentProduct?.name;

  if (currentProductName === PRODUCT_ONBOARDING.TAXV2) {
    currentProductName = "Tax";
  }

  return (
    <>
      <Modal.Root open={open} onOpenChange={onClose}>
        <Modal.Content
          className="t-h-[90vh] t-overflow-hidden"
          useCustomOverlay
          size="xxl"
        >
          <Tab.Root
            onValueChange={(e) =>
              setCurrentProductId(
                products?.find((product: any) => product.name === e)?.uuid
              )
            }
            value={currentProduct?.name}
            className="t-h-full t-flex t-flex-col"
          >
            <Modal.Header className="t-pb-0">
              <div className="t-text-h5 t-text-text-100 t-mb-4">
                {currentProductName} Onboarding
              </div>
              <div className="t-flex t-items-center t-gap-2 t-mb-4">
                <ConditionalLink to={ONBOARDING_LINK} target="_blank">
                  <Button size="small" type="button">
                    Schedule onboarding call
                  </Button>
                </ConditionalLink>

                <Dropdown.Root>
                  <Dropdown.Trigger asChild>
                    <div>
                      <Button customType="ghost_icon">
                        <div className="t-text-neutral-80">
                          <HelpIcon color="currentColor" />
                        </div>
                      </Button>
                    </div>
                  </Dropdown.Trigger>
                  <Dropdown.Portal>
                    <Dropdown.Content sideOffset={5} align="end">
                      <Dropdown.Item
                        onClick={() => openLink("/chat")}
                        className="t-text-text-60 t-text-subtext-sm t-flex t-gap-0.5"
                      >
                        Inkle Chat <Redirect size={16} />
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => openLink(`https://wa.me/${PHONE}`)}
                        className="t-text-text-60 t-text-subtext-sm t-flex t-gap-0.5"
                      >
                        Whatsapp <Redirect size={16} />
                      </Dropdown.Item>
                    </Dropdown.Content>
                  </Dropdown.Portal>
                </Dropdown.Root>
                <Modal.Close />
              </div>
            </Modal.Header>
            <div className="t-grid t-grid-cols-[1fr_3fr] t-overflow-auto t-grow">
              <div className="t-p-6 t-bg-surface-lighter-grey">
                <OnboardingStepper size="small">
                  {currentProduct?.steps?.map((step, i) => {
                    const { name, status, uuid } = step;
                    const isStepClickable =
                      (status !== "NOT_STARTED" &&
                        currentProduct.name === PRODUCT_ONBOARDING.BOOKS) ||
                      currentProduct.name === PRODUCT_ONBOARDING.TAX ||
                      currentProduct.name === PRODUCT_ONBOARDING.TAXV2;

                    const showEntities =
                      step?.type === ENTITY_DETAILS_STEP &&
                      currentStep?.type === ENTITY_DETAILS_STEP &&
                      currentStep?.step_key === US_ENTITY_DETAILS_V2;

                    return (
                      <OnboardingStepper.Step
                        key={uuid}
                        notFirstStep={i !== 0}
                        notLastStep={i < currentProduct.steps.length - 1}
                        isCompleted={status === "COMPLETED"}
                        isActive={currentStep?.name === name}
                        isPending={status === "NOT_STARTED"}
                        step={i + 1}
                        onClick={() => setCurrentStep(step)}
                        clickable={isStepClickable}
                        showEntities={showEntities}
                        selectedEntity={selectedEntity}
                        formStepId={currentStep?.uuid}
                        isCurrentStepDisabled={isCurrentStepDisabled}
                        setSelectedEntity={setSelectedEntity}
                      >
                        {name}
                      </OnboardingStepper.Step>
                    );
                  })}
                </OnboardingStepper>
              </div>
              <div className="t-flex t-flex-col t-overflow-y-auto">
                <Modal.Body className="t-pr-0 t-pl-0 t-pb-0 t-flex-grow !t-overflow-hidden">
                  <Tab.Content
                    className="t-h-full"
                    value={currentProduct?.name || ""}
                  >
                    {currentProduct && (
                      <OnboardingStep
                        groupId={group?.uuid || ""}
                        currentProduct={currentProduct}
                        completeOnboarding={completeOnboarding}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        isCurrentStepDisabled={isCurrentStepDisabled}
                        onClose={onClose}
                        selectedEntity={selectedEntity}
                      />
                    )}
                  </Tab.Content>
                </Modal.Body>
              </div>
            </div>
          </Tab.Root>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};
