import Loader from "components/design/loader";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Search } from "components/DesignSystem/Search/Search";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useGetAllMerchantsQuery } from "store/apis/transactions";
import { FiltersValues } from "store/slices/transactionFilter";
import { debounce } from "utils/debouncing";

export const isChecked = (array: FiltersValues["value"], uuid: string) => {
  if (Array.isArray(array)) {
    return array?.find((id: string) => id === uuid);
  }
  return false;
};

export const VendorsFilter = ({
  updateFilter,
  vendors,
}: {
  updateFilter: (v: any, a: any) => void;
  vendors?: FiltersValues;
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [merchantsData, setMerchantsData] = useState<string[]>([]);
  const vendorValues = vendors?.value || [];

  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const { data, isLoading } = useGetAllMerchantsQuery(
    {
      groupId,
      entityId,
      page_num: pageNumber,
      search_term: searchTerm,
    },
    { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
  );

  const {
    merchants: merchantsRes = [],
    current_page = 1,
    total_pages = 1,
  } = data || {};

  const onSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value) {
      setSearchTerm(value);
    } else {
      setSearchTerm("");
    }
    setPageNumber(1);
  });

  const onHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;

    if (Array.isArray(vendorValues)) {
      let newMerchants: Set<string> = new Set();
      if (checked) {
        newMerchants = new Set([...vendorValues, value]);
      } else {
        newMerchants = new Set(
          [...vendorValues]?.filter((merchant) => merchant !== value)
        );
      }
      let values = [...newMerchants].length > 0 ? [...newMerchants] : undefined;

      updateFilter("vendors", values);
    }
  };

  useEffect(() => {
    setPageNumber(current_page);
    setTotalPages(total_pages);
    if (searchTerm || pageNumber === 1) {
      setMerchantsData([...new Set([...merchantsRes])]);
    } else {
      setMerchantsData((merchant) => {
        const updatedMerchants = new Set([...merchant, ...merchantsRes]);
        return [...updatedMerchants];
      });
    }
  }, [merchantsRes, searchTerm]);

  const setPagination = debounce(() => {
    if (pageNumber < totalPages) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  });

  const noMerchantsFound = merchantsData.length === 0;

  return (
    <div className="t-w-full t-flex t-flex-col t-gap-5 t-h-full">
      <Search
        block
        placeholder="Search Vendors"
        onChange={onSearch}
        customSize="regular"
        autoFocus
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {noMerchantsFound ? (
            <div className="t-flex t-items-center t-h-full t-justify-center">
              No vendor found
            </div>
          ) : (
            <div
              onChange={onHandleChange}
              id="scrollableDiv"
              className="t-flex t-flex-col t-h-[320px] t-overflow-auto"
            >
              <InfiniteScroll
                dataLength={merchantsData?.length}
                next={setPagination}
                hasMore={pageNumber < totalPages}
                className="t-flex t-flex-col t-gap-3 t-px-2 t-h-[330px] t-overflow-auto"
                scrollableTarget="scrollableDiv"
                loader={
                  <div className="t-w-full t-text-center">Loading...</div>
                }
              >
                {merchantsData?.map((merchant) => (
                  <Checkbox
                    key={merchant}
                    name={merchant}
                    label={merchant}
                    value={merchant}
                    checked={Boolean(isChecked(vendorValues, merchant))}
                  />
                ))}
              </InfiniteScroll>
            </div>
          )}
        </>
      )}
    </div>
  );
};
