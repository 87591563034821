import classNames from "classnames";
import ToolTip from "components/design/toolTip";
import { ToolTipIcon } from "components/icons/TooltipIcon";
import {
  forwardRef,
  InputHTMLAttributes,
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
} from "react";

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  label?: string | ReactNode;
  disabled?: boolean;
  indeterminate?: boolean;
  alignTop?: boolean;
  fitWidth?: boolean;
  tooltipText?: string;
}

export const Checkbox = forwardRef<HTMLInputElement | null, CheckboxProps>(
  (
    {
      name,
      label,
      disabled = false,
      indeterminate = false,
      alignTop = false,
      fitWidth = false,
      className = "",
      tooltipText,
      ...props
    },
    ref
  ) => {
    const loacalref = useRef<HTMLInputElement>(null);
    const finalRef =
      (ref as MutableRefObject<HTMLInputElement | null>) || loacalref;

    useEffect(() => {
      if (finalRef.current !== null) {
        finalRef.current.indeterminate = indeterminate;
      }
    }, [finalRef, indeterminate]);

    return (
      <div
        className={classNames(
          "t-relative t-flex t-flex-shrink-0 t-flex-grow-0 t-justify-start t-gap-2",
          {
            "t-items-center": !alignTop,
            "t-items-start": alignTop,
            "t-max-w-fit": fitWidth,
            [className]: className,
          }
        )}
      >
        <input
          className="t-form-checkbox t-left-0 t-top-0 t-h-4 !t-w-4 t-cursor-pointer t-rounded-sm t-border-solid t-border-neutral-20 t-text-purple t-opacity-100 indeterminate:t-border-purple indeterminate:t-text-purple hover:t-bg-surface-purple focus:t-text-purple focus:t-ring-purple-20 focus:t-ring-offset-0 disabled:t-pointer-events-none disabled:t-bg-neutral-10 disabled:t-border-neutral-0 disabled:!t-text-surface-grey t-ml-0.5 !t-p-1"
          disabled={disabled}
          type="checkbox"
          name={name}
          id={name || "checkbox"}
          ref={finalRef}
          {...props}
        />
        {label && (
          <div className="t-flex t-items-center">
            <label
              className={classNames(
                "t-cursor-pointer t-select-none t-text-body t-text-neutral-100",
                {
                  "-t-mt-1": alignTop,
                  "!t-text-text-30 t-pointer-events-none": disabled,
                }
              )}
              htmlFor={name || "checkbox"}
            >
              {label}{" "}
            </label>
            {tooltipText && (
              <div className="t-ml-1 t-pb-1.5">
                <ToolTip text={tooltipText} side="right">
                  <span>
                    <ToolTipIcon />
                  </span>
                </ToolTip>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
);
