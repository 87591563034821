import {
  ConnectionProvider,
  ConnectionType,
  LastReconcilationStatus,
  ManualBank,
} from "types/Models/banks";
import { emptyApi } from "./emptyApi";
import qs from "qs";

export type ConnectionBankAccount = {
  account_number: string;
  name: string;
  available_balance: number;
  account_type?: string;
  uuid: string;
  opening_balance: number | null;
  opening_balance_date: string | null;
  balance_matches?: boolean;
} & LastReconcilationStatus;

export type Connection = {
  uuid: string;
  name: string;
  description: string;
  logo_url: string;
  is_connection_available: boolean;
  bank_accounts?: ConnectionBankAccount[];
  entity_item_id: string;
  last_successful_transaction_update: string;
  connection_provider: ConnectionProvider;
  is_plaid_account_connected?: boolean;
} & ConnectionType;

export type Institution = {
  name: string;
  logo: string;
  connection_id?: string;
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getConnectionAuthUrl: build.query<
      { authorization_url: string },
      {
        groupId: string;
        connectionId: string;
        state: string;
        entityId: string;
        entityItemId?: string;
      }
    >({
      query: ({ groupId, entityId, connectionId, state, entityItemId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/connections/auth_url/`,
          params: {
            entity_item_id: entityItemId,
            connection_id: connectionId,
            state: state,
          },
        };
      },
    }),

    disconnectConnection: build.mutation<
      { authorization_url: string },
      {
        groupId: string;
        connectionId: string;
        itemId: string;
        entityId: string;
      }
    >({
      query: ({ groupId, connectionId, itemId, entityId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/connections/disconnect/`,
          method: "PUT",
          body: {
            entity_item_id: itemId,
            connection_id: connectionId,
          },
        };
      },
      invalidatesTags: (res) =>
        res ? [{ type: "CONNECTION" }, { type: "Subscriptions" }] : [],
    }),

    confirmConnection: build.mutation<
      Connection,
      {
        code: string;
        groupId: string;
        entityId: string;
        connectionId: string;
        txn_pull_choice: string;
        txn_pull_date: string;
      }
    >({
      query: ({
        groupId,
        code,
        connectionId,
        entityId,
        txn_pull_choice,
        txn_pull_date,
      }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/exchange_token/`,
          body: {
            code,
            connection_id: connectionId,
            state: "",
            txn_pull_choice,
            txn_pull_date,
          },
          method: "POST",
        };
      },
      invalidatesTags: (res) =>
        res ? [{ type: "CONNECTION" }, { type: "Subscriptions" }] : [],
    }),

    getAllConnections: build.query<
      Connection[],
      { groupId: string; entityId: string }
    >({
      query: ({ groupId, entityId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/connections/`,
        };
      },
      providesTags: (res) => (res ? [{ type: "CONNECTION" }] : []),
    }),

    getAllBankBrands: build.query<
      {
        uuid: string;
        name: string;
      }[],
      { groupId: string }
    >({
      query: ({ groupId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/bank_brands/`,
        };
      },
      providesTags: (res) => (res ? [{ type: "CONNECTION" }] : []),
    }),

    createManualBankAccount: build.mutation<
      ManualBank,
      {
        groupId: string;
        entityId: string;
        bank_name_id: string;
        account_number: string;
        account_type: string;
        opening_balance: string;
        account_opening_date: string;
      }
    >({
      query: ({
        groupId,
        entityId,
        bank_name_id,
        account_number,
        account_type,
        opening_balance,
        account_opening_date,
      }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/connections/`,
          method: "POST",
          body: {
            bank_name_id,
            account_number,
            account_type,
            opening_balance,
            account_opening_date,
          },
        };
      },
      invalidatesTags: (res) =>
        res ? [{ type: "CONNECTION" }, { type: "Subscriptions" }] : [],
    }),

    getPlaidInstitutions: build.query<
      Institution[],
      { groupId: string; entityId: string; searchTerm: string }
    >({
      query: ({ groupId, entityId, searchTerm }) => {
        let query = qs.stringify(
          {
            search_term: searchTerm || null,
          },
          { skipNulls: true }
        );
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/plaid_institutions/`,
          params: {
            ...qs.parse(query),
          },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetConnectionAuthUrlQuery,
  useGetAllConnectionsQuery,
  useConfirmConnectionMutation,
  useDisconnectConnectionMutation,
  useGetAllBankBrandsQuery,
  useCreateManualBankAccountMutation,
  usePrefetch: useBooksConnectionsPrefetch,
  useLazyGetPlaidInstitutionsQuery,
} = extendedApi;
