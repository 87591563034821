import dayjs from "dayjs";
import * as DATE_PERIOD from "constants/dateFilter";

type DateRange = {
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
};

export type DateRangeValue = (typeof DATE_PERIOD)[keyof typeof DATE_PERIOD];

export const getDateRange = (value: DateRangeValue): DateRange => {
  switch (value) {
    case DATE_PERIOD.LAST_MONTH: {
      return {
        startDate: dayjs().subtract(1, "month").startOf("month"),
        endDate: dayjs().subtract(1, "month").endOf("month"),
      };
    }
    case DATE_PERIOD.CURRENT_MONTH: {
      return {
        startDate: dayjs().startOf("month"),
        endDate: dayjs().endOf("month"),
      };
    }
    case DATE_PERIOD.LAST_3_MONTHS: {
      return {
        startDate: dayjs().subtract(3, "month").startOf("month"),
        endDate: dayjs().subtract(1, "month").endOf("month"),
      };
    }
    case DATE_PERIOD.LAST_6_MONTHS: {
      return {
        startDate: dayjs().subtract(6, "month").startOf("month"),
        endDate: dayjs().subtract(1, "month").endOf("month"),
      };
    }
    case DATE_PERIOD.LAST_12_MONTHS: {
      return {
        startDate: dayjs().subtract(12, "month").startOf("month"),
        endDate: dayjs().subtract(1, "month").endOf("month"),
      };
    }
    case DATE_PERIOD.CURRENT_YEAR: {
      return {
        startDate: dayjs().startOf("year"),
        endDate: dayjs(),
      };
    }
    case DATE_PERIOD.LAST_YEAR: {
      return {
        startDate: dayjs().subtract(1, "year").startOf("year"),
        endDate: dayjs().subtract(1, "year").endOf("year"),
      };
    }
    case DATE_PERIOD.LAST_30_DAYS: {
      return {
        startDate: dayjs().subtract(30, "days"),
        endDate: dayjs(),
      };
    }
    case DATE_PERIOD.LAST_90_DAYS: {
      return {
        startDate: dayjs().subtract(90, "days"),
        endDate: dayjs(),
      };
    }
    case DATE_PERIOD.LAST_180_DAYS: {
      return {
        startDate: dayjs().subtract(180, "days"),
        endDate: dayjs(),
      };
    }
  }

  return {
    startDate: dayjs(),
    endDate: dayjs(),
  };
};
