import { ConditionalLink } from "components/conditionalLink";
import * as BILLING_CYCLES from "constants/billingCycles";
import * as STRIPE_COUPON_TYPES from "constants/stripeCouponTypes";
import { Divider } from "components/design/Divider";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { FormikForm } from "components/FormikForm/FormikForm";
import { ArrowRight } from "components/icons/ArrowRight";
import { Price } from "components/Price";
import { BOOKKEEPING_MANAGE, BOOKKEEPING_V2_MANAGE } from "constants/addons";
import { SOMETHING_WENT_WRONG } from "constants/apiCallError";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useCancelSubscriptionMutation,
  useUpdateSubscriptionMutation,
} from "store/apis/subscriptions";
import { resetAllAddonDataPayload, setAddonType } from "store/slices/addons";
import { RootState } from "store/store";
import { currency } from "utils/Currency";
import { SubscribeStep } from "./BookkeepingService";
import { BackendError } from "types/utils/error";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { SliderAccordion } from "components/DesignSystem/SliderAccordion/SliderAccordion";
import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";
import ToolTip from "components/design/toolTip";
import dayjs from "dayjs";
import { MMM_YYYY } from "constants/date";
import { BILLING_CYCLE } from "dictionaries";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Tag } from "components/icons/Navbar/Tag";
import { TicketTag } from "components/icons/TicketTag";

const BILLING_MAP = {
  [BILLING_CYCLES.MONTHLY]: "Monthly",
  [BILLING_CYCLES.QUARTERLY]: "Quaterly",
  [BILLING_CYCLES.YEARLY]: "Yearly",
};

export const BookkeepingServiceManage = () => {
  const dispatch = useDispatch();
  const entityId = useCurrentEntityId();
  const { addonType, addonData } = useSelector(
    (state: RootState) => state.addonsSlice
  );
  const { isCustomer } = useRoleBasedView();
  const [showAddonModal, setShowAddonModal] = useState<boolean>(
    addonType === BOOKKEEPING_V2_MANAGE
  );
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [updateSubscription, { isLoading: isUpdating }] =
    useUpdateSubscriptionMutation();
  const { alertToast, successToast } = useToast();

  const {
    subscription_name,
    tier_information,
    amount: addonAmount,
    stripe_coupon,
    scheduled_date,
    group_subscription,
    billing_cycle,
    uuid,
  } = addonData || {};

  const groupSubscriptionId = group_subscription?.[0]?.group_subscription_id;

  const tierInformation = tier_information?.find(
    ({ amount }) => amount === addonAmount
  );

  const close = () => {
    setShowAddonModal(false);
    dispatch(setAddonType(""));
    dispatch(resetAllAddonDataPayload());
  };

  const onSubmit = async (values: {
    selected_tier: string;
    schedule_date: string;
  }) => {
    const tier = tier_information?.find(
      ({ uuid }) => uuid === values.selected_tier
    );
    if (uuid && tier) {
      try {
        await updateSubscription({
          entityId: entityId,
          subscriptionId: groupSubscriptionId,
          payload: {
            selected_tier_amount: tier?.amount.toString(),
            scheduled_date: values.schedule_date,
          },
        }).unwrap();
        close();
      } catch (error) {
        alertToast({ message: (error as BackendError).data?.error?.message });
      }
    }
  };

  if (!groupSubscriptionId || !uuid) {
    return (
      <Modal.Root onOpenChange={close}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Manage Plan</Modal.Title>
            <Modal.Close />
          </Modal.Header>

          <Modal.Body>Cannot find subscription.</Modal.Body>
          <Modal.FooterButtonGroup>
            <Modal.RawClose asChild>
              <Button>Close</Button>
            </Modal.RawClose>
          </Modal.FooterButtonGroup>
        </Modal.Content>
      </Modal.Root>
    );
  }

  if (isCustomer) {
    return (
      <>
        <Modal.Root open={showAddonModal} onOpenChange={close}>
          <Modal.Content>
            <Modal.Header>
              <Modal.Title>Manage Plan</Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body>
              <div className="t-flex t-gap-4 t-flex-col">
                <div className="t-flex t-flex-col t-gap-1">
                  <div className="t-flex t-justify-between t-text-subtitle">
                    <span>{subscription_name}</span>
                    <span>
                      <AmountSuperScript
                        amount={Number(
                          stripe_coupon?.discounted_amount || addonAmount
                        )}
                      />
                      {billing_cycle && (
                        <span className="t-text-subtext-sm">
                          /{BILLING_CYCLE[billing_cycle]}
                        </span>
                      )}
                    </span>
                  </div>
                  {stripe_coupon && (
                    <p className="t-m-0 t-text-body-sm t-flex t-gap-0.5 t-text-dark_green">
                      <TicketTag />
                      {stripe_coupon?.coupon_code} (
                      {stripe_coupon.discount_type ===
                      STRIPE_COUPON_TYPES.PERCENT
                        ? `${stripe_coupon.discount_rate}%`
                        : `$${stripe_coupon.discount_rate}`}{" "}
                      OFF)
                    </p>
                  )}
                </div>

                <SliderAccordion.Root type="single" defaultValue={"Details"}>
                  <SliderAccordion.Item value="Details">
                    <SliderAccordion.Trigger disabled>
                      Subscription Details
                    </SliderAccordion.Trigger>
                    <SliderAccordion.Content>
                      <SliderAccordion.ItemGrid>
                        <InfoItem label="Accounting Method">Accrual</InfoItem>
                        <InfoItem label="Start from">
                          {dayjs(scheduled_date).format(MMM_YYYY)}
                        </InfoItem>
                        {billing_cycle && (
                          <InfoItem label="Frequency">
                            {BILLING_MAP[billing_cycle]}
                          </InfoItem>
                        )}
                        <InfoItem label="Estimated expense vol.">
                          <AmountSuperScript
                            amount={Number(tierInformation?.higher_range)}
                          />{" "}
                          to{" "}
                          <AmountSuperScript
                            amount={Number(tierInformation?.lower_range)}
                          />
                        </InfoItem>
                        <InfoItem
                          label={<span className="t-flex">Tier Pricing</span>}
                        >
                          <AmountSuperScript amount={Number(addonAmount)} />
                        </InfoItem>
                      </SliderAccordion.ItemGrid>
                    </SliderAccordion.Content>
                  </SliderAccordion.Item>
                </SliderAccordion.Root>
              </div>
            </Modal.Body>
            <Modal.Footer className="t-flex t-justify-between">
              <Button
                customType="ghost"
                onClick={() => {
                  setShowCancelModal(true);
                  setShowAddonModal(false);
                }}
              >
                Cancel subscription
              </Button>
              <ConditionalLink to={"/chat"}>
                <Button customType="primary">Contact us</Button>
              </ConditionalLink>
            </Modal.Footer>
          </Modal.Content>
        </Modal.Root>
        <BookKeepingProCancel
          showCancelModal={showCancelModal}
          setShowCancelModal={setShowCancelModal}
        />
      </>
    );
  }

  return (
    <>
      <Modal.Root open={showAddonModal} onOpenChange={close}>
        <Modal.Content>
          <FormikForm
            initialValues={{
              selected_tier: tierInformation?.uuid || "",
              schedule_date: scheduled_date || "",
            }}
            // @ts-ignore
            onSubmit={onSubmit}
          >
            <>
              <Modal.Header>
                <Modal.Title>Manage Plan</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                <SubscribeStep subscriptionId={uuid} />
              </Modal.Body>
              <Modal.Footer className="t-flex t-justify-between">
                <Button
                  customType="ghost"
                  onClick={() => {
                    setShowCancelModal(true);
                    setShowAddonModal(false);
                  }}
                >
                  Cancel subscription
                </Button>
                <Button
                  customType="primary"
                  isLoading={isUpdating}
                  disabled={isUpdating}
                >
                  Update
                </Button>
              </Modal.Footer>
            </>
          </FormikForm>
        </Modal.Content>
      </Modal.Root>
      <BookKeepingProCancel
        showCancelModal={showCancelModal}
        setShowCancelModal={setShowCancelModal}
      />
    </>
  );
};

export const BookKeepingProCancel = ({
  showCancelModal,
  setShowCancelModal,
}: {
  showCancelModal: boolean;
  setShowCancelModal: (newState: React.SetStateAction<boolean>) => void;
}) => {
  const dispatch = useDispatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const { addonData } = useSelector((state: RootState) => state.addonsSlice);
  const { alertToast, successToast } = useToast();
  const [cancelSubscription, { isLoading }] = useCancelSubscriptionMutation();
  const entityId = useCurrentEntityId();

  const handleCancelSubscription = async () => {
    try {
      await cancelSubscription({
        groupId,
        entityId,
        subscriptionId:
          addonData?.group_subscription?.[0]?.group_subscription_id,
      }).unwrap();
      successToast({ message: "Subscription cancelled successfully" });
      setShowCancelModal(false);
      dispatch(setAddonType(""));
    } catch (error: any) {
      alertToast({ message: error?.message || SOMETHING_WENT_WRONG });
      setShowCancelModal(false);
      dispatch(setAddonType(""));
    }
  };

  return (
    <Modal.Root open={showCancelModal} onOpenChange={setShowCancelModal}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Cancel subscription</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body">
            Are you sure you want to cancel this subscription? We will stop all
            accounting activity starting next month. You will also lose CPA &
            bookkeeper access via chat.
          </div>
        </Modal.Body>
        <Modal.Footer className="t-flex t-justify-end t-gap-3">
          <Button
            onClick={() => {
              setShowCancelModal(false);
              dispatch(setAddonType(""));
            }}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            customType="danger"
            onClick={handleCancelSubscription}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Cancel subscription
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
