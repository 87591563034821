export const Hourglass = ({ size = 24 }: { size?: number }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_779_5375)">
        <path
          d="M18.75 7.09125V3.75C18.75 3.35218 18.592 2.97064 18.3107 2.68934C18.0294 2.40804 17.6478 2.25 17.25 2.25H6.75C6.35218 2.25 5.97064 2.40804 5.68934 2.68934C5.40804 2.97064 5.25 3.35218 5.25 3.75V7.125C5.25069 7.35784 5.3053 7.58736 5.40957 7.79556C5.51383 8.00376 5.6649 8.18498 5.85094 8.325L10.7503 12L5.85 15.675C5.66408 15.8151 5.51315 15.9963 5.40905 16.2045C5.30495 16.4127 5.25051 16.6422 5.25 16.875V20.25C5.25 20.6478 5.40804 21.0294 5.68934 21.3107C5.97064 21.592 6.35218 21.75 6.75 21.75H17.25C17.6478 21.75 18.0294 21.592 18.3107 21.3107C18.592 21.0294 18.75 20.6478 18.75 20.25V16.9088C18.75 16.6767 18.6962 16.4478 18.5927 16.24C18.4892 16.0323 18.339 15.8514 18.1537 15.7116L13.2431 12L18.1537 8.28844C18.3387 8.14836 18.4888 7.96741 18.5922 7.75973C18.6956 7.55205 18.7496 7.32326 18.75 7.09125Z"
          fill="#F5B041"
        />
      </g>
      <defs>
        <clipPath id="clip0_779_5375">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
