import { ConditionalLink } from "components/conditionalLink";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { HelpCircle } from "components/icons/HelpCircle";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useGetDetailedBankTransferQuery } from "store/apis/bankTransfer";
import { BankAccountDetails } from "./BankAccountDetails";
import { BankOrderInfo } from "./BankOrderInfo";
import { BankOrderSummary } from "./BankOrderSummary";
import { BankPaymentStatus } from "./BankPaymentStatus";
import { BankPaymentTracker } from "./BankPaymentTracker";
import { useAppSelector } from "hooks/useAppSelector";
import { useDispatch } from "react-redux";
import { closeBankTransferDetails } from "store/slices/bankTransfer";

export const TransferOrderDetails = () => {
  const { successToast } = useToast();
  const entityId = useCurrentEntityId();
  const isCustomer = useRoleBasedView();
  const { secondary_channel_url } = useCurrentGroupContext();
  const {
    show,
    bankTransferOrderId,
    isFromLaunch,
    entityId: entityUuid,
  } = useAppSelector((state) => state.bankTransfer);
  const dispatch = useDispatch();
  const {
    data: bankTransferOrder,
    isLoading,
    isSuccess,
  } = useGetDetailedBankTransferQuery(
    {
      entityId: entityId || entityUuid!,
      bankTransferId: bankTransferOrderId,
    },
    { refetchOnMountOrArgChange: true, skip: !entityId && !entityUuid }
  );

  const handleClose = () => {
    if (isFromLaunch) {
      successToast({
        title: "New Order Added",
        message: "You can track the status within the orders tab",
      });
    }
    dispatch(closeBankTransferDetails());
  };

  return (
    <Modal.Root
      open={show}
      onOpenChange={() => dispatch(closeBankTransferDetails())}
    >
      <Modal.Content size="xl">
        <Modal.Header>
          <Modal.Title>
            Order Details{" "}
            {bankTransferOrder?.order_id &&
              ` (Order#${bankTransferOrder?.order_id})`}
          </Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <Async.Root
            isLoading={isLoading}
            isSuccess={isSuccess}
            isEmpty={false}
          >
            <Async.Empty>
              <></>
            </Async.Empty>
            <Async.Success>
              {bankTransferOrder && (
                <div className="t-flex t-gap-4">
                  <div className="t-w-[480px] t-space-y-4">
                    <BankPaymentStatus bankTransferOrder={bankTransferOrder} />
                    <BankAccountDetails bankTransferOrder={bankTransferOrder} />
                    <BankOrderInfo />
                  </div>
                  <div className="t-flex-1 t-space-y-4">
                    <BankPaymentTracker bankTransferOrder={bankTransferOrder} />
                    <BankOrderSummary bankTransferOrder={bankTransferOrder} />
                  </div>
                </div>
              )}
            </Async.Success>
          </Async.Root>
        </Modal.Body>

        <Modal.Footer className="t-flex t-justify-between t-items-center">
          {isCustomer && (
            <div className="t-flex t-gap-1 t-w-full t-items-center">
              <HelpCircle color="#706A85" />
              <div className="t-text-subtext t-text-text-60">Need help?</div>
              <ConditionalLink
                to={`/tax/chat/${secondary_channel_url}`}
                target="_blank"
              >
                <Button customType="link">Chat with us</Button>
              </ConditionalLink>
            </div>
          )}
          <Button customType="primary" onClick={handleClose}>
            {isFromLaunch ? "Got it" : "Close"}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
