import { AmountSuperScript } from "components/design/AmountSuperScript";
import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import CopyIcon from "static/images/CopyPurple.svg";
import { BankTransferOrder } from "store/apis/bankTransfer";
import { BankTransferStatus } from "./BankTransfer";

export const BankPaymentStatus = ({
  bankTransferOrder,
}: {
  bankTransferOrder: BankTransferOrder;
}) => {
  const { copyToClipboard } = useCopyToClipboard();
  const remainingDue = bankTransferOrder.timelines.at(-1)?.amount || 0;

  return (
    <div className="t-border t-border-solid t-border-neutral-10 t-rounded-lg">
      <div className="t-border t-border-solid t-border-neutral-10 t-border-t-0 t-border-r-0 t-border-l-0 t-px-4 t-py-3 t-flex t-justify-between t-items-center">
        <div className="t-text-neutral-60 t-text-sm">Payment Status</div>
        <BankTransferStatus status={bankTransferOrder.status} />
      </div>
      <div className="t-p-4">
        {bankTransferOrder.status === "PENDING" && (
          <div className="t-w-1/2">
            <InfoItem label="Amount Due">
              <div className="t-flex t-gap-1.5 t-items-center">
                <AmountSuperScript amount={Number(bankTransferOrder.amount)} />
                <img
                  src={CopyIcon}
                  alt="copyIcon"
                  onClick={() =>
                    copyToClipboard(bankTransferOrder.amount.toString())
                  }
                />
              </div>
            </InfoItem>
          </div>
        )}
        {bankTransferOrder.status === "SUCCESS" && (
          <div className="t-w-1/2">
            <InfoItem label="Total Amount">
              <div className="t-flex t-gap-1.5 t-items-center">
                <AmountSuperScript amount={Number(bankTransferOrder.amount)} />
                <img
                  src={CopyIcon}
                  alt="copyIcon"
                  onClick={() =>
                    copyToClipboard(bankTransferOrder.amount.toString())
                  }
                />
              </div>
            </InfoItem>
          </div>
        )}
        {bankTransferOrder.status === "PARTIAL" && (
          <div className="t-flex">
            <div className="t-w-1/2">
              <InfoItem label="Amount Due">
                <div className="t-flex t-gap-1.5 t-items-center">
                  <AmountSuperScript amount={Number(remainingDue)} />
                  <img
                    src={CopyIcon}
                    alt="copyIcon"
                    onClick={() =>
                      copyToClipboard(bankTransferOrder.amount.toString())
                    }
                  />
                </div>
              </InfoItem>
            </div>
            <div className="t-w-1/2">
              <InfoItem label="Total Amount">
                <div className="t-flex t-gap-1.5 t-items-center">
                  <AmountSuperScript
                    amount={Number(bankTransferOrder.amount)}
                  />
                  <img
                    src={CopyIcon}
                    alt="copyIcon"
                    onClick={() =>
                      copyToClipboard(bankTransferOrder.amount.toString())
                    }
                  />
                </div>
              </InfoItem>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
