import classNames from "classnames";
import { ReactNode } from "react";
import Breadcrumb from "../Breadcrumb/Breadcrumb";

type HeadersProps = {
  right?: ReactNode;
  title?: string | ReactNode;
  left?: ReactNode;
  bottom?: ReactNode;
  className?: string;
  breadcrumbs?: {
    name: ReactNode;
    link?: string;
  }[];
  v2?: boolean;
};

export const Header = ({
  v2,
  right,
  title,
  left,
  bottom,
  className,
  breadcrumbs = [],
}: HeadersProps) => {
  return (
    <div className="t-flex t-flex-col t-gap-2 t-bg-surface">
      {Boolean(breadcrumbs.length) && (
        <div className={classNames({ "t-px-6 t-pt-4": !v2 })}>
          <Breadcrumb.Root>
            {breadcrumbs.map((e) => (
              <Breadcrumb.Item link={e.link} key={e.link}>
                {e.name}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb.Root>
        </div>
      )}
      <div
        className={classNames(
          `t-sticky t-flex t-flex-col t-top-0 t-z-header t-gap-1  t-border-neutral-0 t-bg-surface sm:t-flex-wrap`,
          className || "",
          {
            "t-pb-0": Boolean(bottom),
            "t-px-6 t-py-3": !v2,
            "t-border-x-0 t-border-b t-border-t-0 t-border-solid":
              !v2 || bottom,
          }
        )}
      >
        <div className="t-flex t-w-full t-items-center t-justify-between t-gap-2 sm:t-flex-wrap">
          <div className="t-flex t-flex-1 t-items-center t-justify-between t-gap-2 t-text-i-primary-100">
            {title && (
              <h5 className="!t-m-0 t-text-h5" data-testid="header-title">
                {title}
              </h5>
            )}
            {left}
          </div>
          {right}
        </div>
        {bottom}
      </div>
    </div>
  );
};
