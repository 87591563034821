import { DashboardLayout } from "components/DashboardLayout";
import { InvoiceHeader } from "components/design/InvoiceHeader";
import Loader from "components/design/loader";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { Label, TextInput } from "components/DesignSystem/TextInput/TextInput";
import { DownloadIcon } from "components/icons/Download";
import { GridLayout } from "components/SendInvoiceModal/SendInvoice";
import {
  BILL_STATUS_LABEL,
  FINANCIAL_RECORD_TYPE,
  TAG_TYPE,
} from "constants/vendorBills";
import { Form, Formik } from "formik";
import { sendBillSchema } from "formValidations/vendorBillSchema";
import { useAuth } from "hooks/useAuth";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { useToast } from "hooks/useToast";
import { useHistory, useParams } from "react-router-dom";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import {
  useGetBillOrRequestQuery,
  useLazyDownloadBillOrRequestQuery,
  useSendRequestMutation,
} from "store/apis/vendorBills";
import { VendorBill } from "types/Models/vendorBills";
import { BackendError } from "types/utils/error";
import { currency } from "utils/Currency";
import { BillPreivew } from "./BillPreview";

type RequestBillProps = {
  close: () => void;
};

type Payload = {
  subject: string;
  body: string;
  vendor_bill_public_url: string;
  to: string;
};

export const invoiceBody = ({
  bill,
  name,
  email,
}: {
  bill: VendorBill;
  name: string;
  email: string;
}) => {
  return `Hi,

${name} has requested an invoice for the amount of ${currency({
    amount: bill.total || 0,
  })}. 
Please review the invoice & payment details here, and finalize the same to receive payment. 
Feel free to reach out if you have any questions.

Thank you for your prompt action.


Best Regards,
${name}
${email}`;
};

export const SendBill = ({ close }: RequestBillProps) => {
  const { alertToast, successToast } = useToast();
  const history = useHistory();
  const { link } = useConstructInternalLink();
  const { billId } = useParams<{ billId: string }>();
  const { uuid: groupId, entities } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { name } = entities.find((entity) => entity.uuid === entityId) || {};
  const { email } = useAuth();
  const { data, isLoading, isSuccess, isError, error } =
    useGetBillOrRequestQuery(
      { groupId, entityId, billId },
      {
        skip: !billId || !entityId || !groupId,
      }
    );

  usePageTitle(
    data?.bill_number ? `Send ${data?.bill_number}` : "Send Request"
  );

  const [downloadBill, { isFetching: isDownloading }] =
    useLazyDownloadBillOrRequestQuery();
  const [getUrl] = useLazyGetPreviewUrlQuery();

  const [sendRequest, { isLoading: isSending }] = useSendRequestMutation();

  const onSend = async (values: Payload) => {
    try {
      await sendRequest({
        billId,
        entityId,
        groupId,
        payload: values,
      }).unwrap();
      successToast({
        message: "Request sent",
      });
      history.push(
        link("/books/bills-and-vendors", {
          moreQuery: { BILL_TYPE: FINANCIAL_RECORD_TYPE.REQUEST },
        })
      );
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError)?.data?.error?.message,
        },
        error as Error
      );
    }
  };

  const onDownload = async () => {
    try {
      const { doc_id } = await downloadBill({
        entityId,
        groupId,
        billId,
      }).unwrap();
      const { download_url } = await getUrl({
        fileId: doc_id,
        groupId,
      }).unwrap();
      window.open(download_url, "_blank");
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError)?.data?.error?.message,
        },
        error as Error
      );
    }
  };

  const publicUrl = `${process.env.PUBLIC_URL}vendor-bill/${billId}`;

  return (
    <Modal.Root open={true} modal={false}>
      <Async.Root
        isLoading={isLoading}
        isSuccess={isSuccess}
        isEmpty={false}
        isError={isError}
        customLoader={
          <Modal.Content size="fullscreen">
            <Loader />
          </Modal.Content>
        }
      >
        <Async.ErrorHandler>
          <Modal.Content size="fullscreen">
            <Modal.Body className="t-min-h-80 t-w-full t-h-full t-flex t-justify-center t-flex-col t-items-center t-gap-2 t-text-subtitle-sm t-text-red">
              <>
                {(error as BackendError)?.data?.error?.message ||
                  "Something went wrong, Please try again later."}
              </>
              <Button onClick={close} size="small">
                Go back
              </Button>
            </Modal.Body>
          </Modal.Content>
        </Async.ErrorHandler>
        <Async.Empty>
          <></>
        </Async.Empty>
        <Async.Success>
          {data && name && email && publicUrl && (
            <Modal.Content size="fullscreen" className="t-grid t-grid-cols-2">
              <Formik<Payload>
                initialValues={{
                  subject: `[Action Required]: Review & approve invoice for ${name}`,
                  body: invoiceBody({
                    bill: data,
                    name,
                    email,
                  }),
                  vendor_bill_public_url: publicUrl,
                  to: data?.entity_merchant_data?.email || "",
                }}
                onSubmit={onSend}
                validateOnChange
                validationSchema={sendBillSchema}
              >
                {({ submitForm, isValid }) => (
                  <>
                    <Form className="t-overflow-auto">
                      <DashboardLayout
                        className="t-pt-6"
                        header={
                          <InvoiceHeader
                            onBackClick={close}
                            status={
                              <Tag
                                rounded
                                icon={false}
                                tagType={TAG_TYPE[data?.bill_request_status]}
                              >
                                {BILL_STATUS_LABEL[data?.bill_request_status]}
                              </Tag>
                            }
                            title={data?.bill_number}
                          />
                        }
                      >
                        <div className="t-flex t-flex-col t-gap-6">
                          <GridLayout>
                            <Label htmlFor="to">To</Label>
                            <TextInput
                              disabled
                              name="to"
                              customSize="small"
                              value={data?.entity_merchant_data?.email || ""}
                            />
                          </GridLayout>
                          <GridLayout>
                            <Label htmlFor="from">From</Label>
                            <TextInput
                              disabled
                              name="from"
                              customSize="small"
                              value="no-reply@inkle.io"
                            />
                          </GridLayout>
                          <GridLayout>
                            <Label htmlFor="subject">Subject</Label>
                            <TextInput name="subject" customSize="small" />
                          </GridLayout>
                          <TextArea
                            name="body"
                            disabled
                            label="Body"
                            rows={13}
                          />
                        </div>
                      </DashboardLayout>
                    </Form>

                    <DashboardLayout
                      className="t-pt-6 t-bg-surface-lighter-grey"
                      header={
                        <div className="t-flex t-gap-3 t-justify-end !t-bg-surface-lighter-grey">
                          <Button
                            type="button"
                            size="small"
                            onClick={onDownload}
                            disabled={isDownloading}
                            isLoading={isDownloading}
                          >
                            <span className="t-flex t-items-center t-gap-2">
                              <DownloadIcon />
                              Download
                            </span>
                          </Button>
                          <Button
                            size="small"
                            customType="primary"
                            onClick={submitForm}
                            isLoading={isSending}
                            disabled={isSending || !isValid}
                          >
                            Send Request
                          </Button>
                        </div>
                      }
                    >
                      <BillPreivew bill={data} />
                    </DashboardLayout>
                  </>
                )}
              </Formik>
            </Modal.Content>
          )}
        </Async.Success>
      </Async.Root>
    </Modal.Root>
  );
};
