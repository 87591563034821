import axios from "axios";
import { Button } from "components/DesignSystem/Button/Button";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { resetPasswordResendOtp } from "../../apis/resetPasswordResendOtp";
import { CODENOTRECEIVED, RESENDTIMER } from "../../constants/resendOtp";
import "../../static/styles/components/landingForm.css";
import Timer from "../design/Timer";

const OtpVerify_url =
  process.env.PUBLIC_BASE_URL + "api/inkle/users/verify-rp-otp/";
const duration = 30;

const OtpVerification = ({ prev, handleChange, values, next }) => {
  const history = useHistory();
  const { alertToast, successToast } = useToast();
  const [error, setError] = useState({
    p_verify: false,
    tokn: "",
  });
  const [showTimer, setShowTimer] = useState(false);
  const [key, setKey] = useState(0);

  const Continue = () => {
    if (values.p_verify.length !== 6) {
      setError((prevState) => ({
        ...prevState,
        p_verify: "Incorrect passkey",
      }));
    } else {
      send();
    }
  };

  const send = () => {
    let data1 = {
      email: values.email_id,
      otp: values.p_verify,
    };
    let data2 = {
      mobile: values.phone_no,
      otp: values.p_verify,
    };
    const params = values.email_id ? data1 : data2;
    axios
      .post(OtpVerify_url, params)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.data.token;
          let p = "token";
          handleChange(p, data);
          next();
        } else {
          history.push("/forgotPassword");
        }
      })
      .catch(function (error) {
        if (error.response) {
          alertToast({ message: error?.response?.data?.error?.message });
        } else {
          console.log(error.request);
        }
      });
  };

  const Previous = (e) => {
    e.preventDefault();
    prev();
  };

  const handChange = (p, event) => {
    let value;
    if (event.target) {
      value = event.target.value;
    } else {
      value = event;
    }
    handleChange(p, value);
    setError((prevState) => ({
      ...prevState,
      [p]: false,
    }));
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      Continue();
    }
  };

  const resend = () => {
    resetPasswordResendOtp({ values: values })
      .then((res) => {
        if (res.status === 200) {
          successToast({ message: "OTP send successfully" });
        } else {
          history.push("/forgotPassword");
        }
      })
      .catch(function (error) {
        if (error.response) {
          alertToast({ message: error?.response?.data?.error?.message });
        } else {
          console.log(error.request);
        }
        history.push("/");
      });
  };

  function updateTimer() {
    setShowTimer(true);
    setKey((prev) => prev + 1);
    resend();
  }

  return (
    <div className="rightBox">
      <div className="navigation">
        <div className="backBtn" onClick={Previous} aria-hidden="true">
          <i className="fas fa-arrow-left"></i>
        </div>
        <div className="credentials">
          <span className="helpMessage">
            {values.email_id || values.phone_no}
          </span>
        </div>
      </div>
      <div className="welcomeBoxParent">
        <h3 className="accountHead">Security Verification</h3>
        <form>
          <span className="verificationSubhead">
            To secure your account, please complete the following verification.
          </span>
          <br />
          <input
            type="text"
            className={error.p_verify !== false ? "errorbox" : ""}
            name="p_verify"
            placeholder="Verification Code"
            onChange={(e) => handChange("p_verify", e)}
            onKeyPress={handleEnter}
            value={values.p_verify}
          />{" "}
          <br />
          {error.p_verify !== false ? (
            <span className="errorMessage">
              {error.p_verify}
              <br />
            </span>
          ) : (
            <span className="helpMessage">
              Enter the 6 digit code sent to{" "}
              <u>{values.email_id || values.phone_no}</u>
              <br />
            </span>
          )}
        </form>
        <Button onClick={Continue}>Verify</Button> <br />
        <span className="welcomeSubhead resendSubhead">
          {showTimer ? RESENDTIMER : CODENOTRECEIVED}
        </span>
        {!showTimer ? (
          <span onClick={updateTimer} className="resendOtp">
            Resend OTP
          </span>
        ) : (
          <Timer setShowTimer={setShowTimer} duration={duration} key={key} />
        )}
      </div>
    </div>
  );
};
export default OtpVerification;
