import { useToast } from "hooks/useToast";
import { FC } from "react";
import { Merchant } from "types/Models/merchant";
import { Button } from "./DesignSystem/Button/Button";
import Modal from "./DesignSystem/Modal/Modal";
import { WSeriesEmailForm } from "./WSeriesEmailForm";
import { FormikForm } from "./FormikForm/FormikForm";
import { TextInput } from "./DesignSystem/TextInput/TextInput";
import {
  useUpdateMerchantFormMutation,
  useUpdateTaskMerchantMutation,
} from "store/apis/taskMerchants";
import { Divider } from "./design/Divider";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
type IWSeriesRequestFormProps = {
  show: boolean;
  closeModal: () => void;
  taskId: string;
  groupId: string;
  merchant: Merchant;
  updateMerchant: () => void;
  season: string;
};

export const WSeriesRequestForm: FC<IWSeriesRequestFormProps> = ({
  show,
  closeModal,
  taskId,
  groupId,
  merchant,
  updateMerchant,
  season,
}) => {
  const group = useCurrentGroupContext();
  const { form_status, email, uuid, name } = merchant || {};
  const [updateTaskMerchant, { isLoading: updatingTask }] =
    useUpdateTaskMerchantMutation();
  const [updateMerchantForm, { isLoading: updatingMerchant }] =
    useUpdateMerchantFormMutation();
  const { alertToast, successToast } = useToast();

  const onEmailSubmit = async ({ email }: { email: string }) => {
    try {
      if (taskId) {
        const { data } = await updateTaskMerchant({
          taskId,
          groupId,
          merchantId: uuid,
          email,
          season,
        });
        updateMerchant();
      } else {
        const { data } = await updateMerchantForm({
          groupId,
          merchantId: uuid,
          email,
          season,
        });
        updateMerchant();
      }
      closeModal();
      successToast({ message: "Email has been sent" });
    } catch (error: any) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };
  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content size="large">
          <FormikForm
            // @ts-ignore
            onSubmit={onEmailSubmit}
            initialValues={{ email }}
          >
            {({ isSubmitting, isValid }) => (
              <>
                <Modal.Header>
                  <Modal.Title>Request W-Form</Modal.Title>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body>
                  <p className="t-mb-4 t-text-body t-text-text-60">
                    Please enter the vendor's email address to request the
                    W-Form. An email will be sent to the vendor with the
                    following message.
                  </p>

                  <TextInput
                    label="To"
                    block
                    placeholder="Enter email of the contractor"
                    name="email"
                    required
                    customSize="regular"
                  />

                  <div className="t-mt-6 t-mb-3">
                    <Divider>
                      <span className="t-text-nowrap t-text-subtext-smm t-text-text-60">
                        Email Preview
                      </span>
                    </Divider>
                  </div>

                  <div className="t-border-dashed t-border-neutral-10 t-border t-bg-surface-lighter-grey t-rounded t-p-4">
                    <p className="t-text-text-30 t-text-body-sm">
                      <strong>Subject:</strong> New W form request from Inkle on
                      behalf of {group.name}
                      <br />
                      <br />
                      <strong>Body:</strong>
                      <br />
                      <span>
                        Hi there,
                        <br />
                        <br />
                        Inkle is helping {group.name} (copied on this email)
                        with their US tax filing! This email is a request from
                        {group.name} to fill out a W series form. {group.name}{" "}
                        had sent you a payment during {season} - so they are
                        required to collect a W series form from you.
                        <br />
                        <br />
                        If you have one ready, feel free to reply and attach it,
                        or you can create one here using our tool and e-sign it:
                        <br />
                        <span className="t-underline">Go to W Form</span>
                        <br />
                        <br />
                        If you are a US taxpayer, it is likely that
                        {group.name} will need to file a 1099 to the IRS in
                        regards to that payment - we'll come back to you when
                        this is done. For any questions or concerns, reach out
                        to us on this email! Many thanks, Team Inkle
                      </span>
                    </p>
                  </div>
                </Modal.Body>

                <Modal.FooterButtonGroup>
                  <Modal.RawClose asChild>
                    <Button>Cancel</Button>
                  </Modal.RawClose>
                  <Button
                    customType="primary"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    {form_status === "SENT" ? "Resend email" : "Send email"}
                  </Button>
                </Modal.FooterButtonGroup>
              </>
            )}
          </FormikForm>
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};
