import { ChatPane } from "components/ChatPane/ChatPane";
import { useSliderTicket } from "components/Ticket/CustomerTicket/TicketSlider";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useState } from "react";

export const ChatTab = ({
  channelId: channelIdFromProps,
  messageId,
}: { channelId?: string | null; messageId?: string } = {}) => {
  const { data } = useSliderTicket();
  const { channel_url, message_id } = data || {};
  const { secondary_channel_url } = useCurrentGroupContext();
  const [channelId, setChannelId] = useState<string | null>(
    channelIdFromProps || channel_url || secondary_channel_url
  );

  return (
    <div className="t-h-full t-shrink-0 t-grow-0 t-sticky t-top-0">
      <ChatPane
        channelId={channelId}
        setChannelId={setChannelId}
        messageId={messageId || message_id || undefined}
      />
    </div>
  );
};
