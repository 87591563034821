import authContext from "jwt_context&axios/authContext";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useGetUserProfileQuery } from "store/apis/profile";
import { setUnreadMessages } from "store/slices/chatUnreadCount";
import { StreamChat } from "stream-chat";
import { useAuth } from "./useAuth";

export const useStreamClient = () => {
  const { authtoken } = useContext(authContext);
  const userProfile = useAuth();
  const dispatch = useDispatch();

  const chatAccessToken =
    userProfile?.member?.stream_token || authtoken?.member?.stream_token;

  const chatMemberId =
    userProfile?.member?.member_id || authtoken?.member?.member_id;

  const user = useMemo(
    () => ({
      id: chatMemberId,
    }),
    [chatMemberId]
  );

  const [chatClient, setChatClient] = useState<null | StreamChat>(null);
  const apiKey = process.env.PUBLIC_STREAM_ACCESS_KEY;

  useEffect(() => {
    if (chatAccessToken && apiKey) {
      const client = new StreamChat(apiKey, { timeout: 20000 });
      // prevents application from setting stale client (user changed, for example)
      let didUserConnectInterrupt = false;

      const connectionPromise = client.connectUser(user, chatAccessToken).then(
        (e) => {
          // @ts-ignore
          if (!didUserConnectInterrupt) {
            setChatClient(client);
            if (e && e.me?.total_unread_count) {
              dispatch(setUnreadMessages(e.me?.total_unread_count));
            }
          }
        },
        (e) => console.log(e)
      );

      return () => {
        didUserConnectInterrupt = true;
        setChatClient(null);
        // wait for connection to finish before initiating closing sequence
        connectionPromise
          .then(() => client.disconnectUser())
          .then(() => {
            console.log("connection closed");
          });
      };
    }
  }, [chatAccessToken, user]);

  return {
    chatClient,
    user,
  };
};
