import { ConditionalLink } from "components/conditionalLink";
import { SUBMITTED_TYPE, TASK_AMENDMENT_COMPLETED } from "constants/taskStates";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useEffect, useState } from "react";
import { useGetCartQuery, useLazyGetCartQuery } from "store/apis/products";
import { useGetTaskListForUserQuery } from "store/apis/taskList";
import { FORM_1099_AND_W_SERIES } from "types/Models/services";
import { entitiesSortedByCountry } from "utils/entitiesSortedByCountry";

export const CurrentDeadlineBanner = ({
  setBannerShown,
}: {
  setBannerShown: (v: boolean) => void;
}) => {
  const { isCustomer, isAnyServiceUser } = useRoleBasedView();
  const { entities, uuid } = useCurrentGroupContext();
  const [entityToPayCartFor, setEntityToPayCartFor] = useState<string | null>(
    null
  );
  const [allEntitiesCartCheked, setAllEntitiesCartCheked] = useState(false);
  const [getCart] = useLazyGetCartQuery();

  const { data: tasks } = useGetTaskListForUserQuery(
    {
      showOngoing: true,
      showFinished: true,
      seasons: "2024",
      taskCategoryType: "TAX,RND",
    },
    { skip: isAnyServiceUser, refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    const fetchCartAndCheck1099 = async () => {
      try {
        for (
          let i = 0;
          i < entitiesSortedByCountry(entities).usEntities.length;
          i++
        ) {
          const cart = await getCart({
            entityId: entities[i].uuid,
            groupId: uuid,
          }).unwrap();

          const cartHas1099 = cart.cart_items.find(
            (cartItem) =>
              cartItem.product_details.base_task_key ===
                FORM_1099_AND_W_SERIES &&
              cartItem.product_details.season === "2024"
          );

          if (cartHas1099) {
            setEntityToPayCartFor(entities[i].uuid);
          }
        }
      } catch (error) {}
      setAllEntitiesCartCheked(true);
    };

    fetchCartAndCheck1099();
  }, [entities.length, uuid]);

  const task = tasks?.tasks?.find(
    (task) => task.base_task_key === FORM_1099_AND_W_SERIES
  );

  if (!isCustomer) {
    return null;
  }

  let content = null;

  if (!allEntitiesCartCheked) {
    content = null;
  }

  if (entityToPayCartFor) {
    content = (
      <>
        Attention! Form 1099 Deadline is January 31, 2025.{" "}
        <ConditionalLink
          to={`/settings/billing/cart?entity=${entityToPayCartFor}&from_template=${FORM_1099_AND_W_SERIES}`}
          className="t-font-bold t-ml-2 t-text-white !t-underline"
        >
          Pay for season 2024 and start filing now
        </ConditionalLink>
      </>
    );
  }

  if (
    task &&
    task?.state_details?.type !== SUBMITTED_TYPE &&
    task?.state_details?.type !== TASK_AMENDMENT_COMPLETED
  ) {
    content = (
      <>
        Attention! Form 1099 Deadline is January 31, 2025.{" "}
        <ConditionalLink
          to={`/filings/${task.uuid}`}
          className="t-font-bold t-ml-2 t-text-white !t-underline"
        >
          Start filing now
        </ConditionalLink>
      </>
    );
  }

  if (content) {
    return (
      <div
        ref={() => setBannerShown(true)}
        className="t-h-10 t-bg-banner-gradient t-bg-[#2d1285] t-text-white t-flex t-items-center t-justify-center t-text-body md:t-w-full t-px-6 t-py-1"
      >
        {content}
      </div>
    );
  }

  return null;
};
