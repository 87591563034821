import { Chip } from "components/DesignSystem/Chips/Chips";
import {
  Filter,
  MultiSelectFilter,
} from "components/DesignSystem/Filter/Filter";
import Radio from "components/DesignSystem/RadioGroup/RadioGroup";
import { useGetAssignAgentsQuery } from "store/apis/agents";

export const RAMailroomSubscriptionsFilter = ({
  updateFilter,
  values,
}: {
  updateFilter: <
    S extends
      | "SUBSCRIPTION_TYPE"
      | "SUPPORT_STEP"
      | "AGENT"
      | "UNASSIGNED_AGENT"
  >(
    name: S,
    newValue: {
      AGENT: string[];
      SUPPORT_STEP: string;
      SUBSCRIPTION_TYPE: string;
      UNASSIGNED_AGENT: boolean;
    }[S]
  ) => void;
  values: {
    AGENT: string[];
    SUPPORT_STEP: string;
    SUBSCRIPTION_TYPE: string;
    UNASSIGNED_AGENT: boolean;
  };
}) => {
  const updateFilterCallback =
    (name: Parameters<typeof updateFilter>[0]) =>
    (newValues: Parameters<typeof updateFilter>[1]) => {
      updateFilter(name, newValues);
    };
  const { data: agents } = useGetAssignAgentsQuery({});

  const subscriptionTypeOptions = [
    {
      label: "Registered Agent",
      value: "REGISTERED_AGENT",
    },
    { label: "Mailroom", value: "MAILROOM" },
  ];

  const supportStepOptions = [
    {
      label: "Initiated",
      value: "INITIATED",
    },
    {
      label: "Processing",
      value: "PROCESSING",
    },
    {
      label: "Completed",
      value: "COMPLETED",
    },
  ];

  const handleFixedFilterClick = (value: string) => {
    if (values.SUBSCRIPTION_TYPE === value) {
      updateFilter("SUBSCRIPTION_TYPE", "");
    } else {
      updateFilter("SUBSCRIPTION_TYPE", value);
    }
  };

  return (
    <Filter.Root
      defaultValue="SUBSCRIPTION_TYPE"
      title="Filters"
      capsule={
        <>
          <Chip
            onClose={() => updateFilter("SUBSCRIPTION_TYPE", "")}
            isActive={values.SUBSCRIPTION_TYPE === "REGISTERED_AGENT"}
            filterType="SUBSCRIPTION_TYPE"
            onClick={() => handleFixedFilterClick("REGISTERED_AGENT")}
          >
            Registered Agent
          </Chip>
          <Chip
            onClose={() => updateFilter("SUBSCRIPTION_TYPE", "")}
            isActive={values.SUBSCRIPTION_TYPE === "MAILROOM"}
            filterType="SUBSCRIPTION_TYPE"
            onClick={() => handleFixedFilterClick("MAILROOM")}
          >
            Mailroom
          </Chip>
          <Chip
            onClose={() => updateFilter("UNASSIGNED_AGENT", false)}
            isActive={values.UNASSIGNED_AGENT}
            filterType="UNASSIGNED_AGENT"
            onClick={() =>
              updateFilter("UNASSIGNED_AGENT", !values.UNASSIGNED_AGENT)
            }
          >
            Unassigned Agent
          </Chip>
          {values.SUPPORT_STEP && (
            <Chip
              onClose={() => updateFilter("SUPPORT_STEP", "")}
              isActive
              filterType="SUPPORT_STEP"
            >
              Support Step
            </Chip>
          )}
          {values?.AGENT.length > 0 && (
            <Chip
              onClose={() => updateFilter("AGENT", [])}
              isActive
              filterType="AGENT"
            >
              Agent ({values.AGENT.length})
            </Chip>
          )}
        </>
      }
    >
      <Filter.Portal>
        <Filter.List>
          <Filter.ListItem value="SUBSCRIPTION_TYPE">
            Subscription Type
          </Filter.ListItem>
          <Filter.ListItem value="SUPPORT_STEP">Support Step</Filter.ListItem>
          <Filter.ListItem value="AGENT">Agents</Filter.ListItem>
        </Filter.List>
        <Filter.Body value="SUBSCRIPTION_TYPE">
          <Radio.Root
            value={values.SUBSCRIPTION_TYPE}
            onValueChange={updateFilterCallback("SUBSCRIPTION_TYPE")}
          >
            <Radio.Content className="t-gap-3 t-flex t-flex-col">
              {subscriptionTypeOptions.map((item) => (
                <Radio.Item key={item.value} value={item.value}>
                  {item.label}
                </Radio.Item>
              ))}
            </Radio.Content>
          </Radio.Root>
        </Filter.Body>
        <Filter.Body value="SUPPORT_STEP">
          <Radio.Root
            value={values.SUPPORT_STEP}
            onValueChange={updateFilterCallback("SUPPORT_STEP")}
          >
            <Radio.Content className="t-gap-3 t-flex t-flex-col">
              {supportStepOptions.map((item) => (
                <Radio.Item key={item.value} value={item.value}>
                  {item.label}
                </Radio.Item>
              ))}
            </Radio.Content>
          </Radio.Root>
        </Filter.Body>
        {agents && (
          <Filter.Body value="AGENT" block>
            <MultiSelectFilter
              onChange={updateFilterCallback("AGENT")}
              options={agents?.map((agent) => ({
                value: agent.profile_id,
                label: agent.name,
                disable: values.UNASSIGNED_AGENT,
              }))}
              selected={values?.AGENT}
            />
          </Filter.Body>
        )}
      </Filter.Portal>
    </Filter.Root>
  );
};
