import { Info } from "components/icons/Info";
import React from "react";

export const BankOrderInfo = () => {
  return (
    <div className="t-text-text-60 t-text-body t-flex t-gap-1.5 t-p-3 t-rounded-lg t-border t-border-solid t-border-neutral-10 t-bg-surface-lighter-grey">
      <span className="t-text-text-60 t-h-4">
        <Info stroke="1.12" />
      </span>
      <div className="t-mt-0.5">
        Please use the account details below to make your transfer. It may take
        at least one day to reflect in the order and up to 5 days to fully
        process.
      </div>
    </div>
  );
};
