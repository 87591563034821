import classNames from "classnames";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import Table from "components/DesignSystem/Table/V2/Table";
import { FileInput } from "components/FileInput/FileInput";
import { Form1099 } from "components/Form1099/Form1099";
import { Cross } from "components/icons/Cross";
import { Info } from "components/icons/Info";
import { NoVendors } from "components/icons/NoVendors";
import { DocumentPreviewModal } from "components/PreviewModal";
import { FileRequestOrUpload } from "components/WformFileRequestOrUpload";
import { WSeriesFormUpload } from "components/WSeriesFormUpload";
import { AWAITING } from "constants/merchantFormStatuses";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useDocPreview } from "hooks/useDocPreview";
import { useModal } from "hooks/useModal";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import CloseIcon from "static/images/Close.svg";
import GreenTick from "static/images/GreenTick.svg";
import HourGlassTimer from "static/images/PinkHourglass.svg";
import {
  useEditVendorsMutation,
  useUploadForm1099FileMutation,
  vendorsApi,
} from "store/apis/vendors";
import { getVendorsFilterStatus } from "store/selector/vendorsFilterStatus";
import {
  closeAddModal,
  closeEditVendorModal,
  openEditVendorModal,
  setMerchantToMerge,
} from "store/slices/vendors";
import {
  CPA_REVIEWED,
  NOT_REQUIRED,
  SYSTEM_REVIEWED,
} from "types/Models/vendors";
import { FileIcon } from "utils/fileTypeIcon";
import { openLink } from "utils/openLink";
import { AddMerchant } from "./AddMerchant";
import { VendorAssign } from "./AssignVendors";
import { EditMerchant } from "./EditMerchant";
import { MarkMerchantAsVendor } from "./MarkMerchantAsVendorModal";
import { MerchantTableDropdown } from "./MerchantTableDropdown";
import { MergeMerchants } from "./MergeMerchants";
import VendorColumn from "./VendorColumn";
import { VendorsFilter } from "./VendorsFilter";
import { VendorTransactions } from "./VendorTransactions";
import { InfoFilledSmall } from "components/icons/InfoFilledSmall";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { MarkMultipleMerchantsAsRequired } from "./MarkMultipleMerchantsAsRequired";
import { taskMerchantApis } from "store/apis/taskMerchants";

export const MerchantTable = ({
  merchants,
  groupId,
  showWform,
  totalPages,
  paginationData,
  noMerchant,
  sorting,
  setSorting,
  transactionsWithoutVendorsCount,
  entityId,
  showHeader = true,
}) => {
  const { alertToast, successToast } = useToast();
  const { update } = useUpdateQuery();
  const query = useQuery();
  const page = Number(query.get("page")) || 1;
  const { filters } = useSelector(getVendorsFilterStatus);
  const selectedSeason = filters.season?.[0]?.value;
  const assignVendors = useModal();
  const [showAssignVendor, setShowAssignVendor] = useState(
    transactionsWithoutVendorsCount > 0
  );

  const [editVendors] = useEditVendorsMutation();

  const { showAddModal, showEditModal } = useSelector((state) => state.vendors);

  const {
    open: openFormUploadModal,
    close: closeFormUploadModal,
    isOpen: showFormUploadModal,
  } = useModal();

  const {
    open: openMarkAsVendorModal,
    close: closeMarkAsVendorModal,
    isOpen: showMarkAsVendorModal,
  } = useModal();
  const {
    open: openMergeMerchantsModal,
    close: closeMergeMerchantsModal,
    isOpen: showMergeMerchantsModal,
  } = useModal();
  const openPreview = useDocPreview();
  const { isCustomer, isAdmin } = useRoleBasedView();
  const [upload1099File, { isLoading: isUploading, originalArgs }] =
    useUploadForm1099FileMutation();
  const dispatch = useDispatch();
  const [editMerchantId, setEditMerchantId] = useState("");
  const [expandedRow, setExpandedRow] = useState(null);
  const [selectedVendorsForMark, setSelectedVendorsForMark] = useState([]);
  const markFormRequiredConfirmationModal = useModal();

  const handleRowClick = (rowId) => {
    setExpandedRow((prevRowId) => (prevRowId === rowId ? null : rowId));
  };

  const updateMerchantType = async (merchant_type, uuid) => {
    try {
      await editVendors({
        groupId,
        uuid,
        payload: { merchant_type, season: selectedSeason },
      }).unwrap();
      successToast({ message: "Vendor has been updated" });
    } catch (e) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  const onDrop = async ({ newFiles, form1099DoucmentType, editMerchantId }) => {
    try {
      const file = newFiles[0];
      const fileToSign = file?.uuid ? { fileId: file.uuid } : { file: file };
      await upload1099File({
        groupId: groupId,
        merchantId: editMerchantId,
        entityId,
        form1099Document: form1099DoucmentType,
        ...fileToSign,
      }).unwrap();
      successToast({ message: "File uploaded successfully" });
    } catch (error) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const openMarkAsVendor = (merchant) => {
    setEditMerchantId(merchant.uuid);
    openMarkAsVendorModal();
  };

  const editMerchant = (merchant) => {
    setEditMerchantId(merchant.uuid);
    dispatch(openEditVendorModal());
  };

  const openWFormUpload = (merchantId) => {
    openFormUploadModal();
    setEditMerchantId(merchantId);
  };

  const openFilePreview = (fileId) => {
    if (fileId) {
      openPreview(fileId);
    }
  };

  const goToFirstPage = () => {
    update({ query: "page", value: 1 });
  };

  const goToPrevPage = () => {
    const localCurrentPage = page < totalPages ? page : totalPages;
    update({ query: "page", value: localCurrentPage - 1 });
  };

  const goToNextPage = () => {
    if (page < totalPages) {
      update({ query: "page", value: page + 1 });
    }
  };

  const goToLastPage = () => {
    update({ query: "page", value: totalPages });
  };

  const getAllMerchants = () => {
    dispatch(vendorsApi.util.invalidateTags(["VENDORS"]));
    dispatch(taskMerchantApis.util.invalidateTags(["Merchants"]));
  };

  const closeAddMerchantModal = () => {
    setEditMerchantId();
    dispatch(closeAddModal());
  };

  const closeEditMerchantModal = () => {
    setEditMerchantId();
    dispatch(closeEditVendorModal());
  };
  const closeWseriesFormUpload = () => {
    closeFormUploadModal();
    setEditMerchantId();
  };

  const mergeMerchants = () => {
    const merchantsToMerge = table
      .getSelectedRowModel()
      .flatRows.map((merchant) => {
        return merchant.original.uuid;
      });
    dispatch(setMerchantToMerge(merchantsToMerge));
    openMergeMerchantsModal();
  };

  const columnHelper = createColumnHelper();

  const checkBoxColumn = showHeader
    ? [
        columnHelper.display({
          id: "select",
          size: 3,
          enableSorting: false,
          header: ({ table }) => (
            <Checkbox
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          ),
          cell: ({ row }) => (
            <Checkbox
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
              key={row.id}
              onClick={(e) => e.stopPropagation()}
            />
          ),
        }),
      ]
    : [];

  const columns = useMemo(
    () => [
      ...checkBoxColumn,
      columnHelper.accessor("logo", {
        enableSorting: false,
        cell: (info) => {
          const merchantName = info.row.original.name;
          const merchantInitialLetter = merchantName?.[0]?.toUpperCase();
          const canRedirectToTransaction =
            info.row.original.source !== "MANUAL";
          let transactionRedirectUrl = `/books/transactions?entity=${entityId}&vendors%5B0%5D=${merchantName}`;
          if (!isCustomer) {
            transactionRedirectUrl += `&company=${groupId}`;
          }

          return (
            <div className="t-flex t-gap-2 t-items-center">
              <div className="t-min-h-8 t-min-w-8 t-flex t-items-center t-justify-center t-rounded-full t-bg-purple-40">
                {info.getValue() ? (
                  <img
                    src={info.getValue()}
                    className="t-h-8 t-w-8 t-rounded-full"
                    alt="logo"
                  />
                ) : (
                  <div className="t-text-subtitle-sm t-text-surface">
                    {merchantInitialLetter}
                  </div>
                )}
              </div>
              <div>
                <div
                  className={classNames(
                    "t-text-subtext t-text-text-60 hover:t-underline hover:t-text-purple-50",
                    {
                      "t-group t-cursor-pointer": canRedirectToTransaction,
                    }
                  )}
                  onClick={(e) =>
                    canRedirectToTransaction && openLink(transactionRedirectUrl)
                  }
                >
                  {merchantName}
                </div>
                <div>
                  <VendorColumn.Email
                    info={info}
                    editMerchantId={editMerchantId}
                    setEditMerchantId={setEditMerchantId}
                  />
                </div>
              </div>
            </div>
          );
        },
        header: "VENDOR",
        size: 24,
      }),
      columnHelper.accessor("transaction_amount", {
        cell: (info) => {
          const showTransactions = info.row.original?.name === expandedRow;
          return (
            <div
              className="t-w-full t-flex t-justify-end t-text-subtext t-text-text-60 t-pr-5 t-group"
              onClick={() => handleRowClick(info.row.original?.name || "")}
            >
              <button
                className={classNames("all:unset t-text-body t-ml-auto", {
                  "hover:!t-underline group-hover:t-text-purple":
                    !showTransactions,
                  "!t-underline t-text-purple": showTransactions,
                })}
              >
                <AmountSuperScript amount={info.getValue() * -1} />
              </button>
            </div>
          );
        },
        header: (
          <div className="t-w-full t-flex t-justify-end t-gap-1">
            <span>AMOUNT PAID</span>
            <ToolTip text="Total of all payments made to the vendor during the selected season, excluding any payments made via credit card">
              <span>
                <InfoFilledSmall color="currentColor" />
              </span>
            </ToolTip>
          </div>
        ),
        size: 18,
      }),
      columnHelper.accessor("w_form_document", {
        enableSorting: false,
        cell: (info) => {
          const rowData = info.row.original;

          if (rowData.is_w_form_required === NOT_REQUIRED) {
            return (
              <Tag tagType="transparent" icon={false} rounded size="medium">
                Not required
              </Tag>
            );
          }

          if (!rowData.w_form_document) {
            return (
              <div className="t-w-4/5">
                <FileRequestOrUpload
                  merchant={rowData}
                  groupId={groupId}
                  updateMerchant={getAllMerchants}
                  editable
                  wFormStatus={rowData.form_status}
                  season={selectedSeason}
                  isSuccessPage={false}
                />
              </div>
            );
          }

          if (rowData.form_status === AWAITING) {
            return (
              <div className="t-w-4/5">
                <ToolTip text="Awaiting response">
                  <span className="t-w-full t-flex t-gap-1 t-items-center">
                    <div className="t-text-body t-text-text-30">Requested</div>
                    <img src={HourGlassTimer} alt="HourGlassTimer" />
                  </span>
                </ToolTip>
              </div>
            );
          }

          if (rowData.w_form_document) {
            return (
              <div className="t-w-4/5">
                <div
                  className="t-flex t-items-center t-gap-1 t-text-text-30"
                  role="button"
                  onClick={() => openWFormUpload(rowData.uuid)}
                >
                  <FileIcon
                    fileType={rowData?.w_form_document?.file_type}
                    width="24px"
                    height="24px"
                  />
                  <div className="t-text-caption">{rowData?.w_form_choice}</div>
                </div>
              </div>
            );
          }
        },
        header: "W FORM",
        id: "wFormDocument",
        size: 28,
      }),

      columnHelper.display({
        id: "form_1099_document?.payers_document",
        header: (
          <div className="t-text-center t-w-full t-text-30 t-text-Subtext-sm">
            1099
          </div>
        ),
        enableSorting: false,
        size: 10,
        cell: (info) => {
          const rowData = info.row.original;
          const {
            form_1099_document,
            is_w_form_required,
            w_form_choice,
            form_1099_status,
            merchant_data_w_form_map_id,
            form_1099_type,
          } = info.row.original;
          const isWFormNotRequired = is_w_form_required === NOT_REQUIRED;

          if (
            form_1099_document?.payers_document ||
            form_1099_document?.recipients_document
          ) {
            return (
              <div className="t-flex t-justify-center t-gap-4 t-items-center">
                {form_1099_document?.payers_document && (
                  <ToolTip text="Payer 1099 Form">
                    <span className="t-flex t-items-center t-gap-1">
                      <FileInput
                        file={form_1099_document?.payers_document}
                        variant="icon"
                        onFileClick={({ uuid: fileId }) =>
                          openFilePreview(fileId)
                        }
                      />
                      <span className="t-text-text-60 t-text-caption">
                        Payer
                      </span>
                    </span>
                  </ToolTip>
                )}
                {form_1099_document?.recipients_document && (
                  <ToolTip text="Recipient 1099 Form">
                    <span className="t-flex t-items-center t-gap-1">
                      <FileInput
                        file={form_1099_document?.recipients_document}
                        variant="icon"
                        onFileClick={({ uuid: fileId }) =>
                          openFilePreview(fileId)
                        }
                      />
                      <span className="t-text-text-60 t-text-caption">
                        Recipient
                      </span>
                    </span>
                  </ToolTip>
                )}
              </div>
            );
          }

          if (form_1099_status === "IN_PROGRESS") {
            return (
              <Form1099
                wFormMapId={merchant_data_w_form_map_id}
                form1099Type={form_1099_type}
                season={selectedSeason}
                entityId={entityId}
                isInprogress
              />
            );
          }

          if (rowData.w_form_choice === "W-9") {
            return (
              <Form1099
                wFormMapId={merchant_data_w_form_map_id}
                form1099Type={form_1099_type}
                season={selectedSeason}
                entityId={entityId}
              />
            );
          }

          if (
            isWFormNotRequired ||
            w_form_choice === "W-8BEN" ||
            w_form_choice === "W-8BEN-E"
          ) {
            return (
              <div className="t-text-center t-text-body t-text-text-60">
                <Tag tagType="transparent" icon={false} rounded size="medium">
                  Not required
                </Tag>
              </div>
            );
          }

          return (
            <div className="t-text-center t-text-body t-text-text-60">-</div>
          );
        },
      }),

      columnHelper.display({
        header: "",
        id: "actions",
        enableSorting: false,
        cell: (info) => {
          const isManualMerchant = info.row.original.source === "MANUAL";
          const isVendor =
            info.row.original.is_w_form_required !== "NOT_REQUIRED";
          const showOption = showWform
            ? info.row.original?.is_w_form_required === CPA_REVIEWED
            : true;

          if (!showOption) {
            return;
          }

          return (
            <div className="t-flex t-justify-end t-w-full t-bg-surface">
              <MerchantTableDropdown
                editMerchant={() => editMerchant(info.row.original)}
                openMarkAsVendor={() => openMarkAsVendor(info.row.original)}
                merchantId={info.row.original.uuid}
                season={info.row.original?.w_form_season}
                groupId={groupId}
                isManualMerchant={isManualMerchant}
                isVendor={isVendor}
                showTransactions={setExpandedRow}
                expandedRow={expandedRow}
                vendorName={info.row.original.name}
              />
            </div>
          );
        },
        size: showWform ? 10 : 25,
      }),
    ],
    [
      entityId,
      groupId,
      isCustomer,
      isUploading,
      selectedSeason,
      showWform,
      expandedRow,
    ]
  );

  const table = useReactTable({
    data: merchants,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    enableMultiSort: false,
    enableSortingRemoval: false,
    defaultColumn: {
      minSize: 1,
    },
  });

  const markFormRequired = async () => {
    const selectedMerchants = table
      .getSelectedRowModel()
      .flatRows.map((merchant) => {
        return merchant.original.uuid;
      });
    setSelectedVendorsForMark(selectedMerchants);
    markFormRequiredConfirmationModal.open();
  };

  const handleSuccess = () => {
    setSelectedVendorsForMark([]);
    table.resetRowSelection();
    markFormRequiredConfirmationModal.close();
  };

  return (
    <>
      {showHeader && (
        <>
          <div className="t-flex">
            {table?.getSelectedRowModel?.()?.flatRows.length === 0 ? (
              <VendorsFilter />
            ) : (
              <>
                <div className="t-flex t-gap-3 t-items-center t-pt-2">
                  <div className="t-flex t-text-body-sm t-text-text-30 t-items-center t-gap-2">
                    <Checkbox
                      onChange={() => table.resetRowSelection()}
                      indeterminate={
                        table.getSelectedRowModel().flatRows.length > 0
                      }
                      label={`${
                        table.getSelectedRowModel().flatRows.length
                      } Selected`}
                    />
                  </div>
                  {table?.getSelectedRowModel?.()?.flatRows.length > 1 && (
                    <Button size="small" onClick={mergeMerchants}>
                      Merge
                    </Button>
                  )}
                  {isAdmin && (
                    <Button size="small" onClick={markFormRequired}>
                      Mark form required
                    </Button>
                  )}
                </div>
              </>
            )}

            <div className="t-ml-auto">
              <Pagination
                {...paginationData}
                goToFirstPage={goToFirstPage}
                goToPrevPage={goToPrevPage}
                goToNextPage={goToNextPage}
                goToLastPage={goToLastPage}
              />
            </div>
          </div>
          {showAssignVendor && (
            <div className="t-p-3 t-rounded-lg t-border t-border-solid t-border-purple-10 t-bg-purple-0 t-flex t-items-center t-justify-between">
              <div className="t-flex t-gap-2 t-items-center">
                <span className="t-text-purple-40 t-flex">
                  <Info stroke="1.4" />
                </span>
                <div className="t-text-body-sm">
                  Vendors missing for {transactionsWithoutVendorsCount}{" "}
                  transactions , assign to ensure accurate payment calculation
                </div>
              </div>
              <div className="t-flex t-gap-3 t-items-center">
                <Button
                  size="small"
                  customType="link"
                  onClick={() => assignVendors.open()}
                >
                  Assign Vendors
                </Button>
                <Button
                  size="extra-small"
                  customType="transparent"
                  onClick={() => setShowAssignVendor(false)}
                >
                  <Cross />
                </Button>
              </div>
            </div>
          )}
        </>
      )}
      {noMerchant ? (
        <>
          <div className="t-pt-6">
            <EmptyScreen text="No vendors found">
              <NoVendors />
            </EmptyScreen>
          </div>
        </>
      ) : (
        <>
          <Table.Container
            layout="fixed"
            className={classNames("t-overflow-auto")}
          >
            <Table.Content className="t-w-full">
              <Table.Head>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Table.HeadRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Table.HeadCell
                        className={classNames(
                          "t-text-subtext-sm t-uppercase t-px-2 t-py-4 t-group t-border-0 t-border-solid t-border-neutral-30 t-border-b",
                          { "!t-border-none": header.id === "select" }
                        )}
                        onClick={header.column.getToggleSortingHandler()}
                        key={header.id}
                        style={{ width: `${header.getSize()}%` }}
                        {...(header.column.getCanSort()
                          ? { role: "button" }
                          : {})}
                      >
                        <span className="t-flex t-gap-1 t-items-center">
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                          {header.column.getCanSort() && (
                            <Table.HeadCellSort
                              nextSortOrder={header.column.getNextSortingOrder()}
                              isSorted={header.column.getIsSorted()}
                            />
                          )}
                        </span>
                      </Table.HeadCell>
                    ))}
                  </Table.HeadRow>
                ))}
              </Table.Head>
              <Table.Body>
                {table.getRowModel().rows.map((row) => (
                  <React.Fragment key={row.id}>
                    <Table.Row>
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          style={{ width: `${cell.column.getSize()}%` }}
                          className="t-p-2"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </Table.Row>
                    {expandedRow === row.original.name && (
                      <Table.Row className="!t-border-b-0">
                        <Table.Cell
                          colSpan={row.getVisibleCells().length}
                          className="t-pl-[3%] !t-pb-0"
                        >
                          <VendorTransactions
                            vendorName={expandedRow}
                            season={selectedSeason}
                            entityId={entityId}
                          />
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </React.Fragment>
                ))}
              </Table.Body>
            </Table.Content>
          </Table.Container>
          <div className="t-mt-7 t-flex t-justify-end">
            <Pagination
              {...paginationData}
              goToFirstPage={goToFirstPage}
              goToPrevPage={goToPrevPage}
              goToNextPage={goToNextPage}
              goToLastPage={goToLastPage}
            />
          </div>
        </>
      )}
      <DocumentPreviewModal />
      <WSeriesFormUpload
        show={showFormUploadModal}
        closeModal={closeWseriesFormUpload}
        groupId={groupId}
        merchant={merchants.find(({ uuid }) => uuid === editMerchantId)}
        updateMerchant={getAllMerchants}
        editable
        openModal={openFormUploadModal}
        season={selectedSeason}
      />

      <MarkMerchantAsVendor
        groupId={groupId}
        show={showMarkAsVendorModal}
        closeModal={closeMarkAsVendorModal}
        merchantId={editMerchantId}
        season={selectedSeason}
      />
      <AddMerchant
        isOpen={showAddModal}
        groupId={groupId}
        closeModal={closeAddMerchantModal}
        season={selectedSeason}
        entityId={entityId}
      />
      {showEditModal && (
        <EditMerchant
          show={showEditModal}
          groupId={groupId}
          editMerchantData={merchants.find(
            ({ uuid }) => uuid === editMerchantId
          )}
          closeModal={closeEditMerchantModal}
        />
      )}
      <MergeMerchants
        show={showMergeMerchantsModal}
        closeModal={closeMergeMerchantsModal}
        groupId={groupId}
        resetSelectedMerchant={() => table.resetRowSelection()}
        merchants={merchants}
      />
      {assignVendors.isOpen && (
        <VendorAssign {...assignVendors} season={selectedSeason} />
      )}
      <MarkMultipleMerchantsAsRequired
        isOpen={markFormRequiredConfirmationModal.isOpen}
        close={markFormRequiredConfirmationModal.close}
        entityId={entityId}
        merchantIds={selectedVendorsForMark}
        season={selectedSeason}
        onSuccess={handleSuccess}
      />
    </>
  );
};
