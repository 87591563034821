import { ConnectionsModal } from "components/Connections/ConnectionsModal";
import Modal from "components/DesignSystem/Modal/Modal";
import { LockSecure } from "components/icons/LockSecure";
import React from "react";
import { PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import { ManualBank } from "types/Models/banks";

export const BankConnectModal = ({
  isOpen,
  close,
  onConnect,
  onManualBankAddSuccess,
  onComplete,
}: {
  isOpen: boolean;
  close: () => void;
  onConnect: ({ connectionId }: { connectionId: string }) => void;
  onManualBankAddSuccess?: (bank: ManualBank) => void;
  onComplete?: (bank: PlaidLinkOnSuccessMetadata) => void;
}) => {
  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={close}>
        <Modal.Content size="large">
          <Modal.Header>
            <Modal.Title>Connect</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <ConnectionsModal
              onConnect={onConnect}
              close={close}
              onComplete={(bank) => {
                onComplete?.(bank);
                close();
              }}
              onManualBankAddSuccess={onManualBankAddSuccess}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="t-flex t-items-center t-gap-3">
              <div>
                <LockSecure />
              </div>
              <p className="t-m-0 t-text-text-30 t-text-body-sm">
                Inkle connects your account securely in compliance with industry
                standards. Inkle will only have read-only access to your
                transactions.
              </p>
            </div>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};
