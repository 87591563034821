import { Button } from "components/DesignSystem/Button/Button";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Table from "components/DesignSystem/Table/V2/Table";
import { Label, TextInput } from "components/DesignSystem/TextInput/TextInput";
import { DeleteIcon } from "components/icons/delete";
import { MagnifyingGlass } from "components/icons/MagnifyingGlass";
import { PriceInput } from "components/PriceInput/PriceInput";
import {
  MerchantComponent,
  MerchantSelector,
} from "components/Transaction/MerchantSelector";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { Field, FieldProps, useFormikContext } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import { useMemo } from "react";
import { SingleValue } from "react-select";
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { useGetMerchantByIdQuery } from "store/apis/merchant";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { useUploadManualTransactionInvoiceMutation } from "store/apis/transactions";
import { FileObject } from "types/Models/fileObject";
import { BackendError } from "types/utils/error";
import { openLink } from "utils/openLink";
import { InternalTransaction as BaseTransaction } from "./AddTransactionManuallyModal";
import { FileInput, FileType } from "components/FileInput/FileInput";
import { CategorySelector } from "components/CategorySelector/CategorySelector";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { CopyIcon } from "components/icons/CopyIcon";
import { DuplicateIcon } from "components/icons/DuplicateIcon";
import ToolTip from "components/design/toolTip";
import randomBytes from "randombytes";

type Transaction = BaseTransaction & {
  id: string;
};

const DateColumn = (info: CellContext<Transaction, string>) => {
  const { setFieldValue } = useFormikContext<{
    transactions: Transaction[];
  }>();

  const id = info.row.index;

  const onDateChange = (date: Date) => {
    setFieldValue(
      `transactions[${id}].date`,
      date ? dayjs(date).format(DD_MMM_YYYY) : null
    );
  };

  return (
    <Field name={`transactions[${id}].date`}>
      {({ field }: FieldProps) => {
        return (
          <DateInput
            hideError
            {...field}
            portalId="export_start_date"
            required
            placeholder={DD_MMM_YYYY}
            name={`transactions[${id}].date`}
            onDateChange={onDateChange}
            block
            maxDate={new Date()}
          />
        );
      }}
    </Field>
  );
};

const Vendor = (info: CellContext<Transaction, string>) => {
  const { uuid: groupId } = useCurrentGroupContext();

  const { setFieldValue } = useFormikContext<{
    transactions: Transaction[];
  }>();

  const id = info.row.index;
  const vendor = info.getValue();

  const { data, isLoading } = useGetMerchantByIdQuery(
    { groupId, merchantId: vendor || "" },
    { skip: !groupId || !vendor }
  );
  const { logo, name = "" } = data || {};

  const selected = vendor
    ? {
        value: name,
        label: <MerchantComponent logo={logo} name={name} />,
        merchantId: vendor,
        data: name,
      }
    : null;

  const merchantChange = async (values: SingleValue<any>) => {
    setFieldValue(`transactions[${id}].merchant`, values?.merchantId);
  };

  return (
    <MerchantSelector
      isLoading={isLoading}
      isDisabled={isLoading}
      merchantChange={merchantChange}
      withForm
      name={`transactions[${id}].merchant`}
      value={selected}
      creatable={false}
    />
  );
};

export const Description = (info: CellContext<BaseTransaction, string>) => {
  const id = info.row.index;

  return (
    <TextInput
      name={`transactions[${id}].description`}
      placeholder="Enter description"
      hideError
      showErrorOnceTouched
    />
  );
};

export const Amount = (info: CellContext<BaseTransaction, number>) => {
  const id = info.row.index;
  const meta = info.table.options.meta as
    | { errors: Record<string, string> }
    | undefined;

  const error = meta?.errors?.[`transactions.${id}.amount`];

  return (
    <PriceInput
      aria-label="Amount"
      placeholder="Enter amount"
      error={error}
      name={`transactions[${id}].amount`}
      rightAlign
    />
  );
};

export const Invoice = ({
  info,
  size = "md",
}: {
  info: CellContext<BaseTransaction, FileObject | null>;
  size?: "sm" | "md";
}) => {
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [getPreviewUrl] = useLazyGetPreviewUrlQuery();
  const rowId = info.row.id;
  const invoice = info.getValue();

  const [uploadManualTransactionInvoice, { isLoading: isUploading }] =
    useUploadManualTransactionInvoiceMutation();

  const { setFieldValue } = useFormikContext<{
    transactions: Transaction[];
  }>();

  const onDrop = async (files: FileType[]) => {
    try {
      const file = await uploadManualTransactionInvoice({
        invoice: files[0] as File,
        entityId,
        groupId,
      }).unwrap();
      setFieldValue(`transactions[${rowId}].invoice`, file);
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const onPreview = async ({ fileId }: { fileId: string }) => {
    try {
      const { preview_url } = await getPreviewUrl({ groupId, fileId }).unwrap();
      openLink(preview_url);
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const onInvoiceDelete = () => {
    setFieldValue(`transactions[${rowId}].invoice`, null);
  };

  return (
    <FileInput
      file={invoice}
      onDrop={onDrop}
      isUploading={isUploading}
      variant="icon"
      onFileClick={({ uuid: fileId }) => onPreview({ fileId })}
      onDelete={onInvoiceDelete}
    />
  );
};

const Delete = (info: CellContext<Transaction, unknown>) => {
  const { setValues } = useFormikContext<{
    transactions: Transaction[];
  }>();

  const onDelete = () => {
    const newValues = info.table.options.data.filter(
      (_, index) => index !== info.row.index
    );

    setValues({ transactions: newValues });
  };

  return (
    <Button
      customType="ghost_icon"
      onClick={onDelete}
      size="small"
      type="button"
    >
      <ToolTip disableHoverableContent text="Delete">
        <span className="t-text-text-30">
          <DeleteIcon stroke="1" />
        </span>
      </ToolTip>
    </Button>
  );
};

export const TransactionTable = ({
  isCSVTransactions,
}: {
  isCSVTransactions?: boolean;
}) => {
  const { setFieldValue, values } = useFormikContext<{
    transactions: Transaction[];
  }>();

  const { manualTxnInvoiceId } = useSelector(
    (state: RootState) => state.linkInkleInvoice
  );

  const addAnotherTransaction = (values: Transaction[], fromIndex?: number) => {
    const newValues = [
      ...values,
      {
        id: randomBytes(16).toString("hex"),
        date: "",
        merchant: "",
        category: "",
        description: "",
        amount: 0,
        invoice: null,
      },
    ];

    if (fromIndex !== undefined) {
      const copyFrom = values[fromIndex];

      return setFieldValue(
        "transactions",
        values.toSpliced(fromIndex + 1, 0, {
          ...copyFrom,
          // @ts-ignore // Id become never, have to check
          id: randomBytes(16).toString("hex"),
          invoice: null,
        })
      );
    }

    setFieldValue("transactions", newValues);
  };

  const createColumn = createColumnHelper<Transaction>();

  const columns = useMemo(
    () => [
      createColumn.accessor("date", {
        size: 15,
        header: () => <Label required>DATE</Label>,
        cell: DateColumn,
      }),

      createColumn.accessor("amount", {
        size: 15,
        header: () => (
          <div className="t-flex t-justify-end">
            <Label required>AMOUNT</Label>
          </div>
        ),
        // @ts-ignore
        cell: Amount,
      }),

      createColumn.accessor("description", {
        size: 20,
        header: () => <Label required>DESCRIPTION</Label>,
        // @ts-ignore // Description not accepting string | number
        cell: Description,
      }),

      createColumn.accessor("merchant", {
        size: 18.5,
        header: () => <Label>VENDOR</Label>,
        cell: Vendor,
      }),

      createColumn.accessor("category", {
        size: 18.5,
        header: () => <Label>Category</Label>,
        cell: (info) => {
          const { category = "" } = info.row.original || {};

          return (
            <CategorySelector
              category={category}
              name={`transactions[${info.row.index}].category`}
              isDisabled={Boolean(manualTxnInvoiceId)}
            />
          );
        },
      }),

      createColumn.accessor("invoice", {
        size: 5,
        header: "",
        // @ts-ignore
        cell: (info) => <Invoice info={info} />,
      }),

      createColumn.display({
        id: "copy",
        size: 5,
        header: "",
        cell: (info) => (
          <Button
            type="button"
            customType="ghost_icon"
            onClick={() =>
              addAnotherTransaction(info.table.options.data, info.row.index)
            }
            size="small"
          >
            <ToolTip disableHoverableContent text="Duplicate">
              <span className="t-text-text-30">
                <DuplicateIcon />
              </span>
            </ToolTip>
          </Button>
        ),
      }),

      createColumn.display({
        id: "delete",
        size: 5,
        header: "",
        cell: Delete,
      }),
    ],
    []
  );

  const table = useReactTable({
    data: values.transactions,
    getRowId: (row) => String(row.id!),
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
    },
  });

  const noTransaction = values.transactions.length === 0;

  return (
    <div className="t-h-full t-overflow-auto">
      {noTransaction ? (
        <EmptyScreen text="No transactions found" className="!t-h-56">
          <span className="t-text-i-neutral-10">
            <MagnifyingGlass size="149" />
          </span>
        </EmptyScreen>
      ) : (
        <div className="t-h-full">
          <div className="t-flex t-justify-between t-w-full">
            {isCSVTransactions && (
              <div className="t-text-subtitle-sm t-mt-2 t-w-full t-flex">
                Edit transactions if required
              </div>
            )}
            <div className="t-text-body t-text-text-30 t-w-full t-flex t-justify-end">
              {values.transactions?.length} transactions
            </div>
          </div>
          <div className="t-h-[280px] t-overflow-auto">
            <Table.Container>
              <Table.Content>
                <Table.Head>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <Table.Row key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <Table.HeadCell
                          key={header.id}
                          style={{ width: `${header.getSize()}%` }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </Table.HeadCell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Head>
                <Table.Body>
                  <AnimatePresence initial={false}>
                    {table.getRowModel().rows.map((row) => (
                      <motion.tr
                        layout
                        key={row.id}
                        initial={{ y: -100 }}
                        animate={{ x: 0, y: 0 }}
                        exit={{ y: -20, opacity: 0 }}
                        transition={{ ease: "easeOut", duration: 0.1 }}
                        className="t-px-3 t-border-solid t-border-neutral-0 t-border-b t-border-0 t-text-body"
                      >
                        {row.getVisibleCells().map((cell) => (
                          <Table.Cell
                            key={cell.id}
                            style={{ width: `${cell.column.getSize()}%` }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Table.Cell>
                        ))}
                      </motion.tr>
                    ))}
                  </AnimatePresence>
                </Table.Body>
              </Table.Content>
            </Table.Container>
          </div>
        </div>
      )}
      {!manualTxnInvoiceId && (
        <div className="t-mt-2 t-bg-white t-py-4 t-sticky t-bottom-0">
          <Button
            size="small"
            type="button"
            onClick={() => addAnotherTransaction(values.transactions)}
          >
            Add
            {noTransaction ? " " : " another "}
            transaction
          </Button>
        </div>
      )}
    </div>
  );
};
