import TransactionColumn from "components/Transaction/TransactionColumn";
import { TableUI } from "components/design/TableUI";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import {
  TransactionResponse,
  Transactions as TransactionsType,
} from "types/Models/books";

const columnHelper = createColumnHelper<TransactionsType>();

const columns = [
  columnHelper.accessor("transaction.date", {
    id: "DATE",
    size: 15,
    header: "Date",
    cell: (info) => (
      <div className="t-text-body t-text-text-30">
        {dayjs(info.getValue()).format(DD_MMM_YYYY)}
      </div>
    ),
  }),

  columnHelper.accessor("transaction.from", {
    id: "logo",
    size: 10,
    enableSorting: false,
    header: () => (
      <div className="t-flex t-justify-center t-w-full">Source</div>
    ),
    cell: (info) => {
      const transaction = info.row.original.transaction;

      return <TransactionColumn.Source transaction={transaction} />;
    },
  }),

  columnHelper.accessor("transaction.description", {
    id: "DESCRIPTION",
    enableSorting: false,
    size: 60,
    header: "DESCRIPTION",
    cell: (info) => {
      const description = info.getValue();
      return (
        <ConditionalToolTip condition={description}>
          <span className="t-line-clamp-1 t-text-body t-text-text-60">
            {description || "-"}
          </span>
        </ConditionalToolTip>
      );
    },
  }),

  columnHelper.accessor("transaction.amount", {
    id: "AMOUNT",
    size: 15,
    header: () => (
      <span className="t-flex t-justify-end t-ml-auto">Amount</span>
    ),
    cell: (info) => {
      const amount = info.getValue();
      const {
        transaction: { is_credit_card },
      } = info.row.original || {};

      return (
        <div className="t-text-body t-text-text-60">
          <TransactionColumn.Amount
            amount={amount}
            isCreditCard={is_credit_card}
          />
        </div>
      );
    },
  }),
];

export const Transactions = ({
  transactionData,
}: {
  transactionData: TransactionResponse;
}) => {
  const { transactions = [] } = transactionData || {};

  const table = useReactTable({
    data: transactions || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  return <TableUI layout="fixed" table={table} />;
};
