import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { DownloadIcon } from "components/icons/Download";
import { PreviewBody } from "components/PreviewModal";
import { TASK_REVIEW_AND_PAY, TASK_REVIEW_DOCUMENT } from "constants/chatType";
import { TASK_AMENDMENT_DOCUMENTS } from "constants/taskStates";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import GreyCaretRight from "static/images/GreyCaretRight.svg";
import { useGetPreviewUrlQuery } from "store/apis/previewUrl";
import {
  useGetTaskQuery,
  useMarkDocumentAsReviewMutation,
} from "store/apis/task";
import {
  closeDocumentReviewModal,
  openBalancingPaymentModal,
  openReviewConfirmModal,
} from "store/slices/reviewAndBalancePayment";
import { RootState } from "store/store";
import { useChannelStateContext, useChatContext } from "stream-chat-react";
import { FileIcon } from "utils/fileTypeIcon";
import { openLink } from "utils/openLink";
import { Channel } from "../Channel";

export const DocumentReview = () => {
  const {
    activeChannelGroupId,
    documentForReview: files = [],
    documentOperationType,
    showDocumentReviewModal,
    isDocReviewed,
    isPaymentRequired,
    amount,
    documentForReview,
    documentForReviewMessageId,
    reviewChannelId,
    reviewTaskId,
  } = useSelector((state: RootState) => state.reviewAndBalancePayment);
  const channelTaskId = useSelector(
    (state: RootState) => state.chat.channelTaskId
  );
  const { uuid: groupId } = useCurrentGroupContext();
  const [currentFileNum, setCurrentFileNum] = useState(0);
  const currentFile = files[currentFileNum];
  const { data, isLoading, isSuccess } = useGetPreviewUrlQuery(
    {
      groupId: groupId || (activeChannelGroupId as string),
      fileId: currentFile?.uuid,
    },
    { skip: !Boolean(currentFile?.uuid) || (!activeChannelGroupId && !groupId) }
  );
  const { taskId } = useParams<{ taskId: string }>();
  const { data: task } = useGetTaskQuery(
    {
      taskId: taskId || channelTaskId || reviewTaskId,
    },
    { refetchOnMountOrArgChange: true }
  );

  const { preview_url } = data || {};
  const { channel: currentChannel } = useChatContext();
  const channelId =
    currentChannel?.id || task?.channel?.channel_url! || reviewChannelId;
  const showScrollToPrev = currentFileNum !== 0;
  const showScrollToNext = currentFileNum !== files.length - 1;
  const dispatch = useDispatch();
  const [documentMarkAsReview, { isLoading: isBtnLoading }] =
    useMarkDocumentAsReviewMutation();
  const { alertToast, successToast } = useToast();

  const openConfirmModal = async () => {
    if (
      documentOperationType === TASK_REVIEW_AND_PAY ||
      documentOperationType === TASK_AMENDMENT_DOCUMENTS
    ) {
      await markDocumentAsReview();
      dispatch(closeDocumentReviewModal());
      if (isPaymentRequired && task) {
        dispatch(openBalancingPaymentModal(task?.entity.uuid));
      }
    }
    if (documentOperationType === TASK_REVIEW_DOCUMENT) {
      dispatch(closeDocumentReviewModal());
      dispatch(openReviewConfirmModal());
    }
  };

  const markDocumentAsReview = async () => {
    try {
      const documentIds = documentForReview
        .map(({ uuid }: { uuid: string }) => uuid)
        .join(",");
      await documentMarkAsReview({
        channel_id: channelId!,
        group_id: groupId || activeChannelGroupId,
        task_id: channelTaskId || taskId || reviewTaskId,
        message_id: documentForReviewMessageId,
        document_ids: documentIds,
      }).unwrap();
      successToast({ message: "Document marked as reviewed" });
    } catch (error: any) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  return (
    <Modal.Root
      open={showDocumentReviewModal}
      onOpenChange={() => dispatch(closeDocumentReviewModal())}
    >
      <Modal.Content size="xxl">
        <Modal.Header>
          <Modal.Title>Review documents</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body className="t-h-[700px] t-bg-surface-lighter-grey">
          <div className="t-flex t-h-full t-justify-between">
            {isLoading || !isSuccess ? (
              <div className="t-w-[calc(100%-376px)]">
                <Loader />
              </div>
            ) : (
              <div className="t-w-[calc(100%-376px)]">
                <div className="t-w-full t-h-6 t-mb-4 t-flex t-justify-between t-items-center">
                  <div className="t-flex t-gap-4 t-items-center">
                    <FileIcon
                      fileType={currentFile.file_type}
                      width="24px"
                      height="24px"
                    />
                    <div className="t-text-body t-text-text-100">
                      {currentFile.name}
                    </div>
                    <Button
                      customType="transparent"
                      size="small"
                      onClick={() => openLink(preview_url)}
                    >
                      <span className="t-text-text-30">
                        <DownloadIcon color="currentColor" strokeWidth="1.5" />
                      </span>
                    </Button>
                  </div>
                  <div className="t-text-body t-text-text-100">
                    {currentFileNum + 1} of {files.length} Files
                  </div>
                </div>
                <div className="t-w-full t-h-[calc(100%-30px)] t-flex t-items-center t-justify-center t-gap-4">
                  {showScrollToPrev && (
                    <Button
                      customType="ghost_icon"
                      onClick={() => setCurrentFileNum((prev) => prev - 1)}
                    >
                      <img
                        src={GreyCaretRight}
                        alt="GreyCaretRight"
                        className="t-rotate-180"
                      />
                    </Button>
                  )}
                  <PreviewBody
                    file={currentFile}
                    preview_url={preview_url!}
                    fullWidth
                  />
                  {showScrollToNext && (
                    <Button
                      customType="ghost_icon"
                      onClick={() => setCurrentFileNum((prev) => prev + 1)}
                    >
                      <img src={GreyCaretRight} alt="GreyCaretRight" />
                    </Button>
                  )}
                </div>
              </div>
            )}
            <div className="t-w-[360px]">
              <Channel
                channelId={channelId}
                Header={() => (
                  <div className="t-w-full t-h-12 t-bg-surface t-p-4 t-text-subtitle">
                    Chat
                  </div>
                )}
                messageMaxWidth="360px"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="t-flex t-justify-end t-gap-3">
            <Button
              type="button"
              onClick={() => dispatch(closeDocumentReviewModal())}
            >
              Go back
            </Button>
            <Button
              customType="primary"
              isLoading={isBtnLoading}
              disabled={isBtnLoading || isDocReviewed || !task}
              onClick={openConfirmModal}
            >
              {documentOperationType === TASK_REVIEW_AND_PAY &&
              isPaymentRequired
                ? `Mark as reviewed and pay $${amount}`
                : "Mark as reviewed"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
