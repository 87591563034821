export const AccountOverview = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <g clip-path="url(#clip0_7003_6475)">
      <path
        d="M5.25 9C7.32107 9 9 7.32107 9 5.25C9 3.17893 7.32107 1.5 5.25 1.5C3.17893 1.5 1.5 3.17893 1.5 5.25C1.5 7.32107 3.17893 9 5.25 9Z"
        stroke="#706A85"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.90173 7.90173L10.5 10.5"
        stroke="#706A85"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7003_6475">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
