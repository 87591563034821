import { Divider } from "components/design/Divider";
import { Button } from "components/DesignSystem/Button/Button";
import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import Modal from "components/DesignSystem/Modal/Modal";
import { ArrowRight } from "components/icons/ArrowRight";
import { Price } from "components/Price";
import { BOOKKEEPING } from "constants/addons";
import { Form, Formik } from "formik";
import { useAppSelector } from "hooks/useAppSelector";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useDispatch, useSelector } from "react-redux";
import { useAddProductToCartMutation } from "store/apis/products";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import {
  AddonData,
  setAddonPayload,
  setAddonType,
  setOpenCheckoutModal,
} from "store/slices/addons";
import { setPaymentTitle, setUsableCredit } from "store/slices/credit";
import { RootState } from "store/store";
import { currency } from "utils/Currency";

const BookkeepingPro = ({
  maybeAddToCart,
}: {
  maybeAddToCart?: (amount?: number) => void;
}) => {
  const { addonData } = useSelector((state: RootState) => state.addonsSlice);

  const onClose = () => {
    dispatch(setAddonType(""));
  };

  const dispatch = useDispatch();
  const { billing_cycle } = addonData || {};

  const handleAddonNext = (values: any) => {
    if (maybeAddToCart) {
      return maybeAddToCart(values?.selectedTierAmount);
    }

    dispatch(
      setAddonPayload({
        title: addonData?.subscription_name,
        amount: values?.selectedTierAmount,
        uuid: addonData?.uuid,
        type: addonData?.subscription_type,
      })
    );
    dispatch(setUsableCredit(false));
    dispatch(setPaymentTitle(addonData?.subscription_name!));
    dispatch(setOpenCheckoutModal());
    onClose();
  };

  return (
    <Formik
      initialValues={{
        selectedRange:
          `${currency({
            amount: Number(addonData?.tier_information?.[0]?.lower_range),
            maximumFractionDigits: 0,
          })} to ${currency({
            amount: Number(addonData?.tier_information?.[0]?.higher_range),
            maximumFractionDigits: 0,
          })}` || "",
        selectedTierAmount: addonData?.tier_information?.[0]?.amount || "",
      }}
      onSubmit={handleAddonNext}
    >
      {({ values, isSubmitting, setFieldValue }) => (
        <Form className="all:unset">
          <Modal.Body>
            <div className="t-flex t-flex-col t-gap-4">
              <div className="t-text-text-60 t-text-body">
                Pro subscription is based on the monthly volume of expense
                incurred (on an accrual basis). Please select a tier or ask us
                for assistance.
              </div>
              <div className="t-w-full t-flex t-gap-10 t-items-end">
                <div className="t-w-[280px]">
                  <DropDown.Root>
                    <DropDown.Trigger asChild>
                      <div className="t-flex t-flex-col t-gap-1.5">
                        <div className="t-font-sans t-text-caption t-text-neutral-80">
                          Select monthly expense volume
                        </div>
                        <div className="secondary-border t-flex t-items-center t-justify-between t-px-2 t-py-2.5 t-text-body t-font-medium t-drop-shadow-i-dropdown">
                          <>
                            <div className="t-flex t-items-center t-justify-between t-w-full">
                              <div className="t-text-body">
                                {values?.selectedRange
                                  ? values.selectedRange
                                  : "Select tier"}
                              </div>
                              <div className="t-rotate-90 group-data-state-open:-t-rotate-90">
                                <ArrowRight stroke="2.5" color="currentColor" />
                              </div>
                            </div>
                          </>
                        </div>
                      </div>
                    </DropDown.Trigger>
                    <DropDown.Portal>
                      <DropDown.Content
                        sideOffset={8}
                        side="bottom"
                        className="t-w-[280px]"
                      >
                        <div className="t-h-56 t-overflow-auto">
                          {addonData?.tier_information?.map(
                            ({
                              lower_range,
                              higher_range,
                              amount,
                            }: {
                              lower_range: number;
                              higher_range: number;
                              amount: number;
                            }) => (
                              <DropDown.Item
                                key={lower_range}
                                onSelect={() => {
                                  setFieldValue(
                                    "selectedRange",
                                    `${currency({
                                      amount: Number(lower_range),
                                      maximumFractionDigits: 0,
                                    })} to ${currency({
                                      amount: Number(higher_range),
                                      maximumFractionDigits: 0,
                                    })}`
                                  );
                                  setFieldValue("selectedTierAmount", amount);
                                }}
                                className="t-flex t-justify-between"
                              >
                                {`${currency({
                                  amount: Number(lower_range),
                                  maximumFractionDigits: 0,
                                })} to ${currency({
                                  amount: Number(higher_range),
                                  maximumFractionDigits: 0,
                                })}`}
                              </DropDown.Item>
                            )
                          )}
                        </div>
                      </DropDown.Content>
                    </DropDown.Portal>
                  </DropDown.Root>
                </div>
                <div>
                  <div className="t-text-text-30 t-text-body-sm">
                    Monthly subscription
                  </div>
                  <div className="t-text-body">
                    {values?.selectedTierAmount
                      ? `$${values?.selectedTierAmount}`
                      : "-"}
                  </div>
                </div>
              </div>
              <Divider />
              <div className="t-flex t-justify-between">
                <div className="t-text-body-sm t-text-text-30">Total</div>
                <div className="t-text-body">
                  <Price
                    size="medium"
                    cycle={billing_cycle!}
                    amount={values?.selectedTierAmount}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="t-flex t-justify-end t-gap-3">
            <Button type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button
              customType="primary"
              isLoading={isSubmitting}
              disabled={!values?.selectedTierAmount}
            >
              Next
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};

export const BooksPAYG = ({
  maybeAddToCart,
  subscriptionData,
  openCheckoutModal,
}: {
  maybeAddToCart?: () => void;
  subscriptionData?: AddonData;
  openCheckoutModal?: () => void;
}) => {
  const { addonData } = useSelector((state: RootState) => state.addonsSlice);
  const { subscription_name, amount, uuid, subscription_type, billing_cycle } =
    subscriptionData || addonData || {};

  const onClose = () => {
    dispatch(setAddonType(""));
  };

  const dispatch = useDispatch();

  const handleAddonNext = () => {
    if (maybeAddToCart) {
      return maybeAddToCart();
    }
    dispatch(
      setAddonPayload({
        title: subscription_name,
        amount: amount,
        uuid: uuid,
        type: subscription_type,
      })
    );
    dispatch(setUsableCredit(false));
    dispatch(setPaymentTitle(subscription_name!));
    dispatch(setOpenCheckoutModal());
    dispatch(setAddonType(""));
  };
  return (
    <Formik
      initialValues={{}}
      onSubmit={() =>
        Boolean(openCheckoutModal) ? openCheckoutModal?.() : handleAddonNext()
      }
    >
      {({ isSubmitting }) => (
        <Form className="all:unset">
          <Modal.Body className="t-flex t-flex-col t-gap-4">
            <div className="t-text-text-60 t-text-body">
              Pay As You Go Plan: Inkle will do monthly bookkeeping, deliver
              Annual MIS using Cash Method of accounting along with simple
              compliance monitoring.
              <br />
              <br />
              Priced at $100/year (covers 100 transactions and $1/txn after
              that).
            </div>
            <Divider />
            <div className="t-flex t-justify-between">
              <div className="t-text-body-sm t-text-text-30">Price</div>
              <div className="t-text-body">
                <Price size="medium" cycle={billing_cycle!} amount={amount} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="t-flex t-justify-end t-gap-3">
            <Button type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button customType="primary" isLoading={isSubmitting}>
              Next
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};

const BooksBOYA = ({ maybeAddToCart }: { maybeAddToCart?: () => void }) => {
  const { addonData } = useSelector((state: RootState) => state.addonsSlice);
  const { subscription_name, amount, uuid, subscription_type, billing_cycle } =
    addonData || {};

  const onClose = () => {
    dispatch(setAddonType(""));
  };

  const dispatch = useDispatch();

  const handleAddonNext = async () => {
    if (maybeAddToCart) {
      return maybeAddToCart();
    }

    dispatch(
      setAddonPayload({
        title: subscription_name,
        amount: amount,
        uuid: uuid,
        type: subscription_type,
      })
    );
    dispatch(setUsableCredit(false));
    dispatch(setPaymentTitle(subscription_name!));
    dispatch(setOpenCheckoutModal());
    dispatch(setAddonType(""));
  };
  return (
    <Formik initialValues={{}} onSubmit={handleAddonNext}>
      {({ isSubmitting }) => (
        <Form className="all:unset">
          <Modal.Body className="t-flex t-flex-col t-gap-4">
            <div className="t-text-text-60 t-text-body">
              Bring Your Own Accountant Plan: Inkle will monitor your
              bookkeeper's work and examine the bookkeeper's output financials
              every month.
              <br />
              <br />
              Inkle will also warn you of any foreign qualification
              requirements, Registered Agent registrations, Advance Tax and
              Sales Tax triggers as part of compliance monitoring.
            </div>
            <Divider />
            <div className="t-flex t-justify-between">
              <div className="t-text-body-sm t-text-text-30">Price</div>
              <div className="t-text-body">
                <Price size="medium" cycle={billing_cycle!} amount={amount} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="t-flex t-justify-end t-gap-3">
            <Button type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button customType="primary" isLoading={isSubmitting}>
              Next
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};

const PlanTypes = ({
  onClose,
  subscriptionData,
  openCheckoutModal,
}: {
  onClose: () => void;
  subscriptionData?: AddonData;
  openCheckoutModal?: () => void;
}) => {
  const group = useCurrentGroupContext();
  const { uuid: groupId } = group;
  const entityId = useCurrentEntityId();
  const { addonData } = useSelector((state: RootState) => state.addonsSlice);

  const { subscription_name, amount, uuid, subscription_type, billing_cycle } =
    subscriptionData || addonData || {};

  const { data: subscriptions } = useGetSubscriptionsQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const { bookkeeping_product_type } = subscriptionData || addonData || {};
  const dispatch = useDispatch();

  const isCartSubscriptionActive = useAppSelector(
    (state) => state.cartSubscription.isCartSubscriptionActive
  );

  const [addToCart, { isLoading: addingToCart }] =
    useAddProductToCartMutation();

  const onAddToCart = async (selectedTierAmount?: number) => {
    const subscription = subscriptions?.find((s) => s.uuid === uuid);

    if (groupId && uuid && amount && subscription) {
      await addToCart({
        groupId: groupId,
        entityId,
        payload: {
          product_id: uuid,
          product_content_type_id: subscription.product_content_type_id,
          selected_tier_amount: selectedTierAmount || amount,
        },
      });

      dispatch(setAddonType(""));
      onClose();
      return;
    }
  };

  if (bookkeeping_product_type === "BOOKS_PRO") {
    return (
      <BookkeepingPro
        maybeAddToCart={isCartSubscriptionActive ? onAddToCart : undefined}
      />
    );
  }
  if (bookkeeping_product_type === "BOOKS_PAYG") {
    return (
      <BooksPAYG
        maybeAddToCart={isCartSubscriptionActive ? onAddToCart : undefined}
        subscriptionData={subscriptionData}
        openCheckoutModal={openCheckoutModal}
      />
    );
  }

  if (bookkeeping_product_type === "BOOKS_BYOA") {
    return (
      <BooksBOYA
        maybeAddToCart={isCartSubscriptionActive ? onAddToCart : undefined}
      />
    );
  }
  return null;
};

export const BookkeepingPlan = ({
  subscriptionData,
  onClose: onCloseFromProps,
  openCheckoutModal,
}: {
  subscriptionData?: AddonData;
  onClose?: () => void;
  openCheckoutModal?: () => void;
}) => {
  const dispatch = useDispatch();
  const { addonData, addonType } = useSelector(
    (state: RootState) => state.addonsSlice
  );

  const isOpen = addonType === BOOKKEEPING || Boolean(subscriptionData);
  const { subscription_name } = subscriptionData || addonData || {};

  const onClose = () => {
    dispatch(setAddonType(""));
    onCloseFromProps?.();
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={onClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>{subscription_name}</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <PlanTypes
          onClose={onClose}
          subscriptionData={subscriptionData}
          openCheckoutModal={openCheckoutModal}
        />
      </Modal.Content>
    </Modal.Root>
  );
};
