import {
  ToggleGroup,
  ToggleGroupItem,
} from "components/DesignSystem/ToggleGroup/ToggleGroup";
import { Hash } from "components/icons/Hash";
import { Percent } from "components/icons/Percent";

export const ToggleNumbers = ({
  value,
  onValueChange,
}: {
  value: string;
  onValueChange: (v: string) => void;
}) => (
  <div className="-t-mr-2 t-py-1">
    <ToggleGroup
      value={value}
      onValueChange={(value) => {
        if (value) {
          onValueChange(value);
        }
      }}
    >
      <ToggleGroupItem color="primary" size="small" value="PERCENT">
        <Percent />
      </ToggleGroupItem>
      <ToggleGroupItem color="primary" size="small" value="ABSOLUTE">
        <Hash />
      </ToggleGroupItem>
    </ToggleGroup>
  </div>
);
