import { ConditionalLink } from "components/conditionalLink";
import { TableUI } from "components/design/TableUI";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import Table from "components/DesignSystem/Table/V2/Table";
import { FilingDueIcon } from "components/icons/FilingDueIcon";
import { SmallWarningInfo } from "components/icons/SmallWarningInfo";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useMemo } from "react";
import ReactCountryFlag from "react-country-flag";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { TaxActionItemType } from "store/apis/actionItems";
import { useGetTaskEntityListByIdsQuery } from "store/apis/task";
import { Task } from "types/Models/task";

export const FilingList = ({ item }: { item: TaxActionItemType }) => {
  const listModal = useModal();
  const { uuid: groupId, entities } = useCurrentGroupContext();

  const {
    data: taskList,
    isLoading: isTaskLoading,
    isSuccess,
  } = useGetTaskEntityListByIdsQuery(
    {
      groupId,
      taskEntityIds: item.ids.join(","),
    },
    { skip: !groupId, refetchOnMountOrArgChange: true }
  );

  const columnHelper = createColumnHelper<Task>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("title", {
        header: "Name",
        size: 35,
        cell: (info) => (
          <div className="t-text-body t-text-text-60">{info.getValue()}</div>
        ),
      }),
      columnHelper.accessor("entity.uuid", {
        header: "Entity Name",
        size: 25,
        cell: (info) => {
          const entity = entities.find(
            (entity) => entity.uuid === info.getValue()
          );
          if (!entity) return "-";

          return (
            <div className="t-flex t-gap-1.5 t-items-center">
              <div className="t-rounded-sm">
                <ReactCountryFlag
                  svg
                  countryCode={entity.country_code}
                  height={12}
                  width={12}
                />
              </div>
              <div className="t-text-body t-text-text-100 t-mt-0.5">
                {entity.name}
              </div>
            </div>
          );
        },
      }),
      columnHelper.accessor("season", {
        header: "SEASON",
        size: 20,
        cell: (info) => {
          return (
            <div className="t-text-body t-text-text-60">
              {info.getValue() || "-"}
            </div>
          );
        },
      }),
      columnHelper.accessor("uuid", {
        header: "",
        size: 20,
        cell: (info) => {
          const {
            entity: { name = "" },
          } = info.row.original;
          return (
            <ConditionalLink target="_blank" to={`/filings/${info.getValue()}`}>
              <Button size="small">Add details</Button>
            </ConditionalLink>
          );
        },
      }),
    ],
    []
  );

  const table = useReactTable({
    data: taskList || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
      maxSize: 100,
    },
  });

  return (
    <div className="t-flex t-justify-between t-items-center t-py-4 t-px-0 t-border t-border-solid t-border-neutral-0 t-border-t-0 t-border-l-0 t-border-r-0 last:t-border-b-0">
      <div className="t-flex t-gap-2 t-items-center">
        <div className="t-relative t-min-h-10 t-min-w-10 t-max-h-10 t-max-w-10 t-border t-border-solid t-border-neutral-0 t-rounded-full t-flex t-items-center t-justify-center">
          <FilingDueIcon />
          <div className="t-absolute -t-right-0.5 -t-bottom-0.5 t-text-orange">
            <SmallWarningInfo />
          </div>
        </div>
        <div className="t-text-subtext-sm t-text-text-100">
          {item.ids.length} {item.title}
        </div>
      </div>
      <Modal.Root onOpenChange={listModal.toggle} open={listModal.isOpen}>
        <Modal.Trigger asChild>
          <Button size="small">View</Button>
        </Modal.Trigger>
        <Modal.Content useCustomOverlay size="large">
          <Modal.Header>
            <Modal.Title>Filing Actions</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body className="t-mb-6">
            <Async.Root
              isLoading={isTaskLoading}
              isSuccess={isSuccess}
              isEmpty={false}
            >
              <Async.Empty>
                <></>
              </Async.Empty>
              <Async.Success>
                <TableUI table={table}>
                  {({ row }) => (
                    <Table.Row
                      key={row.id}
                      className="hover:t-bg-surface-lighter-grey"
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Table.Cell
                            key={cell.id}
                            style={{
                              width: `${cell.column.getSize()}%`,
                              padding: "12px 8px",
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Table.Cell>
                        );
                      })}
                    </Table.Row>
                  )}
                </TableUI>
              </Async.Success>
            </Async.Root>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
};
