import { ACCOUNTING_METHODS, ACTIVE, INACTIVE } from "constants/bookkeeping";
import { ReportsType } from "pages/Books/Reports/Reports";
import qs from "qs";
import { GeneralLedger } from "types/Models/books";
import { FileObject, FileUrl } from "types/Models/fileObject";
import {
  ARAgingSummary,
  InteractiveReport,
  ReportByView,
} from "types/Models/report";
import { emptyApi } from "./emptyApi";
import { Invoice } from "./invoices";
import { AgingData } from "components/Reports/AgingSummary";
import { PERIOD } from "constants/revenueMetrics";

export type Report = {
  uuid: string;
  state: typeof ACTIVE | typeof INACTIVE;
  entity: string;
  start_date: null | string;
  end_date: null | string;
  show_non_zero_accounts?: boolean;
  created_at: string;
  type: ReportsType;
  report: null | FileObject;
  closing_month?: {
    season_name: string;
    date: string;
    end_date: string;
  };
};

export type InteractiveNewReportParams = {
  reportType: string;
  accountingMethod?: string;
  startDate?: string | null;
  endDate?: string | null;
  showNonZeroAccounts?: string | null;
  action?: "DOWNLOAD_PDF" | "DOWNLOAD_EXCEL";
  comparison_report_type?: ViewByOption;
};

export type VendorSummary = {
  name: string;
  category: {
    name: string;
    category_id: string;
    period: Record<
      string,
      {
        amount: number;
        start_date: string;
        end_date: string;
      } | null
    >;
  };
};

export const interactiveReportFilterValuesToParams = (
  values: InteractiveNewReportParams
): string => {
  return qs.stringify(
    {
      report_type: values.reportType,
      start_date: values.startDate,
      end_date: values.endDate,
      accounting_method: values.accountingMethod,
      show_non_zero_accounts: values.showNonZeroAccounts,
      action: values.action,
      comparison_report_type: values.comparison_report_type,
    },
    { skipNulls: true, addQueryPrefix: true }
  );
};

export type ViewByOption = keyof typeof PERIOD;

export const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllReports: build.query<Report[], { groupId: string; entityId: string }>(
      {
        query: ({ groupId, entityId }) => {
          return {
            url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/report/`,
          };
        },
        providesTags: ["REPORTS"],
      }
    ),

    getReportById: build.query<
      Report,
      { groupId: string; entityId: string; reportId: string }
    >({
      query: ({ groupId, entityId, reportId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/report/${reportId}/`,
        };
      },
      providesTags: ["REPORTS"],
    }),

    generateReport: build.mutation<
      Report,
      {
        groupId: string;
        entityId: string;
        reportType: ReportsType;
      }
    >({
      query: ({ groupId, entityId, reportType }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/report/`,
          method: "POST",
          body: {
            report_type: reportType,
          },
        };
      },
      invalidatesTags: ["REPORTS"],
    }),

    updateReport: build.mutation<
      Report,
      {
        groupId: string;
        entityId: string;
        reportId: string;
        endDate: string;
        startDate: string;
        showNonZero: boolean;
      }
    >({
      query: ({
        groupId,
        entityId,
        reportId,
        endDate,
        startDate,
        showNonZero,
      }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/report/${reportId}/`,
          method: "PUT",
          body: {
            end_date: endDate,
            start_date: startDate,
            show_non_zero_accounts: showNonZero,
          },
        };
      },
      invalidatesTags: ["REPORTS"],
    }),

    deleteReportFile: build.mutation<
      Report,
      {
        groupId: string;
        entityId: string;
        reportId: string;
      }
    >({
      query: ({ groupId, entityId, reportId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/report/${reportId}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["REPORTS"],
    }),

    newInteractiveReport: build.query<
      InteractiveReport,
      {
        groupId: string;
        entityId: string;
      } & InteractiveNewReportParams
    >({
      query: ({ groupId, entityId, ...params }) => {
        let queryUrl = interactiveReportFilterValuesToParams(params);
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/interactive_report/new/${queryUrl}`,
        };
      },
      providesTags: (result) => (result ? ["LIVE_REPORT"] : []),
    }),

    byViewInteractiveReport: build.query<
      ReportByView,
      {
        groupId: string;
        entityId: string;
        reportType: string;
        accountingMethod?: string;
        startDate?: string | null;
        endDate?: string | null;
        showNonZeroAccounts?: string | null;
        comparison_report_type: ViewByOption;
      }
    >({
      query: ({
        groupId,
        entityId,
        reportType,
        accountingMethod,
        startDate,
        endDate,
        showNonZeroAccounts,
        comparison_report_type,
      }) => {
        let queryUrl = qs.stringify(
          {
            report_type: reportType,
            start_date: startDate,
            end_date: endDate,
            accounting_method: accountingMethod,
            show_non_zero_accounts: showNonZeroAccounts,
            comparison_report_type,
          },
          { skipNulls: true, addQueryPrefix: true }
        );

        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/interactive_report/new/${queryUrl}`,
        };
      },
      providesTags: (result) => (result ? ["LIVE_REPORT"] : []),
    }),

    getTransactionsForReport: build.query<
      Omit<GeneralLedger, "types">,
      {
        entityId: string;
        query: {
          start_date?: string | null;
          end_date?: string | null;
          report_type: string;
          category_id: string;
          accounting_method?: string | null;
        };
      }
    >({
      query: ({ entityId, query }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/report-txns/`,
          params: query,
        };
      },
      providesTags: (result) => (result ? ["BOOKS_TRANSACTIONS"] : []),
    }),

    saveInteractiveReport: build.mutation<
      InteractiveReport,
      {
        groupId: string;
        entityId: string;
        reportType: string;
        accountingMethod?: string;
        startDate?: string | null;
        endDate?: string | null;
        showNonZeroAccounts?: string | null;
        comparison_report_type: ViewByOption;
      }
    >({
      query: ({
        groupId,
        entityId,
        reportType,
        accountingMethod,
        startDate,
        endDate,
        showNonZeroAccounts,
        comparison_report_type,
      }) => {
        let queryUrl = qs.stringify(
          {
            report_type: reportType,
            accounting_method: accountingMethod,
            start_date: startDate,
            end_date: endDate,
            show_non_zero_accounts: showNonZeroAccounts,
            comparison_report_type: comparison_report_type,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/interactive_report/${queryUrl}`,
          method: "POST",
        };
      },
      invalidatesTags: (result) => (result ? ["INTERACTIVE_REPORTS"] : []),
    }),

    getAllInteractiveReports: build.query<
      { reports: InteractiveReport[] },
      { groupId: string; entityId: string }
    >({
      query: ({ groupId, entityId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/interactive_report/`,
        };
      },
      providesTags: (result) => (result ? ["INTERACTIVE_REPORTS"] : []),
    }),

    updateInteractiveReport: build.mutation<
      InteractiveReport,
      {
        groupId: string;
        entityId: string;
        reportId: string;
        payload: { report_name: string };
      }
    >({
      query: ({ groupId, entityId, reportId, payload }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/interactive_report/${reportId}/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: (result) => (result ? ["INTERACTIVE_REPORTS"] : []),
    }),

    getInteractiveReport: build.query<
      InteractiveReport | ReportByView,
      {
        groupId: string;
        entityId: string;
        reportId: string;
      }
    >({
      query: ({ groupId, entityId, reportId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/interactive_report/${reportId}/`,
        };
      },
      providesTags: (result) => (result ? ["INTERACTIVE_REPORTS"] : []),
    }),

    deleteInteractiveReport: build.mutation<
      InteractiveReport,
      {
        groupId: string;
        entityId: string;
        reportId: string;
      }
    >({
      query: ({ groupId, entityId, reportId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/interactive_report/${reportId}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result) => (result ? ["INTERACTIVE_REPORTS"] : []),
    }),

    getAgingSummary: build.query<
      { ar_aging_summary: ARAgingSummary[] },
      {
        entityId: string;
        date: string;
        accountingMethod?: string;
        daysPerAgingPeriod?: number;
        numberOfAgingPeriods?: number;
      }
    >({
      query: ({
        entityId,
        date,
        accountingMethod,
        daysPerAgingPeriod,
        numberOfAgingPeriods,
      }) => {
        let queryUrl = qs.stringify(
          {
            date: date,
            accounting_method: accountingMethod,
            days_per_aging_period: daysPerAgingPeriod,
            number_of_periods: numberOfAgingPeriods,
          },
          { skipNulls: true, addQueryPrefix: true }
        );

        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/ar-aging-summary${queryUrl}`,
          method: "GET",
        };
      },
      providesTags: (result) => (result ? ["AGING_SUMMARY"] : []),
    }),

    getAgingSummaryInvoices: build.query<
      { invoices: Invoice[] },
      { entityId: string } & AgingData
    >({
      query: ({
        entityId,
        startDate,
        endDate,
        customerId,
        currentlyDueInvoices,
      }) => {
        let queryUrl = qs.stringify(
          {
            due_after_start_date: startDate,
            due_before_end_date: endDate,
            customer_id: customerId,
            get_currently_due_invoices: currentlyDueInvoices,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/ar-aging-summary/invoices${queryUrl}`,
        };
      },
    }),

    exportAgingSummary: build.mutation<
      { document_id: string },
      {
        entityId: string;
        date: string;
        accountingMethod?: string;
        daysPerAgingPeriod?: number;
        numberOfAgingPeriods?: number;
      }
    >({
      query: ({
        entityId,
        date,
        accountingMethod,
        daysPerAgingPeriod,
        numberOfAgingPeriods,
      }) => {
        let queryUrl = qs.stringify(
          {
            date: date,
            accounting_method: accountingMethod,
            days_per_aging_period: daysPerAgingPeriod,
            number_of_periods: numberOfAgingPeriods,
          },
          { skipNulls: true, addQueryPrefix: true }
        );

        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/ar-aging-summary/export${queryUrl}`,
          method: "POST",
        };
      },
    }),
    getVendorSummary: build.query<
      VendorSummary[],
      {
        entityId: string;
        startDate: string;
        endDate: string;
        reportType: ViewByOption;
        accountingMethod?: keyof typeof ACCOUNTING_METHODS;
        vendorNames: string[];
      }
    >({
      query: ({
        entityId,
        startDate,
        endDate,
        reportType,
        accountingMethod,
        vendorNames,
      }) => {
        let queryUrl = qs.stringify(
          {
            start_date: startDate,
            end_date: endDate,
            report_type: reportType,
            accounting_method: accountingMethod,
            vendor_names: vendorNames.length > 0 ? vendorNames.join(",") : null,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/vendor_summary/${queryUrl}`,
        };
      },
      providesTags: [
        "BOOKS_TRANSACTIONS",
        "BOOKS_SINGLE_TRANSACTION",
        "GENERAL_LEDGER",
        "VENDORS",
        "TRANSACTION_MERCHANTS",
        "VENDOR_SUMMARY",
      ],
    }),
    exportVendorSummary: build.query<
      FileUrl,
      {
        entityId: string;
        startDate: string;
        endDate: string;
        reportType: ViewByOption;
        accountingMethod?: keyof typeof ACCOUNTING_METHODS;
        vendorNames: string[];
      }
    >({
      query: ({
        entityId,
        startDate,
        endDate,
        reportType,
        accountingMethod,
        vendorNames,
      }) => {
        let queryUrl = qs.stringify(
          {
            start_date: startDate,
            end_date: endDate,
            report_type: reportType,
            accounting_method: accountingMethod,
            vendor_names: vendorNames.length > 0 ? vendorNames.join(",") : null,
            report_format: "xlsx",
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/download_vendor_summary/${queryUrl}`,
        };
      },
      providesTags: ["VENDOR_SUMMARY"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllReportsQuery,
  useGenerateReportMutation,
  useGetReportByIdQuery,
  useUpdateReportMutation,
  useDeleteReportFileMutation,
  useNewInteractiveReportQuery,
  useLazyNewInteractiveReportQuery,
  useSaveInteractiveReportMutation,
  useGetAllInteractiveReportsQuery,
  useGetInteractiveReportQuery,
  useDeleteInteractiveReportMutation,
  useUpdateInteractiveReportMutation,
  useGetTransactionsForReportQuery,
  useByViewInteractiveReportQuery,
  useGetAgingSummaryQuery,
  useGetAgingSummaryInvoicesQuery,
  useExportAgingSummaryMutation,
  usePrefetch: useReportsPrefetch,
  useGetVendorSummaryQuery,
  useLazyExportVendorSummaryQuery,
} = extendedApi;
