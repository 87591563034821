import * as Popover from "@radix-ui/react-popover";
import classNames from "classnames";
import { AccordionAnimation } from "components/AccordionAnimation";
import { AddCommentPop } from "components/AddComment/AddComment";
import { AddCommentAnchor } from "components/AddCommentPopoverAnchor/AddCommentPopoverAnchor";
import { AddCommentPopoverRoot } from "components/AddCommentPopoverRoot/AddCommentPopoverRoot";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { Loader } from "components/DesignSystem/Loader/Loader";
import { GeneralLedgerTable } from "components/GeneralLedgerAccordion/GeneralLedgerAccordion";
import { ArrowRight } from "components/icons/ArrowRight";
import { CASH_FLOW_STATEMENT } from "constants/bookkeeping";
import { AnimatePresence } from "framer-motion";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import {
  HTMLAttributes,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import ThreeDots from "static/images/ThreeDots.svg";
import { useGetTransactionsForReportQuery } from "store/apis/reports";
import { Account } from "types/Models/report";
import { stopPropagation } from "utils/stopPropagation";
import {
  ReportAccordionContext,
  ReportAccordionContextType,
} from "./ReportAccordionContext";

type RootTypes = {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement> &
  ReportAccordionContextType;

const Root = ({
  maxIndent = 4,
  reportType,
  children,
  accountingMethod,
  showNonZeroAccounts,
  startDate,
  endDate,
  isLoading,
  ...props
}: RootTypes) => {
  return (
    <ReportAccordionContext.Provider
      value={{
        maxIndent,
        reportType,
        showNonZeroAccounts,
        startDate,
        endDate,
        accountingMethod,
        isLoading,
      }}
    >
      <div {...props}>{children}</div>
    </ReportAccordionContext.Provider>
  );
};

const Header = ({
  children,
  ...props
}: { children: ReactNode } & React.DetailedHTMLProps<
  HTMLAttributes<HTMLElement>,
  HTMLElement
>) => {
  return (
    <header
      {...props}
      className="t-py-3 t-text-text-30 t-text-subtext-sm t-border t-border-b t-border-solid t-border-t-0 t-border-x-0 t-border-neutral-10 t-rounded-t-lg t-flex t-justify-between t-w-full t-gap-24 t-items-center"
    >
      {children}
    </header>
  );
};

export const TransactionsTable = ({
  categoryId,
  nextLevel,
}: {
  categoryId: string;
  nextLevel: number;
}) => {
  const entityId = useCurrentEntityId();
  const { reportType, accountingMethod, startDate, endDate } = useContext(
    ReportAccordionContext
  );

  const { data: categoryLedgerInfo, isLoading } =
    useGetTransactionsForReportQuery(
      {
        entityId: entityId,
        query: {
          accounting_method: accountingMethod,
          start_date: startDate,
          end_date: endDate,
          category_id: categoryId!,
          report_type: reportType,
        },
      },
      {
        skip: !categoryId,
      }
    );

  if (isLoading) {
    return (
      <AccordionAnimation>
        <div className="t-flex t-justify-center t-h-full t-w-full t-items-center t-py-3">
          <Loader size="small" />
        </div>
      </AccordionAnimation>
    );
  }

  if (
    categoryLedgerInfo?.transactions &&
    categoryLedgerInfo?.transactions?.length === 0
  ) {
    return (
      <AccordionAnimation>
        <div className="t-flex t-justify-center t-h-full t-w-full t-items-center t-py-3 t-border-solid t-border-y t-border-0 t-border-neutral-10 t-text-body">
          No transactions found
        </div>
      </AccordionAnimation>
    );
  }

  return (
    <>
      {categoryLedgerInfo?.transactions &&
        categoryLedgerInfo?.transactions?.length > 0 && (
          <AccordionAnimation>
            <div style={{ paddingLeft: 9 * nextLevel + 26 }}>
              <div className="t-flex t-rounded t-flex-col">
                <div className="t-text-body-sm t-text-text-60 t-w-full t-flex t-justify-end t-gap-4 t-items-center hover:t-bg-surface-lighter-grey t-border t-border-t t-border-solid t-border-b-0 t-border-x-0 t-border-neutral-0 t-p-2">
                  <div className="t-bg-surface-lighter-grey t-px-1.5 t-py-0.5 t-border t-border-solid t-border-neutral-0 t-rounded">
                    {categoryLedgerInfo?.transactions_count} entries
                  </div>
                  <div>
                    Starting Balance: {"  "}
                    <AmountSuperScript
                      amount={categoryLedgerInfo?.opening_balance!}
                    />
                  </div>
                </div>
              </div>

              <GeneralLedgerTable
                tableData={categoryLedgerInfo?.transactions!}
                startDate={startDate!}
                endDate={endDate!}
                totalDebits={categoryLedgerInfo?.total_debits!}
                totalCredits={categoryLedgerInfo?.total_credits!}
              />
            </div>
          </AccordionAnimation>
        )}
    </>
  );
};

export const Category = ({
  isOpen,
  account,
  nextLevel,
  secondaryAction: action,
  primaryAction,
  openCategory,
}: {
  account: Account;
  nextLevel: number;
  secondaryAction?: (args: Account) => ReactNode;
  primaryAction?: (args: Account) => ReactNode;
  isOpen?: boolean;
  openCategory?: string | null;
}) => {
  const [accordionActive, setAccordionActive] = useState(nextLevel === 1);
  const [showTransactions, setShowTransactions] = useState(false);
  const { reportType } = useContext(ReportAccordionContext);

  const { uuid, types, name, summary_amount, is_cumulative } = account;

  useEffect(() => {
    if (isOpen !== undefined) {
      setAccordionActive(isOpen);
    }
  }, [isOpen]);

  return (
    <div
      className={classNames("t-w-full", {
        "t-border t-border-b t-border-solid t-border-t-0 t-border-x-0 t-border-neutral-0":
          nextLevel === 1,
      })}
      key={uuid}
    >
      <button
        className={classNames(
          "all:unset t-flex t-justify-between t-w-full hover:t-bg-surface-lighter-grey t-py-2 t-px-3 t-gap-24 t-items-center t-group",
          {
            "animate-transaction-row": openCategory && openCategory === uuid,
          }
        )}
        onClick={(e) => {
          e.stopPropagation();
          setAccordionActive((v) => !v);
        }}
      >
        <div
          className="t-flex t-items-center t-justify-between t-w-full t-gap-2"
          style={{ paddingLeft: types ? 8 * nextLevel : 9 * nextLevel }}
        >
          <div className="t-flex t-items-center">
            <div
              className={classNames({
                "t-block": Boolean(types),
                "t-invisible": !Boolean(types),
              })}
            >
              <div
                className={classNames(
                  "t-transform t-transition t-duration-300 t-ease-in-out",
                  { "t-rotate-90": accordionActive }
                )}
              >
                {types.length > 0 ? (
                  <ArrowRight stroke="1.5" color="currentColor" />
                ) : (
                  <div className="t-w-4"></div>
                )}
              </div>
            </div>
            <div className="t-flex t-w-full t-justify-end t-h-full t-items-center t-gap-3">
              <div
                className={classNames(
                  "t-truncate t-w-max t-text-body t-mt-0.5",
                  {
                    "t-text-text-60": showTransactions,
                  }
                )}
              >
                {name}
              </div>
              <div
                className={classNames("t-pb-1", {
                  "t-invisible": nextLevel === 1,
                  "group-hover:!t-visible t-invisible": nextLevel > 1,
                })}
              >
                {action?.(account)}
              </div>
            </div>
          </div>

          {(types.length === 0 || nextLevel > 0) && (
            <button
              className={classNames("all:unset t-text-body t-ml-auto", {
                "hover:!t-underline group-hover:t-text-purple":
                  !showTransactions,
                "!t-underline t-text-purple": showTransactions,
              })}
              onClick={() => setShowTransactions((v) => !v)}
            >
              <AmountSuperScript amount={summary_amount} />
            </button>
          )}

          <AddCommentPopoverRoot contentId={uuid}>
            <Dropdown.Root>
              <Dropdown.Trigger asChild onClick={stopPropagation}>
                <div>
                  <AddCommentAnchor contentId={uuid}>
                    <Button customType="ghost_icon" size="small">
                      <img src={ThreeDots} alt="Action" />
                    </Button>
                  </AddCommentAnchor>
                </div>
              </Dropdown.Trigger>
              <Dropdown.Portal>
                <Dropdown.Content onClick={stopPropagation} align="end">
                  <Popover.Trigger asChild>
                    <Dropdown.Item>Add comment</Dropdown.Item>
                  </Popover.Trigger>
                  <Dropdown.Item
                    onSelect={() => setShowTransactions((v) => !v)}
                  >
                    {showTransactions ? "Hide" : "Show"} transactions
                  </Dropdown.Item>
                </Dropdown.Content>
              </Dropdown.Portal>
              <Popover.Portal>
                <AddCommentPop
                  commentType="TRANSACTION_CATEGORY_COMMENT"
                  contentId={uuid}
                />
              </Popover.Portal>
            </Dropdown.Root>
          </AddCommentPopoverRoot>
        </div>
      </button>

      {showTransactions && (
        <AnimatePresence>
          <TransactionsTable categoryId={uuid} nextLevel={nextLevel} />
        </AnimatePresence>
      )}

      <AnimatePresence>
        {accordionActive && types && (
          <AccordionAnimation>
            <div
              className={classNames({
                "t-text-text-100": nextLevel === 0,
                "t-text-text-60": nextLevel === 1,
                "t-text-text-30": nextLevel >= 2,
              })}
            >
              <Trigger
                openCategory={openCategory}
                data={types}
                level={nextLevel}
                secondaryAction={action}
                primaryAction={primaryAction}
              />
            </div>
          </AccordionAnimation>
        )}
      </AnimatePresence>

      {is_cumulative && types.length > 0 && accordionActive && (
        <div
          className="t-w-full t-flex t-justify-between t-border t-border-b-0 t-border-solid t-border-t t-border-x-0 t-border-neutral-0 t-text-text-100 hover:t-bg-surface-lighter-grey t-py-2 t-px-3"
          style={{
            paddingLeft: types ? 8 * nextLevel : 9 * nextLevel,
          }}
        >
          {" "}
          <div className="t-min-w-32 t-truncate t-w-max t-text-body t-mt-0.5 t-pl-6">
            {nextLevel === 1 && reportType === CASH_FLOW_STATEMENT
              ? "Net cash provided by"
              : "Total"}{" "}
            {name}
          </div>
          <div className="t-text-body t-flex t-gap-2">
            <AmountSuperScript amount={summary_amount} />
            <div className="t-pointer-events-none t-opacity-0 t-invisible">
              <div className="t-w-8 t-h-8" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const Trigger = ({
  data,
  level = 0,
  secondaryAction,
  primaryAction,
  children,
  openCategory,
}: {
  data: Account[];
  level?: number;
  secondaryAction?: (props: Account) => ReactNode;
  primaryAction?: (props: Account) => ReactNode;
  children?: ReactNode;
  openCategory?: string | null;
}) => {
  const { maxIndent, isLoading } = useContext(ReportAccordionContext);
  const [open, setOpen] = useState<string[]>([]);

  useEffect(() => {
    const getParentTree = (
      ledger: Account[],
      selectedCategoryId: string | null,
      currentParents: string[] = []
    ): string[] | undefined => {
      for (const category of ledger) {
        if (category.uuid === selectedCategoryId) {
          return currentParents;
        }

        if (category.types) {
          const parent = getParentTree(category.types, selectedCategoryId, [
            category.uuid,
            ...currentParents,
          ]);
          if (parent?.length) {
            return parent;
          }
        }
      }
    };

    if (openCategory) {
      const parents = getParentTree(data, openCategory);
      if (parents && parents.length > 0) {
        setOpen(parents);
      }
    }
  }, [data, openCategory]);

  let nextLevel = level;
  if (maxIndent && nextLevel <= maxIndent) {
    nextLevel = nextLevel + 1;
  }

  if (!data) {
    return null;
  }

  if (isLoading) {
    return (
      <>
        {data.map(({ uuid }) => (
          <div
            className="t-w-full t-border t-border-b t-border-solid t-border-t-0 t-border-x-0 t-border-neutral-0 t-flex t-justify-between t-py-2 t-px-3 t-h-11 t-items-center"
            key={uuid}
          >
            <div className="t-w-2/4 t-bg-neutral-10 t-animate-pulse t-h-5 t-rounded"></div>
            <div className="t-w-1/6  t-bg-neutral-10 t-animate-pulse t-h-5 t-rounded"></div>
          </div>
        ))}
      </>
    );
  }

  return (
    <>
      {data?.map((account) => (
        <Category
          isOpen={open.includes(account.uuid)}
          openCategory={openCategory}
          key={account.uuid}
          account={account}
          nextLevel={nextLevel}
          secondaryAction={secondaryAction}
          primaryAction={primaryAction}
        />
      ))}
    </>
  );
};

const ReportsAccordion = {
  Root,
  Trigger,
  Header,
};

export default ReportsAccordion;
