import React from "react";

export const CartPaymentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clip-path="url(#clip0_5100_16899)">
      <path
        d="M14.6667 9.33333C14.6667 9.702 14.368 10 14 10H4.49133C4.76867 10.7827 5.51 11.3333 6.37133 11.3333H12.6667C13.0347 11.3333 13.3333 11.6313 13.3333 12C13.3333 12.3687 13.0347 12.6667 12.6667 12.6667H6.37133C4.68133 12.6667 3.258 11.4013 3.06067 9.72333L2.14333 1.92267C2.104 1.58667 1.81933 1.33333 1.48133 1.33333H0.666667C0.298667 1.33333 0 1.03533 0 0.666667C0 0.298 0.298667 0 0.666667 0H1.48133C2.49533 0 3.34933 0.759333 3.46733 1.766L3.49467 2H6C6.368 2 6.66667 2.298 6.66667 2.66667C6.66667 3.03533 6.368 3.33333 6 3.33333H3.652L4.27933 8.66667H14C14.368 8.66667 14.6667 8.96467 14.6667 9.33333ZM4.66667 13.3333C3.93 13.3333 3.33333 13.93 3.33333 14.6667C3.33333 15.4033 3.93 16 4.66667 16C5.40333 16 6 15.4033 6 14.6667C6 13.93 5.40333 13.3333 4.66667 13.3333ZM11.3333 13.3333C10.5967 13.3333 10 13.93 10 14.6667C10 15.4033 10.5967 16 11.3333 16C12.07 16 12.6667 15.4033 12.6667 14.6667C12.6667 13.93 12.07 13.3333 11.3333 13.3333ZM12.6667 3.724V2.66667C12.6667 2.298 12.368 2 12 2C11.632 2 11.3333 2.298 11.3333 2.66667V4C11.3333 4.17733 11.4033 4.34667 11.5287 4.47133L12.1953 5.138C12.3253 5.268 12.496 5.33333 12.6667 5.33333C12.8373 5.33333 13.008 5.268 13.138 5.138C13.3987 4.87733 13.3987 4.456 13.138 4.19533L12.6667 3.724ZM16 4C16 6.206 14.206 8 12 8C9.794 8 8 6.206 8 4C8 1.794 9.794 0 12 0C14.206 0 16 1.794 16 4ZM14.6667 4C14.6667 2.52933 13.4707 1.33333 12 1.33333C10.5293 1.33333 9.33333 2.52933 9.33333 4C9.33333 5.47067 10.5293 6.66667 12 6.66667C13.4707 6.66667 14.6667 5.47067 14.6667 4Z"
        fill="#706A85"
      />
    </g>
    <defs>
      <clipPath id="clip0_5100_16899">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
