import React, { useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { parse, stringify } from "qs";

export const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const useUpdateQuery = () => {
  const { search } = useLocation();
  const history = useHistory();

  return {
    update: useCallback(
      ({ query, value }) => {
        let newQuery = parse(window.location.search, {
          ignoreQueryPrefix: true,
        });
        newQuery[query] = value;

        history.replace({
          search: stringify(newQuery, { skipNulls: true }),
        });
      },
      [history]
    ),

    updateMultiple: useCallback(
      (queries) => {
        let newQuery = parse(search, { ignoreQueryPrefix: true });
        queries.forEach(({ query, value }) => {
          newQuery[query] = value;

          history.replace({
            search: stringify(newQuery, { skipNulls: true }),
          });
        });
      },
      [history, search]
    ),
  };
};
