import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { Loader } from "components/DesignSystem/Loader/Loader";
import Modal from "components/DesignSystem/Modal/Modal";
import Table from "components/DesignSystem/Table/V2/Table";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { PlusIcon } from "components/icons/PlusIcon";
import { DeleteIcon } from "components/icons/delete";
import { useFormikContext, getIn, Formik, FieldArray, Form } from "formik";
import { useToast } from "hooks/useToast";
import { useSelector, useDispatch } from "react-redux";
import {
  CellContext,
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "react-table-8.10.7";
import { actionItemsApi } from "store/apis/actionItems";
import {
  useGetAllDocumentTypesQuery,
  useGetDocumentRequestQuery,
  useCreateDocumentRequestMutation,
  useUpdateDocumentRequestMutation,
  DocumentRequestPayload,
} from "store/apis/requestDocument";
import {
  toggleDocumentRequestEdit,
  openRequestDocumentsModal,
} from "store/slices/requestDocumentsInChat";
import { RootState } from "store/store";

const DescriptionCell = (
  info: CellContext<
    {
      name: string;
      description: string;
    },
    string
  >
) => (
  <TextInput
    placeholder="Write something..."
    customSize="small"
    name={`requests.${info.row.index}.${info.column.id}`}
  />
);

const SelectorCell = (
  info: CellContext<
    {
      name: string;
      description: string;
    },
    string
  >
) => {
  const { values } = useFormikContext();
  const groupId = useSelector(
    (state: RootState) => state.requestDocumentsInChat.groupId
  );
  const { data: documentTypes } = useGetAllDocumentTypesQuery(
    {
      groupId: groupId!,
    },
    { skip: !groupId }
  );

  const value = getIn(values, `requests.${info.row.index}.${info.column.id}`);
  const label = documentTypes?.find((d) => d.uuid === value)?.name;

  return (
    <Combobox
      value={
        value && {
          value,
          label,
        }
      }
      menuPortalTarget={document.body}
      classNames={{
        /* @tw */
        menuPortal: () => "!t-z-dropdown t-pointer-events-auto",
      }}
      size="small"
      withForm
      name={`requests.${info.row.index}.${info.column.id}`}
      options={documentTypes?.map((d) => ({ label: d.name, value: d.uuid }))}
    />
  );
};

export const DocumentRequests = ({
  remove,
}: {
  remove: (index: number) => void;
}) => {
  const { values } = useFormikContext<{
    requests: { name: string; description: string }[];
  }>();
  const columnHelper = createColumnHelper<{
    name: string;
    description: string;
  }>();

  const columns = [
    columnHelper.accessor("name", {
      id: "document_type_id",
      header: "Document Name",
      cell: SelectorCell,
      size: 30,
    }),
    columnHelper.accessor("description", {
      header: "Description",
      cell: DescriptionCell,
      size: 65,
    }),
    columnHelper.display({
      id: "action",
      header: "",
      cell: (info) => (
        <div className="t-flex t-justify-end">
          <Button
            tabIndex={1}
            size="small"
            customType="ghost_icon"
            type="button"
            onClick={() => remove(info.row.index)}
          >
            <DeleteIcon />
          </Button>
        </div>
      ),
      size: 5,
    }),
  ];

  const table = useReactTable({
    data: values.requests || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 5,
      maxSize: 100,
    },
  });

  return (
    <Table.Container>
      <Table.Content>
        <Table.Head>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Table.HeadCell
                  key={header.id}
                  style={{
                    width: `${header.getSize()}%`,
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.HeadCell>
              ))}
            </Table.Row>
          ))}
        </Table.Head>
        <Table.Body>
          {table.getRowModel().rows.map((row) => (
            <Table.Row key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Table.Cell
                  key={cell.id}
                  style={{
                    width: `${cell.column.getSize()}%`,
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Content>
    </Table.Container>
  );
};

export const CreateEditMode = ({ requestId }: { requestId?: string }) => {
  const { successToast, alertToast } = useToast();

  const isOpen = useSelector(
    (state: RootState) => state.requestDocumentsInChat.isModalOpen
  );
  const channelId = useSelector(
    (state: RootState) => state.requestDocumentsInChat.channelId
  );
  const groupId = useSelector(
    (state: RootState) => state.requestDocumentsInChat.groupId
  );

  const { data: documentsRequest, isLoading } = useGetDocumentRequestQuery(
    {
      groupId: groupId!,
      requestId: requestId!,
    },
    { skip: !groupId || !requestId, refetchOnMountOrArgChange: true }
  );

  const [createDocumentRequest, { isLoading: isCreating }] =
    useCreateDocumentRequestMutation();
  const [updateDocumentRequest, { isLoading: isUpdating }] =
    useUpdateDocumentRequestMutation();

  const dispatch = useDispatch();

  const closeModal = () => {
    if (requestId) {
      return dispatch(toggleDocumentRequestEdit(false));
    }
    dispatch(
      openRequestDocumentsModal({ isModalOpen: false, channelId: null })
    );
  };

  const onUpdate = async ({
    requests,
  }: {
    requests: DocumentRequestPayload["request_data"];
  }) => {
    try {
      if (channelId && groupId && requestId) {
        await updateDocumentRequest({
          groupId: groupId,
          requestId,
          payload: {
            request_data: requests,
          },
        }).unwrap();
        successToast({ message: "Request has been edited successfully!" });
        closeModal();
        setTimeout(() => {
          dispatch(
            actionItemsApi.util.invalidateTags([{ type: "ALL_ACTION_ITEMS" }])
          );
        }, 1000);
      }
    } catch (error) {
      alertToast({ message: "Failed to edit the request!" });
    }
  };

  const onCreate = async ({
    requests,
  }: {
    requests: DocumentRequestPayload["request_data"];
  }) => {
    try {
      if (channelId && groupId) {
        await createDocumentRequest({
          groupId: groupId,
          payload: {
            channel_id: channelId,
            request_data: requests,
          },
        }).unwrap();
        successToast({ message: "Request has been sent successfully!" });
        closeModal();
        setTimeout(() => {
          dispatch(
            actionItemsApi.util.invalidateTags([{ type: "ALL_ACTION_ITEMS" }])
          );
        }, 1000);
      }
    } catch (error) {
      alertToast({ message: "Failed to send the request!" });
    }
  };

  if (isLoading) {
    return (
      <div className="t-flex t-justify-center t-items-center">
        <Loader />
      </div>
    );
  }

  return (
    <Modal.Root open={isOpen} onOpenChange={closeModal}>
      <Formik
        initialValues={{
          requests: documentsRequest?.requested_documents || [
            {
              document_type_id: "",
              description: "",
            },
          ],
        }}
        onSubmit={requestId ? onUpdate : onCreate}
      >
        {({ submitForm }) => (
          <Modal.Content size="xxl">
            <Modal.Header>
              <Modal.Title>Request Documents</Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body>
              <div>
                <Form className="t-m-0">
                  <FieldArray name="requests">
                    {(arrayHelpers) => (
                      <div className="t-flex t-flex-col t-gap-2">
                        <DocumentRequests remove={arrayHelpers.remove} />
                        <Button
                          type="button"
                          customType="icon"
                          size="small"
                          onClick={arrayHelpers.push.bind(this, {
                            document_type_id: "",
                            description: "",
                          })}
                        >
                          <PlusIcon />
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                </Form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="t-flex t-justify-end t-gap-2">
                <Button type="button" onClick={closeModal}>
                  Cancel
                </Button>
                <Button
                  onClick={submitForm}
                  customType="primary"
                  isLoading={isCreating || isUpdating}
                  disabled={isCreating || isUpdating}
                >
                  {requestId ? "Save" : "Send"}
                </Button>
              </div>
            </Modal.Footer>
          </Modal.Content>
        )}
      </Formik>
    </Modal.Root>
  );
};
