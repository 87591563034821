import * as Accordion from "@radix-ui/react-accordion";
import classNames from "classnames";
import { CaretDown } from "components/icons/CaretDown";
import React, { ReactNode } from "react";
import { InfoItem } from "../InfoItem/InfoItem";

const ItemGrid = ({ children }: { children: ReactNode }) => (
  <span className="t-grid t-gap-6 t-grid-cols-2">{children}</span>
);

const Trigger = ({
  children,
  ...props
}: { children: ReactNode } & Accordion.AccordionTriggerProps &
  React.RefAttributes<HTMLButtonElement>) => {
  return (
    <Accordion.Trigger asChild {...props}>
      <button className="all:unset t-group t-select-none t-flex t-text-subtext t-justify-between t-p-4 t-gap-2 t-items-center after:t-content-[''] after:data-state-closed:t-border-surface-transparent after:data-state-open:t-border-neutral-10 after:t-border-0 after:t-border-solid after:t-border-b after:t-block after:t-absolute after:t-bottom-0 after:t-w-[calc(100%-32px)] after:t-mx-4 after:t-right-0 t-w-full t-relative t-capitalize">
        {children}

        {!props.disabled && (
          <span className="group-data-state-open:-t-rotate-180 group-data-state-closed:t-rotate-0 t-text-neutral t-transform t-transition t-duration-300 t-ease-in-out">
            <CaretDown />
          </span>
        )}
      </button>
    </Accordion.Trigger>
  );
};

const Item = (
  props: Accordion.AccordionItemProps &
    React.RefAttributes<HTMLDivElement> & { isActive?: boolean }
) => {
  return (
    <Accordion.Item
      {...props}
      className={classNames("t-rounded-lg t-border t-border-solid", {
        "t-animate-active": props.isActive,
        "t-border-neutral-10": !props.isActive,
      })}
    />
  );
};

const Content = (
  props: Accordion.AccordionContentProps & React.RefAttributes<HTMLDivElement>
) => {
  return (
    <Accordion.Content
      {...props}
      className={classNames("t-p-4", props.className || "")}
    />
  );
};

export const SliderAccordion = {
  ...Accordion,
  Item,
  Trigger,
  Content,
  ItemGrid,
  InfoItem,
};
