import cx from "classnames";
import { Address } from "components/Address/Address";
import { Divider } from "components/design/Divider";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { VendorBill } from "types/Models/vendorBills";
import { currency } from "utils/Currency";

export const BillPreivew = ({
  bill,
  size = "large",
  scale = "normal",
}: {
  bill?: VendorBill;
  size?: "small" | "large";
  scale?: "normal" | "scaled";
}) => {
  if (!bill) {
    return null;
  }

  return (
    <div className="t-flex t-flex-col t-gap-4 t-h-full">
      <h4 className="t-text-subtitle t-text-text-100 t-m-0">Preview</h4>
      <Divider />
      <div className="t-bg-white t-rounded-md t-shadow-light-30 t-h-full">
        <div
          className={cx(
            "t-flex t-flex-col t-bg-white t-h-full t-mt-5 t-flex-1",
            {
              "t-text-[7px]": size === "small",
              "t-text-[11px]": size === "large",
            },
            {
              "t-p-8": scale === "normal",
              "t-p-12": scale === "scaled",
              "t-text-[14px]": scale === "scaled" && size === "large",
              "t-text-[9px]": scale === "scaled" && size === "small",
            }
          )}
        >
          <div
            className={cx("t-flex t-justify-between", {
              "t-mb-2": scale === "normal",
              "t-mb-4": scale === "scaled",
            })}
          >
            <div>
              <p
                className={cx("t-font-bold t-m-0", {
                  "t-text-[9px]": size === "small",
                  "t-text-[14px]": size === "large",
                  "t-text-[18px]": scale === "scaled" && size === "large",
                })}
              >
                {bill?.entity_merchant_data?.name}
              </p>
              {bill?.merchant_address && (
                <div>
                  <Address address={bill.merchant_address} />
                </div>
              )}
            </div>

            <div
              className={cx("t-flex t-flex-col t-text-right", {
                "t-gap-1": scale === "normal",
                "t-gap-2": scale === "scaled",
              })}
            >
              <span
                className={cx("t-font-bold", {
                  "t-text-body-sm": size === "small" && scale === "normal",
                  "t-text-[22px]": size === "large" && scale === "normal",
                  "t-text-[16px]": size === "small" && scale === "scaled",
                  "t-text-[32px]": size === "large" && scale === "scaled",
                })}
              >
                {bill.title}
              </span>
              <span className="t-text-text-30">#{bill.bill_number}</span>
            </div>
          </div>

          <table
            className={cx("t-table-fixed t-w-full", {
              "[&_td]:t-py-0.5 [&_td]:t-w-1/4": scale === "normal",
              "[&_td]:t-py-1 [&_td]:t-w-1/4": scale === "scaled",
            })}
          >
            <tbody>
              <tr>
                <td>Bill to:</td>
                <td></td>
                <td className="t-text-right">Date:</td>
                <td className="t-text-right t-pr-1">
                  {bill.bill_date
                    ? dayjs(bill.bill_date).format(DD_MMM_YYYY)
                    : "-"}
                </td>
              </tr>

              <tr>
                <td rowSpan={4} className="t-align-top">
                  {bill.address && (
                    <span>
                      {bill.address?.address_string &&
                        `${bill.address?.address_string},`}
                    </span>
                  )}
                </td>
                <td rowSpan={4} className="t-align-top"></td>
                {bill.due_date && (
                  <>
                    <td className="t-text-right">Due Date:</td>
                    <td className="t-text-right t-pr-1">
                      {dayjs(bill.due_date).format(DD_MMM_YYYY)}
                    </td>
                  </>
                )}
              </tr>

              <tr className="t-font-bold">
                <td className="t-text-right t-bg-neutral-0 t-rounded-l t-pr-1">
                  Balance Due:
                </td>
                <td className="t-text-right t-bg-neutral-0 t-rounded-r t-pr-1">
                  {currency({ amount: bill.due_balance || "0" })}
                </td>
              </tr>

              <tr>
                <td colSpan={4} height={scale === "scaled" ? 24 : 20}></td>
              </tr>
            </tbody>
          </table>

          <table
            className={cx("t-table-fixed t-w-full t-my-5", {
              "[&_td]:t-py-0.5": scale === "normal",
              "[&_td]:t-py-1": scale === "scaled",
            })}
          >
            <tbody>
              <tr>
                <td className="t-bg-i-primary-100 t-rounded-l t-text-surface t-w-6/12 t-pl-1">
                  Item
                </td>
                <td className="t-bg-i-primary-100 t-text-surface t-w-3/12">
                  Quantity
                </td>
                <td className="t-bg-i-primary-100 t-text-surface t-w-1/12 t-text-right">
                  Rate
                </td>
                <td className="t-bg-i-primary-100 t-text-surface t-rounded-r t-pr-1 t-w-2/12 t-text-right">
                  Amount
                </td>
              </tr>

              <tr>
                <td colSpan={4} height={8} />
              </tr>

              {bill?.invoice_items?.map((item) => (
                <tr key={item.uuid}>
                  <td className="t-pl-1">{item.description}</td>
                  <td>{item.quantity}</td>
                  <td className="t-text-right">
                    {currency({ amount: item.rate || "0" })}
                  </td>
                  <td className="t-text-right t-pr-1">
                    {currency({ amount: item.amount || "0" })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <table className="t-table-fixed [&_td]:t-py-0.5 [&_td]:t-w-1/4 t-w-full">
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td className="t-text-right">Subtotal:</td>
                <td className="t-text-right">
                  {currency({ amount: bill.sub_total || "0" })}
                </td>
              </tr>

              {Boolean(bill.discount) && (
                <tr>
                  <td></td>
                  <td></td>
                  <td className="t-text-right">
                    Discount{" "}
                    {bill.discount_type === "PERCENT" && `(${bill.discount}%)`}{" "}
                    :
                  </td>
                  <td className="t-text-right">
                    {currency({ amount: bill.discount || "0" })}
                  </td>
                </tr>
              )}

              {Boolean(bill.tax) && (
                <tr>
                  <td></td>
                  <td></td>
                  <td className="t-text-right">
                    Tax
                    {bill.tax_type === "PERCENT" && `(${bill.tax}%)`}:
                  </td>
                  <td className="t-text-right">
                    {currency({ amount: bill.tax || "0" })}
                  </td>
                </tr>
              )}

              <tr>
                <td></td>
                <td></td>
                <td className="t-text-right">Total</td>
                <td className="t-text-right">
                  {currency({ amount: bill.total || "0" })}
                </td>
              </tr>

              {Boolean(bill.paid_amount) && (
                <tr>
                  <td></td>
                  <td></td>
                  <td className="t-text-right">Amount Paid:</td>
                  <td className="t-text-right">
                    {currency({ amount: bill.paid_amount || "0" })}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {bill.payment_instructions && (
            <>
              <div
                className={cx({
                  "t-gap-1": scale === "normal",
                  "t-gap-2": scale === "scaled",
                })}
              >
                <p className="t-whitespace-pre-wrap t-m-0 t-text-caption t-font-bold">
                  Payment instructions:
                </p>
                <p className="t-m-0 t-whitespace-pre-wrap">
                  {bill.payment_instructions}
                </p>
              </div>
              <br />
            </>
          )}
          {bill.payment_link && (
            <>
              <div
                className={cx({
                  "t-gap-1": scale === "normal",
                  "t-gap-2 t-pt-6": scale === "scaled",
                })}
              >
                <p className="t-whitespace-pre-wrap t-m-0 t-text-caption t-font-bold">
                  Payment link:
                </p>
                <p className="t-whitespace-pre-wrap t-m-0">
                  {bill.payment_link}
                </p>
              </div>
              <br />
            </>
          )}
          {bill.terms && (
            <div
              className={cx({
                "t-gap-1": scale === "normal",
                "t-gap-2": scale === "scaled",
              })}
            >
              <p className="t-m-0 t-text-caption t-font-bold">Terms:</p>
              <p className="t-m-0 t-whitespace-pre-wrap">{bill.terms}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
