import { DD_MMM_YYYY, MMM_YYYY, YYYY } from "constants/date";
import { TIME_FORMAT } from "constants/date";
import { PERIOD, PERIOD_DATE_FORMAT } from "constants/revenueMetrics";
import dayjs from "dayjs";

export const formatDate = (date?: any) => {
  return date ? dayjs(date).format(DD_MMM_YYYY) : dayjs().format(DD_MMM_YYYY);
};

export const formatTime = (date?: any) => {
  return date ? dayjs(date).format(TIME_FORMAT) : dayjs().format(TIME_FORMAT);
};

export const formatTimeToMonthYear = (date?: any) => {
  return date ? dayjs(date).format(MMM_YYYY) : dayjs().format(MMM_YYYY);
};

export const formatDateToYear = (date?: any) => {
  return date ? dayjs(date).format(YYYY) : dayjs().format(YYYY);
};

export const formatDateByViewType = (
  date: string | Date,
  viewType: keyof typeof PERIOD
) => {
  switch (viewType) {
    case PERIOD.MONTHLY:
      return dayjs(date).format(PERIOD_DATE_FORMAT[viewType]);
    case PERIOD.YEARLY:
      return dayjs(date).format(PERIOD_DATE_FORMAT[viewType]);
    case PERIOD.QUARTERLY:
      return `Q${dayjs(date).format(PERIOD_DATE_FORMAT[viewType])}`;
    default:
      return dayjs(date).format(DD_MMM_YYYY);
  }
};
