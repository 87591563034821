import { ConditionalLink } from "components/conditionalLink";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Badge } from "components/design/badge";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import Slider from "components/DesignSystem/Slider/Slider";
import Table from "components/DesignSystem/Table/V2/Table";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { PdfFile } from "components/icons/PdfFile";
import { EmptyInvoiceList } from "components/Illustrations/EmptyInvoiceList";
import { InvoiceActions } from "components/InvoiceAction/InvoiceAction";
import {
  INVOICE_ADD_CLICKED,
  INVOICE_HOME_VISITED,
} from "constants/analyticsEvents";
import { DD_MMM_YYYY } from "constants/date";
import * as INVOICE_STATUSES from "constants/invoiceStatuses";
import dayjs from "dayjs";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useDocPreview } from "hooks/useDocPreview";
import { useModal } from "hooks/useModal";
import { usePageTitle } from "hooks/usePageTitle";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { SetupInoviceSetting } from "pages/Books/Invoicing/SetupInoviceSetting";
import { parse, stringify } from "qs";
import { MouseEvent, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import RecurringIcon from "static/images/Recurring.svg";
import {
  Invoice,
  useCreateEntityInvoiceMutation,
  useGetAllInvoicesQuery,
  useGetInvoiceSettingsQuery,
} from "store/apis/invoices";
import { formatDate } from "utils/formatDate";
import { InvoiceSlider } from "./InvoiceSlider";
import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import { ArrowDown } from "components/icons/ArrowDown";
import AddIcon from "static/images/PlusCircle.svg";
import { PlusIcon } from "components/icons/PlusIcon";
import { MultipleFiles } from "components/icons/MultipleFiles";
import { BulkInvoiceUpload } from "./BulkInvoiceUpload";
import Loader from "components/design/loader";

export const InvoiceConsole = ({
  due,
  paid,
  total,
}: {
  total: {
    label: string;
    amount: number;
  };
  paid: {
    label: string;
    amount: number;
  };
  due: {
    label: string;
    amount: number;
  };
}) => {
  return (
    <div className="t-flex t-gap-9 t-p-5 t-rounded t-border-solid t-border-neutral-0 t-border">
      <div className="t-flex t-gap-1 t-flex-col">
        <span className="t-text-text-30 t-text-body-sm">{total.label}</span>
        <div className="t-text-subtext t-text-text-100">
          <AmountSuperScript amount={total.amount} />
        </div>
      </div>

      <p className="t-m-0 t-text-text-100 t-text-subtext t-self-center">=</p>

      <div className="t-flex t-gap-1 t-flex-col">
        <Tag transparent tagType="green">
          <span className="t-text-text-30">{paid.label}</span>
        </Tag>
        <div className="t-text-subtext t-text-text-100 t-ml-3.5">
          <AmountSuperScript amount={paid.amount} />
        </div>
      </div>

      <p className="t-m-0 t-text-text-100 t-text-subtext t-self-center">+</p>

      <div className="t-flex t-gap-1 t-flex-col">
        <Tag transparent tagType="orange">
          <span className="t-text-text-30">{due.label}</span>
        </Tag>
        <div className="t-text-subtext t-text-text-100 t-ml-3.5">
          <AmountSuperScript amount={due.amount} />
        </div>
      </div>
    </div>
  );
};

const InvoiceNumber = (
  props: CellContext<Invoice, Invoice["invoice_number"]>
) => {
  const openPreview = useDocPreview();
  const location = useLocation();
  const history = useHistory();
  const docId = props.row.original.document?.file_id;
  const invoiceId = props.row.original.uuid;
  const invoiceNumber = props.getValue();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });

  const search = stringify(
    {
      entity: parsedSearch.entity,
      company: parsedSearch.company,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    const redirectLink = `${location.pathname}/${invoiceId}${search}`;
    history.push(redirectLink);
    e.stopPropagation();
  };

  return (
    <>
      <div className="t-flex t-items-center t-gap-1">
        <Button
          customType={invoiceNumber ? "link" : "transparent"}
          onClick={onClick}
        >
          {invoiceNumber || "-"}
        </Button>
        {docId && (
          <Button
            size="small"
            customType="ghost_icon"
            onClick={(e) => {
              e.stopPropagation();
              openPreview(docId);
            }}
          >
            <span className="t-text-text-30">
              <PdfFile />
            </span>
          </Button>
        )}
      </div>
    </>
  );
};

const createColumn = createColumnHelper<Invoice>();

export const InvoiceDateColumn = createColumn.accessor("invoice_date", {
  id: "CREATED_AT",
  header: "Invoice date",
  size: 16,
  cell: (info) => {
    const value = info.getValue();
    const invoiceRecurring = info.row.original.invoice_settings;

    return (
      <div>
        {value ? (
          <div className="t-flex t-gap-2 t-items-center">
            <ToolTip text={dayjs(value).format("MMM DD, YYYY, hh:mm A")}>
              <span className="t-text-body t-font-medium">
                {dayjs(value).format(DD_MMM_YYYY)}
              </span>
            </ToolTip>
            {invoiceRecurring?.is_recurring_enabled && (
              <ToolTip
                text={`Next invoice on ${formatDate(
                  invoiceRecurring.next_recurring_date
                )}`}
              >
                <span>
                  <img src={RecurringIcon} alt="RecurringIcon" />
                </span>
              </ToolTip>
            )}
          </div>
        ) : (
          "-"
        )}
      </div>
    );
  },
  enableSorting: false,
});

export const InvoiceNumberColumn = createColumn.accessor("invoice_number", {
  header: "Invoice Number",
  size: 16,
  enableSorting: false,
  cell: InvoiceNumber,
});

export const InvoiceTitleColumn = createColumn.accessor("title", {
  header: "Invoice Title",
  size: 20,
  enableSorting: false,
  cell: (info) => {
    return (
      <div className="t-text-body t-text-text-30">{info.getValue() || "-"}</div>
    );
  },
});

export const SentToColumn = createColumn.accessor("sent_to", {
  id: "SENT_TO",
  header: "To",
  size: 26,
  cell: (info) =>
    info.getValue() ? (
      <div>
        <div className="t-text-body t-font-medium">
          {info.getValue() || "-"}
        </div>
        <div className="t-font-medium t-text-text-30 t-text-body-sm">
          {info.row.original.customer?.email || "-"}
        </div>
      </div>
    ) : (
      <>-</>
    ),
});

export const InvoiceAmountColumn = createColumn.accessor("due_balance", {
  id: "AMOUNT",
  header: () => <p className="t-text-right t-m-0 t-w-full">Amount</p>,
  size: 16,
  cell: (info) => (
    <div className="t-text-body t-font-medium t-text-right">
      <AmountSuperScript amount={Number(info.getValue() || 0)} />
    </div>
  ),
});

export const StatusColumn = createColumn.accessor("status", {
  header: "Status",
  size: 5,
  enableSorting: false,
  cell: (info) => (
    <span className="t-flex t-text-body-sm">
      <Badge
        color={
          {
            [INVOICE_STATUSES.DRAFT]: "neutral",
            [INVOICE_STATUSES.SENT]: "light-purple",
            [INVOICE_STATUSES.PAID]: "light-green",
            [INVOICE_STATUSES.PARTIALLY_PAID]: "light-purple",
          }[info.getValue()]
        }
      >
        <span>{info.getValue()}</span>
      </Badge>
    </span>
  ),
});

export const ActionsColumn = createColumn.display({
  id: "Actions",
  enableSorting: false,
  header: "",
  size: 5,
  cell: (info) => <InvoiceActions invoice={info.row.original} />,
});

export const invoiceColumns = [
  InvoiceDateColumn,
  InvoiceNumberColumn,
  SentToColumn,
  InvoiceAmountColumn,
  StatusColumn,
  ActionsColumn,
];

export const SettingsRedirectConfirmation = ({
  isOpen,
  close,
  url,
}: {
  isOpen: boolean;
  close: () => void;
  url: string;
}) => {
  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Settings required</Modal.Title>
          <Modal.Close />
        </Modal.Header>

        <Modal.Body>
          <p>
            Invoice settings are needs to be filled before creating invoice.
          </p>
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Modal.RawClose asChild>
            <Button>Cancel</Button>
          </Modal.RawClose>
          <ConditionalLink to={`${url}`}>
            <Button customType="primary">Go to invoice settings</Button>
          </ConditionalLink>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};

export const InvoiceList = () => {
  usePageTitle("Invoicing");
  const history = useHistory();
  const { search } = useLocation();
  const { update } = useUpdateQuery();
  const { url } = useRouteMatch();
  const group = useCurrentGroupContext();
  const query = useQuery();
  const currentPage = Number(query.get("page")) || 1;
  const goToSettings = useModal();
  const entityId = useCurrentEntityId();
  const [invoiceId, setInvoiceId] = useState<string>("");
  const openBulkUpload = useModal();

  const {
    open: openSlider,
    isOpen: isOpenSlider,
    close: closeSlider,
  } = useModal();

  const [sorting, setSorting] = useState<
    {
      desc: boolean;
      id: "CREATED_AT" | "SENT_TO" | "AMOUNT";
    }[]
  >([]);

  const { data: invoiceSetting, isSuccess: settingsLoaded } =
    useGetInvoiceSettingsQuery(
      {
        groupId: group?.uuid!,
        entityId,
      },
      { skip: !group?.uuid || !entityId }
    );

  const searchTerm = query.get("search");

  const sortCol = sorting[0]?.id;
  const sortOrder = sorting[0]?.desc;

  const { data: allInvoices, isLoading } = useGetAllInvoicesQuery(
    {
      currentPage: currentPage,
      groupId: group?.uuid!,
      entityId: entityId,
      searchTerm: searchTerm,
      sortCol: sortCol,
      sortOrder: sortOrder ? ("DSC" as const) : ("ASC" as const),
    },
    {
      skip: !group?.uuid || !entityId,
    }
  );

  const [createInvoice, { isLoading: creatingInvoice }] =
    useCreateEntityInvoiceMutation();

  const { total_pages = 0, invoices } = allInvoices || {};

  const goToFirstPage = () => {
    update({ query: "page", value: 1 });
  };

  const goToPrevPage = () => {
    const localCurrentPage =
      currentPage < total_pages! ? currentPage : total_pages!;
    update({ query: "page", value: localCurrentPage - 1 });
  };

  const goToNextPage = () => {
    if (currentPage < total_pages!) {
      update({ query: "page", value: currentPage + 1 });
    }
  };

  const goToLastPage = () => {
    update({ query: "page", value: total_pages });
  };

  const data = useMemo(() => invoices || [], [invoices]);

  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent(INVOICE_HOME_VISITED);
  }, [trackEvent]);

  const table = useReactTable({
    data,
    columns: invoiceColumns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 5,
    },
    enableRowSelection: true,
    manualSorting: true,
    enableMultiSort: false,
    enableSortingRemoval: false,
    // @ts-ignore
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  const onRowClick = (invoiceId: string, status: string) => () => {
    setInvoiceId(invoiceId);
    openSlider();
  };

  const paginationData = {
    totalPage: allInvoices?.total_pages!,
    currentPage: allInvoices?.current_page!,
    itemsPerPage: allInvoices?.per_page!,
    totalItemCount: allInvoices?.total_count!,
  };

  const onCreateInvoice = async (skipSettingsCheck: boolean = false) => {
    if (!invoiceSetting && !skipSettingsCheck) {
      goToSettings.open();
      return;
    }

    trackEvent(INVOICE_ADD_CLICKED);

    if (group.uuid && invoiceSetting?.entity_id) {
      try {
        const invoice = await createInvoice({
          groupId: group?.uuid,
          entityId: invoiceSetting?.entity_id,
        }).unwrap();

        history.push(`/books/invoices-and-customers/${invoice.uuid}${search}`);
      } catch (error) {}
    }
  };

  if (settingsLoaded && !invoiceSetting) {
    return <SetupInoviceSetting />;
  }

  return (
    <>
      <div className="t-text-text-60 t-relative">
        {isLoading && (
          <div className="t-flex t-h-full t-w-full t-items-center t-justify-center t-py-9">
            <Loader />
          </div>
        )}

        {invoices && !isLoading && (
          <div className="t-flex t-gap-3 t-flex-col">
            <div className="t-flex t-gap-5 t-flex-col t-sticky t-top-0 t-z-header t-bg-white">
              <div className="t-flex t-justify-between t-gap-5">
                <div className="t-w-1/2">
                  <Search
                    value={searchTerm || ""}
                    onChange={(e) =>
                      update({ query: "search", value: e.target.value })
                    }
                    block
                  />
                </div>
                <div className="t-flex t-justify-between t-gap-5">
                  <DropDown.Root>
                    <DropDown.Trigger
                      asChild
                      className="t-group t-text-text-60"
                    >
                      <div>
                        <Button size="small" customType="primary">
                          Create invoice
                          <div className="group-data-state-open:-t-rotate-180 t-transform t-transition t-duration-300 t-ease-in-out t-text-surface">
                            <ArrowDown color="currentColor" strokeWidth="1.5" />
                          </div>
                        </Button>
                      </div>
                    </DropDown.Trigger>
                    <DropDown.Portal>
                      <DropDown.Content
                        sideOffset={8}
                        side="bottom"
                        className="t-min-w-48"
                        align="end"
                      >
                        <DropDown.Item onSelect={() => onCreateInvoice()}>
                          <div className="t-flex t-items-center t-gap-1">
                            <PlusIcon size="12" />
                            <div>Create an Invoice</div>
                          </div>
                        </DropDown.Item>
                        <DropDown.Item onSelect={openBulkUpload.open}>
                          <div className="t-flex t-items-center t-gap-1">
                            <MultipleFiles />
                            <div>Add Invoices in Bulk</div>
                          </div>
                        </DropDown.Item>
                      </DropDown.Content>
                    </DropDown.Portal>
                  </DropDown.Root>
                </div>
              </div>

              {allInvoices && (
                <InvoiceConsole
                  total={{
                    label: "Total invoiced amount",
                    amount: allInvoices.total_amount,
                  }}
                  due={{
                    label: "Due",
                    amount: allInvoices.due_amount,
                  }}
                  paid={{ label: "Paid", amount: allInvoices.paid_amount }}
                />
              )}

              <div className="t-flex t-justify-end">
                <Pagination
                  {...paginationData}
                  goToFirstPage={goToFirstPage}
                  goToPrevPage={goToPrevPage}
                  goToNextPage={goToNextPage}
                  goToLastPage={goToLastPage}
                />
              </div>
            </div>
            <Table.Container>
              <Table.Content>
                <Table.Head>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <Table.HeadRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <Table.HeadCell
                          className="t-text-subtext-sm t-uppercase t-px-2 t-py-2 t-group"
                          onClick={header.column.getToggleSortingHandler()}
                          key={header.id}
                          style={{ width: `${header.getSize()}%` }}
                          {...(header.column.getCanSort()
                            ? { role: "button" }
                            : {})}
                        >
                          <span className="t-flex t-gap-1 t-items-center">
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                            {header.column.getCanSort() && (
                              <Table.HeadCellSort
                                nextSortOrder={header.column.getNextSortingOrder()}
                                isSorted={header.column.getIsSorted()}
                              />
                            )}
                          </span>
                        </Table.HeadCell>
                      ))}
                    </Table.HeadRow>
                  ))}
                </Table.Head>
                <Table.Body>
                  {table.getRowModel().rows.map((row) => (
                    <Table.Row
                      onRowClick={onRowClick(
                        row.original.uuid,
                        row.original.status
                      )}
                      key={row.id}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <Table.Cell
                          key={cell.id}
                          style={{ width: `${cell.column.getSize()}%` }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table.Content>
            </Table.Container>

            {invoices.length === 0 && !isLoading && (
              <div className="t-flex t-flex-col t-items-center t-justify-center t-h-72 t-text-text-60 t-gap-3">
                <EmptyInvoiceList />
                {searchTerm ? (
                  <>
                    <div className="t-flex t-flex-col t-gap-2 t-justify-center t-items-center">
                      <p className="t-m-0 t-text-subtitle-sm">
                        No results found for your search
                      </p>
                      <p className="t-m-0 t-text-body-sm">
                        Try clearing the search.
                      </p>
                    </div>
                    <Button
                      onClick={() => update({ query: "search", value: "" })}
                      size="small"
                      customType="primary"
                    >
                      Clear search
                    </Button>
                  </>
                ) : (
                  <>
                    <div className="t-flex t-flex-col t-gap-2 t-justify-center t-items-center">
                      <p className="t-m-0 t-text-subtitle-sm">
                        Create your first invoice
                      </p>
                      <p className="t-m-0 t-text-body-sm">
                        Send customised invoices to your customers.
                      </p>
                    </div>
                    <Button
                      onClick={() => onCreateInvoice(true)}
                      isLoading={creatingInvoice}
                      disabled={creatingInvoice}
                      size="small"
                      customType="primary"
                    >
                      Create invoice
                    </Button>
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <SettingsRedirectConfirmation
        isOpen={goToSettings.isOpen}
        close={goToSettings.close}
        url={`${url}/settings${search}`}
      />
      {openBulkUpload.isOpen && <BulkInvoiceUpload {...openBulkUpload} />}

      <Slider.Root open={isOpenSlider} onOpenChange={closeSlider}>
        <Slider.Content open={isOpenSlider}>
          <InvoiceSlider
            invoiceId={invoiceId}
            redirectLink={`/books/invoices-and-customers/${invoiceId}${search}`}
          />
        </Slider.Content>
      </Slider.Root>
    </>
  );
};
