import { get, update } from "cypress/types/lodash";
import { emptyApi } from "./emptyApi";
import qs from "qs";
import { Vendor } from "types/Models/vendors";
import { EntityDataAttribute } from "types/Models/entity";

type FormTemplateType = {
  form_template_id: string;
  name: string;
  form_key: string;
  uuid: string;
};

type Section = {
  section_key: string;
  name: string;
  order: number;
  is_repeatable: boolean;
  show: Record<string, unknown>;
  uuid: string;
  fields: EntityDataAttribute[];
  is_collapsable?: boolean;
};

type FormTemplateResponse = {
  form_template_id: string;
  name: string;
  form_key: string;
  entity_id: string;
  content_object_id: string;
  season: string;
  template_type: string;
  uuid: string;
  sections: Section[];
};

export const Form1099Apis = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getForm1099Types: build.query<
      FormTemplateType[],
      { entityId: string; season: string }
    >({
      query: ({ entityId, season }) => {
        return {
          url: `/api/inkle/platform/entity/${entityId}/form_templates/?template_types=FORM_1099&season=${season}`,
        };
      },
    }),
    getForm1099Template: build.query<
      FormTemplateResponse,
      { entityId: string; formTemplateId: string; parentObjectId: string }
    >({
      query: ({ entityId, formTemplateId, parentObjectId }) => {
        return {
          url: `/api/inkle/platform/entity/${entityId}/form_template/${formTemplateId}/parent_object/${parentObjectId}/`,
        };
      },
      providesTags: ["FORM_1099_TEMPLATE"],
    }),
    updateForm1099Template: build.mutation<
      EntityDataAttribute,
      {
        entityId: string;
        fieldId?: string;
        value: EntityDataAttribute["value"];
      }
    >({
      query: ({ entityId, fieldId, value }) => {
        return {
          url: `/api/inkle/platform/entity/${entityId}/form_field/${fieldId}/`,
          method: "PUT",
          body: { value },
        };
      },
      invalidatesTags: ["FORM_1099_TEMPLATE"],
    }),
    submitForm1099Template: build.mutation<
      EntityDataAttribute,
      {
        formId: string;
      }
    >({
      query: ({ formId }) => {
        return {
          url: `/api/inkle/platform/form_instance/${formId}/admin/submit_form/`,
          method: "POST",
        };
      },
      invalidatesTags: ["FORM_1099_TEMPLATE", "VENDORS"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetForm1099TypesQuery,
  useGetForm1099TemplateQuery,
  useUpdateForm1099TemplateMutation,
  useSubmitForm1099TemplateMutation,
} = Form1099Apis;
