import * as Accordion from "@radix-ui/react-accordion";
import classNames from "classnames";
import { CaretDown } from "components/icons/CaretDown";
import React, { ReactNode } from "react";
import { motion } from "framer-motion";

const Trigger = ({
  children,
  ...props
}: { children: ReactNode } & Accordion.AccordionTriggerProps &
  React.RefAttributes<HTMLButtonElement>) => {
  return (
    <Accordion.Trigger asChild {...props}>
      <button className="all:unset t-group t-select-none t-flex t-text-subtext t-py-4 t-gap-2 t-items-center t-w-full hover:t-bg-neutral-0">
        <span className="group-data-state-open:-t-rotate-180 group-data-state-closed:t-rotate-0 t-text-neutral t-transform t-transition t-duration-300 t-ease-in-out ">
          <CaretDown />
        </span>
        {children}
      </button>
    </Accordion.Trigger>
  );
};

const Item = (
  props: Accordion.AccordionItemProps &
    React.RefAttributes<HTMLDivElement> & { isActive?: boolean }
) => {
  return (
    <Accordion.Item
      {...props}
      className={classNames("t-flex t-flex-col t-gap-1", props.className || "")}
    />
  );
};

const Content = (
  props: Accordion.AccordionContentProps & React.RefAttributes<HTMLDivElement>
) => {
  return (
    <Accordion.Content
      {...props}
      className={classNames(props.className || "")}
      asChild
    >
      <motion.div
        initial={{ y: -10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
      >
        {props.children}
      </motion.div>
    </Accordion.Content>
  );
};

export const CheckListAccordion = {
  ...Accordion,
  Item,
  Trigger,
  Content,
};
