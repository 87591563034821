export const SmallInfo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M6 1.125C5.03582 1.125 4.09329 1.41091 3.2916 1.94659C2.48991 2.48226 1.86507 3.24363 1.49609 4.13442C1.12711 5.02521 1.03057 6.00541 1.21867 6.95107C1.40678 7.89672 1.87108 8.76536 2.55286 9.44715C3.23464 10.1289 4.10328 10.5932 5.04894 10.7813C5.99459 10.9694 6.97479 10.8729 7.86558 10.5039C8.75637 10.1349 9.51775 9.51009 10.0534 8.7084C10.5891 7.90671 10.875 6.96418 10.875 6C10.8736 4.70749 10.3596 3.46831 9.44564 2.55436C8.5317 1.64042 7.29251 1.12636 6 1.125ZM6 10.125C5.18415 10.125 4.38663 9.88307 3.70827 9.42981C3.02992 8.97655 2.50121 8.33231 2.189 7.57857C1.87679 6.82482 1.7951 5.99542 1.95426 5.19525C2.11343 4.39508 2.5063 3.66008 3.08319 3.08318C3.66008 2.50629 4.39508 2.11342 5.19525 1.95426C5.99543 1.7951 6.82483 1.87679 7.57857 2.189C8.33232 2.50121 8.97655 3.02992 9.42981 3.70827C9.88307 4.38663 10.125 5.18415 10.125 6C10.1238 7.09364 9.68877 8.14213 8.91545 8.91545C8.14213 9.68876 7.09364 10.1238 6 10.125ZM6.75 8.25C6.75 8.34946 6.71049 8.44484 6.64017 8.51517C6.56984 8.58549 6.47446 8.625 6.375 8.625C6.17609 8.625 5.98532 8.54598 5.84467 8.40533C5.70402 8.26468 5.625 8.07391 5.625 7.875V6C5.52555 6 5.43016 5.96049 5.35984 5.89016C5.28951 5.81984 5.25 5.72446 5.25 5.625C5.25 5.52554 5.28951 5.43016 5.35984 5.35984C5.43016 5.28951 5.52555 5.25 5.625 5.25C5.82391 5.25 6.01468 5.32902 6.15533 5.46967C6.29598 5.61032 6.375 5.80109 6.375 6V7.875C6.47446 7.875 6.56984 7.91451 6.64017 7.98483C6.71049 8.05516 6.75 8.15054 6.75 8.25ZM5.25 3.9375C5.25 3.82625 5.28299 3.71749 5.3448 3.62499C5.40661 3.53249 5.49446 3.46039 5.59724 3.41782C5.70003 3.37524 5.81313 3.3641 5.92224 3.38581C6.03135 3.40751 6.13158 3.46109 6.21025 3.53975C6.28892 3.61842 6.34249 3.71865 6.36419 3.82776C6.3859 3.93688 6.37476 4.04998 6.33218 4.15276C6.28961 4.25554 6.21751 4.34339 6.12501 4.4052C6.03251 4.46701 5.92375 4.5 5.8125 4.5C5.66332 4.5 5.52024 4.44074 5.41475 4.33525C5.30927 4.22976 5.25 4.08668 5.25 3.9375Z"
        fill="currentColor"
      />
    </svg>
  );
};
