import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FINANCIAL_CLOSING_CARD_TYPE } from "constants/chatType";
import { FinancialClosingDetail } from "store/apis/financialClosing";

export type FinancialClosingState = {
  chatCardAttachment: {
    entityId: string;
    financialClosingId: string;
    card_type: keyof typeof FINANCIAL_CLOSING_CARD_TYPE | "";
    start_date: string;
    end_date: string;
    status?: FinancialClosingDetail["status"];
  };
};

const initialState: FinancialClosingState = {
  chatCardAttachment: {
    entityId: "",
    financialClosingId: "",
    card_type: "",
    start_date: "",
    end_date: "",
    status: undefined,
  },
};

export const financialClosing = createSlice({
  name: "financialClosing",
  initialState,
  reducers: {
    setChatCardAttachment: (
      state,
      action: PayloadAction<FinancialClosingState["chatCardAttachment"]>
    ) => {
      state.chatCardAttachment = action.payload;
    },
    resetChatCardAttachment: (state) => {
      state.chatCardAttachment = initialState.chatCardAttachment;
    },
  },
});

export const { setChatCardAttachment, resetChatCardAttachment } =
  financialClosing.actions;

export default financialClosing.reducer;
