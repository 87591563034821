import { Merchant, W_FORM_TYPES } from "types/Models/merchant";
import { emptyApi } from "./emptyApi";

export const taskMerchantApis = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getTaskMerchants: build.query<
      Merchant[],
      {
        taskId: string;
        groupId: string;
        season: string;
        formType?: string;
        entityId: string;
      }
    >({
      query: ({ taskId, groupId, season, formType, entityId }) => ({
        url: `/api/inkle/tasks/${taskId}/group/${groupId}/entity/${entityId}/merchants/`,
        params: {
          season,
          form_type: formType,
        },
      }),
      providesTags: ["Merchants", "TRANSACTION_MERCHANTS", "VENDORS"],
    }),
    updateTaskMerchant: build.mutation<
      Merchant,
      {
        taskId: string;
        groupId: string;
        merchantId: string;
        email?: string;
        file?: File;
        form_type?: W_FORM_TYPES;
        season: string;
      }
    >({
      query: ({
        taskId,
        groupId,
        merchantId,
        email,
        file,
        form_type,
        season,
      }) => {
        const params = {
          merchant_id: merchantId,
          email,
          file,
          form_type,
          season,
        };

        const formData = new FormData();
        Object.entries(params).forEach(
          ([key, value]) => value && formData.append(key, value)
        );

        return {
          url: `/api/inkle/tasks/${taskId}/group/${groupId}/merchants/`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: ["Merchants"],
    }),
    updateMerchantForm: build.mutation<
      Merchant,
      {
        groupId: string;
        merchantId: string;
        email?: string;
        file?: File;
        form_type?: W_FORM_TYPES;
        season: string;
      }
    >({
      query: ({ groupId, merchantId, email, file, form_type, season }) => {
        const params = {
          merchant_id: merchantId,
          email,
          file,
          form_type,
          season,
        };

        const formData = new FormData();
        Object.entries(params).forEach(
          ([key, value]) => value && formData.append(key, value)
        );

        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/merchant/`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: ["Merchants"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTaskMerchantsQuery,
  useUpdateTaskMerchantMutation,
  useUpdateMerchantFormMutation,
} = taskMerchantApis;
