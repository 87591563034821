import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Slider from "components/DesignSystem/Slider/Slider";
import { SliderAccordion } from "components/DesignSystem/SliderAccordion/SliderAccordion";
import Tab from "components/DesignSystem/Tab/Tab";
import { useState } from "react";
import { Reminder } from "store/apis/reminders";
import {
  ChatReminderDetails,
  ToggleReminderEnable,
} from "./ChatReminderSliderContent";
import { ChatTab } from "components/ChatTab/ChatTab";

type ChatAlertSliderProps = {
  selectedReminder: Reminder;
  setSelectedReminder: (val: null) => void;
  setEditReminder: (val: Reminder) => void;
  toggleReminder: (val: string) => void;
  isUpdating: boolean;
  isOpen: boolean;
  close: () => void;
  open: () => void;
};

export const ChatAlertSlider = ({
  selectedReminder,
  setSelectedReminder,
  setEditReminder,
  toggleReminder,
  isUpdating,
  isOpen,
  close,
  open,
}: ChatAlertSliderProps) => {
  const [tab, setTab] = useState("DETAILS");

  return (
    <Tab.Root
      value={tab}
      asChild
      onValueChange={(v) => setTab(v as typeof tab)}
    >
      <Slider.Root open onOpenChange={() => setSelectedReminder(null)}>
        <Slider.Content open={true} useCustomOverlay>
          <Async.Root
            {...{ isLoading: false, isSuccess: true, isEmpty: false }}
          >
            <Async.Success>
              <Slider.Header
                bottom={
                  <Tab.List className="!t-pt-0">
                    <Tab.Trigger value="DETAILS">Details</Tab.Trigger>
                    <Tab.Trigger value="CHAT">Chat</Tab.Trigger>
                  </Tab.List>
                }
              >
                <Slider.Title>Reminder Information</Slider.Title>
                <Slider.Close />
              </Slider.Header>
              <Tab.Content value="DETAILS" asChild>
                <Slider.Body>
                  <SliderAccordion.Root
                    type="multiple"
                    defaultValue={["DETAILS"]}
                    className="t-space-y-5"
                  >
                    <SliderAccordion.Item value="DETAILS">
                      <SliderAccordion.Trigger disabled>
                        <div className="t-flex t-justify-between t-w-full t-items-center">
                          Alert Details
                        </div>
                      </SliderAccordion.Trigger>
                      <SliderAccordion.Content>
                        <ChatReminderDetails
                          selectedReminder={selectedReminder.reminder_setting}
                          alertDetails={selectedReminder.reminder}
                          subscribers={selectedReminder.reminder.subscribers}
                        />
                      </SliderAccordion.Content>
                    </SliderAccordion.Item>
                  </SliderAccordion.Root>
                </Slider.Body>
              </Tab.Content>
              <Tab.Content
                value="CHAT"
                className="t-h-[calc(100%-88px)] t-relative"
              >
                <ChatTab
                  channelId={
                    selectedReminder.reminder_setting.content_details
                      ?.channel_id
                  }
                  messageId={
                    selectedReminder.reminder_setting.content_details
                      ?.message_id
                  }
                />
              </Tab.Content>
              {tab === "DETAILS" && (
                <Slider.Footer className="t-flex t-gap-1.5">
                  <Button
                    block
                    onClick={() => setEditReminder(selectedReminder)}
                  >
                    Edit Alert
                  </Button>
                  <Button customType="danger" block onClick={open}>
                    Stop Reminder
                  </Button>
                </Slider.Footer>
              )}
              <ToggleReminderEnable
                toggleReminder={() =>
                  toggleReminder(
                    selectedReminder.reminder_setting.reminder_setting_id
                  )
                }
                isUpdating={isUpdating}
                isOpen={isOpen}
                isReminderEnabled
                close={close}
              />
            </Async.Success>
          </Async.Root>
        </Slider.Content>
      </Slider.Root>
    </Tab.Root>
  );
};
