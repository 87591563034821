import qs from "qs";
import { emptyApi } from "./emptyApi";
import { JournalEntry } from "types/Models/journalEntry";
import { Pagination } from "types/Models/pagination";

export type JournalEntryParams = {
  pageNum?: number;
  searchTerm?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  sortCol?: "AMOUNT" | "DATE";
  sortOrder?: "ASC" | "DSC";
};

export const journalEntryFilterValuesToParams = (
  values: JournalEntryParams
) => {
  return qs.stringify(
    {
      page_num: values.pageNum,
      search_term: values.searchTerm,
      start_date: values.startDate,
      end_date: values.endDate,
      sort_col: values.sortCol,
      sort_order: values.sortOrder,
    },
    { skipNulls: true, addQueryPrefix: true }
  );
};

export const journalEntries = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllJournalEntries: build.query<
      { journal_entries: JournalEntry[] } & Pagination,
      {
        groupId: string;
        entityId: string;
      } & JournalEntryParams
    >({
      query: ({ groupId, entityId, ...params }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/ledger-entries/${journalEntryFilterValuesToParams(
            params
          )}`,
        };
      },
      providesTags: (result) => (result ? ["JOURNAL_ENTRIES"] : []),
    }),

    deleteJournalEntry: build.mutation<
      void,
      {
        groupId: string;
        entityId: string;
        ledgerId: string;
        event_update_type: string;
      }
    >({
      query: ({ groupId, entityId, ledgerId, event_update_type }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/ledger-entry/${ledgerId}/`,
          method: "DELETE",
          params: {
            event_update_type: event_update_type,
          },
          body: {
            event_update_type,
          },
        };
      },
      invalidatesTags: ["JOURNAL_ENTRIES"],
    }),
    uploadBulkJournalEntry: build.mutation<
      {
        bulk_file_id: string;
      },
      {
        entityId: string;
        file: File;
      }
    >({
      query: ({ entityId, file }) => {
        const formData = new FormData();
        formData.append("excel", file);

        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/bulk-ledger-entry-upload/`,
          method: "POST",
          body: formData,
        };
      },
    }),
    populateBulkJournalEntry: build.mutation<
      any,
      {
        entityId: string;
        bulkFileId: string;
      }
    >({
      query: ({ entityId, bulkFileId }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/populate-bulk-ledger-entry/`,
          method: "POST",
          body: { bulk_file_id: bulkFileId },
        };
      },
      invalidatesTags: ["JOURNAL_ENTRIES"],
    }),
  }),
});

export const {
  useGetAllJournalEntriesQuery,
  useDeleteJournalEntryMutation,
  useUploadBulkJournalEntryMutation,
  usePopulateBulkJournalEntryMutation,
} = journalEntries;
