import { useLocation, useRouteMatch } from "react-router-dom";
import PastQuotesTable from "./PastQuotesTable";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "components/DesignSystem/ToggleGroup/ToggleGroup";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { ConditionalLink } from "components/conditionalLink";
import ToolTip from "components/design/toolTip";
import { Info } from "components/icons/Info";
import { BooksQuotes } from "components/FinancialClosing/BooksQuotes";

const PastQuotes = () => {
  const { pathname, search } = useLocation();
  const { url } = useRouteMatch();
  const { link } = useConstructInternalLink();

  const value = pathname + search;
  const pastQuotes = link("/past", { includeUrl: true });
  const bookkeepingEstimates = link(url);

  return (
    <div className="t-flex t-flex-col t-gap-3">
      <div>
        <ToggleGroup value={value}>
          <ToggleGroupItem value={bookkeepingEstimates} asChild>
            <ConditionalLink
              className="t-text-text-60 t-text-nowrap"
              to={link(url)}
            >
              <span className="t-flex t-gap-1">
                <span>Bookkeeping Estimates</span>
                <ToolTip text="Estimates based on transaction data available">
                  <span className="t-text-neutral">
                    <Info stroke="0.75" />
                  </span>
                </ToolTip>
              </span>
            </ConditionalLink>
          </ToggleGroupItem>

          <ToggleGroupItem value={pastQuotes} asChild>
            <ConditionalLink
              className="t-text-text-60 t-text-nowrap"
              to={link("/past", { includeUrl: true })}
            >
              <span className="t-flex t-gap-1">
                <span>Past Quotes</span>
                <ToolTip text="Log of all past Inkle quotes">
                  <span className="t-text-neutral">
                    <Info stroke="0.75" />
                  </span>
                </ToolTip>
              </span>
            </ConditionalLink>
          </ToggleGroupItem>
        </ToggleGroup>
      </div>

      {value === pastQuotes && <PastQuotesTable />}
      {value === bookkeepingEstimates && <BooksQuotes />}
    </div>
  );
};

export default PastQuotes;
