import classNames from "classnames";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { TableUI } from "components/design/TableUI";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Chip } from "components/DesignSystem/Chips/Chips";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { Filter } from "components/DesignSystem/Filter/Filter";
import { Loader } from "components/DesignSystem/Loader/Loader";
import RadioGroup from "components/DesignSystem/RadioGroup/RadioGroup";
import Slider from "components/DesignSystem/Slider/Slider";
import Table from "components/DesignSystem/Table/V2/Table";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import {
  InvoiceAmountColumn,
  InvoiceDateColumn,
  InvoiceNumberColumn,
  InvoiceTitleColumn,
  SentToColumn,
} from "components/InvoiceList/InvoiceList";
import { InvoiceSlider } from "components/InvoiceList/InvoiceSlider";
import { YYYY_MM_DD } from "constants/date";
import { ACCOUNTING_METHODS } from "constants/bookkeeping";
import dayjs from "dayjs";
import { Field, FieldProps, Form, Formik } from "formik";
import { motion } from "framer-motion";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useFilters } from "hooks/useFilter";
import { useModal } from "hooks/useModal";
import { useQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import {
  useExportAgingSummaryMutation,
  useGetAgingSummaryInvoicesQuery,
  useGetAgingSummaryQuery,
} from "store/apis/reports";
import { ARAgingSummary } from "types/Models/report";
import { BackendError } from "types/utils/error";
import { getCommonPinningStyles } from "utils/getCommonPinningStyles";
import { openLink } from "utils/openLink";
import { pluralize } from "utils/pluralize";
import * as Yup from "yup";

export type FilterValues = {
  DATE: string;
  ACCOUNTING_METHOD: keyof typeof ACCOUNTING_METHODS;
  DAYS_PER_AGING: number;
  NUMBER_OF_PERIODS: number;
};
type FilterName = keyof FilterValues;

const Wrapper = ({
  isValid,
  value,
  name,
  updateFilter,
  children,
}: {
  value: FilterValues[FilterName];
  name: FilterName;
  updateFilter: (name: FilterName, newValue: FilterValues[FilterName]) => void;
  children: React.ReactNode;
  isValid: boolean;
}) => {
  useEffect(() => {
    if (isValid) {
      updateFilter(name, value);
    }
  }, [value, isValid]);

  return children;
};

const AgingSummaryFilter = ({
  filterValues,
  updateFilter,
}: {
  filterValues: FilterValues;
  updateFilter: (name: FilterName, newValue: FilterValues[FilterName]) => void;
}) => {
  return (
    <Formik
      initialValues={{
        DATE: filterValues.DATE,
        DAYS_PER_AGING: filterValues.DAYS_PER_AGING,
        NUMBER_OF_PERIODS: filterValues.NUMBER_OF_PERIODS,
        ACCOUNTING_METHOD: filterValues.ACCOUNTING_METHOD,
      }}
      onSubmit={() => {}}
      validationSchema={Yup.object().shape({
        DATE: Yup.date().required("Required"),
        DAYS_PER_AGING: Yup.number()
          .min(1, "Can be between 1-360 only")
          .max(360, "Can be between 1-360 only")
          .required("Required")
          .typeError("Must be a number"),
        NUMBER_OF_PERIODS: Yup.number()
          .min(1, "Can be between 1-12 only")
          .max(12, "Can be between 1-12 only")
          .required("Required")
          .typeError("Must be a number"),
      })}
      validateOnChange
      validateOnBlur
    >
      {({ values, errors, setFieldValue }) => {
        const isDaysPerAgingError = errors?.DAYS_PER_AGING;
        const isNumberOfPeriodsError = errors?.NUMBER_OF_PERIODS;

        return (
          <Filter.Root
            defaultValue="DATE"
            title="Filters"
            capsule={
              <>
                <Chip
                  filterType="DATE"
                  onClose={() => {}}
                  isActive
                  isRemovable={false}
                >
                  As of {dayjs(filterValues.DATE).format("DD MMM YYYY")}
                </Chip>

                <Chip
                  filterType="ACCOUNTING_METHOD"
                  onClose={() => {}}
                  isActive
                  isRemovable={false}
                >
                  <span className="first-letter:t-capitalize t-lowercase">
                    {filterValues.ACCOUNTING_METHOD} method
                  </span>
                </Chip>

                <Chip
                  filterType="DAYS_PER_AGING"
                  onClose={() => {}}
                  isActive
                  isRemovable={false}
                >
                  {filterValues.DAYS_PER_AGING} days/period
                </Chip>

                <Chip
                  filterType="NUMBER_OF_PERIODS"
                  onClose={() => {}}
                  isActive
                  isRemovable={false}
                >
                  {filterValues.NUMBER_OF_PERIODS} period
                </Chip>
              </>
            }
          >
            <Form>
              <Filter.Portal>
                <Filter.List>
                  <Filter.ListItem value="DATE">Date</Filter.ListItem>
                  <Filter.ListItem value="ACCOUNTING_METHOD">
                    Accounting method
                  </Filter.ListItem>
                  <Filter.ListItem value="DAYS_PER_AGING">
                    Days per aging period
                  </Filter.ListItem>
                  <Filter.ListItem value="NUMBER_OF_PERIODS">
                    Number of periods
                  </Filter.ListItem>
                </Filter.List>
                <Filter.Body value="DATE" block>
                  <Wrapper
                    isValid={true}
                    name="DATE"
                    value={values.DATE}
                    updateFilter={updateFilter}
                  >
                    <Field name="DATE">
                      {({ field }: FieldProps) => {
                        return (
                          <DateInput
                            {...field}
                            maxDate={new Date()}
                            label="As of date"
                            portalId="DATE"
                          />
                        );
                      }}
                    </Field>
                  </Wrapper>
                </Filter.Body>
                <Filter.Body value="ACCOUNTING_METHOD" block>
                  <Wrapper
                    isValid={true}
                    name="ACCOUNTING_METHOD"
                    value={values.ACCOUNTING_METHOD}
                    updateFilter={updateFilter}
                  >
                    <RadioGroup.Root
                      className="t-mb-6"
                      value={values.ACCOUNTING_METHOD}
                      onValueChange={(value) => {
                        setFieldValue("ACCOUNTING_METHOD", value);
                      }}
                    >
                      <RadioGroup.Content>
                        <RadioGroup.Item value={ACCOUNTING_METHODS.CASH}>
                          Cash
                        </RadioGroup.Item>
                        <RadioGroup.Item value={ACCOUNTING_METHODS.ACCRUAL}>
                          Accrual
                        </RadioGroup.Item>
                      </RadioGroup.Content>
                    </RadioGroup.Root>
                  </Wrapper>
                </Filter.Body>
                <Filter.Body value="DAYS_PER_AGING" block>
                  <Wrapper
                    isValid={!isDaysPerAgingError}
                    name="DAYS_PER_AGING"
                    value={values.DAYS_PER_AGING}
                    updateFilter={updateFilter}
                  >
                    <TextInput
                      label="Days per aging period"
                      max={360}
                      min={1}
                      name="DAYS_PER_AGING"
                    />
                  </Wrapper>
                </Filter.Body>
                <Filter.Body value="NUMBER_OF_PERIODS" block>
                  <Wrapper
                    isValid={!isNumberOfPeriodsError}
                    name="NUMBER_OF_PERIODS"
                    value={values.NUMBER_OF_PERIODS}
                    updateFilter={updateFilter}
                  >
                    <TextInput
                      label="Number of periods"
                      max={12}
                      min={1}
                      name="NUMBER_OF_PERIODS"
                    />
                  </Wrapper>
                </Filter.Body>
              </Filter.Portal>
            </Form>
          </Filter.Root>
        );
      }}
    </Formik>
  );
};

export type AgingData = {
  customerId: string;
  startDate: string;
  endDate: string;
  currentlyDueInvoices: boolean;
};

export const InvoiceTable = ({
  customerId,
  startDate,
  endDate,
  currentlyDueInvoices,
}: AgingData) => {
  const { url, path } = useRouteMatch();
  const entityId = useCurrentEntityId();
  const [invoiceId, setInvoiceId] = useState<string>("");
  const invoiceSlider = useModal();
  const { search } = useLocation();

  const { data, isLoading, isFetching, isSuccess } =
    useGetAgingSummaryInvoicesQuery(
      {
        entityId,
        customerId,
        startDate,
        endDate,
        currentlyDueInvoices,
      },
      { skip: !customerId }
    );

  const { invoices = [] } = data || {};

  const invoiceColumns = [
    InvoiceDateColumn,
    InvoiceNumberColumn,
    InvoiceTitleColumn,
    SentToColumn,
    InvoiceAmountColumn,
  ];

  const table = useReactTable({
    data: invoices,
    columns: invoiceColumns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 5,
    },
  });

  return (
    <tr
      className="t-w-full t-z-filter-head t-overscroll-x-none"
      style={{ height: `${50 * (invoices?.length + 1) + 30}px ` }}
    >
      {isLoading ? (
        <td className="t-w-full" colSpan={6}>
          <div className="t-w-full t-flex t-justify-center">
            <Loader size="small" />
          </div>
        </td>
      ) : (
        <td className="t-absolute">
          <div className="t-py-0.5 t-w-full">
            {invoices.length === 0 ? (
              <div className="t-w-full t-flex t-justify-center t-text-body-sm">
                No invoices found
              </div>
            ) : (
              <div className="t-shadow-light-30 t-rounded t-flex-col t-gap-2">
                <div className="t-flex t-justify-end t-text-body-sm t-text-text-60 t-py-2 t-pr-2.5">
                  {pluralize(invoices.length, "invoice", "invoices")}
                </div>
                <TableUI
                  layout="fixed"
                  table={table}
                  onRowClick={(row) => {
                    setInvoiceId(row.original.uuid);
                    invoiceSlider.open();
                  }}
                />
                <Slider.Root
                  open={invoiceSlider.isOpen}
                  onOpenChange={invoiceSlider.close}
                >
                  <Slider.Content open={invoiceSlider.isOpen}>
                    <InvoiceSlider
                      invoiceId={invoiceId}
                      redirectLink={`books/invoices-and-customers/${invoiceId}${search}`}
                    />
                  </Slider.Content>
                </Slider.Root>
              </div>
            )}
          </div>
        </td>
      )}
    </tr>
  );
};

export const AgingSumarySaveExport = ({
  filterValues,
}: {
  filterValues: FilterValues;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { alertToast, successToast } = useToast();

  const [getPreviewUrl, { isLoading: isPreviewLoading }] =
    useLazyGetPreviewUrlQuery();
  const [exportAgingSummary, { isLoading }] = useExportAgingSummaryMutation();

  const onExport = async () => {
    try {
      const { document_id } = await exportAgingSummary({
        entityId,
        date: filterValues.DATE,
        accountingMethod: filterValues.ACCOUNTING_METHOD,
        daysPerAgingPeriod: filterValues.DAYS_PER_AGING,
        numberOfAgingPeriods: filterValues.NUMBER_OF_PERIODS,
      }).unwrap();

      const { download_url } = await getPreviewUrl({
        groupId: groupId!,
        fileId: document_id,
      }).unwrap();

      openLink(download_url);
      successToast({ message: "Report downloaded" });
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  return (
    <div className="t-justify-end t-flex t-gap-2">
      {/* <Button size="small">Save</Button> */}
      <Button
        size="small"
        customType="primary"
        disabled={isLoading || isPreviewLoading}
        isLoading={isLoading || isPreviewLoading}
        onClick={onExport}
      >
        Export
      </Button>
    </div>
  );
};

export const AgingSummary = () => {
  const query = useQuery();
  const date = query.get("DATE") || dayjs().format(YYYY_MM_DD).toString();
  const accountingMethod = (query.get("ACCOUNTING_METHOD") ||
    ACCOUNTING_METHODS.CASH) as keyof typeof ACCOUNTING_METHODS;
  const daysPerAging = query.get("DAYS_PER_AGING") || "30";
  const numberOfPeriods = query.get("NUMBER_OF_PERIODS") || "6";

  const { values: filterValues, updateFilter } = useFilters<FilterValues>({
    initialValue: {
      DATE: date,
      ACCOUNTING_METHOD: accountingMethod,
      DAYS_PER_AGING: parseInt(daysPerAging),
      NUMBER_OF_PERIODS: parseInt(numberOfPeriods),
    },
    useQueryParams: true,
  });
  const entityId = useCurrentEntityId();

  const [agingData, setAgingData] = useState<AgingData>({
    customerId: "",
    startDate: "",
    endDate: "",
    currentlyDueInvoices: false,
  });
  const [showInvoices, setShowInvoices] = useState(false);

  const queryParams = {
    entityId,
    date: filterValues.DATE,
    ACCOUNTING_METHODS: filterValues.ACCOUNTING_METHOD,
    daysPerAgingPeriod: filterValues.DAYS_PER_AGING,
    numberOfAgingPeriods: filterValues.NUMBER_OF_PERIODS,
  };

  const shouldSkipQuery =
    !entityId ||
    !filterValues.DATE ||
    !filterValues.ACCOUNTING_METHOD ||
    !filterValues.DAYS_PER_AGING ||
    !filterValues.NUMBER_OF_PERIODS;

  const { data, isFetching, isLoading, isSuccess } = useGetAgingSummaryQuery(
    queryParams,
    { skip: shouldSkipQuery, refetchOnMountOrArgChange: true }
  );

  const agingSummary = data?.ar_aging_summary;
  const uniqueRanges = agingSummary?.[0]?.aging_summary?.aging_buckets;
  const reportDatabyView = useMemo(() => agingSummary, [isFetching]);

  const columnHelper = createColumnHelper<ARAgingSummary>();

  const onAmountClick = ({
    range,
    customerId,
  }: {
    range?: string;
    customerId: string;
  }) => {
    if (showInvoices) {
      setAgingData({
        customerId: "",
        startDate: "",
        endDate: "",
        currentlyDueInvoices: false,
      });
      setShowInvoices(false);
    } else {
      const currentlyDueInvoices =
        range && range === "Current Due" ? true : false;

      const startDate =
        !currentlyDueInvoices && range ? range.split(" - ")[0] : "";
      const endDate =
        !currentlyDueInvoices && range ? range.split(" - ")[1] : "";

      setAgingData({
        customerId,
        startDate,
        endDate,
        currentlyDueInvoices: currentlyDueInvoices,
      });
      setShowInvoices(true);
    }
  };

  const dynamicViewColumns =
    uniqueRanges?.map(({ range }) =>
      columnHelper.accessor(
        (row) =>
          row?.aging_summary?.aging_buckets?.find(
            (bucket) => bucket.range === range
          )?.amount || 0,
        {
          id: range,
          header: () => (
            <div className="t-flex t-justify-end t-ml-4">{range}</div>
          ),
          cell: ({ getValue, row }) => {
            const isSelected =
              agingData.customerId === row.original.customer_id &&
              (range === agingData.startDate + " - " + agingData.endDate ||
                (agingData.currentlyDueInvoices && range === "Current Due"));

            return (
              <div className="t-flex t-justify-end t-ml-4">
                {getValue() ? (
                  <button
                    className={classNames(
                      "all:unset t-text-body t-ml-auto hover:!t-underline hover:!t-text-purple",
                      {
                        "t-underline t-text-purple": isSelected,
                      }
                    )}
                    onClick={() =>
                      onAmountClick({
                        range,
                        customerId: row.original.customer_id,
                      })
                    }
                  >
                    <AmountSuperScript amount={getValue()} />
                  </button>
                ) : (
                  "-"
                )}
              </div>
            );
          },
          size: 114,
        }
      )
    ) || [];

  const columns = [
    columnHelper.accessor("customer_name", {
      header: "Customer name",
      size: 300,
      cell: ({ row, getValue }) => {
        const name = getValue();

        return (
          <div
            style={{
              paddingLeft: `${row.depth * 1}rem`,
            }}
            className={classNames("t-flex t-items-center")}
          >
            <div className="t-flex t-justify-end t-h-full t-items-center t-gap-3">
              {name}
            </div>
          </div>
        );
      },
    }),
    ...dynamicViewColumns,
    columnHelper.accessor("aging_summary.total", {
      header: "Total",
      id: "total",
      cell: ({ getValue, row }) => {
        return (
          <button
            className={classNames(
              "all:unset t-text-body t-ml-auto hover:!t-underline hover:!t-text-purple",
              {
                "t-underline t-text-purple":
                  agingData.customerId === row.original.customer_id &&
                  !(agingData.currentlyDueInvoices || agingData.startDate),
              }
            )}
            onClick={() =>
              onAmountClick({
                customerId: row.original.customer_id,
              })
            }
          >
            <AmountSuperScript amount={getValue()} />
          </button>
        );
      },
      minSize: 60,
      size: 60,
    }),
  ];

  const table = useReactTable({
    data: reportDatabyView || [],
    columns: columns,
    state: {
      columnPinning: {
        left: ["customer_name"],
        right: ["total"],
      },
    },
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <DashboardContainer className="t-h-full t-w-full t-flex t-px-10 t-pt-5">
      <DashboardContainer.Header className="t-sticky t-top-0 t-bg-white t-flex t-justify-between">
        <AgingSummaryFilter
          filterValues={filterValues}
          updateFilter={updateFilter}
        />
        <AgingSumarySaveExport filterValues={filterValues} />
      </DashboardContainer.Header>
      <DashboardContainer.Content>
        <Async.Root {...{ isEmpty: false, isLoading: isFetching, isSuccess }}>
          <Async.Empty>
            <div className="t-text-body t-flex t-justify-center t-h-full t-w-full">
              No invoices due
            </div>
          </Async.Empty>
          <Async.Success>
            <div className="t-pb-20 t-relative t-pt-6">
              <Table.Container>
                <Table.Content className="t-h-full">
                  <Table.Head>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <Table.HeadRow key={headerGroup.id}>
                        {headerGroup.headers.map((header, index) => {
                          const styles = getCommonPinningStyles(header.column);
                          return (
                            <Table.HeadCell
                              key={header.id}
                              style={{
                                ...styles,
                                minWidth: header.column.getSize(),
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </Table.HeadCell>
                          );
                        })}
                      </Table.HeadRow>
                    ))}
                  </Table.Head>
                  <Table.Body>
                    {table.getRowModel().rows.map((row) => {
                      const isInvoiceVisible =
                        showInvoices &&
                        agingData.customerId === row.original.customer_id;
                      return (
                        <>
                          <motion.tr
                            key={row.id}
                            aria-label={row.original.customer_name}
                            aria-disabled={!row.getCanExpand()}
                            aria-expanded={row.getIsExpanded()}
                            layout
                            className={classNames(
                              "t-select-none t-px-3 t-text-body t-group t-border t-border-b t-border-solid t-border-t-0 t-border-x-0 t-border-neutral-0"
                            )}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.4, ease: "easeInOut" }}
                          >
                            {row.getVisibleCells().map((cell) => {
                              const styles = getCommonPinningStyles(
                                cell.column
                              );

                              return (
                                <Table.Cell
                                  key={cell.id}
                                  style={{
                                    ...styles,
                                    minWidth: cell.column.getSize(),
                                  }}
                                >
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </Table.Cell>
                              );
                            })}
                          </motion.tr>
                          {isInvoiceVisible && (
                            <InvoiceTable
                              customerId={agingData.customerId}
                              startDate={agingData.startDate}
                              endDate={agingData.endDate}
                              currentlyDueInvoices={
                                agingData.currentlyDueInvoices
                              }
                            />
                          )}
                        </>
                      );
                    })}
                  </Table.Body>
                </Table.Content>
              </Table.Container>
            </div>
          </Async.Success>
        </Async.Root>
      </DashboardContainer.Content>
    </DashboardContainer>
  );
};
