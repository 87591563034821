import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";

type RepaymentConfirmationModalProps = {
  show: boolean;
  close: () => void;
  handleClick: () => void;
};

export const RePaymentConfirmationModal = ({
  show,
  close,
  handleClick,
}: RepaymentConfirmationModalProps) => {
  return (
    <Modal.Root open={show} onOpenChange={close}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Confirm Payment</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body t-text-text-black">
            A payment is already in progress for the invoice/s via bank
            transfer.
            <br />
            <br />
            Are you sure you want to proceed?
          </div>
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Modal.RawClose asChild>
            <Button onClick={close}>Cancel</Button>
          </Modal.RawClose>
          <Button customType="primary" onClick={handleClick}>
            Confirm
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};
