import { multipleFileDownloads } from "apis/documents";
import { ConfirmReview } from "components/chat/DocumentReview/ConfirmReview";
import ChatCard from "components/DesignSystem/ChatCard/ChatCard";
import {
  TASK_ADDITIONAL_PAYMENT_CARD_OPENED,
  TASK_AMENDMENT_COMPLETED_CARD_OPENED,
  TASK_REVIEW_AND_PAY_CARD_OPENED,
  TASK_REVIEW_DOCUMENT_CARD_OPENED,
} from "constants/analyticsEvents";
import {
  TASK_ADDITIONAL_PAYMENT,
  TASK_REVIEW_AND_PAY,
  TASK_REVIEW_DOCUMENT,
} from "constants/chatType";
import { TASK_AMENDMENT_DOCUMENTS } from "constants/taskStates";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetTaskFromChannelIdQuery,
  useGetTaskQuery,
  useMarkDocumentAsReviewMutation,
} from "store/apis/task";
import {
  closeReviewConfirmModal,
  openBalancingPaymentModal,
  openDocumentReviewModal,
  setAmount,
  setCustomData,
  setDocumentForReview,
  setDocumentForReviewMessageId,
  setDocumentOperationType,
  setDocumentReviewChannel,
  setHasReviewed,
  setIsDocReviewed,
  setIspaymentRequired,
} from "store/slices/reviewAndBalancePayment";
import { RootState } from "store/store";
import { useChannelStateContext, useChatContext } from "stream-chat-react";
import {
  CustomData,
  CustomDataFile,
} from "types/Models/reviewDocumentAndBalancingPayment";
import { openLink } from "utils/openLink";
import { ChatCardButton } from "./ChatCardButton";
import {
  PricingAndDescription,
  ReviewChatCardBody,
} from "./ReviewChatCardBody";
import { useGetMessage } from "hooks/useGetMessage";
import classNames from "classnames";
import { actionItemsApi } from "store/apis/actionItems";
import { ReviewDocHeader } from "components/icons/ReviewDocHeader";
import { ReviewPayHeader } from "components/icons/ReviewPayHeader";
import { BalancePaymentHeader } from "components/icons/BalancePaymentHeader";

type IReviewMessageChatCardProps = {
  customData: CustomData;
  messageId: string;
  size?: "regular" | "block";
  customBackground?: string;
};

export const ReviewMessageChatCard: FC<IReviewMessageChatCardProps> = ({
  customData,
  messageId,
  size,
  customBackground,
}) => {
  const dispatch = useDispatch();
  const { channelTaskId } = useSelector((state: RootState) => state.chat);
  const { channel } = useChannelStateContext();

  const { data: channelTask } = useGetTaskFromChannelIdQuery(
    {
      channelId: channel?.id!,
    },
    {
      skip: !channel?.id,
    }
  );

  const {
    activeChannelGroupId,
    documentForReviewMessageId,
    documentForReview,
    showReviewConfirmModal,
  } = useSelector((state: RootState) => state.reviewAndBalancePayment);
  const [documentMarkAsReview, { isLoading }] =
    useMarkDocumentAsReviewMutation();
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const isPaymentRequired = customData.pending_payment?.payment_required;
  const isDocReviewed = customData.is_reviewed;
  const { trackEvent } = useAnalytics();

  const setAllData = () => {
    dispatch(setCustomData(customData));
    dispatch(setDocumentForReviewMessageId(messageId));
    dispatch(setIsDocReviewed(isDocReviewed));
    dispatch(setIspaymentRequired(isPaymentRequired));
    dispatch(setAmount(customData.pending_payment?.pending_amount));
  };

  const openReviewDocumentModal = (
    documentOperation: string,
    fileData: CustomDataFile[]
  ) => {
    setAllData();
    dispatch(setDocumentOperationType(documentOperation));
    dispatch(setDocumentForReview(fileData));
    dispatch(openDocumentReviewModal());
    dispatch(
      setDocumentReviewChannel({
        reviewChannelId: channel?.id!,
        reviewTaskId: channel?.data?.task_id as string,
      })
    );
  };

  const markDocumentAsReview = async () => {
    try {
      const documentIds = documentForReview
        .map(({ uuid }: { uuid: string }) => uuid)
        .join(",");
      await documentMarkAsReview({
        channel_id: channel?.id!,
        group_id: groupId || activeChannelGroupId,
        task_id: channelTaskId || (channel?.data?.task_id as string),
        message_id: documentForReviewMessageId,
        document_ids: documentIds,
      }).unwrap();
      successToast({ message: "Document marked as reviewed" });
      setTimeout(() => {
        dispatch(
          actionItemsApi.util.invalidateTags([{ type: "ALL_ACTION_ITEMS" }])
        );
      }, 1000);
    } catch (error: any) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const downloadFiles = async () => {
    try {
      const fileIds = customData?.ctas?.[0]?.metadata?.file_data?.map(
        ({ uuid }: { uuid: string }) => uuid
      );
      const response = await multipleFileDownloads({
        groupId: groupId || activeChannelGroupId,
        fileIds,
      });
      response.data.data.forEach(({ download_url }: { download_url: string }) =>
        openLink(download_url)
      );
    } catch (e) {}
  };

  const markAsReview = async () => {
    await markDocumentAsReview();
    dispatch(closeReviewConfirmModal());
  };

  const trackCardEvents = (documentOperation: string) => {
    switch (documentOperation) {
      case TASK_REVIEW_DOCUMENT:
        return trackEvent(TASK_REVIEW_DOCUMENT_CARD_OPENED);
      case TASK_REVIEW_AND_PAY:
        return trackEvent(TASK_REVIEW_AND_PAY_CARD_OPENED);
      case TASK_AMENDMENT_DOCUMENTS:
        return trackEvent(TASK_AMENDMENT_COMPLETED_CARD_OPENED);
      case TASK_ADDITIONAL_PAYMENT:
        return trackEvent(TASK_ADDITIONAL_PAYMENT_CARD_OPENED);

      default:
        break;
    }
  };

  const handleCardAction = (
    documentOperation: string,
    fileData: CustomDataFile[]
  ) => {
    trackCardEvents(documentOperation);
    if (isDocReviewed && isPaymentRequired && channelTask) {
      dispatch(setHasReviewed(documentOperation === TASK_REVIEW_AND_PAY));

      setAllData();
      if (channelTask?.entity?.uuid) {
        dispatch(openBalancingPaymentModal(channelTask?.entity?.uuid));
      }
    } else if (
      documentOperation === TASK_REVIEW_DOCUMENT ||
      documentOperation === TASK_REVIEW_AND_PAY ||
      documentOperation === TASK_AMENDMENT_DOCUMENTS
    ) {
      openReviewDocumentModal(documentOperation, fileData);
    }
  };

  const chatCardBody = () => {
    const documentOperation = customData?.ctas?.[0]?.document_operation;
    switch (documentOperation) {
      case TASK_ADDITIONAL_PAYMENT:
        return customData.price ? (
          <PricingAndDescription
            price={customData.price}
            description={customData.description}
          />
        ) : null;
      default:
        return (
          <ReviewChatCardBody
            customData={customData}
            downloadFiles={downloadFiles}
            isPaymentRequired={isPaymentRequired}
            isDocReviewed={isDocReviewed}
          />
        );
    }
  };

  const chatCardHeader = () => {
    const isSubmitted = customData.card_status === "SUBMITTED";
    switch (customData.header) {
      case "Review Document":
        return isSubmitted ? "Document Reviewed" : "Review Document";
      case "Review and balance payment":
        return isSubmitted ? "Reviewed & Paid" : "Review & Pay";
      case "Balance Payment":
        return "Balance Payment";
      default:
        return "Balance Payment";
    }
  };

  const chatCardHeaderIcon = () => {
    switch (customData.header) {
      case "Review Document":
        return <ReviewDocHeader />;
      case "Review and balance payment":
        return <ReviewPayHeader />;
      case "Balance Payment":
        return <BalancePaymentHeader />;
      default:
        return (
          <ReviewChatCardBody
            customData={customData}
            downloadFiles={downloadFiles}
            isPaymentRequired={isPaymentRequired}
            isDocReviewed={isDocReviewed}
          />
        );
    }
  };

  return (
    <div
      className={classNames({
        "t-mb-2": size === "regular",
        "t-w-full": size === "block",
      })}
    >
      <ChatCard.Root size={size}>
        <ChatCard.Header>
          <div className="t-flex t-gap-1.5 t-items-center">
            {chatCardHeaderIcon()}
            {chatCardHeader()}
          </div>
        </ChatCard.Header>
        <ChatCard.Body>
          {chatCardBody()}
          {customData.ctas?.map(
            ({
              document_operation,
              metadata: { file_data },
              url_text,
            }: {
              document_operation: string;
              url_text: string;
              metadata: { file_data: CustomDataFile[] };
            }) => (
              <ChatCardButton
                key={document_operation}
                urlText={url_text}
                documentOperation={document_operation}
                isPaymentRequired={isPaymentRequired}
                isDocReviewed={isDocReviewed}
                disabled={!channelTask}
                handleCardAction={() =>
                  handleCardAction(document_operation, file_data)
                }
                amount={customData.pending_payment?.pending_amount}
              />
            )
          )}
        </ChatCard.Body>
      </ChatCard.Root>
      {showReviewConfirmModal && (
        <ConfirmReview
          show={showReviewConfirmModal}
          closeModal={() => dispatch(closeReviewConfirmModal())}
          isLoading={isLoading}
          markDocumentAsReview={markAsReview}
        />
      )}
    </div>
  );
};
