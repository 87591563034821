import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { FILE_UPLOAD_MESSAGE } from "constants/documents";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useToast } from "hooks/useToast";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import UploadFile from "static/images/UploadFile.svg";
import { BackendError } from "types/utils/error";
import DownloadSimple from "static/images/DownloadSimple.svg";
import { openLink } from "utils/openLink";
import { FileIcon } from "utils/fileTypeIcon";
import { DeleteIcon } from "components/icons/delete";
import { Loader } from "components/DesignSystem/Loader/Loader";
import {
  journalEntries,
  usePopulateBulkJournalEntryMutation,
  useUploadBulkJournalEntryMutation,
} from "store/apis/journalEntry";
import { SolidCheck } from "components/icons/SolidCheck";
import { useDispatch } from "react-redux";
import { BULK_JOURNAL_ENTRY_EXCEL_TEMPLATE_URL } from "types/contants/sampleExcelSheet";

type BulkJournalEntryUploadProps = { isOpen: boolean; close: () => void };

export const BulkJournalEntryUpload = ({
  isOpen,
  close,
}: BulkJournalEntryUploadProps) => {
  const { alertToast, successToast, infoToast } = useToast();
  const entityId = useCurrentEntityId();
  const [populateBulkJournalEntry, { isLoading: isPopulating }] =
    usePopulateBulkJournalEntryMutation();
  const [
    uploadBulkJournalEntries,
    { data: bulkJournalEntryData, isLoading: isUploading, reset },
  ] = useUploadBulkJournalEntryMutation();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const dispatch = useDispatch();

  const onDrop = async (files: File[]) => {
    try {
      await uploadBulkJournalEntries({
        file: files[0],
        entityId,
      }).unwrap();
      setSelectedFile(files[0]);
      successToast({ message: FILE_UPLOAD_MESSAGE });
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const populateJournalEntries = async () => {
    try {
      if (bulkJournalEntryData?.bulk_file_id) {
        await populateBulkJournalEntry({
          bulkFileId: bulkJournalEntryData?.bulk_file_id,
          entityId,
        }).unwrap();

        setTimeout(() => {
          dispatch(
            journalEntries.util.invalidateTags([{ type: "JOURNAL_ENTRIES" }])
          );
        }, 1000);

        infoToast({
          title: "Adding Journal Entries",
          message: "We will send a notification once it's complete.",
          duration: 5000,
          size: "large",
        });
        close();
      }
    } catch (error) {
      alertToast({
        title: "Journal entries could not be added",
        message:
          (error as BackendError).data?.error?.message ||
          "We found some error in the uploaded file. Please check and retry.",
        size: "large",
      });
    }
  };

  const onDropRejected = () => {
    alertToast({ message: "Please upload a XLS or XLSX file exclusively." });
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject, open } =
    useDropzone({
      noClick: true,
      noKeyboard: true,
      onDrop,
      maxSize: 25000000,
      accept: {
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      },
      onDropRejected,
      multiple: false,
    });

  const deleteFile = () => {
    setSelectedFile(null);
    reset();
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content size="regular">
        <Modal.Header>
          <Modal.Title>Add Journal Entries in Bulk</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body
          className={classNames("t-space-y-3", {
            "t-pb-10": !bulkJournalEntryData?.bulk_file_id,
          })}
        >
          <div className="t-text-body t-text-text-100">
            Download the pre-formatted template to ensure your data is properly
            structured. Add the journal entry details to the template and upload
            it.
          </div>
          <div className="t-pb-2">
            <Button
              size="small"
              onClick={() => openLink(BULK_JOURNAL_ENTRY_EXCEL_TEMPLATE_URL)}
            >
              <img
                src={DownloadSimple}
                alt="DownloadSimple"
                className="t-mr-1"
              />
              <div>Download Excel template</div>
            </Button>
          </div>
          {isUploading && (
            <div className="t-h-40 t-flex t-justify-center t-items-center">
              <Loader />
            </div>
          )}
          {!isUploading && (
            <>
              {selectedFile ? (
                <div className="t-space-y-2">
                  <div className="t-text-body t-text-text-30">
                    Uploaded file:
                  </div>
                  <div className="t-flex t-items-center t-justify-between t-p-3 t-rounded t-border t-border-solid t-border-neutral-20">
                    <div className="t-flex t-gap-3 t-items-center">
                      <FileIcon
                        fileType={selectedFile.type}
                        height={32}
                        width={32}
                      />
                      <div className="t-space-y-1">
                        <div className="t-text-subtitle-sm t-text-blue-100">
                          {selectedFile.name}
                        </div>
                        <div className="t-text-overline t-text-neutral-60">
                          {(selectedFile.size / (1024 * 1024)).toFixed(2)} MB
                        </div>
                      </div>
                    </div>
                    <Button
                      size="small"
                      customType="ghost"
                      onClick={deleteFile}
                    >
                      <DeleteIcon color="currentColor" />
                    </Button>
                  </div>
                  <div className="t-flex t-items-center t-gap-1">
                    <SolidCheck size="12px" />
                    <div className="t-text-text-30 t-text-body-sm">
                      Upload Completed
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={classNames(
                    "t-border-2 t-rounded-lg t-border-dashed t-border-neutral-20 t-px-6 t-py-8 t-text-body t-text-text-100 t-flex t-items-center t-justify-center t-flex-col t-gap-3 t-bg-surface-lighter-grey t-cursor-pointer",
                    {
                      "!t-border-green !t-text-green-70": isDragAccept,
                      "!t-border-red-50 !t-text-red-50": isDragReject,
                    }
                  )}
                  {...getRootProps()}
                  onClick={open}
                >
                  <input {...getInputProps()} id="csv" />
                  <img src={UploadFile} alt="Upload File" />
                  Click or drag and drop to upload the filled template
                  <div className="t-text-body-sm t-text-text-30">
                    File Format: xls/xlsx
                  </div>
                </div>
              )}
            </>
          )}
        </Modal.Body>
        {selectedFile && (
          <Modal.FooterButtonGroup>
            <Modal.RawClose asChild>
              <Button type="button">Cancel</Button>
            </Modal.RawClose>
            <Button
              customType="primary"
              onClick={populateJournalEntries}
              isLoading={isPopulating}
              disabled={isPopulating}
            >
              Add journal entries
            </Button>
          </Modal.FooterButtonGroup>
        )}
      </Modal.Content>
    </Modal.Root>
  );
};
