import { ConditionalLink } from "components/conditionalLink";
import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";
import React from "react";
import {
  RAMailroomSubscriptionData,
  RAMailroomSubscriptionType,
} from "store/apis/RAMailroom";
import { ColorDot } from "./RAMailroomSubscriptions";
import { formatDate } from "utils/formatDate";
import { AssignedAE } from "./AssignedAE";
import { SupportStep } from "./SupportStep";

export const SubscriptionDetails = ({
  subscriptionDetails,
}: {
  subscriptionDetails: RAMailroomSubscriptionType;
}) => {
  let status = <></>;

  switch (subscriptionDetails.subscription_status) {
    case "ACTIVE": {
      status = (
        <div className="t-flex t-gap-2 t-items-center">
          <ColorDot color="t-bg-green-50" />
          <div className="t-text-body t-text-text-60">Active</div>
        </div>
      );
      break;
    }
    case "INACTIVE": {
      status = (
        <div className="t-flex t-gap-2 t-items-center">
          <ColorDot color="t-bg-red" />
          <div className="t-text-body t-text-text-60">Inactive</div>
        </div>
      );
      break;
    }
    default: {
      status = <></>;
      break;
    }
  }

  return (
    <div className="t-grid t-grid-cols-2 t-gap-x-4 t-gap-y-6">
      <InfoItem label="Group">
        <ConditionalLink
          target="_blank"
          to={`crm/${subscriptionDetails.company.uuid}`}
        >
          <span className="t-text-purple">
            {subscriptionDetails.company.name}
          </span>
        </ConditionalLink>
      </InfoItem>
      <InfoItem label="Entity">
        <ConditionalLink
          target="_blank"
          to={`crm/${subscriptionDetails.company.uuid}/entity/${subscriptionDetails.entity.uuid}`}
        >
          <span className="t-text-purple">
            {subscriptionDetails.company.name}
          </span>
        </ConditionalLink>
      </InfoItem>
      <InfoItem label="Subscription">
        {subscriptionDetails.subscription_name || "-"}
      </InfoItem>
      <InfoItem label="Subscription Status">{status}</InfoItem>
      <InfoItem label="Start Date">
        {subscriptionDetails.start_date
          ? formatDate(subscriptionDetails.start_date)
          : "-"}
      </InfoItem>
      <InfoItem label="End Date">
        {subscriptionDetails.end_date
          ? formatDate(subscriptionDetails.end_date)
          : "-"}
      </InfoItem>
      <div className="t-col-span-2">
        <InfoItem label="Assigned AE">
          <AssignedAE subscription={subscriptionDetails} />
        </InfoItem>
      </div>
      <div className="t-col-span-2">
        <InfoItem label="Support Step">
          <SupportStep subscription={subscriptionDetails} />
        </InfoItem>
      </div>
    </div>
  );
};
