import React from "react";
import SmallGreenTick from "static/images/SmallGreenTick.svg";
import BlueHourGlass from "static/images/BlueHourGlass.svg";
import GrayFilledCross from "static/images/GrayFilledCross.svg";
import { useToast } from "hooks/useToast";
import { useEditEntityDataAttributesMutation } from "store/apis/entity";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { BackendError } from "types/utils/error";
import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import { Form, Formik } from "formik";
import { ArrowDown } from "components/icons/ArrowDown";
import { Entity, EntityStatusType } from "types/Models/entity";

const entityStatusOptions = {
  PRE_INCORPORATED: {
    label: "Pre-incorporated",
    icon: BlueHourGlass,
    bgColor: "t-bg-blue-10",
    order: 0,
  },
  ACTIVE: {
    label: "Active",
    icon: SmallGreenTick,
    bgColor: "t-bg-green-10",
    order: 1,
  },
  DISSOLVED: {
    label: "Dissolved",
    icon: GrayFilledCross,
    bgColor: "t-bg-neutral-0",
    order: 2,
  },
};

export const EntityStatusSelect = ({ entity }: { entity: Entity }) => {
  const { successToast, alertToast } = useToast();
  const [editEntityDataAttributes] = useEditEntityDataAttributesMutation();
  const { uuid: groupId } = useCurrentGroupContext();

  const updateEntityStatus = async (status: 0 | 1 | 2) => {
    try {
      await editEntityDataAttributes({
        groupId,
        entityId: entity.uuid,
        payload: { entity_status: status },
      }).unwrap();

      successToast({ message: "Entity updated successfully" });
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Formik
        initialValues={{
          entity_status: entity.entity_status || "ACTIVE",
        }}
        onSubmit={() => {}}
      >
        {({ values, setFieldValue }) => {
          const selectedItem =
            entityStatusOptions[values.entity_status as EntityStatusType];

          return (
            <Form className="t-m-0 t-w-full">
              <DropDown.Root>
                <DropDown.Trigger asChild className="t-group t-text-text-60">
                  <div
                    className={`t-flex t-gap-1 t-items-center t-px-2 t-py-1 t-rounded ${selectedItem.bgColor}`}
                  >
                    <img src={selectedItem.icon} alt="icon" />
                    <div className="t-text-body-sm">{selectedItem.label}</div>
                    <div className="group-data-state-open:-t-rotate-180 t-transform t-transition t-duration-300 t-ease-in-out">
                      <ArrowDown color="currentColor" strokeWidth="1.5" />
                    </div>
                  </div>
                </DropDown.Trigger>
                <DropDown.Portal>
                  <DropDown.Content
                    sideOffset={8}
                    side="bottom"
                    className="t-w-[180px]"
                    align="start"
                  >
                    {Object.entries(entityStatusOptions).map(([key, value]) => (
                      <DropDown.Item
                        key={key}
                        onSelect={() => {
                          setFieldValue("entity_status", key);
                          updateEntityStatus(value.order as 0 | 1 | 2);
                        }}
                      >
                        <div>{value.label}</div>
                      </DropDown.Item>
                    ))}
                  </DropDown.Content>
                </DropDown.Portal>
              </DropDown.Root>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
