import { HTMLAttributes } from "react";
import cx from "classnames";

const Root = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      {...props}
      className={cx(
        "t-flex t-flex-col t-border t-border-solid t-border-neutral-10 t-rounded t-p-4",
        props.className || ""
      )}
    />
  );
};

const Header = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      {...props}
      className={cx(
        "t-flex t-flex-shrink-0 t-items-center t-justify-between t-border-0 t-border-b t-border-solid t-border-b-neutral-10 t-pb-3",
        props.className || ""
      )}
    />
  );
};

const Title = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      {...props}
      className={cx("t-text-subtext t-text-text-100", props.className || "")}
    />
  );
};

const Body = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      {...props}
      className={cx("t-overflow-auto t-mt-5", {
        [props.className || ""]: props.className,
      })}
    />
  );
};

const Card = {
  Root,
  Header,
  Title,
  Body,
};

export default Card;
