import { CheckoutModal } from "components/CheckoutModal/CheckoutModal";
import { ConditionalLink } from "components/conditionalLink";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { TableUI } from "components/design/TableUI";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import Table from "components/DesignSystem/Table/V2/Table";
import { Preview } from "components/PreviewModal";
import { PAID } from "constants/billing";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { actionItemsApi } from "store/apis/actionItems";
import { useGetBillingInvoicesQuery } from "store/apis/billingInvoice";
import { BillingInvoice } from "types/Models/billing";
import { ModalProps } from "types/utils/modal";
import { FileIcon } from "utils/fileTypeIcon";
import { formatDate } from "utils/formatDate";

export const InvoicesList = ({
  isOpen,
  close,
  entityId,
}: ModalProps & { entityId: string }) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const [pageNumber, setPageNumber] = useState(1);
  const documentPreview = useModal();
  const [previewId, setPreviewId] = useState<string>("");
  const UnpaidPaymentModal = useModal();
  const dispatch = useDispatch();

  const {
    data: {
      group_invoices: invoices = [],
      total_pages = 0,
      current_page = 0,
      per_page = 0,
      total_count = 0,
      due_amount: dueAmount = 0,
    } = {},
    isLoading,
    isSuccess,
  } = useGetBillingInvoicesQuery(
    {
      groupId: groupId!,
      entityId,
      pageNumber: pageNumber,
      paymentStatuses: "PENDING,IN_PROGRESS,WORK_IN_PROGRESS",
    },
    {
      skip: !groupId || !entityId,
      refetchOnMountOrArgChange: true,
    }
  );

  const columnHelper = createColumnHelper<BillingInvoice>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Invoice Name",
        size: 30,
        cell: (info) => (
          <div className="t-text-body t-text-text-60">{info.getValue()}</div>
        ),
      }),

      columnHelper.accessor("created_at", {
        header: "Date of Creation",
        size: 30,
        cell: (info) => (
          <div className="t-text-body t-text-text-60">
            {formatDate(info.getValue())}
          </div>
        ),
      }),
      columnHelper.accessor("document.uuid", {
        header: "Invoice",
        size: 20,
        cell: (info) => {
          const rowData = info.row.original;
          return (
            <span className="invoiceViewDownload">
              {rowData?.document ? (
                <span
                  className="invoiceIcon"
                  onClick={(e) => {
                    setPreviewId(info.getValue());
                    documentPreview.open();
                  }}
                >
                  <FileIcon
                    fileType={rowData?.document?.file_type}
                    width="30px"
                    height="30px"
                  />
                </span>
              ) : (
                <>-</>
              )}
            </span>
          );
        },
      }),
      columnHelper.accessor("amount", {
        header: () => (
          <div className="t-flex t-w-full t-justify-end">AMOUNT</div>
        ),
        size: 20,
        cell: (info) => (
          <div className="t-flex t-w-full t-justify-end">
            <AmountSuperScript amount={Number(info.getValue())} />
          </div>
        ),
      }),
    ],
    []
  );

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  const goToFirstPage = () => {
    setPageNumber(1);
  };

  const { currentPage, totalPage } = paginationData;

  const goToPrevPage = () => {
    const localCurrentPage = currentPage < totalPage ? currentPage : totalPage;
    setPageNumber(localCurrentPage - 1);
  };

  const goToNextPage = () => {
    if (currentPage < totalPage) {
      setPageNumber(currentPage + 1);
    }
  };
  const goToLastPage = () => {
    setPageNumber(totalPage);
  };

  const onInvoicePaid = () => {
    close();
    setTimeout(() => {
      dispatch(
        actionItemsApi.util.invalidateTags([{ type: "ALL_ACTION_ITEMS" }])
      );
    }, 1000);
  };

  const unPaidInvoices = invoices?.filter(
    (invoice) => !isNaN(Number(invoice.amount.replace("$", "")))
  );

  const table = useReactTable({
    data: invoices,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
      maxSize: 100,
    },
  });

  return (
    <Modal.Root onOpenChange={close} open={isOpen}>
      <Modal.Content useCustomOverlay size="large">
        <Modal.Header>
          <Modal.Title>Pending Invoices</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body className="t-space-y-5 t-p-5">
          <Async.Root
            isLoading={isLoading}
            isSuccess={isSuccess}
            isEmpty={false}
          >
            <Async.Empty>
              <></>
            </Async.Empty>
            <Async.Success>
              {total_pages > 1 && (
                <Pagination
                  {...paginationData}
                  goToFirstPage={goToFirstPage}
                  goToPrevPage={goToPrevPage}
                  goToNextPage={goToNextPage}
                  goToLastPage={goToLastPage}
                />
              )}
              <TableUI table={table}>
                {({ row }) => (
                  <Table.Row
                    key={row.id}
                    className="hover:t-bg-surface-lighter-grey"
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Table.Cell
                          key={cell.id}
                          style={{
                            width: `${cell.column.getSize()}%`,
                            padding: "12px 8px",
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Table.Cell>
                      );
                    })}
                  </Table.Row>
                )}
              </TableUI>
              <div className="t-flex t-justify-end">
                <div className="t-ml-auto t-flex t-gap-4 t-items-center">
                  <div className="t-text-subtext-sm t-text-text-30">TOTAL:</div>
                  <div className="t-text-subtitle t-text-text-60">
                    <AmountSuperScript amount={dueAmount} />
                  </div>
                </div>
              </div>
              <Preview
                showModal={documentPreview.isOpen}
                closeModal={documentPreview.close}
                groupId={groupId}
                previewId={previewId}
              />
              {UnpaidPaymentModal.isOpen && (
                <CheckoutModal
                  type="invoices"
                  entityId={entityId}
                  open={UnpaidPaymentModal.isOpen}
                  onClose={UnpaidPaymentModal.close}
                  invoices={unPaidInvoices}
                  onInvoicePaid={onInvoicePaid}
                />
              )}
            </Async.Success>
          </Async.Root>
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <ConditionalLink to="/settings/billing/invoices">
            <Button>Go to invoices</Button>
          </ConditionalLink>
          <Button onClick={UnpaidPaymentModal.open} customType="primary">
            Pay ${dueAmount}
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};
