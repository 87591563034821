import { object, string } from "yup";

export const vendorBillSchema = object({
  entity_merchant_data: object({
    uuid: string().required("Vendor is required"),
  }).required("Vendor is required"),
  bill_date: string().nullable().required("Bill Date is required"),
  bill_number_without_prefix: string()
    .matches(/^\d+$/, "Bill Number should only contain numbers")
    .required("Bill Number is required"),
});

export const sendBillSchema = object({
  vendor_bill_public_url: string().required(),
  subject: string().required("Subject can't be empty"),
});
