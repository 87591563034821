import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { MobileInput } from "components/DesignSystem/MobileInput/MobileInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { SelectDropDown } from "components/DesignSystem/SelectDropDown/SelectDropDown";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { EmailUpdate } from "components/userProfile/EmailUpdate";
import { MobileUpdate } from "components/userProfile/MobileUpdate";
import { timezones } from "data/timezones";
import { Field, Form, Formik, FormikValues } from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useUpdateTeamMemberMutation } from "store/apis/teamSetting";
const timezoneOptions = timezones
  .map(({ value, text }) => [value, `${value} - ${text}`])
  .sort((a, b) => a[0].localeCompare(b[0]));

type ManageProfileProps = {
  show: boolean;
  closeModal: () => void;
  user: {
    email: string;
    name: string;
    mobile: string;
    linkedIn_url: string;
    current_timezone: string;
    secondary_mobile: string;
    role_name: string;
    group_role_id: string;
    profile_id: string;
    available_secondary_whatsapp: boolean;
    available_primary_whatsapp: boolean;
  };
};

export const ManageProfile = ({
  show,
  closeModal,
  user,
}: ManageProfileProps) => {
  const emailUpdateModal = useModal();
  const mobileUpdateModal = useModal();
  const { alertToast, successToast } = useToast();
  const {
    email,
    name,
    mobile,
    linkedIn_url,
    current_timezone,
    secondary_mobile,
    group_role_id,
    profile_id,
    available_primary_whatsapp,
    available_secondary_whatsapp,
  } = user;
  const { roles, uuid: groupId } = useCurrentGroupContext();
  let roleOptions = roles.filter(
    ({ uuid }) => uuid !== process.env.PUBLIC_ACCOUNTANT_ROLE_ID
  );
  const { isInkleSuperAdmin } = useRoleBasedView();
  const [updateTeamMember, { isLoading: teamMemberUpdating }] =
    useUpdateTeamMemberMutation();
  const customerInitialValues = {
    secondary_mobile: secondary_mobile ? `${secondary_mobile}` : "",
    current_timezone,
    linkedin_url: linkedIn_url,
    mobile,
    role: group_role_id,
    available_primary_whatsapp,
    available_secondary_whatsapp,
  };

  const handleSubmit = async (values: FormikValues) => {
    try {
      const {
        current_timezone,
        linkedin_url,
        role,
        secondary_mobile,
        available_secondary_whatsapp,
        available_primary_whatsapp,
      } = values;
      let body: {
        email: string;
        group_role_id: string;
        secondary_mobile?: string;
        current_timezone?: string;
        linkedin_url?: string;
        profile_id: string;
        available_secondary_whatsapp?: boolean;
        available_primary_whatsapp?: boolean;
      } = {
        email,
        group_role_id: role,
        profile_id,
        available_primary_whatsapp,
      };
      if (current_timezone) {
        body = { ...body, current_timezone };
      }
      if (linkedin_url) {
        body = { ...body, linkedin_url };
      }
      if (secondary_mobile) {
        body = { ...body, secondary_mobile, available_secondary_whatsapp };
      }
      await updateTeamMember({ id: groupId, body }).unwrap();
      successToast({
        message: "User updated successfully",
        title: "Group user",
      });
      closeModal();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
      closeModal();
    }
  };

  return (
    <>
      <Modal.Root open={show} onOpenChange={closeModal}>
        <Modal.Portal>
          <Modal.Overlay />
          <Formik
            validateOnChange={false}
            enableReinitialize
            initialValues={customerInitialValues}
            onSubmit={handleSubmit}
          >
            {({ submitForm, setFieldValue }) => {
              return (
                <Form>
                  <Modal.Content>
                    <Modal.Header>
                      <div>
                        <Modal.Title>Manage profile</Modal.Title>
                      </div>
                      <Modal.Close />
                    </Modal.Header>
                    <Modal.Body className="t-flex t-flex-col t-gap-6">
                      <TextInput
                        label="Name"
                        name="name"
                        disabled
                        value={name}
                      />
                      <TextInput
                        label="Email"
                        name="email"
                        disabled
                        value={email}
                      />
                      <SelectDropDown
                        label="Role"
                        name="role"
                        disabled={!isInkleSuperAdmin}
                        dropdownSize="small"
                      >
                        {roleOptions.map(({ uuid, name }) => (
                          <option value={uuid} key={uuid}>
                            {name}
                          </option>
                        ))}
                      </SelectDropDown>
                      <div>
                        <MobileInput
                          label="Primary number"
                          name="mobile"
                          disabled
                        />
                        <div className="t-mt-1.5">
                          <Checkbox
                            label="Available on Whatsapp"
                            name="available_primary_whatsapp"
                            defaultChecked={available_primary_whatsapp}
                            onChange={(e) => {
                              setFieldValue(
                                "available_primary_whatsapp",
                                e.target.checked
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div>
                        <MobileInput
                          label="Secondary number"
                          name="secondary_mobile"
                        />
                        <div className="t-mt-1.5">
                          <Checkbox
                            label="Available on Whatsapp"
                            name="available_secondary_whatsapp"
                            defaultChecked={available_secondary_whatsapp}
                            onChange={(e) => {
                              setFieldValue(
                                "available_secondary_whatsapp",
                                e.target.checked
                              );
                            }}
                          />
                        </div>
                      </div>
                      <SelectDropDown
                        label="Time zone"
                        name="current_timezone"
                        dropdownSize="small"
                      >
                        <option value="">Select timezone</option>
                        {timezoneOptions?.map((option) => {
                          return (
                            <option value={option[0]} key={option[0]}>
                              {option[1]}
                            </option>
                          );
                        })}
                      </SelectDropDown>
                      <TextInput
                        label="LinkedIn"
                        name="linkedin_url"
                        placeholder="Your linkedIn url"
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="t-flex t-justify-end t-gap-3">
                        <Button onClick={closeModal} type="button">
                          Cancel
                        </Button>
                        <Button
                          customType="primary"
                          type="submit"
                          onClick={submitForm}
                          isLoading={teamMemberUpdating}
                          disabled={teamMemberUpdating}
                        >
                          Update
                        </Button>
                      </div>
                    </Modal.Footer>
                  </Modal.Content>
                </Form>
              );
            }}
          </Formik>
        </Modal.Portal>
      </Modal.Root>
      <EmailUpdate
        email={email}
        showEmailModal={emailUpdateModal.isOpen}
        setShowEmailModal={() => emailUpdateModal.close()}
      />
      <MobileUpdate
        showMobileModal={mobileUpdateModal.isOpen}
        setShowMobileModal={() => mobileUpdateModal.close()}
        mobile={mobile}
      />
    </>
  );
};
