import { Button } from "components/DesignSystem/Button/Button";
import { useModal } from "hooks/useModal";
import React from "react";
import { Form1099Fields } from "./Form1099Fields";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { Tag } from "components/DesignSystem/Tag/Tag";

export const Form1099 = ({
  wFormMapId,
  form1099Type,
  season,
  isInprogress,
  entityId,
}: {
  wFormMapId: string;
  form1099Type: string;
  season: string;
  isInprogress?: boolean;
  entityId: string;
}) => {
  const form1099TemplateModal = useModal();
  const { isCpa } = useRoleBasedView();

  return (
    <div className="t-flex t-justify-center">
      {isInprogress ? (
        <Button onClick={form1099TemplateModal.open} size="small">
          In Progress
        </Button>
      ) : (
        <>
          {isCpa ? (
            <Button
              customType="primary"
              onClick={form1099TemplateModal.open}
              size="small"
            >
              Prepare return
            </Button>
          ) : (
            <Tag tagType="purple" size="medium" rounded icon={false}>
              In Process
            </Tag>
          )}
        </>
      )}
      {form1099TemplateModal.isOpen && (
        <Form1099Fields
          {...form1099TemplateModal}
          wFormMapId={wFormMapId}
          form1099Type={form1099Type}
          season={season}
          isInprogress={isInprogress}
          entityId={entityId}
        />
      )}
    </div>
  );
};
