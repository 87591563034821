import { Loader } from "components/DesignSystem/Loader/Loader";
import { ConnectionLocalState } from "hooks/useBankConnect";
import { useQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import { useCallback, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useConfirmConnectionMutation } from "store/apis/booksConnections";
import { BackendError } from "types/utils/error";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

export const ConnectionConfirmation = () => {
  const [confirmConnection, { isLoading: isConfirming }] =
    useConfirmConnectionMutation();
  const history = useHistory();
  const query = useQuery();
  const { bank } = useParams<{ bank: string }>();
  const { alertToast, successToast } = useToast();

  const code = query.get("code");
  const state = query.get("state");
  const error = query.get("error");
  const errorDiscription = query.get("error_description");

  const authenticateCode = useCallback(
    async ({
      groupId,
      entityId,
      connectionId,
      txn_pull_choice,
      txn_pull_date,
      code,
    }: {
      groupId: string;
      entityId: string;
      connectionId: string;
      code: string;
      txn_pull_choice: string;
      txn_pull_date: string;
    }) => {
      if (isConfirming) {
        return;
      }

      try {
        return await confirmConnection({
          groupId,
          connectionId,
          entityId,
          code,
          txn_pull_choice,
          txn_pull_date,
        }).unwrap();
      } catch (error) {
        throw new Error((error as BackendError).data?.error?.message);
      }
    },
    []
  );

  useEffect(() => {
    const authenticate = async (code: string) => {
      const stateKey = localStorage.getItem("connection_oauth_state_key");
      try {
        if (stateKey && state === stateKey) {
          const localState = localStorage.getItem(stateKey);

          if (localState) {
            const localStateObj = JSON.parse(
              localState
            ) as ConnectionLocalState;

            await authenticateCode({
              ...localStateObj,
              code,
            });
            successToast({
              message: `${capitalizeFirstLetter(bank)} is Connected`,
            });

            localStorage.removeItem("connection_oauth_state_key");

            if (localStateObj?.invokedFrom) {
              history.replace(localStateObj?.invokedFrom);
              localStorage.removeItem(stateKey);
              return;
            }

            window.opener?.postMessage("CONNECTION_AUTHENTICATION_SUCCESS");
          }
        }

        throw new Error("Failed to authenticate, please contact support!");
      } catch (error: any) {
        alertToast({ message: error.message });
        if (stateKey && state === stateKey) {
          const localState = localStorage.getItem(stateKey);

          if (localState) {
            const localStateObj = JSON.parse(
              localState
            ) as ConnectionLocalState;

            if (localStateObj?.invokedFrom) {
              history.replace(localStateObj?.invokedFrom);
            }
          }

          window.opener?.postMessage("CONNECTION_AUTHENTICATION_FAILED");
        }
      }
    };

    if (code) {
      authenticate(code);
    }
  }, [authenticateCode, code, state]);

  return (
    <div className="t-h-screen t-w-screen t-flex t-justify-center t-items-center">
      {error ? (
        <>
          <div className="t-m-5">
            <p className="">Error happened</p>
            <p>{error}</p>
            <p>{errorDiscription}</p>
            <Link to="/books/data-sources">Go to dashboard</Link>
          </div>
        </>
      ) : (
        <>
          <div className="t-animate-spin">
            <Loader />
          </div>
          <p className="t-m-5">Confirming integration with {bank}...</p>
        </>
      )}
    </div>
  );
};
