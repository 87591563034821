import { PreviewModal } from "components/ChatPreviewModal/ChatPreviewModal";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Radio from "components/DesignSystem/RadioGroup/RadioGroup";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { Label, TextInput } from "components/DesignSystem/TextInput/TextInput";
import { AuthoriseEmail } from "components/InvoiceSettings/AuthoriseEmail";
import { Preview } from "components/PreviewModal";
import { PlusIcon } from "components/icons/PlusIcon";
import { Trash } from "components/icons/Trash";
import { FieldArray, Form, useFormikContext } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Invoice,
  invoiceApis,
  useGetInvoiceSettingsQuery,
  useLazyDownloadInvoiceQuery,
  useUpdateInvoiceMutation,
} from "store/apis/invoices";
import { BackendError } from "types/utils/error";
import { FileIcon } from "utils/fileTypeIcon";
import { AttachWform } from "./AttachWform";
import { SendInvoiceForm } from "./SendInvoiceModal";

export const GridLayout = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return <div {...props} className="t-grid t-grid-cols-[1fr_4fr] t-gap-2" />;
};

const AddAttachment = ({ onDrop }: { onDrop: (files: File[]) => void }) => {
  const { getInputProps, open, getRootProps } = useDropzone({
    onDrop,
  });

  return (
    <Button type="button" size="small" onClick={open} {...getRootProps()}>
      <input hidden {...getInputProps()} />
      <span className="t-flex t-gap-1 t-items-center">
        <PlusIcon />
        <span>Add attachment</span>
      </span>
    </Button>
  );
};

export const SendInvoice = ({
  invoice,
  invoiceIdFromProp,
}: {
  invoice: Invoice;
  invoiceIdFromProp?: string;
}) => {
  const { values, setFieldValue } = useFormikContext<SendInvoiceForm>();
  const group = useCurrentGroupContext();
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const [attachment, setAttachment] = useState<string | null>();
  const [tempAttachment, setTempAttachment] = useState<File | null>(null);
  const { alertToast } = useToast();
  const dispatch = useDispatch();
  const invoiceUuid = invoiceIdFromProp || invoiceId;
  const entityId = useCurrentEntityId();
  const [updateInvoice] = useUpdateInvoiceMutation();
  const authoriseEmailModal = useModal();
  const { data: invoiceSetting } = useGetInvoiceSettingsQuery(
    {
      groupId: group.uuid,
      entityId,
    },
    {
      skip: !group.uuid || !entityId,
    }
  );

  const attachmentDataUrI =
    tempAttachment && URL.createObjectURL(tempAttachment);

  const [getInvoiceDownloadUrl] = useLazyDownloadInvoiceQuery();

  useEffect(() => {
    const maybeDownloadInvoice = async () => {
      if (invoice && !invoice.document && entityId) {
        await getInvoiceDownloadUrl({
          groupId: group.uuid,
          entityId: entityId,
          invoiceId: invoiceUuid,
        }).unwrap();

        dispatch(invoiceApis.util.invalidateTags([{ type: "INVOICES" }]));
      }
    };

    maybeDownloadInvoice();
  }, []);

  const updateInvoiceWform = async (should_send_w_form: boolean) => {
    try {
      await updateInvoice({
        entityId: entityId!,
        groupId: group?.uuid!,
        invoiceId,
        payload: {
          should_send_w_form,
        },
      }).unwrap();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const onCancelAuthoriseEmail = () => {
    authoriseEmailModal.close();
    setFieldValue("from_email_type", "INKLE");
  };

  return (
    <Form className="t-m-0 t-flex t-flex-col t-gap-6">
      <GridLayout>
        <Label htmlFor="recipients">To</Label>
        <TextInput
          value={invoice?.customer?.email}
          disabled
          name="recipients"
          customSize="small"
        />
      </GridLayout>

      <GridLayout>
        <Label htmlFor="from_email_type">From</Label>
        <Radio.Root
          name="from_email_type"
          defaultValue={values.from_email_type}
          value={values.from_email_type}
          id="from_email_type"
          onValueChange={(value) => {
            if (value === "GMAIL" && !invoiceSetting?.connected_gmail) {
              authoriseEmailModal.open();
            }
            setFieldValue("from_email_type", value);
          }}
        >
          <Radio.Content className="!t-flex-row t-gap-2">
            <Radio.Item value="INKLE">
              <div className="t-text-text-100 t-text-body">Inkle</div>
              <div className="t-text-caption t-text-neutral-40">
                no-reply@inkle.io
              </div>
            </Radio.Item>
            <Radio.Item value="GMAIL">
              <div className="t-text-text-100 t-text-body">Gmail</div>
              <div className="t-text-caption t-text-neutral-40">
                {invoiceSetting?.connected_gmail || "Not connected"}
              </div>
            </Radio.Item>
          </Radio.Content>
        </Radio.Root>
      </GridLayout>

      <GridLayout>
        <Label htmlFor="cc_emails">CC</Label>
        <Combobox
          name="cc_emails"
          size="small"
          creatable
          isMulti
          withForm
          placeholder="Add email address"
        />
      </GridLayout>

      <GridLayout>
        <Label htmlFor="subject">Subject</Label>
        <TextInput name="subject" customSize="small" />
      </GridLayout>

      <TextArea name="body" label="Body" rows={13} />

      <Checkbox
        label="Attach W-Form to this Invoice"
        name="attachWform"
        checked={values.attachWform}
        onChange={(e) => {
          setFieldValue("attachWform", e.target.checked);
          updateInvoiceWform(e.target.checked);
        }}
      />
      {values.attachWform && <AttachWform />}
      <div className="t-flex t-gap-1 t-flex-col">
        <Label>Attachments</Label>
        {invoice?.document && (
          <div
            className="t-border t-border-solid t-border-neutral-10 t-rounded t-px-3 t-py-1 t-flex t-gap-2 t-items-center"
            role="button"
            onClick={() => setAttachment(invoice.document?.file_id)}
          >
            <FileIcon fileType="PDF" width={28} height={28} />
            <span className="t-text-body">{invoice?.document?.file_name}</span>
          </div>
        )}
        <FieldArray
          name="attachments"
          render={(arrayHelpers) => {
            return values.attachments.map((attachment, index) => (
              <div
                key={attachment.name}
                className="t-border t-border-solid t-border-neutral-10 t-rounded t-px-3 t-py-1 t-flex t-gap-2 t-items-center"
                role="button"
                onClick={() => setTempAttachment(attachment)}
              >
                <FileIcon
                  fileType={attachment.name.split(".").pop()?.toUpperCase()}
                  width={28}
                  height={28}
                />
                <span className="t-text-body-sm">{attachment.name}</span>
                <div className="t-ml-auto">
                  <Button
                    type="button"
                    customType="ghost_icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      arrayHelpers.remove(index);
                    }}
                    size="small"
                  >
                    <span className="t-text-red-50">
                      <Trash />
                    </span>
                  </Button>
                </div>
              </div>
            ));
          }}
        />

        <div className="t-mt-2">
          <AddAttachment
            onDrop={(files) =>
              setFieldValue("attachments", [...values.attachments, ...files])
            }
          />
        </div>
      </div>
      {attachment && (
        <Preview
          showModal={Boolean(attachment)}
          closeModal={() => setAttachment(null)}
          groupId={group.uuid}
          previewId={attachment}
        />
      )}
      {tempAttachment && attachmentDataUrI && (
        <PreviewModal
          title={tempAttachment.name}
          open={Boolean(tempAttachment)}
          onClose={() => setTempAttachment(null)}
        >
          <iframe
            className="t-w-full t-h-[96%]"
            src={attachmentDataUrI}
            title={tempAttachment.name}
          />
        </PreviewModal>
      )}
      <AuthoriseEmail
        isOpen={authoriseEmailModal.isOpen}
        close={onCancelAuthoriseEmail}
      />
    </Form>
  );
};
