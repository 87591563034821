import { Button } from "components/DesignSystem/Button/Button";
import { PendingDuesIcon } from "components/icons/PendingDuesIcon";
import { SmallWarningInfo } from "components/icons/SmallWarningInfo";
import { useModal } from "hooks/useModal";
import { TaxActionItemType } from "store/apis/actionItems";
import { InvoicesList } from "./InvoicesList";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import ReactCountryFlag from "react-country-flag";

export const PendingInvoicesRow = ({ item }: { item: TaxActionItemType }) => {
  const actionModal = useModal();
  const { entities } = useCurrentGroupContext();
  const entity = entities.find((entity) => entity.uuid === item.entity_id);

  return (
    <div className="t-flex t-justify-between t-items-center t-py-4 t-px-0 t-border t-border-solid t-border-neutral-0 t-border-t-0 t-border-l-0 t-border-r-0 last:t-border-b-0">
      <div className="t-flex t-gap-2 t-items-center">
        <div className="t-relative t-min-h-10 t-min-w-10 t-max-h-10 t-max-w-10 t-border t-border-solid t-border-neutral-0 t-rounded-full t-flex t-items-center t-justify-center">
          <PendingDuesIcon />
          <div className="t-absolute -t-right-0.5 -t-bottom-0.5 t-text-orange">
            <SmallWarningInfo />
          </div>
        </div>

        <div className="t-space-y-1">
          <div className="t-text-subtext-sm t-text-text-100">
            {item.count} {item.title}
          </div>
          {entity && (
            <div className="t-flex t-gap-1.5 t-items-center">
              <div className="t-rounded-sm">
                <ReactCountryFlag
                  svg
                  countryCode={entity.country_code}
                  height={12}
                  width={12}
                />
              </div>
              <div className="t-text-caption t-text-text-30 t-mt-0.5">
                {entity.name}
              </div>
            </div>
          )}
        </div>
      </div>
      <Button size="small" onClick={actionModal.open}>
        View
      </Button>
      <InvoicesList {...actionModal} entityId={item.entity_id} />
    </div>
  );
};
