import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { Trash } from "components/icons/Trash";
import { Form, Formik } from "formik";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ActionMeta, MultiValue, SingleValue } from "react-select";
import GrayPencil from "static/images/GrayPencil.svg";
import { useGetAutofillQuery } from "store/apis/autofill";
import { useUpdateTaskFormDataMutation } from "store/apis/task";
import classNames from "classnames";
import { DirectorAutofill } from "types/Models/directorAutofill";
import { AddFilingDirectorModal } from "./AddFilingDirector";
import { DeleteDirector } from "./DeleteDirector";
import RedInfo from "static/images/RedFilledInfo.svg";
import { useFormikContext } from "formik";
import { SectionField } from "types/Models/Filing";
import { useDeleteAPAutoFillMutation } from "store/apis/directorsAndOfficers";

type FilingDirectorsProps = {
  section: any;
  groupId: string;
  entityId: string;
  formData: any;
  autofillSuffix: string;
};

export const FilingDirectors = ({
  section,
  groupId,
  entityId,
  formData,
  autofillSuffix,
}: FilingDirectorsProps) => {
  const { values: formValues } = useFormikContext();
  const directorAutoFillKey = "DIRECTOR";
  const { data: directorAutofills = [], isLoading } = useGetAutofillQuery(
    {
      groupId,
      autofillKey: directorAutoFillKey,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const {
    isOpen: showAddDirectorModal,
    close: closeAddDirectorModal,
    open: openAddDirectorModal,
  } = useModal();
  const {
    isOpen: showDeleteDirectorModal,
    close: closeDeleteDirectorModal,
    open: openDeleteDirectorModal,
  } = useModal();
  const [updateTaskForm, { isLoading: isUpdatingTask }] =
    useUpdateTaskFormDataMutation();
  const { taskId } = useParams<{ taskId: string }>();
  const { alertToast } = useToast();
  const [directorIdForEdit, setDirectorIdForEdit] = useState("");
  const [directorIdForDelete, setDirectorIdForDelete] = useState<
    number | null
  >();
  const [deleteDirectorAutofill, { isLoading: isDeleting }] =
    useDeleteAPAutoFillMutation();
  const [deletedDirectorUuid, setDeletedDirectorUuid] = useState<string | null>(
    null
  );

  const addField = (times: number) => {
    const repeatables = section.repeatable_fields;
    const newFields: { id: number; fields: any }[] = [];

    for (let i = 0; i < times; i++) {
      const templateFields = repeatables?.[0]?.fields.map((field: any) => {
        let keyOnly = field.field_key.split("_");
        keyOnly = keyOnly.slice(0, keyOnly.length - 1);
        keyOnly = keyOnly.join("_");
        return {
          ...field,
          value: "",
          field_key: `${keyOnly}_${i}`,
        };
      });
      newFields.push({ id: i, fields: templateFields });
    }
    return newFields;
  };

  const getField = (fieldKey: string, field: any, valuesToSet: any) => {
    if (fieldKey.includes("director_name")) {
      return {
        ...field,
        value:
          valuesToSet?.persona_autofill?.first_name +
          " " +
          valuesToSet?.persona_autofill?.last_name,
      };
    }
    if (fieldKey.includes("director_street_address")) {
      return { ...field, value: valuesToSet.address?.street_address };
    }
    if (fieldKey.includes("director_city")) {
      return { ...field, value: valuesToSet.address?.city };
    }
    if (fieldKey.includes("director_state")) {
      return { ...field, value: valuesToSet.address?.state };
    }
    if (fieldKey.includes("director_zipcode")) {
      return { ...field, value: valuesToSet.address?.zipcode };
    }
    if (fieldKey.includes("director_country")) {
      return { ...field, value: valuesToSet.address?.country };
    }
    //@ts-ignore
    return { ...field, value: formValues?.[fieldKey] || field.value };
  };

  const updateDirectorRepeatableStep = (repeatableFieldsWithValue: any) => {
    return formData?.data?.data?.section_group?.[0].sections.map(
      (section: { [x: string]: any; section_key: string }) => {
        if (section.section_key === "directors") {
          return {
            ...section,
            repeatable_fields: repeatableFieldsWithValue,
          };
        }
        return {
          ...section,
          section_fields: section?.section_fields?.map(
            (sectionField: SectionField) => ({
              ...sectionField,
              value:
                //@ts-ignore
                formValues?.[sectionField.field_key] || sectionField?.value,
            })
          ),
        };
      }
    );
  };

  const getPayload = (updatedSections: any) => {
    const payload = {
      form_data: {
        ...formData,
        data: {
          ...formData.data.data,
          section_group: [{ sections: updatedSections }],
        },
      },
    };
    return payload;
  };

  const onDirectorCreate = async (director: DirectorAutofill) => {
    try {
      const repeatables = section.repeatable_fields;
      const isDataExistsInLastRepeatableField = repeatables?.[
        repeatables.length - 1
      ]?.fields?.some(
        (field: { value: any; field_key: any }) =>
          Boolean(field.value) && !field.field_key.includes("director_country")
      );
      const repeatableFieldId = isDataExistsInLastRepeatableField
        ? repeatables.length
        : repeatables.length - 1;

      const addedDirectorSectionFields = repeatables[0].fields.map(
        (field: any) => {
          let keyOnly = field.field_key.split("_");
          keyOnly = keyOnly.slice(0, keyOnly.length - 1);
          keyOnly = keyOnly.join("_");
          const fieldKey = `${keyOnly}_${repeatableFieldId}`;
          const fieldWithUpdatedKey = { ...field, field_key: fieldKey };

          return getField(fieldKey, fieldWithUpdatedKey, director);
        }
      );

      const selectedAutofillDetails = {
        persona_autofill_string:
          director?.persona_autofill?.first_name +
          " " +
          director?.persona_autofill?.last_name,
        persona_autofill_uuid: director?.persona_autofill?.uuid,
        address_autofill_string: director?.address?.autofill_string,
        address_autofill_uuid: director?.address?.uuid,
        director_autofill_uuid: director?.uuid,
      };
      const newRepeatableField = {
        id: repeatableFieldId,
        fields: addedDirectorSectionFields,
        selected_autofill_details: selectedAutofillDetails,
      };
      const repeatableFieldsWithValue =
        repeatableFieldId === 0
          ? [newRepeatableField]
          : [...repeatables, newRepeatableField];
      const updatedSections = updateDirectorRepeatableStep(
        repeatableFieldsWithValue
      );
      const payload = getPayload(updatedSections);

      await updateTaskForm({
        formId: formData.uuid,
        payload,
        taskId,
      }).unwrap();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  const handleRemoveDirector = async (
    merchants: SingleValue<OptionData> | MultiValue<OptionData> = [],
    actionMeta: ActionMeta<OptionData>
  ) => {
    if (actionMeta?.removedValue?.value) {
      setDeletedDirectorUuid(actionMeta?.removedValue?.value);
    }
    openDeleteDirectorModal();
  };

  const updateTaskDirectors = async () => {
    try {
      if (deletedDirectorUuid) {
        await deleteDirectorAutofill({
          personaId: deletedDirectorUuid,
          entityId,
        }).unwrap();
        closeDeleteDirector();
      }
      const merchants = directorAutofills
        .filter((merchant) => merchant.uuid !== deletedDirectorUuid)
        .map(({ uuid }) => ({ value: uuid }));

      //@ts-ignore
      const repeatableFields = addField(merchants?.length);

      const repeatableFieldsWithValue = repeatableFields.map(
        (repeatableField, i) => {
          const valuesToSet = directorAutofills.find(
            //@ts-ignore
            ({ uuid }) => uuid === merchants?.[i]?.value
          );

          const updatedFieldWithValue = {
            ...repeatableField,
            selected_autofill_details: {
              persona_autofill_string:
                (valuesToSet?.persona_autofill?.first_name || "") +
                " " +
                (valuesToSet?.persona_autofill?.last_name || ""),
              persona_autofill_uuid: valuesToSet?.persona_autofill?.uuid,
              address_autofill_string: valuesToSet?.address?.autofill_string,
              address_autofill_uuid: valuesToSet?.address?.uuid,
              director_autofill_uuid: valuesToSet?.uuid,
            },
            fields: repeatableField.fields?.map(
              (field: { field_key: string }) => {
                return getField(field.field_key, field, valuesToSet);
              }
            ),
          };
          return updatedFieldWithValue;
        }
      );

      const updatedSections = updateDirectorRepeatableStep(
        repeatableFieldsWithValue
      );

      const payload = getPayload(updatedSections);

      await updateTaskForm({
        formId: formData.uuid,
        payload,
        taskId,
      }).unwrap();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  const editDirector = async ({
    directorIdForEdit,
    editedDirectorDetails,
  }: {
    directorIdForEdit: string;
    editedDirectorDetails: {
      persona_autofill: any;
      address: any;
    };
  }) => {
    try {
      const directors = section.repeatable_fields;

      const updatedDirectors = directors.map(
        (director?: {
          id: number;
          fields: any;
          selected_autofill_details: any;
        }) => {
          if (
            directorIdForEdit ===
            director?.selected_autofill_details?.director_autofill_uuid
          ) {
            const editedDirector = {
              ...director,
              fields: director.fields?.map((field: { field_key: string }) => {
                return getField(field.field_key, field, editedDirectorDetails);
              }),
              selected_autofill_details: {
                ...director.selected_autofill_details,
                persona_autofill_string:
                  editedDirectorDetails?.persona_autofill?.first_name +
                  " " +
                  editedDirectorDetails?.persona_autofill?.last_name,
                address_autofill_string:
                  editedDirectorDetails?.address?.autofill_string,
              },
            };
            return editedDirector;
          } else {
            return director;
          }
        }
      );

      const updatedSections = updateDirectorRepeatableStep(updatedDirectors);
      const payload = getPayload(updatedSections);

      await updateTaskForm({
        formId: formData.uuid,
        payload,
        taskId,
      }).unwrap();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  const closeDeleteDirector = () => {
    closeDeleteDirectorModal();
    setDirectorIdForDelete(null);
    setDeletedDirectorUuid(null);
  };

  const openDeleteDirector = (deleteDirectorId: number) => {
    setDirectorIdForDelete(deleteDirectorId);
    openDeleteDirectorModal();
  };

  const handleDeleteDirectorFromSelect = async () => {
    try {
      const directors = section.repeatable_fields;
      let isDeletedFieldIdFound = false;

      const updatedDirectors = directors?.reduce(
        (acc: any, director: { id: number; fields: any }) => {
          if (director.id === directorIdForDelete) {
            isDeletedFieldIdFound = true;
            return acc;
          } else {
            if (isDeletedFieldIdFound) {
              let updatedDirector = { ...director };
              const newId = director.id - 1;

              const updatedFields = director.fields.map((field: any) => {
                const fieldKeyNumber = parseInt(
                  field.field_key.split("_").pop()
                );

                return {
                  ...field,
                  field_key: field.field_key.replace(
                    new RegExp(`${fieldKeyNumber}$`),
                    fieldKeyNumber - 1
                  ),
                };
              });
              updatedDirector = {
                ...director,
                id: newId,
                fields: updatedFields,
              };
              return [...acc, updatedDirector];
            } else {
              return [...acc, director];
            }
          }
        },
        []
      );

      const updatedSections = updateDirectorRepeatableStep(updatedDirectors);
      const payload = getPayload(updatedSections);

      const deletedPersonaId = directors?.find(
        ({ id }: { id: number }) => id === directorIdForDelete
      )?.selected_autofill_details?.director_autofill_uuid;

      if (deletedPersonaId) {
        await deleteDirectorAutofill({
          personaId: deletedPersonaId,
          entityId,
        }).unwrap();
      }

      await updateTaskForm({
        formId: formData.uuid,
        payload,
        taskId,
      }).unwrap();

      closeDeleteDirector();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
      closeDeleteDirector();
    }
  };

  const openEditDirectorModal = (directorId: string) => {
    setDirectorIdForEdit(directorId);
    openAddDirectorModal();
  };

  const onAddDirectorClose = () => {
    closeAddDirectorModal();
    setDirectorIdForEdit("");
  };

  const selectedDirectors = section.repeatable_fields?.filter(
    ({ fields }: { fields: any }) =>
      fields?.some(
        (field: { value: any; field_key: any }) =>
          Boolean(field.value) && !field.field_key.includes("director_country")
      )
  );

  const defaultSelectedDirectors = selectedDirectors.map(
    ({
      selected_autofill_details,
    }: {
      selected_autofill_details: {
        persona_autofill_string: string;
        director_autofill_uuid: string;
      };
    }) => ({
      isDisabled: selectedDirectors.length === 1,
      label: (
        <div className="t-text-body t-w-full t-truncate">
          {selected_autofill_details?.persona_autofill_string}
        </div>
      ),
      value: selected_autofill_details?.director_autofill_uuid,
    })
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div>
        <Formik
          validateOnChange={false}
          enableReinitialize
          initialValues={{
            directors: {},
          }}
          onSubmit={() => {}}
        >
          {({ setFieldValue }) => (
            <Form className="t-m-0 t-mb-6">
              <Combobox
                actions={
                  <Button customType="link" onClick={openAddDirectorModal}>
                    Add new director
                  </Button>
                }
                components={{ ClearIndicator: () => null }}
                menuPortalTarget={document.body}
                onChange={handleRemoveDirector}
                value={defaultSelectedDirectors}
                isMulti
                withForm
                name="directors"
                label="Select Directors"
                options={directorAutofills.map((autofill) => {
                  const isSelectedAndLastOption =
                    selectedDirectors.length === 1 &&
                    selectedDirectors.some(
                      ({
                        selected_autofill_details,
                      }: {
                        selected_autofill_details: {
                          director_autofill_uuid: string;
                        };
                      }) =>
                        selected_autofill_details?.director_autofill_uuid ===
                        autofill.uuid
                    );

                  return {
                    label: (
                      <div className="t-text-body t-w-full t-truncate">
                        {autofill.persona_autofill?.first_name || ""}{" "}
                        {autofill.persona_autofill?.last_name || ""}
                      </div>
                    ),
                    value: autofill.uuid,
                    isDisabled: isSelectedAndLastOption,
                  };
                })}
                isLoading={isUpdatingTask}
              />
            </Form>
          )}
        </Formik>
        {section.repeatable_fields.map(
          (repeatableField?: {
            id: number;
            fields: any[];
            selected_autofill_details: {
              persona_autofill_string: string;
              address_autofill_string: string;
              director_autofill_uuid: string;
            };
          }) => {
            const isOneDirectorRemaining =
              section.repeatable_fields.length === 1;
            const isDirectorDataExists =
              section.repeatable_fields?.[0]?.fields?.some(
                (field: { value: any; field_key: any }) =>
                  Boolean(field.value) &&
                  !field.field_key.includes("director_country")
              );
            const isDirectorDataMissing = repeatableField?.fields?.some(
              (field: { value: any; field_key: any; type: any }) =>
                !Boolean(field.value) && !field.type.includes("select_autofill")
            );

            if (!isDirectorDataExists) {
              return <></>;
            }

            return (
              <div className="t-mb-3" key={repeatableField?.id}>
                <div
                  className={classNames(
                    "t-border t-border-solid t-border-neutral-0 t-p-4 t-rounded-lg",
                    {
                      "t-border-red-30": isDirectorDataMissing,
                    }
                  )}
                >
                  <div className="t-flex t-justify-between">
                    <div className="t-text-body">
                      {
                        repeatableField?.selected_autofill_details
                          ?.persona_autofill_string
                      }
                    </div>
                    <div className="t-flex t-gap-2">
                      <Button
                        onClick={() =>
                          openEditDirectorModal(
                            repeatableField?.selected_autofill_details
                              ?.director_autofill_uuid!
                          )
                        }
                        customType="transparent"
                        size="small"
                        type="button"
                      >
                        <img
                          src={GrayPencil}
                          className="t-h-4 t-m-1"
                          alt="GrayPencil"
                        />
                      </Button>
                      {!isOneDirectorRemaining && (
                        <Button
                          onClick={() =>
                            openDeleteDirector(repeatableField?.id!)
                          }
                          customType="transparent"
                          size="small"
                          type="button"
                        >
                          <span className="t-text-text-30">
                            <Trash />
                          </span>
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="t-text-caption t-text-text-30 t-w-4/5">
                    {
                      repeatableField?.selected_autofill_details
                        ?.address_autofill_string!
                    }
                  </div>
                </div>
                {isDirectorDataMissing && (
                  <div className="t-text-red t-text-body-sm t-mt-1 t-flex t-items-center t-gap-1">
                    <img src={RedInfo} alt="RedInfo" />
                    <div>
                      Please update missing information. Edit to complete the
                      required fields
                    </div>
                  </div>
                )}
              </div>
            );
          }
        )}
      </div>
      <DeleteDirector
        isOpen={showDeleteDirectorModal}
        close={closeDeleteDirector}
        handleDeleteDirector={
          deletedDirectorUuid
            ? updateTaskDirectors
            : handleDeleteDirectorFromSelect
        }
        isLoading={isUpdatingTask || isDeleting}
      />
      {showAddDirectorModal && (
        <AddFilingDirectorModal
          open={showAddDirectorModal}
          onClose={onAddDirectorClose}
          groupId={groupId}
          entityId={entityId}
          directorUuid={directorIdForEdit}
          updateTaskDirector={(editedDirectorDetails: {
            persona_autofill: any;
            address: any;
          }) => {
            editDirector({ directorIdForEdit, editedDirectorDetails });
            onAddDirectorClose();
          }}
          isLoading={isUpdatingTask}
          onDirectorCreate={onDirectorCreate}
          title={directorIdForEdit ? "Edit Director" : "Add New Director"}
          autofillKey="DIRECTOR"
        />
      )}
    </>
  );
};
