import {
  deleteMerchantFile,
  updateMerchantForm,
  updateTaskMerchant,
} from "apis/merchants";
import { useDocPreview } from "hooks/useDocPreview";
import { useToast } from "hooks/useToast";
import { FC, useState } from "react";
import { Merchant, W_FORM_TYPES } from "types/Models/merchant";
import Modal from "./DesignSystem/Modal/Modal";
import { WSeriesUploadForm } from "./WSeriesUploadForm";
import RadioGroup from "./DesignSystem/RadioGroup/RadioGroup";
import { Button } from "./DesignSystem/Button/Button";
import { FileInput } from "./FileInput/FileInput";
import { FormikForm } from "./FormikForm/FormikForm";

type IWSeriesFormUploadProps = {
  show: boolean;
  closeModal: () => void;
  taskId?: string;
  groupId: string;
  merchant: Merchant;
  editable: boolean;
  updateMerchant: (merchant: Merchant) => void;
  openModal: () => void;
  season: string;
};

export const WSeriesFormUpload: FC<IWSeriesFormUploadProps> = ({
  taskId,
  groupId,
  show,
  closeModal,
  merchant,
  editable = true,
  updateMerchant,
  openModal,
  season,
}) => {
  const { w_form_document, w_form_choice, uuid, name } = merchant || {};
  const { alertToast, successToast } = useToast();
  const openPreview = useDocPreview();
  const [isDeleting, setDeleting] = useState(false);

  const onFormTypeSubmit = async ({
    form_type,
    file,
  }: {
    form_type: W_FORM_TYPES;
    file?: File;
  }) => {
    try {
      if (taskId) {
        const { data } = await updateTaskMerchant({
          taskId,
          groupId,
          merchantId: uuid,
          form_type,
          file,
          season,
        });
        updateMerchant(data);
      } else {
        const { data } = await updateMerchantForm({
          // do we need it here
          groupId,
          merchantId: uuid,
          form_type,
          file,
          season,
        });
        updateMerchant(data);
      }
      closeModal();
      successToast({ message: "Your form has been saved" });
    } catch (error: any) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const onDelete = async () => {
    try {
      setDeleting(true);
      const { data } = await deleteMerchantFile({
        groupId,
        merchantId: uuid,
        season: merchant.w_form_season,
      });
      updateMerchant(data);
      setDeleting(false);
      successToast({ message: "Form has been removed" });
    } catch (error: any) {
      alertToast({ message: error?.response?.data?.error?.message });
      setDeleting(false);
    }
  };

  const fileToShow = w_form_document;

  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content size="large">
          <FormikForm
            // @ts-ignore
            onSubmit={onFormTypeSubmit}
            initialValues={{
              form_type: w_form_choice || "W-8BEN-E",
              file: fileToShow ? new File([], fileToShow.name) : undefined,
            }}
          >
            {({ values, isSubmitting, setFieldValue }) => (
              <>
                <Modal.Header>
                  <Modal.Title>Upload W-form for {name}</Modal.Title>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body className="t-flex t-flex-col t-gap-6">
                  <RadioGroup.Root
                    value={values.form_type}
                    onValueChange={(value) => {
                      setFieldValue("form_type", value);
                    }}
                  >
                    <RadioGroup.Content className="t-flex t-flex-col t-gap-5">
                      <p className="t-m-0 t-text-text-60 t-text-body">
                        Select the form you're trying to upload{" "}
                        <span className="t-text-red">*</span>
                      </p>
                      <RadioGroup.Item
                        align="start"
                        value="W-9"
                        className="!t-h-auto"
                      >
                        <div>
                          <p className="t-m-0 t-text-body">W-9</p>
                          <p className="t-m-0 t-text-text-30  t-text-body-sm">
                            Used by US persons or entities to provide their TIN
                            for tax reporting purposes.
                          </p>
                        </div>
                      </RadioGroup.Item>

                      <RadioGroup.Item
                        align="start"
                        value="W-8BEN"
                        className="!t-h-auto"
                      >
                        <div>
                          <p className="t-m-0 t-text-body">W-8 BEN</p>
                          <p className="t-m-0 t-text-text-30  t-text-body-sm">
                            Used by non-US individuals to certify foreign status
                            and claim tax treaty benefits.
                          </p>
                        </div>
                      </RadioGroup.Item>

                      <RadioGroup.Item
                        align="start"
                        value="W-8BEN-E"
                        className="!t-h-auto"
                      >
                        <div>
                          <p className="t-m-0 t-text-body">W-8 BEN-E</p>
                          <p className="t-m-0 t-text-text-30  t-text-body-sm">
                            Used by non-US entities to certify foreign status
                            and claim tax treaty benefits.
                          </p>
                        </div>
                      </RadioGroup.Item>
                    </RadioGroup.Content>
                  </RadioGroup.Root>

                  <div>
                    <FileInput
                      onFileClick={() => openPreview(w_form_document?.uuid!)}
                      onDelete={onDelete}
                      name="file"
                      withForm
                      required
                      label="Upload W-Form"
                      isDeleting={isDeleting}
                      accept={{ "application/pdf": [".pdf"] }}
                      file={fileToShow}
                    />
                  </div>
                </Modal.Body>
                <Modal.FooterButtonGroup>
                  <Modal.RawClose asChild>
                    <Button customType="secondary">Cancel</Button>
                  </Modal.RawClose>
                  <Button
                    disabled={
                      (!Boolean(values["file"]) && !Boolean(w_form_document)) ||
                      values["form_type"] === "NOT_SPECIFIED" ||
                      isSubmitting
                    }
                    type="submit"
                    isLoading={isSubmitting}
                    customType="primary"
                  >
                    Save
                  </Button>
                </Modal.FooterButtonGroup>
              </>
            )}
          </FormikForm>
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};
