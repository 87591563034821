import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BlueInfo from "static/images/InfoBlue.svg";
import "static/styles/components/taskContractors.css";
import { useGetTaskMerchantsQuery } from "store/apis/taskMerchants";
import { closeAddModal, openAddVendorModal } from "store/slices/vendors";
import { RootState } from "store/store";
import { AnalyseBankStatement } from "./AnalyseBankStatement";
import Loader from "./design/loader";
import { Button } from "./DesignSystem/Button/Button";
import { NoContractor } from "./NoContractor";
import { IncompleteBooksOnboarding } from "./Task1099/IncompleteBooksOnboarding";
import { AddMerchant } from "./Vendors/AddMerchant";
import { useState } from "react";
import { MerchantTable } from "./Vendors/merchantTable";

// const W_FORM_TEXT_MAP = {
//   [RECEIVED]: "Received",
//   [SENT]: "Sent",
//   [NOT_SENT]: "Not sent",
//   [FORM_REQUIRED]: "Form required",
//   [AWAITING]: "Awaiting",
//   [UPLOADED]: "Uploaded",
//   [SUBMITTED]: "Submitted",
// };

// const W_FORM_STATUS_COLORS = {
//   [RECEIVED]: "green",
//   [SENT]: "green",
//   [NOT_SENT]: "red",
//   [FORM_REQUIRED]: "red",
//   [AWAITING]: "orange",
//   [UPLOADED]: "green",
//   [SUBMITTED]: "green",
// };

export const WSeriesForm = ({
  withFormProgress,
  groupId,
  noContractorsFound,
  taskSeason,
  isSuccessPage = false,
  shouldLoadBooksOnboarding,
  entityId,
}: {
  withFormProgress?: boolean;
  groupId: string;
  noContractorsFound: boolean;
  taskSeason: string;
  isSuccessPage?: boolean;
  shouldLoadBooksOnboarding: boolean;
  entityId: string;
}): JSX.Element | null => {
  const { taskId } = useParams<{ taskId: string }>();

  const [sorting, setSorting] = useState([
    {
      id: "AMOUNT",
      desc: false,
    },
  ]);

  const { data: merchants = [], isLoading: isContractorLoading } =
    useGetTaskMerchantsQuery({
      groupId,
      taskId,
      season: taskSeason,
      entityId,
    });

  const { showAddModal } = useSelector((state: RootState) => state.vendors);

  const dispatch = useDispatch();

  const closeAddMerchantModal = () => {
    dispatch(closeAddModal());
  };

  if (isContractorLoading) {
    return <Loader />;
  }

  if (shouldLoadBooksOnboarding) {
    return <IncompleteBooksOnboarding groupId={groupId} />;
  }

  if (noContractorsFound && merchants?.length === 0) {
    return <NoContractor />;
  }

  if (merchants?.length === 0) {
    return <AnalyseBankStatement season={taskSeason} />;
  }

  const tableContent = (
    //@ts-ignore
    <MerchantTable
      merchants={merchants}
      groupId={groupId}
      showHeader={false}
      sorting={sorting}
      setSorting={setSorting}
      entityId={entityId}
    />
  );
  if (isSuccessPage) {
    return <div className="t-w-full">{tableContent}</div>;
  }

  return (
    <div className="task-contractors pb-3 overflow-auto t-mx-4">
      <div className="t-my-4">
        <div className="t-flex t-justify-between t-items-center t-mb-3">
          <div className="t-text-subtitle-sm t-text-text-100">
            W Series and Form 1099
          </div>
          <Button size="small" onClick={() => dispatch(openAddVendorModal())}>
            Add contractors
          </Button>
        </div>
        <div className="t-text-body t-text-text-60">
          Below is the list of contractors from your bank statements. Please add
          their contact details and collect W-series forms. If a contractor is
          missing, inform us in chat.
        </div>
      </div>

      {/* @ts-ignore */}
      {tableContent}
      <AddMerchant
        isOpen={showAddModal}
        groupId={groupId}
        closeModal={closeAddMerchantModal}
        season={taskSeason}
        close={closeAddMerchantModal}
        entityId={entityId}
      />
    </div>
  );
};
