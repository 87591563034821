import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { Label, TextInput } from "components/DesignSystem/TextInput/TextInput";
import { NumericInput } from "components/NumericInput/NumericInput";
import { PriceInput } from "components/PriceInput/PriceInput";
import { AddProductModal } from "components/MyProducts/AddProductModal";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { EnterIcon } from "components/icons/EnterIcon";
import { SparkleSolid } from "components/icons/Navbar/SparkleSolid";
import { DeleteIcon } from "components/icons/delete";
import { DRAFT } from "constants/invoiceStatuses";
import { PRODUCTS_AND_SERVICES } from "constants/subscriptionPermissionFeatures";
import { FieldArray, useFormikContext } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { useHasSubscriptionPermission } from "hooks/useHasSubscriptionPermission";
import { useModal } from "hooks/useModal";
import { useUpgradePlan } from "hooks/useUpgradePlan";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Invoice,
  InvoiceItem,
  useGetEntityInvoiceQuery,
} from "store/apis/invoices";
import { useGetGroupByProductsServicesQuery } from "store/apis/productAndServices";
import { ProductAndServices } from "types/Models/productAndServices";
import { debounce } from "utils/debouncing";
import { onEnterKey } from "utils/onEnterKey";

const ItemWrapper = ({
  children,
  invoiceItem,
  onNewProductCreateSuccess,
}: {
  children: ({
    isCustomInput,
    onChange,
  }: {
    isCustomInput: boolean;
    onChange: ({ checked }: { checked: boolean }) => void;
    createNewProduct: (inputValue: string) => void;
    addNewProductAndServicesModal: () => void;
  }) => React.ReactNode;
  invoiceItem: InvoiceItem;
  onNewProductCreateSuccess: ({ item }: { item: ProductAndServices }) => void;
}) => {
  const { description, product_data_id } = invoiceItem;
  const isCustomItem = Boolean(!product_data_id && description);
  const [isCustomInput, setIsCustomInput] = useState(isCustomItem);
  const [newProductName, setNewProductName] = useState<string>("");
  const addProductAndServicesModal = useModal();

  const onChange = ({ checked }: { checked: boolean }) => {
    setIsCustomInput(checked);
  };

  const createNewProduct = (inputValue: string) => {
    setNewProductName(inputValue);
    addProductAndServicesModal.open();
  };

  return (
    <>
      {children({
        isCustomInput,
        onChange,
        createNewProduct,
        addNewProductAndServicesModal: addProductAndServicesModal.open,
      })}
      <Modal.Root
        open={addProductAndServicesModal.isOpen}
        onOpenChange={addProductAndServicesModal.close}
        modal={false}
      >
        <Modal.Content useCustomOverlay>
          <AddProductModal
            name={newProductName}
            close={() => {
              addProductAndServicesModal.close();
              setNewProductName("");
            }}
            isOpen={addProductAndServicesModal.isOpen}
            onSuccess={({ item }) => {
              onNewProductCreateSuccess({ item });
            }}
          />
        </Modal.Content>
      </Modal.Root>
    </>
  );
};

export const InvoiceItems = () => {
  const { values, setFieldValue } = useFormikContext<Partial<Invoice>>();
  const group = useCurrentGroup();
  const groupId = group.uuid;
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const entityId = useCurrentEntityId();
  const { data, isLoading: isProductsLoading } =
    useGetGroupByProductsServicesQuery(
      {
        groupId: groupId!,
        entityId,
      },
      {
        skip: !groupId || !entityId,
      }
    );

  const { products = [], services = [] } = data || {};

  const productoptions = products.map(({ name, uuid }) => ({
    label: name,
    value: uuid,
  }));

  const serviceoptions = services.map(({ name, uuid }) => ({
    label: name,
    value: uuid,
  }));

  const options = [
    { label: "Products", options: productoptions },
    {
      label: "Services",
      options: serviceoptions,
    },
  ];

  const { data: invoice } = useGetEntityInvoiceQuery(
    {
      groupId: group?.uuid!,
      entityId: entityId!,
      invoiceId,
    },
    { skip: !group?.uuid || !entityId }
  );

  const { upgrade } = useUpgradePlan();

  const permission = useHasSubscriptionPermission({
    feature: PRODUCTS_AND_SERVICES,
  });

  if (!values?.invoice_items) {
    return null;
  }
  return (
    <div className="t-flex t-flex-col t-gap-6">
      <FieldArray
        name="invoice_items"
        render={({ remove, push }) => (
          <>
            <div>
              <div
                className="t-flex t-flex-col t-gap-5"
                onKeyDown={onEnterKey(() =>
                  push({
                    description: "",
                    quantity: 1,
                    rate: 0.0,
                    uuid: "",
                    product_data_id: null,
                  })
                )}
              >
                {values.invoice_items &&
                  values.invoice_items.map((invoiceItem, index) => (
                    <ItemWrapper
                      key={index}
                      invoiceItem={invoiceItem}
                      onNewProductCreateSuccess={debounce(
                        ({ item: { uuid } }) => {
                          setFieldValue(
                            `invoice_items.${index}.product_data_id`,
                            uuid
                          );
                        }
                      )}
                    >
                      {({
                        isCustomInput,
                        onChange,
                        createNewProduct,
                        addNewProductAndServicesModal,
                      }) => (
                        <div className="t-flex t-flex-col t-gap-1">
                          <div className="t-grid t-grid-cols-[2fr_1fr_1fr_0.5fr] t-gap-3 t-items-end">
                            <div>
                              <Label
                                required
                                htmlFor={
                                  permission?.blocked || isCustomInput
                                    ? `invoice_items.${index}.description`
                                    : `invoice_items.${index}.uuid`
                                }
                              >
                                Item
                              </Label>
                              {permission?.blocked || isCustomInput ? (
                                <TextInput
                                  customSize="small"
                                  name={`invoice_items.${index}.description`}
                                  required
                                  onChange={() => {
                                    setFieldValue(
                                      `invoice_items.${index}.product_data_id`,
                                      null
                                    );
                                  }}
                                />
                              ) : (
                                <Combobox
                                  withForm
                                  size="small"
                                  name={`invoice_items.${index}.product_data_id`}
                                  required
                                  creatable
                                  onCreateOption={(inputValue) => {
                                    createNewProduct(inputValue);
                                  }}
                                  onChange={(value) => {
                                    if (value instanceof Array) {
                                      return;
                                    }
                                    const currentItem = [
                                      ...products,
                                      ...services,
                                    ].find(({ uuid }) => uuid === value?.value);

                                    setFieldValue(
                                      `invoice_items.${index}.rate`,
                                      currentItem?.price || 0
                                    );
                                  }}
                                  isLoading={isProductsLoading}
                                  isDisabled={isProductsLoading}
                                  options={options}
                                  isClearable={false}
                                  value={[
                                    ...productoptions,
                                    ...serviceoptions,
                                  ].find(
                                    ({ value }) =>
                                      value === invoiceItem.product_data_id
                                  )}
                                  actions={
                                    <Button
                                      size="small"
                                      customType="link"
                                      type="button"
                                      onClick={addNewProductAndServicesModal}
                                    >
                                      Add Product
                                    </Button>
                                  }
                                />
                              )}
                            </div>
                            <div>
                              <NumericInput
                                storeNumeric
                                fieldProps={{
                                  name: `invoice_items.${index}.quantity`,
                                }}
                                numericProps={{
                                  customSize: "small",
                                }}
                                label="Quantity"
                                required
                              />
                            </div>
                            <div>
                              <PriceInput
                                customSize="small"
                                name={`invoice_items.${index}.rate`}
                                label="Rate"
                                required
                              />
                            </div>
                            <div>
                              <Button
                                onClick={() => remove(index)}
                                type="button"
                                customType="icon"
                                disabled={values.invoice_items?.length === 1}
                                size="small"
                              >
                                <DeleteIcon color="currentColor" />
                              </Button>
                            </div>
                          </div>
                          {!permission?.blocked && (
                            <Checkbox
                              name={`item_description_type.${index}`}
                              checked={isCustomInput}
                              onChange={({ target }) =>
                                onChange({ checked: target.checked })
                              }
                              label={
                                <ConditionalToolTip
                                  side="right"
                                  condition={
                                    <>
                                      Use this if you want to input custom text
                                      instead of
                                      <br />
                                      selecting/creating a product as a line
                                      item.
                                    </>
                                  }
                                >
                                  <span className="t-mt-1.5 t-text-body-sm t-text-text-30 t-leading-none">
                                    Add custom input instead
                                  </span>
                                </ConditionalToolTip>
                              }
                            />
                          )}
                        </div>
                      )}
                    </ItemWrapper>
                  ))}
              </div>

              {permission?.blocked &&
                permission?.subscription &&
                invoice?.status === DRAFT && (
                  <div className="t-flex t-gap-1 t-text-body-sm t-text-text-30 t-mt-1.5">
                    <span className="t-flex t-text-neutral-20">
                      <SparkleSolid />
                    </span>
                    <span>To link your products effortlessly, </span>
                    <Button
                      onClick={() =>
                        upgrade({ addon: permission?.subscription! })
                      }
                      size="small"
                      type="button"
                      customType="link"
                    >
                      <span className="t-text-body-sm t-font-normal">
                        upgrade to {permission?.subscription?.subscription_name}
                      </span>
                    </Button>
                  </div>
                )}
            </div>

            <div className="t-flex t-items-center t-gap-2">
              <Button
                type="button"
                customType="secondary"
                size="small"
                onClick={() =>
                  push({
                    description: "",
                    quantity: 1,
                    rate: 0.0,
                    uuid: "",
                    product_data_id: null,
                  })
                }
              >
                Add line item
              </Button>
              <span className=" t-text-button t-text-neutral-30">or</span>
              <div className="t-bg-neutral-0 t-justify-center t-items-center  t-rounded-full t-text-neutral-70 t-px-3 t-py-2 t-text-body-sm t-gap-2 t-flex">
                <EnterIcon />
                <span>Return</span>
              </div>
            </div>
          </>
        )}
      />
    </div>
  );
};
