import DashboardContainer from "components/dashboard/DashboardContainer";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { TableUI } from "components/design/TableUI";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Button } from "components/DesignSystem/Button/Button";
import { Chip } from "components/DesignSystem/Chips/Chips";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import {
  Filter,
  MultiSelectFilter,
} from "components/DesignSystem/Filter/Filter";
import { HighlightSearchTerm } from "components/DesignSystem/HighlightText";
import Modal from "components/DesignSystem/Modal/Modal";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import { OptionDropdown } from "components/icons/optionDropdown";
import { NoProductAndServices } from "components/Illustrations/NoProductAndServices";
import {
  PRODUCT_SERVICE_ITEM_TYPE,
  PRODUCT_SERVICE_SOURCE_TYPE,
} from "dictionaries";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useFilters } from "hooks/useFilter";
import { useModal } from "hooks/useModal";
import { usePagination } from "hooks/usePagination";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import React, { useState } from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import {
  createColumnHelper,
  getCoreRowModel,
  SortingState,
  useReactTable,
} from "react-table-8.10.7";
import {
  useDeleteProductAndServiceMutation,
  useGetProductAndServicesQuery,
  useGetProductCategoriesQuery,
} from "store/apis/productAndServices";
import { ProductAndServices } from "types/Models/productAndServices";
import { BackendError } from "types/utils/error";
import { useDebounce } from "utils/debounce";
import { AddProductModal } from "./AddProductModal";
import { EditProductAndServiceModal } from "./EditProductAndServiceModal";
import { ProductOrServiceSlider } from "./ProductOrServiceSlider";
import {
  sourceFilterOptions,
  typeFilterOptions,
} from "constants/productAndServices";
import { MyProducts as ProductAndServicesIcon } from "components/icons/LeftNav/Books/MyProducts";

const ProductAndServiceAction = ({
  productAndService,
}: {
  productAndService: ProductAndServices;
}) => {
  const deleteModal = useModal();
  const editModal = useModal();
  const { alertToast, successToast } = useToast();
  const { uuid, source } = productAndService;
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();
  const [deleteProductAndService, { isLoading: isDeleting }] =
    useDeleteProductAndServiceMutation();

  const onDelete = async () => {
    try {
      const { type } = await deleteProductAndService({
        groupId,
        entityId,
        productId: uuid,
      }).unwrap();
      deleteModal.close();

      successToast({ message: `${PRODUCT_SERVICE_ITEM_TYPE[type]} deleted!` });
    } catch (error) {
      alertToast(
        { message: (error as BackendError).data?.error?.message },
        error as {}
      );
    }
  };

  const isManual = source === "MANUAL";

  return (
    <span onClick={(e) => e.stopPropagation()}>
      <Dropdown.Root>
        <Dropdown.Trigger asChild>
          <span>
            <Button size="small" customType="ghost_icon">
              <OptionDropdown color="currentColor" />
            </Button>
          </span>
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <Dropdown.Content align="end" className="!t-min-w-40">
            <Dropdown.Item type="ordinary" onSelect={editModal.open}>
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              type="danger"
              onSelect={deleteModal.open}
              disabled={!isManual}
            >
              <ConditionalToolTip
                condition={!isManual && "Cannot delete Stripe product/serivce"}
              >
                <span>Delete</span>
              </ConditionalToolTip>
            </Dropdown.Item>
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>

      <Modal.Root open={deleteModal.isOpen} onOpenChange={deleteModal.close}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Are you sure?</Modal.Title>
            <Modal.Close disabled={isDeleting} />
          </Modal.Header>
          <Modal.Body>
            The selected item will be permanently deleted. This action cannot be
            undone.
          </Modal.Body>
          <Modal.FooterButtonGroup>
            <Modal.RawClose asChild disabled={isDeleting}>
              <Button>Cancel</Button>
            </Modal.RawClose>
            <Button
              customType="danger"
              onClick={onDelete}
              isLoading={isDeleting}
              disabled={isDeleting}
            >
              Confirm
            </Button>
          </Modal.FooterButtonGroup>
        </Modal.Content>
      </Modal.Root>
      <EditProductAndServiceModal
        isOpen={editModal.isOpen}
        close={editModal.close}
        itemId={uuid}
      />
    </span>
  );
};

export const ProductAndServicesList = () => {
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const { search } = useLocation();
  const addProductAndServicesModal = useModal();
  const query = useQuery();
  const { update } = useUpdateQuery();
  const page = query.get("page");
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();

  const searchValue = query.get("search");
  const searchTerm = useDebounce(searchValue);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    update({ query: "search", value: e.target.value });
  };

  const { data: categories = [] } = useGetProductCategoriesQuery();

  const filterOptionsWithCategories = {
    SOURCES: sourceFilterOptions,
    TYPE: typeFilterOptions,
    CATEGORY: categories.map((category) => ({
      value: category.uuid,
      label: category.name,
    })),
  };

  const { values: filterValues, updateFilter } = useFilters({
    initialValue: {
      SOURCES: [] as string[],
      TYPE: [] as string[],
      CATEGORY: [] as string[],
    },
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  const sortCol = sorting[0]?.id;
  const sortOrder = sorting[0]?.desc;

  const { data, isLoading, isSuccess } = useGetProductAndServicesQuery(
    {
      groupId,
      entityId,
      pageNum: Number(page) || 1,
      searchTerm,
      sources: filterValues.SOURCES.join(","),
      productTypes: filterValues.TYPE.join(","),
      productCategories: filterValues.CATEGORY.join(","),
      sortCol: sortCol ? (sortCol as "PRICE" | "NAME") : undefined,
      sortOrder: sortOrder ? ("DSC" as const) : ("ASC" as const),
    },
    {
      skip: !entityId || !groupId,
    }
  );

  const {
    products = [],
    current_page = 1,
    per_page = 1,
    total_count = 1,
    total_pages = 1,
  } = data || {};

  const { goToFirstPage, goToLastPage, goToNextPage, goToPrevPage } =
    usePagination({
      totalPage: total_pages,
      pageNumber: current_page,
      onPageNumChange: (page) => update({ query: "page", value: page }),
    });

  const createColumn = createColumnHelper<ProductAndServices>();

  const columns = [
    createColumn.accessor("uuid", {
      size: 1,
      header: "NO.",
      enableSorting: false,
      cell: ({ row }) => {
        return row.index + 1;
      },
    }),

    createColumn.accessor("name", {
      size: 25,
      header: "NAME",
      id: "NAME",
      enableSorting: true,
      cell: (row) => {
        return (
          <HighlightSearchTerm
            text={row.getValue()}
            searchTerm={searchTerm || ""}
          />
        );
      },
    }),

    createColumn.accessor("type", {
      size: 10,
      header: "TYPE",
      enableSorting: false,
      cell: (row) => {
        return PRODUCT_SERVICE_ITEM_TYPE[row.getValue()];
      },
    }),

    createColumn.accessor("product_category_name", {
      size: 25,
      header: "GROUP",
      enableSorting: false,
      cell: (row) => {
        return row.getValue() || "-";
      },
    }),

    createColumn.accessor("price", {
      size: 10,
      header: () => (
        <span className="t-flex t-justify-end t-ml-auto">PRICE</span>
      ),
      enableSorting: true,
      id: "PRICE",
      cell: (info) => {
        return (
          <div className="t-flex t-justify-end t-whitespace-nowrap">
            <AmountSuperScript amount={info.getValue()} />
          </div>
        );
      },
    }),
    createColumn.accessor("logo_url", {
      size: 10,
      header: () => <div className="t-flex-1 t-text-center">SOURCE</div>,
      enableSorting: false,
      cell: (info) => {
        const { source, logo_url } = info.row.original;

        return (
          <div className="t-flex t-justify-center">
            <ConditionalToolTip condition={PRODUCT_SERVICE_SOURCE_TYPE[source]}>
              {logo_url ? (
                <Avatar size="small" src={logo_url} alt={source!} />
              ) : (
                <span
                  className="t-rounded-full t-bg-white t-overflow-hidden t-min-h-6 t-min-w-6 t-max-h-6 t-max-w-6 t-text-neutral-40 t-border-neutral-0 t-border t-border-solid t-text-center"
                  role="img"
                >
                  <ProductAndServicesIcon />
                </span>
              )}
            </ConditionalToolTip>
          </div>
        );
      },
    }),

    createColumn.accessor("uuid", {
      size: 5,
      header: "",
      enableSorting: false,
      cell: (info) => {
        return (
          <ProductAndServiceAction productAndService={info.row.original} />
        );
      },
    }),
  ];

  const table = useReactTable({
    data: products,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
    manualSorting: true,
    enableMultiSort: false,
    enableSortingRemoval: false,
    onSortingChange: (newSorting) => {
      setSorting(newSorting);
    },
    state: {
      sorting,
    },
  });

  return (
    <>
      <DashboardContainer className="t-relative t-gap-5">
        <DashboardContainer.Header className="t-sticky t-top-0 t-z-header t-flex t-flex-col t-gap-3 t-bg-white">
          <div className="t-flex t-justify-between">
            <div className="t-w-1/2">
              <Search
                block
                onChange={onSearch}
                value={searchValue || ""}
                placeholder="Search"
              />
            </div>
            <Modal.Root
              open={addProductAndServicesModal.isOpen}
              onOpenChange={addProductAndServicesModal.toggle}
              modal={false}
            >
              <Modal.Trigger asChild>
                <Button size="small" customType="primary">
                  Add Product
                </Button>
              </Modal.Trigger>
              <Modal.Content useCustomOverlay>
                <AddProductModal {...addProductAndServicesModal} />
              </Modal.Content>
            </Modal.Root>
          </div>
          <div className="t-flex t-justify-between">
            <Filter.Root
              defaultValue="TYPE"
              capsule={
                <>
                  {filterValues.SOURCES.map((value) => (
                    <Chip
                      key="SOURCES"
                      onClose={() => {
                        updateFilter(
                          "SOURCES",
                          filterValues.SOURCES.filter((v) => v !== value)
                        );
                      }}
                      isActive
                      filterType="SOURCES"
                    >
                      <span className="first-letter:t-uppercase t-lowercase">
                        {value}
                      </span>
                    </Chip>
                  ))}

                  {filterValues.TYPE.map((value) => (
                    <Chip
                      key="TYPE"
                      onClose={() => {
                        updateFilter(
                          "TYPE",
                          filterValues.TYPE.filter((v) => v !== value)
                        );
                      }}
                      isActive
                      filterType="TYPE"
                    >
                      <span className="first-letter:t-uppercase t-lowercase">
                        {value}
                      </span>
                    </Chip>
                  ))}

                  {filterValues.CATEGORY.map((value) => (
                    <Chip
                      key="CATEGORY"
                      onClose={() => {
                        updateFilter(
                          "CATEGORY",
                          filterValues.CATEGORY.filter((v) => v !== value)
                        );
                      }}
                      isActive
                      filterType="CATEGORY"
                    >
                      <span className="first-letter:t-uppercase t-lowercase">
                        {
                          filterOptionsWithCategories.CATEGORY.find(
                            (category) => category.value === value
                          )?.label
                        }
                      </span>
                    </Chip>
                  ))}
                </>
              }
            >
              <Filter.Portal>
                <Filter.List>
                  <Filter.ListItem value="TYPE">Type</Filter.ListItem>
                  <Filter.ListItem value="CATEGORY">Category</Filter.ListItem>
                  <Filter.ListItem value="SOURCES">Source</Filter.ListItem>
                </Filter.List>
                {Object.keys(filterValues).map((key) => {
                  const filter = key as keyof typeof filterValues;
                  return (
                    <Filter.Body value={filter} block key={filter}>
                      <MultiSelectFilter
                        onChange={(newValues) =>
                          updateFilter(filter, newValues)
                        }
                        isSearchable={filter === "CATEGORY"}
                        options={filterOptionsWithCategories[filter]}
                        selected={filterValues[filter]}
                      />
                    </Filter.Body>
                  );
                })}
              </Filter.Portal>
            </Filter.Root>
            <Pagination
              currentPage={current_page}
              itemsPerPage={per_page}
              totalItemCount={total_count}
              totalPage={total_pages}
              goToFirstPage={goToFirstPage}
              goToPrevPage={goToPrevPage}
              goToNextPage={goToNextPage}
              goToLastPage={goToLastPage}
            />
          </div>
        </DashboardContainer.Header>
        <DashboardContainer.Content>
          <Async.Root
            isLoading={isLoading}
            isSuccess={isSuccess}
            isEmpty={products.length === 0}
          >
            <Async.Empty>
              <EmptyScreen
                text=""
                cta={
                  <>
                    {searchTerm && (
                      <Button
                        onClick={() => update({ query: "search", value: "" })}
                        size="small"
                        customType="primary"
                      >
                        Clear search
                      </Button>
                    )}
                  </>
                }
              >
                <div className="t-text-center t-flex t-gap-2 t-flex-col t-items-center">
                  <NoProductAndServices />
                  {searchTerm ? (
                    <div className="t-flex t-flex-col t-gap-2 t-justify-center t-items-center">
                      <p className="t-m-0 t-text-subtitle-sm">
                        No results found for your search
                      </p>
                      <p className="t-m-0 t-text-body-sm">
                        Try clearing the search.
                      </p>
                    </div>
                  ) : (
                    <div className="t-flex t-flex-col t-gap-2 t-justify-center t-items-center">
                      <p className="t-m-0 t-text-subtitle-sm">
                        No results found
                      </p>
                      <p className="t-m-0 t-text-body-sm">
                        Try clearing the filters.
                      </p>
                    </div>
                  )}
                </div>
              </EmptyScreen>
            </Async.Empty>
            <Async.Success>
              <TableUI
                table={table}
                onRowClick={(row) =>
                  history.push(`${url}/${row.original.uuid}${search}`)
                }
                enableSort
              />
            </Async.Success>
          </Async.Root>
        </DashboardContainer.Content>
      </DashboardContainer>
      <Switch>
        <Route path={`${path}/:productOrserviceId`}>
          <ProductOrServiceSlider
            onClose={() => history.push(`${url}${search}`)}
          />
        </Route>
      </Switch>
    </>
  );
};
