import classNames from "classnames";
import { HTMLAttributes, ReactNode } from "react";

export const InfoItem = ({
  label,
  children,
  dir = "col",
  ...rest
}: {
  label: string | ReactNode;
  children: ReactNode;
  dir?: "col" | "row";
} & HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={classNames("t-flex", {
        "t-flex-col t-gap-1": dir === "col",
        [rest.className || ""]: rest.className,
      })}
    >
      <span className="!t-pb-0 t-font-sans t-text-caption t-text-text-30">
        {label}
      </span>
      <span className="t-text-subtext">{children}</span>
    </div>
  );
};
