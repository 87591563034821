import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Info } from "components/icons/Info";
import { useModal } from "hooks/useModal";
import { Group } from "types/Models/group";
import { AccountInfoContent } from "./AccountInfoContent";
import { AccountOverview } from "components/icons/AccountOverview";

export const CompanyAccountInfo = ({ group }: { group: Group }) => {
  const infoModal = useModal();

  return (
    <Modal.Root onOpenChange={infoModal.toggle} open={infoModal.isOpen}>
      <Modal.Trigger asChild>
        <Button size="small" customType="transparent">
          <span>
            <ToolTip text="View Account Info">
              <span>
                <AccountOverview />
              </span>
            </ToolTip>
          </span>
        </Button>
      </Modal.Trigger>
      <Modal.Content useCustomOverlay size="xxxl">
        <Modal.Header>
          <Modal.Title>Company Account Overview</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <AccountInfoContent group={group} />
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Modal.RawClose asChild>
            <Button type="button">Cancel</Button>
          </Modal.RawClose>
          <Button customType="primary">Close</Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};
