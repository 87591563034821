import axios from "axios";
const baseURL = process.env.PUBLIC_BASE_URL;

export const getAccessTokenUsingRefreshToken = async ({ refresh }) => {
  const response = await axios.post(`${baseURL}api/token/refresh/`, {
    refresh: refresh,
  });

  return response.data;
};
