import { DashboardLayout } from "components/DashboardLayout";
import { InvoiceHeader } from "components/design/InvoiceHeader";
import Loader from "components/design/loader";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { Info } from "components/icons/Info";
import {
  BILL_STATUS,
  BILL_STATUS_LABEL,
  TAG_TYPE,
} from "constants/vendorBills";
import { Form, Formik } from "formik";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { PaymentDetails } from "pages/Books/BillsAndVendors/BillConsole";
import { BillPreivew } from "pages/Books/BillsAndVendors/BillPreview";
import { useHistory, useParams } from "react-router-dom";
import {
  PublicPayload,
  useGetPubilcBillQuery,
  useUpdatePubilcBillMutation,
} from "store/apis/vendorBills";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";

export const ApprovePublicBill = () => {
  const { billId } = useParams<{ billId: string }>();
  const { alertToast, successToast } = useToast();
  const approverModal = useModal();
  const history = useHistory();

  const { data, isLoading, isSuccess, isError, error } = useGetPubilcBillQuery(
    { billId },
    { skip: !billId }
  );
  const [updatePubilcBill, { isLoading: isUpdating }] =
    useUpdatePubilcBillMutation();

  const onApprove = async (values: PublicPayload) => {
    try {
      approverModal.close();
      await updatePubilcBill({
        billId,
        payload: {
          ...values,
          bill_request_status: BILL_STATUS.APPROVED,
        },
      });
      successToast({
        message: "Invoice approved",
      });
      history.push(`/vendor-bill/${billId}`);
    } catch (e) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as Error
      );
    }
  };

  const isApproved = data?.bill_request_status === BILL_STATUS.APPROVED;

  return (
    <Modal.Root open={true} modal={false}>
      <Async.Root
        isLoading={isLoading}
        isSuccess={isSuccess}
        isEmpty={false}
        isError={isError}
        customLoader={
          <Modal.Content size="fullscreen">
            <Loader />
          </Modal.Content>
        }
      >
        <Async.ErrorHandler>
          <Modal.Content size="fullscreen">
            <Modal.Body className="t-min-h-80 t-w-full t-h-full t-flex t-justify-center t-flex-col t-items-center t-gap-2 t-text-subtitle-sm t-text-red">
              <>
                {(error as BackendError)?.data?.error?.message ||
                  "Something went wrong, Please try again later."}
              </>
            </Modal.Body>
          </Modal.Content>
        </Async.ErrorHandler>
        <Async.Empty>
          <></>
        </Async.Empty>
        <Async.Success>
          {data && (
            <Formik<PublicPayload>
              initialValues={data}
              onSubmit={onApprove}
              enableReinitialize
            >
              {({ values, submitForm }) => (
                <Modal.Content
                  size="fullscreen"
                  className="t-grid t-grid-cols-2"
                >
                  <Form className="t-overflow-auto">
                    <DashboardLayout
                      className="t-pt-6"
                      header={
                        <InvoiceHeader
                          status={
                            <Tag
                              rounded
                              icon={false}
                              tagType={TAG_TYPE[data?.bill_request_status]}
                            >
                              {BILL_STATUS_LABEL[data?.bill_request_status]}
                            </Tag>
                          }
                          title={data?.bill_number}
                        />
                      }
                    >
                      <fieldset
                        disabled={isUpdating || isApproved}
                        className="disabled:t-opacity-60 disabled:t-cursor-not-allowed t-h-full t-flex t-flex-col t-gap-12 t-pb-40 t-relative"
                      >
                        <PaymentDetails paymentlink bill={values} />
                      </fieldset>
                    </DashboardLayout>
                    {!isApproved && (
                      <Modal.Footer className="t-flex t-gap-2 t-items-center t-justify-end t-fixed t-bottom-0 t-bg-white t-w-1/2 t-p-4 t-border-t">
                        <Modal.Root
                          open={approverModal.isOpen}
                          onOpenChange={approverModal.toggle}
                        >
                          <Modal.Trigger asChild>
                            <Button
                              customType="primary"
                              isLoading={isUpdating}
                              disabled={isUpdating}
                            >
                              <span className="t-flex t-items-center t-gap-1">
                                Approve
                              </span>
                            </Button>
                          </Modal.Trigger>
                          <ApproveInvoice
                            {...approverModal}
                            onApprove={submitForm}
                          />
                        </Modal.Root>
                      </Modal.Footer>
                    )}
                  </Form>

                  <DashboardLayout className="t-pt-6 t-bg-surface-lighter-grey">
                    <BillPreivew bill={values} />
                  </DashboardLayout>
                </Modal.Content>
              )}
            </Formik>
          )}
        </Async.Success>
      </Async.Root>
    </Modal.Root>
  );
};

const ApproveInvoice = ({
  onApprove,
}: ModalProps & { onApprove: () => void }) => {
  return (
    <Modal.Content size="small">
      <Modal.Header>
        <Modal.Title>Approve Invoice</Modal.Title>
        <Modal.Close />
      </Modal.Header>
      <Modal.Body className="t-text-subtext t-text-text-60">
        This action cannot be undone. Are you sure you want <br /> to approve
        the invoice?
        <br />
        <br />
        <span className="t-text-body-sm t-text-neutral-50 t-flex t-gap-1">
          <Info stroke="1" />
          <span>
            A copy of the finalized invoice will be sent to you via email once
            approved.
          </span>
        </span>
      </Modal.Body>
      <Modal.FooterButtonGroup>
        <Modal.RawClose asChild>
          <Button>Cancel</Button>
        </Modal.RawClose>
        <Button customType="primary" onClick={onApprove}>
          Approve
        </Button>
      </Modal.FooterButtonGroup>
    </Modal.Content>
  );
};
