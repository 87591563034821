import classNames from "classnames";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Divider } from "components/design/Divider";
import { FilledGreenCheck } from "components/icons/FilledGreenCheck";
import { Hourglass } from "components/icons/Hourglass";
import dayjs from "dayjs";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { parse } from "qs";
import { useLocation } from "react-router-dom";
import { BankTransferOrder } from "store/apis/bankTransfer";

const AwaitingDescription = () => {
  const { link } = useConstructInternalLink();
  const location = useLocation();
  const keysToPreserve = Object.keys(
    parse(location.search, { ignoreQueryPrefix: true })
  );

  const redirectLink = link(`/settings/billing/banktransfer`, {
    keys: keysToPreserve,
    moreQuery: {},
  });

  return (
    <div className="t-text-body-sm t-text-text-60">
      Payment status update may take upto 5 days to reflect. Find your bank
      transfers within the
      <a href={redirectLink} className="t-text-purple t-no-underline t-mx-1">
        orders
      </a>
      tab.
    </div>
  );
};

const titleMap = {
  CREATED: "Order launched for",
  PARTIALLY_FUNDED: "Partial payment received for",
  SETTLED: "Payment received",
  AWAITING_CONFIRMATION: "Awaiting confirmation",
  AWAITING_TRANSFER: "Awaiting transfer for",
};

const eventIconMap = {
  CREATED: <FilledGreenCheck />,
  PARTIALLY_FUNDED: <FilledGreenCheck />,
  SETTLED: <FilledGreenCheck />,
  AWAITING_CONFIRMATION: <Hourglass size={20} />,
  AWAITING_TRANSFER: <Hourglass size={20} />,
};

export const BankPaymentTracker = ({
  bankTransferOrder,
}: {
  bankTransferOrder: BankTransferOrder;
}) => (
  <div className="t-p-4 t-rounded-lg t-border t-border-solid t-border-neutral-10 t-bg-surface-lighter-grey">
    <div className="t-mb-3 t-text-subtext t-text-text-60">Payment Tracker</div>
    <Divider />
    <div className="t-mt-5">
      {bankTransferOrder.timelines.map((data, index) => {
        return (
          <div
            className="t-border t-border-solid t-border-r-0 t-border-t-0 t-border-b-0 t-border-neutral-10 t-flex t-relative t-ml-1.5 last:t-border-l-0"
            key={index}
          >
            <div
              className={classNames(
                "t-bg-surface-lighter-grey t-mb-1 t-max-h-6 t-absolute",
                {
                  "-t-ml-[11px] t-text-green-70":
                    data.event_type === "SETTLED" ||
                    data.event_type === "PARTIALLY_FUNDED" ||
                    data.event_type === "CREATED",
                  "-t-ml-[10px] t-text-green-70":
                    data.event_type === "AWAITING_CONFIRMATION" ||
                    data.event_type === "AWAITING_TRANSFER",
                }
              )}
            >
              {eventIconMap[data.event_type]}
            </div>
            <div
              className={classNames("t-ml-4", {
                "t-mb-7": index !== bankTransferOrder.timelines.length - 1,
              })}
            >
              <div className="t-mb-1 t-text-subtext-sm">
                {titleMap[data.event_type]}{" "}
                {data.amount && (
                  <b className="t-text-subtext-sm t-font-bold">
                    <AmountSuperScript amount={Number(data.amount)} />
                  </b>
                )}
              </div>
              {data.event_type === "AWAITING_CONFIRMATION" ||
              data.event_type === "AWAITING_TRANSFER" ? (
                <AwaitingDescription />
              ) : (
                <div className="t-text-body-sm t-text-text-60">
                  {dayjs(data.occurred_at).format("DD-MMM-YYYY h:mmA")}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  </div>
);
