import { DashboardLayout } from "components/DashboardLayout";
import { Header } from "components/DesignSystem/Header/Header";
import Tab from "components/DesignSystem/Tab/Tab";
import { FINANCIAL_RECORD_TYPE } from "constants/vendorBills";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { parse, stringify } from "qs";
import {
  matchPath,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { Vendors } from "../Vendors/Vendors";
import { AddBill } from "./AddBill";
import { Bills } from "./Bills";
import { RequestBill } from "./RequestBill";
import { SendBill } from "./SendBill";

export const BillsAndVendors = () => {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { pathname, search } = useLocation();
  const parsedSearch = parse(search, { ignoreQueryPrefix: true });
  const { link } = useConstructInternalLink();

  const searchQuery = stringify(
    {
      entity: parsedSearch.entity,
      company: parsedSearch.company,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  return (
    <Tab.Root asChild defaultValue={pathname} value="MATCH">
      <DashboardLayout
        header={
          <div className="t-sticky t-top-0">
            <Header
              v2
              title="Vendors & Bills"
              bottom={
                <Tab.List>
                  <Tab.NavTrigger
                    exact
                    to={`${url}/vendors${searchQuery}`}
                    value={
                      matchPath(pathname, {
                        path: `${url}/vendors`,
                      })
                        ? "MATCH"
                        : ""
                    }
                  >
                    Vendors
                  </Tab.NavTrigger>
                  <Tab.NavTrigger
                    exact
                    to={`${url}${searchQuery}`}
                    value={
                      matchPath(pathname, { path: url, exact: true })
                        ? "MATCH"
                        : ""
                    }
                  >
                    Bills
                  </Tab.NavTrigger>
                </Tab.List>
              }
            />
          </div>
        }
      >
        <Switch>
          <Route exact path={`${path}/vendors`}>
            <Vendors />
          </Route>
          <Route exact path={path}>
            <Bills />
          </Route>
          <Route path={`${path}/request/:billId`}>
            <RequestBill
              close={() =>
                history.push(
                  link(url, {
                    moreQuery: { BILL_TYPE: FINANCIAL_RECORD_TYPE.REQUEST },
                  })
                )
              }
            />
          </Route>
          <Route path={`${path}/send/:billId`}>
            <SendBill close={() => history.goBack()} />
          </Route>
          <Route path={`${path}/bill/:billId`}>
            <AddBill
              close={() =>
                history.push(
                  link(url, {
                    moreQuery: { BILL_TYPE: FINANCIAL_RECORD_TYPE.BILL },
                  })
                )
              }
            />
          </Route>
        </Switch>
      </DashboardLayout>
    </Tab.Root>
  );
};
