import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MONTHLY } from "constants/billingCycles";
import { BookkeepingProductType, Coupon } from "types/Models/subscription";

export type AddonData = {
  amount: number | null;
  billing_cycle: "MONTHLY" | "YEARLY" | "QUARTERLY";
  is_ra_subscribed?: boolean | null;
  description: string | null;
  is_redeemable?: boolean | null;
  stripe_coupon?: Coupon | null;
  stripe_price_id?: string | null;
  stripe_product_id?: string | null;
  subscription_name: string | null;
  subscription_type: string | null;
  tier_information?:
    | {
        lower_range: number;
        higher_range: number;
        amount: number;
        uuid: string;
      }[]
    | null;
  uuid: string | null;
  group_subscription?: any;
  dropdownContent?: [] | null;
  stateMappings?: [] | null;
  unselectedStates?: any;
  bookkeeping_product_type?: keyof typeof BookkeepingProductType;
  metadata?: { ra_state?: string };
  selectedState?: string | null;
  disableStateChange?: boolean;
  ra_state_amount_maps?: {
    ra_state: string;
    amount: string;
    uuid?: string;
  }[];
};

export type Addons = {
  addonData: AddonData | null;
  addonType: string;
  addonPayload: Record<string, string> | null;
  showCheckoutModal: boolean;
};

const initialState: Addons = {
  addonData: {
    amount: null,
    billing_cycle: MONTHLY,
    description: null,
    is_redeemable: null,
    stripe_coupon: null,
    stripe_price_id: null,
    stripe_product_id: null,
    subscription_name: null,
    subscription_type: null,
    tier_information: null,
    group_subscription: null,
    stateMappings: null,
    unselectedStates: [],
    uuid: null,
    selectedState: null,
    disableStateChange: false,
  },
  addonPayload: {},
  addonType: "",
  showCheckoutModal: false,
};

export const addonsSlice = createSlice({
  name: "addonsSlice",
  initialState,
  reducers: {
    setAddonData: (state, action: PayloadAction<any>) => {
      state.addonData = action.payload;
    },
    setAddonType: (state, action: PayloadAction<string>) => {
      state.addonType = action.payload;
    },
    setAddonPayload: (state, action: PayloadAction<any>) => {
      state.addonPayload = action.payload;
    },
    resetAllAddonDataPayload: (state) => {
      state.addonData = null;
      state.addonType = "";
      state.addonPayload = null;
    },
    resetAddonPayloadData: (state) => {
      state.addonPayload = null;
    },
    setOpenCheckoutModal: (state) => {
      state.showCheckoutModal = true;
    },
    setCloseCheckoutModal: (state) => {
      state.showCheckoutModal = false;
    },
  },
});

export const {
  setAddonData,
  setAddonType,
  setAddonPayload,
  resetAllAddonDataPayload,
  resetAddonPayloadData,
  setOpenCheckoutModal,
  setCloseCheckoutModal,
} = addonsSlice.actions;

export default addonsSlice.reducer;
