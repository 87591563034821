import { Button } from "components/DesignSystem/Button/Button";
import { CustomDataCta } from "types/Models/reviewDocumentAndBalancingPayment";

export const TicketCardBtn = ({
  cta,
  openPaymentModal,
  redirectToTicket,
  isPaymentRequire,
  isGettingInvoices,
}: {
  cta: CustomDataCta;
  openPaymentModal?: () => void;
  redirectToTicket?: (ticketId: string | undefined) => void;
  isPaymentRequire?: boolean;
  isGettingInvoices?: boolean;
}) => {
  switch (cta.document_operation) {
    case "VIEW_TICKET": {
      return (
        <Button
          size="small"
          block
          onClick={() => redirectToTicket?.(cta.metadata.ticket_id)}
        >
          {cta.url_text}
        </Button>
      );
    }
    case "PAY_TICKET": {
      return (
        <Button
          customType="primary"
          size="small"
          block
          onClick={openPaymentModal}
          isLoading={isGettingInvoices}
          disabled={isGettingInvoices}
        >
          {cta.url_text}
        </Button>
      );
    }
  }
  return <></>;
};
