import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { Label } from "components/InvoiceForm/InvoiceForm";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { Field, FieldProps, useFormikContext } from "formik";
import { useMemo, useState } from "react";
import { MultiValue, SingleValue } from "react-select";
import { formatDate } from "utils/formatDate";

export const DueDate = ({
  invoiceDate: invoiceDateFromProps,
  required,
  dueDate,
}: {
  invoiceDate?: string | null;
  required?: boolean;
  dueDate?: string | null;
}) => {
  const { setFieldValue } = useFormikContext();
  const invoiceDate = invoiceDateFromProps || undefined;
  const [selectingDate, setSelectingDate] = useState(false);

  const dates = useMemo(
    () => ({
      INVOICE_DATE: invoiceDate ? dayjs(invoiceDate) : null,
      NET_30: dayjs(invoiceDate).add(30, "days"),
      NET_60: dayjs(invoiceDate).add(60, "days"),
      NET_90: dayjs(invoiceDate).add(90, "days"),
    }),
    [invoiceDate]
  );

  const options = useMemo(() => {
    return [
      ...(invoiceDate
        ? [
            {
              label: <Label info="Due on receipt">Invoice date</Label>,
              value: dayjs(dates.INVOICE_DATE).format(YYYY_MM_DD),
            },
          ]
        : []),
      {
        label: <Label info="Net 30">{formatDate(dates.NET_30)}</Label>,
        value: dayjs(dates.NET_30).format(YYYY_MM_DD),
      },
      {
        label: <Label info="Net 60">{formatDate(dates.NET_60)}</Label>,
        value: dayjs(dates.NET_60).format(YYYY_MM_DD),
      },
      {
        label: <Label info="Net 90">{formatDate(dates.NET_90)}</Label>,
        value: dayjs(dates.NET_90).format(YYYY_MM_DD),
      },
      {
        label: (
          <Label info={dueDate ? "Custom" : ""}>
            {dueDate ? formatDate(dueDate) : "Custom"}
          </Label>
        ),
        value: "CUSTOM",
      },
    ];
  }, [dates, dueDate]);

  const handleChange = (
    newValue: SingleValue<OptionData> | MultiValue<OptionData>
  ) => {
    if (newValue) {
      const value = (newValue as OptionData).value;
      if (value === "CUSTOM") {
        setSelectingDate(true);
        return;
      }
      setFieldValue("due_date", value);
    }
  };

  const currentValue = dueDate
    ? options.find((option) => option.value === dueDate) || options.at(-1)
    : null;

  if (selectingDate) {
    return (
      <Field
        name="due_date"
        placeholder="Select a due date"
        required={required}
      >
        {({ field }: FieldProps) => {
          return (
            <DateInput
              customSize="small"
              customInput
              open={selectingDate}
              onClickOutside={() => {
                setSelectingDate(false);
              }}
              {...field}
              onDateChange={() => {
                setSelectingDate(false);
              }}
              customDatePickerInputTrigger={
                <Combobox
                  onChange={handleChange}
                  label="Due Date"
                  isClearable={false}
                  options={options}
                  required={required}
                  isSearchable={false}
                  value={currentValue}
                  placeholder={DD_MMM_YYYY}
                  size="small"
                />
              }
              label="Due Date"
              placeholder={DD_MMM_YYYY}
              required={required}
              minDate={invoiceDate ? new Date(invoiceDate) : null}
            />
          );
        }}
      </Field>
    );
  }

  return (
    <Combobox
      onChange={handleChange}
      label="Due Date"
      isClearable={false}
      options={options}
      required={required}
      isSearchable={false}
      value={currentValue}
      placeholder={DD_MMM_YYYY}
      menuPortalTarget={document.body}
      size="small"
    />
  );
};
