import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";
import { TasksStatusType } from "types/Models/group";

export const Tasks = ({ taskData }: { taskData: TasksStatusType }) => {
  return (
    <div className="t-rounded-lg t-border-neutral-10 t-border t-border-solid t-flex-1">
      <div className="!t-border-b t-border-0 t-border-neutral-10 t-p-4 t-border-solid t-text-subtitle">
        Tasks
      </div>
      <div className="t-p-4 t-grid t-grid-cols-2 t-gap-6">
        <InfoItem label="Unassigned Team">
          {taskData.unassigned_team_tasks}
        </InfoItem>
        <InfoItem label="Unassigned Preparer">
          {taskData.unassigned_preparer_tasks}
        </InfoItem>
      </div>
    </div>
  );
};
