import React from "react";

export const DottedCircle = ({ size = "20" }: { size?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
    >
      <circle
        cx="10"
        cy="10"
        r="9.3"
        fill="#F3F3F5"
        stroke="#BBA7FF"
        strokeWidth="1.4"
        strokeDasharray="4 4"
      />
    </svg>
  );
};
