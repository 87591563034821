import React from "react";

export const SmallMultipleFiles = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M13.3538 4.14625L10.8538 1.64625C10.8073 1.59983 10.7521 1.56303 10.6914 1.53793C10.6307 1.51284 10.5657 1.49995 10.5 1.5H5.5C5.23478 1.5 4.98043 1.60536 4.79289 1.79289C4.60536 1.98043 4.5 2.23478 4.5 2.5V3.5H3.5C3.23478 3.5 2.98043 3.60536 2.79289 3.79289C2.60536 3.98043 2.5 4.23478 2.5 4.5V13.5C2.5 13.7652 2.60536 14.0196 2.79289 14.2071C2.98043 14.3946 3.23478 14.5 3.5 14.5H10.5C10.7652 14.5 11.0196 14.3946 11.2071 14.2071C11.3946 14.0196 11.5 13.7652 11.5 13.5V12.5H12.5C12.7652 12.5 13.0196 12.3946 13.2071 12.2071C13.3946 12.0196 13.5 11.7652 13.5 11.5V4.5C13.5001 4.43432 13.4872 4.36927 13.4621 4.30858C13.437 4.24788 13.4002 4.19272 13.3538 4.14625ZM10.5 13.5H3.5V4.5H8.29313L10.5 6.70687V11.99C10.5 11.9937 10.5 11.9969 10.5 12C10.5 12.0031 10.5 12.0063 10.5 12.01V13.5ZM12.5 11.5H11.5V6.5C11.5001 6.43432 11.4872 6.36927 11.4621 6.30858C11.437 6.24788 11.4002 6.19272 11.3538 6.14625L8.85375 3.64625C8.80728 3.59983 8.75212 3.56303 8.69143 3.53793C8.63073 3.51284 8.56568 3.49995 8.5 3.5H5.5V2.5H10.2931L12.5 4.70688V11.5ZM9 9.5C9 9.63261 8.94732 9.75979 8.85355 9.85355C8.75979 9.94732 8.63261 10 8.5 10H5.5C5.36739 10 5.24021 9.94732 5.14645 9.85355C5.05268 9.75979 5 9.63261 5 9.5C5 9.36739 5.05268 9.24021 5.14645 9.14645C5.24021 9.05268 5.36739 9 5.5 9H8.5C8.63261 9 8.75979 9.05268 8.85355 9.14645C8.94732 9.24021 9 9.36739 9 9.5ZM9 11.5C9 11.6326 8.94732 11.7598 8.85355 11.8536C8.75979 11.9473 8.63261 12 8.5 12H5.5C5.36739 12 5.24021 11.9473 5.14645 11.8536C5.05268 11.7598 5 11.6326 5 11.5C5 11.3674 5.05268 11.2402 5.14645 11.1464C5.24021 11.0527 5.36739 11 5.5 11H8.5C8.63261 11 8.75979 11.0527 8.85355 11.1464C8.94732 11.2402 9 11.3674 9 11.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
