import { ConditionalLink } from "components/conditionalLink";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import RadioGroup from "components/DesignSystem/RadioGroup/RadioGroup";
import { Stepper } from "components/DesignSystem/Stepper/Stepper";
import {
  BareInput,
  Label,
  TextInput,
} from "components/DesignSystem/TextInput/TextInput";
import { FormikForm } from "components/FormikForm/FormikForm";
import { ArrowRight } from "components/icons/ArrowRight";
import { InfoSolid } from "components/InfoSolid";
import { BOOKKEEPING, BOOKKEEPING_V2 } from "constants/addons";
import { YYYY_MM_DD } from "constants/date";
import { values } from "cypress/types/lodash";
import dayjs from "dayjs";
import { BILLING_CYCLE } from "dictionaries";
import { Field, FieldProps, useFormikContext } from "formik";
import { useAppSelector } from "hooks/useAppSelector";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useSelector, useDispatch } from "react-redux";
import { useAddProductToCartMutation } from "store/apis/products";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import {
  setAddonPayload,
  setAddonType,
  setOpenCheckoutModal,
} from "store/slices/addons";
import { setUsableCredit, setPaymentTitle } from "store/slices/credit";
import { RootState } from "store/store";
import { currency } from "utils/Currency";
import { pluralize } from "utils/pluralize";

const SubscribeStep = ({ subscriptionId }: { subscriptionId: string }) => {
  const group = useCurrentGroupContext();
  const groupId = group.uuid;
  const entityId = useCurrentEntityId();
  const entity = group.entities.find((e) => e.uuid === entityId);
  const { data: subscriptions } = useGetSubscriptionsQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const subscription = subscriptions?.find((s) => s.uuid === subscriptionId);

  const { values } = useFormikContext<{
    selected_tier: string;
  }>();

  const currentTier = subscription?.tier_information?.find(
    (tier) => tier.uuid === values.selected_tier
  );

  return (
    <div className="t-flex t-flex-col t-gap-4">
      <div className="t-grid t-grid-cols-2 t-gap-4">
        {entity && (
          <Combobox
            required
            label="Entity"
            value={{
              value: entity.uuid,
              label: (
                <div className="t-flex t-items-center t-gap-2 group-[[data-disabled]]:t-text-neutral-30">
                  <ReactCountryFlag
                    countryCode={entity?.country_code}
                    svg
                    title={entity?.country}
                  />
                  <span className="t-truncate t-max-w-36">{entity?.name}</span>
                </div>
              ),
            }}
            isDisabled
          />
        )}

        <Combobox
          label="Accounting Method"
          required
          value={{
            value: "",
            label: "Accrual",
          }}
          isDisabled
        />

        <Field name="schedule_date">
          {({ field }: FieldProps) => (
            <DateInput
              {...field}
              showMonthYearPicker
              label="Start from"
              required
              portalId={field.name}
              minDate={dayjs().add(1, "month").startOf("month").toDate()}
            />
          )}
        </Field>

        <Combobox
          label="Frequency"
          required
          value={{
            value: "",
            label: "Monthly",
          }}
          isDisabled
        />

        <Combobox
          menuPortalTarget={document.body}
          label={
            <span className="t-gap-1 t-inline-flex t-items-center">
              Estimated expense vol.
              <span className="t-text-red">*</span>
              <ToolTip text="Expense volume excluding inter-bank and inter-company transactions">
                <span className="t-flex">
                  <InfoSolid size="12" />
                </span>
              </ToolTip>
            </span>
          }
          withForm
          name="selected_tier"
          options={subscription?.tier_information?.map((tier) => ({
            value: tier.uuid,
            label: `${currency({ amount: tier.lower_range })} - ${currency({
              amount: tier.higher_range,
            })}`,
          }))}
        />

        <div className="t-flex t-flex-col">
          <Label>
            <span className="t-gap-1 t-inline-flex t-items-center">
              Tier Pricing
              <span className="t-text-red">*</span>
              <ToolTip text="Tier pricing is calculated based on expense vol. and plan selected">
                <span className="t-flex">
                  <InfoSolid size="12" />
                </span>
              </ToolTip>
            </span>
          </Label>

          <BareInput
            disabled
            required
            value={currentTier ? currency({ amount: currentTier.amount }) : ""}
          />
        </div>
      </div>
      {currentTier && subscription && (
        <>
          <div className="t-border-t t-border-0 t-border-solid t-border-neutral-0"></div>
          <div className="t-flex t-gap-2 t-w-full t-flex-col">
            <div className="t-flex t-justify-between t-items-center">
              <span className="t-text-body t-text-text-30">
                Retainer amount
              </span>
              <span className="t-text-body t-text-text-30 t-text-nowrap">
                <AmountSuperScript amount={currentTier.amount} />/
                {BILLING_CYCLE[subscription?.billing_cycle]}
              </span>
            </div>

            {subscription?.stripe_coupon && currentTier.discounted_amount && (
              <>
                <div className="t-flex t-justify-between t-items-center">
                  <span className="t-text-body t-text-dark_green">
                    {subscription.stripe_coupon?.coupon_code}
                  </span>
                  <span className="t-text-body t-text-dark_green t-flex t-gap-2 t-items-center">
                    <span>
                      <AmountSuperScript
                        amount={
                          currentTier.discounted_amount - currentTier.amount
                        }
                      />
                    </span>
                    <span className="t-text-body-xs">
                      (
                      {subscription.stripe_coupon.discount_type ===
                      "PERCENT" ? (
                        subscription.stripe_coupon.discount_rate + "%"
                      ) : (
                        <AmountSuperScript
                          amount={Number(
                            subscription.stripe_coupon.discount_rate
                          )}
                        />
                      )}{" "}
                      OFF )
                    </span>
                  </span>
                </div>
                <div className="t-text-body-xs t-text-neutral-50">
                  <InfoSolid size="12" /> Expires in{" "}
                  {pluralize(
                    subscription.stripe_coupon.duration_period || 0,
                    "month",
                    "months"
                  )}
                </div>
              </>
            )}

            {currentTier && subscription && (
              <div className="t-flex t-justify-between t-items-center t-mt-2 t-text-subtitle">
                <span>Total</span>
                <span className="t-text-nowrap">
                  <AmountSuperScript
                    amount={currentTier.discounted_amount || currentTier.amount}
                  />
                  <span className="t-text-text-30">
                    /{BILLING_CYCLE[subscription?.billing_cycle]}
                  </span>
                </span>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export const BookkeepingService = () => {
  const group = useCurrentGroupContext();
  const groupId = group.uuid;
  const entityId = useCurrentEntityId();
  const isCartSubscriptionActive = useAppSelector(
    (state) => state.cartSubscription.isCartSubscriptionActive
  );

  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<
    string | null
  >(null);

  const [addToCart, { isLoading: addingToCart }] =
    useAddProductToCartMutation();

  const { addonData, addonType } = useSelector(
    (state: RootState) => state.addonsSlice
  );

  const { amount, uuid } = addonData || {};

  const { data: subscriptions } = useGetSubscriptionsQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const dispatch = useDispatch();
  const subscription = subscriptions?.find((s) => s.uuid === uuid);
  const selectedSubscription = subscriptions?.find(
    (s) => s.uuid === selectedSubscriptionId
  );
  const bookkeepingServices = subscriptions?.filter(
    (s) => s.subscription_type === BOOKKEEPING_V2
  );

  const onClose = () => {
    dispatch(setAddonType(""));
  };

  const onAddToCart = async (selectedTierAmount: number, startFrom: string) => {
    if (groupId && selectedSubscription && selectedTierAmount) {
      await addToCart({
        groupId: groupId,
        entityId,
        payload: {
          product_id: selectedSubscription.uuid,
          product_content_type_id: selectedSubscription.product_content_type_id,
          selected_tier_amount: selectedTierAmount,
          from_date: startFrom,
        },
      });

      onClose();
      return;
    }
  };

  const handleAddonNext = (values: { tierId: string; from_date: string }) => {
    const tier = selectedSubscription?.tier_information?.find(
      (tier) => tier.uuid === values.tierId
    );

    if (isCartSubscriptionActive && tier) {
      return onAddToCart(tier?.amount, values?.from_date);
    }

    dispatch(
      setAddonPayload({
        title: selectedSubscription?.subscription_name,
        amount: tier?.amount,
        selectedTierAmount: tier?.amount,
        uuid: selectedSubscription?.uuid,
        type: selectedSubscription?.subscription_type,
        scheduleDate: values?.from_date,
        tierId: values.tierId,
      })
    );
    dispatch(setUsableCredit(false));
    dispatch(setPaymentTitle(addonData?.subscription_name!));
    dispatch(setOpenCheckoutModal());
    onClose();
  };

  const [step, setStep] = useState(1);

  const isOpen = addonType === BOOKKEEPING_V2 || Boolean(subscription);

  const onSubmit = (value: {
    subscription_id: string;
    selected_tier: string;
    schedule_date: string;
  }) => {
    if (step === 1) {
      return setStep(2);
    }

    if (value.selected_tier) {
      handleAddonNext({
        tierId: value.selected_tier,
        from_date: dayjs(value.schedule_date)
          .startOf("month")
          .format(YYYY_MM_DD),
      });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={onClose}>
      <Modal.Content useCustomOverlay>
        <FormikForm
          // @ts-ignore
          onSubmit={onSubmit}
          initialValues={{
            selected_tier: "",
            schedule_date: "",
          }}
        >
          <>
            <Modal.Header>
              <div>
                <Modal.Title>Bookkeeping Services - Subscription</Modal.Title>
                <Modal.Subtitle className="t-mt-2">
                  <Stepper size="xs" direction="horizontal">
                    <Stepper.Step
                      step={1}
                      isActive
                      clickable={step === 2}
                      onClick={() => setStep(1)}
                    >
                      <div className="t-flex t-items-center">
                        Select plan
                        <ArrowRight color="currentColor" />
                      </div>
                    </Stepper.Step>

                    <Stepper.Step
                      step={2}
                      isActive={step === 2}
                      clickable={step === 2}
                      onClick={() => setStep(2)}
                    >
                      <div className="t-flex t-items-center">Enter details</div>
                    </Stepper.Step>
                  </Stepper>
                </Modal.Subtitle>
              </div>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body>
              {step === 1 && (
                <div className="t-flex t-flex-col t-gap-4">
                  <p className="t-m-0 t-text-body">
                    Select a subscription plan to continue.{" "}
                    <ConditionalLink
                      to="https://www.inkle.io/pricing"
                      className="t-text-purple t-text-body-sm"
                    >
                      Know more
                    </ConditionalLink>
                  </p>

                  <RadioGroup.Root
                    // @ts-ignore
                    value={selectedSubscriptionId}
                    onValueChange={(value) => setSelectedSubscriptionId(value)}
                    className="t-flex t-flex-col t-gap-5"
                  >
                    {bookkeepingServices?.map((service) => (
                      <RadioGroup.Item
                        align="start"
                        value={service.uuid}
                        key={service.uuid}
                        className="t-border-neutral-10 t-border-solid t-border t-py-4 t-px-3 !t-h-auto t-rounded-lg"
                      >
                        <p className="t-text-subtitle t-m-0 t-mb-1.5">
                          {service.subscription_name}
                        </p>
                        <p className="t-text-body-sm t-m-0 t-text-text-30">
                          {service.description}
                        </p>
                      </RadioGroup.Item>
                    ))}
                  </RadioGroup.Root>
                </div>
              )}

              {step === 2 && selectedSubscriptionId && (
                <SubscribeStep subscriptionId={selectedSubscriptionId} />
              )}
            </Modal.Body>
            <Modal.FooterButtonGroup>
              <Modal.RawClose asChild>
                <Button type="button">Cancel</Button>
              </Modal.RawClose>
              <Button
                type="submit"
                customType="primary"
                isLoading={addingToCart}
                disabled={
                  addingToCart || (step === 1 && !selectedSubscriptionId)
                }
              >
                Next
              </Button>
            </Modal.FooterButtonGroup>
          </>
        </FormikForm>
      </Modal.Content>
    </Modal.Root>
  );
};
