import { TagTypeProps } from "components/DesignSystem/Tag/Tag";
import { VendorBill } from "types/Models/vendorBills";

export const BILL_STATUS = {
  DRAFT: "DRAFT",
  DUE: "DUE",
  OVERDUE: "OVERDUE",
  PAID: "PAID",
  IN_REVIEW: "IN_REVIEW",
  APPROVED: "APPROVED",
} as const;

export const BILL_STATUS_LABEL = {
  [BILL_STATUS.DRAFT]: "Draft",
  [BILL_STATUS.DUE]: "Due",
  [BILL_STATUS.OVERDUE]: "Overdue",
  [BILL_STATUS.PAID]: "Paid",
  [BILL_STATUS.IN_REVIEW]: "In Review",
  [BILL_STATUS.APPROVED]: "Approved",
};

export const TAX_TYPE = {
  PERCENT: "PERCENT",
  ABSOLUTE: "ABSOLUTE",
} as const;

export const FINANCIAL_RECORD_TYPE = {
  BILL: "BILL",
  REQUEST: "REQUEST",
} as const;

export const BILL_STATE = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
} as const;

export const TAG_TYPE: Record<
  VendorBill["bill_payment_status"],
  TagTypeProps["tagType"]
> = {
  [BILL_STATUS.DRAFT]: "gray",
  [BILL_STATUS.DUE]: "orange",
  [BILL_STATUS.OVERDUE]: "red",
  [BILL_STATUS.PAID]: "green",
  [BILL_STATUS.IN_REVIEW]: "purple",
  [BILL_STATUS.APPROVED]: "green",
};

export const STATUS_HEADER = {
  [FINANCIAL_RECORD_TYPE.BILL]: "PAYMENT STATUS",
  [FINANCIAL_RECORD_TYPE.REQUEST]: "REQUEST STATUS",
} as const;

export const DATE_HEADER = {
  [FINANCIAL_RECORD_TYPE.BILL]: "DUE DATE",
  [FINANCIAL_RECORD_TYPE.REQUEST]: "REQUEST DATE",
} as const;
