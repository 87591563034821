import classNames from "classnames";
import Popover from "components/DesignSystem/Popover/Popover";
import { useActionItems } from "hooks/useActionItems";
import { ReactNode, useRef, useState } from "react";
import { Timeout } from "react-number-format/types/types";
import { useLocation } from "react-router-dom";
import WarningCircle from "static/images/WarningCircle.svg";
import { openLink } from "utils/openLink";
import { pluralize } from "utils/pluralize";

const PopoverContent = ({
  title,
  body,
  btnText,
  onClick,
}: {
  title: string;
  body: ReactNode;
  btnText?: string;
  onClick?: () => void;
}) => {
  return (
    <div className="t-flex t-flex-col t-gap-2 t-text-white">
      <Popover.Header>{title}</Popover.Header>
      <div
        className={classNames("t-text-body-sm", {
          "t-mb-2": Boolean(btnText),
        })}
      >
        {body}
      </div>
      {btnText && (
        <button
          className="all:unset t-underline t-text-subtext-sm"
          onClick={onClick}
        >
          {btnText}
        </button>
      )}
    </div>
  );
};

const AllRequiredActionsContent = ({
  actionItemsResponseLength,
  isBankReconnectRequired,
  isReconciliationRequired,
  openDataSources,
  openReconciliation,
}: {
  actionItemsResponseLength: number;
  isBankReconnectRequired: boolean;
  isReconciliationRequired: boolean;
  openDataSources: () => void;
  openReconciliation: () => void;
}) => {
  return (
    <div className="t-text-white">
      {isBankReconnectRequired && isReconciliationRequired ? (
        <div className="t-text-white t-flex t-flex-col t-gap-2">
          <Popover.Header>Action Required</Popover.Header>
          <div className="t-flex t-flex-col t-gap-2 t-text-body-sm">
            {isBankReconnectRequired && (
              <div className="t-flex t-justify-between">
                <div>Account disconnected</div>
                <button
                  className="all:unset t-underline"
                  onClick={openDataSources}
                >
                  Reconnect
                </button>
              </div>
            )}
            {isReconciliationRequired && (
              <div className="t-flex t-justify-between">
                <div>Reconciliation required</div>
                <button
                  className="all:unset t-underline"
                  onClick={openReconciliation}
                >
                  Reconcile
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          {isBankReconnectRequired && (
            <PopoverContent
              title="Account disconnected"
              body=" One or more of your accounts have been disconnected. Please 
              reconnect your accounts to ensure an uninterrupted data feed."
              btnText="Reconnect"
              onClick={openDataSources}
            />
          )}
          {isReconciliationRequired && (
            <PopoverContent
              title="Reconciliation Required"
              body="Some of your accounts require reconciliation. Please resolve 
              this to ensure the accuracy of the data feed."
              btnText="Reconcile"
              onClick={openReconciliation}
            />
          )}
        </>
      )}
    </div>
  );
};

const RequiredActionsContent = ({
  type,
  btnText,
}: {
  type: "ALL" | "RECONCILIATION" | "ACCOUNT" | "RECONCILLATION_OR_ACCOUNT";
  btnText?: string;
}) => {
  const { search } = useLocation();
  const {
    action_items_count: actionItemsResponseLength,
    isBankReconnectRequired,
    isReconciliationRequired,
    action_items_response,
  } = useActionItems();

  const disconnectedConnections = action_items_response.find(
    ({ item_type }) => item_type == "Connections"
  );

  const toBeReconciledAccounts = action_items_response.find(
    ({ item_type }) => item_type == "Reconciliation"
  );

  const openDataSources = () => openLink(`/books/data-sources${search}`);
  const openReconciliation = () =>
    openLink(`/books/data-sources/reconciliation${search}`);

  if (type === "ALL") {
    return (
      <AllRequiredActionsContent
        actionItemsResponseLength={actionItemsResponseLength}
        isBankReconnectRequired={isBankReconnectRequired}
        isReconciliationRequired={isReconciliationRequired}
        openDataSources={openDataSources}
        openReconciliation={openReconciliation}
      />
    );
  } else if (type === "RECONCILIATION" && isReconciliationRequired) {
    return (
      <PopoverContent
        title="Reconciliation Required"
        body="Some of your accounts require reconciliation. Please resolve 
          this to ensure the accuracy of the data feed."
        btnText={btnText}
        onClick={btnText ? openReconciliation : () => {}}
      />
    );
  } else if (type === "ACCOUNT" && isBankReconnectRequired) {
    return (
      <PopoverContent
        title="Account disconnected"
        body=" One or more of your accounts have been disconnected. Please 
          reconnect your accounts to ensure an uninterrupted data feed."
        btnText={btnText}
        onClick={btnText ? openDataSources : () => {}}
      />
    );
  } else if (
    type === "RECONCILLATION_OR_ACCOUNT" &&
    (isReconciliationRequired || isBankReconnectRequired)
  ) {
    return (
      <div>
        <PopoverContent
          title=""
          body={
            <div>
              <ul>
                {isReconciliationRequired && (
                  <li
                    className={classNames("", {
                      "t-list-none": !isBankReconnectRequired,
                    })}
                  >
                    {pluralize(
                      Number(toBeReconciledAccounts?.items_count),
                      "account needs",
                      "accounts need"
                    )}
                    to be reconciled{" "}
                  </li>
                )}
                {isBankReconnectRequired && (
                  <li
                    className={classNames("", {
                      "t-list-none": !isReconciliationRequired,
                    })}
                  >
                    {" "}
                    {disconnectedConnections?.items_count}{" "}
                    {pluralize(
                      Number(disconnectedConnections?.items_count),
                      "data source needs",
                      "data source needs"
                    )}
                    to be reconnected
                  </li>
                )}
              </ul>
            </div>
          }
          btnText={btnText}
          onClick={btnText ? openDataSources : () => {}}
        />
      </div>
    );
  }

  return null;
};

export const ActionRequiredPopOver = ({
  type,
  btnText,
}: {
  type: "ALL" | "RECONCILIATION" | "ACCOUNT" | "RECONCILLATION_OR_ACCOUNT";
  btnText?: string;
}) => {
  const [pop, setPop] = useState(false);
  const closeRef = useRef<Timeout>();
  const { action_items_count: actionItemsResponseLength } = useActionItems();

  const onClose = () => {
    if (closeRef.current) {
      clearTimeout(closeRef.current);
    }

    closeRef.current = setTimeout(() => {
      setPop(false);
    }, 200);
  };

  const onOpen = () => {
    closeRef.current && clearTimeout(closeRef.current);
    setPop(true);
  };

  if (actionItemsResponseLength === 0) {
    return null;
  }

  return (
    <Popover.Root open={pop}>
      <Popover.Trigger
        asChild
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        onClick={(e) => e.preventDefault()}
      >
        <button className="all:unset">
          <img
            src={WarningCircle}
            alt="WarningCircle"
            className="t-w-5 t-h-5"
          />
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          asChild
          className="t-w-[310px]"
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
        >
          <RequiredActionsContent type={type} btnText={btnText} />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
