import { PlaidBank } from "types/Models/bankAccount";
import {
  Account,
  BankBrand,
  Banks,
  BankStatement,
  ErrorItem,
} from "types/Models/banks";
import { PlaidBankAccountMap } from "types/Models/plaid";
import { emptyApi } from "./emptyApi";
import qs from "qs";

type ExchangeTokenProps = {
  groupId: string;
  public_token: string;
  entity_id: string;
  institution_id: string;
  accounts: { name: string; mask: string }[];
};

export type LedgerBanks = {
  accounts: Banks[];
  aggregate: {
    accounts: number;
    current_balance: number;
  };
  items: ErrorItem[];
};

export const bankConnectionsApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getGenerateLinkToken: build.query<
      {
        expiration: string;
        link_token: string;
        request_id: string;
      },
      {
        groupId: string;
        entityId: string;
        entityItemId?: string;
      }
    >({
      query: ({ groupId, entityItemId, entityId }) => {
        const queries = {
          entity_item_id: entityItemId || null,
        };
        let queryUrl = qs.stringify(queries, {
          skipNulls: true,
          addQueryPrefix: true,
        });
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/link_token/${queryUrl}`,
        };
      },
    }),

    getPlaidBanks: build.query<PlaidBank[], { groupId: string }>({
      query: ({ groupId }) =>
        `/api/inkle/bookkeeping/group/${groupId}/plaid_bank_account/`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ item_data: { uuid } }) => ({
                type: "PlaidBanks" as "PlaidBanks",
                id: uuid,
              })),
              { type: "PlaidBanks" as "PlaidBanks", id: "LIST" },
            ]
          : [{ type: "PlaidBanks" as "PlaidBanks", id: "LIST" }],
    }),

    exchangeToken: build.mutation<PlaidBankAccountMap, ExchangeTokenProps>({
      query: ({
        public_token,
        groupId,
        entity_id,
        institution_id,
        accounts,
      }) => ({
        url: `/api/inkle/bookkeeping/group/${groupId}/plaid_bank_account/`,
        method: "POST",
        body: {
          public_token,
          entity_id,
          institution_id,
          accounts,
        },
      }),
      invalidatesTags: (_result, _error) => {
        return [
          { type: "PlaidBanks", id: "LIST" },
          { type: "CONNECTION" },
          { type: "Subscriptions" },
        ];
      },
    }),

    syncPlaidTransactions: build.mutation({
      query: ({
        groupId,
        entity_item_id,
      }: {
        groupId: string;
        entity_item_id: string;
      }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/plaid_transactions/`,
          method: "post",
          body: {
            entity_item_id,
          },
        };
      },
    }),

    getPlaidBankLogo: build.query({
      query: ({
        groupId,
        institution_id,
      }: {
        groupId: string;
        institution_id: string;
      }) => ({
        url: `/api/inkle/bookkeeping/group/${groupId}/plaid_institutions/`,
        params: {
          ins_id: institution_id,
        },
      }),
    }),

    updateItemErrorState: build.mutation<
      void,
      { groupId: string; entityItemId: string }
    >({
      query: ({ entityItemId, groupId }) => ({
        url: `/api/inkle/bookkeeping/group/${groupId}/item/${entityItemId}/update_error_state`,
        method: "put",
      }),
      invalidatesTags: () => [
        { type: "PlaidBanks" as "PlaidBanks", id: "LIST" },
      ],
    }),

    getEntityBanks: build.query<
      LedgerBanks,
      { groupId: string; entityId: string }
    >({
      query: ({ groupId, entityId }) =>
        `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/ledger_banks/`,
      providesTags: (result) =>
        result
          ? [
              ...result.accounts.map(({ account: { uuid } }) => ({
                type: "PlaidBanks" as "PlaidBanks",
                id: uuid,
              })),
              { type: "PlaidBanks" as "PlaidBanks", id: "LIST" },
              { type: "CONNECTION" },
            ]
          : [
              { type: "PlaidBanks" as "PlaidBanks", id: "LIST" },
              { type: "CONNECTION" },
              { type: "Subscriptions" },
            ],
    }),

    editBankAccount: build.mutation<
      void,
      {
        groupId: string;
        entityId: string;
        bankId: string;
        bankAccountId: string;
        payload: {
          opening_balance: string;
          opening_balance_date: string;
        };
      }
    >({
      query: ({ groupId, entityId, bankId, bankAccountId, payload }) => ({
        url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/entity_item/${bankId}/bank_accounts/${bankAccountId}/`,
        body: payload,
        method: "PATCH",
      }),
      invalidatesTags: (_result, _error) => {
        return [{ type: "PlaidBanks", id: "LIST" }];
      },
    }),

    syncEntityTransactions: build.mutation<
      { message: string }[],
      { groupId: string; entityId: string }
    >({
      query: ({ groupId, entityId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/ledger_banks/`,
          method: "post",
        };
      },
      invalidatesTags: (_result, _error) => {
        return [{ type: "PlaidBanks", id: "LIST" }, { type: "Subscriptions" }];
      },
    }),

    syncGroupBanks: build.mutation<{ message: string }[], { groupId: string }>({
      query: ({ groupId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/bank_transactions/`,
          method: "post",
        };
      },
      invalidatesTags: (_result, _error) => {
        return [{ type: "PlaidBanks", id: "LIST" }];
      },
    }),

    deleteBank: build.mutation<
      void,
      {
        groupId: string;
        entityId: string;
        bankId: string;
      }
    >({
      query: ({ groupId, entityId, bankId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/item/${bankId}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: (_result, _error) => {
        return [
          { type: "PlaidBanks", id: "LIST" },
          { type: "CONNECTION" },
          { type: "Subscriptions" },
        ];
      },
    }),

    disconnectBank: build.mutation<
      void,
      {
        groupId: string;
        bankId: string;
        entityId: string;
      }
    >({
      query: ({ groupId, bankId, entityId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/item/${bankId}/`,
          method: "PUT",
        };
      },
      invalidatesTags: (res) =>
        res ? [{ type: "PlaidBanks" }, { type: "Subscriptions" }] : [],
    }),

    RemovePlaidBank: build.mutation<
      any,
      { groupId: string; entityItemId: string }
    >({
      query: ({ groupId, entityItemId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/plaid_transactions/`,
          method: "DELETE",
          body: {
            entity_item_id: entityItemId,
          },
        };
      },
      invalidatesTags: (_result, _error) => {
        return [
          { type: "PlaidBanks", id: "LIST" },
          { type: "CONNECTION" },
          { type: "Subscriptions" },
        ];
      },
    }),

    getEntityItemBankAccounts: build.query<
      Banks[],
      { groupId: string; entityItemId: string; entityId: string }
    >({
      query: ({ groupId, entityItemId, entityId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/entity_item/${entityItemId}/bank_accounts/`,
        };
      },
      providesTags: (result) => [
        { type: "PlaidBanks" as "PlaidBanks", id: "LIST" },
        { type: "CONNECTION" },
      ],
    }),

    getBankAccountById: build.query<
      {
        bank_brand: BankBrand;
        account: Account & {
          account_number: string;
          account_type: string;
          opening_balance: number;
          opening_balance_date: string;
        };
        last_successful_transaction_update: string;
        in_error_state: boolean;
      },
      {
        groupId: string;
        entityItemId: string;
        entityId: string;
        entityBankAccountId: string;
      }
    >({
      query: ({ groupId, entityItemId, entityId, entityBankAccountId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/entity_item/${entityItemId}/bank_accounts/${entityBankAccountId}`,
        };
      },
      providesTags: (result) => [
        { type: "PlaidBanks" as "PlaidBanks", id: "LIST" },
        { type: "CONNECTION" },
      ],
    }),
    updateBankAccount: build.mutation<
      {
        bank_brand: BankBrand;
        account: Account & {
          account_number: string;
          account_type: string;
          opening_balance: string;
          opening_balance_date: string;
        };
        last_successful_transaction_update: string;
        in_error_state: boolean;
      },
      {
        groupId: string;
        entityItemId: string;
        entityId: string;
        entityBankAccountId: string;
        account_number: string;
        account_type: string;
        opening_balance: string;
        opening_balance_date: string;
      }
    >({
      query: ({
        groupId,
        entityItemId,
        entityId,
        entityBankAccountId,
        account_number,
        account_type,
        opening_balance,
        opening_balance_date,
      }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/entity_item/${entityItemId}/bank_accounts/${entityBankAccountId}`,
          method: "PATCH",
          body: {
            account_number,
            account_type,
            opening_balance,
            opening_balance_date,
          },
        };
      },
      invalidatesTags: (_result, _error) => {
        return [{ type: "PlaidBanks", id: "LIST" }, { type: "Subscriptions" }];
      },
    }),
    getBankAccountStatements: build.query<
      BankStatement[],
      {
        entityId: string;
        entityBankAccountId: string;
        startDate?: string;
        endDate?: string;
        sortBy?: "DSC" | "ASC";
      }
    >({
      query: ({
        entityId,
        entityBankAccountId,
        startDate,
        endDate,
        sortBy,
      }) => {
        const queries = {
          start_date: startDate || null,
          end_date: endDate || null,
          sort_order: sortBy,
        };
        let queryUrl = qs.stringify(queries, {
          skipNulls: true,
        });

        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/account/${entityBankAccountId}/statements/`,
          params: qs.parse(queryUrl),
        };
      },
      providesTags: (result) => (result ? ["BANK_ACCOUNT_STATEMENTS"] : []),
    }),

    refreshAccountStatements: build.mutation<
      void,
      {
        entityId: string;
        entityBankAccountId: string;
      }
    >({
      query: ({ entityId, entityBankAccountId }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/account/${entityBankAccountId}/statements/`,
          method: "POST",
        };
      },
      invalidatesTags: (result) => (result ? ["BANK_ACCOUNT_STATEMENTS"] : []),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetGenerateLinkTokenQuery,
  useExchangeTokenMutation,
  useGetPlaidBanksQuery,
  useSyncPlaidTransactionsMutation,
  useGetEntityBanksQuery,
  useSyncEntityTransactionsMutation,
  useGetPlaidBankLogoQuery,
  useLazyGetPlaidBankLogoQuery,
  useSyncGroupBanksMutation,
  useUpdateItemErrorStateMutation,
  useRemovePlaidBankMutation,
  useLazyGetEntityBanksQuery,
  useGetEntityItemBankAccountsQuery,
  useGetBankAccountByIdQuery,
  useUpdateBankAccountMutation,
  useEditBankAccountMutation,
  useDisconnectBankMutation,
  useDeleteBankMutation,
  usePrefetch: useBankConnectionsPrefetch,
  useGetBankAccountStatementsQuery,
  useRefreshAccountStatementsMutation,
} = bankConnectionsApi;
