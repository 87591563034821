import * as Accordion from "@radix-ui/react-accordion";
import { Price } from "components/Price";
import { CaretDown } from "components/icons/CaretDown";
import {
  BOOKKEEPING_SOFTWARE,
  GROUP_BOOKS_CONSOLIDATION,
  MAILROOM,
  REGISTERED_AGENT,
} from "constants/addons";
import { OPENED_MANAGE_PLAN_MODAL } from "constants/analyticsEvents";
import { MONTHLY, YEARLY } from "constants/billingCycles";
import dayjs from "dayjs";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import authContext from "jwt_context&axios/authContext";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import BigTaxIcon from "static/images/BigTaxIcon.svg";
import { ProductGroup } from "store/apis/products";
import { AddonData } from "store/slices/addons";
import { toggleCartSubscription } from "store/slices/cartSubscription";
import { Subscription, SubscriptionInProfile } from "types/Models/subscription";
import { formatDate } from "utils/formatDate";
import { Button } from "./DesignSystem/Button/Button";
import { Discount } from "./Discount";
import { ProductCard } from "./ProductCard/ProductCard";
import { SubscriptionUpdateModal } from "./SubscriptionUpdateModal";
import { TagSolid } from "./TagSolid";
import { AmountSuperScript } from "./design/AmountSuperScript";
import { Cart } from "./icons/Cart";
import { ProductTypeCard } from "./product/ProductTypeCard";
import { SubscriptionTypeCard } from "./product/SubscriptionTypeCard";

export const AddonPricing = ({ addon }: { addon: AddonData }) => {
  if (addon?.stripe_coupon) {
    const {
      stripe_coupon: { discounted_amount },
      billing_cycle,
    } = addon;

    return (
      <div className="t-mt-2 t-flex t-flex-col t-gap-1">
        <Price amount={discounted_amount} cycle={billing_cycle} size="small" />
        <Discount coupon={addon.stripe_coupon} amount={addon.amount} />
      </div>
    );
  }

  if (
    addon?.subscription_type === BOOKKEEPING_SOFTWARE &&
    addon?.group_subscription
  ) {
    return (
      <div className="t-mt-2">
        <Price
          cycle={addon?.billing_cycle}
          amount={addon?.group_subscription?.[0]?.amount}
          size="small"
        />
      </div>
    );
  }

  if (addon.subscription_type === GROUP_BOOKS_CONSOLIDATION) {
    const amount = addon.group_subscription
      ? addon?.group_subscription?.[0]?.amount
      : addon.amount;

    let billingPeriod = "/quarter or month";

    if (addon.group_subscription) {
      if (addon.group_subscription?.[0]?.billingCycle === MONTHLY) {
        billingPeriod = "/month";
      } else {
        billingPeriod = "/once in 3 months";
      }
    }

    return (
      <p className="t-m-0 t-text-subtext t-text-text-100">
        <AmountSuperScript amount={Number(amount)} />
        {addon?.billing_cycle && (
          <span className="t-text-body-sm t-text-text-30">{billingPeriod}</span>
        )}
      </p>
    );
  }

  const conditionForStartsAt =
    ((addon?.subscription_type === BOOKKEEPING_SOFTWARE ||
      addon?.subscription_type === GROUP_BOOKS_CONSOLIDATION) &&
      !addon?.group_subscription) ||
    addon?.subscription_type === REGISTERED_AGENT;

  return (
    <div className="t-flex t-items-center t-gap-1">
      {conditionForStartsAt && (
        <div className="t-text-body-sm t-text-text-30">Starts at</div>
      )}
      <Price cycle={addon?.billing_cycle} amount={addon?.amount} size="small" />
    </div>
  );
};

export const GetDescription = ({
  type,
  subscriptions,
}: {
  type: string;
  subscriptions: any;
}) => {
  if (type === BOOKKEEPING_SOFTWARE) {
    return (
      <div className="t-text-body-sm t-text-white">
        {subscriptions ? (
          <>Selected software: {subscriptions?.[0]?.subscriptionName}</>
        ) : (
          "Choose between QuickBooks USA or Zoho Books USA."
        )}
      </div>
    );
  }
  if (type === REGISTERED_AGENT) {
    let totalAmount: number = 0;

    const othersStates = subscriptions?.filter(
      (ele: any) => ele.type === "Others"
    );

    const othersStatesLength = othersStates?.[0]?.selectedStates?.length || 0;

    totalAmount = Number(othersStates?.[0]?.amount) * othersStatesLength || 0;

    subscriptions
      ?.filter((ele: any) => ele.type !== "Others")
      ?.map((ele: any) => {
        totalAmount = Number(totalAmount) + Number(ele?.amount);
      });

    const statesSelectedLength: number =
      subscriptions?.length +
      othersStatesLength -
      (othersStatesLength > 0 ? 1 : 0);

    if (statesSelectedLength === 1) {
      let selectedStateName = "";
      if (othersStatesLength === 1) {
        selectedStateName =
          othersStates?.[0]?.selectedStates?.[0]?.ra_state_name;
      } else {
        selectedStateName = subscriptions?.filter(
          (ele: any) => ele.type !== "Others"
        )?.[0]?.type;
      }
      return (
        <div className="t-flex t-justify-between t-items-center t-text-white">
          <div className="t-text-body">{selectedStateName}</div>
          <Price amount={totalAmount} size="medium" cycle={MONTHLY} />
        </div>
      );
    }

    return (
      <>
        {subscriptions ? (
          <div className="t-flex t-justify-between t-flex-wrap">
            <div className="t-text-body">
              Active States: {statesSelectedLength}
            </div>
            <Price amount={totalAmount} size="medium" cycle={MONTHLY} />
          </div>
        ) : (
          <div className="t-text-body-sm t-text-white">
            Registered Agent service for all states of the United States of
            America
          </div>
        )}
      </>
    );
  }
  if (type === MAILROOM) {
    return (
      <div className="t-text-body-sm t-text-white">
        {!subscriptions &&
          "Get a virtual mailroom with a permanent U.S. address accessible through our platform anytime, anywhere."}
      </div>
    );
  }
  if (type === GROUP_BOOKS_CONSOLIDATION) {
    return (
      <div className="t-text-body-sm t-text-white">
        Consolidated financials of all your entities
      </div>
    );
  }

  return null;
};

export const SubscriptionSummary = ({
  freeSeats,
  extraSeats,
  activeSeats,
  setShowSeatManage,
  tax,
}: {
  freeSeats: number;
  extraSeats: number;
  activeSeats: number;
  setShowSeatManage: (v: boolean) => void;
  tax: ProductGroup;
}) => {
  const [isSubscriptionUpdateModalOpen, setSubscriptionUpdateModal] =
    useState(false);
  const { authtoken } = useContext(authContext);
  const { groupId: currentGroup } = useParams<{ groupId?: string }>();
  const { platform_subscription: groupPlatformSubscription } =
    useCurrentGroupContext();

  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();

  let {
    platform_subscription: currentSubscription,
  }: {
    platform_subscription: SubscriptionInProfile;
  } = authtoken || {};

  if (currentGroup) {
    currentSubscription = groupPlatformSubscription;
  }

  const isCurrentYearIncorporated =
    dayjs(currentSubscription.scheduled_date)
      .subtract(1, "year")
      .year()
      .toString() === new Date().getFullYear().toString();

  const isAnnualSubscription = currentSubscription.billing_cycle === YEARLY;

  const subscriptionAmount =
    currentSubscription.stripe_coupon?.discounted_amount ||
    currentSubscription.amount;

  const onManagePlan = (useCart: boolean = false) => {
    trackEvent(OPENED_MANAGE_PLAN_MODAL);
    dispatch(toggleCartSubscription(useCart));
    setSubscriptionUpdateModal(true);
  };

  const allProductKeys = Object.keys(tax);
  const categoriesToShow = allProductKeys.filter(
    (key) => key !== "subscription" && tax[key]?.length > 0
  );

  return (
    <>
      {currentSubscription && (
        <div className="!t-border-none t-space-y-8">
          <img src={BigTaxIcon} alt="BigTaxIcon" />
          <div className="t-grid md:t-grid-cols-2 lg:t-grid-cols-3 t-gap-5">
            <ProductCard
              title={currentSubscription.subscription_name || "Free Plan"}
              isPrimarySubscription
              planStatus={
                !Boolean(currentSubscription.subscription_status) ||
                currentSubscription.subscription_status === "ACTIVE"
                  ? "ACTIVE"
                  : "OVERDUE"
              }
              price={
                <span className="">
                  <Price
                    amount={subscriptionAmount}
                    size="small"
                    cycle={isAnnualSubscription ? YEARLY : MONTHLY}
                  />

                  <div>
                    <div className="t-mt-2">
                      <Discount
                        coupon={currentSubscription.stripe_coupon}
                        amount={currentSubscription.amount}
                      />
                    </div>
                    {authtoken.platform_subscription.ongoing_extended_month && (
                      <span className="t-mb-4 t-text-body-sm t-text-dark_green t-flex t-gap-1">
                        <TagSolid />
                        Offer applied! You have a Free month.
                      </span>
                    )}
                  </div>
                </span>
              }
              action={
                <div className="t-flex t-justify-between t-w-full">
                  {isCurrentYearIncorporated && (
                    <div className="t-flex t-text-body-sm t-text-neutral-70 t-bg-neutral-0 t-rounded-3xl t-px-3 t-items-center t-w-2/3 t-py-1">
                      Next payment:{" "}
                      {formatDate(currentSubscription?.scheduled_date)}
                    </div>
                  )}
                  <div className="t-flex t-gap-1">
                    <Button size="small" onClick={() => onManagePlan(false)}>
                      Manage plan
                    </Button>
                    {!currentSubscription.subscription_uuid && (
                      <Button
                        size="small"
                        onClick={() => onManagePlan(true)}
                        customType="icon"
                      >
                        <Cart />
                      </Button>
                    )}
                  </div>
                </div>
              }
              isSubscription
            />
          </div>
          {categoriesToShow.length > 0 && (
            <Accordion.Root
              type="multiple"
              defaultValue={categoriesToShow}
              className="t-space-y-8"
            >
              {categoriesToShow.map((category) => (
                <Accordion.Item value={category} key={category}>
                  <Accordion.Trigger className="all:unset t-text-subtitle-sm t-text-text-60 t-flex t-gap-2 t-group t-w-full">
                    <span className="group-data-state-open:t-rotate-0 group-data-state-closed:-t-rotate-90 t-text-neutral t-transform t-transition t-duration-300 t-ease-in-out">
                      <CaretDown />
                    </span>
                    {category}
                  </Accordion.Trigger>
                  <Accordion.Content
                    className="t-text-body-sm t-text-text-60 t-mt-3 t-grid t-gap-4"
                    style={{
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(360px, max-content))",
                    }}
                  >
                    {tax[category].map((product) => {
                      if ("subscription_name" in product) {
                        return (
                          <SubscriptionTypeCard
                            key={product.uuid}
                            subscription={product as Subscription}
                          />
                        );
                      }
                      return (
                        <ProductTypeCard key={product.uuid} product={product} />
                      );
                    })}
                  </Accordion.Content>
                </Accordion.Item>
              ))}
            </Accordion.Root>
          )}
        </div>
      )}
      <SubscriptionUpdateModal
        isOpen={isSubscriptionUpdateModalOpen}
        handleClose={() => setSubscriptionUpdateModal(false)}
        freeSeats={freeSeats}
        extraSeats={extraSeats}
        activeSeats={activeSeats}
        setShowSeatManage={setShowSeatManage}
      />
    </>
  );
};
