export const CHECKLIST_ITEM_TYPE = {
  ACCOUNT: "ACCOUNT",
  CATEGORIZATION: "CATEGORIZATION",
  REVIEW_PENDING_BANK_TRANSFERS: "REVIEW_PENDING_BANK_TRANSFERS",
  REVIEW_PENDING_CARD_PAYMENTS: "REVIEW_PENDING_CARD_PAYMENTS",
  REVIEW_VENDORS: "REVIEW_VENDORS",
  REVIEW_FIXED_ASSETS: "REVIEW_FIXED_ASSETS",
  REVIEW_JOURNAL_ENTRIES: "REVIEW_JOURNAL_ENTRIES",
  REVIEW_BALANCE_SHEET: "REVIEW_BALANCE_SHEET",
  REVIEW_CASHFLOW_STATEMENT: "REVIEW_CASHFLOW_STATEMENT",
  REVIEW_INCOME_STATEMENT: "REVIEW_INCOME_STATEMENT",
} as const;

export const CHECKLIST_ITEM_SECTION = {
  RECONCILE_ACCOUNTS: "RECONCILE_ACCOUNTS",
  VERIFY_CATEGORIZATION: "VERIFY_CATEGORIZATION",
  REVIEW_CHANGES: "REVIEW_CHANGES",
  FINALIZE_FINANCIALS: "FINALIZE_FINANCIALS",
} as const;

export const CLOSING_SUMMARY_KEY = {
  REVENUE: "revenue",
  COGS: "cogs",
  OPERATING_EXPENSES: "operating_expenses",
  NET_PROFIT: "net_profit",
  CASH_BALANCE: "cash_balance",
  CASH_BURN: "cash_burn",
} as const;

export const CLOSING_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  REVIEW_PENDING: "REVIEW_PENDING",
  COMPLETED: "COMPLETED",
} as const;
