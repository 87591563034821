import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { Form, Formik, FormikValues, useFormikContext } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useGetEntityBanksQuery } from "store/apis/bankConnections";
import { useUploadCSVTransactionMutation } from "store/apis/transactions";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { object, string } from "yup";
import { AddTransactionModalBody } from "./AddTransactionModalBody";
import { Stepper } from "components/DesignSystem/Stepper/Stepper";
import { ArrowRight } from "components/icons/ArrowRight";
import { FileInput } from "components/FileInput/FileInput";
import { useDispatch } from "react-redux";
import { setManualTransactionAdditionInvoice } from "store/slices/linkInkleInvoice";
import { Transaction } from "types/Models/books";

export const ManualTransactionAddStepper = ({
  isCSV,
  currentStep,
  onStepperClick,
}: {
  isCSV: boolean;
  onStepperClick: (step: number) => void;
  currentStep: number;
}) => {
  const breadcrumbs = isCSV
    ? [
        { name: "Select bank", step: 1 },
        { name: "Map columns", step: 2 },
        { name: "Review transactions", step: 3 },
      ]
    : [
        { name: "Select bank", step: 1 },
        { name: "Add transactions", step: 2 },
      ];

  return (
    <Stepper size="small" direction="horizontal">
      {breadcrumbs?.map(({ name, step }) => (
        <Stepper.Step
          step={step}
          key={step}
          isActive={currentStep >= step}
          clickable={currentStep >= step}
          onClick={() => onStepperClick(step)}
        >
          <div className="t-flex t-items-center">
            {name}
            {step !== breadcrumbs.length && <ArrowRight color="currentColor" />}
          </div>
        </Stepper.Step>
      ))}
    </Stepper>
  );
};

const BankSelector = () => {
  const {
    values: { account },
  } = useFormikContext<{ account: string | null }>();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const { data: banks } = useGetEntityBanksQuery(
    { entityId, groupId },
    { skip: !entityId || !groupId }
  );

  const banksAccounts = banks?.accounts.map(
    ({ account: { mask, nickname, uuid } }) => ({
      value: uuid,
      label: `${nickname}  ${mask ? `••••${mask}` : ""}`,
    })
  );

  const selectedBank = banks?.accounts?.find(
    ({ account: { uuid } }) => account === uuid
  );

  const { account: selectedBankAccount } = selectedBank || {};

  const selectedBankAccountLabel = `${selectedBankAccount?.nickname}  ${
    selectedBankAccount?.mask ? `••••${selectedBankAccount?.mask}` : ""
  }`;

  return (
    <Combobox
      options={banksAccounts}
      label="Select account"
      required
      name="account"
      withForm
      placeholder="Select or input account"
      menuPortalTarget={document.getElementById("root")}
      block
      {...{
        value: account
          ? {
              label: selectedBankAccountLabel,
              value: account,
            }
          : null,
      }}
    />
  );
};

const SelectAddAccount = ({
  csv,
  selectedAccountId,
  onSubmit,
  onBack,
}: {
  csv?: File;
  selectedAccountId?: string;
  onSubmit: (values: { csv: File | null; account: string | null }) => void;
  onBack?: () => void;
}) => {
  return (
    <Formik
      initialValues={{
        csv: csv || null,
        account: selectedAccountId || null,
      }}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={object({
        account: string().required("Please select account").nullable(),
      })}
      validateOnChange
    >
      {({
        submitForm,
        setFieldValue,
        values: { csv },
        isSubmitting,
        isValid,
      }) => (
        <Form className="t-m-0 t-w-full">
          <Modal.Body className="t-flex t-gap-3 t-flex-col t-w-full">
            {Boolean(csv) && (
              <FileInput
                withForm
                name="csv"
                onDelete={() => {
                  setFieldValue("csv", null);
                  setFieldValue("account", null);
                  onBack?.();
                }}
                label="Uploaded file"
                file={
                  csv && {
                    name: csv.name,
                    file_type: "CSV",
                    is_previewable: false,
                    uuid: "",
                  }
                }
              />
            )}
            <BankSelector />
          </Modal.Body>
          <Modal.FooterButtonGroup>
            <Button onClick={onBack} type="reset" disabled={isSubmitting}>
              Back
            </Button>
            <Button
              customType="primary"
              type="submit"
              onClick={submitForm}
              isLoading={isSubmitting}
              disabled={!isValid || isSubmitting}
            >
              Next
            </Button>
          </Modal.FooterButtonGroup>
        </Form>
      )}
    </Formik>
  );
};

type TransactionsBankSelectorProps = ModalProps & {
  onBack: () => void;
  csv?: File;
  step?: number;
  defaultTransaction?: Transaction;
};

export const TransactionsBankSelector = ({
  close,
  isOpen,
  onBack,
  csv,
  step = 1,
  defaultTransaction,
}: TransactionsBankSelectorProps) => {
  const { alertToast } = useToast();
  const dispatch = useDispatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [selectedAccountId, setSelectedAccountId] = useState(
    defaultTransaction?.from?.bank_account.uuid || ""
  );
  const [currentStep, setCurrentStep] = useState(step);

  const [uploadCSV, { data: uploadedCsvData, reset }] =
    useUploadCSVTransactionMutation();

  const onSubmit = async (value: FormikValues) => {
    try {
      if (csv) {
        await uploadCSV({
          accountId: value.account,
          csv,
          entityId,
          groupId,
        }).unwrap();
      }
      setSelectedAccountId(value.account);
      setCurrentStep(2);
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const onClose = () => {
    reset();
    close();
    setCurrentStep(1);
    setSelectedAccountId("");
    dispatch(
      setManualTransactionAdditionInvoice({
        invoiceId: "",
        invoiceDueAmount: 0,
      })
    );
  };

  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={onClose}>
        <Modal.Content size={currentStep > 1 ? "xxxl" : "xl"} useCustomOverlay>
          <Modal.Header>
            <div>
              {defaultTransaction ? (
                <Modal.Title>Duplicate Transactions</Modal.Title>
              ) : (
                <Modal.Title>Manually Add Transactions</Modal.Title>
              )}
              <Modal.Subtitle>
                <ManualTransactionAddStepper
                  isCSV={Boolean(csv)}
                  currentStep={currentStep}
                  onStepperClick={setCurrentStep}
                />
              </Modal.Subtitle>
            </div>
            <Modal.Close />
          </Modal.Header>
          {currentStep > 1 ? (
            <AddTransactionModalBody
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              close={onClose}
              selectedAccountId={selectedAccountId}
              uploadedCsvData={uploadedCsvData}
              defaultTransaction={defaultTransaction}
            />
          ) : (
            <SelectAddAccount
              csv={csv}
              onSubmit={onSubmit}
              onBack={onBack}
              selectedAccountId={selectedAccountId}
            />
          )}
        </Modal.Content>
      </Modal.Root>
    </>
  );
};
