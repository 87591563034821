import { emptyApi } from "./emptyApi";
import * as INVOICE_STATUSES from "constants/invoiceStatuses";
import { stringify } from "qs";
import { Transactions } from "types/Models/books";
import { fileAndFolder, SelecteFile } from "types/Models/documents";
import { Pagination } from "types/Models/pagination";

const baseURL = process.env.PUBLIC_BASE_URL;

export type RecurringSettingTypes =
  | "WEEKLY"
  | "QUARTERLY"
  | "MONTHLY"
  | "YEARLY";

export type CustomerAddress = {
  street_address: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  added_by?: string;
  uuid: string;
  address_string: string;
  address_type?: "BILLING" | "SHIPPING";
};

export type InvoiceCustomer = {
  company_name: string;
  billing_address?: {
    address_string: string;
    street_address: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    uuid: string;
  };
  shipping_address?: {
    street_address: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    uuid: string;
  };
  customer_id: string;
  email: string;
  phone: string;
  uuid?: string;
  is_reminders_enabled: boolean;
  shipping_same_as_billing: boolean;
  billing_address_id: string;
  shipping_address_id: string;
};

export type ReminderSetting = {
  subject: string;
  cc_emails: string[];
  greeting: string;
  body: string;
  signature: string;
  reminder_setting_types: string;
  uuid: string;
};

export type InvoiceSettings = {
  entity_name: string;
  invoice_prefix: string;
  logo_url?: string;
  entity_id: string;
  address?: {
    address_string: string;
    uuid: string;
    street_address: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
  };
  email: string;
  payment_info?: string;
  reminder_settings?: ReminderSetting;
  connected_gmail: string;
};

export type InvoiceItem = {
  description: string;
  quantity: number;
  rate: number;
  amount?: string;
  uuid: string;
  product_data_id: string | null;
};

export type GroupInvoiceCustomer = {
  uuid: string;
  name: string;
  email: string;
  due_amount: number;
  latest_invoice_on: string | null;
  total_amount?: number;
  paid_amount?: number;
  source: "MANUAL" | "STRIPE";
  logo_url: string;
};

export type Invoice = {
  source: "MANUAL" | "STRIPE";
  created_on: string;
  title: string;
  customer?: InvoiceCustomer;
  customer_id?: string;
  invoice_number: string;
  invoice_number_without_prefix: string;
  po_number: string;
  sent_to: string;
  sent_on: string;
  discount: string;
  document?: {
    file_name: string;
    file_id: string;
  };
  discount_amount?: string;
  invoice_date?: string | null;
  status: (typeof INVOICE_STATUSES)[keyof typeof INVOICE_STATUSES];
  invoice_items: InvoiceItem[];
  due_date: string;
  sub_total: string;
  tax: string;
  tax_type: "PERCENT" | "ABSOLUTE";
  discount_type: "PERCENT" | "ABSOLUTE";
  tax_amount?: string;
  total: string;
  paid_amount: string;
  due_balance: string;
  invoice_amount: string;
  due_amount: string;
  notes: string;
  payment_link: string | null;
  terms: string;
  updated_at?: string;
  uuid: string;
  invoice_settings?: {
    is_recurring_enabled: boolean;
    recurring_setting_types: RecurringSettingTypes;
    next_recurring_date: string | null;
    recurring_end_date: string | null;
    is_recurring_send_automated: boolean;
    uuid: string;
  };
  associated_txns?: {
    uuid: string;
    amount: string;
    date: string;
    source: "MANUAL" | "STRIPE" | "BREX" | "MERCURY";
  }[];
  w_form?: SelecteFile;
  should_send_w_form: boolean;
  field_errors?: {
    invoice_number_without_prefix?: string;
  };
  entity: InvoiceSettings;
};

export const invoiceApis = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllInvoices: build.query<
      {
        invoices: Invoice[];
        current_page: number;
        total_pages: number;
        total_count: number;
        per_page: number;
        paid_amount: number;
        due_amount: number;
        total_amount: number;
      },
      {
        groupId: string;
        currentPage: number;
        entityId: string;
        searchTerm: string | null;
        sortCol?: "CREATED_AT" | "SENT_TO" | "AMOUNT";
        sortOrder?: "ASC" | "DSC";
        startDate?: string;
        endDate?: string;
        customerIds?: string;
        minAmount?: string;
        maxAmount?: string;
        limit?: number;
        linkedStatus?: "UNLINKED" | null;
      }
    >({
      query: ({
        groupId,
        entityId,
        currentPage,
        searchTerm,
        sortCol,
        sortOrder,
        startDate,
        endDate,
        customerIds,
        minAmount,
        maxAmount,
        limit = 20,
        linkedStatus,
      }) => {
        const query = stringify(
          {
            search_term: searchTerm,
            page_num: currentPage,
            sort_col: sortCol,
            sort_order: sortOrder,
            start_date: startDate,
            end_date: endDate,
            customer_uuids: customerIds,
            min_amount: minAmount,
            max_amount: maxAmount,
            limit: limit,
            linked_status: linkedStatus,
          },
          { skipNulls: true, addQueryPrefix: true }
        );

        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/invoices/${query}`,
        };
      },
      providesTags: (result) => (result ? [{ type: "INVOICES" }] : []),
    }),

    createEntityInvoice: build.mutation<
      Invoice,
      {
        groupId: string;
        entityId: string;
        payload?: Omit<
          Invoice,
          | "uuid"
          | "due_date"
          | "invoice_date"
          | "updated_at"
          | "document"
          | "status"
          | "sent_to"
          | "sent_on"
          | "title"
        > & { invoice_items?: InvoiceItem[] };
      }
    >({
      query: ({ groupId, entityId, payload }) => {
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/invoice/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "INVOICES" }] : []),
    }),

    deleteEntityInvoice: build.mutation<
      Invoice & { invoice_id: string },
      { groupId: string; entityId: string; invoiceId: string }
    >({
      query: ({ groupId, entityId, invoiceId }) => {
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/invoice/${invoiceId}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "INVOICES" }] : []),
    }),

    getEntityInvoice: build.query<
      Invoice,
      { groupId: string; entityId: string; invoiceId: string }
    >({
      query: ({ groupId, entityId, invoiceId }) => {
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/invoice/${invoiceId}/`,
        };
      },
      providesTags: (result) => {
        return result ? [{ type: "INVOICES" }] : [];
      },
    }),

    updateInvoice: build.mutation<
      Invoice,
      {
        groupId: string;
        entityId: string;
        invoiceId: string;
        payload: Partial<Invoice>;
      }
    >({
      query: ({ groupId, entityId, invoiceId, payload }) => {
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/invoice/${invoiceId}/`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: (result) =>
        result
          ? [
              { type: "INVOICES" },
              { type: "INVOICE_CUSTOMERS", id: result.customer?.customer_id },
              "AUDIT_LOG_BY_OBJECT",
              "JOURNAL_ENTRIES",
            ]
          : [],
    }),

    sendInvoice: build.mutation<
      Invoice,
      {
        groupId: string;
        entityId: string;
        invoiceId: string;
        payload: {
          attachments?: File[];
          body: string;
          subject: string;
          cc_emails?: string;
          from_email_type?: string;
        };
      }
    >({
      query: ({ groupId, entityId, invoiceId, payload }) => {
        const formData = new FormData();
        formData.append("subject", payload.subject);
        formData.append("body", payload.body);
        if (payload.cc_emails) {
          formData.append("cc_emails", payload.cc_emails);
        }
        formData.append("from_email_type", payload.from_email_type || "INKLE");
        payload.attachments?.forEach((file) => {
          formData.append("attachments", file);
        });

        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/invoice/${invoiceId}/send/`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "INVOICES" }] : []),
    }),

    downloadInvoice: build.query<
      { doc_id: string },
      { groupId: string; entityId: string; invoiceId: string }
    >({
      query: ({ groupId, entityId, invoiceId }) => {
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/invoice/${invoiceId}/download/`,
        };
      },
    }),

    getAllInvoiceCustomers: build.query<
      InvoiceCustomer[],
      {
        groupId: string;
        entityId: string;
      }
    >({
      query: ({ groupId, entityId }) =>
        `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/recipients/`,
      providesTags: (result) =>
        result
          ? [
              { type: "INVOICE_CUSTOMERS" as const, id: "LIST" },
              ...result.map((c) => ({
                type: "INVOICE_CUSTOMERS" as const,
                id: c.uuid,
              })),
            ]
          : [],
    }),

    getAllInvoiceCustomersList: build.query<
      Pagination & {
        client_customers: GroupInvoiceCustomer[];
      },
      {
        groupId: string;
        entityId: string;
        pageNum: string | number;
        searchTerm?: string | null;
        sources?: string;
      }
    >({
      query: ({ groupId, entityId, pageNum, searchTerm, sources }) => {
        const query = stringify(
          {
            page_num: pageNum,
            search_term: searchTerm || null,
            sources: sources || null,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/customers/${query}`,
        };
      },
      providesTags: (result) =>
        result
          ? [
              { type: "INVOICE_CUSTOMERS" as const, id: "LIST" },
              ...result.client_customers?.map((c) => ({
                type: "INVOICE_CUSTOMERS" as const,
                id: c.uuid,
              })),
            ]
          : [],
    }),

    getInvoiceCustomer: build.query<
      {
        uuid: string;
        name: string;
        email: string;
        phone: string;
        billing_address: string;
        due_amount: number;
        stripe_due_amount: number;
        total_amount: number;
        total_stripe_amount: number;
        paid_amount: number;
        paid_stripe_amount: number;
        percent_paid: number;
        stripe_percent_paid: number;
        total_percent_paid: number;
        invoices: Invoice[];
        is_reminders_enabled?: boolean;
        source: "MANUAL" | "STRIPE";
      },
      {
        groupId: string;
        entityId: string;
        customerId: string;
        sortCol?: "CREATED_AT" | "INVOICE_DATE" | "AMOUNT";
        sortOrder?: "ASC" | "DSC";
      }
    >({
      query: ({ groupId, entityId, customerId, sortCol, sortOrder }) => {
        const query = stringify(
          {
            sort_col: sortCol,
            sort_order: sortOrder,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/customers/${customerId}/${query}`,
          method: "GET",
        };
      },
      providesTags: (result) =>
        result ? [{ type: "INVOICE_CUSTOMERS", id: result.uuid }] : [],
    }),

    deleteCustomer: build.mutation<
      GroupInvoiceCustomer,
      { groupId: string; entityId: string; customerId: string }
    >({
      query: ({ groupId, entityId, customerId }) => {
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/customers/${customerId}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result) =>
        result ? [{ type: "INVOICE_CUSTOMERS", id: result.uuid }] : [],
    }),

    createInvoiceCustomer: build.mutation<
      InvoiceCustomer,
      {
        groupId: string;
        entityId: string;
        payload: Omit<
          InvoiceCustomer,
          | "customer_id"
          | "is_reminders_enabled"
          | "billing_address"
          | "shipping_address"
          | "shipping_same_as_billing"
          | "billing_address_id"
          | "shipping_address_id"
        >;
      }
    >({
      query: ({ groupId, entityId, payload }) => {
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/recipients/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: (result) =>
        result ? [{ type: "INVOICE_CUSTOMERS", id: "LIST" }] : [],
    }),

    updateInvoiceCustomer: build.mutation<
      InvoiceCustomer,
      {
        groupId: string;
        entityId: string;
        customerId: string;
        payload: Partial<InvoiceCustomer>;
      }
    >({
      query: ({ groupId, entityId, customerId, payload }) => {
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/recipients/${customerId}/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: (result) =>
        result
          ? [
              { type: "INVOICE_CUSTOMERS", id: result.uuid },
              { type: "INVOICES" },
              { type: "INVOICE_SETTING" },
            ]
          : [],
    }),

    createOrUpdateInvoiceSettings: build.mutation<
      InvoiceSettings & { logo: File },
      {
        groupId: string;
        entityId: string;
        payload: Partial<Omit<InvoiceSettings, "address">> & {
          autofill_address_id?: string;
        };
      }
    >({
      query: ({ groupId, entityId, payload }) => {
        const formData = new FormData();

        Object.entries(payload).forEach(
          // @ts-ignore
          ([key, value]) => value && formData.append(key, value)
        );

        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/entity_info/`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result) => {
        return result ? [{ type: "INVOICE_SETTING" }] : [];
      },
    }),

    getInvoiceSettings: build.query<
      InvoiceSettings | null,
      {
        groupId: string;
        entityId: string;
      }
    >({
      query: ({ groupId, entityId }) => {
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/entity_info/`,
        };
      },
      providesTags: (result) =>
        result || result === null ? [{ type: "INVOICE_SETTING" }] : [],
    }),

    getPendingDues: build.query<void, { groupId: string }>({
      query: ({ groupId }) => ({
        url: `/api/inkle/company/group/${groupId}/pending_dues/`,
      }),
    }),
    createInvoicingEmailConfiguration: build.mutation<
      null,
      {
        payload: {
          subject: string;
          cc_emails: string;
          greeting: string;
          body: string;
          signature: string;
          payment_info: string;
          reminder_setting_types: string;
        };
        groupId: string;
        entityId: string;
      }
    >({
      query: ({ payload, groupId, entityId }) => {
        return {
          url: `/api/inkle/invoicing/reminders/group/${groupId}/entity/${entityId}`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["INVOICE_SETTING"],
    }),
    updateInvoicingEmailConfiguration: build.mutation<
      null,
      {
        payload: {
          subject?: string;
          cc_emails?: string;
          greeting?: string;
          body?: string;
          signature?: string;
          payment_info?: string;
          reminder_setting_types?: string;
          is_reminders_enabled?: boolean;
        };
        reminderId: string;
        groupId: string;
        entityId: string;
      }
    >({
      query: ({ payload, reminderId, groupId, entityId }) => {
        return {
          url: `/api/inkle/invoicing/reminders/group/${groupId}/entity/${entityId}/reminder_setting/${reminderId}`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["INVOICE_SETTING"],
    }),
    createInvoiceRecurring: build.mutation<
      null,
      {
        payload: {
          is_recurring_enabled: boolean;
          recurring_setting_type?: RecurringSettingTypes;
          recurring_end_date?: string | null;
          is_recurring_send_automated?: boolean;
        };
        invoiceId: string;
        groupId: string;
        entityId: string;
      }
    >({
      query: ({ payload, invoiceId, groupId, entityId }) => {
        return {
          url: `/api/inkle/invoicing/invoice_settings/group/${groupId}/entity/${entityId}/invoice/${invoiceId}/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["INVOICES"],
    }),

    updateInvoiceRecurring: build.mutation<
      null,
      {
        payload: {
          is_recurring_enabled: boolean;
          recurring_setting_type?: RecurringSettingTypes;
          recurring_end_date?: string | null;
          is_recurring_send_automated?: boolean;
        };
        invoiceSettingId: string;
        groupId: string;
        entityId: string;
        invoiceId: string;
      }
    >({
      query: ({ payload, invoiceSettingId, groupId, entityId, invoiceId }) => {
        return {
          url: `/api/inkle/invoicing/invoice_settings/group/${groupId}/entity/${entityId}/invoice/${invoiceId}/invoice_setting/${invoiceSettingId}/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["INVOICES"],
    }),

    markInvoicePaid: build.mutation({
      query: ({
        entityId,
        invoiceId,
        transactionId,
        addedTransactions,
        accountId,
        manualTransactions,
      }) => {
        return {
          url: `/api/inkle/invoicing/entity/${entityId}/invoice/${invoiceId}/paid/`,
          method: "PUT",
          body: {
            txn_id: transactionId,
            split_transactions: addedTransactions,
            account_id: accountId,
            manual_transaction: manualTransactions,
          },
        };
      },
      invalidatesTags: ["INVOICES"],
    }),

    getPaidInvoiceTransactions: build.query<
      { transactions: Transactions[]; invoices: Invoice[] },
      { entityId: string; invoiceId: string }
    >({
      query: ({ entityId, invoiceId }) => {
        return {
          url: `/api/inkle/invoicing/entity/${entityId}/invoice/${invoiceId}/paid/`,
        };
      },
    }),

    getAllCustomerAddresses: build.query<
      CustomerAddress[],
      {
        entityId: string;
        customerId: string;
        addressType: "BILLING" | "SHIPPING";
      }
    >({
      query: ({ entityId, customerId, addressType }) => {
        const query = stringify(
          { address_type: addressType },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/invoicing/entity/${entityId}/recipients/${customerId}/address/${query}`,
        };
      },
      providesTags: ["CUSTOMER_ADDRESSES"],
    }),

    getCustomerAddress: build.query<
      CustomerAddress,
      {
        entityId: string;
        customerId: string;
        addressId: string;
      }
    >({
      query: ({ entityId, customerId, addressId }) => {
        return {
          url: `/api/inkle/invoicing/entity/${entityId}/recipients/${customerId}/address/${addressId}/`,
        };
      },
      providesTags: ["INVOICE_CUSTOMER_ADDRESS"],
    }),

    createCustomerAddress: build.mutation<
      CustomerAddress,
      {
        entityId: string;
        customerId: string;
        payload: { address: CustomerAddress };
      }
    >({
      query: ({ entityId, customerId, payload }) => {
        return {
          url: `/api/inkle/invoicing/entity/${entityId}/recipients/${customerId}/create_address/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["CUSTOMER_ADDRESSES"],
    }),

    updateCustomerAddress: build.mutation<
      CustomerAddress,
      {
        entityId: string;
        customerId: string;
        addressId: string;
        payload: { address: CustomerAddress };
      }
    >({
      query: ({ entityId, customerId, addressId, payload }) => {
        return {
          url: `/api/inkle/invoicing/entity/${entityId}/recipients/${customerId}/address/${addressId}/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["CUSTOMER_ADDRESSES"],
    }),

    genrateShortLink: build.mutation<
      { token: string },
      {
        entityId: string;
        invoiceId: string;
        from: "invoice_edit_screen" | "mail_draft_screen";
      }
    >({
      query: ({ entityId, invoiceId, from }) => {
        return {
          url: `/api/inkle/invoicing/entity/${entityId}/generate_short_link_token/`,
          method: "POST",
          body: {
            invoice_id: invoiceId,
            from,
          },
        };
      },
    }),

    getPublicInvoice: build.query<Invoice, { token: string }>({
      query: ({ token }) => {
        const query = stringify(
          { token },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/invoicing/webpage_details/${query}`,
        };
      },
    }),

    uploadBulkInvoice: build.mutation<
      {
        bulk_file_id: string;
      },
      {
        entityId: string;
        file: File;
      }
    >({
      query: ({ entityId, file }) => {
        const formData = new FormData();
        formData.append("excel", file);

        return {
          url: `/api/inkle/invoicing/entity/${entityId}/bulk_xlsx/`,
          method: "POST",
          body: formData,
        };
      },
    }),
    populateBulkInvoice: build.mutation<
      fileAndFolder[],
      {
        entityId: string;
        bulkFileId: string;
      }
    >({
      query: ({ entityId, bulkFileId }) => {
        return {
          url: `/api/inkle/invoicing/entity/${entityId}/populate_bulk_xlsx/`,
          method: "POST",
          body: { bulk_file_id: bulkFileId },
        };
      },
      invalidatesTags: ["INVOICES"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetPendingDuesQuery,
  useGetAllInvoicesQuery,
  useGetEntityInvoiceQuery,
  useCreateEntityInvoiceMutation,
  useUpdateInvoiceMutation,
  useGetAllInvoiceCustomersQuery,
  useCreateInvoiceCustomerMutation,
  useUpdateInvoiceCustomerMutation,
  useGetInvoiceSettingsQuery,
  useCreateOrUpdateInvoiceSettingsMutation,
  useSendInvoiceMutation,
  useLazyDownloadInvoiceQuery,
  useGetAllInvoiceCustomersListQuery,
  useGetInvoiceCustomerQuery,
  useDeleteEntityInvoiceMutation,
  useLazyGetEntityInvoiceQuery,
  useCreateInvoicingEmailConfigurationMutation,
  useUpdateInvoicingEmailConfigurationMutation,
  useCreateInvoiceRecurringMutation,
  useUpdateInvoiceRecurringMutation,
  useDeleteCustomerMutation,
  useLazyGetAllInvoiceCustomersListQuery,
  useMarkInvoicePaidMutation,
  useGetPaidInvoiceTransactionsQuery,
  useGetAllCustomerAddressesQuery,
  useGetCustomerAddressQuery,
  useCreateCustomerAddressMutation,
  useUpdateCustomerAddressMutation,
  useGenrateShortLinkMutation,
  useGetPublicInvoiceQuery,
  useUploadBulkInvoiceMutation,
  usePopulateBulkInvoiceMutation,
} = invoiceApis;
