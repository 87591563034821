import { TableUI } from "components/design/TableUI";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useModal } from "hooks/useModal";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import { useState } from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import {
  BankTransferOrderDetails,
  useGetAllBankTransferQuery,
} from "store/apis/bankTransfer";
import { ArrayElement } from "types/utils/ArrayElement";
import { debounce } from "utils/debouncing";
import { formatDate } from "utils/formatDate";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { useDispatch } from "react-redux";
import { openBankTransferDetails } from "store/slices/bankTransfer";
import BankTransferZeroScreen from "static/images/BankTransferZeroScreen.svg";

export const BankTransferStatus = ({
  status,
}: {
  status: "PENDING" | "PARTIAL" | "SUCCESS" | "FAILED";
}) => {
  switch (status) {
    case "PARTIAL": {
      return (
        <Tag icon={false} tagType="yellow">
          Partially paid
        </Tag>
      );
    }
    case "PENDING": {
      return (
        <Tag icon={false} tagType="yellow">
          Awaiting confirmation
        </Tag>
      );
    }
    case "SUCCESS": {
      return (
        <Tag icon={false} tagType="green">
          Payment Successful
        </Tag>
      );
    }
    case "FAILED": {
      return (
        <Tag icon={false} tagType="red">
          Failed
        </Tag>
      );
    }
    default: {
      return (
        <Tag icon={false} tagType="yellow">
          Awaiting confirmation
        </Tag>
      );
    }
  }
};

export const BankTransfer = () => {
  const { update } = useUpdateQuery();
  const query = useQuery();
  const page = Number(query.get("page")) || 1;
  const entityId = useCurrentEntityId();
  const [searchTerm, setSearchTerm] = useState("");
  const [bankTransferId, setBankTransferId] = useState("");
  const bankTransferModal = useModal();
  const dispatch = useDispatch();

  const {
    data: bankTransfers,
    isLoading,
    isSuccess,
  } = useGetAllBankTransferQuery(
    { entityId, search: searchTerm || null, pageNum: page || null },
    { skip: !entityId }
  );

  const {
    bank_transfer: orders = [],
    current_page = 0,
    per_page = 0,
    total_count = 0,
    total_pages = 0,
  } = bankTransfers || {};

  const handleChange = debounce((e) => {
    setSearchTerm(e.target.value);
  });

  const openDetails = (id: string) => {
    dispatch(
      openBankTransferDetails({
        bankTransferOrderId: id,
        isFromLaunch: false,
      })
    );
  };

  const createColumn =
    createColumnHelper<ArrayElement<BankTransferOrderDetails[]>>();

  const columns = [
    createColumn.accessor("order_id", {
      cell: (info) => (
        <div className="t-text-text-100">Order#{info.getValue()}</div>
      ),
      header: "ORDER ID",
      size: 20,
    }),

    createColumn.accessor("items_count", {
      cell: (info) => {
        return <div className="t-text-text-100">{info.getValue()}</div>;
      },
      header: "NUMBER OF ITEMS",
      size: 13,
    }),

    createColumn.accessor("amount", {
      cell: (info) => {
        return (
          <div className="t-text-text-100">
            <AmountSuperScript amount={Number(info.getValue())} />
          </div>
        );
      },
      header: "AMOUNT",
      size: 13,
    }),

    createColumn.accessor("created_at", {
      header: "DATE OF CREATION",
      cell: (info) => (
        <div className="t-text-text-100">{formatDate(info.getValue())}</div>
      ),
      size: 13,
    }),

    createColumn.accessor("status", {
      cell: (info) => <BankTransferStatus status={info.getValue()} />,
      header: "STATUS",
      size: 25,
    }),

    createColumn.accessor("uuid", {
      header: "",
      cell: (info) => (
        <Button size="small" onClick={() => openDetails(info.getValue())}>
          View Details
        </Button>
      ),
      size: 15,
    }),
  ];

  const table = useReactTable({
    data: orders,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const goToFirstPage = () => {
    update({ query: "page", value: 1 });
  };

  const goToPrevPage = () => {
    const localCurrentPage = page < total_pages ? page : total_pages;
    update({ query: "page", value: localCurrentPage - 1 });
  };

  const goToNextPage = () => {
    if (page < total_pages) {
      update({ query: "page", value: page + 1 });
    }
  };

  const goToLastPage = () => {
    update({ query: "page", value: total_pages });
  };

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  const isEmpty = orders.length == 0;

  return (
    <div className="t-mt-1 t-space-y-6 t-h-full">
      <Async.Root isLoading={isLoading} isSuccess={isSuccess} isEmpty={isEmpty}>
        <Async.Empty>
          <div className="t-flex t-flex-col t-items-center t-justify-center t-w-full t-min-h-full">
            <img src={BankTransferZeroScreen} alt="BankTransferZeroScreen" />
            <div className="t-text-text-body t-text-text-30">
              No bank transfers have been initiated yet
            </div>
          </div>
        </Async.Empty>
        <Async.Success>
          <div className="t-flex t-justify-between t-items-center">
            <div className="t-flex t-gap-1.5 t-items-center t-w-1/2">
              <Search onChange={handleChange} block placeholder="Search..." />
            </div>
            <Pagination
              {...paginationData}
              goToFirstPage={goToFirstPage}
              goToPrevPage={goToPrevPage}
              goToNextPage={goToNextPage}
              goToLastPage={goToLastPage}
            />
          </div>
          <TableUI table={table} />
        </Async.Success>
      </Async.Root>
    </div>
  );
};
