import { Button } from "components/DesignSystem/Button/Button";
import { HighlightSearchTerm } from "components/DesignSystem/HighlightText";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { Cart } from "components/icons/Cart";
import { ProductCard } from "components/ProductCard/ProductCard";
import { AddonPricing, GetDescription } from "components/SubscriptionSummary";
import {
  BOOKKEEPING_SOFTWARE,
  BOOKKEEPING_V2,
  REGISTERED_AGENT,
  _MANAGE,
} from "constants/addons";
import dayjs from "dayjs";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useDispatch } from "react-redux";
import { chatApi, useCreateMessageDraftMutation } from "store/apis/chat";
import { AddonData, setAddonData, setAddonType } from "store/slices/addons";
import { toggleCartSubscription } from "store/slices/cartSubscription";
import { openFloatingChat, setToOpenChatId } from "store/slices/chat";
import { Subscription } from "types/Models/subscription";
import { formatDate } from "utils/formatDate";

export const SubscriptionTypeCard = ({
  subscription,
  isPrimarySubscription,
}: {
  subscription: Subscription;
  isPrimarySubscription?: boolean;
}) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const search = query.get("search") || "";
  const { isAdmin } = useRoleBasedView();
  const { secondary_channel_url } = useCurrentGroupContext();
  const [createUpdateDraft, { isLoading }] = useCreateMessageDraftMutation();

  const onSubscribeHandler = async (
    addon: AddonData,
    useCart: boolean = false
  ) => {
    if (isBooksService && !subscription.group_subscription && !isAdmin) {
      if (secondary_channel_url) {
        const messageData = await createUpdateDraft({
          channelUrl: secondary_channel_url,
          message: `I would like to subscribe to the Bookkeeping Service, please let me know the next steps.`,
        });

        if (messageData.data) {
          await dispatch(
            chatApi.util.upsertQueryData(
              "getDraftMessage",
              { channelUrl: secondary_channel_url },
              messageData.data
            )
          );
        }
      }

      dispatch(setToOpenChatId(secondary_channel_url));
      return dispatch(openFloatingChat());
    }
    dispatch(toggleCartSubscription(useCart));
    dispatch(setAddonData(addon));
    dispatch(setAddonType(addon?.subscription_type!));
  };

  const onManageHandler = (addon: AddonData) => {
    dispatch(setAddonData(addon));
    dispatch(setAddonType(addon?.subscription_type + _MANAGE));
  };

  const isSubscribed =
    subscription.subscription_type !== "REGISTERED_AGENT" &&
    subscription.group_subscription;

  let minAmount =
    subscription.ra_state_amount_maps?.reduce(
      (ac, cv) => Math.min(Number(ac), Number(cv)),
      Infinity
    ) || subscription.amount;

  const isBooksService = subscription.subscription_type === BOOKKEEPING_V2;

  return (
    <ProductCard
      key={subscription.uuid}
      isPrimarySubscription={isPrimarySubscription}
      title={
        <HighlightSearchTerm
          text={
            isBooksService && !subscription.group_subscription
              ? "Bookkeeping Service - Subscription"
              : subscription.subscription_name
          }
          searchTerm={search}
        />
      }
      description={
        subscription.subscription_type === "REGISTERED_AGENT"
          ? "Registered Agent service for all states of the United States of America"
          : !subscription.stripe_coupon && (
              <GetDescription
                type={subscription.subscription_type}
                subscriptions={subscription.group_subscription}
              />
            )
      }
      price={
        isBooksService && !subscription.group_subscription ? null : (
          <AddonPricing
            addon={
              subscription.subscription_type !== "REGISTERED_AGENT"
                ? subscription
                : { ...subscription, amount: minAmount }
            }
          />
        )
      }
      action={
        isSubscribed ? (
          <div className="t-flex t-gap-1">
            {subscription.scheduled_date &&
              dayjs(subscription.scheduled_date).isAfter(dayjs()) && (
                <Tag tagType="gray" icon={false}>
                  Starts on {formatDate(subscription?.scheduled_date)}
                </Tag>
              )}
            <Button size="small" onClick={() => onManageHandler(subscription)}>
              Manage plan
            </Button>
            <div>
              {[REGISTERED_AGENT, BOOKKEEPING_SOFTWARE].includes(
                subscription.subscription_type
              ) && (
                <Button
                  customType="icon"
                  size="small"
                  onClick={() => onSubscribeHandler(subscription, true)}
                >
                  <Cart />
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div className="t-flex t-gap-1">
            <Button
              size="small"
              isLoading={isLoading}
              onClick={() => onSubscribeHandler(subscription, false)}
            >
              {isBooksService && !isAdmin ? "Get Quote" : "Subscribe"}
            </Button>
            {isBooksService && !subscription.group_subscription && isAdmin && (
              <Button
                customType="icon"
                size="small"
                onClick={() => onSubscribeHandler(subscription, true)}
              >
                <Cart />
              </Button>
            )}
          </div>
        )
      }
      planStatus={Boolean(isSubscribed) ? "ACTIVE" : undefined}
      isSubscription
    />
  );
};
