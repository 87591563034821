import classNames from "classnames";
import { Badge } from "components/design/badge";
import ConditionalDynamicToolTip from "components/design/conditionalDynamicToolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { PERCENT } from "constants/stripeCouponTypes";
import { CANT_EDIT_BECAUSE_TASK_ARCHIVED } from "constants/task";
import { useToast } from "hooks/useToast";
import { useContext, useEffect, useState } from "react";
import { putDeadline } from "../../apis/editDeadline";
import {
  ADHOC_UPDATE,
  TASK_EDIT,
  TASK_LOG,
} from "../../constants/adhocTaskIdentifier";
import authContext from "../../jwt_context&axios/authContext";
import ActiveTaskEdit from "../../static/images/ActiveTaskEdit.svg";
import ActiveTaskLog from "../../static/images/ActiveTaskLog.svg";
import TaskEdit from "../../static/images/TaskEdit.svg";
import TaskLog from "../../static/images/TaskLog.svg";
import { checkEmptyObject } from "../../utils/checkEmptyObject";
import { getDate } from "../../utils/formatDayJs";
import { updateTaskState } from "./adhocTaskFunctions";
import TaskButtonBar from "./TaskButtonBar";
import TaskLogModal from "./taskLogModal";
import { useDispatch, useSelector } from "react-redux";
import { openTaskReview } from "store/slices/TaskReview";
import { FilingReviewModal } from "./FilingReviewModal";
import Modal from "components/DesignSystem/Modal/Modal";
import { Form, Formik } from "formik";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { PriceInput } from "components/PriceInput/PriceInput";
import { adHocScopeChange } from "formValidations/adHocScopeChange";
import { useRoleBasedView } from "hooks/useRoleBasedView";

export const AdhocScopeOfWork = ({
  next,
  task,
  activeScopeOfWork,
  setLoad,
  hasCustomerApproved,
  isArchived,
  parentRef,
}) => {
  const { authtoken } = useContext(authContext);
  const { alertToast } = useToast();
  const [isActiveIcon, setActiveIcon] = useState(null);
  const [isShowEdit, setShowEdit] = useState(false);
  const [isShowLog, setShowLog] = useState(false);
  const [isFinal, setFinal] = useState(false);
  const { created_at, price, scope_of_work, updated_at } = activeScopeOfWork;
  const isPricingStep = useSelector((state) => state.taskSlice.isPricingStep);
  const { isAdmin } = useRoleBasedView();

  let isNextDisable =
    !authtoken?.is_service_user && !authtoken?.is_any_service_user;
  const { showTaskReviewStep, showTaskReview } = useSelector(
    (state) => state.taskReview
  );
  const dispatch = useDispatch();

  const isActive = (action) => isActiveIcon === action;
  const openEditModal = () => setShowEdit(true);
  const closeEditModal = () => setShowEdit(false);
  const openLogModal = () => setShowLog(true);
  const closeLogModal = () => setShowLog(false);
  const [isLoading, setLoading] = useState();

  const openTaskReviewModal = () => {
    if (showTaskReviewStep) {
      dispatch(openTaskReview());
    }
  };

  const onSubmit = async ({ scopeOfWork, amount }) => {
    try {
      setLoading(true);
      const payload = {
        operation: ADHOC_UPDATE,
        scope_of_work: scopeOfWork,
        price: amount,
        new_state_id: task?.task_states[0].uuid,
      };
      const res = await putDeadline({ task_id: task.uuid, body: payload });
      if (res.success) setLoad((prev) => !prev);
    } catch (e) {
      alertToast({ message: e?.response?.data?.error?.message });
    }
    setLoading(false);
    closeEditModal();
  };

  const updateState = () => updateTaskState(next);
  useEffect(() => {
    setFinal(task?.state?.is_final);
  }, [task]);

  const coupon = task?.coupon;

  const discountOff =
    coupon?.discount_type === PERCENT
      ? `${coupon?.discount_rate}%`
      : `$${coupon?.discount_rate}`;

  let finalPrice = coupon?.discounted_price || price;

  return (
    <div
      className={classNames("taxing scopeOfWorkContainer", {
        disabledForm: isArchived || (isPricingStep && !isAdmin),
      })}
    >
      <div className="d-flex justify-content-between w-100">
        <h6 className="pricingSubtitle">Scope of Work and Inkle Fee</h6>
        <div className="actionContainer">
          <ConditionalDynamicToolTip
            condition={isArchived && CANT_EDIT_BECAUSE_TASK_ARCHIVED}
          >
            <img
              onClick={!isArchived && openLogModal}
              onMouseEnter={() => {
                setActiveIcon(TASK_LOG);
              }}
              onMouseLeave={() => {
                setActiveIcon(null);
              }}
              className="icon"
              src={isActive(TASK_LOG) ? ActiveTaskLog : TaskLog}
              alt="TaskLog"
            />
          </ConditionalDynamicToolTip>
          {!authtoken?.is_service_user && !authtoken?.is_any_service_user ? (
            <></>
          ) : (
            <>
              {!isFinal && (
                <ConditionalDynamicToolTip
                  condition={isArchived && CANT_EDIT_BECAUSE_TASK_ARCHIVED}
                >
                  <img
                    onClick={!isArchived && openEditModal}
                    onMouseEnter={() => {
                      setActiveIcon(TASK_EDIT);
                    }}
                    onMouseLeave={() => {
                      setActiveIcon(null);
                    }}
                    className="taskLog icon"
                    src={isActive(TASK_EDIT) ? ActiveTaskEdit : TaskEdit}
                    alt="TaskEdit"
                  />
                </ConditionalDynamicToolTip>
              )}
            </>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between w-100">
        <span className="scopeOfWork content t-w-4/5">{scope_of_work}</span>
        <span className="scopeOfWork">$ {price}</span>
      </div>
      <div className="d-flex justify-content-end w-100 inkleServiceFee pricingSubtitle">
        Inkle service fee
      </div>
      <div className="pricingAmount">
        <span>
          {coupon && (
            <div className="t-mb-2 t-flex t-items-center">
              <span className="t-mr-2 t-text-body-sm t-text-text-30">
                {coupon.coupon_code}
              </span>
              <Badge color="light-green">
                <span className="t-text-caption t-font-medium">
                  {discountOff} off
                </span>
              </Badge>
            </div>
          )}
          <span className="t-block t-text-right">$ {finalPrice}</span>
        </span>
      </div>
      <span className="creationDate">
        Created on : {getDate(created_at)} | LAST EDIT : {getDate(updated_at)}
      </span>
      {!isArchived && !hasCustomerApproved && (
        <TaskButtonBar justifyContent="end" parentRef={parentRef}>
          <ConditionalDynamicToolTip
            condition={isNextDisable && "Awaiting confirmation from Inkle Team"}
          >
            <span>
              <Button
                onClick={showTaskReviewStep ? openTaskReviewModal : updateState}
                customType="primary"
                disabled={isNextDisable}
              >
                Next
              </Button>
            </span>
          </ConditionalDynamicToolTip>
        </TaskButtonBar>
      )}

      <Formik
        initialValues={{
          scopeOfWork: scope_of_work,
          amount: price,
        }}
        onSubmit={onSubmit}
        validationSchema={adHocScopeChange}
      >
        <Modal.Root onOpenChange={closeEditModal} open={isShowEdit}>
          <Modal.Content asChild>
            <Form className="t-flex t-flex-col t-gap-2">
              <Modal.Header>
                <Modal.Title>Edit Pricing</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                <TextArea
                  label="Scope of work"
                  name="scopeOfWork"
                  placeholder="Scope of work"
                  required
                />

                <PriceInput
                  label="Price"
                  name="amount"
                  placeholder="Price"
                  className="amountEdit w-100"
                />
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Button
                  customType="primary"
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Update Adhoc Task
                </Button>
              </Modal.FooterButtonGroup>
            </Form>
          </Modal.Content>
        </Modal.Root>
      </Formik>

      {isShowLog && (
        <TaskLogModal
          closeModal={closeLogModal}
          taskLogs={task.adhoc_data_updates}
        />
      )}
      {showTaskReview && (
        <FilingReviewModal isArchived={isArchived} handleSubmit={updateState} />
      )}
    </div>
  );
};
