import classNames from "classnames";
import { Chip } from "components/DesignSystem/Chips/Chips";
import {
  Filter,
  MultiSelectFilter,
} from "components/DesignSystem/Filter/Filter";
import Radio from "components/DesignSystem/RadioGroup/RadioGroup";
import { Search } from "components/DesignSystem/Search/Search";
import {
  ETD_FILTER_OPTIONS,
  MY_FILING_SORT_OPTIONS,
  UN_SET,
} from "constants/task";
import React, { useState } from "react";
import SortDescendingIcon from "../../../static/images/SortDescending.svg";
import { useRoleBasedView } from "hooks/useRoleBasedView";

type ConsultantDashBoardGlobalFilterProps = {
  searchedTerm: string;
  selectedStateFilter: string | null;
  tags: Array<{ uuid: string; title: string }>;
  selectedTag: string | null;
  taskStates: Array<{ uuid: string; name: string }>;
  templates: Array<{ uuid: string; title: string; year?: string }>;
  selectedTemplate: string | null;
  selectedSortOption: { id: number; title: string; value: string };
  setSelectedSortOption: (option: {
    id: number;
    title: string;
    value: string;
  }) => void;
  showSortFeature: boolean;
  update: (updateObj: { query: string; value: string | null }) => void;
  selectedETD: string | null;
};

export const ConsultantDashBoardGlobalFilter = ({
  searchedTerm,
  selectedStateFilter,
  tags,
  selectedTag,
  taskStates,
  templates,
  selectedTemplate,
  selectedSortOption,
  setSelectedSortOption,
  showSortFeature,
  update,
  selectedETD,
}: ConsultantDashBoardGlobalFilterProps) => {
  const [hideSortDiv, setHideSortDiv] = useState(true);
  const selectedEtdLabel = ETD_FILTER_OPTIONS.find(
    ({ value }) => value === selectedETD
  )?.label;

  function manageSortOptionModal() {
    setHideSortDiv((hideSortDiv) => !hideSortDiv);
  }

  function setSortOption(
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    sortOption: { id: number; title: string; value: string }
  ) {
    setHideSortDiv(true);
    setSelectedSortOption(sortOption);
    e.stopPropagation();
  }

  const searchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    update({ query: "search_term", value: value || null });
  };

  const templateFilter = (values: string[]) => {
    update({
      query: "task_template_filter_uuids",
      value: values.join(",") || null,
    });
  };

  const tagsFilter = (values: string[]) => {
    update({ query: "task_tag_filter_uuids", value: values.join(",") || null });
  };

  const handleEtdFilter = (value: string) => {
    update({
      query: "etd_filter",
      value: value || null,
    });
  };

  const stateFilter = (values: string[]) => {
    update({
      query: "task_state_filter_uuids",
      value: values.join(",") || null,
    });
  };

  return (
    <div className="t-w-full">
      <div className="t-flex t-justify-between t-w-full">
        <div className="t-w-1/2 t-m">
          <Search
            placeholder="Search..."
            defaultValue={searchedTerm || ""}
            onChange={searchQuery}
            autoFocus
          />
        </div>
        <div
          className={classNames("selectSortFilterDiv t-h-8", {
            "t-hidden": !showSortFeature,
          })}
          onClick={manageSortOptionModal}
        >
          <img src={SortDescendingIcon} alt="icon" height="24px" width="24px" />
          <span className="selectSortFilter">Sort By :</span>
          <span className="selectSortFilter">{selectedSortOption.value}</span>
          <div
            className={classNames("sortOptionModal", {
              hideSortFilterDiv: hideSortDiv,
            })}
          >
            {MY_FILING_SORT_OPTIONS.map((option) => {
              return (
                <p
                  key={option.id}
                  className={classNames("consultantDashBoardSortOptions", {
                    consultantDashBoardSelectedSortOption:
                      option.value === selectedSortOption.value,
                  })}
                  onClick={(e) => setSortOption(e, option)}
                >
                  {option.title}
                </p>
              );
            })}
          </div>
        </div>
      </div>
      <div className="t-mt-3 t-mb-2 t-flex t-flex-wrap">
        {/* //state  */}
        <Filter.Root
          defaultValue="STATE"
          title="Filter"
          capsule={
            <>
              {selectedStateFilter &&
                selectedStateFilter?.split(",").length > 0 && (
                  <Chip
                    onClose={() => stateFilter([])}
                    isActive
                    filterType="STATE"
                  >
                    State ({selectedStateFilter.split(",").length})
                  </Chip>
                )}
              {selectedTemplate && selectedTemplate?.split(",").length > 0 && (
                <Chip
                  onClose={() => templateFilter([])}
                  isActive
                  filterType="FILING"
                >
                  Filing ({selectedTemplate.split(",").length})
                </Chip>
              )}
              {selectedTag && selectedTag?.split(",").length > 0 && (
                <Chip onClose={() => tagsFilter([])} isActive filterType="TAG">
                  Tag ({selectedTag.split(",").length})
                </Chip>
              )}
              {selectedETD && selectedEtdLabel && (
                <Chip
                  onClose={() => handleEtdFilter("")}
                  isActive
                  filterType="ETD"
                >
                  {selectedEtdLabel}
                </Chip>
              )}
            </>
          }
        >
          <Filter.Portal>
            <Filter.List>
              <Filter.ListItem value="STATE">State</Filter.ListItem>
              <Filter.ListItem value="FILING">Filing</Filter.ListItem>
              <Filter.ListItem value="TAG">Tag</Filter.ListItem>
              <Filter.ListItem value="ETD">ETD</Filter.ListItem>
            </Filter.List>
            <Filter.Body value="STATE" block>
              {taskStates && (
                <MultiSelectFilter
                  totalCount={taskStates.length}
                  onChange={stateFilter}
                  options={taskStates.map((taskState) => ({
                    value: taskState.uuid,
                    label: taskState.name,
                  }))}
                  selected={selectedStateFilter?.split(",")}
                />
              )}
            </Filter.Body>
            <Filter.Body value="FILING" block>
              <MultiSelectFilter
                onChange={templateFilter}
                options={templates?.map((template) => ({
                  value: template.uuid,
                  label: `${template.title}  ${
                    !template.year ? "" : `- ${template.year}`
                  }`,
                }))}
                selected={selectedTemplate?.split(",")}
              />
            </Filter.Body>
            <Filter.Body value="TAG" block>
              <MultiSelectFilter
                onChange={tagsFilter}
                options={tags?.map((tag) => ({
                  value: tag.uuid,
                  label: tag.title,
                }))}
                selected={selectedTag?.split(",")}
              />
            </Filter.Body>
            <Filter.Body value="ETD" block>
              <Radio.Root
                value={selectedETD || ""}
                onValueChange={handleEtdFilter}
              >
                <Radio.Content className="t-gap-3 t-flex t-flex-col">
                  {ETD_FILTER_OPTIONS.map((item) => (
                    <Radio.Item key={item.value} value={item.value}>
                      {item.label}
                    </Radio.Item>
                  ))}
                </Radio.Content>
              </Radio.Root>
            </Filter.Body>
          </Filter.Portal>
        </Filter.Root>
      </div>
    </div>
  );
};
