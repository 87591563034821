import ChatCard from "components/DesignSystem/ChatCard/v2/ChatCard";
import { Cross } from "components/icons/Cross";
import { FINANCIAL_CLOSING_CARD_TYPE } from "constants/chatType";
import { useAppDispatch } from "hooks/useAppDispatch";
import { Period } from "pages/Books/FinancialClosing/FinancialClosing";
import React from "react";
import {
  FinancialClosingState,
  resetChatCardAttachment,
} from "store/slices/financialClosing";

type FinancialClosingCardAttachmentProps = {
  attachment: FinancialClosingState["chatCardAttachment"];
};

const LABEL_TEXT = {
  [FINANCIAL_CLOSING_CARD_TYPE.CLARIFICATION_RAISED]: "Clarification Raised",
  [FINANCIAL_CLOSING_CARD_TYPE.REVIEW_FINANCIAL]: "Review Financials",
};

export const FinancialClosingCardAttachment = ({
  attachment,
}: FinancialClosingCardAttachmentProps) => {
  const dispatch = useAppDispatch();

  return (
    <div>
      <button
        className="all:unset t-rounded-full t-border t-border-neutral-20 t-border-solid t-p-1 t-h-6 t-w-6 t-flex t-items-center t-justify-center t-bg-neutral-10 t-absolute t-top-0 t-right-0"
        onClick={() => dispatch(resetChatCardAttachment())}
      >
        <Cross />
      </button>
      <div className="t-bg-neutral-0 t-p-1 t-rounded">
        <ChatCard.Content addMargin={false}>
          <ChatCard.Header>
            <span className="t-text-text-100 t-text-subtext">
              {LABEL_TEXT[attachment.card_type as keyof typeof LABEL_TEXT]}
            </span>
          </ChatCard.Header>
          <ChatCard.Header>
            <span className="t-text-text-30 t-text-body-sm">
              <Period
                end_date={attachment.end_date}
                start_date={attachment.start_date}
              />
            </span>
          </ChatCard.Header>
        </ChatCard.Content>
      </div>
    </div>
  );
};
