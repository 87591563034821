import { BarCustomLayerProps, ResponsiveBar } from "@nivo/bar";
import classNames from "classnames";
import { BlockedButton } from "components/BlockedButton/BlockedButton";
import { ConditionalLink } from "components/conditionalLink";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { Loader } from "components/DesignSystem/Loader/Loader";
import Modal from "components/DesignSystem/Modal/Modal";
import { EmptyMetrics } from "components/EmptyMetrics/EmptyMetrics";
import { ArrowRight } from "components/icons/ArrowRight";
import { Expand } from "components/icons/Expand";
import { ProfitLossGraph } from "components/icons/ProfitLossGraph";
import { EmptyCashBalanceMetrics } from "components/Illustrations/EmptyCashBalanceMetrics";
import { PermissionBasedUI } from "components/PermissionBasedUI/PermissionBasedUI";
import { monthPeriod } from "constants/bookkeeping";
import { colors } from "constants/colors";
import { YYYY_MM_DD } from "constants/date";
import {
  EXPENSE_BAR_COLOR,
  PROFIT_LOSS_LINE_COLOR,
  REVENUE_BAR_COLOR,
} from "constants/revenueMetrics";
import { REVENUE_METRICS } from "constants/subscriptionPermissionFeatures";
import dayjs from "dayjs";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { SingleValue } from "react-select";
import { useGetRevenueMetricsQuery } from "store/apis/revenueMetrics";
import { useGetAllAccountQuery } from "store/apis/transactions";
import { RevenueMetrics } from "types/Models/revenueMetrics";
import { formatAmount } from "utils/foramtAmount";
import { DateRangeValue, getDateRange } from "utils/getDateRange";
import { motion } from "framer-motion";
import RevenueMetricesBackground from "static/images/RevenueMetricesBackground.svg";

const InfoToolTip = ({
  data,
  children,
}: {
  data: RevenueMetrics;
  children: ReactNode;
}) => {
  return (
    <ToolTip
      propClass="!t-bg-neutral-100/70"
      text={
        <div className="t-text-start t-flex t-flex-col t-gap-2">
          <div>{data.period}</div>
          <div className="t-flex t-text-center t-gap-2 t-items-center">
            <div className="t-h-3 t-w-3 t-bg-purple-20 t-rounded-sm"></div>
            Revenue: {formatAmount(Math.abs(Number(data.revenue || 0)))}
          </div>
          <div className="t-flex t-text-center t-gap-2 t-items-center">
            <div className="t-h-3 t-w-3 t-bg-neutral-10 t-rounded-sm"></div>
            Expense: {formatAmount(Math.abs(Number(data.expense || 0)))}
          </div>
          <div className="t-flex t-text-center t-gap-2 t-items-center">
            <div className="t-h-3 t-w-3 t-flex t-items-center t-rounded-sm t-border t-border-solid t-border-neutral-20 t-bg-white">
              <div className="t-w-1 t-h-1 t-bg-purple-30 t-rotate-90"></div>
              <div className="t-bg-purple-30 t-rounded-full t-h-1 t-w-1"></div>
            </div>
            {Number(data.profit) < 0 ? "Loss: " : "Profit: "}
            {formatAmount(Math.abs(Number(data.profit || 0)))}
          </div>
        </div>
      }
    >
      {children}
    </ToolTip>
  );
};

const Bar = (props: BarCustomLayerProps<RevenueMetrics>) => {
  const revenue = props.bars.filter((bar) => bar.data.id === "revenue");
  const expense = props.bars.filter((bar) => bar.data.id === "expense");

  return (
    <>
      {[...revenue, ...expense].map(
        ({ x, y, width, height, color, key, label, data }) => {
          return (
            <InfoToolTip data={data.data} key={key}>
              <motion.g
                initial={{ x: x, y: y }}
                animate={{ x: x, y: y }}
                transform={`translate(${x}, ${y})`}
                aria-label={label}
              >
                <motion.rect
                  initial={{ height: "100%", width: "100%" }}
                  animate={{ height: height, width: width }}
                  fill={color}
                  strokeWidth="0"
                  stroke={color}
                  focusable="false"
                  z={-20}
                ></motion.rect>
              </motion.g>
            </InfoToolTip>
          );
        }
      )}
      {[...revenue, ...expense].map(({ x, width, key, label, data }) => {
        const DOT_SIZE = 8;

        const revenuepoints = revenue.find(
          (bar) => bar.data.index === data.index
        );
        const expensepoints = expense.find(
          (bar) => bar.data.index === data.index
        );

        const th = revenuepoints?.height! - expensepoints?.height!;

        const pointsX = x + width / 2;
        const pointsY = expensepoints?.y! - th - 5;

        const x2revenuePoints = revenue.find(
          (bar) => bar.data.index === data.index + 1
        );
        const y2expensepoints = expense.find(
          (bar) => bar.data.index === data.index + 1
        );

        const x2 = x2revenuePoints?.x! + x2revenuePoints?.width! / 2;

        const y2 =
          y2expensepoints?.y! -
          (x2revenuePoints?.height! - y2expensepoints?.height!);

        return (
          <InfoToolTip data={data.data} key={key}>
            <motion.g>
              <motion.g
                transform={`translate(${pointsX - DOT_SIZE / 2}, ${pointsY})`}
                aria-label={label}
                aria-details={data.value?.toString()}
              >
                <motion.rect
                  fill={PROFIT_LOSS_LINE_COLOR}
                  width={DOT_SIZE}
                  height={DOT_SIZE}
                  key={key}
                  rx={DOT_SIZE / 2}
                  ry={DOT_SIZE / 2}
                ></motion.rect>
              </motion.g>
              {x2 && y2 && (
                <motion.line
                  x1={pointsX}
                  y1={pointsY + DOT_SIZE / 2}
                  x2={x2}
                  y2={y2}
                  stroke={PROFIT_LOSS_LINE_COLOR}
                  strokeWidth="1.5"
                ></motion.line>
              )}
            </motion.g>
          </InfoToolTip>
        );
      })}
    </>
  );
};

const Chart = ({ data }: { data: RevenueMetrics[] }) => {
  return (
    <ResponsiveBar
      data={data}
      keys={["revenue", "expense"]}
      indexBy="period"
      margin={{ bottom: 40, left: 50, right: 20, top: 20 }}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={[REVENUE_BAR_COLOR, EXPENSE_BAR_COLOR]}
      axisBottom={{
        renderTick: (tick) => {
          const month = tick.value.split(" ")[0];
          const year = tick.value.split(" ")[2];

          return (
            <>
              <motion.g transform={`translate(${tick.x},${tick.y})`}>
                <motion.text
                  dy={16}
                  textAnchor="middle"
                  fontSize={10}
                  fill={colors.neutral[30]}
                >
                  {month}
                </motion.text>
                <motion.text
                  dy={28}
                  textAnchor="middle"
                  fontSize={10}
                  fill={colors.neutral[30]}
                >
                  {year}
                </motion.text>
              </motion.g>

              <motion.line
                opacity="1"
                x1="0"
                x2="0"
                y1={-tick.x}
                y2="0"
                stroke={colors.neutral[0]}
                strokeWidth="1"
              ></motion.line>
            </>
          );
        },
      }}
      axisLeft={{
        renderTick: (tick) => {
          return (
            <>
              <motion.g transform={`translate(${-30},${tick.y + 3})`}>
                <motion.text
                  textAnchor="middle"
                  fontSize={10}
                  fill={colors.neutral[30]}
                >
                  {formatAmount(tick.value)}
                </motion.text>
              </motion.g>
              <motion.g>
                <motion.line
                  opacity="1"
                  x1="0"
                  x2="100%"
                  y1={tick.y}
                  y2={tick.y}
                  stroke={colors.neutral[0]}
                  strokeWidth="1"
                ></motion.line>
              </motion.g>
            </>
          );
        },
      }}
      enableLabel={false}
      layers={["axes", Bar, "totals", "markers", "legends", "annotations"]}
    />
  );
};

export const HomePageRevenueMetricsCard = () => {
  const { search } = useLocation();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [dateRange, setDateRange] =
    React.useState<DateRangeValue>("last6months");
  const { endDate, startDate } = getDateRange(dateRange);
  const {
    data = [],
    isLoading,
    isFetching,
  } = useGetRevenueMetricsQuery(
    {
      groupId,
      entityId,
      start_date: dayjs(startDate).format(YYYY_MM_DD),
      end_date: dayjs(endDate).format(YYYY_MM_DD),
    },
    {
      skip: !groupId || !entityId,
    }
  );

  const { data: accounts } = useGetAllAccountQuery(
    {
      groupId: groupId,
      entityId: entityId,
      account_type: "ALL",
    },
    {
      skip: !groupId || !entityId,
    }
  );

  const value = monthPeriod.find((item) => item.value === dateRange);

  const handleRefetch = async (dateRange: string) => {
    try {
      setDateRange(dateRange as DateRangeValue);
    } catch (error) {}
  };

  return (
    <div className="t-border t-border-solid t-border-neutral-10 t-overflow-hidden t-transform t-transition t-duration-300 t-ease-in-out t-rounded-lg t-w-full t-h-[382px] t-relative">
      <div className="t-absolute t-top-0 t-left-0 t-h-full t-w-full t-object-cover t-z-[-1]">
        <img
          src={RevenueMetricesBackground}
          alt="RevenueMetricesBackground"
          className="t-w-full"
        />
      </div>
      <div className="t-m-4 t-mb-0 t-pb-4 t-text-subtitle t-border t-border-solid t-border-neutral-0 t-border-t-0 t-border-l-0 t-border-r-0 t-flex t-gap-2 t-items-center t-justify-between">
        Revenue Metrics
        <div className="t-flex t-gap-2">
          <Modal.Root>
            <Modal.Trigger asChild>
              <Button
                customType="ghost_icon"
                size="small"
                disabled={accounts?.length === 0}
              >
                <span
                  className={classNames({
                    "t-text-text-30": accounts?.length !== 0,
                    "t-text-neutral-20": accounts?.length === 0,
                  })}
                >
                  <Expand />
                </span>
              </Button>
            </Modal.Trigger>
            <Modal.Content size="xxxl">
              <Modal.Header>
                <Modal.Title>Revenue Metrics</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                {isLoading ? (
                  <div className="t-h-80 t-w-full">
                    <Loader />
                  </div>
                ) : (
                  <div>
                    <div className="t-flex t-gap-7 t-text-body-sm">
                      <div className="t-flex t-gap-2 t-text-center">
                        <div className="t-h-[18px] t-w-[18px] t-bg-purple-30 t-rounded-sm"></div>
                        Revenue
                      </div>
                      <div className="t-flex t-gap-2 t-text-center">
                        <div className="t-h-[18px] t-w-[18px] t-bg-neutral-10 t-rounded-sm"></div>
                        Expense
                      </div>
                      <div className="t-flex t-gap-2 t-text-center">
                        <ProfitLossGraph />
                        Profit/Loss
                      </div>

                      <div className="t-ml-auto">
                        <Combobox
                          onChange={(value) =>
                            handleRefetch(
                              (value as SingleValue<OptionData>)?.value!
                            )
                          }
                          size="small"
                          isDisabled={isFetching}
                          autoFocus
                          defaultValue={value}
                          name="dateRange"
                          isClearable={false}
                          options={monthPeriod}
                          menuPortalTarget={document.body}
                          isSearchable={false}
                        />
                      </div>
                    </div>
                    <div className="t-h-[50vh] t-mt-7">
                      <Chart data={data} />
                    </div>
                  </div>
                )}
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Modal.RawClose asChild>
                  <Button type="button">Close</Button>
                </Modal.RawClose>

                <ConditionalLink to={`/books/revenue-metrics${search}`}>
                  <Button customType="primary">View more</Button>
                </ConditionalLink>
              </Modal.FooterButtonGroup>
            </Modal.Content>
          </Modal.Root>

          <PermissionBasedUI
            align="end"
            feature={REVENUE_METRICS}
            blockedUI={
              <BlockedButton size="small">
                View more <ArrowRight color="currentColor" />
              </BlockedButton>
            }
          >
            <ConditionalLink to={`/books/revenue-metrics${search}`}>
              <Button size="small">
                View more <ArrowRight color="currentColor" />
              </Button>
            </ConditionalLink>
          </PermissionBasedUI>
        </div>
      </div>
      <div className="t-p-5 t-w-full t-relative">
        {accounts && accounts?.length > 0 && (
          <>
            {isFetching ? (
              <div className="t-h-[280px] t-flex t-justify-center t-items-center">
                <Loader />
              </div>
            ) : (
              <div className="t-relative">
                {accounts && accounts.length > 0 && (
                  <>
                    <div className="t-flex t-gap-7 t-text-body-xs">
                      <div className="t-flex t-gap-1 t-text-center">
                        <div className="t-h-3 t-w-3 t-bg-purple-20 t-rounded-sm"></div>
                        Revenue
                      </div>
                      <div className="t-flex t-gap-1 t-text-center">
                        <div className="t-h-3 t-w-3 t-bg-neutral-10 t-rounded-sm"></div>
                        Expense
                      </div>
                      <div className="t-flex t-gap-1 t-text-center">
                        <div className="t-h-3 t-w-3 t-flex t-items-center t-rounded-sm t-border t-border-solid t-border-neutral-20">
                          <div className="t-w-1 t-h-1 t-bg-purple-30 t-rotate-90"></div>
                          <div className="t-bg-purple-30 t-rounded-full t-h-1 t-w-1"></div>
                        </div>
                        Profit/Loss
                      </div>
                    </div>
                    <div className="t-h-80 t-w-full">
                      <Chart data={data} />
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        )}

        {accounts?.length === 0 && (
          <div className="t-absolute t-inset-0 t-bg-white t-bg-opacity-65">
            <EmptyMetrics
              illustration={<EmptyCashBalanceMetrics />}
              title="Revenue metrics unavailable"
              subtitle="Add/categorise transactions to see revenue metrics"
            />
          </div>
        )}
      </div>
    </div>
  );
};
