import { INKLE_BOOKS, INKLE_TAX } from "constants/home";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useEffect, useMemo, useState } from "react";
import NoPendingItems from "static/images/NoPendingItems.svg";
import {
  TaxActionItemType,
  useGetActionItemsQuery,
} from "store/apis/actionItems";
import { ActionItemRow } from "./ActionItemRow";
import { TabCapsule } from "./TabCapsule";
import DashboardContainer from "components/dashboard/DashboardContainer";
import Loader from "components/design/loader";
import { useDispatch } from "react-redux";
import { setActiveChannelGroupId } from "store/slices/reviewAndBalancePayment";
import { ActionItems } from "pages/Books/BooksHome/BooksHome";
import { TodoWidgetBG } from "components/TPPayments/Illustration/TodoWidgetBG";
import { UnreadChatRow } from "./UnreadChatRow";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "components/DesignSystem/ToggleGroup/ToggleGroup";
import { CartActionItem } from "./CartActionItem";
import { PendingInvoicesRow } from "./PendingInvoicesRow";
import { FilingList } from "./FilingList";
import { useAuth } from "hooks/useAuth";

export const HomeActionItems = ({
  defaultTab,
}: {
  defaultTab?: typeof INKLE_TAX | typeof INKLE_BOOKS;
}) => {
  const { isCustomer } = useRoleBasedView();
  const [selectedTab, setSelectedTab] = useState<
    typeof INKLE_TAX | typeof INKLE_BOOKS
  >(defaultTab || INKLE_TAX);
  const { uuid: groupId } = useCurrentGroupContext();
  const dispatch = useDispatch();
  const isFromBooks = defaultTab === INKLE_BOOKS;
  const userProfile = useAuth();

  const {
    data = { tax_action_items: [] },
    isSuccess,
    isLoading,
  } = useGetActionItemsQuery(
    {
      groupId: groupId,
      type: selectedTab === INKLE_TAX ? "TAX" : "BOOKS",
    },
    {
      skip: !groupId || selectedTab === INKLE_BOOKS,
      refetchOnMountOrArgChange: true,
    }
  );

  const taxActionItems = useMemo(() => {
    if (!("tax_action_items" in data)) return [];
    const taxItems = (data as { tax_action_items: TaxActionItemType[] })
      .tax_action_items;
    return [...taxItems].sort((a, b) => {
      if (a.order == null && b.order == null) return 0;
      if (a.order == null) return 1;
      if (b.order == null) return -1;
      return a.order - b.order;
    });
  }, [data]);

  useEffect(() => {
    if (groupId) dispatch(setActiveChannelGroupId(groupId));
  }, [groupId]);

  if (defaultTab === INKLE_TAX && selectedTab === INKLE_TAX && !isSuccess)
    return null;

  return (
    <DashboardContainer className="t-px-6 t-rounded-lg t-space-y-3 t-bg-cover t-bg-no-repeat t-border t-border-solid t-border-neutral-10 t-h-[382px] t-pb-3 t-relative">
      <div className="t-absolute t-top-0 t-left-0 t-h-full t-w-full t-object-cover t-z-[-1]">
        <TodoWidgetBG />
      </div>
      <DashboardContainer.Header className="t-text-subtitle t-space-y-4 !t-mt-0">
        <div className="t-text-subtitle t-text-text-100 t-border t-border-solid t-border-neutral-0 t-border-t-0 t-border-l-0 t-border-r-0 t-py-[22px]">
          To-Do List
        </div>
        {isCustomer && (
          <>
            <ToggleGroup
              value={selectedTab}
              onValueChange={(value: string) =>
                setSelectedTab(value as typeof INKLE_TAX | typeof INKLE_BOOKS)
              }
            >
              <ToggleGroupItem value={INKLE_TAX}>
                <div className="t-w-[72px]">Tax</div>
              </ToggleGroupItem>
              <ToggleGroupItem value={INKLE_BOOKS} className="t-min-w-[124px]">
                <div className="t-w-[72px]">Books</div>
              </ToggleGroupItem>
            </ToggleGroup>
          </>
        )}
      </DashboardContainer.Header>

      <DashboardContainer.Content className="t-w-full t-h-[290px] t-pb-6">
        {selectedTab === INKLE_BOOKS && (
          <div className="t-min-w-full t-h-full">
            <ActionItems isGroupBased={!isFromBooks} />
          </div>
        )}
        {selectedTab === INKLE_TAX && (
          <div>
            {isFromBooks && isLoading ? (
              <div>
                <Loader />
              </div>
            ) : (data as { tax_action_items: TaxActionItemType[] })
                ?.tax_action_items?.length === 0 ? (
              <div className="t-flex t-flex-col t-items-center t-justify-center t-h-64 t-w-full t-gap-8">
                <img src={NoPendingItems} alt="NoPendingItems" />
                <div className="t-flex t-flex-col t-items-center t-justify-center t-text-subtitle t-text-text-30">
                  <div>Awesome!</div>
                  <div>You have no pending items</div>
                </div>
              </div>
            ) : (
              <>
                {taxActionItems.map((item, i) => {
                  if (
                    item.custom_type === "FILING_IN_ADD_DETAILS_CUSTOM_TYPE"
                  ) {
                    return <FilingList key={i} item={item} />;
                  }
                  if (item.custom_type === "CART") {
                    return <CartActionItem key={i} item={item} />;
                  }
                  if (item.custom_type == "INVOICES_DUE") {
                    return <PendingInvoicesRow key={i} item={item} />;
                  }
                  return <ActionItemRow item={item} key={i} />;
                })}
                {userProfile.email === "demo1@inkle.io" && <UnreadChatRow />}{" "}
                {/* //TODO REMOVE THIS CHECK AFTER TESTING  */}
              </>
            )}
          </div>
        )}
      </DashboardContainer.Content>
      <div
        className="t-absolute t-w-full t-bottom-0 t-left-0 t-h-16 t-rounded-lg"
        style={{
          background:
            "linear-gradient(0deg, #FFF 24%, rgba(255, 255, 255, 0.22) 100%)",
        }}
      ></div>
    </DashboardContainer>
  );
};
