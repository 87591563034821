import { useRef, useEffect, useState } from "react";
import ConditionalToolTip from "components/design/conditionalToolTip";

export const ClampedTextWithTooltip = ({
  text,
  lineClampElementClass,
  toolTipClass = "",
}: {
  text: string;
  lineClampElementClass: string;
  toolTipClass?: string;
}) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const checkTruncation = () => {
      if (textRef.current) {
        setIsTruncated(
          textRef.current.scrollHeight > textRef.current.clientHeight
        );
      }
    };

    checkTruncation();

    window.addEventListener("resize", checkTruncation);

    return () => {
      window.removeEventListener("resize", checkTruncation);
    };
  }, []);

  return (
    <div>
      <ConditionalToolTip
        condition={isTruncated && text}
        propClass={toolTipClass}
      >
        <div
          ref={textRef}
          className={`${lineClampElementClass} t-overflow-hidden t-max-w-full`}
        >
          {text}
        </div>
      </ConditionalToolTip>
    </div>
  );
};
