import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Button } from "components/DesignSystem/Button/Button";
import Card from "components/DesignSystem/Card/Card";
import Modal from "components/DesignSystem/Modal/Modal";
import Pencil from "components/icons/pencil";
import { PriceInput } from "components/PriceInput/PriceInput";
import { Form, Formik } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { ReactNode } from "react";
import {
  Summary,
  useUpdateFinancialClosingSummaryMutation,
} from "store/apis/financialClosing";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";

type SummaryCardProps = {
  editable?: boolean;
  summary: Summary;
  financialClosingId: string;
};

const SummaryItem = ({ label, value }: { label: string; value: ReactNode }) => {
  return (
    <div className="t-flex t-justify-between t-items-center">
      <div className="t-text-body t-text-text-60">{label}</div>
      <div className="t-text-subtext-sm t-text-text-100">{value}</div>
    </div>
  );
};

const EditSummaryModal = ({
  isOpen,
  close,
  financialClosingId,
  summary,
}: ModalProps & Pick<SummaryCardProps, "financialClosingId" | "summary">) => {
  const [updateSummary] = useUpdateFinancialClosingSummaryMutation();
  const entityId = useCurrentEntityId();
  const { alertToast, successToast } = useToast();

  const onSubmit = async (value: Record<string, number>) => {
    try {
      await updateSummary({
        entityId,
        financialClosingId,
        summary: value,
      }).unwrap();
      close();
      successToast({ message: "Summary updated!" });
    } catch (error) {
      alertToast(
        { message: (error as BackendError).data?.error?.message },

        error as Error
      );
    }
  };

  const initialValues = Object.entries(summary).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: value.value }),
    {} as Record<string, number>
  );

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ submitForm, isSubmitting }) => (
          <Modal.Content>
            <Modal.Header>
              <Modal.Title>Edit Summary</Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body>
              <Form className="t-flex t-flex-col t-gap-4">
                {Object.entries(summary).map(([key, value]) => {
                  return (
                    <SummaryItem
                      key={key}
                      label={value.title}
                      value={<PriceInput name={key} required type="number" />}
                    />
                  );
                })}
              </Form>
            </Modal.Body>
            <Modal.FooterButtonGroup>
              <Modal.RawClose asChild>
                <Button disabled={isSubmitting}>Close</Button>
              </Modal.RawClose>
              <Button
                customType="primary"
                onClick={submitForm}
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Modal.FooterButtonGroup>
          </Modal.Content>
        )}
      </Formik>
    </Modal.Root>
  );
};

export const SummaryCard = ({
  editable = false,
  summary,
  financialClosingId,
}: SummaryCardProps) => {
  const editModal = useModal();

  return (
    <>
      <Card.Root className="t-bg-surface-lighter-grey">
        <Card.Header>
          <Card.Title className="t-flex t-justify-between t-w-full t-items-center">
            Summary
            {editable && (
              <Button
                customType="ghost_icon"
                size="small"
                onClick={editModal.open}
              >
                <span className="t-text-text-30">
                  <Pencil color="currentColor" size="14" />
                </span>
              </Button>
            )}
          </Card.Title>
        </Card.Header>
        <Card.Body className="t-flex t-flex-col t-gap-4">
          {Object.entries(summary).map(([key, value]) => (
            <SummaryItem
              key={key}
              label={value.title}
              value={<AmountSuperScript amount={value.value} />}
            />
          ))}
        </Card.Body>
      </Card.Root>
      <EditSummaryModal
        financialClosingId={financialClosingId}
        summary={summary}
        isOpen={editModal.isOpen}
        close={editModal.close}
      />
    </>
  );
};
