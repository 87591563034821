import { Input } from "components/design/input";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { Search } from "components/DesignSystem/Search/Search";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Form, Formik } from "formik";
import { newMember } from "formValidations/newMemberSchema";
import { useToast } from "hooks/useToast";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useAddTeamMemberMutation,
  useGetGroupTaskListQuery,
  useGetTaskAccessListFCAQuery,
  useGroupJoinActionMutation,
  useUpdateTeamMemberMutation,
} from "store/apis/teamSetting";
import { debounce } from "utils/debouncing";
import { TaskName } from "./TaskAccessModal";
import GreenInfo from "static/images/GreenInfo.svg";
import {
  ACCEPT,
  FOREIGN_ENTITY_ACCOUNTANT,
  ADMIN,
  MEMBER,
} from "constants/groupRequest";

const roleOptionsSubtitle: { [key: string]: string } = {
  [ADMIN]: "Full access to the platform, can manage and remove users.",
  [FOREIGN_ENTITY_ACCOUNTANT]:
    "For other subsidiary/parent team users. Limited access & Different dashboard.",
  [MEMBER]: "Full access to the platform, can’t manage and remove users.",
};

export const AddOrEditTeamMemberModal = ({
  show,
  closeModal,
  options,
  groupName,
  groupId,
  availableSeats,
  user,
  isRequestAcceptFlow,
  openInviteUserSeatManageModal,
  setRequestedMemberRole,
  openSeatManageModal,
  manageUserFlow,
  requestedMemberRole,
  userInviteFlow,
}: {
  show: boolean;
  closeModal: (
    user?:
      | {
          uuid: string;
          email: string;
          profile: string;
          role_name: string;
          allowed_access: boolean;
          secondary_mobile: string;
          current_timezone: string;
          linkedIn_url: string;
          profile_id: string;
        }
      | undefined
  ) => void;
  options: { name: string; uuid: string }[];
  groupName: string;
  groupId: string;
  availableSeats: number;
  user?: {
    uuid: string;
    email: string;
    profile: string;
    role_name: string;
    group_role_id: string;
    allowed_access: boolean;
    secondary_mobile: string;
    current_timezone: string;
    linkedIn_url: string;
    profile_id: string;
  };
  isRequestAcceptFlow?: boolean;
  openInviteUserSeatManageModal?: () => void;
  setRequestedMemberRole?: (memberRole: string) => void;
  openSeatManageModal?: () => void;
  manageUserFlow?: boolean;
  requestedMemberRole?: string;
  userInviteFlow?: boolean;
}) => {
  const { alertToast, successToast } = useToast();
  const [addTeamMember, { isLoading: addTeamMemberLoading }] =
    useAddTeamMemberMutation();
  const [updateTeamMember, { isLoading: TeamMemberUpdating }] =
    useUpdateTeamMemberMutation();
  const [searchTerm, setSearchTerm] = useState<null | string>(null);
  const [selectedTasks, setSelectedTasks] = useState(new Set<string>());

  const isAccountant =
    user?.group_role_id === process.env.PUBLIC_ACCOUNTANT_ROLE_ID;
  const { data: providerTaskList, isLoading: providerTaskListLoading } =
    useGetTaskAccessListFCAQuery(
      {
        searchTerm,
        groupId,
        providerId: user?.uuid!,
      },
      {
        skip: !groupId || !user?.uuid || !isAccountant,
        refetchOnMountOrArgChange: true,
      }
    );

  const { task_list = [] } = providerTaskList || {};

  const { data: allTaskList = [], isLoading: allTaskListLoading } =
    useGetGroupTaskListQuery(
      {
        searchTerm,
        groupId,
      },
      { skip: !groupId, refetchOnMountOrArgChange: true }
    );

  const isLoading = providerTaskListLoading || allTaskListLoading;

  const taskList = (isAccountant ? task_list : allTaskList) || allTaskList;
  const tasksUserHasAccess = taskList
    .filter((task) => task.has_task_access)
    .map((task) => task.uuid);

  useEffect(() => {
    setSelectedTasks(new Set(tasksUserHasAccess));
  }, [JSON.stringify(tasksUserHasAccess)]);

  const [handleGroupJoinRequest, { isLoading: groupRequestActionLoading }] =
    useGroupJoinActionMutation();
  const noTaskFound = taskList.length === 0 && !isLoading;
  const isFCA = user?.role_name === FOREIGN_ENTITY_ACCOUNTANT;
  const isAccountantUserManageFlow = manageUserFlow && isFCA;

  const handleSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchTerm(value || null);
  });

  const onTaskSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    if (name === "allTask") {
      if (checked) {
        return setSelectedTasks((s) => {
          const newList = taskList.reduce(
            (acc, c) => acc.add(c.uuid),
            new Set<string>()
          );
          return newList;
        });
      }
      setSelectedTasks(new Set());
      return;
    }

    if (checked) {
      return setSelectedTasks((s) => {
        const newList = new Set(Array.from(s));
        newList.add(name);
        return newList;
      });
    }

    setSelectedTasks((s) => {
      const newList = new Set(Array.from(s));
      newList.delete(name);
      return newList;
    });
  };

  const handleManageUser = async ({
    groupRole,
    accessType,
  }: {
    groupRole: string;
    accessType?: string;
  }) => {
    try {
      if (user?.email) {
        let body: {
          email: string;
          group_role_id?: string;
          all_access_allowed?: boolean;
          task_id_list?: string[];
          secondary_mobile?: string;
          current_timezone?: string;
          linkedin_url?: string;
          profile_id: string;
        } = {
          email: user.email,
          group_role_id: groupRole,
          secondary_mobile: user.secondary_mobile,
          current_timezone: user.current_timezone,
          linkedin_url: user.linkedIn_url,
          profile_id: user.profile_id,
        };
        if (isFCA) {
          if (accessType === "allTaskIncludesFuture") {
            body = { ...body, all_access_allowed: true };
          } else {
            body = {
              ...body,
              task_id_list: Array.from(selectedTasks),
            };
          }
        }

        await updateTeamMember({ id: groupId, body }).unwrap();
        successToast({
          message: "User updated successfully",
          title: "Group user",
        });
        closeModal(user);
      }
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
      closeModal();
    }
  };

  const handleClick = async (values: {
    email: string;
    groupRole: string;
    accessType: string;
  }) => {
    try {
      let body: {
        email: string;
        group_role_id?: string;
        all_access_allowed?: boolean;
        task_id_list?: string[];
      } = {
        email: values.email,
        ...(values.groupRole !== process.env.PUBLIC_ACCOUNTANT_ROLE_ID
          ? { group_role_id: values.groupRole }
          : {}),
      };
      if (values.accessType === "allTaskIncludesFuture") {
        body = { ...body, all_access_allowed: true };
      } else {
        body = { ...body, task_id_list: Array.from(selectedTasks) };
      }
      await addTeamMember({ id: groupId, body }).unwrap();
      successToast({
        message: "User invited successfully",
        title: "Group user",
      });

      closeModal();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
      closeModal();
    }
  };

  const handleAcceptUser = async (groupRole: string) => {
    try {
      if (user?.uuid) {
        await handleGroupJoinRequest({
          type: ACCEPT,
          requestId: user.uuid,
          payload: { group_role_id: groupRole },
          groupId: groupId,
        }).unwrap();

        successToast({
          message: `You accepted ${user.profile} request`,
          title: "Group user",
        });
        closeModal();
      }
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  let roleOptions = options
    .filter((option) =>
      option.uuid === process.env.PUBLIC_ACCOUNTANT_ROLE_ID
        ? userInviteFlow
        : true
    )
    .map((option) => {
      const optionName = option.name;
      const isAccountantOption = option.name === FOREIGN_ENTITY_ACCOUNTANT;
      return {
        label: (
          <div>
            <div className="t-text-subtext t-text-text-100">
              {option.name}
              {isAccountantOption && (
                <span className="t-ml-2 t-px-2 t-py-1 t-rounded t-bg-purple-0 t-text-purple t-text-body-sm">
                  Free seat
                </span>
              )}
            </div>
            <div className="t-text-body-sm t-text-text-30 t-mt-1">
              {roleOptionsSubtitle[optionName]}
            </div>
          </div>
        ),
        value: option.uuid,
      };
    });

  const handleFormSubmit = (values: {
    email: string;
    groupRole: string;
    accessType: string;
  }) => {
    if (
      values.groupRole !== process.env.PUBLIC_ACCOUNTANT_ROLE_ID &&
      availableSeats <= 0 &&
      (user?.role_name === "ROLE_NOT_DECIDED" || !user?.role_name)
    ) {
      setRequestedMemberRole?.(values.groupRole);
      openInviteUserSeatManageModal?.();
      closeModal(user);
      return;
    }

    if (isRequestAcceptFlow) {
      handleAcceptUser(values.groupRole);
      return;
    }

    if (user?.email) {
      handleManageUser({
        groupRole: values.groupRole,
        accessType: values.accessType,
      });
      return;
    }

    handleClick(values);
  };

  const handleOpenSeatManage = () => {
    if (availableSeats <= 0) {
      openInviteUserSeatManageModal?.();
      closeModal();
      return;
    }
    openSeatManageModal?.();
    closeModal();
  };

  const submitButtonText = isRequestAcceptFlow
    ? "Accept"
    : manageUserFlow
    ? "Update"
    : "Invite";

  const modalTitle = isRequestAcceptFlow
    ? "View Request"
    : manageUserFlow
    ? "Update User"
    : "Invite User";

  const accessType = user?.allowed_access
    ? "allTaskIncludesFuture"
    : "selectedTask";

  return (
    <Modal.Root open={show} onOpenChange={() => closeModal()}>
      <Formik
        initialValues={{
          email: user?.email || "",
          groupRole: requestedMemberRole || user?.group_role_id || "",
          accessType: accessType,
        }}
        onSubmit={handleFormSubmit}
        validationSchema={newMember}
        validateOnBlur
        validateOnChange
      >
        {({ values: { accessType, groupRole }, isValid, submitForm }) => (
          <Form>
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>{modalTitle}</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                <div className="t-m-0 t-flex t-flex-col t-gap-4">
                  <TextInput
                    placeholder="Enter email"
                    label="Email"
                    name="email"
                    block
                    disabled={Boolean(user?.email)}
                  />
                  <Combobox
                    isDisabled={isAccountantUserManageFlow}
                    label={`${groupName} Role`}
                    menuPortalTarget={document.body}
                    withForm
                    name="groupRole"
                    options={roleOptions.map((option) => {
                      return {
                        label: <div>{option.label}</div>,
                        value: option.value,
                      };
                    })}
                  />
                  {groupRole === process.env.PUBLIC_ACCOUNTANT_ROLE_ID && (
                    <div
                      role="group"
                      aria-labelledby="form-selector-radio-group"
                      className="t-ml-1 t-flex t-flex-col t-gap-2"
                    >
                      <Input
                        label={
                          <div className="t-cursor-pointer t-text-subtitle-sm t-font-medium">
                            All tasks (includes future tasks as well)
                          </div>
                        }
                        type="radio"
                        value="allTaskIncludesFuture"
                        name="accessType"
                        /* @tw */
                        customInputClass="!t-h-4 !t-w-4 t-cursor-pointer"
                      />
                      <Input
                        label={
                          <div className="t-cursor-pointer t-text-subtitle-sm t-font-medium">
                            Select filings
                          </div>
                        }
                        value="selectedTask"
                        type="radio"
                        name="accessType"
                        /* @tw */
                        customInputClass="!t-h-4 !t-w-4 t-cursor-pointer"
                      />
                      {accessType === "selectedTask" && (
                        <div className="t-ml-8 t-flex t-flex-col t-gap-3">
                          <Search
                            onChange={handleSearch}
                            customSize="regular"
                            placeholder="Search for Filing"
                            block
                            autoFocus
                          />
                          {noTaskFound ? (
                            <div className="t-flex t-h-56 t-items-center t-justify-center ">
                              No filing found
                            </div>
                          ) : (
                            <div className="t-flex t-h-56 t-flex-col t-gap-4 t-overflow-auto t-p-2">
                              <Checkbox
                                onChange={onTaskSelect}
                                checked={taskList.every(({ uuid }) =>
                                  selectedTasks.has(uuid)
                                )}
                                label={
                                  <span className="t-text-subtext">
                                    Select all filings
                                  </span>
                                }
                                name="allTask"
                              />
                              {taskList.map(({ task_title, uuid }) => {
                                return (
                                  <Checkbox
                                    key={uuid}
                                    onChange={onTaskSelect}
                                    checked={selectedTasks.has(uuid)}
                                    label={<TaskName task_title={task_title} />}
                                    name={uuid}
                                  />
                                );
                              })}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  <div className="t-p-2 t-rounded t-border t-border-solid t-bg-blue-0 t-text-body-sm t-items-center t-flex t-gap-1 t-border-blue-20">
                    <img src={GreenInfo} alt="GreenInfo" />
                    <div className="t-text-text-60">
                      You have {availableSeats} paid seat left. Add more seats
                    </div>
                    <Button customType="link" onClick={handleOpenSeatManage}>
                      <div className="t-text-blue t-text-body-sm">here</div>
                    </Button>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="t-flex t-justify-end t-w-full">
                  <Button
                    type="submit"
                    customType="primary"
                    isLoading={
                      addTeamMemberLoading ||
                      groupRequestActionLoading ||
                      TeamMemberUpdating
                    }
                    disabled={
                      !isValid ||
                      addTeamMemberLoading ||
                      groupRequestActionLoading ||
                      TeamMemberUpdating
                    }
                    onClick={submitForm}
                  >
                    {submitButtonText}
                  </Button>
                </div>
              </Modal.Footer>
            </Modal.Content>
          </Form>
        )}
      </Formik>
    </Modal.Root>
  );
};
