import classNames from "classnames";
import { ConditionalLink } from "components/conditionalLink";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { Label } from "components/DesignSystem/TextInput/TextInput";
import { FilePreviewTag } from "components/FilePreviewTag/FilePreviewTag";
import { CheckOutline } from "components/icons/CheckOutline";
import { Cross } from "components/icons/Cross";
import { LinkBreak } from "components/icons/LinkBreak";
import { Redirect } from "components/icons/Redirect";
import { Trash } from "components/icons/Trash";
import { FILE_DELETE_MESSAGE } from "constants/documents";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useGetTransaction } from "hooks/useGetTransaction";
import { useToast } from "hooks/useToast";
import { useEffect, useRef } from "react";
import {
  useAddTransactionInvoiceMutation,
  useDeleteTransactionInvoiceMutation,
  useEditTransactionMutation,
  useMapInkleTransactionToInvoiceMutation,
  useUpdateTransactionMutation,
} from "store/apis/transactions";
import { InvoiceType } from "types/Models/books";
import { BackendError } from "types/utils/error";
import * as INVOICE_TRANSACTION_MAPPING_STATUS from "constants/invoiceTransactionMappingStatus";
import { FileInput, FileType } from "components/FileInput/FileInput";

const InvoiceItem = ({
  invoice,
  transactionId,
}: {
  invoice: InvoiceType;
  transactionId: string;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const { alertToast, successToast } = useToast();
  const invoiceRef = useRef<HTMLButtonElement>(null);
  const { data: transaction } = useGetTransaction({
    transactionId,
  });
  const { invoices = [] } = transaction || {};
  const lastInvoiceId = invoices[invoices.length - 1]?.uuid;

  const entityId = useCurrentEntityId();

  useEffect(() => {
    if (lastInvoiceId === invoice.uuid) {
      invoiceRef?.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [lastInvoiceId, invoice.uuid]);

  const [deleteInvoice, { isLoading: isDeleting }] =
    useDeleteTransactionInvoiceMutation();

  const [editTransaction, { isLoading: isUpdating }] =
    useEditTransactionMutation();

  const [markInvoicePaid, { isLoading: isMarking }] =
    useMapInkleTransactionToInvoiceMutation();

  const confirmInvoice = async () => {
    try {
      await editTransaction({
        groupId: groupId,
        entityId: entityId,
        invoice_mapping_id: invoice.uuid,
        invoice_status: INVOICE_TRANSACTION_MAPPING_STATUS.AUTO_MATCH_CONFIRM,
        transactionId: transactionId,
      }).unwrap();
      await markInvoicePaid({
        entityId,
        entityInvoiceIds: invoice.file_data?.invoice_uuid!,
        transactionId,
      }).unwrap();
      successToast({ message: "Invoice attached to transaction" });
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  const onDelete = async () => {
    const payload = { invoice_id: invoice.file_data?.uuid };
    await deleteInvoice({
      groupId,
      previewTxnId: transactionId,
      payload,
    }).unwrap();
  };

  const unlink = async () => {
    try {
      await onDelete();
      successToast({ message: "Invoice unlinked" });
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  const cancelMatchedInvoice = async () => {
    try {
      await onDelete();
      successToast({ message: "Removed invoice suggestion" });
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  const deleteManualInvoice = async () => {
    try {
      await onDelete();
      successToast({ message: FILE_DELETE_MESSAGE });
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  const { link } = useConstructInternalLink();
  const invoiceLink = link(
    `/books/invoices-and-customers/${invoice.file_data?.invoice_uuid}`
  );

  return (
    <div className="t-flex t-gap-2 t-w-full">
      <div className="t-text-body-sm t-flex-1">
        {invoice.file_data && (
          <FilePreviewTag file={invoice.file_data} size="small" block />
        )}
      </div>
      {invoice.file_data?.invoice_uuid ? (
        <>
          <div>
            {invoice.file_data.invoice_status ===
              INVOICE_TRANSACTION_MAPPING_STATUS.AUTO_MATCH_CONFIRM && (
              <ConditionalLink to={invoiceLink}>
                <ToolTip
                  disableHoverableContent
                  text="Auto attached invoice, go to linked invoice"
                >
                  <Button
                    customType="icon"
                    size="small"
                    isLoading={isUpdating || isMarking}
                    disabled={isUpdating || isMarking}
                  >
                    <span className="t-text-purple">
                      <Redirect color="currentColor" size={16} />
                    </span>
                  </Button>
                </ToolTip>
              </ConditionalLink>
            )}

            {invoice.file_data.invoice_status ===
              INVOICE_TRANSACTION_MAPPING_STATUS.AUTO_MATCHED && (
              <ToolTip disableHoverableContent text="Confirm attached invoice">
                <Button
                  customType="icon"
                  size="small"
                  onClick={confirmInvoice}
                  disabled={isUpdating || isMarking}
                  isLoading={isUpdating || isMarking}
                >
                  <CheckOutline />
                </Button>
              </ToolTip>
            )}
          </div>

          {invoice.file_data.invoice_status ===
            INVOICE_TRANSACTION_MAPPING_STATUS.AUTO_MATCHED && (
            <div>
              <Button
                customType="icon"
                size="small"
                onClick={cancelMatchedInvoice}
                disabled={isDeleting || isUpdating || isMarking}
                isLoading={isDeleting}
              >
                <Cross color="currentColor" />
              </Button>
            </div>
          )}

          {invoice.file_data.invoice_status ===
            INVOICE_TRANSACTION_MAPPING_STATUS.AUTO_MATCH_CONFIRM && (
            <div>
              <ToolTip disableHoverableContent text="Unlink attached invocie">
                <Button
                  customType="icon"
                  size="small"
                  onClick={unlink}
                  disabled={isDeleting || isUpdating || isMarking}
                  isLoading={isDeleting}
                >
                  <span className="t-text-text-100">
                    <LinkBreak />
                  </span>
                </Button>
              </ToolTip>
            </div>
          )}
        </>
      ) : (
        <>
          <div>
            <ToolTip
              disableHoverableContent
              text="Manually added. No linked Invoice"
            >
              <span>
                <Button customType="icon" size="small" disabled>
                  <Redirect color="currentColor" size={16} />
                </Button>
              </span>
            </ToolTip>
          </div>
          <div>
            <Button
              customType="icon"
              size="small"
              onClick={deleteManualInvoice}
              isLoading={isDeleting}
              disabled={isDeleting}
            >
              <Trash />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export const Invoices = ({ transactionId }: { transactionId: string }) => {
  const { data: transactionDetail } = useGetTransaction({
    transactionId,
  });
  const { invoices } = transactionDetail || {};
  const { uuid: groupId } = useCurrentGroupContext();
  const { alertToast, successToast } = useToast();
  const [addInvoice, { isLoading }] = useAddTransactionInvoiceMutation();

  const onDrop = async (files: FileType[]) => {
    try {
      await addInvoice({
        groupId,
        previewTxnId: transactionId,
        files,
      }).unwrap();
      successToast({ message: "Invoice added" });
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  const noInvoice = invoices?.length === 0;

  return (
    <div className="t-flex t-flex-col t-gap-2 t-max-h-52 t-overflow-y-auto t-w-full">
      {!noInvoice && (
        <div className="t-flex t-flex-col">
          <Label>Invoice</Label>
          <div className="t-flex t-flex-col t-gap-2">
            {invoices?.map((invoice) => (
              <InvoiceItem
                invoice={invoice}
                transactionId={transactionId}
                key={invoice.uuid}
              />
            ))}
          </div>
        </div>
      )}
      <div className={classNames("t-sticky t-bottom-0")}>
        <FileInput
          isUploading={isLoading}
          disabled={isLoading}
          onDrop={onDrop}
        />
      </div>
    </div>
  );
};
