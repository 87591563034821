import { ConditionalLink } from "components/conditionalLink";
import { Button } from "components/DesignSystem/Button/Button";
import { LargeCard } from "components/home/Home";
import { GetBrexCard } from "components/Illustrations/GetBrexCard";
import { RedeemBrexPoints } from "components/Illustrations/RedeemBrexPoints";
import { BrexHomeCardBG } from "components/TPPayments/Illustration/BrexHomeCardBG";
import { useBankConnect } from "hooks/useBankConnect";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useGetAllConnections } from "hooks/useGetAllConnections";
import { useCallback, useEffect, useState } from "react";
import { useLazyGetBrexPointsQuery } from "store/apis/billing";

export const BrexHomeCard = () => {
  const [entitiesConnected, setEntitiesConnected] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetchcing] = useState(false);
  const [getBrexPoints] = useLazyGetBrexPointsQuery();
  const { entities, uuid: groupId } = useCurrentGroupContext();

  const fetchAllEntityBrexPoints = useCallback(async () => {
    const connections = await Promise.all(
      entities.map(async (entity) => {
        try {
          const data = await getBrexPoints({
            entityId: entity.uuid,
          }).unwrap();

          return {
            connection: data?.connection,
            entityId: entity.uuid,
          };
        } catch (error) {}
      })
    );

    setEntitiesConnected(
      connections
        .filter((c) => c?.connection?.connection_available)
        .map((c) => c?.entityId)
        .filter((id) => id !== undefined)
        .filter(Boolean)
    );
  }, [entities.length]);

  useEffect(() => {
    const fetchAndSetEntitiesConnected = async () => {
      setLoading(true);
      await fetchAllEntityBrexPoints();
      setLoading(false);
    };

    fetchAndSetEntitiesConnected();
  }, [fetchAllEntityBrexPoints]);

  const { onConnectPopup, isLoading: isConnecting } = useBankConnect({
    onConnectSuccess: async () => {
      setFetchcing(true);
      await fetchAllEntityBrexPoints();
      setFetchcing(false);
    },
  });

  const firstConnectedUsEntity = entities.find(
    (e) => e.country_code === "US" && entitiesConnected.includes(e.uuid)
  );

  const firstNonConnectedUsEntity = entities.find(
    (e) => e.country_code === "US" && !entitiesConnected.includes(e.uuid)
  );

  const { connections, isFetching } = useGetAllConnections({
    groupId: groupId!,
    entityId: firstNonConnectedUsEntity?.uuid!,
  });

  const connectBrexAccount = async () => {
    const brexConnection = connections?.find(
      (c) => c.connection_provider === "BREX"
    );

    if (brexConnection && firstNonConnectedUsEntity) {
      await onConnectPopup({
        connectionId: brexConnection.uuid,
        entityId: firstNonConnectedUsEntity.uuid,
      });
    }
  };

  if (loading) {
    return null;
  }

  if (firstConnectedUsEntity) {
    return (
      <LargeCard>
        <ConditionalLink
          to={`/settings/billing?entity=${firstConnectedUsEntity.uuid}`}
        >
          <div className="t-relative t-border-neutral-10 t-border t-border-solid t-p-5 t-text-text-100 t-bg-auto t-self-start t-w-full t-h-full t-rounded-lg">
            <div className="t-absolute t-top-0 t-left-0 t-h-full t-w-full t-object-cover t-z-[-1]">
              <BrexHomeCardBG />
            </div>
            <div className="t-flex t-flex-col t-justify-between t-h-full t-gap-3">
              <div className="t-absolute t-bottom-0 t-right-0">
                <RedeemBrexPoints />
              </div>

              <div className="t-flex t-gap-3 t-flex-col t-z-10">
                <div className="t-text-subtitle">
                  Earn on Brex, redeem on Inkle!
                </div>
                <p className="t-m-0 t-text-body-sm t-text-text-30 t-w-8/12">
                  You can now redeem your Brex reward points for filings, tasks,
                  and services on our platform.
                </p>
              </div>

              <div>
                <Button size="small">Redeem Now</Button>
              </div>
            </div>
          </div>
        </ConditionalLink>
      </LargeCard>
    );
  }

  if (firstNonConnectedUsEntity) {
    return (
      <LargeCard>
        <div
          className="t-relative t-border-neutral-10 t-border t-border-solid t-p-5 t-text-text-100 t-bg-auto t-self-start t-w-full t-h-full t-rounded-lg"
          data-testid="Hellothere"
        >
          <div className="t-absolute t-top-0 t-left-0 t-h-full t-w-full t-object-cover t-z-[-1]">
            <BrexHomeCardBG />
          </div>

          <div className="t-absolute t-bottom-0 t-right-0">
            <GetBrexCard />
          </div>

          <div className="t-flex t-flex-col t-justify-between t-h-full t-gap-3">
            <div className="t-flex t-gap-2 t-flex-col t-z-10">
              <div
                className="t-text-subtitle t-line-clamp-1"
                title="Get your Brex card!"
              >
                Get your Brex card!
              </div>
              <p className="t-m-0 t-text-body-sm t-text-text-30 t-w-9/12">
                Apply for a Brex card and earn benefits of upto $300.
              </p>
            </div>

            <div className="t-flex t-gap-2 t-flex-col">
              <div>
                <ConditionalLink to="/community/perks/brex">
                  <Button size="small">Apply Now</Button>
                </ConditionalLink>
              </div>
              <div className="t-flex t-gap-0.5 t-text-body-sm t-text-text-30 t-items-center t-flex-wrap">
                <p className="t-m-0">Already have a Brex account?</p>
                <Button
                  isLoading={isConnecting || isFetching || fetching}
                  disabled={isConnecting || isFetching || fetching}
                  onClick={connectBrexAccount}
                  customType="link"
                >
                  <span className="t-text-body-sm">
                    Connect to redeem Brex points
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </LargeCard>
    );
  }

  return null;
};
