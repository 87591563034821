import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import CopyIcon from "static/images/CopyPurple.svg";
import { BankTransferOrder } from "store/apis/bankTransfer";

export const BankAccountDetails = ({
  bankTransferOrder,
}: {
  bankTransferOrder: BankTransferOrder;
}) => {
  const { copyToClipboard } = useCopyToClipboard();

  let bankDetails =
    bankTransferOrder.bank_transfer_details[
      bankTransferOrder.bank_transfer_details.type
    ];

  return (
    <div className="t-border t-border-solid t-border-neutral-10 t-rounded-lg">
      <div className="t-border t-border-solid t-border-neutral-10 t-border-t-0 t-border-r-0 t-border-l-0 t-px-4 t-py-3">
        <div className="t-text-neutral-60 t-text-sm">Account Details</div>
        <div className="t-text-caption t-text-text-30">
          Use the below account details to make the transfer
        </div>
      </div>
      <div className="t-px-4 t-py-5 t-space-y-5">
        <InfoItem label="Bank">
          <div className="t-flex t-gap-1.5 t-items-center">
            <span className="t-text-text-100 t-text-body">
              {bankDetails.bank_name}
            </span>
            <img
              src={CopyIcon}
              alt="copyIcon"
              role="button"
              onClick={() => copyToClipboard(bankDetails.bank_name)}
            />
          </div>
        </InfoItem>
        {bankDetails.routing_number && (
          <InfoItem label="Routing Number">
            <div className="t-flex t-gap-1.5 t-items-center">
              <span className="t-text-text-100 t-text-body">
                {bankDetails.routing_number}
              </span>
              <img
                src={CopyIcon}
                alt="copyIcon"
                role="button"
                onClick={() => copyToClipboard(bankDetails.routing_number)}
              />
            </div>
          </InfoItem>
        )}
        <InfoItem label="A/C Number">
          <div className="t-flex t-gap-1.5 t-items-center">
            <span className="t-text-text-100 t-text-body">
              {bankDetails.account_number}
            </span>
            <img
              src={CopyIcon}
              alt="copyIcon"
              role="button"
              onClick={() => copyToClipboard(bankDetails.account_number)}
            />
          </div>
        </InfoItem>
        <InfoItem label="Payment Method">
          <span className="t-text-text-100 t-text-body">
            {bankTransferOrder.bank_transfer_details.supported_networks.join(
              "/"
            )}
          </span>
        </InfoItem>
      </div>
    </div>
  );
};
