import { ConditionalLink } from "components/conditionalLink";
import { Badge } from "components/design/badge";
import Loader from "components/design/loader";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import Table from "components/DesignSystem/Table/Table";
import CirclePlus from "components/icons/circlePlus";
import Pencil from "components/icons/pencil";
import CrmTabBar from "components/serviceTeam/crmTabs/crmTabBar";
import {
  ACCOUNTING_METHODS,
  QUICK_BOOKS,
  ZOHO_BOOKS,
} from "constants/bookkeeping";
import {
  BOOKS_SEASON_SUMMARY_STATUS,
  CRM_SUBSCRIPTION_TYPE,
} from "dictionaries";
import { usePageTitle } from "hooks/usePageTitle";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { MouseEvent, useMemo } from "react";
import { useTable } from "react-table";
import CrossRed from "static/images/CrossRed.svg";
import GreenTick from "static/images/GreenTick.svg";
import QuickBooks from "static/images/QuickBooks.svg";
import ZohoBooks from "static/images/ZohoBooks.svg";
import {
  SeasonSummary,
  useGetBookkeeperQuery,
  useGetBooksCRMentitiesQuery,
  useUpdateBooksCRMentitiesMutation,
} from "store/apis/booksCrm";
import { useDebounce } from "utils/debounce";
import { openLink } from "utils/openLink";

type AccountingMethodType = keyof typeof ACCOUNTING_METHODS;

const stopPropagation = (e: MouseEvent<any>) => {
  e.stopPropagation();
};

const AccountingMethod = ({
  currentAccountingMethod,
  entityId,
}: {
  currentAccountingMethod: AccountingMethodType | null;
  entityId: string;
}) => {
  const { alertToast, successToast } = useToast();
  const [updateMethod, { isLoading }] = useUpdateBooksCRMentitiesMutation();

  const onUpdated = async (accountingMethod: AccountingMethodType) => {
    try {
      await updateMethod({ entityId, accountingMethod }).unwrap();

      successToast({ message: "Accounting Method updated" });
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  return (
    <Dropdown.Root modal={false}>
      <Dropdown.Trigger asChild className="t-group">
        <div>
          {Boolean(currentAccountingMethod) ? (
            <Button
              customType="transparent"
              onClick={stopPropagation}
              isLoading={isLoading}
            >
              <span className="t-text-body">{currentAccountingMethod}</span>
              &nbsp;
              <Pencil />
            </Button>
          ) : (
            <Button
              customType="transparent"
              onClick={stopPropagation}
              isLoading={isLoading}
            >
              <CirclePlus />
            </Button>
          )}
        </div>
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content sideOffset={4} align="start" className="t-z-dropdown">
          <Dropdown.Item
            onClick={stopPropagation}
            onSelect={() => onUpdated(ACCOUNTING_METHODS.CASH)}
          >
            CASH
          </Dropdown.Item>
          <Dropdown.Item
            onClick={stopPropagation}
            onSelect={() => onUpdated(ACCOUNTING_METHODS.ACCRUAL)}
          >
            ACCRUAL
          </Dropdown.Item>
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};
const Bookkeeper = ({
  assignedBookkeeper,
  entityId,
}: {
  assignedBookkeeper: {
    name: string;
    profile_id: string;
  } | null;
  entityId: string;
}) => {
  const { alertToast, successToast } = useToast();
  const { isServiceSuperAdmin } = useRoleBasedView();
  const [updateBookkeeper, { isLoading }] = useUpdateBooksCRMentitiesMutation();
  const { data: bookkeepers = [], isLoading: isBookkeeperLoading } =
    useGetBookkeeperQuery(undefined, { skip: !isServiceSuperAdmin });

  const onBookkeeperUpdated = async (assignedBookkeeperId: string) => {
    try {
      await updateBookkeeper({ entityId, assignedBookkeeperId }).unwrap();
      successToast({ message: "Bookkeeper updated!" });
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const removeBookkeeper = async (e: MouseEvent<HTMLButtonElement>) => {
    stopPropagation(e);
    try {
      await updateBookkeeper({
        entityId,
        removeAssignedBookkeeper: true,
      }).unwrap();
      successToast({ message: "Bookkeeper removed!" });
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const { name: assignedBookkeeperName, profile_id } = assignedBookkeeper || {};

  if (isBookkeeperLoading) {
    return <Loader />;
  }

  if (isServiceSuperAdmin) {
    return (
      <Dropdown.Root modal={false}>
        <Dropdown.Trigger asChild className="t-group">
          <div>
            {Boolean(profile_id) ? (
              <Button customType="transparent" onClick={stopPropagation}>
                <span className="t-text-lg t-rounded-lg t-bg-purple-20 t-px-2 t-py-1 t-text-purple">
                  {assignedBookkeeperName}
                </span>
                &nbsp;
                <Pencil />
              </Button>
            ) : (
              <Button customType="transparent" onClick={stopPropagation}>
                <CirclePlus />
              </Button>
            )}
          </div>
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <Dropdown.Content
            sideOffset={4}
            align="start"
            className="t-max-h-80 t-overflow-auto t-z-dropdown"
          >
            {bookkeepers?.map(({ uuid, name }) => {
              return (
                <Dropdown.Item
                  key={uuid}
                  disabled={isLoading}
                  onClick={stopPropagation}
                  onSelect={() =>
                    uuid !== profile_id && onBookkeeperUpdated(uuid)
                  }
                >
                  <span className="t-flex t-justify-between t-items-center t-gap-3">
                    {name}
                    {uuid === profile_id && (
                      <Button
                        customType="ghost_icon"
                        size="small"
                        disabled={isLoading}
                        onClick={(e) => removeBookkeeper(e)}
                      >
                        <i className="far fa-times-circle t-text-blue" />
                      </Button>
                    )}
                  </span>
                </Dropdown.Item>
              );
            })}
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>
    );
  }

  return (
    <>
      {assignedBookkeeperName ? (
        <span className="t-text-lg t-rounded-lg t-bg-purple-20 t-px-2 t-py-1 t-text-purple">
          {assignedBookkeeperName}
        </span>
      ) : (
        "-"
      )}
    </>
  );
};

export const BooksCRM = () => {
  usePageTitle("Books CRM");
  const { update } = useUpdateQuery();
  const query = useQuery();
  const searchTerm = query.get("search");
  const page = Number(query.get("page")) || 1;
  const searchValue = useDebounce(searchTerm, 500);

  const { data: booksCRMentities, isLoading } = useGetBooksCRMentitiesQuery({
    pageNum: page,
    searchTerm: searchValue,
  });

  const {
    total_count = 0,
    current_page = 0,
    total_pages = 0,
    per_page = 0,
    company_entities = [],
  } = booksCRMentities || {};

  const paginationData = {
    totalItemCount: total_count,
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
  };

  const data = useMemo(() => company_entities, [company_entities]);

  const columns = useMemo(
    () => [
      {
        Header: "Entity Name",
        accessor: "name",
        width: "30%",
        Cell: ({ row: { original } }: any) => {
          const {
            name,
            subscription_name,
            group: { name: groupName, uuid },
          } = original;

          return (
            <div className="t-flex t-flex-col">
              <div className="t-flex t-gap-2 t-items-center">
                <span className="t-text-subtitle-sm t-text-text-100">
                  {name}
                </span>

                {Boolean(subscription_name) &&
                  subscription_name !== "NOT_STARTED" && (
                    <Badge
                      size="small"
                      color={
                        CRM_SUBSCRIPTION_TYPE[
                          subscription_name as "PRO" | "STANDARD"
                        ]
                      }
                    >
                      {subscription_name?.[0]}
                    </Badge>
                  )}
              </div>
              <span onClick={stopPropagation}>
                <ConditionalLink to={`/crm/${uuid}`} target="_blank">
                  <span className="t-text-body-sm t-underline hover:t-text-purple t-text-text-100">
                    {groupName}
                  </span>
                </ConditionalLink>
              </span>
            </div>
          );
        },
      },
      {
        Header: "Software",
        accessor: "bookkeeping_software",
        width: "10%",
        Cell: ({ value }: any) => {
          if (value === ZOHO_BOOKS) {
            return (
              <ToolTip text={ZOHO_BOOKS}>
                <img alt={ZOHO_BOOKS} src={ZohoBooks} className="t-h-6 t-w-6" />
              </ToolTip>
            );
          }

          if (value === QUICK_BOOKS) {
            return (
              <ToolTip text={QUICK_BOOKS}>
                <img
                  alt={QUICK_BOOKS}
                  src={QuickBooks}
                  className="t-h-6 t-w-6"
                />
              </ToolTip>
            );
          }
          return "-";
        },
      },
      {
        Header: "Ledger Check",
        accessor: "ledger_check",
        width: "10%",
        textAlign: "center",
        Cell: ({ value }: { value: boolean }) => {
          return (
            <>
              {value ? (
                <img
                  alt="No mismatch transation"
                  src={GreenTick}
                  className="t-h-6 t-w-6"
                />
              ) : (
                <img
                  alt="Mismatch transation"
                  src={CrossRed}
                  className="t-h-6 t-w-6"
                />
              )}
            </>
          );
        },
      },
      {
        Header: "Bookkeeper",
        accessor: "assigned_bookkeeper",
        width: "20%",
        Cell: ({ row: { original } }: any) => {
          const { assigned_bookkeeper, uuid } = original || {};
          return (
            <Bookkeeper
              assignedBookkeeper={assigned_bookkeeper}
              entityId={uuid}
            />
          );
        },
      },
      {
        Header: "Method",
        accessor: "accounting_method",
        width: "10%",
        textAlign: "center",
        Cell: ({ row: { original } }: any) => {
          const { accounting_method, uuid } = original || {};
          return (
            <AccountingMethod
              currentAccountingMethod={accounting_method}
              entityId={uuid}
            />
          );
        },
      },
      {
        Header: "Closing Status",
        accessor: "season_summaries",
        width: "10%",
        textAlign: "center",
        Cell: ({ value }: any) => {
          const isSessonSummary = value?.length > 1;

          if (isSessonSummary) {
            return (
              <div className="t-flex t-flex-1 t-gap-1.5 t-flex-wrap">
                {value.map(
                  ({ start_date_year, status, name }: SeasonSummary) => (
                    <ToolTip text={status} key={status}>
                      <span>
                        <Badge
                          size="small"
                          color={BOOKS_SEASON_SUMMARY_STATUS[status]}
                        >
                          {start_date_year}
                        </Badge>
                      </span>
                    </ToolTip>
                  )
                )}
              </div>
            );
          }

          return "-";
        },
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      // @ts-ignore
      columns,
      // @ts-ignore
      data,
    });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    update({ query: "search", value: value || null });
  };

  const goToFirstPage = () => {
    update({ query: "page", value: 1 });
  };

  const goToPrevPage = () => {
    const localCurrentPage = page < total_pages ? page : total_pages;
    update({ query: "page", value: localCurrentPage - 1 });
  };

  const goToNextPage = () => {
    if (page < total_pages) {
      update({ query: "page", value: page + 1 });
    }
  };
  const goToLastPage = () => {
    update({ query: "page", value: total_pages });
  };

  const onRowClick = ({
    groupId,
    entityId,
  }: {
    groupId: string;
    entityId: string;
  }) => {
    openLink(
      `/books/financial-closing?company=${groupId}&entity=${entityId}`,
      "_blank"
    );
  };

  if (isLoading) {
    return (
      <div className="t-w-full">
        <Loader />
      </div>
    );
  }

  return (
    <div className="t-flex t-w-full t-flex-col">
      <CrmTabBar selectedTabIs="admin/bookscrm" />
      <div className="t-flex t-flex-wrap t-items-center t-justify-between t-gap-2 t-px-5 t-pt-5 t-bg-white t-mb-4">
        <Search
          placeholder="Search..."
          onChange={handleChange}
          value={searchTerm || ""}
        />
        <Pagination
          {...paginationData}
          goToFirstPage={goToFirstPage}
          goToPrevPage={goToPrevPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
        />
      </div>
      <div className="t-px-5 t-overflow-auto t-relative t-pb-3">
        <Table.Container>
          <Table.Content {...getTableProps()} className="all:unset">
            <Table.Head className="t-sticky t-top-0 t-z-header">
              {headerGroups.map((headerGroup: any) => (
                <Table.Row
                  key={headerGroup.id}
                  {...headerGroup.getHeaderGroupProps()}
                  className="t-flex t-items-center t-justify-center t-gap-6 t-px-4 t-py-2"
                >
                  {headerGroup.headers.map((column: any) => (
                    <th
                      className="t-text-start"
                      key={column.id}
                      {...column.getHeaderProps({})}
                      style={{ width: column?.width }}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </Table.Row>
              ))}
            </Table.Head>
            <Table.Body {...getTableBodyProps()}>
              {rows.map((row: any) => {
                prepareRow(row);

                const {
                  original: {
                    group: { uuid: groupId },
                    uuid,
                  },
                } = row;

                return (
                  <Table.Row
                    onClick={() => onRowClick({ groupId, entityId: uuid })}
                    key={row.id}
                    {...row.getRowProps()}
                    className="t-cursor-pointer t-border-b t-border-r-0 t-border-t-0 t-border-solid t-border-i-neutral-10 t-border-l-surface-transparent hover:t-bg-i-surface-light-purple t-flex t-items-center t-justify-center t-gap-6 t-px-4 t-py-2"
                  >
                    {row.cells.map((cell: any) => {
                      return (
                        <td
                          key={cell.value}
                          {...cell.getCellProps({})}
                          style={{
                            width: cell.column?.width,
                            textAlign: cell.column?.textAlign,
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table.Content>
        </Table.Container>
      </div>
    </div>
  );
};
