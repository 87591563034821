import Async from "components/DesignSystem/AsyncComponents/Async";
import { METRICS, PERIOD, REVENUE_BASIS } from "constants/revenueMetrics";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { useGetAllRevenueMetricsQuery } from "store/apis/revenueMetrics";
import { formatRevenueData } from "utils/formatRevenueData";
import { FinalDataTable } from "./FinalDataTable";
import { RevenueChart } from "./RevenueChart";

import { YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { useFilters } from "hooks/useFilter";
import { getDateRange } from "utils/getDateRange";
import { TotalRevenueFilter } from "./RevenueFilter";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { usePagination } from "hooks/usePagination";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { ACCOUNTING_METHODS } from "constants/bookkeeping";

export type FilterValues = {
  START_DATE: string;
  END_DATE: string;
  SELECT_PERIOD: string;
  VIEW_TYPE: keyof typeof PERIOD;
  REVENUE_BASIS: keyof typeof REVENUE_BASIS;
  ACCOUNTING_METHOD: keyof typeof ACCOUNTING_METHODS;
};

export const TotalRevenue = () => {
  usePageTitle("Total Revenue");
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { startDate, endDate } = getDateRange("last12months");
  const query = useQuery();
  const page_num = query.get("page_num") || 1;
  const { update } = useUpdateQuery();

  const { values: filterValues, updateFilter } = useFilters<FilterValues>({
    initialValue: {
      START_DATE: dayjs(startDate).format(YYYY_MM_DD),
      END_DATE: dayjs(endDate).format(YYYY_MM_DD),
      SELECT_PERIOD: "last12months",
      VIEW_TYPE: PERIOD.MONTHLY,
      REVENUE_BASIS: REVENUE_BASIS.CUSTOMER,
      ACCOUNTING_METHOD: ACCOUNTING_METHODS.ACCRUAL,
    },
  });

  const onRevenueBasisChange = (revenueBasis: keyof typeof REVENUE_BASIS) => {
    updateFilter("REVENUE_BASIS", revenueBasis);
  };

  const { data, isLoading, isSuccess } = useGetAllRevenueMetricsQuery(
    {
      groupId,
      entityId,
      revenue_basis: filterValues.REVENUE_BASIS,
      period_type: filterValues.VIEW_TYPE,
      start_date: filterValues.START_DATE,
      end_date: filterValues.END_DATE,
      metric_type: METRICS.TOTAL,
      accounting_method: filterValues.ACCOUNTING_METHOD,
      page_num: Number(page_num),
    },
    {
      skip: !groupId || !entityId,
    }
  );

  const {
    customers = [],
    periodic_total_amounts = [],
    current_page = 1,
    total_pages = 1,
    per_page = 1,
    total_count = 1,
  } = data || {};

  const { goToFirstPage, goToLastPage, goToNextPage, goToPrevPage } =
    usePagination({
      totalPage: total_pages,
      onPageNumChange: (page) => {
        update({
          query: "page_num",
          value: page,
        });
      },
      pageNumber: current_page,
    });

  const { chartData, finalTableData } = formatRevenueData({
    data: { periodic_total_amounts, customers },
  });

  return (
    <div className="t-flex t-gap-6 t-flex-col t-pb-10 t-relative t-top-0">
      <TotalRevenueFilter
        filterValues={filterValues}
        updateFilter={updateFilter}
      />
      <Async.Root isLoading={isLoading} isSuccess={isSuccess} isEmpty={false}>
        <Async.Empty>
          <></>
        </Async.Empty>
        <Async.Success>
          <RevenueChart title="Total Revenue" chartData={chartData} />
          <FinalDataTable
            finalTableData={finalTableData}
            onRevenueBasisChange={onRevenueBasisChange}
            revenueBasis={filterValues.REVENUE_BASIS}
            pagination={
              <Pagination
                goToFirstPage={goToFirstPage}
                goToLastPage={goToLastPage}
                goToNextPage={goToNextPage}
                goToPrevPage={goToPrevPage}
                itemsPerPage={per_page}
                totalItemCount={total_count}
                totalPage={total_pages}
                currentPage={current_page}
              />
            }
          />
        </Async.Success>
      </Async.Root>
    </div>
  );
};
