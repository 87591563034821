import { PUBLIC_USER_EMAIL } from "./../../constants/session";
import {
  BaseQueryApi,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAccessTokenUsingRefreshToken } from "apis/token";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";
import {
  openFreeSubscription,
  openUpgradePlanModal,
  setRequiredSubscriptionId,
} from "store/slices/subscriptionModal";

// import { http, HttpResponse } from "msw";

// USE THIS SETTING ONLY IF YOU NEED TO MOCK API CALLS
// if (import.meta.env.DEV) {
//   const startWorker = async () => {
//     const { worker } = await import("../../mocks/browser");
//     worker.use();
//     worker.start();
//   };
//   startWorker();
// }

type QueryReturnValue<T = unknown, E = unknown, M = unknown> =
  | {
      error: E;
      data?: undefined;
      meta?: M;
    }
  | {
      error?: undefined;
      data: T;
      meta?: M;
    };

const getAccessToken = async () => {
  let authInLocal;
  try {
    authInLocal = localStorage.getItem("authTokens");
    authInLocal = JSON.parse(authInLocal || "{}");

    if (!authInLocal.access) {
      throw new Error("TOKEN_NOT_FOUND");
    }

    const user = jwt_decode<{ exp: number }>(authInLocal.access);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if (isExpired) {
      throw new Error("TOKEN_EXPIRED");
    }

    if (authInLocal.access && !isExpired) {
      return authInLocal.access;
    }
  } catch (error: any) {
    const message = (error as Error).message;

    if (message === "TOKEN_EXPIRED") {
      try {
        const { access } = await getAccessTokenUsingRefreshToken({
          refresh: authInLocal.refresh,
        });
        localStorage.setItem(
          "authTokens",
          JSON.stringify({ ...authInLocal, access })
        );
      } catch (error) {
        return false;
      }
    }
  }
};

export const baseURL = process.env.PUBLIC_BASE_URL;

const query = fetchBaseQuery({
  baseUrl: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

const signout = () => {
  localStorage.removeItem("authTokens");
  window.location.href = `${window.location.origin}/signin`;
};

export const handleSubscribscriptionBlocker = (
  dispatch: BaseQueryApi["dispatch"],
  error: { error: { code?: string; message: string } }
) => {
  const authInLocal = localStorage.getItem("authTokens");
  const parsedAuth = JSON.parse(authInLocal || "");

  const requiredSubscriptionId = error.error.message;

  if (parsedAuth.is_public_user) {
    dispatch(openFreeSubscription());
  } else {
    dispatch(setRequiredSubscriptionId(requiredSubscriptionId));
    dispatch(openUpgradePlanModal());
  }
};

export const handleDebterBlock = () => {
  if (!window.location.pathname.includes("/billing")) {
    window.location.href = `${window.location.origin}/billing/payment-pending`;
  }
};

const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let accessToken = null;
  try {
    accessToken = await getAccessToken();
  } catch (error) {}
  const authInLocal = JSON.parse(localStorage.getItem("authTokens") || "{}");

  const headers = new Headers();

  if (accessToken) {
    headers.set("Authorization", `Bearer ${accessToken}`);
    headers.set("request-path", window.location.href);
  }

  let queryParams: string | FetchArgs = { url: "" };

  if (typeof args === "object") {
    queryParams = { ...args, headers };
  } else {
    queryParams = { url: args, headers };
  }

  let result = await query(queryParams, api, extraOptions);

  if (result.error) {
    if (result?.error?.status === 401) {
      signout();
    }

    if (result?.error?.status === 402) {
      let error = result?.error.data as {
        error: { code?: string; message: string };
      };

      if (error.error.code === "pending_dues") {
        if (authInLocal?.is_service_user) {
          return {
            error: result.error,
          };
        }
        handleDebterBlock();
        return {
          error: result.error,
        };
      }

      handleSubscribscriptionBlocker(api.dispatch, error);
      error.error.message =
        "You don't have access to this feature. Please upgrade your subscription to continue.";
      result.error.data = error;
    }

    return {
      error: result.error,
    };
  }

  // return result;
  return {
    ...result,
    data: (result.data as { data: unknown } | null)?.data,
  } as QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>;
};

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptyApi = createApi({
  tagTypes: [
    "ServiceMembers",
    "Tasks",
    "Calender",
    "PlaidBanks",
    "CalendarPdf",
    "Referral",
    "CompanyWForm",
    "Transactions",
    "ChatTemplate",
    "CrmCompanyGroups",
    "ChatBroadcast",
    "ChannelListQuery",
    "Task7004",
    "Quotes",
    "BooksOnboarding",
    "ChatTickets",
    "ChatOverview",
    "SalesCrmCompanyGroups",
    "SalesCrmProfiles",
    "CalendarReminder",
    "TeamSettingGroups",
    "ChannelDraft",
    "TP_INVOICES",
    "TP_PARTNER_ADDRESS",
    "TP_TRANSFERS",
    "TP_ACCOUNTINFO",
    "AUTOFILL",
    "AP_AUTOFILLS",
    "TaskTemplate",
    "CustomerChatTickets",
    "GroupCoupon",
    "TaskList",
    "TeamMember",
    "RequestedTeamMember",
    "Groups",
    "STAKEHOLDER_UPDATE",
    "STAKEHOLDER_UPDATE_INTRO",
    "STAKEHOLDER_UPDATE_CB",
    "STAKEHOLDERS",
    "SavedCards",
    "NOTIFICATION",
    "REGISTER_AGENT",
    "FCA",
    "PreIncorpList",
    "TicketNotes",
    "BooksCRM",
    "PerkList",
    "GetFilings",
    "CPATeamManagement",
    "Subscriptions",
    "OnboardingProducts",
    "BillingInvoices",
    "OnboardingSteps",
    "OPEN_ITEMS",
    "SliderCRM",
    "INVOICES",
    "INVOICE_CUSTOMERS",
    "INVOICE_SETTING",
    "INVOICE_ITEMS",
    "GLOBAL_COMPLIANCE",
    "CHART_OF_ACCOUNTS",
    "Merchants",
    "DOCUMENT_REQUEST",
    "BOOKS_TRANSACTIONS",
    "BOOKS_SINGLE_TRANSACTION",
    "CART",
    "RECONCILIATION",
    "VENDORS",
    "GENERAL_LEDGER",
    "CONNECTION",
    "BILLING_INFO",
    "REPORTS",
    "TRANSACTION_MERCHANTS",
    "CREDITS",
    "VC_INTROS",
    "BOOKS_CRM",
    "RAISE_PROFILES",
    "VENTURE_CAPITALIST",
    "SINGLE_VENTURE_CAPITALIST",
    "VC_CONTACTS",
    "VC_PROFILE",
    "PROFILE",
    "CAPTBALE",
    "NEXUSTRACKER",
    "INTERACTIVE_REPORTS",
    "RULE_ENGINE",
    "INTERNAL_TEAM_SETTINGS",
    "JOURNAL_ENTRIES",
    "FIXED_ASSET",
    "COA_START_DATE",
    "MESSAGE_DETAILS",
    "ALL_SHARED_MESSAGE",
    "MAILROOM",
    "MAILROOM_DEFAULT_ADDRESS",
    "RECENT_DOCUMENT",
    "CALL_HISTORY",
    "CHAT_SETTINGS",
    "DOCUMENT_LIST",
    "FSPGroups",
    "PracticeTeamManagement",
    "All_SERVICE_PROVIDERS",
    "ENTITY_DATA_ATTRIBUTES",
    "ALARMS",
    "ALL_COMPLIANCES",
    "MISSING_DATA_POINTS",
    "PRACTICE_BILLING_INFO",
    "PRACTICE_PAYMENT_METHODS",
    "PRACTICE_AUTOFILL",
    "PRACTICE_INVOICES",
    "MONEY_IN_TRANSIT",
    "AGENT_AVAILABILITY",
    "BOOKS_QUOTES",
    "SHAREHOLDERS",
    "LIVE_REPORT",
    "AUDIT_LOG_BY_OBJECT",
    "CALL_DETAILS",
    "BREX_POINTS",
    "PRODUCTS_AND_SERVICES",
    "SENSITIVE_DATA",
    "SENSITIVE_DATA_ACCESS",
    "TASK_ALARM",
    "FEDERAL_REGISTRATIONS",
    "STATE_REGISTRATIONS",
    "PLATFORM_SUBSCRIPTION_PRODUCT",
    "LINKED_INVOICES",
    "SingleBillingInvoice",
    "TEAMS_FOR_CHANNEL",
    "MEMBERS_FOR_CHANNEL",
    "NOTES",
    "FORM_1099_TEMPLATE",
    "TICKET_ETD_HISTORY",
    "AGING_SUMMARY",
    "REMINDERS",
    "ALL_ACTION_ITEMS",
    "CUSTOMER_ADDRESSES",
    "INVOICE_CUSTOMER_ADDRESS",
    "FINANCIAL_CLOSING",
    "FINANCIAL_CLOSING_COMMENTS",
    "FINANCIAL_CLOSING_SUMMARY",
    "BANK_ACCOUNT_STATEMENTS",
    "BANK_TRANSFERS",
    "BANK_TRANSFER_DETAILS",
    "VENDOR_SUMMARY",
    "GROUP_ACCOUNT_OVERVIEW",
    "COMPANY_RA_MAILROOM",
    "VENDOR_BILL",
    "PUBLIC_VENDOR_BILL",
  ],
  baseQuery: baseQuery,
  endpoints: () => ({}),
});
