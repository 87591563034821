import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { YYYY_MM_DD } from "constants/date";
import { CLOSING_STATUS } from "constants/financialClosing";
import dayjs from "dayjs";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useAddFinancialClosingMutation } from "store/apis/financialClosing";
import { useGetTaskDataQuery } from "store/apis/taskTags";
import { US_BOOKKEEPING_SUBSCRIPTION_PARENT } from "types/Models/services";
import { TaskStateType } from "types/Models/task";
import { BackendError } from "types/utils/error";

export const AddFinancialClosing = () => {
  const { taskId } = useParams<{ taskId: string }>();
  const history = useHistory();
  const [addFinancial, { isLoading: isAdding }] =
    useAddFinancialClosingMutation();
  const { alertToast } = useToast();
  const { url } = useRouteMatch();

  const {
    data: taskData,
    isLoading,
    isSuccess,
  } = useGetTaskDataQuery({ uuid: taskId }, { skip: !taskId });

  const {
    entity,
    group_uuid,
    from_date,
    to_date,
    accounting_method,
    state,
    base_task_key,
  } = taskData || {};

  const entityId = entity?.uuid;

  const onAddFinancial = async () => {
    try {
      if (!entityId || !accounting_method) return;

      const { uuid } = await addFinancial({
        entityId,
        payload: {
          accounting_method,
          start_date: from_date
            ? dayjs(from_date).startOf("month").format(YYYY_MM_DD)
            : "",
          end_date: to_date
            ? dayjs(to_date).endOf("month").format(YYYY_MM_DD)
            : "",
          task_id: taskId,
        },
      }).unwrap();
      history.push(`${url}/${uuid}?company=${group_uuid}&entity=${entityId}`);
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as Error
      );
    }
  };

  const { isCustomer } = useRoleBasedView();

  if (isCustomer || base_task_key === US_BOOKKEEPING_SUBSCRIPTION_PARENT) {
    return null;
  }

  return (
    <Async.Root
      {...{
        isLoading,
        isSuccess,
        isEmpty:
          !taskData || (state as TaskStateType)?.type !== "FILING_IN_PROGRESS",
      }}
      loaderType="secondary"
    >
      <Async.Empty>
        <></>
      </Async.Empty>
      <Async.Success>
        <Button
          customType="primary-outlined"
          size="small"
          onClick={onAddFinancial}
          disabled={isAdding}
          isLoading={isAdding}
          type="button"
        >
          Add financials
        </Button>
      </Async.Success>
    </Async.Root>
  );
};

export const ReviewFinancialClosing = () => {
  const { taskId } = useParams<{ taskId: string }>();
  const history = useHistory();
  const { url } = useRouteMatch();

  const {
    data: taskData,
    isLoading,
    isSuccess,
  } = useGetTaskDataQuery({ uuid: taskId }, { skip: !taskId });
  const { group_uuid, entity, financial_closing, base_task_key } =
    taskData || {};

  const onReview = () => {
    const entityId = entity?.uuid;

    history.push(
      `${url}/${financial_closing?.uuid}?company=${group_uuid}&entity=${entityId}`
    );
  };

  const isPushedForReview =
    financial_closing?.status === CLOSING_STATUS.REVIEW_PENDING;

  if (base_task_key === US_BOOKKEEPING_SUBSCRIPTION_PARENT) {
    return null;
  }
  return (
    <Async.Root
      {...{
        isLoading,
        isSuccess,
        isEmpty: !taskData || !isPushedForReview || !financial_closing,
      }}
      loaderType="secondary"
    >
      <Async.Empty>
        <></>
      </Async.Empty>
      <Async.Success>
        <Button
          customType="primary-outlined"
          size="small"
          type="button"
          onClick={onReview}
        >
          Review financials
        </Button>
      </Async.Success>
    </Async.Root>
  );
};

export const ViewFinancialClosing = () => {
  const { taskId } = useParams<{ taskId: string }>();
  const history = useHistory();
  const { url } = useRouteMatch();

  const {
    data: taskData,
    isLoading,
    isSuccess,
  } = useGetTaskDataQuery({ uuid: taskId }, { skip: !taskId });
  const { group_uuid, entity, financial_closing, base_task_key } =
    taskData || {};

  const onReview = () => {
    const entityId = entity?.uuid;

    history.push(
      `${url}/${financial_closing?.uuid}?company=${group_uuid}&entity=${entityId}`
    );
  };

  const isCompleted = financial_closing?.status === CLOSING_STATUS.COMPLETED;

  if (base_task_key === US_BOOKKEEPING_SUBSCRIPTION_PARENT) {
    return null;
  }

  return (
    <Async.Root
      {...{
        isLoading,
        isSuccess,
        isEmpty: !taskData || !isCompleted || !financial_closing,
      }}
      loaderType="secondary"
    >
      <Async.Empty>
        <></>
      </Async.Empty>
      <Async.Success>
        <Button
          customType="primary-outlined"
          type="button"
          size="small"
          onClick={onReview}
        >
          View financials
        </Button>
      </Async.Success>
    </Async.Root>
  );
};
