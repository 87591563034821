import { Button } from "components/DesignSystem/Button/Button";
import ChatCard from "components/DesignSystem/ChatCard/v2/ChatCard";
import { QuestionMark } from "components/icons/QuestionMark";
import { Period } from "pages/Books/FinancialClosing/FinancialClosing";
import React from "react";
import { useHistory } from "react-router-dom";
import TinySuccess from "static/images/TinySuccess.svg";

type RaiseClarificationCardProps = {
  customData: {
    action: string;
    card_type: string;
    end_date: string;
    entity_id: string;
    financial_closing_id: string;
    group_id: string;
    start_date: string;
  };
  children?: React.ReactNode;
};

export const RaiseClarificationCard = ({
  customData,
  children,
}: RaiseClarificationCardProps) => {
  const history = useHistory();

  const handleViewDetails = () => {
    history.push(
      `/books/financial-closing/${customData.financial_closing_id}?company=${customData.group_id}&entity=${customData.entity_id}`
    );
  };

  return (
    <div className="t-min-w-64 t-mb-2">
      <ChatCard.Content addMargin={false}>
        <ChatCard.Header
          icon={
            <div className="t-text-neutral">
              <QuestionMark />
            </div>
          }
        >
          <div className="t-text-subtitle-sm t-text-text-30">
            {customData.action}
          </div>
        </ChatCard.Header>
        <ChatCard.Body>
          <div className="t-space-y-4">
            <div className="t-text-subtext t-text-text-60">
              <Period
                start_date={customData?.start_date}
                end_date={customData?.end_date}
              />
            </div>
            <Button
              customType="primary"
              block
              size="small"
              onClick={handleViewDetails}
            >
              View
            </Button>
          </div>
        </ChatCard.Body>
      </ChatCard.Content>
      {children}
    </div>
  );
};
