import React from "react";

export const ReviewPayHeader = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clip-path="url(#clip0_2057_1134)">
      <path
        d="M3.66667 7.33333C4.21867 7.33333 4.66667 7.78133 4.66667 8.33333C4.66667 8.88533 4.21867 9.33333 3.66667 9.33333C3.11467 9.33333 2.66667 8.88533 2.66667 8.33333C2.66667 7.78133 3.11467 7.33333 3.66667 7.33333ZM16 3.33333V7.33333C16 7.70133 15.702 8 15.3333 8C14.9647 8 14.6667 7.70133 14.6667 7.33333V4.66667H1.33333V8.66667C1.33333 9.76933 2.23067 10.6667 3.33333 10.6667H8.66667C9.03533 10.6667 9.33333 10.9653 9.33333 11.3333C9.33333 11.7013 9.03533 12 8.66667 12H3.33333C1.49533 12 0 10.5047 0 8.66667V3.33333C0 1.49533 1.49533 0 3.33333 0H12.6667C14.5047 0 16 1.49533 16 3.33333ZM14.6667 3.33333C14.6667 2.23067 13.7693 1.33333 12.6667 1.33333H3.33333C2.23067 1.33333 1.33333 2.23067 1.33333 3.33333H14.6667ZM14.3333 10.3333C14.3333 9.414 13.586 8.66667 12.6667 8.66667C11.7473 8.66667 11 9.414 11 10.3333C11 11.2527 11.7473 12 12.6667 12C13.586 12 14.3333 11.2527 14.3333 10.3333ZM12.6667 12.6667C11.1113 12.6667 9.75 13.692 9.356 15.1607C9.26 15.5167 9.47133 15.882 9.82733 15.9773C10.1833 16.0727 10.548 15.862 10.644 15.506C10.8813 14.6193 11.7133 14 12.6667 14C13.62 14 14.452 14.6193 14.6893 15.506C14.7693 15.8033 15.038 16 15.3327 16C15.39 16 15.448 15.9927 15.506 15.9767C15.862 15.8813 16.0733 15.516 15.9773 15.16C15.5833 13.6913 14.222 12.6667 12.6667 12.6667Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2057_1134">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
