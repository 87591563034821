import Loader from "components/design/loader";
import { MultiSelectFilter } from "components/DesignSystem/Filter/Filter";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { useState } from "react";
import { useLazyGetAllGroupsQuery } from "store/apis/crm";
import { CompanyGroup } from "types/Models/groups";
import { debounce } from "utils/debouncing";

export const MultiGroupFilter = ({
  updateFilter,
  values,
}: {
  updateFilter: <S extends "GROUPS">(
    name: S,
    newValue: {
      GROUPS: string[];
    }[S]
  ) => void;
  values: {
    GROUPS?: string[];
  };
}) => {
  const [groupSearchValue, setGroupSearchValue] = useState("");

  const {
    data: groups,
    loadNext,
    isLoading,
  } = usePaginatedQuery<{
    company_groups?: CompanyGroup[];
  }>(useLazyGetAllGroupsQuery, "company_groups", {
    search_term: groupSearchValue,
  });

  const updateFilterCallback =
    (name: Parameters<typeof updateFilter>[0]) =>
    (newValues: Parameters<typeof updateFilter>[1]) => {
      updateFilter(name, newValues);
    };

  const onGroupSearch = debounce((value) => setGroupSearchValue(value));

  if (isLoading || !groups?.company_groups) {
    return (
      <div className="t-flex t-items-center t-justify-center t-h-full">
        <Loader customType="secondary" size="small" />
      </div>
    );
  }

  if (groups?.company_groups) {
    return (
      <MultiSelectFilter
        onSearchValueChange={onGroupSearch}
        hasMore={true}
        totalCount={groups.company_groups.length}
        loadNext={loadNext}
        onChange={updateFilterCallback("GROUPS")}
        options={groups.company_groups.map((group) => ({
          value: group.uuid,
          label: group.name,
        }))}
        selected={values.GROUPS}
      />
    );
  }

  return null;
};
