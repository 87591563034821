import { REMINDER_FREQUECIES_TYPES } from "types/contants/reminderFrequencyTypes";
import { emptyApi } from "./emptyApi";
import { Pagination } from "types/Models/pagination";

export type UserProfile = {
  email: string;
  name: string;
  uuid: string;
  is_listview_preferred: boolean;
  is_slack_added: boolean;
  is_group_created: boolean;
  user_type: string;
  type: string;
  is_public_user: boolean;
  esign_consent: boolean;
  service_team_id: string;
  service_team?: {
    uuid: string;
    name: string;
    billing_type: string;
  };
};

export type ContentDetails = {
  uuid: string;
  content_type: string;
  message_text: string;
  message_id: string;
  channel_id: string;
  channel_name: string;
};

export type CompanyGroup = {
  name: string;
  uuid: string;
};

export type ReminderSetting = {
  reminder_setting_id: string;
  frequency: REMINDER_FREQUECIES_TYPES;
  notification_channel: string[];
  is_reminder_enabled: boolean;
  subscribed_profiles: UserProfile[];
  on_date: string;
  company_group: CompanyGroup;
  content_details: ContentDetails;
  created_at: string;
  reminder_setter?: { name: string | null };
};

export type ReminderDetails = {
  uuid: string;
  message_text: string;
  status: "DRAFT" | "SENT" | "SKIPPED";
  recurring_date: string;
  subscribers: UserProfile[];
  notification_channels: string[];
};

export type Reminder = {
  reminder: ReminderDetails;
  reminder_setting: ReminderSetting;
};

export type ReminderSettingPayload = {
  reminder_details: {
    frequency: REMINDER_FREQUECIES_TYPES;
    is_reminder_enabled: boolean;
    notification_channels: string[];
    on_date: string | null;
    subscribers: string[];
    group_id: string;
  };
  content_details: {
    message_id: string;
    message_text: string;
    content_type: string;
    channel_id: string;
  };
};

export type ReminderPayload = {
  reminder_notification_details: {
    message_text: string;
    skip_message: boolean;
    notification_channels: string[];
    subscribers: string[];
  };
};

export type ReminderStatusFilter =
  | "DRAFT"
  | "SENT"
  | "SKIPPED"
  | "SCHEDULED"
  | "OVERDUE";

export const reminders = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllReminders: build.query<
      Pagination & { reminders: ReminderSetting[] },
      {
        page_num: number;
        reminder_status?: string;
        company_groups?: string;
      }
    >({
      query: ({ page_num, reminder_status, company_groups }) => ({
        url: `/api/inkle/pigeon/reminders/`,
        params: {
          page_num,
          reminder_status,
          company_groups,
        },
      }),
      providesTags: (result) => (result ? ["REMINDERS"] : []),
    }),

    createReminderSetting: build.mutation<
      null,
      {
        groupId: string;
        payload: ReminderSettingPayload;
      }
    >({
      query: ({ groupId, payload }) => ({
        url: `/api/inkle/pigeon/group/${groupId}/reminder_settings/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result) => ["REMINDERS"],
    }),

    updateReminderSetting: build.mutation<
      null,
      {
        groupId: string;
        reminderId: string;
        payload: ReminderSettingPayload;
      }
    >({
      query: ({ groupId, reminderId, payload }) => ({
        url: `/api/inkle/pigeon/group/${groupId}/reminder_setting/${reminderId}/`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: (result) => ["REMINDERS"],
    }),

    getUpcomingReminders: build.query<
      Pagination & { reminders: Reminder[] },
      {
        page_num: number;
        reminder_status: string;
        company_groups: string;
      }
    >({
      query: ({ page_num = 1, reminder_status = "DRAFT", company_groups }) => ({
        url: `/api/inkle/pigeon/reminders/upcoming/`,
        params: {
          page_num,
          reminder_status,
          company_groups,
        },
      }),
      providesTags: (result) => (result ? ["REMINDERS"] : []),
    }),

    updateReminder: build.mutation<
      null,
      {
        groupId: string;
        reminderId: string;
        payload: ReminderPayload;
      }
    >({
      query: ({ groupId, reminderId, payload }) => ({
        url: `/api/inkle/pigeon/group/${groupId}/reminders/${reminderId}/`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: (result) => ["REMINDERS"],
    }),

    sendReminder: build.mutation<
      null,
      {
        groupId: string;
        reminderId: string;
      }
    >({
      query: ({ groupId, reminderId }) => ({
        url: `/api/inkle/pigeon/group/${groupId}/reminders/${reminderId}/`,
        method: "POST",
      }),
      invalidatesTags: (result) => ["REMINDERS"],
    }),
  }),
});

export const {
  useGetAllRemindersQuery,
  useCreateReminderSettingMutation,
  useUpdateReminderSettingMutation,
  useUpdateReminderMutation,
  useGetUpcomingRemindersQuery,
  useSendReminderMutation,
} = reminders;
