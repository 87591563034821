import { axiosInstance } from "./apiInstance";

export const assignUserToTask = async ({ taskId, userId }) => {
  const response = await axiosInstance.put(
    `/api/inkle/tasks/${taskId}/assign/`,
    {
      profile_id: userId,
    }
  );

  return response.data;
};
