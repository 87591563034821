import DashboardContainer from "components/dashboard/DashboardContainer";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "components/DesignSystem/ToggleGroup/ToggleGroup";
import { EmptyInvoiceList } from "components/Illustrations/EmptyInvoiceList";
import { InvoiceConsole } from "components/InvoiceList/InvoiceList";
import { FINANCIAL_RECORD_TYPE } from "constants/vendorBills";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useFilters } from "hooks/useFilter";
import { usePageTitle } from "hooks/usePageTitle";
import { usePagination } from "hooks/usePagination";
import { useToast } from "hooks/useToast";
import { parse, stringify } from "qs";
import React from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import {
  useAddBillOrRequestMutation,
  useGetAllBillsQuery,
} from "store/apis/vendorBills";
import { BackendError } from "types/utils/error";
import { useDebounce } from "utils/debounce";
import { EmptyScreen } from "../EmptyScreen";
import BillList from "./BillList";

export const Bills = () => {
  usePageTitle("Bills");
  const { alertToast } = useToast();
  const history = useHistory();
  const { uuid: groupId } = useCurrentGroupContext();
  const location = useLocation();
  const { path, url } = useRouteMatch();
  const entityId = useCurrentEntityId();
  const { values, updateFilter } = useFilters<{
    SEARCH: string | null;
    BILL_TYPE: keyof typeof FINANCIAL_RECORD_TYPE;
    PAGE_NUM: number;
  }>({
    initialValue: {
      SEARCH: null,
      BILL_TYPE: FINANCIAL_RECORD_TYPE.BILL,
      PAGE_NUM: 1,
    },
    useQueryParams: true,
  });

  const pageNum = useDebounce(values.PAGE_NUM);
  const search = useDebounce(values.SEARCH);
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });
  const [addBillOrRequest, { isLoading: isAdding, originalArgs }] =
    useAddBillOrRequestMutation();

  const searchQuery = stringify(
    {
      entity: parsedSearch.entity,
      company: parsedSearch.company,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  const { data, isSuccess, isFetching } = useGetAllBillsQuery(
    {
      bill_type: values.BILL_TYPE,
      entityId,
      groupId,
      page_num: pageNum,
      search_term: search,
    },
    {
      skip: !groupId || !entityId,
    }
  );

  const {
    bills = [],
    due_amount,
    total_amount,
    paid_amount,
    per_page = 1,
    total_count = 1,
    total_pages = 1,
  } = data || {};

  const pagination = usePagination({
    onPageNumChange: (page) => {
      updateFilter("PAGE_NUM", page);
    },
    pageNumber: values.PAGE_NUM,
    totalPage: total_pages,
  });

  const handlesearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFilter("SEARCH", e.target.value || null);
  };

  const handleAddBillOrRequest = async ({
    billType,
  }: {
    billType: keyof typeof FINANCIAL_RECORD_TYPE;
  }) => {
    try {
      const { uuid } = await addBillOrRequest({
        bill_type: billType,
        entityId,
        groupId,
      }).unwrap();
      if (billType === FINANCIAL_RECORD_TYPE.BILL) {
        history.push(`${path}/bill/${uuid}${searchQuery}`);
        return;
      }
      if (billType === FINANCIAL_RECORD_TYPE.REQUEST) {
        history.push(`${path}/request/${uuid}${searchQuery}`);
        return;
      }
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as Error
      );
    }
  };

  const isRequesting =
    originalArgs?.bill_type === FINANCIAL_RECORD_TYPE.REQUEST && isAdding;
  const isAddingBill =
    originalArgs?.bill_type === FINANCIAL_RECORD_TYPE.BILL && isAdding;

  return (
    <DashboardContainer className="t-relative">
      <DashboardContainer.Header sticky className="t-flex t-flex-col t-gap-5">
        <div className="t-flex t-justify-between">
          <Search
            placeholder="Search bills"
            className="t-w-1/2"
            onChange={handlesearch}
            defaultValue={search || ""}
          />
          <div className="t-flex t-justify-between t-gap-2">
            <Button
              isLoading={isRequesting}
              disabled={isRequesting}
              size="small"
              customType="primary-outlined"
              onClick={() =>
                handleAddBillOrRequest({
                  billType: FINANCIAL_RECORD_TYPE.REQUEST,
                })
              }
            >
              Request Bill
            </Button>
            <Button
              isLoading={isAddingBill}
              disabled={isAddingBill}
              size="small"
              customType="primary"
              onClick={() =>
                handleAddBillOrRequest({
                  billType: FINANCIAL_RECORD_TYPE.BILL,
                })
              }
            >
              Add Bill
            </Button>
          </div>
        </div>
        <InvoiceConsole
          total={{
            label: "Total billed amount",
            amount: total_amount || 0,
          }}
          paid={{
            label: "Paid",
            amount: paid_amount || 0,
          }}
          due={{
            label: "Due",
            amount: due_amount || 0,
          }}
        />
        <div className="t-flex t-justify-between">
          <ToggleGroup
            className="t-w-80"
            value={values.BILL_TYPE}
            onValueChange={(value: keyof typeof FINANCIAL_RECORD_TYPE) => {
              if (!value) return;
              updateFilter("BILL_TYPE", value);
            }}
          >
            <ToggleGroupItem value={FINANCIAL_RECORD_TYPE.BILL}>
              Bills
            </ToggleGroupItem>
            <ToggleGroupItem value={FINANCIAL_RECORD_TYPE.REQUEST}>
              Requests
            </ToggleGroupItem>
          </ToggleGroup>
          <Pagination
            goToFirstPage={pagination.goToFirstPage}
            goToLastPage={pagination.goToLastPage}
            goToNextPage={pagination.goToNextPage}
            goToPrevPage={pagination.goToPrevPage}
            currentPage={values.PAGE_NUM}
            itemsPerPage={per_page}
            totalItemCount={total_count}
            totalPage={total_pages}
          />
        </div>
      </DashboardContainer.Header>
      <DashboardContainer.Content className="t-mt-4">
        <Async.Root
          isLoading={isFetching}
          isSuccess={isSuccess}
          isEmpty={bills.length === 0}
        >
          <Async.Empty>
            <>
              {values.BILL_TYPE === FINANCIAL_RECORD_TYPE.BILL ? (
                <EmptyScreen text="No bills found">
                  <EmptyInvoiceList />
                </EmptyScreen>
              ) : (
                <EmptyScreen text="No bills requested">
                  <EmptyInvoiceList />
                </EmptyScreen>
              )}
            </>
          </Async.Empty>
          <Async.Success>
            <BillList bills={bills} />
          </Async.Success>
        </Async.Root>
      </DashboardContainer.Content>
    </DashboardContainer>
  );
};
