import { Chip } from "components/DesignSystem/Chips/Chips";
import {
  Filter,
  MultiSelectFilter,
} from "components/DesignSystem/Filter/Filter";
import RadioGroup from "components/DesignSystem/RadioGroup/RadioGroup";
import { DateFilter } from "components/Filters/DateFilter";
import { ACCOUNTING_METHODS } from "constants/bookkeeping";
import { DD_MMM_YYYY } from "constants/date";
import { PERIOD, PERIOD_DATE_FORMAT } from "constants/revenueMetrics";
import dayjs from "dayjs";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { useState } from "react";
import { useLazyGetAllMerchantsQuery } from "store/apis/transactions";
import { debounce } from "utils/debouncing";
import { formatDateByViewType } from "utils/formatDate";

export type FilterValues = {
  START_DATE: string;
  END_DATE: string;
  ACCOUNTING_METHOD: keyof typeof ACCOUNTING_METHODS;
  VENDORS: string[];
  VIEW_BY: keyof typeof PERIOD;
  SELECT_PERIOD: string;
};

export const VendorFilter = ({
  filterValues,
  updateFilter,
}: {
  filterValues: FilterValues;
  updateFilter: (key: keyof FilterValues, value: any) => void;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [searchTerm, setSearchTerm] = useState("");

  const { data: merchants, loadNext } = usePaginatedQuery<{
    merchants?: string[];
  }>(
    useLazyGetAllMerchantsQuery,
    "merchants",
    {
      search_term: searchTerm,
      groupId,
      entityId,
    },
    ["groupId", "entityId"]
  );

  return (
    <Filter.Root
      defaultValue="VIEW_BY"
      capsule={
        <>
          {(filterValues.START_DATE || filterValues.END_DATE) && (
            <Chip
              onClose={() => {}}
              isRemovable={false}
              isActive
              filterType="VIEW_BY"
            >
              {formatDateByViewType(
                filterValues.START_DATE,
                filterValues.VIEW_BY
              )}
              {" to "}
              {formatDateByViewType(
                filterValues.END_DATE,
                filterValues.VIEW_BY
              )}
            </Chip>
          )}
          <Chip
            onClose={() => {}}
            isRemovable={false}
            isActive
            filterType="ACCOUNTING_METHOD"
          >
            <div className="first-letter:t-uppercase t-lowercase">
              {filterValues.ACCOUNTING_METHOD} method
            </div>
          </Chip>

          {Boolean(filterValues.VENDORS.length) && (
            <Chip
              onClose={() => updateFilter("VENDORS", [])}
              isActive
              filterType="VENDORS"
            >
              Vendors ({filterValues.VENDORS.length})
            </Chip>
          )}
        </>
      }
    >
      <Filter.Portal>
        <Filter.List>
          <Filter.ListItem value="VIEW_BY">Date</Filter.ListItem>
          <Filter.ListItem value="ACCOUNTING_METHOD">
            Accounting Method
          </Filter.ListItem>
          <Filter.ListItem value="VENDORS">Vendors</Filter.ListItem>
        </Filter.List>
        <Filter.Body value="VIEW_BY" block>
          <RadioGroup.Root
            className="t-mb-6"
            onValueChange={(value: typeof filterValues.VIEW_BY) => {
              updateFilter("VIEW_BY", value);
            }}
            value={filterValues.VIEW_BY}
          >
            <RadioGroup.Content className="t-flex t-gap-3">
              <RadioGroup.Item value={PERIOD.TOTALS}>Date</RadioGroup.Item>
              {filterValues.VIEW_BY === PERIOD.TOTALS && (
                <DateFilter values={filterValues} updateFilter={updateFilter} />
              )}

              <RadioGroup.Item value={PERIOD.MONTHLY}>Months</RadioGroup.Item>
              {filterValues.VIEW_BY === PERIOD.MONTHLY && (
                <DateFilter
                  values={filterValues}
                  updateFilter={updateFilter}
                  pickerType="month"
                />
              )}

              <RadioGroup.Item value={PERIOD.QUARTERLY}>
                Quarters
              </RadioGroup.Item>
              {filterValues.VIEW_BY === PERIOD.QUARTERLY && (
                <DateFilter
                  values={filterValues}
                  updateFilter={updateFilter}
                  pickerType="quarter"
                  showPeriodSelector={false}
                />
              )}
              <RadioGroup.Item value={PERIOD.YEARLY}>Years</RadioGroup.Item>
              {filterValues.VIEW_BY === PERIOD.YEARLY && (
                <DateFilter
                  values={filterValues}
                  updateFilter={updateFilter}
                  pickerType="year"
                  showPeriodSelector={false}
                />
              )}
            </RadioGroup.Content>
          </RadioGroup.Root>
        </Filter.Body>
        <Filter.Body value="ACCOUNTING_METHOD" block>
          <RadioGroup.Root
            className="t-mb-6"
            onValueChange={(value: typeof filterValues.ACCOUNTING_METHOD) => {
              updateFilter("ACCOUNTING_METHOD", value);
            }}
            value={filterValues.ACCOUNTING_METHOD}
          >
            <RadioGroup.Content>
              <RadioGroup.Item value={ACCOUNTING_METHODS.CASH}>
                Cash method
              </RadioGroup.Item>
              <RadioGroup.Item value={ACCOUNTING_METHODS.ACCRUAL}>
                Accrual method
              </RadioGroup.Item>
            </RadioGroup.Content>
          </RadioGroup.Root>
        </Filter.Body>
        <Filter.Body value="VENDORS" block>
          {merchants?.merchants && (
            <MultiSelectFilter
              loadNext={loadNext}
              hasMore={merchants.current_page !== merchants.total_pages}
              totalCount={merchants.merchants.length}
              options={
                merchants.merchants.map((merchant) => ({
                  label: merchant,
                  value: merchant,
                })) || []
              }
              onChange={(value: string[]) => updateFilter("VENDORS", value)}
              onSearchValueChange={debounce(setSearchTerm)}
              selected={filterValues.VENDORS}
            />
          )}
        </Filter.Body>
      </Filter.Portal>
    </Filter.Root>
  );
};
