import { Button } from "components/DesignSystem/Button/Button";
import { Loader } from "components/DesignSystem/Loader/Loader";
import Modal from "components/DesignSystem/Modal/Modal";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import { merchantAddSchema } from "formValidations/merchantSchema";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useToast } from "hooks/useToast";
import {
  useBulkMerchantUploadMutation,
  useGetParsedCSVQuery,
} from "store/apis/vendors";
import { MerchantCSVResponse } from "types/Models/vendors";
import { BackendError } from "types/utils/error";
import { AddMerchantsTable } from "./AddMerchantsTable";
import randomBytes from "randombytes";
import Async from "components/DesignSystem/AsyncComponents/Async";

type AddMerchantProps = {
  show: boolean;
  closeModal: () => void;
  season?: string;
  useCSV: boolean;
  csvId?: string;
  entityId: string;
};

const AddVendorLayout = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Modal.Content size="xl" useCustomOverlay>
      <Modal.Header>
        <Modal.Title>Add Vendors Manually</Modal.Title>
        <Modal.Close />
      </Modal.Header>
      {children}
    </Modal.Content>
  );
};

export const AddManualVendors = ({
  show,
  closeModal,
  season,
  useCSV,
  csvId,
  entityId,
}: AddMerchantProps) => {
  const { alertToast, successToast } = useToast();

  const [bulkUploadMerchant, { isLoading }] = useBulkMerchantUploadMutation();

  const {
    data: parsedCSV = [],
    isLoading: isCSVParsing,
    isSuccess,
    isError,
    error,
  } = useGetParsedCSVQuery(
    {
      entityId,
      csvId: csvId || "",
    },
    { skip: !useCSV || !csvId }
  );

  const handleSubmit = async (values: { merchants: MerchantCSVResponse[] }) => {
    const merchants = values.merchants.map((data) => {
      return {
        ...data,
        amount_paid_in_season: dayjs(data.amount_paid_in_season).year(),
      };
    });
    try {
      await bulkUploadMerchant({
        entityId,
        merchants: merchants,
      }).unwrap();

      successToast({ message: "New vendor has been added" });
      closeModal();
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  const parsedInitialValue = parsedCSV.map((data) => {
    return {
      ...data,
      amount_paid_in_season: dayjs().year(data.amount_paid_in_season as number),
      id: randomBytes(10).toString("hex"),
    };
  });

  const initialValue = useCSV
    ? parsedInitialValue
    : [
        {
          name: "",
          email: "",
          total_amount: 0,
          amount_paid_in_season: season
            ? dayjs().year(parseInt(season))
            : dayjs().year(),
          id: randomBytes(10).toString("hex"),
        },
        {
          name: "",
          email: "",
          total_amount: 0,
          amount_paid_in_season: season
            ? dayjs().year(parseInt(season))
            : dayjs().year(),
          id: randomBytes(10).toString("hex"),
        },
      ];

  return (
    <Modal.Root open={show} onOpenChange={closeModal} modal={false}>
      <Async.Root
        isLoading={isCSVParsing}
        isEmpty={useCSV ? parsedCSV.length === 0 : false}
        isSuccess={useCSV ? isSuccess : true}
        isError={isError}
        customLoader={
          <AddVendorLayout>
            <Modal.Body className="t-min-h-80 t-w-full t-h-full t-flex t-justify-center t-items-center">
              <Loader />
            </Modal.Body>
          </AddVendorLayout>
        }
      >
        <Async.Empty>
          <AddVendorLayout>
            <Modal.Body className="t-min-h-80 t-w-full t-h-full t-flex t-justify-center t-items-center">
              <>No data found in csv</>
            </Modal.Body>
          </AddVendorLayout>
        </Async.Empty>
        <Async.ErrorHandler>
          <AddVendorLayout>
            <Modal.Body className="t-min-h-80 t-w-full t-h-full t-flex t-justify-center t-items-center">
              <>{(error as BackendError)?.data?.error?.message}</>
            </Modal.Body>
          </AddVendorLayout>
        </Async.ErrorHandler>
        <Async.Success>
          <Formik
            initialValues={{ merchants: initialValue }}
            onSubmit={handleSubmit}
            validationSchema={merchantAddSchema}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ values, setFieldValue, submitForm }) => (
              <Form className="t-m-0">
                <AddVendorLayout>
                  <Modal.Body>
                    <AddMerchantsTable />
                    <div className="t-mt-6">
                      <Button
                        size="small"
                        onClick={() =>
                          setFieldValue("merchants", [
                            ...values?.merchants,
                            {
                              name: "",
                              email: "",
                              total_amount: "",
                              amount_paid_in_season: season,
                              id: randomBytes(10).toString("hex"),
                            },
                          ])
                        }
                        type="button"
                      >
                        Add more
                      </Button>
                    </div>
                  </Modal.Body>
                  <Modal.FooterButtonGroup>
                    <Modal.RawClose asChild>
                      <Button type="button" disabled={isLoading}>
                        Cancel
                      </Button>
                    </Modal.RawClose>
                    <Button
                      customType="primary"
                      onClick={submitForm}
                      isLoading={isLoading}
                      disabled={isLoading}
                    >
                      Confirm
                    </Button>
                  </Modal.FooterButtonGroup>
                </AddVendorLayout>
              </Form>
            )}
          </Formik>
        </Async.Success>
      </Async.Root>
    </Modal.Root>
  );
};
