import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import ChatCard from "components/DesignSystem/ChatCard/v2/ChatCard";
import { Check } from "components/icons/Check";
import { DocumentRequestedHeader } from "components/icons/DocumentRequestedHeader";
import { Files } from "components/icons/Files";
import { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { openRequestDocumentsModal } from "store/slices/requestDocumentsInChat";
import { useChannelStateContext } from "stream-chat-react";
import { pluralize } from "utils/pluralize";
import TinySuccess from "static/images/TinySuccess.svg";
import TicketResolve from "static/images/TicketResolve.svg";

type DocumentRequested = {
  uuid: string;
  type: string;
  is_uploaded: string;
};

type CustomData = {
  header: string;
  metadata: {
    document_request_id: string;
    requested_documents: DocumentRequested[];
  };
};

export const RequestDocumentsChatCard = ({
  children,
  channelId,
  customData,
  groupId,
  size,
}: {
  children: ReactNode;
  customData: CustomData;
  channelId: string;
  groupId: string;
  size?: "regular" | "block";
}) => {
  const { requested_documents, document_request_id } = customData.metadata;

  const dispatch = useDispatch();

  const onUploadClick = () =>
    dispatch(
      openRequestDocumentsModal({
        isModalOpen: true,
        channelId: channelId,
        groupId: groupId,
        documentsRequest: requested_documents,
        requestId: document_request_id,
      })
    );

  const allDocumentsAreUploaded = requested_documents.every(
    (d) => d.is_uploaded
  );

  return (
    <div
      className={classNames("t-flex t-flex-col t-gap-4", {
        "t-w-full": size === "block",
      })}
    >
      <ChatCard.Content addMargin={false}>
        <ChatCard.Header
          icon={
            <span className="t-text-text-30">
              {allDocumentsAreUploaded ? (
                <img src={TicketResolve} alt="TicketResolve" />
              ) : (
                <DocumentRequestedHeader />
              )}
            </span>
          }
        >
          <div className="t-text-subtitle-sm t-text-text-30">
            {allDocumentsAreUploaded
              ? `${pluralize(
                  requested_documents.length,
                  "Requested Doc",
                  "Requested Docs"
                )} Uploaded`
              : customData.header}
          </div>
        </ChatCard.Header>
        <ChatCard.Body>
          {requested_documents.map((document) => (
            <div
              className="t-flex t-gap-3 t-border t-border-dashed t-border-neutral-10 first:t-border-t-0 t-py-3 t-border-b-0 t-border-l-0 t-border-r-0 first:t-pt-0"
              key={document.uuid}
            >
              {!document.is_uploaded ? (
                <div className="t-min-w-1.5 t-min-h-1.5 t-max-h-1.5 t-w-1.5 t-rounded-full t-bg-orange t-mt-1.5" />
              ) : (
                <img
                  src={TinySuccess}
                  alt="TinySuccess"
                  className="t-h-2 t-w-2 t-mt-1.5"
                />
              )}
              <div className="t-text-subtext t-text-text-60">
                {document.type}
              </div>
            </div>
          ))}
          <Button
            block
            onClick={onUploadClick}
            size="small"
            customType={allDocumentsAreUploaded ? "secondary" : "primary"}
          >
            {allDocumentsAreUploaded ? "View" : "Upload"}
          </Button>
        </ChatCard.Body>
      </ChatCard.Content>
      {children}
    </div>
  );
};
