import { DashboardLayout } from "components/DashboardLayout";
import { InvoiceHeader } from "components/design/InvoiceHeader";
import Loader from "components/design/loader";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { CaretRight } from "components/icons/CaretRight";
import { Refresh } from "components/icons/Refresh";
import {
  BILL_STATUS,
  BILL_STATUS_LABEL,
  TAG_TYPE,
  TAX_TYPE,
} from "constants/vendorBills";
import { Form, Formik } from "formik";
import { vendorBillSchema } from "formValidations/vendorBillSchema";
import { useModal } from "hooks/useModal";
import { usePageTitle } from "hooks/usePageTitle";
import { useToast } from "hooks/useToast";
import {
  BillDetails,
  ItemDetails,
  TaxAndDiscount,
  VendorAddress,
  VendorDetails,
} from "pages/Books/BillsAndVendors/BillConsole";
import { BillPreivew } from "pages/Books/BillsAndVendors/BillPreview";
import { useHistory, useParams } from "react-router-dom";
import {
  PublicPayload,
  useGetPubilcBillQuery,
  useUpdatePubilcBillMutation,
} from "store/apis/vendorBills";
import { VendorBill } from "types/Models/vendorBills";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";

const updateInvoiceAmounts = ({
  bill,
  invoiceTotal,
}: {
  bill: VendorBill;
  invoiceTotal: number;
}): {
  discount: number;
  tax: number;
  total: string;
  sub_total: string;
  due_balance: string;
} => {
  let discount: number;
  let tax: number;

  if (bill.discount_type === TAX_TYPE.PERCENT) {
    discount = parseFloat((invoiceTotal * (bill.discount / 100)).toFixed(2));
  } else {
    discount = parseFloat(bill.discount.toFixed(2));
  }

  if (bill.tax_type === TAX_TYPE.PERCENT) {
    tax = parseFloat(((invoiceTotal - discount) * (bill.tax / 100)).toFixed(2));
  } else {
    tax = parseFloat(bill.tax.toFixed(2));
  }

  const sub_total = parseFloat((invoiceTotal - discount + tax).toFixed(2));

  const total = parseFloat((sub_total - Number(bill.paid_amount)).toFixed(2));

  return {
    discount,
    tax,
    sub_total: sub_total.toString(),
    total: total.toString(),
    due_balance: (sub_total - Number(bill.paid_amount || 0)).toString(),
  };
};

export const PublicVendorBill = () => {
  const { billId } = useParams<{ billId: string }>();
  const { alertToast } = useToast();
  const history = useHistory();
  const resetModal = useModal();

  const { data, isLoading, isSuccess, isError, error } = useGetPubilcBillQuery(
    { billId },
    { skip: !billId }
  );

  usePageTitle(
    data?.bill_number ? `Review ${data?.bill_number}` : "Review Bill"
  );

  const [updatePubilcBill, { isLoading: isUpdating }] =
    useUpdatePubilcBillMutation();

  const onNext = async (values: PublicPayload) => {
    try {
      await updatePubilcBill({
        billId,
        payload: values,
      }).unwrap();
      history.push(`/vendor-bill-approve/${billId}`);
    } catch (e) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as Error
      );
    }
  };

  const isApproved = data?.bill_request_status === BILL_STATUS.APPROVED;

  return (
    <Modal.Root open={true} modal={false}>
      <Async.Root
        isLoading={isLoading}
        isSuccess={isSuccess}
        isEmpty={false}
        isError={isError}
        customLoader={
          <Modal.Content size="fullscreen">
            <Loader />
          </Modal.Content>
        }
      >
        <Async.ErrorHandler>
          <Modal.Content size="fullscreen">
            <Modal.Body className="t-min-h-80 t-w-full t-h-full t-flex t-justify-center t-flex-col t-items-center t-gap-2 t-text-subtitle-sm t-text-red">
              <>
                {(error as BackendError)?.data?.error?.message ||
                  "Something went wrong, Please try again later."}
              </>
            </Modal.Body>
          </Modal.Content>
        </Async.ErrorHandler>
        <Async.Empty>
          <></>
        </Async.Empty>
        <Async.Success>
          {data && (
            <Formik<PublicPayload>
              initialValues={data}
              onSubmit={onNext}
              enableReinitialize
              validationSchema={vendorBillSchema}
              validateOnChange
            >
              {({ values, isValid }) => (
                <Modal.Content
                  size="fullscreen"
                  className="t-grid t-grid-cols-2"
                >
                  <Form className="t-overflow-auto">
                    <fieldset
                      disabled={isUpdating || isApproved}
                      className="disabled:t-opacity-60 disabled:t-cursor-not-allowed t-h-full"
                    >
                      <DashboardLayout
                        className="t-pt-6"
                        header={
                          <InvoiceHeader
                            status={
                              <Tag
                                rounded
                                icon={false}
                                tagType={TAG_TYPE[data?.bill_request_status]}
                              >
                                {BILL_STATUS_LABEL[data?.bill_request_status]}
                              </Tag>
                            }
                            title={data?.bill_number}
                          />
                        }
                      >
                        <div className="t-flex t-flex-col t-gap-12 t-pb-40">
                          <VendorDetails disabled bill={values} />
                          <VendorAddress bill={values} />
                          <BillDetails bill={values} />
                          <ItemDetails bill={values} />
                          <TaxAndDiscount bill={values} />
                        </div>
                      </DashboardLayout>
                      {!isApproved && (
                        <Modal.Footer className="t-flex t-gap-2 t-items-center t-justify-end t-fixed t-bottom-0 t-bg-white t-w-1/2 t-p-4 t-border-t">
                          <Modal.Root
                            open={resetModal.isOpen}
                            onOpenChange={resetModal.toggle}
                          >
                            <Modal.Trigger asChild>
                              <Button type="button">
                                <span className="t-flex t-items-center t-gap-1">
                                  <Refresh size="16" />
                                  Reset Changes
                                </span>
                              </Button>
                            </Modal.Trigger>
                            <ResetModal {...resetModal} />
                          </Modal.Root>

                          <Button
                            customType="primary"
                            disabled={isUpdating || !isValid}
                            isLoading={isUpdating}
                            type="submit"
                          >
                            <span className="t-flex t-items-center t-gap-1">
                              Next
                              <CaretRight />
                            </span>
                          </Button>
                        </Modal.Footer>
                      )}
                    </fieldset>
                  </Form>

                  <DashboardLayout className="t-pt-6 t-bg-surface-lighter-grey">
                    <BillPreivew
                      bill={{
                        ...values,
                        invoice_items: values.invoice_items.map((item) => ({
                          ...item,
                          amount: item.rate * item.quantity,
                        })),
                        ...updateInvoiceAmounts({
                          bill: values,
                          invoiceTotal: values.invoice_items.reduce(
                            (acc, item) => item.rate * item.quantity + acc,
                            0
                          ),
                        }),
                      }}
                    />
                  </DashboardLayout>
                </Modal.Content>
              )}
            </Formik>
          )}
        </Async.Success>
      </Async.Root>
    </Modal.Root>
  );
};

const ResetModal = ({ close }: ModalProps) => {
  const { billId } = useParams<{ billId: string }>();
  const { alertToast, successToast } = useToast();

  const [updatePubilcBill, { isLoading: isUpdating }] =
    useUpdatePubilcBillMutation();

  const onReselect = async () => {
    try {
      await updatePubilcBill({
        billId,
        payload: { reset_changes: true },
      }).unwrap();
      successToast({
        message: "Bill reset successfully",
      });
      close();
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as Error
      );
    }
  };

  return (
    <Modal.Content size="small">
      <Modal.Header>
        <Modal.Title>Rest Changes</Modal.Title>
        <Modal.Close />
      </Modal.Header>
      <Modal.Body className="t-text-subtext t-text-text-60 t-text-center">
        All the changes made will be removed and this action
        <br />
        cannot be undone. Are you sure you want to reset?
      </Modal.Body>
      <Modal.FooterButtonGroup>
        <Modal.RawClose asChild>
          <Button disabled={isUpdating}>Cancel</Button>
        </Modal.RawClose>
        <Button
          customType="danger"
          disabled={isUpdating}
          isLoading={isUpdating}
          onClick={onReselect}
        >
          Reset
        </Button>
      </Modal.FooterButtonGroup>
    </Modal.Content>
  );
};
