import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { PriceInput } from "components/PriceInput/PriceInput";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { Field, FieldProps, Form, Formik } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useGetTransaction } from "hooks/useGetTransaction";
import { useToast } from "hooks/useToast";
import { useGetEntityBanksQuery } from "store/apis/bankConnections";
import { useEditTransactionMutation } from "store/apis/transactions";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";

export const EditModal = ({
  isOpen,
  close,
  transactionId,
}: ModalProps & { transactionId: string }) => {
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [editTransaction] = useEditTransactionMutation();

  const { data: banks } = useGetEntityBanksQuery(
    { entityId, groupId },
    { skip: !entityId || !groupId }
  );

  const banksAccounts = banks?.accounts.map(
    ({ account: { mask, nickname, uuid } }) => ({
      value: uuid,
      label: `${nickname} •••• ${mask}`,
    })
  );

  const {
    data: transactionDetail,
    isLoading,
    isSuccess,
  } = useGetTransaction({
    transactionId,
  });

  const state = { isEmpty: false, isLoading, isSuccess };
  const { transaction } = transactionDetail || {};
  const { date, amount, from, description } = transaction || {};

  const selectedBank = banksAccounts?.find(
    ({ value }) => value === from?.bank_account?.uuid
  )!;

  const onSubmit = async (values: {
    date: string;
    amount: number;
    from: string;
    description?: string;
  }) => {
    try {
      await editTransaction({
        entityId,
        groupId,
        transactionId,
        date: dayjs(values.date).format(YYYY_MM_DD),
        amount: values.amount.toString(),
        account_id: values.from,
        description: values.description,
      }).unwrap();
      successToast({ message: "Transaction edited" });
      close();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Formik
        initialValues={{
          date: dayjs(date).format(DD_MMM_YYYY),
          amount: amount || 0,
          from: selectedBank?.value || "",
          description,
        }}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ submitForm, isSubmitting }) => (
          <Form className="t-m-0 t-w-full" onClick={(e) => e.stopPropagation()}>
            <Modal.Content useCustomOverlay>
              <Modal.Header>
                <Modal.Title>Edit transaction</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Async.Root {...state}>
                <Async.Empty>
                  <></>
                </Async.Empty>
                <Async.Success>
                  <Modal.Body className="t-flex t-gap-4 t-flex-col">
                    <div className="t-flex t-gap-3 t-items-center">
                      <PriceInput name="amount" label="Amount" required block />
                      <Field name="date">
                        {({ field }: FieldProps) => {
                          return (
                            <DateInput
                              portalId={field.name}
                              {...field}
                              label="Date"
                              placeholder="Select date"
                              required
                              block
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <Combobox
                      options={banksAccounts}
                      label="Select account"
                      required
                      name="from"
                      withForm
                      placeholder="Select or input account"
                      menuPortalTarget={document.body}
                      block
                      isClearable={false}
                      defaultValue={selectedBank}
                    />
                    <TextArea name="description" label="Description" rows={5} />
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="t-flex t-gap-3 t-justify-end">
                      <Button
                        type="reset"
                        onClick={close}
                        disabled={isSubmitting}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        onClick={submitForm}
                        customType="primary"
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                      >
                        Save Changes
                      </Button>
                    </div>
                  </Modal.Footer>
                </Async.Success>
              </Async.Root>
            </Modal.Content>
          </Form>
        )}
      </Formik>
    </Modal.Root>
  );
};
