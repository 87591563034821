import { DashboardLayout } from "components/DashboardLayout";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import * as PRODUCT_ONBOARDING from "constants/productOnboardings";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { useDispatch } from "react-redux";
import FinancialClosingHome from "static/images/FinancialClosing-Home.svg";
import { useGetOnboardingProductsQuery } from "store/apis/productOnboarding";
import { openOnboardingWidget } from "store/slices/onboardingWidget";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { FinancialClosingListAction } from "components/FinancialClosing/FinancialClosingListAction";
import { FinancialClosingDetail } from "store/apis/financialClosing";
import { TableUI } from "components/design/TableUI";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { Dash } from "components/icons/Dash";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { ComponentProps, useMemo } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import GreenCheck from "static/images/GreenCheck.svg";
import { FileIcon } from "utils/fileTypeIcon";
import { ReviewFinancialsModal } from "./ReviewFinancialsModal";
import { useModal } from "hooks/useModal";
import {
  useAddFinancialClosingMutation,
  useGetAllFinancialClosingQuery,
} from "store/apis/financialClosing";
import { EmptyScreen } from "../EmptyScreen";
import { useToast } from "hooks/useToast";
import { BackendError } from "types/utils/error";
import dayjs from "dayjs";
import { YYYY_MM_DD } from "constants/date";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import EmptyCalender from "static/images/EmptyCalendar.svg";
import { FileObject } from "types/Models/fileObject";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { FinancialClosingDetails } from "./FinancialClosingDetails";
import { LinkIcon } from "components/icons/LinkIcon";
import { CLOSING_STATUS } from "constants/financialClosing";

export const Period = ({
  end_date,
  start_date,
}: {
  start_date: string;
  end_date: string;
}) => {
  const startDate = dayjs(start_date);
  const endDate = dayjs(end_date);
  const isSameYear = startDate.year() === endDate.year();
  const isSameMonth =
    startDate.month() === endDate.month() &&
    startDate.year() === endDate.year();

  if (isSameMonth) {
    return startDate.format("MMM YYYY");
  }
  if (isSameYear) {
    return `${startDate.format("MMM")}-${endDate.format("MMM YYYY")}`;
  }
  return `${startDate.format("MMM YYYY")}-${endDate.format("MMM YYYY")}`;
};

export const TAG_TYPE: Record<string, ComponentProps<typeof Tag>["tagType"]> = {
  [CLOSING_STATUS.REVIEW_PENDING]: "red",
  [CLOSING_STATUS.IN_PROGRESS]: "orange",
  [CLOSING_STATUS.COMPLETED]: "green",
};

export const TAG_LABEL = {
  [CLOSING_STATUS.REVIEW_PENDING]: "Review Pending",
  [CLOSING_STATUS.IN_PROGRESS]: "Ongoing",
  [CLOSING_STATUS.COMPLETED]: "Done",
};

export const ClosingStatus = ({
  status,
}: {
  status: FinancialClosingDetail["status"];
}) => {
  return (
    <Tag tagType={TAG_TYPE[status]} icon={false}>
      {TAG_LABEL[status]}
    </Tag>
  );
};

const Action = ({
  financialClosingItem,
}: {
  financialClosingItem: FinancialClosingDetail;
}) => {
  const disabled = financialClosingItem.status === CLOSING_STATUS.COMPLETED;
  const reviewModal = useModal();
  const { isCustomer } = useRoleBasedView();

  if (
    financialClosingItem.status === CLOSING_STATUS.REVIEW_PENDING &&
    isCustomer
  ) {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Button
          customType="primary"
          size="small"
          disabled={disabled}
          onClick={reviewModal.open}
        >
          Review
        </Button>
        <ReviewFinancialsModal
          isOpen={reviewModal.isOpen}
          close={reviewModal.close}
          financialClosingId={financialClosingItem.uuid}
        />
      </div>
    );
  }

  if (financialClosingItem.status === CLOSING_STATUS.COMPLETED) {
    return (
      <div className="t-flex t-gap-1 t-text-body-sm t-text-text-30 t-items-center">
        <img src={GreenCheck} alt="Reviewed" className="t-w-4 t-h-4" />
        Reviewed
      </div>
    );
  }

  return (
    <div className="t-text-text-30">
      <Dash />
    </div>
  );
};

const Financial = ({
  file,
  link,
}: {
  file: FileObject | null;
  link: string | null;
}) => {
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const [downloadFile, { isLoading: isDownloading }] =
    useLazyGetPreviewUrlQuery();

  const onDownloadFile = async (fileId: string) => {
    try {
      const { download_url } = await downloadFile({ fileId, groupId }).unwrap();
      window.open(download_url, "_blank");
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as Error
      );
    }
  };

  if (file) {
    return (
      <Button
        type="button"
        customType="ghost_icon"
        size="small"
        isLoading={isDownloading}
        disabled={isDownloading}
        onClick={(e) => {
          e.stopPropagation();
          onDownloadFile(file.uuid!);
        }}
      >
        <FileIcon fileType={file?.file_type} width={20} height={20} />
      </Button>
    );
  }

  return (
    <>
      {link && (
        <Button
          type="button"
          customType="ghost_icon"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            window.open(link, "_blank");
          }}
        >
          <LinkIcon />
        </Button>
      )}
    </>
  );
};

const BooksOnboarding = () => {
  const dispatch = useDispatch();

  return (
    <div className="t-w-full t-h-full t-flex t-flex-col t-gap-12 t-justify-center">
      <div className="t-flex t-justify-center t-w-full t-h-[20%]">
        <img src={FinancialClosingHome} alt="Financials" />
      </div>
      <div className="t-flex t-justify-center">
        <div className="t-flex t-flex-col t-gap-4 t-justify-center t-items-center">
          <div className="t-text-subtitle-sm">Financial Closing</div>
          <div className="t-text-body t-text-text-30 t-w-[60%] t-flex t-flex-wrap t-justify-around t-items-center t-text-center">
            Complete onboarding to view and track updates of your bookkeeping
            process.
          </div>
          <Button
            customType="primary"
            size="small"
            onClick={() =>
              dispatch(openOnboardingWidget(PRODUCT_ONBOARDING.BOOKS))
            }
          >
            Complete onboarding
          </Button>
        </div>
      </div>
    </div>
  );
};

export const FinancialClosing = () => {
  usePageTitle("Financial Closing");
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const history = useHistory();
  const { link } = useConstructInternalLink();
  const { alertToast } = useToast();
  const { search } = history.location;

  const {
    data: products,
    isLoading,
    isSuccess,
  } = useGetOnboardingProductsQuery(
    {
      groupId: groupId!,
    },
    { skip: !groupId }
  );

  const platformOnboarding = products?.find(
    (p) => p.name === PRODUCT_ONBOARDING.BOOKS
  );

  const isBooksOnboardingComplete =
    platformOnboarding && platformOnboarding.status === "COMPLETED";

  const [addFinancial, { isLoading: isAdding }] =
    useAddFinancialClosingMutation();

  const goToDetails = (uuid: string) => {
    history.push(
      link(`/${uuid}`, {
        includeUrl: true,
      })
    );
  };

  const onAddFinancial = async () => {
    try {
      const { uuid } = await addFinancial({
        entityId,
        payload: {
          accounting_method: "CASH",
          start_date: dayjs().startOf("month").format(YYYY_MM_DD),
          end_date: dayjs().endOf("month").format(YYYY_MM_DD),
        },
      }).unwrap();
      goToDetails(uuid);
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as Error
      );
    }
  };

  const { data: financialClosings = [], ...financialClosingsStates } =
    useGetAllFinancialClosingQuery(
      { entityId },
      {
        skip: !entityId,
      }
    );

  const isEmpty = financialClosings.length === 0;
  const createColumn = createColumnHelper<FinancialClosingDetail>();

  const invoiceColumns = useMemo(
    () => [
      createColumn.accessor("period", {
        header: "PERIOD",
        size: 25,
        cell: (info) => {
          return (
            <div className="t-flex t-flex-col t-gap-0.5">
              <div className="t-text-subtitle-sm t-text-text-60">
                <Period
                  end_date={info.row.original.end_date}
                  start_date={info.row.original.start_date}
                />
              </div>
              <div className="t-text-overline t-text-text-30">
                {info.row.original.accounting_method} METHOD
              </div>
            </div>
          );
        },
      }),

      createColumn.accessor("status", {
        header: "CLOSING STATUS",
        size: 20,
        cell: (info) => {
          const status = info.row.original.status;

          return <ClosingStatus status={status} />;
        },
      }),

      createColumn.accessor("checklist_status", {
        header: "CHECKLIST",
        size: 15,
        cell: (info) => {
          const checklist_status = info.getValue();

          return (
            <span className="t-text-body-sm t-text-text-60">{`${checklist_status.completed}/${checklist_status.total}`}</span>
          );
        },
      }),

      createColumn.accessor("uuid", {
        header: "FINANCIALS",
        size: 20,
        cell: (info) => {
          const {
            cash_flow_statement,
            balance_sheet,
            income_statement,
            cash_flow_statement_url,
            balance_sheet_url,
            income_statement_url,
          } = info.row.original;

          if (
            !cash_flow_statement &&
            !balance_sheet &&
            !income_statement &&
            !cash_flow_statement_url &&
            !balance_sheet_url &&
            !income_statement_url
          ) {
            return (
              <div className="t-text-text-30">
                <Dash />
              </div>
            );
          }

          return (
            <div className="t-flex t-gap-2 t-items-center">
              {(cash_flow_statement || cash_flow_statement_url) && (
                <ConditionalToolTip condition="Cash flow">
                  <span>
                    <Financial
                      file={cash_flow_statement}
                      link={cash_flow_statement_url}
                    />
                  </span>
                </ConditionalToolTip>
              )}

              {(balance_sheet || balance_sheet_url) && (
                <ConditionalToolTip condition="Balance sheet">
                  <span>
                    <Financial file={balance_sheet} link={balance_sheet_url} />
                  </span>
                </ConditionalToolTip>
              )}

              {(income_statement || income_statement_url) && (
                <ConditionalToolTip condition="Income statement">
                  <span>
                    <Financial
                      file={income_statement}
                      link={income_statement_url}
                    />
                  </span>
                </ConditionalToolTip>
              )}
            </div>
          );
        },
      }),

      createColumn.accessor("status", {
        header: "ACTION",
        size: 15,
        cell: (info) => {
          return <Action financialClosingItem={info.row.original} />;
        },
      }),

      createColumn.accessor("uuid", {
        header: "",
        size: 5,
        cell: (info) => {
          const financialClosingItem = info.row.original;
          return (
            <FinancialClosingListAction
              financialClosingItem={financialClosingItem}
            />
          );
        },
      }),
    ],
    []
  );

  const table = useReactTable({
    data: financialClosings,
    columns: invoiceColumns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 5,
    },
  });

  const { url } = useRouteMatch();

  return (
    <>
      <Async.Root
        {...{ isLoading, isEmpty: !isBooksOnboardingComplete, isSuccess }}
      >
        <Async.Empty>
          <DashboardLayout header={<Header v2 title="Financial Closing" />}>
            <div className="t-flex t-flex-col t-gap-6 t-px-5 t-pb-1 t-h-full">
              <BooksOnboarding />
            </div>
          </DashboardLayout>
        </Async.Empty>
        <Async.Success>
          <DashboardLayout
            header={
              <Header
                v2
                title="Financial Closing"
                right={
                  <>
                    {!isEmpty && (
                      <Button
                        customType="primary"
                        size="small"
                        onClick={onAddFinancial}
                        disabled={isAdding}
                        isLoading={isAdding}
                      >
                        Add financials
                      </Button>
                    )}
                  </>
                }
              />
            }
          >
            <Async.Root
              isEmpty={isEmpty}
              isLoading={financialClosingsStates.isLoading}
              isSuccess={financialClosingsStates.isSuccess}
            >
              <Async.Empty>
                <EmptyScreen
                  className="t-h-[calc(100%-64px)]"
                  text={
                    <span className="t-text-body-sm t-text-text-30">
                      Add financials and close tasks periodically for accurate
                      accounting
                    </span>
                  }
                  cta={
                    <Button
                      customType="primary"
                      size="small"
                      onClick={onAddFinancial}
                      disabled={isAdding}
                      isLoading={isAdding}
                    >
                      Add financials
                    </Button>
                  }
                >
                  <img src={EmptyCalender} alt="EmptyCalender" />
                </EmptyScreen>
              </Async.Empty>
              <Async.Success>
                <TableUI
                  table={table}
                  onRowClick={(row) => {
                    goToDetails(row.original.uuid);
                  }}
                />
              </Async.Success>
            </Async.Root>
          </DashboardLayout>
        </Async.Success>
      </Async.Root>
      <Switch>
        <Route exact path={`${url}/:financialClosingId`}>
          <FinancialClosingDetails
            close={() => {
              history.push(`${url}${search}`);
            }}
            breadcrumbs={[
              {
                name: "Financial closing",
                link: `${url}${search}`,
              },
            ]}
          />
        </Route>
      </Switch>
    </>
  );
};
