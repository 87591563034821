import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { CLOSING_STATUS } from "constants/financialClosing";
import {
  Period,
  TAG_TYPE,
} from "pages/Books/FinancialClosing/FinancialClosing";
import { FinancialClosingTracker } from "types/Models/group";

export const CompletionTracker = ({
  completionTrackerData,
}: {
  completionTrackerData: FinancialClosingTracker;
}) => {
  const TAG_LABEL = {
    [CLOSING_STATUS.REVIEW_PENDING]: "Review Pending",
    [CLOSING_STATUS.IN_PROGRESS]: "Ongoing",
    [CLOSING_STATUS.COMPLETED]: "Done",
  };
  return (
    <div className="t-rounded-lg t-border-neutral-10 t-border t-border-solid t-flex-1">
      <div className="!t-border-b t-border-0 t-border-neutral-10 t-p-4 t-border-solid t-text-subtitle">
        Completion Tracker
      </div>
      <div className="t-p-4 t-grid t-grid-cols-2 t-gap-6">
        {completionTrackerData.map((item, index) => (
          <InfoItem
            key={index}
            label={
              <Period end_date={item.end_date} start_date={item.start_date} />
            }
          >
            <Tag tagType={TAG_TYPE[item.status]} icon={false}>
              {TAG_LABEL[item.status]}
            </Tag>
          </InfoItem>
        ))}
      </div>
    </div>
  );
};
