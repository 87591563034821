import React from "react";

export const DataRequestedHeader = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M6.00002 10.6673H10V12.0006H6.00002V10.6673ZM9.19535 4.5293L8.00002 5.72463L6.80469 4.5293L5.86202 5.47196L7.05735 6.6673L5.86202 7.86263L6.80469 8.8053L8.00002 7.60996L9.19535 8.8053L10.138 7.86263L8.94269 6.6673L10.138 5.47196L9.19535 4.5293ZM11.3334 12.0006H15.3334V10.6673H11.3334V12.0006ZM15.4714 5.47196L14.5287 4.5293L13.3334 5.72463L12.138 4.5293L11.1954 5.47196L12.3907 6.6673L11.1954 7.86263L12.138 8.8053L13.3334 7.60996L14.5287 8.8053L15.4714 7.86263L14.276 6.6673L15.4714 5.47196ZM0.666687 12.0006H4.66669V10.6673H0.666687V12.0006ZM3.86202 4.5293L2.66669 5.72463L1.47135 4.5293L0.528687 5.47196L1.72402 6.6673L0.528687 7.86263L1.47135 8.8053L2.66669 7.60996L3.86202 8.8053L4.80469 7.86263L3.60935 6.6673L4.80469 5.47196L3.86202 4.5293Z"
      fill="currentColor"
    />
  </svg>
);
