import classNames from "classnames";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { PaymentFailedCard } from "components/icons/PaymentFailedCard";
import { PaymentSuccessCard } from "components/icons/PaymentSuccessCard";
import dayjs from "dayjs";
import { stat } from "fs";
import React from "react";
import { RAMailroomPaymentHistoryType } from "store/apis/RAMailroom";
import { formatDate } from "utils/formatDate";

const titleMap = {
  SUCCESS: "Paid Successfully",
  FAILED: "Payment Declined",
};

const eventIconMap = {
  SUCCESS: <PaymentSuccessCard />,
  FAILED: <PaymentFailedCard />,
};

export const RAMailroomPaymentHistory = ({
  paymentHistory,
}: {
  paymentHistory: RAMailroomPaymentHistoryType[];
}) => {
  if (paymentHistory.length === 0) {
    return (
      <div className="t-text-subtext t-text-text-60 t-text-center t-my-6">
        No payment history available
      </div>
    );
  }

  return paymentHistory.map((data, index) => (
    <div
      className="t-border t-border-solid t-border-r-0 t-border-t-0 t-border-b-0 t-border-neutral-10 t-flex t-relative t-ml-1.5 last:t-border-l-0"
      key={index}
    >
      <div className="t-bg-surface-lighter-grey t-mb-1 t-max-h-6 t-absolute -t-ml-[10px] -t-mt-1">
        {/* @ts-ignore */}
        {eventIconMap[data.status]}
      </div>
      <div
        className={classNames("t-ml-5", {
          "t-mb-7": index !== paymentHistory.length - 1,
        })}
      >
        <div className="t-mb-1 t-text-subtext-sm t-text-text-60 t-flex t-items-center t-gap-1">
          <b className="t-text-subtext-sm t-font-bold">
            <AmountSuperScript amount={Number(data.amount)} />
          </b>{" "}
          {/* @ts-ignore */}
          <div>{titleMap[data.status]} for</div>
          <div>{dayjs(data.created_at).format("MMMM YYYY")}</div>
        </div>

        <div className="t-text-body-sm t-text-text-60">
          {formatDate(data.created_at)}
        </div>
      </div>
    </div>
  ));
};
