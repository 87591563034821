import classNames from "classnames";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Chip } from "components/DesignSystem/Chips/Chips";
import { Filter } from "components/DesignSystem/Filter/Filter";
import RadioGroup from "components/DesignSystem/RadioGroup/RadioGroup";
import Slider from "components/DesignSystem/Slider/Slider";
import { SliderAccordion } from "components/DesignSystem/SliderAccordion/SliderAccordion";
import { Label } from "components/DesignSystem/TextInput/TextInput";
import { FilePreviewTag } from "components/FilePreviewTag/FilePreviewTag";
import { DateFilter } from "components/Filters/DateFilter";
import { DownloadIcon } from "components/icons/Download";
import { EmptyStatements } from "components/icons/EmptyStatements";
import { Sync } from "components/icons/Sync";
import { MMM_YYYY, YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useFilters } from "hooks/useFilter";
import { useToast } from "hooks/useToast";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import React, { Fragment } from "react";
import {
  useGetBankAccountStatementsQuery,
  useRefreshAccountStatementsMutation,
} from "store/apis/bankConnections";
import { ConnectionBankAccount } from "store/apis/booksConnections";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { FileObject } from "types/Models/fileObject";
import { BackendError } from "types/utils/error";
import { pluralize } from "utils/pluralize";

const SORT_BY = {
  LATEST: "LATEST",
  OLD_NEW: "OLD_NEW",
};

const SORT_ORDER = {
  [SORT_BY.LATEST]: "ASC",
  [SORT_BY.OLD_NEW]: "DSC",
} as const;

const Statement = ({ statement }: { statement: FileObject }) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const [getPreviewUrl, { isFetching }] = useLazyGetPreviewUrlQuery();
  const { alertToast } = useToast();

  const onDownload = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    try {
      const { download_url } = await getPreviewUrl({
        groupId,
        fileId: statement.uuid,
      }).unwrap();

      window.open(download_url, "_blank");
    } catch (error) {
      alertToast(
        { message: (error as BackendError).data?.error?.message },
        error as Error
      );
    }
  };

  return (
    <FilePreviewTag file={statement} block size="small">
      <span className="t-text-text-30">
        <Button
          customType="ghost_icon"
          size="small"
          onClick={onDownload}
          disabled={isFetching}
          isLoading={isFetching}
        >
          <DownloadIcon color="currentColor" />
        </Button>
      </span>
    </FilePreviewTag>
  );
};

export const BankStatementSlider = ({
  account,
}: {
  account: ConnectionBankAccount;
}) => {
  const { values, updateFilter } = useFilters({
    initialValue: {
      START_DATE: "",
      END_DATE: "",
      SELECT_PERIOD: "",
      SORT_BY: SORT_BY.LATEST,
    },
  });
  const { alertToast, successToast } = useToast();
  const entityId = useCurrentEntityId();
  const [openFilter, setOpenFilter] = React.useState(false);
  const isDateFileterApplied = values.START_DATE && values.END_DATE;

  const {
    data: statements = [],
    isLoading,
    isSuccess,
  } = useGetBankAccountStatementsQuery(
    {
      entityId,
      entityBankAccountId: account.uuid,
      ...(isDateFileterApplied
        ? {
            startDate: dayjs(values.START_DATE)
              .startOf("month")
              .format(YYYY_MM_DD),
            endDate: dayjs(values.END_DATE).endOf("month").format(YYYY_MM_DD),
          }
        : {}),
      sortBy: SORT_ORDER[values.SORT_BY],
    },
    {
      skip: !entityId || !account.uuid,
    }
  );

  const [refreshAccountStatements, { isLoading: isRefreshing }] =
    useRefreshAccountStatementsMutation();

  const onRefresh = async () => {
    try {
      await refreshAccountStatements({
        entityId,
        entityBankAccountId: account.uuid,
      }).unwrap();
      successToast({ message: "Statements refreshed" });
    } catch (error) {
      alertToast(
        { message: (error as BackendError).data?.error?.message },
        error as Error
      );
    }
  };

  return (
    <>
      <Slider.Header>
        <div>
          <Slider.Title>Bank Statement</Slider.Title>
          <Slider.Description className="t-text-body-sm t-text-text-30">
            {account.name} {account.account_number}
          </Slider.Description>
        </div>
        <Slider.Close />
      </Slider.Header>
      <Slider.Body className="t-flex t-flex-col t-gap-6">
        <div className="t-flex t-flex-col t-gap-4">
          <Filter.Root
            open={openFilter}
            onOpenChange={setOpenFilter}
            defaultValue="DATE_RANGE"
            capsule={
              <>
                {values.START_DATE && values.END_DATE && (
                  <Chip
                    onClose={() => {
                      updateFilter("START_DATE", "");
                      updateFilter("END_DATE", "");
                    }}
                    isActive
                    filterType="DATE_RANGE"
                  >
                    {dayjs(values.START_DATE).format(MMM_YYYY)} -{" "}
                    {dayjs(values.END_DATE).format(MMM_YYYY)}
                  </Chip>
                )}

                {values.SORT_BY && (
                  <Chip
                    onClose={() => {}}
                    isRemovable={false}
                    isActive
                    filterType="SORT_BY"
                  >
                    {values.SORT_BY === SORT_BY.LATEST ? "Latest" : "Old - New"}
                  </Chip>
                )}
                <div></div>
              </>
            }
          >
            <Filter.Portal size="small">
              <Filter.List>
                <Filter.ListItem value="SORT_BY">Sort by</Filter.ListItem>
                <Filter.ListItem value="DATE_RANGE">Date Range</Filter.ListItem>
              </Filter.List>

              <Filter.Body value="DATE_RANGE" block>
                <DateFilter
                  values={values}
                  updateFilter={updateFilter}
                  showPeriodSelector={false}
                  pickerType="month"
                />
              </Filter.Body>
              <Filter.Body value="SORT_BY" block>
                <Label htmlFor="SORT_BY">Sort by</Label>
                <RadioGroup.Root
                  id="SORT_BY"
                  onValueChange={(value: typeof values.SORT_BY) => {
                    updateFilter("SORT_BY", value);
                  }}
                  value={values.SORT_BY}
                >
                  <RadioGroup.Content>
                    <RadioGroup.Item value={SORT_BY.LATEST}>
                      Latest
                    </RadioGroup.Item>
                    <RadioGroup.Item value={SORT_BY.OLD_NEW}>
                      Old - New
                    </RadioGroup.Item>
                  </RadioGroup.Content>
                </RadioGroup.Root>
              </Filter.Body>
            </Filter.Portal>
          </Filter.Root>
        </div>
        <div className="t-flex t-gap-4 t-flex-col t-w-full">
          <div className="t-text-text-30 t-text-subtext t-flex t-gap-2 t-items-center t-justify-between t-w-full">
            <span>
              {pluralize(statements.length, "Statement", "Statements")}
            </span>
            <Button size="small" onClick={onRefresh} disabled={isRefreshing}>
              <div className="t-flex t-gap-1.5 t-text-text-60">
                Refresh
                <div
                  className={classNames({
                    "t-animate-spin": isRefreshing,
                  })}
                >
                  <Sync />
                </div>
              </div>
            </Button>
          </div>
          <Async.Root
            isEmpty={statements.length === 0}
            isLoading={isLoading}
            isSuccess={isSuccess}
          >
            <Async.Empty>
              <EmptyScreen
                text="No Statements Found"
                cta={
                  <>
                    {isDateFileterApplied && (
                      <div className="t-flex t-flex-col t-gap-4 t-items-center t-text-body t-text-text-60">
                        Try editing the date range
                        <Button
                          size="small"
                          onClick={() => setOpenFilter(true)}
                        >
                          Edit Date Range
                        </Button>
                      </div>
                    )}
                  </>
                }
              >
                <div className="t-text-text-30 t-p-5 t-bg-surface-grey t-rounded-full">
                  <EmptyStatements />
                </div>
              </EmptyScreen>
            </Async.Empty>
            <Async.Success>
              <SliderAccordion.Root
                type="multiple"
                defaultValue={statements.map(({ uuid }) => uuid)}
                className="t-flex t-gap-4 t-flex-col t-w-full"
              >
                {statements.map(
                  ({ uuid, end_balance, end_date, statement }) => (
                    <SliderAccordion.Item value={uuid} key={uuid}>
                      <SliderAccordion.Trigger>
                        {dayjs(end_date).format(MMM_YYYY)}
                      </SliderAccordion.Trigger>
                      <SliderAccordion.Content>
                        {statement ? (
                          <Statement statement={statement} />
                        ) : (
                          <SliderAccordion.InfoItem label="Closing Balance">
                            {end_balance && (
                              <AmountSuperScript amount={end_balance} />
                            )}
                          </SliderAccordion.InfoItem>
                        )}
                      </SliderAccordion.Content>
                    </SliderAccordion.Item>
                  )
                )}
              </SliderAccordion.Root>
            </Async.Success>
          </Async.Root>
        </div>
      </Slider.Body>
    </>
  );
};
