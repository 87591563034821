import Async from "components/DesignSystem/AsyncComponents/Async";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import { useEffect, useState } from "react";
import { SingleValue } from "react-select";
import {
  useGetGroupAccountOverviewQuery,
  useGetGroupDataQuery,
} from "store/apis/group";
import { Group } from "types/Models/group";
import { BooksProfile } from "./AccountInfoSections/BooksProfile";
import CompanyProfile from "./AccountInfoSections/CompanyProfile";
import { CompletionTracker } from "./AccountInfoSections/CompletionTracker";
import { RecurringFilings } from "./AccountInfoSections/RecurringFilings";
import { SupportOverview } from "./AccountInfoSections/SupportOverview";
import { Tasks } from "./AccountInfoSections/Tasks";
import { TaxProfile } from "./AccountInfoSections/TaxProfile";
import { EntitySelector } from "components/EntitySelector/EntitySelector";

export const AccountInfoContent = ({ group }: { group: Group }) => {
  let { data: groupDetails, isLoading: isGettingEntities } =
    useGetGroupDataQuery({ groupId: group.uuid }, { skip: !group.uuid });
  const entityId = useCurrentEntityId();
  const [selectedEntity, setSelectedEntity] = useState<string | null>(
    entityId || groupDetails?.entities?.[0]?.uuid || null
  );
  const currentYear = new Date().getFullYear();
  const startYear = 2022;
  const [season, setSeason] = useState<string | null>(
    (currentYear - 1).toString()
  );

  let {
    data: groupData,
    isLoading,
    isSuccess,
  } = useGetGroupAccountOverviewQuery(
    { entityId: selectedEntity!, season },
    { skip: !selectedEntity, refetchOnMountOrArgChange: true }
  );

  const seasons = Array.from(
    { length: currentYear - startYear + 1 },
    (_, i) => currentYear - i
  );

  useEffect(() => {
    setSelectedEntity(entityId || groupDetails?.entities?.[0]?.uuid || null);
  }, [groupDetails?.entities]);

  const isEmpty = !groupData;

  const entityOptions = groupDetails?.entities?.map((entity) => ({
    value: entity.uuid,
    label: entity.name,
  }));

  return (
    <div>
      <div className="t-flex t-gap-4">
        <Combobox
          menuPlacement="auto"
          menuPortalTarget={document.body}
          tooltip
          creatable={false}
          isClearable={false}
          isDisabled={isGettingEntities}
          isLoading={isGettingEntities}
          options={entityOptions}
          onChange={(value: any) => setSelectedEntity(value.value)}
          styles={{
            menu: (base) => ({
              ...base,
              width: "230px",
              zIndex: 1210,
            }),
          }}
          value={
            entityOptions?.find((entity) => entity.value === selectedEntity) ||
            null
          }
        />

        <Combobox
          menuPortalTarget={document.body}
          name="season"
          withForm={false}
          components={{
            ClearIndicator: () => null,
          }}
          label=""
          value={
            season
              ? {
                  value: season,
                  label: season,
                }
              : null
          }
          options={seasons?.map((season: number) => ({
            value: String(season),
            label: season,
          }))}
          onChange={(selectedOption) =>
            setSeason(
              (selectedOption as SingleValue<{ value: string }>)?.value || null
            )
          }
        />
      </div>
      <Async.Root
        isLoading={isLoading}
        isError={!isSuccess}
        isSuccess={isSuccess}
        isEmpty={isEmpty}
      >
        <Async.Empty>
          <EmptyScreen text="No data available" />
        </Async.Empty>
        <Async.Success>
          {groupData && (
            <div className="t-mt-6 t-grid t-grid-cols-3 t-gap-3">
              <div className="t-w-full">
                {selectedEntity && (
                  <CompanyProfile
                    companyData={groupData.company_profile}
                    entityCount={groupDetails?.entities?.length}
                    selectedEntity={selectedEntity}
                  />
                )}
              </div>
              <div className="t-flex t-flex-col t-gap-3 t-w-full">
                <TaxProfile taxData={groupData.tax_profile} />
                <RecurringFilings
                  recurringFilingsData={groupData.recurring_filings}
                />
                <BooksProfile booksData={groupData.books_profile} />
                <Tasks taskData={groupData.tasks_status} />
              </div>
              <div className="t-flex t-flex-col t-gap-3 t-w-full">
                <SupportOverview groupData={groupData} />
                <CompletionTracker
                  completionTrackerData={
                    groupData.financial_closing_completion_tracker
                  }
                />
              </div>
            </div>
          )}
        </Async.Success>
      </Async.Root>
    </div>
  );
};
