import cx from "classnames";
import React, { ReactNode } from "react";

import "static/styles/components/design/divider.css";

type DividerProps = {
  children?: ReactNode;
  color?: "grey" | "light-grey" | "dark-grey";
};

export const Divider = ({
  children,
  color = "grey",
}: DividerProps): JSX.Element => {
  return (
    <div className="custom-divider t-flex t-items-center t-justify-center">
      <div
        className={cx(
          "t-w-full t-border-b t-border-t-0 t-border-solid t-border-neutral-0",
          {
            "!t-border-neutral-10": color === "grey",
            "!t-border-neutral-20": color === "dark-grey",
          }
        )}
      ></div>
      {children && (
        <>
          <p
            className={cx("t-mx-3 t-mb-0", {
              "t-text-neutral-10": color === "grey",
              "t-text-neutral-20": color === "dark-grey",
            })}
          >
            {children}
          </p>
          <div
            className={cx(
              "t-w-full t-border-b t-border-t-0 t-border-solid t-border-neutral-0",
              {
                "!t-border-neutral-10": color === "grey",
                "!t-border-neutral-20": color === "dark-grey",
              }
            )}
          ></div>
        </>
      )}
    </div>
  );
};
