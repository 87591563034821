import classNames from "classnames";
import { AdminCallCardStatusType } from "components/ChatCard/AdminCallCard/AdminCallCard";
import {
  CALL_CONNECTED,
  CALL_DISCONNECTED,
  CALL_NO_RESPONSE,
} from "constants/CallAdmin";
import {
  TICKET_CREATED,
  TICKET_RESOLVED,
  TICKET_PAYMENT,
  TICKET_UPDATED,
  TICKET_PAID,
} from "constants/ticket";
import { ReactNode } from "react";

export type TicketStatusType =
  | "IN_PROGRESS"
  | "COMPLETED"
  | typeof TICKET_CREATED
  | typeof TICKET_RESOLVED
  | typeof TICKET_PAYMENT
  | typeof TICKET_UPDATED
  | typeof TICKET_PAID
  | typeof CALL_NO_RESPONSE
  | typeof CALL_CONNECTED
  | typeof CALL_DISCONNECTED;

const headerIconBgColor = {
  IN_PROGRESS: "t-bg-yellow-30",
  COMPLETED: "t-bg-yellow-30",
  [TICKET_CREATED]: "t-bg-neutral-10",
  [TICKET_RESOLVED]: "t-bg-green-30",
  [TICKET_PAYMENT]: "t-bg-red-20",
  [TICKET_UPDATED]: "t-bg-yellow-30",
  [TICKET_PAID]: "t-bg-green-30",
  [CALL_NO_RESPONSE]: "",
  [CALL_CONNECTED]: "",
  [CALL_DISCONNECTED]: "",
  "": "t-bg-purple-30",
};

const Header = ({
  icon,
  children,
}: {
  icon?: ReactNode;
  children: ReactNode;
}) => (
  <div className="t-border-neutral-0 t-border-b t-border-solid t-w-full t-flex t-items-center t-justify-between t-p-3 t-gap-1.5 t-border-0 t-bg-neutral-0 t-rounded-t">
    {icon && icon}
    <span className="t-text-subtext t-text-text-100 t-w-full">{children}</span>
  </div>
);

const Content = ({
  addMargin = true,
  children,
}: {
  addMargin?: boolean;
  children: ReactNode;
}) => (
  <div
    className={classNames(
      "t-bg-surface t-border t-border-neutral-10 t-border-solid t-rounded-lg t-w-[288px]",
      {
        "t-mt-1.5 t-mx-1": addMargin,
      }
    )}
  >
    {children}
  </div>
);

const Body = ({ children }: { children: ReactNode }) => (
  <div className="t-p-3">{children}</div>
);

const ChatCard = { Header, Content, Body };

export default ChatCard;
