import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AttachmentType } from "components/MessageBubble/Attachment";
import { StreamMessage } from "stream-chat-react";
import { ChatTicket } from "types/Models/chatTicket";
import * as FILE_TYPE from "constants/fileTypes";
import internal from "stream";

const emptyTicket = {
  uuid: "",
  assignee: [{ assignee_name: "", assignee_uuid: "" }],
  ticket_comments: null,
  priority: "",
  assigned_by: "",
  created_on: "",
  message_id: "",
  channel_url: "",
  resolved: false,
  name: "",
  ticket_title: "",
  channel_name: "",
  is_priced_ticket: false,
  ticket_price: "",
  resolved_at: "",
  does_contain_notes: false,
  is_escalated: false,
  ticket_payments: [
    {
      is_price_finalized: false,
    },
  ],
  internal_notes_count: 0,
  etd_date: null,
};

interface DeleteMessageData {
  message: StreamMessage | { id: string };
  channelId: string;
  showDeleteMessageModal: boolean;
}

export interface previewData {
  file_type: keyof typeof FILE_TYPE;
  is_previewable: boolean;
  name: string;
  task_document_map_id: string[];
  task_document_type: string;
  uuid: string;
}

export interface docReviewPayload {
  amount?: string;
  description?: string;
  document_operation: string;
}

export const initialDocumentReviewPayload = {
  amount: "",
  description: "",
  document_operation: "",
};

export interface FloatingChatState {
  isOpen: boolean;
  defaultChannel?: string;
  unreadMessageCount: number;
  channelEntity: any;
  channelTaskId: string;
  selectedTransaction: null | {
    uuid: string;
    merchant?: string;
    amount?: number;
    category?: string;
    description?: string;
    date?: string;
    transaction_status?: string;
    state?: string;
    bank_logo?: string;
  };
  messageType: string | null;
  adminAssigneeMembers: { name: string; uuid: string }[];
  selectedTicket: ChatTicket;
  files: File[] | null;
  previewFiles: { index?: number; files: AttachmentType[] };
  toOpenChatId: string;
  isMessagesOpened: boolean;
  deleteMessageData: DeleteMessageData;
  activeChannelId: string;
  isMeetWithTranqModalOpen: string;
  populateTransactionIDsList: string;
  messageIdForTransactionList: string;
}

const initialState: FloatingChatState = {
  isOpen: false,
  defaultChannel: "",
  unreadMessageCount: 0,
  channelEntity: {},
  channelTaskId: "",
  selectedTransaction: null,
  messageType: null,
  adminAssigneeMembers: [],
  selectedTicket: emptyTicket,
  files: null,
  previewFiles: { index: 0, files: [] },
  toOpenChatId: "",
  isMessagesOpened: false,
  deleteMessageData: {
    message: { id: "" },
    channelId: "",
    showDeleteMessageModal: false,
  },
  activeChannelId: "",
  isMeetWithTranqModalOpen: "",
  populateTransactionIDsList: "",
  messageIdForTransactionList: "",
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    openFloatingChat: (state) => {
      state.isOpen = true;
    },
    closeFloatingChat: (state) => {
      state.isOpen = false;
    },
    setDefaultChannel: (state, action: PayloadAction<string>) => {
      state.defaultChannel = action.payload;
    },
    addTransactionMessagePreview: (
      state,
      action: PayloadAction<FloatingChatState["selectedTransaction"]>
    ) => {
      state.selectedTransaction = action.payload;
    },
    setEntityAndTaskId: (
      state,
      action: PayloadAction<{
        channelTaskId: string;
        channelEntity: any;
      }>
    ) => {
      state.channelTaskId = action.payload.channelTaskId;
      state.channelEntity = action.payload.channelEntity;
    },
    setMessageType: (state, action: PayloadAction<string>) => {
      state.messageType = action.payload;
    },
    setAdminAssigneeMembers: (
      state,
      action: PayloadAction<{ name: string; uuid: string }[]>
    ) => {
      state.adminAssigneeMembers = action.payload;
    },
    setSelectedChatTicket: (state, action: PayloadAction<ChatTicket>) => {
      state.selectedTicket = action.payload;
    },
    setDropedFile: (
      state,
      action: PayloadAction<FloatingChatState["files"]>
    ) => {
      state.files = action.payload;
    },
    setPreviewFiles: (
      state,
      action: PayloadAction<FloatingChatState["previewFiles"]>
    ) => {
      const { index = 0 } = action.payload;
      state.previewFiles = { index, ...action.payload };
    },

    closePreview: (state) => {
      state.previewFiles = initialState.previewFiles;
    },

    setToOpenChatId: (state, action: PayloadAction<string>) => {
      state.toOpenChatId = action.payload;
      state.isMessagesOpened = true;
    },
    removeToOpenChatUrl: (state) => {
      state.toOpenChatId = "";
      state.isMessagesOpened = false;
    },
    setDeleteMessageData: (state, action: PayloadAction<DeleteMessageData>) => {
      state.deleteMessageData = action.payload;
    },
    setChannelTaskId: (state, action: PayloadAction<string>) => {
      state.channelTaskId = action.payload;
    },

    setActiveChannelId: (
      state,
      action: PayloadAction<FloatingChatState["activeChannelId"]>
    ) => {
      state.activeChannelId = action.payload;
    },
    openMeetWithTranqModal: (state, action: PayloadAction<string>) => {
      state.isMeetWithTranqModalOpen = action?.payload;
    },
    closeMeetWithTranqModal: (state) => {
      state.isMeetWithTranqModalOpen = "";
    },
    setPopulateTransactionIDs: (state, action: PayloadAction<string>) => {
      state.populateTransactionIDsList = action.payload;
    },
    setMessageIdForTransactionList: (state, action: PayloadAction<string>) => {
      state.messageIdForTransactionList = action.payload;
    },
  },
});

export const {
  openFloatingChat,
  closeFloatingChat,
  setDefaultChannel,
  setEntityAndTaskId,
  addTransactionMessagePreview,
  setMessageType,
  setAdminAssigneeMembers,
  setSelectedChatTicket,
  setDropedFile,
  setPreviewFiles,
  closePreview,
  setToOpenChatId,
  removeToOpenChatUrl,
  setDeleteMessageData,
  setChannelTaskId,
  setActiveChannelId,
  openMeetWithTranqModal,
  closeMeetWithTranqModal,
  setPopulateTransactionIDs,
  setMessageIdForTransactionList,
} = chatSlice.actions;

export default chatSlice.reducer;
