import { AdminCallCard } from "components/ChatCard/AdminCallCard/AdminCallCard";
import { EsignCard } from "components/ChatCard/EsignCard";
import { RaiseClarificationCard } from "components/ChatCard/RaiseClarificationCard";
import { RequestedInfoChatCard } from "components/ChatCard/RequestedInfoChatCard";
import { ReviewClosingCard } from "components/ChatCard/ReviewClosingCard";
import { ReviewMessageChatCard } from "components/ChatCard/ReviewMessageChatCard";
import { SensitiveDataCard } from "components/ChatCard/SensitiveDataCard";
import { TicketCard } from "components/ChatCard/TicketCard/TicketCard";
import { TransactionChatCard } from "components/ChatCard/TransactionChatCard";
import { OpenItemCard } from "components/MessageInput/OpenItemCard";
import { RequestDocumentsChatCard } from "components/RequestDocumentsChatCard/RequestDocumentsChatCard";
import {
  CALL_LOG_CUSTOM_TYPE,
  CHAT_CARD_TYPE,
  ESIGN_REQUEST_CUSTOM_TYPE,
  FINANCIAL_CLOSING_CARD_TYPE,
  OPEN_ITEM_CUSTOM_TYPE,
  REQUESTED_DOCUMENTS_CUSTOM_TYPE,
  REQUESTED_INFO_CUSTOM_TYPE,
  SENSITIVE_DATA_CUSTOM_TYPE,
  TICKET_CARD,
  TRANSACTIONS,
} from "constants/chatType";
import { FC, ReactNode } from "react";
import { useChannelStateContext } from "stream-chat-react";

type Cardsprops = {
  type: string;
  customData: any;
  messageId: string;
  children?: ReactNode;
  size?: "regular" | "block";
};
export const Cards: FC<Cardsprops> = ({
  type,
  customData,
  messageId,
  size = "regular",
  children,
}) => {
  const { channel } = useChannelStateContext();

  switch (type) {
    case CHAT_CARD_TYPE:
      return (
        <ReviewMessageChatCard
          customData={customData}
          messageId={messageId}
          size={size}
        />
      );

    case CALL_LOG_CUSTOM_TYPE:
      return <AdminCallCard customData={customData} />;

    case TICKET_CARD:
      return <TicketCard customData={customData} messageId={messageId} />;

    case OPEN_ITEM_CUSTOM_TYPE:
      return (
        <OpenItemCard
          customData={customData}
          messageId={messageId}
          size={size}
        />
      );

    case REQUESTED_DOCUMENTS_CUSTOM_TYPE:
      return channel.id ? (
        <RequestDocumentsChatCard
          customData={customData}
          groupId={channel.data?.group_uuid as string}
          channelId={channel.id}
          size={size}
        >
          {children}
        </RequestDocumentsChatCard>
      ) : null;

    case TRANSACTIONS:
      return (
        <TransactionChatCard
          customData={customData}
          groupId={channel.data?.group_uuid as string}
          messageId={messageId}
        >
          {children}
        </TransactionChatCard>
      );

    case REQUESTED_INFO_CUSTOM_TYPE:
      return (
        <RequestedInfoChatCard
          customData={customData}
          groupId={channel.data?.group_uuid as string}
          messageId={messageId}
          channelId={channel.id!}
          size={size}
        />
      );

    case ESIGN_REQUEST_CUSTOM_TYPE:
      return (
        <EsignCard
          customData={customData}
          groupId={channel.data?.group_uuid as string}
          messageId={messageId}
          size={size}
        />
      );

    case SENSITIVE_DATA_CUSTOM_TYPE:
      return (
        <SensitiveDataCard
          customData={customData}
          groupId={channel.data?.group_uuid as string}
          messageId={messageId}
          size={size}
        />
      );

    case FINANCIAL_CLOSING_CARD_TYPE.CLARIFICATION_RAISED:
      return (
        <RaiseClarificationCard customData={customData}>
          {children}
        </RaiseClarificationCard>
      );

    case FINANCIAL_CLOSING_CARD_TYPE.REVIEW_FINANCIAL:
      return (
        <ReviewClosingCard customData={customData}>
          {children}
        </ReviewClosingCard>
      );

    default:
      return null;
  }
};
