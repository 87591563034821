import classNames from "classnames";
import { AmountSuperScript } from "components/design/AmountSuperScript";

import Table from "components/DesignSystem/Table/V2/Table";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "components/DesignSystem/ToggleGroup/ToggleGroup";
import { REVENUE_BASIS } from "constants/revenueMetrics";
import React, { ReactNode, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { TableData } from "types/Models/revenueMetrics";
import { getCommonPinningStyles } from "utils/getCommonPinningStyles";

export const FinalDataTable = ({
  finalTableData,
  revenueBasis,
  onRevenueBasisChange,
  pagination,
}: {
  finalTableData: TableData[];
  revenueBasis: keyof typeof REVENUE_BASIS;
  onRevenueBasisChange: (revenueBasis: keyof typeof REVENUE_BASIS) => void;
  pagination: ReactNode;
}) => {
  const createColumn = createColumnHelper<TableData>();

  const allPossibleColumns = finalTableData[0]?.periodic_revenue || [];

  const monthlyColumns = allPossibleColumns.map((period) => {
    return createColumn.accessor(`periodic_revenue`, {
      size: 114,
      header: () => <div className="t-text-end">{period.period.range}</div>,
      id: period.period.start_date,
      enableSorting: true,
      cell: ({ row: { original } }) => {
        const amount = original.periodic_revenue.find(
          (x) => x.period.range === period.period.range
        )?.customer_total_amount;

        return (
          <div className="t-text-end">
            <AmountSuperScript amount={Number(amount)} />
          </div>
        );
      },
    });
  });

  const columns = [
    createColumn.accessor("item_info.name", {
      size: 200,
      header: "NAME",
      id: "name",
      enableSorting: true,
      cell: (row) => {
        return row.getValue();
      },
    }),
    ...monthlyColumns,
  ];

  const table = useReactTable({
    data: finalTableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnPinning: {
        left: ["name"],
      },
    },
  });

  return (
    <div className="t-flex t-gap-3 t-border t-border-solid t-border-neutral-0 t-shadow-light-30 t-rounded-lg t-flex-col">
      <div className="t-w-full t-border t-border-solid t-border-neutral-0 t-border-t-0 t-border-l-0 t-border-r-0 t-p-5 t-text-subtitle t-flex t-justify-between">
        <ToggleGroup
          defaultValue="CUSTOMER"
          onValueChange={onRevenueBasisChange}
          value={revenueBasis}
        >
          <ToggleGroupItem value="CUSTOMER" aria-label="Center aligned">
            Customers
          </ToggleGroupItem>
          <ToggleGroupItem value="PRODUCT" aria-label="Center aligned">
            Products
          </ToggleGroupItem>
        </ToggleGroup>
        {pagination}
      </div>
      <div className="t-p-5 t-w-full">
        <Table.Container className="t-overflow-scroll scrollbar-visible t-scroll-auto">
          <Table.Content className="t-w-full">
            <Table.Head>
              {table.getHeaderGroups().map((headerGroup) => (
                <Table.HeadRow key={headerGroup.id}>
                  {headerGroup.headers.map((header, index) => {
                    const styles = getCommonPinningStyles(header.column);
                    return (
                      <Table.HeadCell
                        key={header.id}
                        style={{
                          ...styles,
                          minWidth: header.column.getSize(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </Table.HeadCell>
                    );
                  })}
                </Table.HeadRow>
              ))}
            </Table.Head>
            <Table.Body>
              {table.getRowModel().rows.map((row) => {
                return (
                  <Table.Row key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      const styles = getCommonPinningStyles(cell.column);
                      return (
                        <Table.Cell
                          key={cell.id}
                          style={{
                            ...styles,
                            minWidth: cell.column.getSize(),
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Table.Cell>
                      );
                    })}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table.Content>
        </Table.Container>
      </div>
    </div>
  );
};
