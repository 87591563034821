import { AmountSuperScript } from "components/design/AmountSuperScript";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { MobileInput } from "components/DesignSystem/MobileInput/MobileInput";
import Radio from "components/DesignSystem/RadioGroup/RadioGroup";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { Label, TextInput } from "components/DesignSystem/TextInput/TextInput";
import { FileInput, FileType } from "components/FileInput/FileInput";
import { AddEditAddress } from "components/fileTax/AddEditAddress";
import { DeleteIcon } from "components/icons/delete";
import { HidePinIcon } from "components/icons/HidePinIcon";
import Pencil from "components/icons/pencil";
import { ShowPinIcon } from "components/icons/ShowPinIcon";
import { ToolTipIcon } from "components/icons/TooltipIcon";
import { Preview } from "components/PreviewModal";
import { PriceInput } from "components/PriceInput/PriceInput";
import { YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { Field, FieldProps, useFormikContext } from "formik";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useMultipleFileDetails } from "hooks/useMultipleFileDetails";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import React, { useContext, useState } from "react";
import { NumberFormatValues } from "react-number-format";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { MultiValue, SingleValue } from "react-select";
import CopyIcon from "static/images/CopyPurple.svg";
import { useGetAutofillQuery } from "store/apis/autofill";
import { useUploadFileMutation } from "store/apis/documents";
import { useDeleteFieldMutation } from "store/apis/entity";
import { useCreateWFormsMutation } from "store/apis/groupWForms";
import { useGetFolderOrFileQuery } from "store/apis/previewUrl";
import { AddressAutofill } from "types/Models/addressAutofill";
import { EntityDataAttribute } from "types/Models/entity";
import { FileObject } from "types/Models/fileObject";
import { SubscriptionInProfile } from "types/Models/subscription";
import { BackendError } from "types/utils/error";

type FieldBuilderProps = {
  field: EntityDataAttribute;
  size?: "small" | "regular" | "large";
  onChange?: ({
    name,
    value,
  }: {
    name: string;
    value: EntityDataAttribute["value"];
  }) => void;
  entityIdFromProps?: string;
};

const useHideField = (show: EntityDataAttribute["show"]) => {
  const { values: formikValues } = useFormikContext();
  const { value: showValue, when } = show || {};
  //   @ts-ignore
  const formikFieldValue = formikValues[when];

  return showValue !== formikFieldValue && show;
};

const DeleteField = ({
  dataAttributeId,
  is_delete_allowed,
}: {
  dataAttributeId: string;
  is_delete_allowed: boolean;
}) => {
  const [deleteField, { isLoading }] = useDeleteFieldMutation();
  const { isAdmin } = useRoleBasedView();

  const onDelete = async () => {
    try {
      await deleteField({ dataAttributeId }).unwrap();
    } catch (error) {}
  };

  if (is_delete_allowed && isAdmin) {
    return (
      <div className="t-self-end">
        <Button
          customType="ghost_icon"
          size="small"
          onClick={onDelete}
          isLoading={isLoading}
          disabled={isLoading}
        >
          <DeleteIcon />
        </Button>
      </div>
    );
  }

  return null;
};

const CheckBoxField = ({ field, onChange }: FieldBuilderProps) => {
  const { values: formikValues, setFieldValue } = useFormikContext();
  const { disabled, label, name, is_required: required, show } = field;
  //   @ts-ignore
  const value = formikValues[name] || field.value;
  const isHidden = useHideField(show);

  if (isHidden) return null;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(name, e.target.checked);
    onChange?.({ name, value: e.target.checked });
  };

  return (
    <Checkbox
      name={name}
      label={label}
      required={required}
      disabled={disabled}
      defaultChecked={value as boolean}
      checked={value as boolean}
      onChange={handleChange}
      tooltipText={field.tooltip}
    />
  );
};

const DateField = ({ field, size, onChange }: FieldBuilderProps) => {
  const { authtoken } = useContext(authContext);
  const {
    platform_subscription: currentSubscription,
  }: {
    platform_subscription: SubscriptionInProfile;
  } = authtoken || {};

  const minDate = dayjs(currentSubscription.scheduled_date)
    .subtract(1, "year")
    .startOf("year")
    .toDate();

  const { setFieldValue } = useFormikContext();
  const {
    disabled,
    label,
    name,
    is_required: required,
    show,
    placeholder,
    tooltip,
  } = field;
  const isHidden = useHideField(show);

  if (isHidden) return null;

  const handleChange = (date: Date) => {
    setFieldValue(name, date ? dayjs(date).format(YYYY_MM_DD) : null);
    onChange?.({ name, value: date ? dayjs(date).format(YYYY_MM_DD) : null });
  };

  return (
    <Field name={name}>
      {({ field }: FieldProps) => {
        return (
          <DateInput
            customSize={size}
            {...field}
            name={name}
            label={label}
            required={required}
            disabled={disabled}
            portalId={field.name}
            tooltipText={tooltip}
            placeholder={placeholder || "DD-MMM-YYYY"}
            onDateChange={handleChange}
            {...(currentSubscription.scheduled_date
              ? { minDate: minDate }
              : {})}
          />
        );
      }}
    </Field>
  );
};

const PhoneField = ({ field, size, onChange }: FieldBuilderProps) => {
  const {
    disabled,
    label,
    name,
    is_required: required,
    show,
    placeholder,
    is_delete_allowed,
    uuid,
    tooltip,
  } = field;
  const isHidden = useHideField(show);

  if (isHidden) return null;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      console.log({ name, value: e.target.value });
      onChange?.({ name, value: e.target.value });
    } catch (e: any) {
      console.log({ e });
    }
  };

  return (
    <div>
      <MobileInput
        customSize={size}
        name={name}
        disabled={disabled}
        placeholder={placeholder || ""}
        label={label}
        required={required}
        onChange={handleChange}
        tooltipText={tooltip}
        block
        defaultValue={(field.value || "") as string}
      />
    </div>
  );
};

const InputField = ({ field, size, onChange }: FieldBuilderProps) => {
  const {
    disabled,
    label,
    name,
    is_required: required,
    show,
    placeholder,
    is_delete_allowed,
    uuid,
    tooltip,
  } = field;
  const isHidden = useHideField(show);

  if (isHidden) return null;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.({ name, value: e.target.value });
  };

  return (
    <div className="t-flex t-items-center t-gap-2">
      <TextInput
        customSize={size}
        name={name}
        disabled={disabled}
        placeholder={placeholder || ""}
        label={label}
        required={required}
        onChange={handleChange}
        tooltipText={tooltip}
        block
        defaultValue={(field.value || "") as string}
      />
      <DeleteField
        dataAttributeId={uuid}
        is_delete_allowed={is_delete_allowed}
      />
    </div>
  );
};

const SelectField = ({ field, size, onChange }: FieldBuilderProps) => {
  const { values: formikValues } = useFormikContext();
  const {
    disabled,
    label,
    name,
    is_required: required,
    show,
    options,
    placeholder,
    tooltip,
  } = field;
  //   @ts-ignore
  const value = formikValues[name] || field.value;
  const isHidden = useHideField(show);

  const optionValue = value
    ? options?.find((item) => item.value === value)
    : null;

  if (isHidden) return null;

  const handleChange = (
    option: SingleValue<OptionData> | MultiValue<OptionData>
  ) => {
    if (option instanceof Array) {
      return null;
    }
    onChange?.({ name, value: option?.value || "" });
  };

  return (
    <Combobox
      size={size}
      options={options || []}
      name={name}
      label={label}
      value={optionValue}
      required={required}
      isDisabled={disabled}
      placeholder={placeholder}
      onChange={handleChange}
      tooltipText={tooltip}
      withForm
      menuPortalTarget={document.body}
    />
  );
};

const TextAreaField = ({ field, onChange }: FieldBuilderProps) => {
  const {
    disabled,
    label,
    name,
    is_required: required,
    show,
    placeholder,
    tooltip,
  } = field;
  const isHidden = useHideField(show);

  if (isHidden) return null;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.({ name, value: e.target.value });
  };

  return (
    <TextArea
      name={name}
      label={label}
      required={required}
      disabled={disabled}
      placeholder={placeholder || ""}
      onChange={handleChange}
      defaultValue={(field.value || "") as string}
      tooltipText={tooltip}
    />
  );
};

const RadioField = ({ field, onChange }: FieldBuilderProps) => {
  const { values: formikValues, setFieldValue } = useFormikContext();
  const {
    disabled,
    label,
    name,
    is_required: required,
    show,
    options,
    tooltip,
  } = field;
  //   @ts-ignore
  const value = formikValues[name] || field.value;
  const isHidden = useHideField(show);

  if (isHidden) return null;

  const handleChange = (value: string) => {
    onChange?.({ name, value });
    setFieldValue(name, value);
  };

  return (
    <Radio.Root
      disabled={disabled}
      defaultValue={value as string}
      onValueChange={handleChange}
      className="t-flex t-flex-col t-gap-2"
    >
      <div className="t-flex t-items-center">
        <Label required={required} htmlFor={name}>
          {label}
        </Label>
        {tooltip && (
          <div className="t-ml-1 t-pb-1.5">
            <ToolTip text={tooltip} side="right">
              <span>
                <ToolTipIcon />
              </span>
            </ToolTip>
          </div>
        )}
      </div>
      <Radio.Content className="t-gap-2.5">
        {options?.map((item) => (
          <Radio.Item key={item.value} value={item.value}>
            {item.label}
          </Radio.Item>
        ))}
      </Radio.Content>
    </Radio.Root>
  );
};

const MultiFileInputField = ({ field, onChange }: FieldBuilderProps) => {
  const { alertToast } = useToast();
  const [uploadFile, { isLoading }] = useUploadFileMutation();
  const [previewId, setPreviewId] = useState<string>("");
  const { uuid: groupId, unsorted_folder_id } = useCurrentGroupContext();
  const { values: formikValues, setFieldValue } = useFormikContext();
  const {
    disabled,
    label,
    name,
    is_required: required,
    show,
    placeholder,
    tooltip,
  } = field;

  // @ts-ignore
  const selectedFiles = formikValues[name] || field.value;
  const isFile = (selectedFiles as FileObject[])?.every(
    (file) => typeof file !== "string" && "uuid" in file
  );

  const isHidden = useHideField(show);
  //   @ts-ignore
  const value = (formikValues[name] as string[]) || [];

  const fileresponse = useMultipleFileDetails(value);
  const files = isFile
    ? //   @ts-ignore
      formikValues[name]
    : fileresponse.map(({ name, file_type, is_previewable, uuid }) => ({
        name,
        file_type,
        is_previewable,
        uuid,
      }));

  const onDelete = (e: React.MouseEvent<HTMLButtonElement>, index?: number) => {
    const newFiles = value.filter((_, i) => i !== index);
    setFieldValue(name, newFiles);
    onChange?.({ name, value: newFiles });
  };

  const {
    close: closePreviewModal,
    isOpen: isPreviewModalOpen,
    open: openPreviewModal,
  } = useModal();

  const onDrop = async (files: FileType[]) => {
    try {
      const uploadedFiles = await uploadFile({
        files: files as File[],
        groupId,
        parentFolderId: unsorted_folder_id,
      }).unwrap();
      const fileIds = uploadedFiles.map(({ uuid }) => uuid);
      setFieldValue(name, [...value, ...fileIds]);
      onChange?.({ name, value: [...value, ...fileIds] });
    } catch (error) {
      alertToast(
        { message: (error as BackendError).data?.error?.message },
        error as {}
      );
    }
  };

  if (isHidden) return null;

  return (
    <div>
      <FileInput
        required={required}
        isUploading={isLoading}
        disabled={disabled || isLoading}
        onDrop={onDrop}
        onDelete={onDelete}
        onFileClick={({ uuid }) => {
          setPreviewId(uuid);
          openPreviewModal();
        }}
        label={label}
        files={files}
        multiple
        tooltipText={tooltip}
      />
      <Preview
        showModal={isPreviewModalOpen}
        closeModal={closePreviewModal}
        groupId={groupId}
        previewId={previewId}
      />
    </div>
  );
};

const SingleFileInputField = ({ field, onChange }: FieldBuilderProps) => {
  const { alertToast } = useToast();
  const [uploadFile, { isLoading }] = useUploadFileMutation();
  const { uuid: groupId, unsorted_folder_id } = useCurrentGroupContext();
  const { values: formikValues, setFieldValue } = useFormikContext();
  const {
    disabled,
    label,
    name,
    is_required: required,
    show,
    placeholder,
    tooltip,
  } = field;
  //   @ts-ignore
  const value = (formikValues[name] as string[]) || field.value;
  const isHidden = useHideField(show);
  const file = value?.[0];
  const isUploaded = Boolean(value.length > 0);
  const [createWForm] = useCreateWFormsMutation();
  const history = useHistory();
  const { entityId: entityUuid } = useParams<{ entityId: string }>();
  const entityIdFromQuery = useCurrentEntityId();
  const entityId = entityUuid || entityIdFromQuery;
  const location = useLocation();
  const fullPath = encodeURIComponent(`${location.pathname}${location.search}`);

  const isFile = Boolean(file && typeof file !== "string");

  const { data, isFetching } = useGetFolderOrFileQuery(
    {
      fileId: file,
      groupId,
    },
    { skip: !file || isFile }
  );

  const fileData = isFile
    ? file
    : {
        name: data?.name || "",
        file_type: data?.file_type || "IMAGE",
        is_previewable: data?.is_previewable || false,
        uuid: file,
      };

  const onDelete = () => {
    setFieldValue(name, []);
    onChange?.({ name, value: [] });
  };

  const {
    close: closePreviewModal,
    isOpen: isPreviewModalOpen,
    open: openPreviewModal,
  } = useModal();

  const onDrop = async (files: FileType[]) => {
    try {
      const uploadedFiles = await uploadFile({
        files: [files[0]] as File[],
        groupId,
        parentFolderId: unsorted_folder_id,
      }).unwrap();

      const fileIds = uploadedFiles.map(({ uuid }) => uuid);
      setFieldValue(name, [fileIds[0]]);
      onChange?.({ name, value: [fileIds[0]] });
    } catch (error) {
      alertToast(
        { message: (error as BackendError).data?.error?.message },
        error as {}
      );
    }
  };

  const createCompanyWForm = async () => {
    try {
      const { uuid } = await createWForm({ groupId, entityId }).unwrap();
      history.push(
        `/contractor-form/${uuid}?companywform=true&redirect_to=${fullPath}`
      );
    } catch (e: any) {
      alertToast({ message: e?.response?.data?.error?.message });
    }
  };

  if (isHidden) return null;

  return (
    <div>
      <Label required={required} htmlFor={name}>
        {label}
      </Label>
      <FileInput
        isUploading={isLoading}
        disabled={disabled || isFetching || isLoading}
        onDrop={onDrop}
        onDelete={onDelete}
        label={placeholder}
        onFileClick={openPreviewModal}
        file={isUploaded ? (fileData as FileObject) : null}
        tooltipText={tooltip}
      />
      <Preview
        showModal={isPreviewModalOpen}
        closeModal={closePreviewModal}
        groupId={groupId}
        previewId={file}
      />
      {field.name === "w_form" &&
        Array.isArray(field.value) &&
        field.value.length === 0 && (
          <div className="t-flex t-justify-between t-items-center t-bg-purple-0 t-rounded t-p-1 t-pl-3 t-border t-border-solid t-border-purple-10 t-mt-3">
            <div className="t-text-body t-text-purple-100">
              Don’t have a W-form? Inkle can help
            </div>
            <Button size="small" onClick={createCompanyWForm}>
              Generate
            </Button>
          </div>
        )}
    </div>
  );
};

const String = ({ field }: FieldBuilderProps) => {
  const { values: formikValues } = useFormikContext();
  const { copyToClipboard } = useCopyToClipboard();
  const { label, name, is_required: required, show, can_copy, tooltip } = field;
  //   @ts-ignore
  const value = formikValues[name] || field.value;
  const isHidden = useHideField(show);
  const isPin = name === "pin";
  const [hidePin, setHidePin] = useState(isPin);
  const asterickValues = value
    ?.split("")
    ?.reduce((ac: string, _: any) => (ac += "*"), "");

  if (isHidden) return null;

  return (
    <div>
      <div className="t-flex t-items-center">
        <Label required={required} htmlFor={name}>
          {label}
        </Label>
        {tooltip && (
          <div className="t-ml-1 t-pb-1.5">
            <ToolTip text={tooltip} side="right">
              <span>
                <ToolTipIcon />
              </span>
            </ToolTip>
          </div>
        )}
      </div>
      <div className="t-text-body t-text-text-100 t-flex t-gap-1 t-items-center">
        {hidePin ? asterickValues : value}
        {isPin && (
          <Button
            customType="ghost_icon"
            size="small"
            onClick={() => setHidePin((val) => !val)}
            type="button"
            aria-label="showpin"
          >
            {hidePin ? (
              <ToolTip text="Show PIN">
                <ShowPinIcon />
              </ToolTip>
            ) : (
              <ToolTip text="Hide PIN">
                <HidePinIcon />
              </ToolTip>
            )}
          </Button>
        )}
        {can_copy && (
          <Button
            customType="ghost_icon"
            size="small"
            onClick={() => copyToClipboard(value)}
          >
            <img src={CopyIcon} alt="copyIcon" />
          </Button>
        )}
      </div>
    </div>
  );
};

const AmountInputField = ({ field, size, onChange }: FieldBuilderProps) => {
  const {
    disabled,
    label,
    name,
    is_required: required,
    show,
    placeholder,
    value,
    tooltip,
  } = field;

  const isHidden = useHideField(show);

  if (isHidden) return null;

  const handleChange = (values: NumberFormatValues) => {
    const { floatValue } = values;
    onChange?.({ name, value: floatValue || null });
  };

  return (
    <PriceInput
      customSize={size}
      name={name}
      label={label}
      required={required}
      disabled={disabled}
      placeholder={placeholder}
      onValueChange={handleChange}
      value={Number(value)}
      tooltipText={tooltip}
    />
  );
};

const AddressField = ({
  field,
  size = "small",
  onChange,
  entityIdFromProps,
}: FieldBuilderProps) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const { entityId: entityUuid } = useParams<{ entityId: string }>();
  const entityIdFromQuery = useCurrentEntityId();
  const { alertToast, successToast } = useToast();
  const [detailsForEdit, setDetailsForEdit] = useState<AddressAutofill>();
  const entityId = entityUuid || entityIdFromQuery || entityIdFromProps;

  const { data: autofills = [], isLoading } = useGetAutofillQuery(
    {
      groupId,
      autofillKey: "addresses",
      entityId: entityId!,
    },
    { skip: !groupId || !entityId }
  );

  const { label, name, value, disabled } = field;

  const defaultSelected = (value as AddressAutofill)?.autofill_string
    ? {
        label: (
          <div className="t-text-body t-w-full t-truncate">
            {(value as AddressAutofill)?.autofill_string}
          </div>
        ),
        value: (value as AddressAutofill)?.uuid!,
      }
    : null;

  const {
    isOpen: showAddModal,
    close: closeAddModal,
    open: openAddModal,
  } = useModal();

  const {
    isOpen: showEditModal,
    close: closeEditModal,
    open: openEditModal,
  } = useModal();

  const updateTaskSection = async (
    autofillValue: SingleValue<OptionData> | MultiValue<OptionData> = []
  ) => {
    try {
      const selectedAutofill = (autofillValue as SingleValue<OptionData>)
        ?.value;
      const valuesToSet = autofills.find(
        ({ uuid }) => uuid === selectedAutofill
      );
      onChange?.({ name: name, value: valuesToSet?.uuid! });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const openEdit = () => {
    setDetailsForEdit(value as AddressAutofill);
    openEditModal();
  };

  const closeEdit = () => {
    setDetailsForEdit(undefined);
    closeEditModal();
  };

  const onClose = () => {
    closeAddModal();
    closeEdit();
  };

  return (
    <div className="t-flex t-flex-col">
      <div className="t-flex t-justify-between t-items-center -t-mb-1">
        <div className="t-flex t-items-center">
          <Label required={field.is_required}>{label}</Label>
          {field.tooltip && (
            <div className="t-ml-1 t-pb-1.5">
              <ToolTip text={field.tooltip} side="right">
                <span>
                  <ToolTipIcon />
                </span>
              </ToolTip>
            </div>
          )}
        </div>
        {detailsForEdit && (
          <Button size={size} customType="text" onClick={openEdit}>
            Edit
          </Button>
        )}
      </div>
      <Combobox
        actions={
          <Button customType="link" onClick={openAddModal}>
            Add address
          </Button>
        }
        size="small"
        components={{ ClearIndicator: () => null }}
        menuPortalTarget={document.body}
        onChange={updateTaskSection}
        value={defaultSelected}
        placeholder={field.placeholder}
        options={autofills.map((autofill) => {
          return {
            label: (
              <div className="t-text-body t-w-full t-truncate">
                {autofill.autofill_string}
              </div>
            ),
            value: autofill.uuid,
          };
        })}
        isDisabled={disabled}
      />

      <AddEditAddress
        entityId={entityId!}
        isOpenAdd={showAddModal}
        isOpenEdit={showEditModal}
        onClose={onClose}
        initialValue={detailsForEdit as AddressAutofill}
        isEditFlow={showEditModal}
        onSuccess={(address: AddressAutofill) => {
          onChange?.({ name: name, value: address?.uuid! });
        }}
        groupId={groupId}
        autofillKey="addresses"
      />
    </div>
  );
};

const AmountField = ({ field }: FieldBuilderProps) => {
  const { values: formikValues } = useFormikContext();
  const { label, name, is_required: required, show, tooltip } = field;
  //   @ts-ignore
  const value = formikValues[name] || field.value;
  const isHidden = useHideField(show);

  if (isHidden) return null;

  return (
    <div>
      <div className="t-flex t-items-center">
        <Label required={required} htmlFor={name}>
          {label}
        </Label>
        {tooltip && (
          <div className="t-ml-1 t-pb-1.5">
            <ToolTip text={tooltip} side="right">
              <span>
                <ToolTipIcon />
              </span>
            </ToolTip>
          </div>
        )}
      </div>
      <div>
        <AmountSuperScript amount={Number(value)} />
      </div>
    </div>
  );
};

export const FieldBuilder = ({
  field,
  size = "small",
  onChange,
  entityIdFromProps,
}: FieldBuilderProps) => {
  switch (field.type) {
    case "CHECKBOX":
      return <CheckBoxField field={field} onChange={onChange} />;
    case "DATE":
      return <DateField field={field} size={size} onChange={onChange} />;
    case "INPUT":
      return <InputField field={field} size={size} onChange={onChange} />;
    case "SELECT":
      return <SelectField field={field} size={size} onChange={onChange} />;
    case "TEXTAREA":
      return <TextAreaField field={field} onChange={onChange} />;
    case "RADIO":
      return <RadioField field={field} onChange={onChange} />;
    case "MULTI_FILE_INPUT":
      return <MultiFileInputField field={field} onChange={onChange} />;
    case "FILE":
      return <SingleFileInputField field={field} onChange={onChange} />;
    case "AMOUNT_INPUT":
      return <AmountInputField field={field} size={size} onChange={onChange} />;
    case "STRING":
      return <String field={field} size={size} />;
    case "NUMBER":
      return <String field={field} size={size} />;
    case "AMOUNT":
      return <AmountField field={field} size={size} />;
    case "ADDRESS":
      return (
        <AddressField
          field={field}
          size={size}
          onChange={onChange}
          entityIdFromProps={entityIdFromProps}
        />
      );
    case "PHONE":
      return <PhoneField field={field} size={size} onChange={onChange} />;
    default:
      return null;
  }
};

const CustomField = {
  CheckBoxField,
  DateField,
  InputField,
  SelectField,
  TextAreaField,
  RadioField,
  MultiFileInputField,
  SingleFileInputField,
  AmountInputField,
  AmountField,
  String,
  AddressField,
};

export default CustomField;
