import { AppLayout } from "components/AppLayout/AppLayout";
import HandbookApp from "components/apps/handbook";
import { ComingSoon } from "components/ComingSoon/ComingSoon";
import GroupSelect from "components/dashboard/sideBar/groupSelect";
import PerksTab from "components/deals/PerksTab";
import PerkStepController from "components/deals/selfServePerk/perkStepController";
import { Blog } from "components/icons/LeftNav/Community/Blog";
import { Events } from "components/icons/LeftNav/Community/Events";
import { FAQs } from "components/icons/LeftNav/Community/FAQs";
import { Forum } from "components/icons/LeftNav/Community/Forum";
import { Handbook } from "components/icons/LeftNav/Community/Handbook";
import { InvestorUpdates } from "components/icons/LeftNav/Community/InvestorUpdates";
import { Perks } from "components/icons/LeftNav/Community/Perks";
import { Raise as RaiseIcon } from "components/icons/LeftNav/Community/Raise";
import { Home } from "components/icons/LeftNav/Home";
import { InkleCommunityLogo } from "components/icons/Logos/InkleCommunityLogo";
import { InkleCommunitySymbol } from "components/icons/Logos/InkleCommunitySymbol";
import { NavAcrossApps } from "components/NavAcrossApps/NavAcrossApps";
import { RoutesAccrossApps } from "components/RoutesAcrossApps/RoutesAcrossApps";
import {
  LeftBar,
  LeftBarItem,
  LeftBarLogo,
  LeftBarSeparator,
  LeftBarTag,
} from "components/Sidebar/LeftBar";
import { TopBar } from "components/TopBar/TopBar";
import { FOREIGN_CA } from "constants/onBoarding";
import { useAuth } from "hooks/useAuth";
import { Switch, useRouteMatch } from "react-router-dom";
import { RestrictedRoute } from "RestrictedRoute";
import { DashboardBanner } from "signInSignUp/DashboardBanner";
import "static/styles/containers/dashboard.css";
import { CreateNewInvestorUpdate } from "../InvestorUpdates/CreateNewInvestorUpdate";
import { InvestorUpdatePreviewPage } from "../InvestorUpdates/InvestorUpdatePreviewPage";
import { InvestorUpdatesHome } from "../InvestorUpdates/InvestorUpdatesHome";
import { ViewInvestorUpdate } from "../InvestorUpdates/ViewInvestorUpdate";
import { Raise } from "../Resources/Raise";
import { RaiseHome } from "../Resources/RaiseHome";
import { UpgradeTaxPlan } from "../Tax/UpgradeTaxPlan";
import { useCurrentAppContext } from "contexts/CurrentAppContext";
import { useEffect } from "react";

const Navbar = () => {
  const { url } = useRouteMatch();
  const { type } = useAuth();

  return (
    <div className="t-h-full t-flex t-flex-col t-justify-between">
      <div>
        {type === FOREIGN_CA && (
          <div className="t-m-2">
            <GroupSelect />
          </div>
        )}
        <LeftBarItem exact to="/" icon={Home}>
          Home
        </LeftBarItem>

        <LeftBarItem to="/perks" icon={Perks}>
          Perks
        </LeftBarItem>
        <LeftBarItem to="/raise" icon={RaiseIcon}>
          Raise
        </LeftBarItem>
        <LeftBarItem to="/investor-updates" icon={InvestorUpdates}>
          Investor Updates
        </LeftBarItem>
        <LeftBarItem
          suffix={<LeftBarTag type="blue">Upcoming</LeftBarTag>}
          to="/forum"
          icon={Forum}
        >
          Forum
        </LeftBarItem>
        <LeftBarItem to="/handbook" icon={Handbook}>
          Handbooks
        </LeftBarItem>
        <LeftBarItem
          useParentPath={false}
          to="https://www.inkle.io/resources/events/talks"
          icon={Events}
        >
          Events
        </LeftBarItem>
        <LeftBarItem
          useParentPath={false}
          to="https://www.inkle.io/services"
          icon={FAQs}
        >
          FAQs
        </LeftBarItem>
        <LeftBarItem
          useParentPath={false}
          to="https://www.inkle.io/resources/blog"
          icon={Blog}
        >
          Blogs
        </LeftBarItem>
      </div>

      <div>
        <LeftBarSeparator />
        <NavAcrossApps />
      </div>
    </div>
  );
};

export const CommunitygroupDashboard = () => {
  const { path } = useRouteMatch();
  const { dispatch: currentAppDispatch } = useCurrentAppContext();

  useEffect(() => {
    currentAppDispatch({
      type: "SET_CURRENT_APP",
      payload: { currentApp: "Community" },
    });
  }, []);

  return (
    <AppLayout
      sidebar={
        <LeftBar
          logo={
            <LeftBarLogo
              logo={InkleCommunityLogo}
              symbol={InkleCommunitySymbol}
            />
          }
          showCollapse
        >
          <Navbar />
        </LeftBar>
      }
      topbar={
        <TopBar
          upgradeButton={<UpgradeTaxPlan />}
          logo={
            <LeftBarLogo
              logo={InkleCommunityLogo}
              symbol={InkleCommunitySymbol}
            />
          }
          nav={
            <LeftBar>
              <Navbar />
            </LeftBar>
          }
        />
      }
      banner={<DashboardBanner />}
    >
      <Switch>
        <RestrictedRoute
          exact
          path={`${path}/investor-updates/add`}
          component={CreateNewInvestorUpdate}
        />

        <RestrictedRoute
          exact
          path={`${path}/perks/create`}
          component={PerkStepController}
        />
        <RestrictedRoute path={`${path}/perks`} component={PerksTab} />

        <RestrictedRoute exact path={`${path}/forum`} component={ComingSoon} />
        <RestrictedRoute
          exact
          path={`${path}/handbook`}
          component={HandbookApp}
        />

        <RestrictedRoute
          exact
          path={`${path}/investor-updates/preview/:investorUpdateId`}
          component={InvestorUpdatePreviewPage}
        />

        <RestrictedRoute
          path={`${path}/investor-updates/:investorUpdateId`}
          component={ViewInvestorUpdate}
        />

        <RestrictedRoute
          path={`${path}/investor-updates/`}
          component={InvestorUpdatesHome}
        />

        <RestrictedRoute path={`${path}/raise`} component={Raise} />
        <RestrictedRoute exact path={path} component={RaiseHome} />

        <RoutesAccrossApps />
      </Switch>
    </AppLayout>
  );
};
