import React from "react";

export const SensitiveDataIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M6.00002 10.6667H10V12H6.00002V10.6667ZM9.19535 4.52866L8.00002 5.72399L6.80469 4.52866L5.86202 5.47132L7.05735 6.66666L5.86202 7.86199L6.80469 8.80466L8.00002 7.60932L9.19535 8.80466L10.138 7.86199L8.94269 6.66666L10.138 5.47132L9.19535 4.52866ZM11.3334 12H15.3334V10.6667H11.3334V12ZM15.4714 5.47132L14.5287 4.52866L13.3334 5.72399L12.138 4.52866L11.1954 5.47132L12.3907 6.66666L11.1954 7.86199L12.138 8.80466L13.3334 7.60932L14.5287 8.80466L15.4714 7.86199L14.276 6.66666L15.4714 5.47132ZM0.666687 12H4.66669V10.6667H0.666687V12ZM3.86202 4.52866L2.66669 5.72399L1.47135 4.52866L0.528687 5.47132L1.72402 6.66666L0.528687 7.86199L1.47135 8.80466L2.66669 7.60932L3.86202 8.80466L4.80469 7.86199L3.60935 6.66666L4.80469 5.47132L3.86202 4.52866Z"
      fill="#706A85"
    />
  </svg>
);
