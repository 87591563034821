import { FileUrl, FileObject } from "types/Models/fileObject";
import { emptyApi } from "./emptyApi";

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getPreviewUrl: build.query<FileUrl, { groupId: string; fileId: any }>({
      query: ({ groupId, fileId }) => ({
        url: `/api/inkle/documents/group/${groupId}/file/${fileId}/url/`,
      }),
    }),
    getFolderOrFile: build.query<
      FileObject,
      { groupId: string; fileId: string }
    >({
      query: ({ groupId, fileId }) => ({
        url: `/api/inkle/documents/group/${groupId}/file/${fileId}/details/`,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPreviewUrlQuery,
  useLazyGetPreviewUrlQuery,
  useGetFolderOrFileQuery,
  useLazyGetFolderOrFileQuery,
} = extendedApi;
