import { ConditionalLink } from "components/conditionalLink";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { TableUI } from "components/design/TableUI";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import Table from "components/DesignSystem/Table/V2/Table";
import CrmTabBar from "components/serviceTeam/crmTabs/crmTabBar";
import { useFilters } from "hooks/useFilter";
import { usePageTitle } from "hooks/usePageTitle";
import { usePagination } from "hooks/usePagination";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useEffect, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import EmptyOpenedBox from "static/images/EmptyOpenedBox.svg";
import {
  RAMailroomSubscriptionType,
  useGetAllRAMailroomQuery,
} from "store/apis/RAMailroom";
import { ClampedTextWithTooltip } from "utils/ClampedTextWithTooltip";
import { debounce } from "utils/debouncing";
import { formatDate } from "utils/formatDate";
import { AssignedAE } from "./AssignedAE";
import { RAMailroomSubscriptionsFilter } from "./RAMailroomSubscriptionsFilter";
import { RAMailroomSubscriptionSlider } from "./RAMailroomSubscriptionSlider";
import { SupportStep } from "./SupportStep";

export const ColorDot = ({ color }: { color: string }) => (
  <div
    className={`t-min-h-3 t-min-w-3 t-max-h-3 t-max-w-3 t-rounded-full ${color}`}
  />
);

export const RAMailroomSubscriptions = () => {
  usePageTitle("RA Mailroom Subscriptions");
  const query = useQuery();
  const { update } = useUpdateQuery();
  const pageNum = Number(query.get("page") || 1);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<
    null | string
  >(null);

  const { values: filterValues, updateFilter } = useFilters({
    initialValue: {
      AGENT: [] as string[],
      SUPPORT_STEP: "" as string,
      SUBSCRIPTION_TYPE: "" as string,
      UNASSIGNED_AGENT: false,
    },
  });

  const filterAsArg = {
    profile_ids: filterValues.AGENT.join(",") || null,
    subscription_type: filterValues?.SUBSCRIPTION_TYPE || null,
    support_step: filterValues?.SUPPORT_STEP || null,
    search_term: searchQuery || null,
    un_assigned_agents: filterValues.UNASSIGNED_AGENT || null,
  };

  const {
    data: raMailrooms,
    isLoading,
    isSuccess,
  } = useGetAllRAMailroomQuery(
    {
      page_num: pageNum.toString(),
      ...filterAsArg,
    },
    { refetchOnMountOrArgChange: true }
  );

  const {
    ra_mailroom_subscriptions = [],
    total_pages = 0,
    total_count = 0,
    current_page = 1,
    per_page = 25,
  } = raMailrooms || {};

  const onPageNumChange = (currentPage: number) => {
    update({ query: "page", value: currentPage });
  };

  const {
    setTotalPage,
    goToFirstPage,
    goToPrevPage,
    goToNextPage,
    goToLastPage,
  } = usePagination({
    pageNumber: pageNum,
    onPageNumChange,
  });

  useEffect(() => {
    setTotalPage(total_pages);
  }, [setTotalPage, total_pages]);

  const onChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    update({ query: "page", value: 1 });
    setSearchQuery(e.target.value);
  });

  const columnHelper = createColumnHelper<RAMailroomSubscriptionType>();

  const columns = [
    columnHelper.accessor("company.name", {
      header: "GROUP",
      size: 14,
      cell: (info) => {
        const rowData = info.row.original;
        return (
          <div className="t-space-y-0.5">
            <ConditionalLink to={`crm/${rowData.company.uuid}`} target="_blank">
              <span onClick={(e) => e.stopPropagation()}>
                <ClampedTextWithTooltip
                  text={info.getValue()}
                  lineClampElementClass="t-line-clamp-1 t-text-body t-text-purple-70"
                  toolTipClass="t-w-64 t-word-break"
                />
              </span>
            </ConditionalLink>
            <div className="t-text-body-sm t-text-text-60">
              {rowData.entity.name}
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor("subscription_name", {
      header: "SUBSCRIPTION",
      size: 14,
      cell: (info) => (
        <ClampedTextWithTooltip
          text={info.getValue()}
          lineClampElementClass="t-line-clamp-1 t-text-body t-text-text-100"
          toolTipClass="t-w-64 t-word-break"
        />
      ),
    }),
    columnHelper.accessor("subscription_status", {
      header: "SUBSCRIPTION STATUS",
      size: 8,
      cell: (info) => {
        switch (info.getValue()) {
          case "ACTIVE": {
            return (
              <div className="t-flex t-gap-2 t-items-center">
                <ColorDot color="t-bg-green-50" />
                <div className="t-text-body t-text-text-60">Active</div>
              </div>
            );
          }
          case "INACTIVE": {
            return (
              <div className="t-flex t-gap-2 t-items-center">
                <ColorDot color="t-bg-red" />
                <div className="t-text-body t-text-text-60">Inactive</div>
              </div>
            );
          }
          default: {
            return <>-</>;
          }
        }
      },
    }),
    columnHelper.accessor("start_date", {
      header: "START DATE",
      size: 10,
      cell: (info) => (
        <div className="t-text-text-100 t-text-body">
          {info.getValue() ? formatDate(info.getValue()) : "-"}
        </div>
      ),
    }),
    columnHelper.accessor("end_date", {
      header: "END DATE",
      size: 10,
      cell: (info) => (
        <div className="t-text-text-100 t-text-body">
          {info.getValue() ? formatDate(info.getValue()) : "-"}
        </div>
      ),
    }),
    columnHelper.accessor("assigned_agent.profile_id", {
      header: "ASSIGNED AE",
      size: 19,
      cell: (info) => <AssignedAE subscription={info.row.original} />,
    }),
    columnHelper.accessor("support_step", {
      header: "SUPPORT STEP",
      size: 23,
      cell: (info) => (
        <div className="t-text-text-100 t-text-body">
          <SupportStep subscription={info.row.original} />
        </div>
      ),
    }),
  ];

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  const table = useReactTable({
    data: ra_mailroom_subscriptions,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
      maxSize: 100,
    },
  });

  const isEmpty = ra_mailroom_subscriptions.length == 0;

  return (
    <div className="t-flex t-flex-col t-h-full">
      <CrmTabBar selectedTabIs="admin/radeadlines" />
      <DashboardContainer.Header>
        <div className="t-flex t-flex-col t-gap-3 t-p-5">
          <div className="t-flex t-gap-4 t-items-center t-justify-between">
            <div className="t-w-1/2">
              <Search onChange={onChange} placeholder="Search" block />
            </div>
            <Pagination
              {...paginationData}
              goToFirstPage={goToFirstPage}
              goToPrevPage={goToPrevPage}
              goToNextPage={goToNextPage}
              goToLastPage={goToLastPage}
            />
          </div>
          <RAMailroomSubscriptionsFilter
            updateFilter={updateFilter}
            values={filterValues}
          />
        </div>
      </DashboardContainer.Header>
      <Async.Root isLoading={isLoading} isSuccess={isSuccess} isEmpty={isEmpty}>
        <Async.Empty>
          <div className="t-flex t-flex-col t-items-center t-justify-center t-w-full t-gap-4 t-flex-1">
            <img src={EmptyOpenedBox} alt="EmptyOpenedBox" />
            <div className="t-text-text-body t-text-text-30">
              No subscriptions
            </div>
          </div>
        </Async.Empty>
        <Async.Success>
          <div className="t-px-5">
            <TableUI table={table}>
              {({ row }) => (
                <Table.Row
                  onRowClick={() =>
                    setSelectedSubscriptionId(row.original.uuid)
                  }
                  key={row.original.uuid}
                  className="hover:t-bg-surface-lighter-grey t-px-2 t-py-3"
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Table.Cell
                        key={cell.id}
                        style={{
                          width: `${cell.column.getSize()}%`,
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              )}
            </TableUI>
          </div>
        </Async.Success>
      </Async.Root>
      {selectedSubscriptionId && (
        <RAMailroomSubscriptionSlider
          subscriptionId={selectedSubscriptionId}
          setSubscriptionId={() => setSelectedSubscriptionId(null)}
        />
      )}
    </div>
  );
};
