import { NovuProvider, useNotifications } from "@novu/notification-center";
import Loader from "components/design/loader";
import { LoadingIcon } from "components/icons/LoadingIcon";
import { EmptyScreen, NotificationItem } from "components/Notification";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import authContext from "jwt_context&axios/authContext";
import { useContext } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

dayjs.extend(relativeTime);

const NotificationList = () => {
  const { notifications, fetchNextPage, hasNextPage, isLoading } =
    useNotifications();

  if (isLoading) {
    return <Loader />;
  }

  if (notifications?.length === 0) {
    return <EmptyScreen />;
  }

  return (
    <div
      className="t-flex t-h-full t-flex-col t-overflow-y-auto"
      id="groupInfiniteScroll"
    >
      <InfiniteScroll
        dataLength={notifications?.length || 20}
        next={fetchNextPage}
        hasMore={hasNextPage}
        scrollableTarget="groupInfiniteScroll"
        loader={
          <div className="t-flex t-justify-center t-items-center t-w-full t-gap-2 t-p-2">
            <span className="t-flex t-origin-center t-animate-spin">
              <LoadingIcon />
            </span>
            Loading...
          </div>
        }
      >
        {notifications?.map?.((notification) => (
          <div className="t-max-w-[600px]" key={notification._id}>
            <NotificationItem message={notification} noBorder />
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export const AllNotifications = () => {
  const {
    authtoken: { uuid },
  } = useContext(authContext);

  const applicationIdentifier =
    process.env.PUBLIC_NOVU_NOTIFICATION_APP_IDENTIFIER || "";

  return (
    <NovuProvider
      subscriberId={uuid}
      applicationIdentifier={applicationIdentifier}
      initialFetchingStrategy={{
        fetchNotifications: true,
        fetchUserPreferences: true,
      }}
    >
      <NotificationList />
    </NovuProvider>
  );
};
