import { useStripe } from "@stripe/react-stripe-js";
import { StripeError, PaymentIntent } from "@stripe/stripe-js";
import { profileData } from "apis/profileData";
import {
  BOOKKEEPING,
  BOOKKEEPING_V2,
  REGISTERED_AGENT,
} from "constants/addons";
import { SUBSCRIPTION_CREATED } from "constants/analyticsEvents";
import { PAID, PAYMENT_SUCCESS } from "constants/billing";
import { useAnalytics } from "hooks/useAnalytics";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import { ReactNode, useContext, useState } from "react";
import {
  billingApis,
  useApplyCreditMutation,
  useGetBrexPointsQuery,
} from "store/apis/billing";
import {
  billingInvoiceApis,
  useCreateCreditInvoiceMutation,
  usePayMultipleInvoiceMutation,
} from "store/apis/billingInvoice";
import { useGetCreditsQuery } from "store/apis/refrral";
import {
  IncompletePaymentIntent,
  subscriptionApis,
  useCreateSubscriptionMutation,
  useLazyGetSubscriptionsQuery,
  useSavePaymentMethodMutation,
} from "store/apis/subscriptions";
import { ArrayElement } from "types/utils/ArrayElement";
import { BackendError } from "types/utils/error";
import { CheckoutCart, useSavedCards } from "./CheckoutModal";
import * as Sentry from "@sentry/browser";
import { getInvoices } from "apis/invoices";
import { SubscriptionType, Subscription } from "types/Models/subscription";
import {
  getCreditUsageForBulkInvoices,
  getCreditUsageForCart,
  getCreditUsageForTask,
} from "./creditUtils";
import { Button } from "components/DesignSystem/Button/Button";
import { ThreeDSPaymentFrame } from "components/ThreeDSPaymentFrame";
import { BillingInvoice } from "types/Models/billing";
import {
  CheckoutModalPropsContext,
  CheckoutModalPropsContextType,
} from "./CheckoutModalPropsContext";
import { useBillingAddress } from "./useBillingAddress";
import { useDispatch } from "react-redux";
import {
  useGenerateTaskInvoiceMutation,
  useGenerateTaskInvoiceForFCAMutation,
} from "store/apis/task";
import { useServiceTeamId } from "hooks/useServiceTeamId";
import { useGetPracticeAutofillQuery } from "store/apis/practiceAutofill";
import {
  practiceBillingApi,
  useInitiatePaymentMutation,
  useLazyGetMultipleInvoicesQuery,
} from "store/apis/practiceBilling";
import { useLazyGetTaskByRequestIdQuery } from "store/apis/registrations";
import { RePaymentConfirmationModal } from "./RePaymentConfirmationModal";
import { useModal } from "hooks/useModal";

type GetAllSubscriptionsType = ReturnType<
  typeof useLazyGetSubscriptionsQuery
>[0];

type GetTaskByRequestIdType = ReturnType<
  typeof useLazyGetTaskByRequestIdQuery
>[0];

const startCheckingIfSubscriptionStarted = async (
  groupId: string,
  entityId: string,
  type: keyof typeof SubscriptionType,
  selectedSubscription: string,
  getAllSubscriptions: GetAllSubscriptionsType,
  resolve: (value: any) => void,
  reject: () => void,
  totalSeconds: number = 0
) => {
  const allSubscriptions = await getAllSubscriptions({
    groupId,
    entityId,
  }).unwrap();

  const toSubscribePlan = allSubscriptions?.filter(
    ({ uuid }) => uuid === selectedSubscription
  )?.[0];

  const isSubscribedSuccessfully =
    toSubscribePlan?.group_subscription?.[0]?.group_subscription_id;

  if (totalSeconds === 1 * 60 * 1000) {
    reject();
  }

  if (isSubscribedSuccessfully) {
    resolve(toSubscribePlan);
  } else {
    setTimeout(
      () =>
        startCheckingIfSubscriptionStarted(
          groupId,
          entityId,
          type,
          selectedSubscription,
          getAllSubscriptions,
          resolve,
          reject,
          totalSeconds + 3000
        ),
      3000
    );
  }
};

const startCheckingIfInvoicesArePaid = async (
  groupId: string,
  entityId: string,
  invoiceIds: string[],
  resolve: () => void,
  reject: () => void,
  totalSeconds = 0
) => {
  const {
    data: { data: allInvoices },
  } = await getInvoices({
    invoices: invoiceIds,
    groupId,
    entityId,
  });

  if (totalSeconds === 1 * 60 * 1000) {
    reject();
  }

  const isAllInvoicesPaid = allInvoices.every(
    (i: BillingInvoice) => i.status === PAID
  );

  if (isAllInvoicesPaid) {
    resolve();
  } else {
    setTimeout(
      () =>
        startCheckingIfInvoicesArePaid(
          groupId,
          entityId,
          invoiceIds,
          resolve,
          reject,
          totalSeconds + 2000
        ),
      2000
    );
  }
};

const startCheckingIfTaskCreated = async (
  groupId: string,
  entityId: string,
  invoiceRequestId: string,
  getTaskByRequestId: GetTaskByRequestIdType,
  resolve: () => void,
  reject: () => void,
  totalSeconds = 0
) => {
  const taskData = await getTaskByRequestId({
    groupId: groupId,
    requestId: invoiceRequestId,
    entityId: entityId!,
  }).unwrap();

  if (totalSeconds === 1 * 60 * 1000) {
    reject();
  }

  if (taskData.length > 0) {
    resolve();
  } else {
    setTimeout(
      () =>
        startCheckingIfTaskCreated(
          groupId,
          entityId,
          invoiceRequestId,
          getTaskByRequestId,
          resolve,
          reject,
          totalSeconds + 2000
        ),
      2000
    );
  }
};

const useStartCheckingIfPracticeAllInvocesArePaid = () => {
  const serviceTeamId = useServiceTeamId();
  const [getPracticeInvoices] = useLazyGetMultipleInvoicesQuery();

  const startChecking = async (
    invoiceIds: string[],
    resolve: () => void,
    reject: () => void,
    totalSeconds = 0
  ) => {
    const invoices = await getPracticeInvoices({
      invoicesId: invoiceIds.join(","),
      serviceTeamId,
    }).unwrap();

    if (totalSeconds === 1 * 60 * 1000) {
      reject();
    }

    const isAllInvoicesPaid = invoices.every(
      (invoice) => invoice.status === PAID
    );

    if (isAllInvoicesPaid) {
      resolve();
    } else {
      setTimeout(
        () => startChecking(invoiceIds, resolve, reject, totalSeconds + 2000),
        2000
      );
    }
  };
  return { startChecking };
};

export const PaymentButton = ({ disabled }: { disabled: boolean }) => {
  const propsContext = useContext(CheckoutModalPropsContext);
  const groupId = propsContext?.groupId;
  const stripe = useStripe();
  const repaymentConfirmModal = useModal();

  const [authorizePaymentUrl, setauthorizePaymentUrl] = useState<string | null>(
    null
  );

  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const { authtoken, updateUser } = useContext(authContext);

  const { data: { total_credits } = {} } = useGetCreditsQuery(
    {
      groupId: groupId!,
    },
    { skip: !groupId }
  );
  const { data: brexPoints } = useGetBrexPointsQuery(
    {
      entityId: propsContext?.entityId!,
    },
    {
      skip: !propsContext?.entityId,
    }
  );

  const [getAllSubscriptions] = useLazyGetSubscriptionsQuery({});
  const serviceTeamId = useServiceTeamId();
  const isPayerFCA = propsContext?.payerUserType === "FCA";

  const [createSubscription] = useCreateSubscriptionMutation();
  const [savePaymentMethod] = useSavePaymentMethodMutation();
  const [generatedTaskInvoice] = useGenerateTaskInvoiceMutation();
  const [generateTaskInvoiceForFCA] = useGenerateTaskInvoiceForFCAMutation();
  const [createCreditInvoice] = useCreateCreditInvoiceMutation();
  const [applyCredit] = useApplyCreditMutation();
  const [payMultipleInvoices] = usePayMultipleInvoiceMutation();
  const [initiatePracticePayment] = useInitiatePaymentMutation();
  const { data: savedCards } = useSavedCards({
    serviceTeamId,
    groupId,
    entityId: propsContext?.entityId,
  });
  const { startChecking } = useStartCheckingIfPracticeAllInvocesArePaid();
  const [getTaskByRequestId] = useLazyGetTaskByRequestIdQuery();

  const { alertToast, successToast } = useToast();
  const { trackEvent } = useAnalytics();
  const { billingAddresses: customerBillingAddresses, billingTagId } =
    useBillingAddress({
      groupId,
      entityId: propsContext?.entityId,
    });

  const { data: autoFill = [] } = useGetPracticeAutofillQuery(
    {
      serviceTeamId,
      autofillKey: "addresses",
      tagId: billingTagId,
    },
    {
      skip: !serviceTeamId || !billingTagId,
    }
  );

  const billingAddresses = isPayerFCA ? autoFill : customerBillingAddresses;

  const dispatch = useDispatch();

  if (!stripe) {
    return null;
  }

  const confirmCardPayment = async (
    paymentMethodId: string,
    incompletePaymentIntent: IncompletePaymentIntent,
    doSave?: Boolean
  ) => {
    if (!stripe) {
      return null;
    }

    let confirmationError:
      | null
      | undefined
      | StripeError
      | PaymentIntent.LastPaymentError;

    let defaultCard = false;
    let saveCard = doSave;

    const {
      subscription_status,
      payment_intent_client_secret,
      stripe_user_profile_id,
      payment_not_required,
    } = incompletePaymentIntent;

    const isSubscription = subscription_status;

    if (isSubscription) {
      defaultCard = true;
      saveCard = true;
    }

    try {
      if (!payment_not_required) {
        const { error, paymentIntent } = await stripe.confirmCardPayment(
          payment_intent_client_secret,
          {
            payment_method: paymentMethodId,
            setup_future_usage: defaultCard || saveCard ? "off_session" : null,
            return_url: `${window.location.origin}/payment-confirmation`,
          },
          { handleActions: false }
        );

        if (error) {
          confirmationError = error;
          throw new Error("FAILED_TO_CONFIRM");
        }

        if (paymentIntent?.status === "requires_payment_method") {
          throw new Error(paymentIntent?.last_payment_error?.message);
        }

        // 3DS flow
        if (
          paymentIntent.status === "requires_action" &&
          paymentIntent?.next_action?.redirect_to_url?.url
        ) {
          setauthorizePaymentUrl(paymentIntent.next_action.redirect_to_url.url);

          await new Promise<void>((resolve, reject) => {
            window.addEventListener(
              "message",
              async function (ev) {
                if (ev.data === "3DS-authentication-complete") {
                  resolve();
                }
              },
              false
            );
          });

          setauthorizePaymentUrl(null);

          // Check the PaymentIntent
          if (paymentIntent?.client_secret) {
            const intent = await stripe.retrievePaymentIntent(
              paymentIntent.client_secret
            );

            if (intent.error) {
              setauthorizePaymentUrl(null);
              throw new Error(intent.error.message);
            }

            if (intent.paymentIntent.status === "requires_payment_method") {
              // Authentication failed, prompt the customer to enter another payment method
              confirmationError = intent.paymentIntent.last_payment_error;
              throw Error("FAILED_TO_CONFIRM");
            }
          }
        }
      }

      if (saveCard && groupId && propsContext?.entityId) {
        await savePaymentMethod({
          groupId: groupId,
          stripeUserProfileId: stripe_user_profile_id,
          paymentMethodId,
          isDefaultCard: defaultCard,
          entityId: propsContext?.entityId,
        });
      }
    } catch (error: any) {
      if (error.message === "FAILED_TO_CONFIRM") {
        throw new Error(confirmationError?.message);
      }
      throw error;
    }
  };

  const subscribe = async (
    paymentMethodId: string,
    subscription: ArrayElement<CheckoutCart["cart_items"]>["subscription"],
    scheduleAt?: string
  ) => {
    let incompletePaymentIntent;

    if (subscription && groupId) {
      try {
        let customAddonPayload = {};

        const subscriptionType = subscription.subscription_type;

        if (
          subscriptionType === BOOKKEEPING ||
          subscriptionType === BOOKKEEPING_V2
        ) {
          customAddonPayload = {
            selected_tier_amount: subscription.selectedTierAmount,
          };
        }

        if (subscriptionType === REGISTERED_AGENT) {
          customAddonPayload = {
            ra_state_name:
              subscription.raStateName || subscription?.metadata?.ra_state,
          };
        }

        if (subscription?.cartItemId) {
          customAddonPayload = {
            ...customAddonPayload,
            cart_item_id: subscription.cartItemId,
          };
        }

        const incompleteSubscription = await createSubscription({
          groupId,
          entityId: propsContext?.entityId,
          payload: {
            subscription_uuid: subscription.uuid,
            scheduled_date: scheduleAt,
            ...customAddonPayload,
          },
        }).unwrap();

        incompletePaymentIntent = incompleteSubscription;
      } catch (error) {
        alertToast({ message: "Failed to subscribe, please contact support" });
        Sentry.captureException(new Error("Subscription failed"));
        throw error;
      }

      try {
        await confirmCardPayment(paymentMethodId, incompletePaymentIntent);

        await new Promise((resolve, reject) =>
          startCheckingIfSubscriptionStarted(
            groupId,
            propsContext.entityId,
            subscription.subscription_type,
            subscription.uuid,
            getAllSubscriptions,
            resolve,
            reject
          )
        );
        dispatch(
          subscriptionApis.util.invalidateTags([{ type: "Subscriptions" }])
        );

        const profile = await profileData();
        updateUser(profile);

        trackEvent(SUBSCRIPTION_CREATED, {
          subscription_id: subscription.uuid,
          is_on_free_trial: Boolean(authtoken.is_public_user),
        });

        //  Handle only for subscriptions
        // if (!selectedSubscriptions) {
        //   onSubscribed?.();
        // }
      } catch (error: any) {
        Sentry.captureException(error);
        alertToast({ message: error.message });
        throw error;
      }
    }
  };

  const payForMultipleInvoice = async ({
    paymentMethodId,
    invoiceIds,
    onPaid,
    pendingCreditId,
    groupId,
    entityId,
    noOfcreditsUsed,
    isCreditsUsed,
    messageId,
    isBrexPointsUsed,
    noOfBrexUsdUsed,
    brexQuoteId,
  }: {
    paymentMethodId: string;
    invoiceIds: string[];
    onPaid: () => void;
    pendingCreditId?: string | null;
    groupId: string;
    entityId: string;
    noOfcreditsUsed?: number;
    isCreditsUsed?: boolean;
    isBrexPointsUsed?: boolean;
    noOfBrexUsdUsed?: number;
    brexQuoteId?: string;
    messageId?: string;
  }) => {
    if (invoiceIds.length <= 0) {
      return;
    }

    let incompletePaymentIntent;

    try {
      try {
        const data = await payMultipleInvoices({
          groupId: groupId,
          entityId: entityId,
          payload: {
            credits_used: isCreditsUsed ? noOfcreditsUsed : undefined,
            brex_usd_used: isBrexPointsUsed ? noOfBrexUsdUsed : undefined,
            pending_credit_ledger_record: pendingCreditId,
            invoices: invoiceIds,
            balancing_payment_message_id: messageId,
            brex_quote_id: brexQuoteId,
          },
        }).unwrap();
        incompletePaymentIntent = data;
      } catch (e: any) {
        throw new Error((e as BackendError)?.data?.error?.message);
      }

      try {
        await confirmCardPayment(paymentMethodId, incompletePaymentIntent);

        await new Promise<void>((resolve, reject) =>
          startCheckingIfInvoicesArePaid(
            groupId,
            entityId,
            invoiceIds,
            resolve,
            reject
          )
        );

        dispatch(billingInvoiceApis.util.invalidateTags(["BillingInvoices"]));

        // polling for new task launch
        if (propsContext?.invoiceRequestId) {
          await new Promise<void>((resolve, reject) =>
            startCheckingIfTaskCreated(
              groupId,
              entityId,
              propsContext?.invoiceRequestId!,
              getTaskByRequestId,
              resolve,
              reject
            )
          );
        }
      } catch (error: any) {
        throw error;
      }

      setPaymentProcessing(false);
      successToast({ message: PAYMENT_SUCCESS });
      onPaid();
    } catch (error: any) {
      if (error.message === "3DS_PENDING") {
        return;
      }
      Sentry.captureException(error);
      alertToast({ message: error.message });
      setPaymentProcessing(false);
    }
  };

  const startSubscription = async (
    propsContext: CheckoutModalPropsContextType & {
      type: "subscription";
    }
  ) => {
    const { subscription } = propsContext;
    const defaultCard = savedCards.find((c) => c.is_default_card);
    if (defaultCard) {
      await subscribe(
        defaultCard?.payment_method_id,
        subscription,
        propsContext.scheduleDate
      );

      successToast({
        title: "Subscribed successfully",
        message: `You have subscribed to ${subscription.subscription_name} plan.`,
      });

      propsContext.onSubscribed();
    }
    setPaymentProcessing(false);
  };

  const practicePayment = async ({
    invoices,
    paymentMethodId,
    onPaid,
  }: {
    paymentMethodId: string;
    invoices: string[];
    onPaid: () => void;
  }) => {
    if (invoices.length <= 0) {
      return;
    }

    try {
      const incompletePaymentIntent = await initiatePracticePayment({
        invoices,
        serviceTeamId,
      }).unwrap();

      try {
        await confirmCardPayment(
          paymentMethodId,
          incompletePaymentIntent,
          false
        );

        await new Promise<void>((resolve, reject) =>
          startChecking(invoices, resolve, reject)
        );

        successToast({ message: PAYMENT_SUCCESS });
        dispatch(practiceBillingApi.util.invalidateTags(["PRACTICE_INVOICES"]));
        setPaymentProcessing(false);
        onPaid();
      } catch (error: any) {
        if (error.message === "3DS_PENDING") {
          return;
        }
        Sentry.captureException(error);
        alertToast({ message: error.message });
        setPaymentProcessing(false);
      }
    } catch (error) {
      setPaymentProcessing(false);
      alertToast({
        message: (error as BackendError).data?.error?.message,
      });
    }
  };

  const payForInvoices = async (
    propsContext: CheckoutModalPropsContextType & {
      type: "invoices";
    }
  ) => {
    const {
      paymentMethodId,
      // cart: { cart_items },
      // cartInvoice,
      invoices,
      totalDue,
    } = propsContext;

    if (paymentMethodId) {
      let pendingCreditId;

      const { usableCredits, usableBrexPoints, usableBrexPointsAfterCredits } =
        getCreditUsageForBulkInvoices(
          invoices,
          total_credits || 0,
          brexPoints?.connection?.available_usd,
          totalDue
        );

      const brexUSDtoUse = propsContext.useCredits
        ? usableBrexPointsAfterCredits
        : usableBrexPoints;

      if (propsContext.useCredits || propsContext.useBrexPoints) {
        try {
          const data = await applyCredit({
            invoices: invoices.map((i) => i.uuid),
            entityId: propsContext.entityId,
            is_post_payment: true,
            credits_applied: propsContext.useCredits,
            brex_points_used: propsContext.useBrexPoints,
            brex_quote_id: brexPoints?.connection?.quote_id,
            credits_used: usableCredits,
          }).unwrap();

          if (!data.is_payment_required) {
            setPaymentProcessing(false);
            successToast({ message: PAYMENT_SUCCESS });
            propsContext.onInvoicePaid();
            return;
          }
          pendingCreditId = data.pending_ledger_event_uuid;
        } catch (error) {
          setPaymentProcessing(false);
          alertToast({
            message: (error as BackendError).data?.error?.message,
          });
          return;
        }
      }

      if (isPayerFCA) {
        await practicePayment({
          invoices: invoices.map((i) => i.uuid),
          paymentMethodId,
          onPaid: propsContext.onInvoicePaid,
        });
        return;
      }

      await payForMultipleInvoice({
        paymentMethodId,
        invoiceIds: invoices.map((i) => i.uuid),
        pendingCreditId,
        groupId: propsContext.groupId,
        entityId: propsContext.entityId,
        isCreditsUsed: propsContext.useCredits,
        isBrexPointsUsed: propsContext.useBrexPoints,
        noOfBrexUsdUsed: brexUSDtoUse,
        brexQuoteId: brexPoints?.connection?.quote_id,
        noOfcreditsUsed: usableCredits,
        onPaid: propsContext.onInvoicePaid,
        messageId: propsContext.messageId,
      });
    }
  };

  const payForCredits = async (
    propsContext: CheckoutModalPropsContextType & {
      type: "credits";
    }
  ) => {
    const { paymentMethodId } = propsContext;

    if (paymentMethodId) {
      try {
        const creditInvoice = await createCreditInvoice({
          entityId: propsContext.entityId,
          groupId: propsContext.groupId,
          invoice_amount: propsContext.creditsToBeAdded,
        }).unwrap();

        await payForMultipleInvoice({
          paymentMethodId,
          invoiceIds: [creditInvoice.uuid],
          groupId: propsContext.groupId,
          entityId: propsContext.entityId,
          onPaid: propsContext.onCreditsAdded,
        });
      } catch (error) {
        setPaymentProcessing(false);
        alertToast({
          message: (error as BackendError).data?.error?.message,
        });
        return;
      }
    }
  };

  const payForTask = async (
    propsContext: CheckoutModalPropsContextType & {
      type: "task";
    }
  ) => {
    const { paymentMethodId, task } = propsContext;

    if (paymentMethodId) {
      const { usableCredits, usableBrexPoints, usableBrexPointsAfterCredits } =
        getCreditUsageForTask(
          task,
          total_credits || 0,
          brexPoints?.connection?.available_usd
        );

      const brexUSDtoUse = propsContext.useCredits
        ? usableBrexPointsAfterCredits
        : usableBrexPoints;

      let taskInvoice;
      try {
        if (isPayerFCA) {
          taskInvoice = await generateTaskInvoiceForFCA({
            taskId: task.uuid,
            serviceTeamId,
          }).unwrap();
        } else {
          taskInvoice = await generatedTaskInvoice({
            taskId: task.uuid,
            groupId: propsContext.groupId,
          }).unwrap();
        }

        let pendingCreditId;

        if (propsContext.useCredits || propsContext.useBrexPoints) {
          try {
            const data = await applyCredit({
              invoices: [taskInvoice.uuid],
              entityId: propsContext.entityId,
              is_post_payment: true,
              credits_applied: propsContext.useCredits,
              credits_used: usableCredits,
              brex_points_used: propsContext.useBrexPoints,
              brex_quote_id: brexPoints?.connection?.quote_id,
            }).unwrap();

            if (!data.is_payment_required) {
              setPaymentProcessing(false);
              successToast({ message: PAYMENT_SUCCESS });
              propsContext.onInvoicePaid();
              return;
            }
            pendingCreditId = data.pending_ledger_event_uuid;
          } catch (error) {
            setPaymentProcessing(false);
            alertToast({
              message: (error as BackendError).data?.error?.message,
            });
            return;
          }
        }

        if (isPayerFCA) {
          await practicePayment({
            invoices: [taskInvoice.uuid],
            paymentMethodId,
            onPaid: propsContext.onInvoicePaid,
          });
          return;
        }

        await payForMultipleInvoice({
          paymentMethodId,
          invoiceIds: [taskInvoice.uuid],
          groupId: propsContext.groupId,
          entityId: task.entity.uuid,
          isCreditsUsed: propsContext.useCredits,
          isBrexPointsUsed: propsContext.useBrexPoints,
          brexQuoteId: brexPoints?.connection?.quote_id,
          noOfBrexUsdUsed: brexUSDtoUse,
          pendingCreditId: pendingCreditId,
          noOfcreditsUsed: usableCredits,
          onPaid: propsContext.onInvoicePaid,
          messageId: propsContext.messageId,
        });
      } catch (error) {
        setPaymentProcessing(false);
        alertToast({
          message: (error as BackendError).data?.error?.message,
        });
        return;
      }
    }
  };

  const payForCart = async (
    propsContext: CheckoutModalPropsContextType & {
      type: "cart";
    }
  ) => {
    const {
      paymentMethodId,
      cart: { cart_items },
      cartInvoice,
    } = propsContext;

    const subscriptionItems = cart_items.filter((c) => Boolean(c.subscription));

    if (paymentMethodId) {
      for (let index = 0; index < subscriptionItems.length; index++) {
        const cartItem = subscriptionItems[index];
        const subscription = cartItem.subscription;
        await subscribe(paymentMethodId, subscription, cartItem.from_date);
      }
    }

    if (!cartInvoice) {
      setPaymentProcessing(false);
      successToast({ message: PAYMENT_SUCCESS });
      propsContext.onCartPaid();
      return;
    }

    let pendingCreditId;

    const { usableCredits, usableBrexPoints, usableBrexPointsAfterCredits } =
      getCreditUsageForCart(
        cartInvoice,
        total_credits || 0,
        brexPoints?.connection?.available_usd
      );

    const brexUSDtoUse = propsContext.useCredits
      ? usableBrexPointsAfterCredits
      : usableBrexPoints;

    if (propsContext.useCredits || propsContext.useBrexPoints) {
      try {
        const data = await applyCredit({
          invoices: [cartInvoice.uuid],
          entityId: propsContext.entityId,
          is_post_payment: true,
          credits_applied: propsContext.useCredits,
          credits_used: usableCredits,
          brex_points_used: propsContext.useBrexPoints,
          brex_quote_id: brexPoints?.connection?.quote_id,
        }).unwrap();

        if (!data.is_payment_required) {
          setPaymentProcessing(false);
          successToast({ message: PAYMENT_SUCCESS });
          propsContext.onCartPaid();
          return;
        }
        pendingCreditId = data.pending_ledger_event_uuid;
      } catch (error) {
        setPaymentProcessing(false);
        alertToast({
          message: (error as BackendError).data?.error?.message,
        });
        return;
      }
    }

    if (paymentMethodId) {
      await payForMultipleInvoice({
        paymentMethodId,
        invoiceIds: [cartInvoice.uuid],
        pendingCreditId,
        groupId: propsContext.groupId,
        entityId: propsContext.entityId,
        isCreditsUsed: propsContext.useCredits,
        noOfcreditsUsed: usableCredits,
        isBrexPointsUsed: propsContext.useBrexPoints,
        noOfBrexUsdUsed: brexUSDtoUse,
        brexQuoteId: brexPoints?.connection?.quote_id,
        onPaid: propsContext.onCartPaid,
      });
    }
  };

  const invalidateBrexPointsCache = ({
    useBrexPoints,
  }: CheckoutModalPropsContextType & {
    type: "cart" | "task" | "invoices";
  }) => {
    if (useBrexPoints) {
      dispatch(billingApis.util.invalidateTags(["CONNECTION"]));
    }
  };

  const pay = async () => {
    setPaymentProcessing(true);
    if (propsContext?.type === "cart") {
      await payForCart(propsContext);

      return invalidateBrexPointsCache(propsContext);
    }

    if (propsContext?.type === "credits") {
      return await payForCredits(propsContext);
    }

    if (propsContext?.type === "task") {
      await payForTask(propsContext);

      return invalidateBrexPointsCache(propsContext);
    }

    if (propsContext?.type === "invoices") {
      await payForInvoices(propsContext);

      return invalidateBrexPointsCache(propsContext);
    }

    if (propsContext?.type === "subscription") {
      return await startSubscription(propsContext);
    }

    Sentry.captureException(new Error("Unhandled payment flow detected"));
  };

  let buttonText = "Pay";

  if (
    propsContext?.type === "subscription" &&
    propsContext?.subscription?.amount
  ) {
    const payableAmount = Boolean(
      propsContext?.subscription?.stripe_coupon?.discounted_amount?.toString()
    )
      ? propsContext?.subscription?.stripe_coupon?.discounted_amount
      : propsContext?.subscription?.amount;
    buttonText = `Pay $${payableAmount}`;
  }

  const disablePayButton =
    paymentProcessing ||
    !billingAddresses ||
    billingAddresses.length === 0 ||
    disabled;

  const showConfirmModal =
    propsContext?.type === "invoices" &&
    propsContext.invoices.some((invoice) => invoice.is_in_bank_transfer);

  return (
    <>
      <Button
        customType="primary"
        disabled={disablePayButton}
        onClick={showConfirmModal ? repaymentConfirmModal.open : pay}
        isLoading={paymentProcessing}
      >
        {buttonText}
      </Button>
      <ThreeDSPaymentFrame url={authorizePaymentUrl} />
      <RePaymentConfirmationModal
        show={repaymentConfirmModal.isOpen}
        close={repaymentConfirmModal.close}
        handleClick={() => {
          repaymentConfirmModal.close();
          pay();
        }}
      />
    </>
  );
};
