import { ActionRequiredPopOver } from "components/ActionRequiredPopover/ActionRequiredPopover";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { DashboardLayout } from "components/DashboardLayout";
import { Header } from "components/DesignSystem/Header/Header";
import Tab from "components/DesignSystem/Tab/Tab";
import { AgingSummary } from "components/Reports/AgingSummary";
import { InteractiveReport } from "components/Reports/InteractiveReport";
import { VendorSummary } from "components/Reports/VendorSummary/VendorSummary";
import { YourReports } from "components/Reports/YourReports";
import {
  BALANCE_SHEET,
  CASH_FLOW_STATEMENT,
  GENERAL_LEDGER,
  INCOME_STATEMENT,
  SAVED_REPORTS,
  TRIAL_BALANCE,
} from "constants/bookkeeping";
import { YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { useActionItems } from "hooks/useActionItems";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { parse } from "qs";
import { useEffect, useRef } from "react";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { useReportsPrefetch } from "store/apis/reports";

export type ReportsType =
  | typeof BALANCE_SHEET
  | typeof INCOME_STATEMENT
  | typeof CASH_FLOW_STATEMENT
  | typeof SAVED_REPORTS;

export type GeneralLedgerReportType =
  | typeof GENERAL_LEDGER
  | typeof TRIAL_BALANCE;

export const Reports = () => {
  usePageTitle("Live Financials");
  const entityId = useCurrentEntityId();
  const groupId = useCurrentGroupContext()?.uuid;
  const rRef = useRef(null);
  const { isReconciliationRequired } = useActionItems();
  const { path, url } = useRouteMatch();
  const { pathname, search } = useLocation();

  const isIncomeStatement = pathname.includes("income-statement");
  const isCashFlowStatement = pathname.includes("cash-flow-statement");
  const isAgingSummary = pathname.includes("aging-summary");
  const isSavedReports = pathname.includes("saved-reports");
  const isVendorSummary = pathname.includes("vendor-summary");

  const isBalanceSheet = !(
    isIncomeStatement ||
    isCashFlowStatement ||
    isAgingSummary ||
    isSavedReports ||
    isVendorSummary
  );

  const prefetchLiveReports = useReportsPrefetch("newInteractiveReport");

  useEffect(() => {
    if (entityId) {
      prefetchLiveReports({
        groupId,
        entityId,
        reportType: "INCOME_STATEMENT",
        accountingMethod: "CASH",
        startDate: dayjs().startOf("year").format(YYYY_MM_DD),
        endDate: dayjs().format(YYYY_MM_DD),
        // @ts-ignore
        showNonZeroAccounts: true,
        comparison_report_type: "TOTALS",
      });

      prefetchLiveReports({
        groupId,
        entityId,
        reportType: "CASH_FLOW",
        accountingMethod: "ACCRUAL",
        startDate: dayjs().startOf("year").format(YYYY_MM_DD),
        endDate: dayjs().format(YYYY_MM_DD),
        // @ts-ignore
        showNonZeroAccounts: true,
        comparison_report_type: "TOTALS",
      });
    }
  }, [entityId]);

  const { link } = useConstructInternalLink();

  return (
    <>
      <Tab.Root asChild defaultValue={pathname} value="MATCH">
        <DashboardContainer ref={rRef} className="t-h-full">
          <DashboardContainer.Header className="t-px-10 t-z-header">
            <Header
              v2
              title={
                <div className="t-flex t-items-center t-gap-2 t-h-full">
                  Live Financials
                  {isReconciliationRequired && (
                    <ActionRequiredPopOver
                      type="RECONCILIATION"
                      btnText="Reconcile"
                    />
                  )}
                </div>
              }
              bottom={
                <Tab.List>
                  <Tab.NavTrigger
                    exact
                    to={`${url}${search}`}
                    value={isBalanceSheet ? "MATCH" : ""}
                  >
                    Balance Sheet
                  </Tab.NavTrigger>

                  <Tab.NavTrigger
                    exact
                    to={`${url}/income-statement${search}`}
                    value={isIncomeStatement ? "MATCH" : ""}
                  >
                    Income Statement
                  </Tab.NavTrigger>

                  <Tab.NavTrigger
                    exact
                    to={link(`${url}/cash-flow-statement`, {
                      moreQuery: {
                        ...(parse(search, { ignoreQueryPrefix: true }) as {}),
                        accountingMethod: "ACCRUAL",
                      },
                    })}
                    value={isCashFlowStatement ? "MATCH" : ""}
                  >
                    Cash Flow Statement
                  </Tab.NavTrigger>

                  <Tab.NavTrigger
                    exact
                    to={`${url}/aging-summary${search}`}
                    value={isAgingSummary ? "MATCH" : ""}
                  >
                    Aging Summary
                  </Tab.NavTrigger>

                  <Tab.NavTrigger
                    exact
                    to={`${url}/vendor-summary${search}`}
                    value={isVendorSummary ? "MATCH" : ""}
                  >
                    Vendor Summary
                  </Tab.NavTrigger>

                  <Tab.NavTrigger
                    exact
                    to={`${url}/saved-reports${search}`}
                    value={isSavedReports ? "MATCH" : ""}
                  >
                    Saved Reports
                  </Tab.NavTrigger>
                </Tab.List>
              }
            />
          </DashboardContainer.Header>
          <DashboardContainer.Content>
            <div className="t-h-full t-w-full">
              <Switch>
                <Route exact path={path}>
                  <InteractiveReport reportType={BALANCE_SHEET} />
                </Route>
                <Route exact path={`${path}/income-statement`}>
                  <InteractiveReport reportType={INCOME_STATEMENT} />
                </Route>
                <Route exact path={`${path}/cash-flow-statement`}>
                  <InteractiveReport reportType={CASH_FLOW_STATEMENT} />
                </Route>
                <Route exact path={`${path}/aging-summary`}>
                  <AgingSummary />
                </Route>
                <Route exact path={`${path}/vendor-summary`}>
                  <VendorSummary />
                </Route>
                <Route exact path={`${path}/saved-reports`}>
                  <YourReports />
                </Route>
              </Switch>
            </div>
          </DashboardContainer.Content>
        </DashboardContainer>
      </Tab.Root>
    </>
  );
};
