export const TicketTag = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2192_22811)">
      <path
        d="M11.097 4.51143L6.5885 0.00292969L2.45 0.45143L2.5575 1.44543L6.22 1.04893L10.39 5.21843C10.538 5.36643 10.538 5.60693 10.384 5.76043L9.903 6.25743L5.5495 1.90393L0.981001 2.52843L0.476501 7.08993L4.983 11.5959C5.2435 11.8564 5.5895 11.9999 5.958 11.9999C6.3265 11.9999 6.673 11.8564 6.939 11.5904L10.061 8.36593C10.3935 8.03343 10.519 7.57193 10.4405 7.14043L11.0975 6.46193C11.635 5.92393 11.6345 5.04893 11.097 4.51143ZM3 4.99993C2.724 4.99993 2.5 4.77593 2.5 4.49993C2.5 4.22393 2.724 3.99993 3 3.99993C3.276 3.99993 3.5 4.22393 3.5 4.49993C3.5 4.77593 3.276 4.99993 3 4.99993Z"
        fill="#52835A"
      />
    </g>
    <defs>
      <clipPath id="clip0_2192_22811">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
