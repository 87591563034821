import React from "react";

export const PaymentSuccessCard = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
    >
      <path
        d="M16.5 0.75H1.5C1.16848 0.75 0.850537 0.881696 0.616116 1.11612C0.381696 1.35054 0.25 1.66848 0.25 2V12C0.25 12.3315 0.381696 12.6495 0.616116 12.8839C0.850537 13.1183 1.16848 13.25 1.5 13.25H16.5C16.8315 13.25 17.1495 13.1183 17.3839 12.8839C17.6183 12.6495 17.75 12.3315 17.75 12V2C17.75 1.66848 17.6183 1.35054 17.3839 1.11612C17.1495 0.881696 16.8315 0.75 16.5 0.75ZM9.625 10.75H8.375C8.20924 10.75 8.05027 10.6842 7.93306 10.5669C7.81585 10.4497 7.75 10.2908 7.75 10.125C7.75 9.95924 7.81585 9.80027 7.93306 9.68306C8.05027 9.56585 8.20924 9.5 8.375 9.5H9.625C9.79076 9.5 9.94973 9.56585 10.0669 9.68306C10.1842 9.80027 10.25 9.95924 10.25 10.125C10.25 10.2908 10.1842 10.4497 10.0669 10.5669C9.94973 10.6842 9.79076 10.75 9.625 10.75ZM1.5 3.875V2H16.5V3.875H1.5Z"
        fill="#CFCDD6"
      />
    </svg>
  );
};
