import { useEffect, useRef } from "react";
import { useDebounce } from "utils/debounce";

export const FormikListener: <T extends {}>(arg: {
  values: T;
  callback: (v: T) => void;
  debounce?: boolean;
}) => JSX.Element | null = ({ values, callback, debounce }) => {
  const stringValues = JSON.stringify(values);
  const debouncedValues = useDebounce(stringValues, 500);
  const rendered = useRef(false);
  const finalValues = debounce ? debouncedValues : stringValues;

  useEffect(() => {
    if (rendered.current) {
      callback(values);
    }

    rendered.current = true;
  }, [finalValues]);

  return null;
};
