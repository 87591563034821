import qs from "qs";
import { emptyApi } from "./emptyApi";

export type SupportStepType = "INITIATED" | "IN_PROGRESS" | "COMPLETED";

export type RAMailroomSubscriptionType = {
  uuid: string;
  company: {
    name: string;
    uuid: string;
  };
  entity: {
    name: string;
    uuid: string;
  };
  subscription_name: string;
  subscription_status: "ACTIVE" | "INACTIVE" | "CANCELLED";
  start_date: string | null;
  end_date: string | null;
  assigned_agent: {
    name: string | null;
    profile_id: string | null;
  };
  support_step: SupportStepType;
  subscription_type: "REGISTERED_AGENT" | "MAILROOM";
};

export type RAMailrooms = {
  total_pages: number;
  total_count: number;
  current_page: number;
  per_page: number;
  ra_mailroom_subscriptions: RAMailroomSubscriptionType[];
};

export type RAMailroomPaymentHistoryType = {
  amount: number;
  status: "SUCCESS" | "FAILED";
  created_at: string;
};

export type RAMailroomSubscriptionData = {
  subscription_details: RAMailroomSubscriptionType;
  payment_history: RAMailroomPaymentHistoryType[];
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllRAMailroom: build.query<
      RAMailrooms,
      {
        page_num?: string | null;
        search_term?: string | null;
        profile_ids?: string | null;
        subscription_type?: string | null;
        support_step?: string | null;
        un_assigned_agents: boolean | null;
      }
    >({
      query: ({
        page_num,
        search_term,
        profile_ids,
        subscription_type,
        support_step,
        un_assigned_agents,
      }) => {
        let queryUrl = qs.stringify(
          {
            page_num,
            search_term,
            profile_ids,
            subscription_type,
            support_step,
            un_assigned_agents,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return `/api/inkle/company/track_ra_mailroom/${queryUrl}`;
      },
      providesTags: ["COMPANY_RA_MAILROOM"],
    }),
    getRAMailroomDetail: build.query<
      RAMailroomSubscriptionData,
      { raMailroomId: string }
    >({
      query: ({ raMailroomId }) =>
        `/api/inkle/company/track_ra_mailroom/${raMailroomId}/`,
    }),
    updateRAMailroom: build.mutation<
      RAMailroomSubscriptionType,
      {
        raMailroomId: string;
        payload: {
          profile_id?: string;
          support_step?: SupportStepType;
        };
      }
    >({
      query: ({ raMailroomId, payload }) => {
        return {
          url: `/api/inkle/company/track_ra_mailroom/${raMailroomId}/`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["COMPANY_RA_MAILROOM"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllRAMailroomQuery,
  useGetRAMailroomDetailQuery,
  useUpdateRAMailroomMutation,
} = extendedApi;
