import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { useToast } from "hooks/useToast";
import { useMarkMultipleVendorsRequiredMutation } from "store/apis/vendors";
import { BackendError } from "types/utils/error";

type MarkMultipleMerchantsAsRequiredProps = {
  isOpen: boolean;
  close: () => void;
  entityId: string;
  merchantIds: string[];
  season: string;
  onSuccess: () => void;
};

export const MarkMultipleMerchantsAsRequired = ({
  isOpen,
  close,
  entityId,
  merchantIds,
  season,
  onSuccess,
}: MarkMultipleMerchantsAsRequiredProps) => {
  const { alertToast, successToast } = useToast();
  const [editVendors, { isLoading: isEditing }] =
    useMarkMultipleVendorsRequiredMutation();

  const markMerchantsAsVendor = async () => {
    try {
      await editVendors({
        entityId,
        payload: { merchant_ids: merchantIds, season },
      }).unwrap();
      successToast({ message: "Vendors have been updated" });
      onSuccess();
    } catch (error: any) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content className="!t-w-[440px]">
          <Modal.Header>
            <Modal.Title>Mark tax documents required</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="t-text-body-lg t-text-text-100">
              Customer will be required to collect W-9/s or W8-BEN/s from the
              vendors.
              <br />
              <br />
              Are you sure you want to mark the tax documents required for the
              selected vendors?
            </div>
          </Modal.Body>
          <Modal.FooterButtonGroup>
            <Modal.RawClose asChild>
              <Button type="button">Cancel</Button>
            </Modal.RawClose>
            <Button
              disabled={isEditing}
              customType="primary"
              isLoading={isEditing}
              onClick={markMerchantsAsVendor}
            >
              Confirm
            </Button>
          </Modal.FooterButtonGroup>
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};
