import AnalyseBankStatementImg from "static/images/AnalyseBankStatementImg.svg";

export const AnalyseBankStatement = ({ season }: { season: string }) => {
  return (
    <div className="t-w-full t-h-full t-flex t-justify-center t-items-center">
      <div className="t-w-2/3 t-text-center">
        <img src={AnalyseBankStatementImg} alt="AnalyseBankStatement" />
        <div className="t-text-subtitle t-text-text-100">
          We are analysing your bank statements
        </div>
        <div className="t-text-body t-text-text-30">
          Please wait while we identify all your contractors for the year{" "}
          {season}
        </div>
      </div>
    </div>
  );
};
