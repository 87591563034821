import classNames from "classnames";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { Cross } from "components/icons/Cross";
import { Trash } from "components/icons/Trash";
import { Form, Formik, useFormikContext } from "formik";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { ComponentType, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { MultiValue, SingleValue } from "react-select";
import GrayPencil from "static/images/GrayPencil.svg";
import RedInfo from "static/images/RedFilledInfo.svg";
import { autofillApi, useGetAutofillQuery } from "store/apis/autofill";
import { useUpdateTaskFormDataMutation } from "store/apis/task";
import { AddressAutofill } from "types/Models/addressAutofill";
import { BankAutofill } from "types/Models/bankAutofill";
import {
  AutofillDetailKey,
  FilingFormType,
  RepeatableField,
  Section,
  SectionField,
} from "types/Models/Filing";
import { PersonaAutofill } from "types/Models/personaAutofill";
import { BackendError } from "types/utils/error";
import { DeleteFilingAutofill } from "./DeleteFilingAutofill";
import { FilingInfo } from "./FilingReviewSectionDisplay";
import { SelectedAutofillDetails } from "./SelectedAutofillDetails";

export type SelectAutofillProps = {
  section: Section;
  groupId: string;
  entityId: string;
  formData: FilingFormType;
  autofillKey: string;
  field: SectionField;
  keysToFill: string[];
  deleteModalTitle: string;
  addNewAutofillText: string;
  comboboxName: string;
  currentAutofillDetailKey: string;
  shouldShowDetailsCard: boolean;
  isAnyDetailsMissing: boolean;
  AutofillCreateEdit: ComponentType<any>;
  autofillSuffix?: string;
  repeatableFieldData: RepeatableField;
  fields: SectionField[];
};

export const SelectAutofill = ({
  section,
  groupId,
  entityId,
  formData,
  autofillKey,
  field,
  keysToFill,
  deleteModalTitle,
  addNewAutofillText,
  comboboxName,
  currentAutofillDetailKey,
  shouldShowDetailsCard,
  isAnyDetailsMissing,
  AutofillCreateEdit,
  autofillSuffix,
  repeatableFieldData,
  fields,
}: SelectAutofillProps) => {
  const { data: autofills = [], isLoading } = useGetAutofillQuery(
    {
      groupId,
      autofillKey,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const {
    isOpen: showAddModal,
    close: closeAddModal,
    open: openAddModal,
  } = useModal();
  const {
    isOpen: showDeleteModal,
    close: closeDeleteModal,
    open: openDeleteModal,
  } = useModal();
  const {
    isOpen: showEditModal,
    close: closeEditModal,
    open: openEditModal,
  } = useModal();

  const { values: formValues } = useFormikContext();
  const [updateTaskForm, { isLoading: isUpdatingTask }] =
    useUpdateTaskFormDataMutation();
  const { taskId } = useParams<{ taskId: string }>();
  const { alertToast, successToast } = useToast();
  const [detailsForEdit, setDetailsForEdit] = useState<
    BankAutofill | AddressAutofill | PersonaAutofill | undefined
  >();
  const dispatch = useDispatch();

  const currentSelectedAutofillDetails = autofillSuffix
    ? repeatableFieldData?.selected_autofill_details?.[
        currentAutofillDetailKey as AutofillDetailKey
      ]
    : section.selected_autofill_details?.[
        currentAutofillDetailKey as AutofillDetailKey
      ];

  const getUpdatedField = (
    fieldKey: string,
    field: SectionField,
    values: any
  ) => {
    let keyToFill = keysToFill.find((key) => fieldKey?.includes(key));

    if (keyToFill && keyToFill === "bank_brand_name") {
      return { ...field, value: values.name || "" };
    }
    if (keyToFill) {
      return { ...field, value: values[keyToFill] };
    }

    //@ts-ignore
    return { ...field, value: formValues?.[fieldKey] || field.value };
  };

  const getRepeatableUpdatedField = (
    fieldKey: string,
    field: SectionField,
    values: any
  ) => {
    let keysToFillWithoutSuffix = keysToFill.map((autofillKey) =>
      autofillKey?.replace(`_${autofillSuffix}`, "")
    );
    let keyToFill = keysToFillWithoutSuffix.find((autofillKey, i) => {
      return autofillKey != "" && fieldKey.includes(autofillKey);
    });
    if (keyToFill && keyToFill === "bank_brand_name") {
      return { ...field, value: values.name || "" };
    }

    if (keyToFill) {
      return { ...field, value: values[keyToFill] };
    }
    //@ts-ignore
    return { ...field, value: formValues?.[fieldKey] || field.value };
  };

  const updateAutofillSection = (updatedFieldsWithValue: {
    section_fields: {}[];
  }) => {
    return formData?.data?.data?.section_group?.[0].sections.map(
      (currentSection: { [x: string]: any | {}[]; section_key: string }) => {
        if (currentSection.section_key === section.section_key) {
          return updatedFieldsWithValue;
        }
        return {
          ...currentSection,
          section_fields: currentSection?.section_fields?.map(
            (sectionField: SectionField) => ({
              ...sectionField,
              value:
                //@ts-ignore
                formValues?.[sectionField.field_key] || sectionField?.value,
            })
          ),
        };
      }
    );
  };

  const getPayload = (updatedSections: any) => {
    const payload = {
      form_data: {
        ...formData,
        data: {
          ...formData.data.data,
          section_group: [{ sections: updatedSections }],
        },
      },
    };
    return payload;
  };

  const onComplete = async () => {
    try {
      dispatch(autofillApi.util.invalidateTags(["AUTOFILL"]));
      closeAddModal();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const updateTaskSection = async (
    autofillValue: SingleValue<OptionData> | MultiValue<OptionData> = []
  ) => {
    try {
      const selectedAutofill = (autofillValue as SingleValue<OptionData>)
        ?.value;
      const valuesToSet = autofills.find(
        ({ uuid }) => uuid === selectedAutofill
      );

      await updateTaskDetails(valuesToSet!);
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const handleDelete = async () => {
    try {
      const valuesToSet = keysToFill.map((key) => ({ [key]: "" }));
      await updateTaskDetails(valuesToSet, {});
      closeDeleteModal();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
      closeDeleteModal();
    }
  };

  const updateTaskDetails = async (
    autofill:
      | BankAutofill
      | AddressAutofill
      | PersonaAutofill
      | { [x: string]: string }[],
    selectedAutofillDetails?: any
  ) => {
    try {
      let updatedFieldWithValue: any = {};

      // repeatable field logic
      if (autofillSuffix) {
        updatedFieldWithValue = {
          ...section,
          repeatable_fields: section.repeatable_fields?.map(
            (repeatableField) => {
              if (repeatableField.id != Number(autofillSuffix)) {
                return repeatableField;
              }
              return {
                ...repeatableField,
                fields: repeatableField?.fields?.map((field: SectionField) => {
                  return getRepeatableUpdatedField(
                    field.field_key,
                    field,
                    autofill
                  );
                }),
                selected_autofill_details: {
                  ...repeatableFieldData.selected_autofill_details,
                  [currentAutofillDetailKey]:
                    selectedAutofillDetails || autofill,
                },
              };
            }
          ),
        };
      } else {
        updatedFieldWithValue = {
          ...section,
          selected_autofill_details: {
            ...section.selected_autofill_details,
            [currentAutofillDetailKey]: selectedAutofillDetails || autofill,
          },
          section_fields: section.section_fields?.map((field: SectionField) => {
            return getUpdatedField(field.field_key, field, autofill);
          }),
        };
      }
      const updatedSections = updateAutofillSection(updatedFieldWithValue);
      const payload = getPayload(updatedSections);

      await updateTaskForm({
        formId: formData.uuid,
        payload,
        taskId,
      }).unwrap();
      successToast({ message: "Task updated successfully!" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const openEdit = (
    autofillDetails: BankAutofill | AddressAutofill | PersonaAutofill
  ) => {
    setDetailsForEdit(autofillDetails);
    openEditModal();
  };

  const closeEdit = () => {
    setDetailsForEdit(undefined);
    closeEditModal();
  };

  const onClose = () => {
    closeAddModal();
    closeEdit();
  };

  const defaultSelected = currentSelectedAutofillDetails?.autofill_string
    ? {
        label: (
          <div className="t-text-body t-w-full t-truncate">
            {currentSelectedAutofillDetails?.autofill_string}
          </div>
        ),
        value: currentSelectedAutofillDetails?.uuid!,
      }
    : null;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div>
        <Formik
          validateOnChange={false}
          enableReinitialize
          initialValues={{
            bank: {},
          }}
          onSubmit={() => {}}
        >
          <Form className="t-m-0 t-mb-6">
            <Combobox
              actions={
                <Button customType="link" onClick={openAddModal}>
                  {addNewAutofillText}
                </Button>
              }
              components={{ ClearIndicator: () => null }}
              onChange={updateTaskSection}
              value={defaultSelected}
              withForm
              name={comboboxName}
              label={field.field_label}
              placeholder={field.placeholder}
              options={autofills.map((autofill) => {
                return {
                  label: (
                    <div className="t-text-body t-w-full t-truncate">
                      {autofill.autofill_string}
                    </div>
                  ),
                  value: autofill.uuid,
                };
              })}
              isLoading={isUpdatingTask}
            />
          </Form>
        </Formik>
        {shouldShowDetailsCard && (
          <div className="t-mb-3">
            <div
              className={classNames(
                "t-border t-border-solid t-border-neutral-0 t-p-4 t-rounded-lg",
                {
                  "t-border-red-30": isAnyDetailsMissing,
                }
              )}
            >
              <div className="t-flex t-justify-between">
                <div className="t-grid t-gap-x-20 t-gap-y-4 t-grid-cols-2 t-w-4/5">
                  {keysToFill
                    .filter((keyToFill) => !keyToFill.includes("autofill"))
                    ?.map((keyToFill, index) => {
                      const fieldData = autofillSuffix
                        ? repeatableFieldData?.fields?.find(
                            (field: SectionField) =>
                              field.field_key?.includes(keyToFill)
                          )
                        : fields?.find((field: SectionField) =>
                            field.field_key?.includes(keyToFill)
                          );

                      if (
                        !fieldData ||
                        fieldData?.type?.includes("autofill") ||
                        fieldData?.field_key?.includes("last_name")
                      ) {
                        return null;
                      }

                      if (fieldData?.field_key?.includes("first_name")) {
                        const lastName = autofillSuffix
                          ? repeatableFieldData?.fields?.find(
                              (field: SectionField) =>
                                field.field_key?.includes("last_name")
                            )?.value
                          : fields?.find((field: SectionField) =>
                              field.field_key?.includes("last_name")
                            )?.value;

                        return (
                          <FilingInfo
                            key={index}
                            label="Full Name"
                            value={fieldData.value + " " + lastName}
                          />
                        );
                      }

                      return (
                        <FilingInfo
                          key={index}
                          label={fieldData.field_label}
                          value={fieldData.value}
                        />
                      );
                    })}
                </div>
                <div className="t-flex t-gap-2">
                  <Button
                    onClick={() => openEdit(currentSelectedAutofillDetails!)}
                    customType="transparent"
                    size="small"
                    type="button"
                  >
                    <img
                      src={GrayPencil}
                      className="t-h-4 t-m-1"
                      alt="GrayPencil"
                    />
                  </Button>
                  <Button
                    onClick={openDeleteModal}
                    customType="transparent"
                    size="small"
                    type="button"
                  >
                    <span className="t-text-text-30">
                      <Cross />
                    </span>
                  </Button>
                </div>
              </div>
            </div>

            {isAnyDetailsMissing && (
              <div className="t-text-red t-text-body-sm t-mt-1 t-flex t-items-center t-gap-1">
                <img src={RedInfo} alt="RedInfo" />
                <div>
                  Please update missing information. Edit to complete the
                  required fields
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <DeleteFilingAutofill
        isOpen={showDeleteModal}
        close={closeDeleteModal}
        handleDeleteBank={handleDelete}
        isLoading={isUpdatingTask}
        title={deleteModalTitle}
      />
      <AutofillCreateEdit
        isOpenAdd={showAddModal}
        isOpenEdit={showEditModal}
        onClose={onClose}
        entityId={entityId}
        onSuccess={updateTaskDetails}
        groupId={groupId}
        onComplete={onComplete}
        isEditFlow={showEditModal}
        autofillKey={autofillKey}
        //@ts-ignore
        initialValue={detailsForEdit}
      />
    </>
  );
};
