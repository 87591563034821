import { DashboardLayout } from "components/DashboardLayout";
import { Header } from "components/DesignSystem/Header/Header";
import { REPORT_TYPE } from "dictionaries";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  useGetInteractiveReportQuery,
  useUpdateInteractiveReportMutation,
  ViewByOption,
} from "store/apis/reports";
import ReportsAccordion from "./ReportsAccordion";
import { Account, InteractiveReport } from "types/Models/report";
import { ConditionalLink } from "components/conditionalLink";
import { Redirect } from "components/icons/Redirect";
import { ReportDownload } from "./InteractiveReport";
import dayjs from "dayjs";
import { DD_MMM_YYYY, DD_MMM_YYYY_LT } from "constants/date";
import { Button } from "components/DesignSystem/Button/Button";
import { useToast } from "hooks/useToast";
import { BackendError } from "types/utils/error";
import { ChangeEvent, useRef } from "react";
import { debounce } from "utils/debouncing";
import Loader from "components/design/loader";
import {
  BALANCE_SHEET,
  CASH_FLOW_STATEMENT,
  INCOME_STATEMENT,
  SAVED_REPORTS,
} from "constants/bookkeeping";
import { redirectToCategories } from "utils/redirectToCategories";
import { getFilterStatus } from "store/selector/reportFilter";
import { useSelector } from "react-redux";
import { TransactionSlider } from "components/Transaction/Slider/TransactionSlider";
import { ComparisonReport } from "./ComparisonReport";
import { ReportsType } from "pages/Books/Reports/Reports";
import { useAppSelector } from "hooks/useAppSelector";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import * as Popover from "@radix-ui/react-popover";
import ThreeDots from "static/images/ThreeDots.svg";
import { AddCommentPop } from "components/AddComment/AddComment";
import { AddCommentPopoverRoot } from "components/AddCommentPopoverRoot/AddCommentPopoverRoot";
import { capitalize } from "utils/capitalize";

const DetailItem = ({
  title,
  value,
}: {
  title: string;
  value?: string | null;
}) => {
  return (
    <div className="t-flex t-justify-between t-w-full">
      <div className="t-text-body-sm t-text-text-30">{title}</div>
      <div className="t-text-body">{value}</div>
    </div>
  );
};

export const ReportViewWithId = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const history = useHistory();
  const { reportId } = useParams<{ reportId: string }>();
  const { isCpa, isForeignCA, isCustomer, isAdmin } = useRoleBasedView();
  const { alertToast } = useToast();
  const { getFilterName, capsuleFilters } = useSelector(getFilterStatus);

  const { data, isLoading, isUninitialized } = useGetInteractiveReportQuery(
    { groupId, entityId, reportId },
    { skip: !groupId || !entityId || !reportId }
  );

  const {
    isOpen: isAddToClosingModalOpen,
    close: closeAddToClosingModal,
    open: openAddToClosing,
  } = useModal();

  const {
    report_data,
    created_at,
    start_date,
    end_date,
    type,
    name,
    uuid,
    document_id,
    accounting_method = "CASH",
    comparison_report_type = "TOTALS",
    excel_document_id,
  } = (data as InteractiveReport) || {};

  const onNewReport = () => {
    const reportTypeRoute = {
      [BALANCE_SHEET]: "",
      [INCOME_STATEMENT]: "income-statement",
      [CASH_FLOW_STATEMENT]: "cash-flow-statement",
      [SAVED_REPORTS]: "saved-reports",
    };

    if (!type) return;

    history.push(
      isCustomer
        ? `/books/live-financials/${reportTypeRoute[type]}?entity=${entityId}`
        : `/books/live-financials/${reportTypeRoute[type]}?company=${groupId}&entity=${entityId}`
    );
  };

  const reportBreadcrumbs = [
    {
      name: "Live Financials",
      link: isCustomer
        ? `/books/live-financials/saved-reports?entity=${entityId}`
        : `/books/live-financials/saved-reports?company=${groupId}&entity=${entityId}`,
    },
    { name: REPORT_TYPE[type!] },
  ];

  const [updateReport, { isLoading: updating }] =
    useUpdateInteractiveReportMutation();

  const renameReport = debounce((e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    if (name) {
      try {
        updateReport({
          groupId,
          entityId,
          reportId: reportId!,
          payload: {
            report_name: name,
          },
        }).unwrap();
      } catch (error) {
        alertToast({
          message: (error as BackendError)?.data?.error?.message,
        });
      }
    }
  });

  const portalContainer = useRef<HTMLDivElement>(null);

  if (isLoading || isUninitialized) {
    return <Loader />;
  }

  return (
    <DashboardLayout
      header={
        <Header
          v2
          left={
            <input
              onChange={renameReport}
              className="all:unset t-border t-border-l-0 t-border-r-0 t-border-t-0 t-border-dotted t-text-body t-font-semibold md:t-text-[20px] md:t-font-bold t-w-1/2"
              defaultValue={
                REPORT_TYPE[data?.report_name || ""] || data?.report_name
              }
            />
          }
          breadcrumbs={reportBreadcrumbs}
        />
      }
    >
      <div
        className="t-grid t-grid-cols-3 t-w-full t-gap-7 t-h-full"
        ref={portalContainer}
      >
        <div className="t-col-span-2">
          {comparison_report_type === "TOTALS" ? (
            <>
              <ReportsAccordion.Root
                startDate={start_date}
                endDate={end_date}
                accountingMethod={accounting_method}
                className="t-w-full t-shadow-[0px_1px_8px_-1px_#1F0C5C14] t-h-full t-overflow-y-auto t-rounded-lg t-boder t-border-neutral t-p-2 t-mt-1 t-pb-20"
                reportType={type!}
              >
                <ReportsAccordion.Trigger
                  data={report_data?.accounts}
                  secondaryAction={({ uuid }: Account) => (
                    <ConditionalLink
                      to={redirectToCategories({
                        uuid,
                        groupId,
                        entityId,
                        capsuleFilters,
                        getFilterName,
                        isAdmin,
                      })}
                    >
                      <Redirect size={16} />
                    </ConditionalLink>
                  )}
                  primaryAction={({ uuid }: Account) => (
                    <AddCommentPopoverRoot contentId={uuid}>
                      <Dropdown.Root>
                        <Dropdown.Trigger>
                          <Popover.Anchor asChild>
                            <Button size="small" customType="ghost_icon">
                              <img
                                src={ThreeDots}
                                alt="Action"
                                className="t-select-none"
                              />
                            </Button>
                          </Popover.Anchor>
                        </Dropdown.Trigger>
                        <Popover.Portal container={portalContainer.current}>
                          <AddCommentPop
                            commentType="TRANSACTION_CATEGORY_COMMENT"
                            contentId={uuid}
                          />
                        </Popover.Portal>
                        <Dropdown.Content>
                          <Popover.Trigger asChild>
                            <Dropdown.Item>Add comment</Dropdown.Item>
                          </Popover.Trigger>
                        </Dropdown.Content>
                      </Dropdown.Root>
                    </AddCommentPopoverRoot>
                  )}
                />
              </ReportsAccordion.Root>
            </>
          ) : (
            <ComparisonReport
              reportType={report_data?.report_type! as ReportsType}
              reportId={reportId}
            />
          )}
        </div>

        <div className="t-flex t-col-span-1 t-flex-col t-gap-8 t-sticky md:t-top-0 t-h-min">
          <div className="t-flex t-flex-col t-gap-6">
            <div className="t-text-subtitle">Details</div>

            <div className="t-flex t-flex-col t-gap-2">
              <DetailItem
                title="From:"
                value={start_date ? dayjs(start_date).format(DD_MMM_YYYY) : "-"}
              />
              <DetailItem
                title="To:"
                value={end_date ? dayjs(end_date).format(DD_MMM_YYYY) : "-"}
              />
              <DetailItem
                title="Accounting method:"
                value={capitalize(accounting_method!)}
              />
              <DetailItem
                title="Created On:"
                value={dayjs(created_at).format(DD_MMM_YYYY_LT)}
              />
              <DetailItem title="Created By:" value={name} />
            </div>
          </div>
          <div className="t-flex t-gap-2">
            <ReportDownload
              reportType={type!}
              isTotalView={comparison_report_type === "TOTALS"}
              comparisonReportType={comparison_report_type}
              documentId={document_id}
              excelDocumentId={excel_document_id}
            />
            <Button size="small" onClick={onNewReport}>
              New report
            </Button>
            {/* TODO: handle "Add to closing" with new Financial closing */}
            {/* {(isCpa || isForeignCA) && (
              <Button size="small" onClick={openAddToClosing}>
                Add to closing
              </Button>
            )} */}
          </div>
        </div>
      </div>
      <TransactionSlider />
    </DashboardLayout>
  );
};
