import { ParentSelector } from "components/ChartOfAccounts/ParentSelector";
import { CategoryLabel } from "components/design/CategoryLabel";
import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import {
  coaOptions,
  CategoryIndent,
} from "components/Transaction/TransactionColumn";
import { useChartOfAccounts } from "hooks/useChartOfAccounts";
import { useModal } from "hooks/useModal";
import { MultiValue, SingleValue } from "react-select";
import { HiddenCategoryTypes } from "store/apis/chartOfAccounts";
import { flattenTypes } from "utils/flattenCOA";

export const CategorySelector = ({
  name,
  category,
  hiddenCategories = [],
  label,
  hideError = true,
  onChange: onChangeFromProps,
  withForm = true,
  isDisabled = false,
  hiddenCategoryTypes = ["BANK_ACCOUNT"],
}: {
  name: string;
  label?: string;
  category?: string;
  hiddenCategories?: string[];
  hiddenCategoryTypes?: HiddenCategoryTypes[];
  hideError?: boolean;
  onChange?: (
    value: {
      label: string;
      value: string;
      categoryType: string;
      data: string;
    } | null
  ) => void;
  withForm?: boolean;
  isDisabled?: boolean;
}) => {
  const { isOpen, close, open } = useModal();
  const { chartOfAccounts = [], isLoading } = useChartOfAccounts({
    hiddenCategoryTypes,
  });

  const defaultAssignedCategory = flattenTypes({
    accounts: chartOfAccounts,
  }).find(({ uuid }) => uuid === category);

  const onChange = (
    value:
      | SingleValue<{
          label: string;
          value: string;
          categoryType: string;
          data: string;
        }>
      | MultiValue<OptionData>
  ) => {
    if (value instanceof Array) {
      return;
    }

    onChangeFromProps?.(value);
  };

  return (
    <div className="t-group t-select-none" onClick={(e) => e.stopPropagation()}>
      <Combobox
        aria-label="Category selector"
        isLoading={isLoading}
        actions={
          <Button customType="link" onClick={open}>
            Add a new account
          </Button>
        }
        styles={{
          menuPortal: (base) => ({
            ...base,
            width: 450,
          }),
        }}
        name={name}
        withForm={withForm}
        hideError={hideError}
        // @ts-ignore - options not updated based on this usecase
        onChange={onChange}
        placeholder="Select"
        menuPortalTarget={document.body}
        options={coaOptions(chartOfAccounts, 0, true, hiddenCategories)}
        filterOption={(v, i) =>
          v.data.data?.toLocaleLowerCase().includes(i.toLocaleLowerCase()) ||
          false
        }
        isDisabled={isDisabled}
        label={label}
        value={
          category
            ? {
                label: (
                  <CategoryIndent
                    label={<CategoryLabel category={defaultAssignedCategory} />}
                    indentLevel={0}
                  />
                ),
                value: defaultAssignedCategory?.uuid || "",
              }
            : null
        }
      />
      <ParentSelector
        isOpen={isOpen}
        close={close}
        open={open}
        onSuccess={() => close()}
      />
    </div>
  );
};
