import TicketCreated from "static/images/TicketCreated.svg";
import TicketResolve from "static/images/TicketResolve.svg";
import TicketUnpaid from "static/images/TicketUnpaid.svg";
import TicketUpdated from "static/images/TicketUpdated.svg";
import {
  TICKET_CREATED,
  TICKET_RESOLVED,
  TICKET_PAYMENT,
  TICKET_UPDATED,
  TICKET_PAID,
} from "constants/ticket";
import { CardStatusType } from "types/Models/reviewDocumentAndBalancingPayment";

export type TicketCardStatusType =
  | typeof TICKET_CREATED
  | typeof TICKET_RESOLVED
  | typeof TICKET_PAYMENT
  | typeof TICKET_UPDATED
  | typeof TICKET_PAID;

export const TicketCardIcon = ({
  status,
}: {
  status: TicketCardStatusType | CardStatusType;
}) => {
  switch (status) {
    case TICKET_CREATED: {
      return <img src={TicketCreated} alt="TicketCreated" />;
    }
    case TICKET_PAID:
    case TICKET_RESOLVED: {
      return <img src={TicketResolve} alt="TicketResolve" />;
    }
    case TICKET_PAYMENT: {
      return <img src={TicketUnpaid} alt="TicketUnpaid" />;
    }
    case TICKET_UPDATED: {
      return <img src={TicketUpdated} alt="TicketUpdated" />;
    }
    default: {
      return <></>;
    }
  }
};

export const getTicketCardStatus = (
  status: TicketCardStatusType | CardStatusType
) => {
  switch (status) {
    case TICKET_CREATED:
      return "Ticket Created";
    case TICKET_RESOLVED:
      return "Ticket Resolved";
    case TICKET_PAYMENT:
      return "Ticket Payment";
    case TICKET_UPDATED:
      return "Ticket Updated";
    case TICKET_PAID:
      return "Ticket Paid";
    default:
      return "";
  }
};
