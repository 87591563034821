import { Header } from "components/DesignSystem/Header/Header";
import { CaretRight } from "components/icons/CaretRight";

export const InvoiceHeader = ({
  title,
  onBackClick,
  right,
  status,
}: {
  onBackClick?: () => void;
  right?: React.ReactNode;
  title: React.ReactNode;
  status: React.ReactNode;
}) => {
  return (
    <div className="t-flex t-gap-2 t-items-center t-w-full">
      {onBackClick && (
        <button
          className="all:unset t-rounded-full t-border t-border-solid t-border-neutral-10 t-bg-white t-rotate-180 t-h-8 t-w-8 t-items-center t-flex t-justify-center hover:t-bg-neutral-0 t-text-text-black"
          onClick={onBackClick}
        >
          <CaretRight />
        </button>
      )}

      <div className="t-w-full">
        <Header
          v2
          title={
            <div className="t-flex t-items-center t-justify-center t-gap-3">
              <h5 className="t-text-h5 t-font-bold t-m-0">{title}</h5>
              {status}
            </div>
          }
          right={right}
        />
      </div>
    </div>
  );
};
