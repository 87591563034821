import { FileObjectWithTypeOverride } from "components/documents/RecentDocument";
import qs from "qs";
import { fileAndFolder, parents_list } from "types/Models/documents";
import { emptyApi } from "./emptyApi";
import { FileUrl } from "types/Models/fileObject";

type Result = {
  files: fileAndFolder[];
  parents_list: parents_list[];
  subfolders: fileAndFolder[];
};

export const documentApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getTagDocuments: build.query<
      Result,
      {
        tag_title?: string;
        groupId: string;
      }
    >({
      query: ({ tag_title, groupId }) => {
        let query = qs.stringify(
          {
            tag_title,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/documents/group/${groupId}/tag-documents/${query}`,
        };
      },
    }),

    getFolderContent: build.query<
      Result,
      {
        folderId: string;
        groupId: string;
      }
    >({
      query: ({ folderId, groupId }) => {
        return {
          url: `/api/inkle/documents/group/${groupId}/folder/${folderId}/list/`,
        };
      },
      providesTags: ["DOCUMENT_LIST"],
    }),

    getSearchContent: build.query<
      Result,
      {
        groupId: string;
        searchTerm: string | null;
        pageNum: number;
      }
    >({
      query: ({ groupId, searchTerm, pageNum }) => {
        let query = qs.stringify(
          {
            search_term: searchTerm,
            page_num: pageNum,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/documents/group/${groupId}/search/${query}`,
        };
      },
    }),
    exportFolder: build.mutation<FileUrl, { groupId: string; fileId?: string }>(
      {
        query: ({ groupId, fileId }) => {
          return {
            url: `/api/inkle/documents/group/${groupId}/export/`,
            method: "POST",
            body: { file_ids: fileId },
          };
        },
      }
    ),
    recentDocuments: build.query<
      FileObjectWithTypeOverride,
      { groupId: string }
    >({
      query: ({ groupId }) => {
        return {
          url: `/api/inkle/documents/group/${groupId}/recent-documents/`,
        };
      },
      providesTags: ["RECENT_DOCUMENT"],
    }),
    renameFileFolder: build.mutation<
      any,
      {
        groupId: string;
        fileId?: string;
        payload: { file_name: string };
      }
    >({
      query: ({ groupId, fileId, payload }) => {
        return {
          url: `/api/inkle/documents/group/${groupId}/file/${fileId}/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["RECENT_DOCUMENT"],
    }),
    deleteFileFolder: build.mutation<
      any,
      {
        groupId: string;
        folderId?: string;
      }
    >({
      query: ({ groupId, folderId }) => {
        return {
          url: `/api/inkle/documents/group/${groupId}/file/${folderId}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["RECENT_DOCUMENT"],
    }),

    uploadFile: build.mutation<
      fileAndFolder[],
      {
        groupId: string;
        parentFolderId: string;
        files: File[];
      }
    >({
      query: ({ groupId, parentFolderId, files }) => {
        const formData = new FormData();
        formData.append("parent_uuid", parentFolderId);
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i]);
        }

        return {
          url: `/api/inkle/documents/group/${groupId}/files/upload/`,
          method: "POST",
          body: formData,
        };
      },
    }),

    createFolder: build.mutation<
      fileAndFolder,
      {
        groupId: string;
        parentFolderId: string;
        folder_name: string;
      }
    >({
      query: ({ groupId, parentFolderId, folder_name }) => {
        return {
          url: `/api/inkle/documents/group/${groupId}/folder/create/`,
          method: "POST",
          body: { folder_name, parent_uuid: parentFolderId },
        };
      },
      invalidatesTags: ["DOCUMENT_LIST"],
    }),

    moveDocItems: build.mutation<
      fileAndFolder,
      {
        groupId: string;
        file_uuids: string[];
        destination_folder_id: string;
      }
    >({
      query: ({ groupId, file_uuids, destination_folder_id }) => {
        return {
          url: `/api/inkle/documents/group/${groupId}/files/move/`,
          method: "POST",
          body: { destination_folder_id, file_uuids },
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetTagDocumentsQuery,
  useGetFolderContentQuery,
  useLazyGetFolderContentQuery,
  useGetSearchContentQuery,
  useLazyGetSearchContentQuery,
  useExportFolderMutation,
  useRecentDocumentsQuery,
  useRenameFileFolderMutation,
  useDeleteFileFolderMutation,
  useUploadFileMutation,
  useCreateFolderMutation,
  useMoveDocItemsMutation,
} = documentApi;
