import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useDispatch, useSelector } from "react-redux";
import { useSubmitClarificationMutation } from "store/apis/openItem";
import { closeCalrifyModal } from "store/slices/openItem";
import { RootState } from "store/store";
import { OpenItemTable } from "./OpenItemTable";
import { actionItemsApi } from "store/apis/actionItems";

export const ClarifyModal = () => {
  const dispatch = useDispatch();
  const { alertToast, successToast } = useToast();
  const { isCustomer } = useRoleBasedView();
  const { activeChannelGroupId: groupId } = useSelector(
    (state: RootState) => state.reviewAndBalancePayment
  );
  const [submitClarification] = useSubmitClarificationMutation();
  const { csvId, messageId, show, status } = useSelector(
    (state: RootState) => state.openItem.calrifyModal
  );

  const onClose = () => {
    dispatch(closeCalrifyModal());
  };

  const onSubmit = async () => {
    try {
      await submitClarification({ groupId, messageId, csvId }).unwrap();
      successToast({ message: "Submitted" });
      setTimeout(() => {
        dispatch(
          actionItemsApi.util.invalidateTags([{ type: "ALL_ACTION_ITEMS" }])
        );
      }, 1000);
      onClose();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  return (
    <Modal.Root open={show} onOpenChange={onClose}>
      <Modal.Content size="xxl">
        <Modal.Header>
          <Modal.Title>
            {status === "REQUESTED" ? "Clarify open items" : "Open items"}
          </Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <OpenItemTable csvId={csvId} />
        </Modal.Body>
        {status === "REQUESTED" && isCustomer && (
          <Modal.Footer className="t-flex t-justify-end t-gap-3">
            <Button customType="primary" type="submit" onClick={onSubmit}>
              Submit
            </Button>
          </Modal.Footer>
        )}
      </Modal.Content>
    </Modal.Root>
  );
};
