import ChatCard from "components/DesignSystem/ChatCard/ChatCard";
import { useDispatch } from "react-redux";
import { openAddRequestInfoModal } from "store/slices/openItem";
import { OpenItemStatus } from "types/Models/openItem";
import { pluralize } from "utils/pluralize";
import { ChatCardButton } from "./ChatCardButton";
import { InfoRequestedHeader } from "components/icons/InfoRequestedHeader";
import ArrowsDownUp from "static/images/ArrowsDownUp.svg";
import TinySuccess from "static/images/TinySuccess.svg";

export const RequestedInfoChatCard = ({
  customData,
  groupId,
  messageId,
  channelId,
  size,
}: {
  customData: {
    card_status: OpenItemStatus;
    csv_id: string;
    header: string;
    status: OpenItemStatus;
    transaction_count: 1;
    transaction_ids: string;
  };
  groupId: string;
  messageId: string;
  channelId: string;
  size?: "regular" | "block";
}) => {
  const dispatch = useDispatch();
  const isRequested = customData.status === "REQUESTED";

  const onAction = () => {
    dispatch(
      openAddRequestInfoModal({
        csvId: customData?.csv_id,
        groupId,
        messageId,
        channelId,
      })
    );
  };

  const urlText = isRequested ? "Add Info" : "View Info";

  return (
    <ChatCard.Root size={size}>
      <ChatCard.Header>
        <div className="t-gap-1.5 t-flex t-items-center">
          <InfoRequestedHeader />
          <div>{customData.header}</div>
        </div>
      </ChatCard.Header>
      <ChatCard.Body>
        <div className="t-flex t-gap-3 t-items-center">
          <div className="t-h-6 t-w-6 t-rounded-full t-border t-border-solid t-border-neutral-0 t-flex t-items-center t-justify-center t-relative">
            <div className="t-text-neutral -t-mt-0.5">
              <img src={ArrowsDownUp} alt="ArrowsDownUp" />
            </div>
            {isRequested ? (
              <div className="t-min-w-1.5 t-min-h-1.5 t-max-h-1.5 t-max-w-1.5 t-rounded-full t-bottom-0 t-right-0 t-absolute t-bg-orange" />
            ) : (
              <img
                src={TinySuccess}
                alt="TinySuccess"
                className="t-bottom-0 t-right-0 t-absolute"
              />
            )}
          </div>
          <div className="t-text-subtext t-text-text-60">
            {pluralize(
              customData?.transaction_count,
              "transaction",
              "transactions"
            )}
          </div>
        </div>
        <ChatCardButton urlText={urlText} handleCardAction={onAction} />
      </ChatCard.Body>
    </ChatCard.Root>
  );
};
