export const FinancialClosing = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6445_1543)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.19999 3.19999V12.8H12.8V3.19999H3.19999ZM1.79999 2.99999C1.79999 2.33725 2.33725 1.79999 2.99999 1.79999H13C13.6627 1.79999 14.2 2.33725 14.2 2.99999V13C14.2 13.6627 13.6627 14.2 13 14.2H2.99999C2.33725 14.2 1.79999 13.6627 1.79999 13V2.99999Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 0.799988C11.3866 0.799988 11.7 1.11339 11.7 1.49999V3.49999C11.7 3.88659 11.3866 4.19999 11 4.19999C10.6134 4.19999 10.3 3.88659 10.3 3.49999V1.49999C10.3 1.11339 10.6134 0.799988 11 0.799988Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.99999 0.799988C5.38659 0.799988 5.69999 1.11339 5.69999 1.49999V3.49999C5.69999 3.88659 5.38659 4.19999 4.99999 4.19999C4.61339 4.19999 4.29999 3.88659 4.29999 3.49999V1.49999C4.29999 1.11339 4.61339 0.799988 4.99999 0.799988Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.79999 5.49999C1.79999 5.11339 2.11339 4.79999 2.49999 4.79999H13.5C13.8866 4.79999 14.2 5.11339 14.2 5.49999C14.2 5.88659 13.8866 6.19999 13.5 6.19999H2.49999C2.11339 6.19999 1.79999 5.88659 1.79999 5.49999Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.13306 7.49999C4.13306 7.11339 4.44646 6.79999 4.83306 6.79999H6.8377C7.09871 6.79999 7.33804 6.94521 7.45858 7.17672C7.57912 7.40823 7.56084 7.68758 7.41116 7.90141L6.76424 8.82558C6.9448 8.95627 7.10109 9.11861 7.22518 9.30538C7.39574 9.56209 7.50019 9.85698 7.52922 10.1638C7.55825 10.4706 7.51097 10.7799 7.39158 11.064C7.2722 11.3482 7.08441 11.5984 6.84494 11.7924C6.60547 11.9864 6.32175 12.1182 6.01902 12.176C5.7163 12.2339 5.40397 12.216 5.10984 12.1239C4.81571 12.0319 4.5489 11.8686 4.33316 11.6485C4.06254 11.3724 4.06697 10.9292 4.34306 10.6586C4.61914 10.3879 5.06234 10.3924 5.33296 10.6685C5.38713 10.7237 5.45412 10.7647 5.52797 10.7878C5.60182 10.811 5.68025 10.8154 5.75625 10.8009C5.83226 10.7864 5.9035 10.7533 5.96363 10.7046C6.02376 10.6559 6.07091 10.5931 6.10088 10.5217C6.13086 10.4504 6.14273 10.3727 6.13544 10.2957C6.12815 10.2186 6.10193 10.1446 6.0591 10.0801C6.01628 10.0157 5.95818 9.96282 5.88999 9.92625C5.82179 9.88967 5.74561 9.87053 5.66822 9.87052C5.40723 9.87048 5.16793 9.72525 5.04741 9.49374C4.9269 9.26224 4.94519 8.98291 5.09486 8.7691L5.49324 8.19999H4.83306C4.44646 8.19999 4.13306 7.88659 4.13306 7.49999Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.59184 8.50713C9.41905 8.73752 9.29148 9.08787 9.29148 9.50463C9.29148 9.92138 9.41905 10.2717 9.59184 10.5021C9.76447 10.7323 9.94951 10.8093 10.095 10.8093C10.2404 10.8093 10.4255 10.7323 10.5981 10.5021C10.7709 10.2717 10.8984 9.92138 10.8984 9.50463C10.8984 9.08787 10.7709 8.73752 10.5981 8.50713C10.4255 8.27696 10.2404 8.19999 10.095 8.19999C9.94951 8.19999 9.76447 8.27696 9.59184 8.50713ZM8.47184 7.66713C8.84336 7.17177 9.41006 6.79999 10.095 6.79999C10.7799 6.79999 11.3466 7.17177 11.7181 7.66713C12.0894 8.16228 12.2984 8.81425 12.2984 9.50463C12.2984 10.195 12.0894 10.847 11.7181 11.3421C11.3466 11.8375 10.7799 12.2093 10.095 12.2093C9.41006 12.2093 8.84336 11.8375 8.47184 11.3421C8.10048 10.847 7.89148 10.195 7.89148 9.50463C7.89148 8.81425 8.10048 8.16228 8.47184 7.66713Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6445_1543">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
