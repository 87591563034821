import classNames from "classnames";
import Table from "components/DesignSystem/Table/V2/Table";
import { ComponentProps, ReactNode } from "react";
import {
  flexRender,
  HeaderGroup,
  Row,
  Table as TableProps,
} from "react-table-8.10.7";

export const TableUI = <T,>({
  table,
  children,
  header,
  size,
  onRowClick,
  enableSort = false,
  layout,
  sticky = true,
}: {
  table: TableProps<T>;
  children?: ({ row }: { row: Row<T> }) => ReactNode;
  header?: ({ headerGroup }: { headerGroup: HeaderGroup<T> }) => ReactNode;
  size?: "small" | "regular" | "large";
  onRowClick?: (row: Row<T>) => void;
  enableSort?: boolean;
  layout?: ComponentProps<typeof Table.Container>[`layout`];
  sticky?: boolean;
}) => {
  return (
    <Table.Container sticky={sticky} layout={layout} size={size || "small"}>
      <Table.Content>
        <Table.Head>
          {table.getHeaderGroups().map((headerGroup) =>
            Boolean(header) ? (
              header?.({ headerGroup })
            ) : (
              <Table.HeadRow key={headerGroup.id}>
                {headerGroup.headers.map((header) =>
                  enableSort ? (
                    <Table.HeadCell
                      className="t-text-subtext-sm t-uppercase t-px-2 t-py-2 t-group"
                      onClick={header.column.getToggleSortingHandler()}
                      key={header.id}
                      style={{ width: `${header.getSize()}%` }}
                      {...(header.column.getCanSort()
                        ? { role: "button" }
                        : {})}
                    >
                      <span className="t-flex t-gap-1 t-items-center">
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                        {enableSort && header.column.getCanSort() && (
                          <Table.HeadCellSort
                            nextSortOrder={header.column.getNextSortingOrder()}
                            isSorted={header.column.getIsSorted()}
                          />
                        )}
                      </span>
                    </Table.HeadCell>
                  ) : (
                    <Table.HeadCell
                      key={header.id}
                      style={{ width: `${header.getSize()}%` }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Table.HeadCell>
                  )
                )}
              </Table.HeadRow>
            )
          )}
        </Table.Head>
        <Table.Body>
          {table.getRowModel().rows.map((row) => {
            const isRowPinned = table
              ?.getState()
              ?.rowPinning.top?.includes(row.id);

            return Boolean(children) ? (
              children?.({ row })
            ) : (
              <Table.Row
                onRowClick={onRowClick ? () => onRowClick(row) : undefined}
                key={row.id}
                className={classNames("", {
                  "t-bg-surface-lighter-grey": isRowPinned,
                })}
              >
                {row.getVisibleCells().map((cell) => (
                  <Table.Cell
                    key={cell.id}
                    style={{ width: `${cell.column.getSize()}%` }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table.Content>
    </Table.Container>
  );
};
