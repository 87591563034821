import classNames from "classnames";
import UpgradeBooksPlusPlan from "components/BooksDashboard/UpgradeBooksPlusPlan";
import { ConditionalLink } from "components/conditionalLink";
import { DashboardLayout } from "components/DashboardLayout";
import Loader from "components/design/loader";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import { CaretDown } from "components/icons/CaretDown";
import {
  CHAT_REQUESTS,
  CONNECTIONS,
  DOCUMENTS_REQUESTED,
  DOCUMENT_REQUESTED,
  INFO_REQUESTED,
  PENDING_REVIEW_TRANSACTIONS,
  RECONCILIATION,
} from "constants/bookkeeping";
import { REQUESTED_INFO_CUSTOM_TYPE } from "constants/chatType";
import * as PRODUCT_ONBOARDING from "constants/productOnboardings";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { usePaginatedMessages } from "hooks/usePaginatedMessages";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import authContext from "jwt_context&axios/authContext";
import { ReactNode, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import BankCircular from "static/images/BankCircular.svg";
import EmptyActionItem from "static/images/EmptyActionItem.svg";
import { useBooksActionItemsQuery } from "store/apis/books";
import { useGetOnboardingProductsQuery } from "store/apis/productOnboarding";
import { openFloatingChat, setToOpenChatId } from "store/slices/chat";
import { setScrollToMessageId } from "store/slices/messageToScrollTo";
import { useChatContext } from "stream-chat-react";
import { BankItems, BooksActionItems } from "types/Models/books";
import { Metrics } from "../Metrics/Metrics";
import { BooksOnboarding } from "./BooksOnboarding";
import { HomePageRevenueMetricsCard } from "components/RevenueMetrics/HomePageRevenueMetricsCard";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { useGetActionItemsQuery } from "store/apis/actionItems";
import { HomeActionItems } from "components/HomeActionItems/HomeActionItems";
import { INKLE_BOOKS } from "constants/home";
import ArrowsInOut from "static/images/ArrowsInOut.svg";
import BalanceIcon from "static/images/BalanceIcon.svg";
import InfoGray from "static/images/InfoGray.svg";
import { SmallWarningInfo } from "components/icons/SmallWarningInfo";

const ItemCard = ({
  logo,
  text,
  action,
  showWarning = false,
}: {
  logo?: ReactNode;
  text: string;
  action: ReactNode;
  showWarning?: boolean;
}) => {
  const { isCustomer } = useRoleBasedView();
  return (
    <div
      className={classNames(
        "t-flex t-justify-between t-border-b t-border-solid t-border-neutral-0 t-border-0 t-flex-wrap t-gap-2 t-w-[99%]",
        {
          "t-p-4": !isCustomer,
          "t-py-4": isCustomer,
        }
      )}
    >
      <div className="t-flex t-gap-2 t-items-center">
        <div className="t-relative t-min-h-10 t-min-w-10 t-max-h-10 t-max-w-10 t-border t-border-solid t-border-neutral-0 t-rounded-full t-flex t-items-center t-justify-center">
          {logo}
          {showWarning && (
            <div className="t-absolute -t-right-0.5 -t-bottom-0.5 t-text-red">
              <SmallWarningInfo />
            </div>
          )}
        </div>
        <div className="t-flex t-flex-col t-gap-0.5">
          <div className="t-text-subtext-sm">{text}</div>
        </div>
      </div>
      {action}
    </div>
  );
};

const ActionItemsCard = ({
  item,
  isGroupBased,
}: {
  item: any;
  isGroupBased: boolean;
}) => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const entityId = useCurrentEntityId() || item.entity_id;

  //@ts-ignore
  switch (item?.item_type) {
    case CONNECTIONS:
      const connectionItem = item as BankItems;
      return (
        <ItemCard
          showWarning
          logo={<Avatar alt={CONNECTIONS} src={connectionItem?.logo} />}
          text={`${connectionItem?.bank_name} accounts are disconnected`}
          action={
            <ConditionalLink
              to={
                isGroupBased
                  ? `/books/data-sources?entity=${entityId}`
                  : `/books/data-sources${search}`
              }
            >
              <Button customType="primary" size="small">
                Reconnect
              </Button>
            </ConditionalLink>
          }
        />
      );

    case RECONCILIATION:
      return (
        <ItemCard
          logo={<Avatar alt={RECONCILIATION} src={BalanceIcon} />}
          text="Reconcile transactions in Inkle Books"
          action={
            <ConditionalLink
              to={
                isGroupBased
                  ? `/books/transactions/reconciliation?entity=${entityId}`
                  : `/books/transactions/reconciliation${search}`
              }
            >
              <Button customType="secondary" size="small">
                Reconcile
              </Button>
            </ConditionalLink>
          }
        />
      );

    case PENDING_REVIEW_TRANSACTIONS:
      return (
        <ItemCard
          logo={
            <Avatar
              alt={item?.transaction_count?.toString()}
              src={ArrowsInOut}
            />
          }
          text={`Pending review of ${item?.transaction_count} transactions`}
          action={
            <ConditionalLink
              to={
                isGroupBased
                  ? `/books/transactions?entity=${entityId}&notReviewed=true`
                  : `/books/transactions${search}&notReviewed=true`
              }
            >
              <Button customType="secondary" size="small">
                Review
              </Button>
            </ConditionalLink>
          }
        />
      );

    case CHAT_REQUESTS:
      let displayText = item?.header;
      let displayCount = item?.transaction_count;

      if (item?.header === INFO_REQUESTED) {
        displayText = `Information requested for ${item?.transaction_count} transactions`;
      }

      if (item?.header.includes(DOCUMENT_REQUESTED)) {
        displayCount = item?.text.replace(DOCUMENT_REQUESTED, "");
        displayText = DOCUMENT_REQUESTED;
      }

      if (item?.header.includes(DOCUMENTS_REQUESTED)) {
        displayCount = item?.header.replace(DOCUMENTS_REQUESTED, "");
        displayText = DOCUMENTS_REQUESTED;
      }

      const jumpToMsg = () => {
        dispatch(openFloatingChat());
        dispatch(setToOpenChatId(item?.channel_url!));
        dispatch(setScrollToMessageId(item?.id));
      };

      return (
        <ItemCard
          logo={<Avatar alt={displayCount?.toString()} src={InfoGray} />}
          text={displayText}
          action={
            <Button customType="secondary" size="small" onClick={jumpToMsg}>
              Add info
            </Button>
          }
        />
      );

    default:
      return null;
  }
};

export const ActionItems = ({
  isGroupBased = false,
}: {
  isGroupBased?: boolean;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { client } = useChatContext();
  const { isCustomer } = useRoleBasedView();

  const {
    data: booksActionItems,
    isLoading,
    isSuccess,
  } = useBooksActionItemsQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId || isGroupBased }
  );

  const {
    data: groupBooksActionItems,
    isLoading: isGroupBooksLoading,
    isSuccess: isGroupBooksSuccess,
  } = useGetActionItemsQuery(
    {
      groupId: groupId,
      type: "BOOKS",
    },
    { skip: !groupId || !isGroupBased, refetchOnMountOrArgChange: true }
  );

  const { action_items_response, channel_url } =
    booksActionItems || (groupBooksActionItems as BooksActionItems) || {};

  const actionItems =
    action_items_response?.filter(({ item_type }) =>
      isCustomer
        ? item_type !== "Reconciliation" && item_type !== "Financial Closing"
        : item_type !== "Financial Closing"
    ) || [];

  const { messages: chatActionItems, loading } = usePaginatedMessages(
    client,
    {
      id: channel_url,
    },
    {},
    {},
    {
      custom_type: {
        $in: [REQUESTED_INFO_CUSTOM_TYPE],
      },
      card_status: { $eq: "REQUESTED" },
    },
    !channel_url
  );

  const chatCards = chatActionItems?.map(
    ({
      message: {
        custom_data: { header, transaction_count },
        id,
      },
    }: any) => ({
      header,
      transaction_count,
      id,
      channel_url,
      item_type: CHAT_REQUESTS,
    })
  );

  const allBooksActionItems = actionItems.flatMap(({ items, item_type }) =>
    items.map((item) => ({ ...item, item_type }))
  );

  const allItems = [...allBooksActionItems, ...chatCards];

  const totalActionItemCount = allBooksActionItems.length + chatCards.length;
  const isEmpty = totalActionItemCount === 0;

  if (isEmpty && !isCustomer) {
    return null;
  }

  return (
    <DashboardContainer
      className={classNames(
        "t-w-full t-rounded t-overflow-hidden t-transform t-transition t-duration-300 t-ease-in-out",
        {
          "t-border t-border-solid t-border-neutral-0": !isCustomer,
          "t-h-full": isCustomer,
        }
      )}
    >
      {!isCustomer && (
        <DashboardContainer.Header className="t-p-4 t-text-subtitle t-border t-border-solid t-border-neutral-0 t-border-t-0 t-border-l-0 t-border-r-0 t-flex t-gap-2 t-items-center">
          Your action items
        </DashboardContainer.Header>
      )}
      <DashboardContainer.Content className="t-w-full">
        <Async.Root
          {...{
            isEmpty,
            isLoading: isLoading || loading || isGroupBooksLoading,
            isSuccess: isSuccess || isGroupBooksSuccess,
          }}
        >
          <Async.Empty>
            <div className="t-flex t-items-center t-justify-center t-h-full">
              <img src={EmptyActionItem} alt="noActions" />
            </div>
          </Async.Empty>
          <Async.Success>
            {allItems.map((item) => (
              <ActionItemsCard
                item={item}
                key={item.item_type}
                isGroupBased={isGroupBased}
              />
            ))}
          </Async.Success>
        </Async.Root>
      </DashboardContainer.Content>
    </DashboardContainer>
  );
};

export const BooksHome = () => {
  usePageTitle("Books Dashboard");
  const { uuid: groupId } = useCurrentGroupContext();
  const { isCustomer } = useRoleBasedView();

  const {
    authtoken: { first_name },
  } = useContext(authContext);

  const {
    data: products,
    isLoading,
    isSuccess,
  } = useGetOnboardingProductsQuery(
    {
      groupId: groupId!,
    },
    { skip: !groupId }
  );

  const platformOnboarding = products?.find(
    (p) => p.name === PRODUCT_ONBOARDING.BOOKS
  );

  const isBooksOnboardingComplete =
    platformOnboarding && platformOnboarding.status === "COMPLETED";

  if (isLoading || !isSuccess) {
    return <Loader />;
  }

  return (
    <DashboardLayout header={<Header v2 title={`Welcome ${first_name}`} />}>
      <div className="t-flex t-gap-5 t-flex-col t-relative t-top-0">
        <UpgradeBooksPlusPlan />
        {isBooksOnboardingComplete ? (
          <>
            <div className="t-grid t-grid-cols-2 t-gap-5 t-w-full t-max-h-[382px]">
              {isCustomer ? (
                <HomeActionItems defaultTab={INKLE_BOOKS} />
              ) : (
                <ActionItems />
              )}
              <HomePageRevenueMetricsCard />
            </div>
            <Metrics />
          </>
        ) : (
          <BooksOnboarding />
        )}
      </div>
    </DashboardLayout>
  );
};
