import dayjs from "dayjs";
import { date, number, object, string } from "yup";

export const addProductToCartValidation = object({
  entity_id: string().required("Please select Entity"),
  season: string().when("has_season", {
    is: true,
    then: string().required("Please select Season"),
    otherwise: string().notRequired(),
  }),
  state_id: string().when("has_states", {
    is: true,
    then: string().required("Please select state"),
    otherwise: string().notRequired(),
  }),
  payment_amount: number().when("has_states", {
    is: true,
    then: number()
      .positive()
      .min(1, "Please enter a valid price.")
      .required("Please select state"),
    otherwise: number().notRequired(),
  }),
  from_date: string().when("has_from_date", {
    is: true,
    then: string().required("Please select start date"),
    otherwise: string().notRequired(),
  }),
  accounting_method: string().when("has_accounting_method", {
    is: true,
    then: string().required("Please select accounting method"),
    otherwise: string().notRequired(),
  }),
  to_date: string().when("has_to_date", {
    is: true,
    then: string().required("Please select end date"),
    otherwise: string().notRequired(),
  }),
});
