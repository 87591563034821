import { ReactNode } from "react";

export const InvoiceItemGroup = ({
  title,
  children,
  required,
}: {
  title?: ReactNode;
  children: ReactNode;
  required?: boolean;
}) => (
  <div className="t-flex t-flex-col t-gap-4">
    {title && (
      <div className="t-text-subtitle">
        {title} {required && <span className="t-text-red">*</span>}
      </div>
    )}
    {children}
  </div>
);
