import React from "react";

export const UnreadChatIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M16 10.6667V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H10.6667C9.7314 15.999 8.81283 15.7521 8.0031 15.2841C7.19337 14.816 6.52097 14.1433 6.05333 13.3333C6.55591 13.3297 7.05678 13.2743 7.548 13.168C7.9221 13.6358 8.39666 14.0133 8.93653 14.2728C9.47639 14.5322 10.0677 14.6668 10.6667 14.6667H14C14.1768 14.6667 14.3464 14.5964 14.4714 14.4714C14.5964 14.3464 14.6667 14.1768 14.6667 14V10.6667C14.6665 10.0675 14.5315 9.47602 14.2716 8.93615C14.0117 8.39627 13.6336 7.92182 13.1653 7.54799C13.2726 7.05687 13.3289 6.55599 13.3333 6.05332C14.1433 6.52096 14.8161 7.19336 15.2841 8.00309C15.7522 8.81282 15.999 9.73139 16 10.6667ZM11.9847 6.43399C12.047 5.57508 11.9237 4.71282 11.6232 3.90579C11.3227 3.09875 10.852 2.36587 10.2431 1.75693C9.63412 1.148 8.90123 0.677276 8.0942 0.376758C7.28717 0.0762414 6.42491 -0.0470302 5.566 0.0153203C4.04394 0.189142 2.63795 0.913438 1.61278 2.05182C0.587618 3.19019 0.0140181 4.6641 0 6.19599L0 9.55599C0 11.244 1.00467 12 2 12H5.8C7.33254 11.9868 8.80734 11.4136 9.94653 10.3884C11.0857 9.36312 11.8106 7.95667 11.9847 6.43399ZM9.3 2.70065C9.77329 3.17499 10.1392 3.74545 10.3729 4.37345C10.6066 5.00144 10.7027 5.67231 10.6547 6.34065C10.5124 7.52977 9.94033 8.62587 9.04622 9.42261C8.1521 10.2194 6.99759 10.6618 5.8 10.6667H2C1.38133 10.6667 1.33333 9.81666 1.33333 9.55599V6.19599C1.33889 4.9989 1.78177 3.84512 2.57861 2.95177C3.37546 2.05842 4.47133 1.4871 5.66 1.34532C5.77067 1.33732 5.88133 1.33332 5.992 1.33332C6.60618 1.33275 7.21445 1.45321 7.78206 1.68783C8.34966 1.92244 8.86547 2.26661 9.3 2.70065Z"
      fill="#706A85"
    />
  </svg>
);
