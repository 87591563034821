import { BooksActionItems } from "types/Models/books";
import { emptyApi } from "./emptyApi";

export type TaxActionItemType = {
  count: number;
  custom_type:
    | "INVOICES_DUE"
    | "REQUESTED_DOCUMENTS_CUSTOM_TYPE"
    | "DOCUMENT_REVIEW"
    | "ESIGN_REQUEST_CUSTOM_TYPE"
    | "SENSITIVE_DATA_CUSTOM_TYPE"
    | "FILING_IN_ADD_DETAILS_CUSTOM_TYPE"
    | "CART"
    | "PAYMENT_REQUESTED"
    | "REVIEW_AND_PAY";
  title: string;
  ids: string[];
  entity_id: string;
  order: number;
};

type GetActionItemsQueryParams = { groupId: string; type: "TAX" | "BOOKS" };

type GetActionItemsResponse<T extends "TAX" | "BOOKS"> = T extends "TAX"
  ? { tax_action_items: TaxActionItemType[] }
  : BooksActionItems;

export const actionItemsApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getActionItems: build.query<
      GetActionItemsResponse<GetActionItemsQueryParams["type"]>,
      GetActionItemsQueryParams
    >({
      query: ({ groupId, type }) => {
        return {
          url: `/api/inkle/company/group/${groupId}/action_items/?product=${type}`,
        };
      },
      providesTags: () => [{ type: "ALL_ACTION_ITEMS" }],
    }),
  }),
});

export const { useGetActionItemsQuery } = actionItemsApi;
