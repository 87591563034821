import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";
import { DD_MMM_YYYY_LT } from "constants/date";
import dayjs from "dayjs";
import { useChatContext, usePaginatedChannels } from "stream-chat-react";
import { GROUP_ACCOUNT_OVERVIEW } from "types/Models/group";

export const SupportOverview = ({
  groupData,
}: {
  groupData: GROUP_ACCOUNT_OVERVIEW;
}) => {
  return (
    <div className="t-rounded-lg t-border-neutral-10 t-border t-border-solid t-h-[308px]">
      <div className="!t-border-b t-border-0 t-border-neutral-10 t-p-4 t-border-solid t-text-subtitle">
        Support Overview
      </div>
      <div className="t-p-4 t-space-y-6">
        <InfoItem label="No. Of Interactions">
          {groupData.support_overview.interactions_count}
        </InfoItem>
        <div className="t-grid t-grid-cols-2 t-gap-6">
          <InfoItem label="Total Tickets Created">
            {groupData.support_overview.total_tickets_created_count}
          </InfoItem>
          <InfoItem label="Unresolved Tickets">
            {groupData.support_overview.unresolved_tickets_count}
          </InfoItem>
        </div>
        <InfoItem label="Last Contacted">
          {dayjs(groupData.support_overview.last_contacted)
            .format(DD_MMM_YYYY_LT)
            .toString()}
        </InfoItem>
      </div>
    </div>
  );
};
