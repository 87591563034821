import classNames from "classnames";
import { ConditionalLink } from "components/conditionalLink";
import { Divider } from "components/design/Divider";
import { Button } from "components/DesignSystem/Button/Button";
import ChatCard from "components/DesignSystem/ChatCard/ChatCard";
import { Link } from "components/DesignSystem/Link/Link";
import { DataRequestedHeader } from "components/icons/DataRequestedHeader";
import { Redirect } from "components/icons/Redirect";
import { ShieldCheck } from "components/icons/ShieldCheck";
import { ShieldStar } from "components/icons/ShieldStar";
import { EditSensitiveDataDynamicForm } from "components/SensitiveData/SensitiveData";
import { REQUESTED, SUBMITTED } from "constants/sensitiveData";
import { useAuth } from "hooks/useAuth";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useDispatch } from "react-redux";
import { actionItemsApi } from "store/apis/actionItems";

type SensitiveDataCardProps = {
  customData: {
    description: string;
    header: string;
    card_status: typeof REQUESTED | typeof SUBMITTED;

    metadata: {
      name: string;
      sensitive_data_instance_id: string;
      entity_id: string;
      shared_profiles: { profile_id: string; name: string }[] | null;
    };
  };
  groupId: string;
  messageId: string;
  size?: "regular" | "block";
};

const CardStatus = ({ status = REQUESTED }: { status: string }) => {
  return (
    <div
      className={classNames("t-p-1 t-flex t-items-center t-rounded", {
        "t-bg-green-30": status === SUBMITTED,
        "t-bg-yellow-30": status === REQUESTED,
      })}
    >
      <div
        className={classNames("", {
          "t-text-green-80": status === SUBMITTED,
          "t-text-yellow-80": status === REQUESTED,
        })}
      >
        <ShieldStar />
      </div>
    </div>
  );
};
export const SensitiveDataCard = ({
  customData,
  groupId,
  messageId,
  size,
}: SensitiveDataCardProps) => {
  const { metadata, description, header, card_status } = customData || {};
  const {
    sensitive_data_instance_id,
    entity_id,
    name: formName,
    shared_profiles,
  } = metadata || {};
  const { isCustomer, isAdmin } = useRoleBasedView();
  const { uuid: currentProfileId } = useAuth();
  const dispatch = useDispatch();
  const adminViewLink = `/admin/crm/${groupId}/entity/${entity_id}/sensitive-data/${sensitive_data_instance_id}`;

  const customerViewLink = `/tax/entities/entity/${entity_id}/sensitive-data/${sensitive_data_instance_id}`;

  const isSharedAccess = shared_profiles
    ? Boolean(
        shared_profiles.find(
          ({ profile_id }) => profile_id === currentProfileId
        )
      )
    : false;

  const {
    isOpen: isEditOpen,
    open: openEditModal,
    close: closeEditModal,
  } = useModal();

  const close = () => {
    closeEditModal();
    setTimeout(() => {
      dispatch(
        actionItemsApi.util.invalidateTags([{ type: "ALL_ACTION_ITEMS" }])
      );
    }, 1000);
  };

  return (
    <>
      <ChatCard.Root size={size}>
        <ChatCard.Header>
          <div className="t-flex t-gap-1.5 t-items-center">
            <DataRequestedHeader />
            <div>Data Requested</div>
          </div>
        </ChatCard.Header>
        <ChatCard.Body>
          <div className="t-space-y-1.5">
            <div className="t-text-subtitle t-text-text-60">{header}</div>
            <div className="t-text-body">{formName}</div>
          </div>

          <div className="t-w-full t-gap-3">
            {card_status == REQUESTED && isCustomer && (
              <Button
                block
                customType="primary"
                onClick={openEditModal}
                size="small"
              >
                Add details
              </Button>
            )}
            {card_status == REQUESTED && isAdmin && (
              <Button block onClick={openEditModal} size="small">
                Edit details
              </Button>
            )}
          </div>
          {card_status == SUBMITTED &&
            (isCustomer || isAdmin || isSharedAccess) && (
              <ConditionalLink
                target="_blank"
                to={isCustomer ? customerViewLink : adminViewLink}
              >
                <Button block customType="secondary" size="small">
                  <span className="t-flex t-gap-2 t-items-center">
                    View in sensitive data store{" "}
                    <Redirect color="currentColor" size={16} />
                  </span>
                </Button>
              </ConditionalLink>
            )}
          <div className="t-flex t-items-center t-gap-1 t-justify-center t-p-1 t-rounded t-bg-neutral-0 t-w-fit t-mx-auto">
            <span className=" t-text-blue -t-mt-1">
              <ShieldCheck />
            </span>
            <div className="t-text-caption t-text-text-30">
              Your data is secured and protected
            </div>
          </div>
        </ChatCard.Body>
      </ChatCard.Root>
      {isEditOpen && (
        <EditSensitiveDataDynamicForm
          formId={sensitive_data_instance_id}
          isOpen={isEditOpen}
          close={close}
          entityId={entity_id!}
        />
      )}
    </>
  );
};
