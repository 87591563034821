import { Divider } from "components/design/Divider";
import { DownloadIcon } from "components/icons/Download";
import { Children, FC, MouseEvent, ReactNode } from "react";
import { FileIcon } from "utils/fileTypeIcon";
import { Button } from "components/DesignSystem/Button/Button";
import classNames from "classnames";

type HeaderProps = {
  status?: ReactNode;
  children: ReactNode;
};

type FileProps = {
  children: ReactNode;
  fileType?: string;
  onFileClick?: () => void;
  onDownload?: () => void;
  isLoading?: boolean;
};

const Root: FC<{
  size?: "regular" | "block";
  children: ReactNode;
}> = ({ children, size = "regular" }) => {
  return (
    <div
      className={classNames(
        "t-rounded-lg t-border-neutral-0 t-flex t-border t-border-solid t-overflow-hidden t-flex-col t-bg-white t-box-content t-w-[288px]",
        {
          "t-w-64": size === "regular",
          "t-w-full": size === "block",
        }
      )}
    >
      {children}
    </div>
  );
};

const Header: FC<HeaderProps> = ({ children, status }) => {
  return (
    <div className="t-border-neutral-0 t-border-b t-border-solid t-w-full t-flex t-items-center t-justify-between t-p-3 t-gap-2 t-border-0 t-bg-neutral-0 t-rounded-t">
      <span className="t-text-subtitle-sm t-text-text-30">{children}</span>
    </div>
  );
};

const Body: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="t-p-3 t-flex t-gap-4 t-flex-col">{children}</div>;
};

const File: FC<FileProps> = ({
  children,
  fileType,
  onFileClick,
  onDownload,
  isLoading,
}) => {
  const onDownloadClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onDownload?.();
  };

  return (
    <button
      className="all:unset t-flex t-justify-between t-items-center t-cursor-pointer t-select-none"
      onClick={onFileClick}
      disabled={isLoading}
    >
      <div className="t-flex t-gap-2 t-text-subtext t-text-text-60 t-items-center t-w-64">
        <FileIcon fileType={fileType} width="24px" height="24px" />
        <div className="t-text-ellipsis t-whitespace-nowrap t-overflow-x-hidden">
          {children}
        </div>
      </div>
      <Button
        customType="ghost_icon"
        size="small"
        onClick={onDownloadClick}
        disabled={isLoading}
        isLoading={isLoading}
      >
        <span className="t-text-text-60">
          <DownloadIcon color="currentColor" strokeWidth="1.5" size={24} />
        </span>
      </Button>
    </button>
  );
};

const Description: FC<{
  children: ReactNode;
}> = ({ children }) => {
  return <div className="t-text-body-sm t-text-text-30">{children}</div>;
};

const ChatCard = { Root, Header, Body, File, Description };

export default ChatCard;
