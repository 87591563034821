export const ShieldCheck = ({ size = "24" }: { size?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M13.5 2.5H3.5C3.23478 2.5 2.98043 2.60536 2.79289 2.79289C2.60536 2.98043 2.5 3.23478 2.5 3.5V7C2.5 10.295 4.095 12.2919 5.43313 13.3869C6.87437 14.5656 8.30813 14.9656 8.37063 14.9825C8.45656 15.0059 8.54719 15.0059 8.63313 14.9825C8.69563 14.9656 10.1275 14.5656 11.5706 13.3869C12.905 12.2919 14.5 10.295 14.5 7V3.5C14.5 3.23478 14.3946 2.98043 14.2071 2.79289C14.0196 2.60536 13.7652 2.5 13.5 2.5ZM11.355 6.85375L7.855 10.3538C7.80856 10.4002 7.75342 10.4371 7.69272 10.4623C7.63202 10.4874 7.56696 10.5004 7.50125 10.5004C7.43554 10.5004 7.37048 10.4874 7.30978 10.4623C7.24908 10.4371 7.19394 10.4002 7.1475 10.3538L5.6475 8.85375C5.55368 8.75993 5.50097 8.63268 5.50097 8.5C5.50097 8.36732 5.55368 8.24007 5.6475 8.14625C5.74132 8.05243 5.86857 7.99972 6.00125 7.99972C6.13393 7.99972 6.26118 8.05243 6.355 8.14625L7.5 9.29313L10.6462 6.14625C10.6927 6.09979 10.7479 6.06294 10.8086 6.0378C10.8692 6.01266 10.9343 5.99972 11 5.99972C11.0657 5.99972 11.1308 6.01266 11.1914 6.0378C11.2521 6.06294 11.3073 6.09979 11.3538 6.14625C11.4002 6.1927 11.4371 6.24786 11.4622 6.30855C11.4873 6.36925 11.5003 6.4343 11.5003 6.5C11.5003 6.5657 11.4873 6.63075 11.4622 6.69145C11.4371 6.75214 11.4002 6.8073 11.3538 6.85375H11.355Z"
        fill="currentColor"
      />
    </svg>
  );
};
