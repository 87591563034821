import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Divider } from "components/design/Divider";
import dayjs from "dayjs";
import React from "react";
import { BankTransferOrder } from "store/apis/bankTransfer";

export const BankOrderSummary = ({
  bankTransferOrder,
}: {
  bankTransferOrder: BankTransferOrder;
}) => (
  <div className="t-p-4 t-rounded-lg t-border t-border-solid t-border-neutral-10 t-bg-surface-lighter-grey">
    <div className="t-mb-3 t-text-subtext t-text-text-60">Order Summary</div>
    <Divider />
    <div className="t-mt-5 t-space-y-3 t-max-h-40 t-overflow-scroll">
      {bankTransferOrder.payment_summary.items.map((item, index) => {
        return (
          <div key={index} className="t-flex t-justify-between t-gap-6">
            <div className="t-space-y-1">
              <div className="t-text-body-sm t-text-text-60">
                {item.name} {item.quantity > 1 && `(x${item.quantity})`}
              </div>
              {item.season && (
                <div className="t-text-body-sm t-text-neutral-30">
                  {dayjs(item.season).format("YYYY")}
                </div>
              )}
            </div>
            <div className="t-text-body-sm t-text-text-60">
              <AmountSuperScript amount={Number(item.amount)} />
            </div>
          </div>
        );
      })}
    </div>
    {bankTransferOrder.payment_summary.coupon &&
      bankTransferOrder.payment_summary.discount_value > 0 && (
        <>
          <div className="t-my-3">
            <Divider />
          </div>
          <div className="t-flex t-justify-between t-gap-6">
            <div className="t-text-body-sm t-text-text-60">
              {bankTransferOrder.payment_summary.coupon.coupon_code} (discount)
            </div>
            <div className="t-text-body-sm t-text-text-60">
              <AmountSuperScript
                amount={Number(
                  -bankTransferOrder.payment_summary.discount_value
                )}
              />
            </div>
          </div>
        </>
      )}
    <div className="t-my-3">
      <Divider />
    </div>
    <div className="t-flex t-justify-between t-items-center t-text-subtitle t-text-text-60">
      <div>Total</div>
      <div>
        <AmountSuperScript amount={bankTransferOrder.amount} />
      </div>
    </div>
  </div>
);
