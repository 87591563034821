import React from "react";
import {
  RAMailroomSubscriptionType,
  useUpdateRAMailroomMutation,
} from "store/apis/RAMailroom";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useGetAssignAgentsQuery } from "store/apis/agents";
import { useUpdateGroupDataMutation } from "store/apis/salesCrm";
import { MultiValue, SingleValue } from "react-select";

export const AssignedAE = ({
  subscription,
}: {
  subscription: RAMailroomSubscriptionType;
}) => {
  const { alertToast, successToast } = useToast();
  const { isAdmin } = useRoleBasedView();
  const { data } = useGetAssignAgentsQuery(
    { agentType: "ACCOUNT_EXECUTIVE" },
    { skip: !isAdmin }
  );
  const [updateRAMailroom, { isLoading }] = useUpdateRAMailroomMutation();
  const { assigned_agent: assignedAgent } = subscription;

  const handleAssignAgent = async (value: string) => {
    try {
      await updateRAMailroom({
        raMailroomId: subscription.uuid,
        payload: { profile_id: value },
      }).unwrap();
      successToast({ message: "Assigned agent successfully" });
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  const agentOptions = [
    ...(data?.map(({ name, profile_id }) => ({
      label: name,
      value: profile_id,
    })) || []),
  ];

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Combobox
        components={{
          ClearIndicator: () => null,
        }}
        size="small"
        menuPortalTarget={document.body}
        value={
          assignedAgent.profile_id
            ? {
                label: assignedAgent.name || "",
                value: assignedAgent.profile_id || "",
              }
            : null
        }
        name="assignedAgent"
        label=""
        placeholder="Select"
        options={agentOptions}
        isLoading={isLoading}
        onChange={(
          selectedOption: MultiValue<OptionData> | SingleValue<OptionData>
        ) =>
          handleAssignAgent(
            (selectedOption as SingleValue<OptionData>)?.value || ""
          )
        }
        withForm={false}
        fullWidth
      />
    </div>
  );
};
