import { AWAITING } from "constants/merchantFormStatuses";
import { useDocPreview } from "hooks/useDocPreview";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { FC, useState } from "react";
import GrayPencil from "static/images/GrayPencil.svg";
import { Merchant } from "types/Models/merchant";
import { FileIcon } from "utils/fileTypeIcon";
import { Button } from "./DesignSystem/Button/Button";
import Dropdown from "./DesignSystem/Dropdown/Dropdown";
import { Tag } from "./DesignSystem/Tag/Tag";
import { CaretDown } from "./icons/CaretDown";
import { Cross } from "./icons/Cross";
import { DocumentPreviewModal } from "./PreviewModal";
import { WSeriesFormUpload } from "./WSeriesFormUpload";
import { WSeriesRequestForm } from "./WSeriesRequestForm";
import { WSeriesSelectExistingVendor } from "./WSeriesSelectExistingVendor";

interface IFileRequestOrUploadProps {
  merchant: Merchant;
  taskId: string;
  groupId: string;
  updateMerchant: () => void;
  editable: boolean;
  wFormStatus: string;
  season: string;
  isSuccessPage: boolean;
  isEditing?: boolean;
}

export const FileRequestOrUpload: FC<IFileRequestOrUploadProps> = ({
  merchant,
  taskId,
  groupId,
  updateMerchant,
  editable,
  wFormStatus,
  season,
  isSuccessPage,
}) => {
  const { isAdmin } = useRoleBasedView();
  const [isEditable, setIsEditable] = useState(false);
  const openPreview = useDocPreview();
  const {
    open: openFormUploadModal,
    close: closeFormUploadModal,
    isOpen: showFormUploadModal,
  } = useModal();

  const {
    open: openFormRequestModal,
    close: closeFormRequestModal,
    isOpen: showFormRequestModal,
  } = useModal();

  const existingVendorModal = useModal();

  const openFilePreview = () => {
    if (merchant?.w_form_document?.uuid) {
      openPreview(merchant.w_form_document.uuid);
    }
  };

  const updateMerchantAndCloseEdit = () => {
    updateMerchant();
  };

  if (wFormStatus === AWAITING && !isEditable) {
    return (
      <div className="t-group t-flex t-items-center t-gap-2 t-w-full t-relative">
        <div className="t-text-subtext t-text-text-60">
          <div className="t-flex t-gap-1 t-h-full t-items-center">
            <Tag tagType="orange" icon={false} size="medium" rounded>
              Requested
            </Tag>
          </div>
        </div>
        {!isSuccessPage && (
          <Button customType="transparent" onClick={() => setIsEditable(true)}>
            <img src={GrayPencil} alt="GrayPencil" className="t-h-4" />
          </Button>
        )}
      </div>
    );
  }

  if (merchant?.w_form_document && !isEditable) {
    return (
      <div className="t-flex t-group t-gap-2 t-w-full t-relative">
        <div
          className="t-flex t-items-center t-p-2 t-gap-2 t-border t-border-solid t-border-surface-grey t-rounded t-w-full t-bg-surface-lighter-grey t-cursor-pointer"
          onClick={openFilePreview}
        >
          <FileIcon
            fileType={merchant?.w_form_document?.file_type}
            width="20px"
            height="20px"
          />
          <div className="t-text-subtext-sm t-text-text-100 t-truncate t-max-w-32">
            {merchant?.w_form_document?.name}
          </div>
        </div>
        <Button customType="transparent" onClick={() => setIsEditable(true)}>
          <img src={GrayPencil} alt="GrayPencil" className="t-h-4" />
        </Button>
        <DocumentPreviewModal />
      </div>
    );
  }

  if (isSuccessPage) {
    return <div className="t-w-full t-text-center">-</div>;
  }

  return (
    <>
      <Dropdown.Root>
        <div className="t-flex t-items-center">
          <Dropdown.Trigger asChild className="t-group t-select-none">
            <div className="t-flex t-items-center t-justify-between t-px-3 t-py-1 t-text-body t-text-text-30 t-rounded t-border t-border-solid t-border-neutral-0 t-bg-surface-lighter-grey group-data-state-open:!t-border-purple">
              <div className="t-truncate">Request/upload</div>
              <div className="group-data-state-open:t-rotate-180 t-ml-2">
                <CaretDown />
              </div>
            </div>
          </Dropdown.Trigger>
          {isEditable && (
            <div className="t-ml-2">
              <Button
                customType="transparent"
                onClick={() => setIsEditable(false)}
              >
                <Cross />
              </Button>
            </div>
          )}
        </div>
        <Dropdown.Portal>
          <Dropdown.Content
            sideOffset={4}
            align="start"
            side="bottom"
            className="t-max-h-56 t-overflow-auto t-max-w-md"
          >
            <Dropdown.Item
              textValue="Request form"
              onSelect={openFormRequestModal}
            >
              Request form
            </Dropdown.Item>
            <Dropdown.Item
              textValue="Upload form"
              onSelect={openFormUploadModal}
            >
              Upload form
            </Dropdown.Item>
            {isAdmin && (
              <Dropdown.Item
                textValue="Add from existing vendor"
                onSelect={existingVendorModal.open}
              >
                Add from existing vendor
              </Dropdown.Item>
            )}
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>
      <WSeriesRequestForm
        show={showFormRequestModal}
        closeModal={closeFormRequestModal}
        taskId={taskId}
        groupId={groupId}
        merchant={merchant}
        updateMerchant={updateMerchantAndCloseEdit}
        season={season}
      />
      <WSeriesFormUpload
        show={showFormUploadModal}
        closeModal={closeFormUploadModal}
        taskId={taskId}
        groupId={groupId}
        merchant={merchant}
        updateMerchant={updateMerchantAndCloseEdit}
        editable={editable}
        openModal={openFormUploadModal}
        season={season}
      />
      <WSeriesSelectExistingVendor
        isOpen={existingVendorModal.isOpen}
        close={existingVendorModal.close}
        taskId={taskId}
        merchant={merchant}
        groupId={groupId}
        season={season}
      />
    </>
  );
};
