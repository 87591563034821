import { Group, GROUP_ACCOUNT_OVERVIEW } from "types/Models/group";
import { emptyApi } from "./emptyApi";
import qs from "qs";

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getEntities: build.query<
      { groups: Group[] },
      void | { include_shareholder?: boolean | null }
    >({
      query: (params) => {
        let queryUrl = "";

        if (params && params.include_shareholder !== undefined) {
          queryUrl = qs.stringify(
            { include_shareholder_entities: params.include_shareholder },
            { skipNulls: true, addQueryPrefix: true }
          );
        }
        return `/api/inkle/company/group/entities/${queryUrl}`;
      },
      providesTags: (result) => (result ? [{ type: "Groups" }] : []),
    }),

    getGroupData: build.query<
      Group,
      { groupId: string; include_shareholder?: boolean | null }
    >({
      query: ({ groupId, include_shareholder = null }) => {
        let queryUrl = qs.stringify(
          {
            include_shareholder_entities: include_shareholder,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return `/api/inkle/company/group/${groupId}/${queryUrl}`;
      },
      providesTags: (result) => (result ? [{ type: "Groups" }] : []),
    }),

    createEntity: build.mutation({
      query: ({ groupId, payload }) => {
        return {
          url: `/api/inkle/company/group/${groupId}/entity/`,
          method: "POST",
          body: payload,
        };
      },
    }),

    getGroupAccountOverview: build.query<
      GROUP_ACCOUNT_OVERVIEW,
      { entityId: string; season: string | null }
    >({
      query: ({ entityId, season }) => {
        let queryUrl = qs.stringify(
          {
            season: season,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return `/api/inkle/company/entity/${entityId}/account_overview/${queryUrl}`;
      },
      providesTags: (result) =>
        result ? [{ type: "GROUP_ACCOUNT_OVERVIEW" }] : [],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetEntitiesQuery,
  useGetGroupDataQuery,
  useLazyGetGroupDataQuery,
  useCreateEntityMutation,
  useLazyGetEntitiesQuery,
  useGetGroupAccountOverviewQuery,
} = extendedApi;
