import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  ComboboxProps,
} from "components/DesignSystem/Combobox/Combobox";
import { LoadingIcon } from "components/icons/LoadingIcon";
import { AddManualVendors } from "components/Vendors/AddManualVendors";
import dayjs from "dayjs";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { useState } from "react";
import {
  Merchant,
  useLazyGetAllTransactionMerchantsQuery,
} from "store/apis/merchant";
import { useDebounce } from "utils/debounce";

export const MerchantSeletor = (props: ComboboxProps) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [search, setSearch] = useState("");
  const addVendorModal = useModal();
  const searchTerm = useDebounce(search);

  const { data, loadNext, isLoading, isFetching } = usePaginatedQuery<{
    merchants?: Merchant[];
  }>(
    useLazyGetAllTransactionMerchantsQuery,
    "merchants",
    {
      search_term: searchTerm,
      groupId,
      entityId,
    },
    ["groupId", "entityId"]
  );

  const onSearch = (value: string) => setSearch(value);

  return (
    <>
      <Combobox
        isDisabled={isLoading || props.isDisabled}
        isLoading={isLoading || props.isLoading}
        options={
          data?.merchants?.map((merchant) => ({
            value: merchant.uuid,
            label: merchant.name,
          })) || []
        }
        onMenuScrollToBottom={loadNext}
        onInputChange={onSearch}
        creatable={false}
        actions={
          isFetching ? (
            <div className="t-flex t-justify-center t-items-center t-w-full t-gap-2 t-p-1.5">
              <span className="t-flex t-origin-center t-animate-spin">
                <LoadingIcon />
              </span>
              Loading...
            </div>
          ) : (
            <Button
              type="button"
              customType="link"
              block
              onClick={addVendorModal.open}
              disabled={isLoading}
            >
              Add Vendor
            </Button>
          )
        }
        {...props}
      />
      <AddManualVendors
        entityId={entityId}
        closeModal={addVendorModal.close}
        show={addVendorModal.isOpen}
        useCSV={false}
        season={dayjs().year().toString()}
      />
    </>
  );
};

export default MerchantSeletor;
