import { Button } from "components/DesignSystem/Button/Button";
import ProgressBar from "components/DesignSystem/ProgressBar/ProgressBar";
import React from "react";
import OnboardingBackground from "static/images/OnboardingBackground.svg";

export const OnboardingHomeCard = ({
  progress,
  onClick,
}: {
  progress?: number;
  onClick: React.DOMAttributes<HTMLDivElement>["onClick"];
}) => {
  return (
    <div
      role="button"
      onClick={onClick}
      className="t-border t-border-solid t-rounded-lg t-p-5 t-border-neutral-10 t-h-full t-w-full t-relative"
    >
      <img
        src={OnboardingBackground}
        alt="OnboardingBackground"
        className="t-absolute t-top-0 t-left-0 t-h-full t-w-full t-object-cover t-z-[-1]"
      />
      <div className="t-flex t-justify-between t-h-full t-items-center">
        <div className="t-w-9/12 t-gap-4 t-space-y-3">
          <div className="t-text-subtitle">Complete Onboarding</div>
          <div>
            <div className="t-w-8/12">
              <ProgressBar.Root className="t-bg-white">
                <ProgressBar.Indicator progress={progress || 0} />
              </ProgressBar.Root>
            </div>

            <span className="t-text-body-sm">{progress}% Complete</span>
          </div>
        </div>
        <div>
          <Button size="small">Continue</Button>
        </div>
      </div>
    </div>
  );
};
