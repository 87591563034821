import * as Accordion from "@radix-ui/react-accordion";
import Loader from "components/design/loader";
import { SolidCaretDown } from "components/icons/SolidCaretDown";
import { TASK_LIST_YEAR_ACCORDION_OPENED } from "constants/analyticsEvents";
import { ONGOING } from "constants/task";
import { TASK_LIST_FILTER_TYPES } from "dictionaries";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery } from "hooks/useQuery";
import authContext from "jwt_context&axios/authContext";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTaskYearWiseCountsQuery } from "store/apis/taskList";
import { closeTaskSlider } from "store/slices/taskList";
import { useDebounce } from "utils/debounce";
import { TaskTable } from "./TaskTable";
import { useRoleBasedView } from "hooks/useRoleBasedView";

export const AccordionTable = () => {
  const { trackEvent } = useAnalytics();
  const query = useQuery();
  const { authtoken } = useContext(authContext);
  const { email, name } = authtoken;
  const location = useLocation();
  const dispatch = useDispatch();
  const taskType = (query.get("task") ||
    ONGOING) as keyof typeof TASK_LIST_FILTER_TYPES;
  const filterValues = TASK_LIST_FILTER_TYPES[taskType];
  const search = query.get("search");
  const searchTerm = useDebounce(search, 500);
  const { uuid: groupId } = useCurrentGroupContext();
  const { isCustomer } = useRoleBasedView();
  let categoryType = isCustomer
    ? {
        taskCategoryType: "TAX,RND" as
          | "TAX,RND"
          | "BOOKS"
          | "INTRA_GROUP"
          | "SALES_TAX"
          | undefined,
      }
    : {};
  const {
    data: taskYears = [],
    isLoading,
    isSuccess,
    error,
  } = useTaskYearWiseCountsQuery(
    {
      ...filterValues,
      searchTerm,
      groupId,
      ...categoryType,
    },
    { skip: !groupId, refetchOnMountOrArgChange: true }
  );

  const noTaskYears = taskYears.length === 0 && isSuccess;

  const onAccordionClicked = (value: string[]) => {
    if (Boolean(value.length)) {
      trackEvent(TASK_LIST_YEAR_ACCORDION_OPENED, {
        screen: location.pathname,
        task_year_list: value,
        email,
        name,
      });
    }
  };

  const allYears = taskYears.map(({ task__season }) => task__season);
  const defaultOpenValue =
    taskType === ONGOING ? allYears : [taskYears[0]?.task__season];

  if (isLoading) {
    return (
      <div className="t-w-full">
        <Loader />
      </div>
    );
  }

  return (
    <>
      {noTaskYears ? (
        <TaskTable />
      ) : (
        <div className="t-flex t-w-full">
          <Accordion.Root
            type="multiple"
            className="t-relative t-flex t-h-full t-w-full t-flex-col t-gap-6"
            defaultValue={defaultOpenValue}
            onValueChange={onAccordionClicked}
          >
            {taskYears.map(({ task__season, count }: any) => (
              <Accordion.Item
                value={task__season}
                key={task__season}
                className="t-flex t-flex-col"
              >
                <Accordion.Trigger asChild>
                  <div className="t-group t-sticky t-z-10 t-flex t-flex-col t-gap-2 t-bg-surface t-py-5">
                    <button
                      className="all:unset t-flex t-w-full t-items-center t-gap-2 t-text-subtitle t-text-purple-30"
                      onClick={() => dispatch(closeTaskSlider())}
                    >
                      Tax Year {task__season}
                      <span className="group-data-state-open:-t-rotate-180 t-text-purple-30">
                        <SolidCaretDown />
                      </span>
                    </button>
                    <p className="t-border-y-0 t-border-l-4 t-border-r-0 t-border-solid t-border-purple-30 t-px-4 t-py-3 t-text-body t-font-normal t-shadow-table-shodow group-data-state-open:t-hidden">
                      {count} Filings
                    </p>
                  </div>
                </Accordion.Trigger>
                <Accordion.Content>
                  <TaskTable seasons={task__season} />
                </Accordion.Content>
              </Accordion.Item>
            ))}
          </Accordion.Root>
        </div>
      )}
    </>
  );
};
