import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { BOOKS } from "constants/productOnboardings";
import { useAppSelector } from "hooks/useAppSelector";
import { useToast } from "hooks/useToast";
import { FC } from "react";
import { useDispatch } from "react-redux";
import ConnectBankIcon from "static/images/ConnectBank.svg";
import { useCreateProductOnboardingMutation } from "store/apis/productOnboarding";
import { openOnboardingWidget } from "store/slices/onboardingWidget";

type IncompleteBooksOnboardingProps = {
  groupId: string;
};

export const IncompleteBooksOnboarding: FC<IncompleteBooksOnboardingProps> = ({
  groupId,
}) => {
  const dispatch = useDispatch();
  const [createBooksOnboarding, { isLoading }] =
    useCreateProductOnboardingMutation();
  const { alertToast } = useToast();
  const { isPricingStep } = useAppSelector((state) => state.taskSlice);

  const openBooksOnboarding = async () => {
    try {
      await createBooksOnboarding({
        groupId,
        productId: process.env.PUBLIC_BOOKS_ONBOARDING_PRODUCT_ID!,
      }).unwrap();
      dispatch(openOnboardingWidget(BOOKS));
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  if (isLoading) {
    return (
      <div className="t-w-full t-h-[calc(100%-84px)]">
        <Loader />
      </div>
    );
  }
  return (
    <div className="t-w-1/2 t-h-[calc(100%-84px)] t-justify-center t-flex t-flex-col t-items-center t-mx-auto">
      <img src={ConnectBankIcon} alt="ConnectBankIcon" />
      <div className="t-text-text-100 t-text-subtext-sm t-mt-7 t-mb-4 t-text-center">
        It looks like your Books onboarding is incomplete. Please connect all
        your US bank accounts and share access to proceed with the filing.
      </div>
      <Button
        customType="primary"
        onClick={openBooksOnboarding}
        size="small"
        disabled={isPricingStep}
      >
        Complete Books Onboarding
      </Button>
    </div>
  );
};
