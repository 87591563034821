import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";
import React from "react";
import { TaxProfileType } from "types/Models/group";

export const TaxProfile = ({ taxData }: { taxData: TaxProfileType }) => {
  return (
    <div className="t-rounded-lg t-border-neutral-10 t-border t-border-solid">
      <div className="!t-border-b t-border-0 t-border-neutral-10 t-p-4 t-border-solid t-text-subtitle">
        Tax Profile
      </div>
      <div className="t-p-4 t-space-y-6">
        <div className="t-grid t-grid-cols-2 t-gap-6">
          <InfoItem label="Tax Year">{taxData.tax_year}</InfoItem>
          <InfoItem label="BOIR Filed?">
            {taxData.is_boir_filed ? "Yes" : "No"}
          </InfoItem>
          <InfoItem label="Upcoming Deadlines">
            {taxData.upcoming_deadlines_count}
          </InfoItem>
        </div>
        <div className="t-grid t-grid-cols-2 t-gap-6">
          <InfoItem label="Ongoing Filings">
            {taxData.ongoing_filings_count}
          </InfoItem>
          <InfoItem label="Not Started Filings">
            {taxData.not_started_filings_count}
          </InfoItem>
          <InfoItem label="Completed Filings">
            {taxData.completed_filings_count}
          </InfoItem>
          <InfoItem label="Archived Filings">
            {taxData.archived_filings_count}
          </InfoItem>
        </div>
      </div>
    </div>
  );
};
