import classNames from "classnames";
import { PreviewModal } from "components/ChatPreviewModal/ChatPreviewModal";
import { FILE_VIEWED, VIEW_FILE_FAILED } from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePreviewUrl } from "hooks/usePreviewUrl";
import { useToast } from "hooks/useToast";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import PreviewImageName from "static/images/PreviewImageName.svg";
import { useGetFolderOrFileQuery } from "store/apis/previewUrl";
import { RootState } from "store/store";
import Loader from "./design/loader";

export const PreviewBody = ({
  file,
  preview_url,
  fullWidth = false,
}: {
  file: any;
  preview_url: string;
  fullWidth?: boolean;
}) => {
  console.log({ file, preview_url });

  if (preview_url) {
    if (file.file_type === "IMAGE") {
      return (
        <img
          src={preview_url}
          className="t-object-contain t-max-h-[90%] t-self-center t-w-10/12 t-justify-self-center -t-top-8 t-relative"
          alt={file.name}
        />
      );
    }

    if (!file.is_previewable && preview_url) {
      return (
        <div
          className={classNames(
            "t-flex t-items-center t-justify-center t-gap-1 t-rounded-lg t-p-7 t-bg-neutral-80 t-text-white t-h-14 t-self-center t-justify-self-center",
            {
              "t-w-2/5": !fullWidth,
              "t-w-full": fullWidth,
            }
          )}
        >
          File not previewable,{" "}
          <a
            href={preview_url}
            download
            className="t-text-blue-50 hover:t-text-blue"
          >
            click here
          </a>{" "}
          to download the file.
        </div>
      );
    }

    return (
      <iframe
        className="t-w-full t-h-[96%]"
        src={preview_url}
        title={file.name}
      />
    );
  }
  return null;
};

export const Preview = ({
  showModal,
  closeModal,
  groupId: groupIdFromProps,
  previewId: previewIdFromProps,
}: {
  showModal: boolean;
  closeModal: () => void;
  groupId?: string;
  previewId?: string | null;
}) => {
  const { trackEvent } = useAnalytics();
  const { alertToast } = useToast();
  const { previewId } = useParams<{ previewId: string }>();
  const { uuid } = useCurrentGroupContext();
  const { activeChannelGroupId } = useSelector(
    (state: RootState) => state.reviewAndBalancePayment
  );
  const groupId = (groupIdFromProps as string) || uuid || activeChannelGroupId;
  const docId = previewIdFromProps || previewId;

  const {
    data: file,
    isError: isfileError,
    error,
  } = useGetFolderOrFileQuery(
    { groupId: groupId, fileId: docId },
    { skip: !Boolean(docId) || !groupId || !showModal }
  );

  const {
    data,
    isError,
    isLoading,
    isSuccess,
    error: previewUrlError,
  } = usePreviewUrl({
    groupId: groupId,
    fileId: file?.uuid!,
  });

  useEffect(() => {
    if (isSuccess) {
      trackEvent(FILE_VIEWED, { file_id: file?.uuid! });
    }
    if (isError) {
      trackEvent(VIEW_FILE_FAILED, {
        file_id: file?.uuid!,
        //@ts-ignore
        error: previewUrlError?.data?.error?.message,
      });
    }
  }, [isSuccess, isError]);

  const { preview_url } = data || {};

  useEffect(() => {
    if (isError) {
      alertToast({
        message:
          "Some issue opening the document, please contact us if this happens in multiple instances",
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isfileError) {
      alertToast({
        //@ts-ignore
        message: error?.data?.error.message,
      });
      closeModal();
    }
  }, [isfileError]);

  return (
    <PreviewModal
      onClose={closeModal}
      title={
        <>
          <img src={PreviewImageName} alt={file?.name} />
          {file?.name}
        </>
      }
      open={showModal}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <PreviewBody file={file} preview_url={preview_url!} />
      )}
    </PreviewModal>
  );
};

export const DocumentPreviewModal = ({
  groupId,
}: {
  groupId?: string;
}): JSX.Element | null => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const { search } = useLocation();

  const closeModal = () => {
    history.push(`${url}${search}`);
  };

  return (
    <Switch>
      <Route path={`${url}/preview/:previewId/`}>
        <Preview closeModal={closeModal} groupId={groupId} showModal={true} />
      </Route>
    </Switch>
  );
};
