import Tab from "components/DesignSystem/Tab/Tab";
import { useRoleBasedView } from "hooks/useRoleBasedView";

function CrmTabBar({ selectedTabIs }) {
  const { isAdmin, isCpa, isForeignCA } = useRoleBasedView();
  let tabNames = [];
  if (isCpa && !isForeignCA) {
    tabNames = [
      {
        name: "All Clients",
        route: "/admin/crm",
      },
      {
        name: "Books CRM",
        route: "/admin/bookscrm",
      },
    ];
  }

  if (isAdmin) {
    tabNames = [
      {
        name: "All Clients",
        route: "/admin/crm",
      },
      {
        name: "Reg Agents",
        route: "/admin/radeadlines",
      },
      {
        name: "Books CRM",
        route: "/admin/bookscrm",
      },
      { name: "Archived", route: "/admin/archivedcrm" },
    ];
  }

  return (
    <div className="t-box-border t-w-full t-border t-border-l-0 t-border-r-0 t-border-t-0 t-border-solid t-border-neutral-20 t-pl-6">
      <Tab.Root defaultValue={`/${selectedTabIs}`}>
        <Tab.List>
          {tabNames.map(({ name, route }) => {
            return (
              <Tab.NavTrigger exact value={route} omitUrl key={route}>
                {name}
              </Tab.NavTrigger>
            );
          })}
        </Tab.List>
      </Tab.Root>
    </div>
  );
}

export default CrmTabBar;
