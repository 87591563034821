import * as Popover from "@radix-ui/react-popover";
import { AddCommentPop } from "components/AddComment/AddComment";
import { AddCommentPopoverRoot } from "components/AddCommentPopoverRoot/AddCommentPopoverRoot";
import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import Modal from "components/DesignSystem/Modal/Modal";
import { OptionDropdown } from "components/icons/optionDropdown";
import { Formik } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { BackendError } from "types/utils/error";
import { FinancialClosingDetail } from "store/apis/financialClosing";
import { useDeleteFinancialClosingMutation } from "store/apis/financialClosing";
import { FINANCIAL_CLOSING_CARD_TYPE } from "constants/chatType";
import { CLOSING_STATUS } from "constants/financialClosing";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useAppDispatch } from "hooks/useAppDispatch";
import { openFloatingChat, setToOpenChatId } from "store/slices/chat";
import { setChatCardAttachment } from "store/slices/financialClosing";

export const FinancialClosingListAction = ({
  financialClosingItem,
}: {
  financialClosingItem: FinancialClosingDetail;
}) => {
  const { alertToast, successToast } = useToast();
  const entityId = useCurrentEntityId();
  const [deleteClosing] = useDeleteFinancialClosingMutation();
  const dispatch = useAppDispatch();
  const { entities } = useCurrentGroupContext();

  const channelId = entities.find(
    ({ uuid }) => uuid === entityId
  )?.bookkeeping_channel;

  const deleteModal = useModal();

  const onDelete = async () => {
    try {
      await deleteClosing({
        entityId,
        financialClosingId: financialClosingItem.uuid,
      }).unwrap();
      successToast({ message: "Financial closing deleted" });
      deleteModal.close();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const onRaiseClarification = async () => {
    try {
      if (!channelId) {
        return alertToast({
          message: "No chat channel found for this entity",
        });
      }

      dispatch(
        setChatCardAttachment({
          entityId: entityId,
          financialClosingId: financialClosingItem.uuid,
          card_type: FINANCIAL_CLOSING_CARD_TYPE.CLARIFICATION_RAISED,
          start_date: financialClosingItem.start_date,
          end_date: financialClosingItem.end_date,
        })
      );
      dispatch(openFloatingChat());
      dispatch(setToOpenChatId(channelId));
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as Error
      );
    }
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <AddCommentPopoverRoot contentId={financialClosingItem.uuid}>
        <Dropdown.Root>
          <Modal.Root
            open={deleteModal.isOpen}
            onOpenChange={deleteModal.close}
          >
            <Dropdown.Trigger asChild>
              <Popover.Anchor asChild>
                <div>
                  <Button customType="ghost_icon" size="small">
                    <span className="t-text-text-30">
                      <OptionDropdown color="currentColor" />
                    </span>
                  </Button>
                </div>
              </Popover.Anchor>
            </Dropdown.Trigger>
            <Popover.Portal>
              <AddCommentPop
                commentType="FINANCIAL_CLOSING_COMMENT"
                contentId={financialClosingItem.uuid}
              />
            </Popover.Portal>
            <Dropdown.Content align="end" className="t-w-40">
              <Popover.Trigger asChild>
                <Dropdown.Item>Add comment</Dropdown.Item>
              </Popover.Trigger>

              {financialClosingItem.status ===
                CLOSING_STATUS.REVIEW_PENDING && (
                <Dropdown.Item onSelect={onRaiseClarification}>
                  Raise clarification
                </Dropdown.Item>
              )}

              <Modal.Trigger asChild>
                <Dropdown.Item type="danger" onSelect={deleteModal.open}>
                  Delete
                </Dropdown.Item>
              </Modal.Trigger>
            </Dropdown.Content>
            <Formik initialValues={{}} onSubmit={onDelete}>
              {({ isSubmitting, submitForm }) => (
                <Modal.Content>
                  <Modal.Header>
                    <Modal.Title>Delete financial closing</Modal.Title>
                    <Modal.Close />
                  </Modal.Header>
                  <Modal.Body className="t-text-body  t-text-text-100">
                    Are you sure you want to delete this financial closing?
                    <br />
                    This cannot be undone.
                  </Modal.Body>
                  <Modal.FooterButtonGroup>
                    <Modal.RawClose asChild>
                      <Button disabled={isSubmitting}>Cancel</Button>
                    </Modal.RawClose>
                    <Button
                      customType="danger"
                      onClick={submitForm}
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                    >
                      Delete
                    </Button>
                  </Modal.FooterButtonGroup>
                </Modal.Content>
              )}
            </Formik>
          </Modal.Root>
        </Dropdown.Root>
      </AddCommentPopoverRoot>
    </div>
  );
};
