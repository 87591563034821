import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";
import React from "react";
import { RecurringFilingsType } from "types/Models/group";

export const RecurringFilings = ({
  recurringFilingsData,
}: {
  recurringFilingsData: RecurringFilingsType;
}) => {
  return (
    <div className="t-rounded-lg t-border-neutral-10 t-border t-border-solid">
      <div className="!t-border-b t-border-0 t-border-neutral-10 t-p-4 t-border-solid t-text-subtitle">
        Recurring Filings
      </div>
      <div className="t-p-4 t-grid t-grid-cols-2 t-gap-6">
        <InfoItem label="Delaware Franchise Tax">
          {recurringFilingsData.delaware_franchise_tax ? "Yes" : "No"}
        </InfoItem>
        <InfoItem label="Sales Tax">
          {recurringFilingsData.sales_tax ? "Yes" : "No"}
        </InfoItem>
      </div>
    </div>
  );
};
