import React from "react";
import * as RUToggleGroup from "@radix-ui/react-toggle-group";
import classNames from "classnames";

export const ToggleGroup = ({
  className = "",
  type = "single",
  children,
  ...props
}: Omit<RUToggleGroup.ToggleGroupSingleProps, "type"> & {
  type?: RUToggleGroup.ToggleGroupSingleProps["type"];
}) => (
  <RUToggleGroup.Root
    type="single"
    {...props}
    className={classNames(
      "ToggleGroup t-inline-flex t-rounded-md t-bg-surface-lighter-grey t-text-center",
      className || ""
    )}
  >
    {children}
  </RUToggleGroup.Root>
);

export const ToggleGroupItem = ({
  size = "regular",
  color = "neutral",
  ...props
}: RUToggleGroup.ToggleGroupItemProps & {
  size?: "small" | "regular";
  color?: "neutral" | "primary";
}) => (
  <RUToggleGroup.Item
    {...props}
    tabIndex={0}
    className={classNames(
      "all:unset t-border-neutral-10 t-text-subtext-sm t-cursor-pointer first:t-rounded-l last:t-rounded-r t-justify-center first:t-border-l t-border-l-0 t-border t-border-solid data-[state=on]:t-border-neutral-10 t-flex-1 t-text-center data-[state=on]:t-shadow-[0px_1px_1px_0px_rgba(0,0,0,0.09)] t-flex t-items-center t-text-text-60 data-[state=on]:t-rounded-sm t-transform t-transition t-duration-300 t-ease-in-out",
      {
        "t-px-5 t-py-2 t-h-9": size === "regular",
        "t-h-[30px] t-w-[30px] t-justify-center": size === "small",
        "data-[state=on]:t-bg-surface": color === "neutral",
        "data-[state=on]:t-bg-surface-purple data-[state=on]:t-text-purple":
          color === "primary",
      }
    )}
  />
);
