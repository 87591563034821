import { Header } from "components/DesignSystem/Header/Header";
import { ColorInfo } from "components/icons/ColorInfo";
import { InvoiceForm } from "components/InvoiceForm/InvoiceForm";
import { InvoiceInfo } from "components/InvoiceInfo/InvoiceInfo";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery } from "hooks/useQuery";
import { ComponentProps, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetEntityInvoiceQuery } from "store/apis/invoices";

export const FormikListener = ({
  values,
  callback,
}: {
  values: Record<string, any>;
  callback: (v: Record<string, any>) => void;
}) => {
  const stringValues = JSON.stringify(values);

  useEffect(() => {
    callback(values);
  }, [stringValues]);

  return null;
};

export const AddInvoice = ({
  onChange,
}: {
  onChange: ComponentProps<typeof InvoiceForm>["onChange"];
}) => {
  const { invoiceId } = useParams<{
    invoiceId: string;
  }>();
  const query = useQuery();
  const group = useCurrentGroupContext();
  const editFlow = query.get("editflow");
  const entityId = useCurrentEntityId();

  const { data: invoice } = useGetEntityInvoiceQuery(
    {
      groupId: group?.uuid!,
      entityId: entityId!,
      invoiceId,
    },
    { skip: !group?.uuid || !entityId }
  );

  const isInvoiceSent = invoice && invoice.status === "SENT";
  const isSentInvoiceEditFlow = isInvoiceSent && Boolean(editFlow);

  if (!invoice) {
    return null;
  }

  return (
    <>
      {isSentInvoiceEditFlow && (
        <div className="t-rounded-lg t-bg-purple-0 t-border t-border-solid t-border-purple-10 t-p-3 t-flex t-items-center t-gap-2 t-text-purple t-mb-4">
          <ColorInfo />
          <div className="t-text-subtitle-sm t-text-text-100">
            You are editing a sent invoice
          </div>
        </div>
      )}

      {invoice.status === "DRAFT" || isSentInvoiceEditFlow ? (
        <InvoiceForm onChange={onChange} />
      ) : (
        <InvoiceInfo />
      )}
    </>
  );
};
