import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";

type ConfirmFilingModalProps = {
  isOpen: boolean;
  close: () => void;
  handleClick: () => void;
  isLoading: boolean;
};

export const ConfirmFilingModal = ({
  isOpen,
  close,
  handleClick,
  isLoading,
}: ConfirmFilingModalProps) => {
  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Confirm Submission</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body t-text-text-60 t-space-y-4">
            <div>
              You are about to submit your 1099 form to the IRS. Please take a
              moment to review the details to ensure accuracy.
            </div>
            <div>
              <b>Important: Once submitted, changes cannot be made.</b>
            </div>
            <div>Are you sure everything is correct and ready to submit?</div>
          </div>
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Modal.RawClose asChild>
            <Button onClick={close}>Cancel</Button>
          </Modal.RawClose>
          <Button
            customType="primary"
            onClick={handleClick}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Confirm
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};
