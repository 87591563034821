import { Button } from "components/DesignSystem/Button/Button";
import { Loader } from "components/DesignSystem/Loader/Loader";
import Modal from "components/DesignSystem/Modal/Modal";
import { CLICKED_SEND_INVOICE_FROM_EMAIL_PREVIEW_MODAL } from "constants/analyticsEvents";
import { Formik } from "formik";
import { useAnalytics } from "hooks/useAnalytics";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Invoice,
  invoiceApis,
  InvoiceSettings,
  useGetEntityInvoiceQuery,
  useGetInvoiceSettingsQuery,
  useLazyDownloadInvoiceQuery,
  useSendInvoiceMutation,
} from "store/apis/invoices";
import { SelecteFile } from "types/Models/documents";
import { Entity } from "types/Models/entity";
import { currency } from "utils/Currency";
import { SendInvoice } from "./SendInvoice";

export type SendInvoiceForm = {
  attachments: File[];
  subject: string;
  body: string;
  attachWform: boolean;
  wform: SelecteFile | null;
  from_email_type: string;
  cc_emails: string[];
};

export const invoiceBody = ({
  invoice,
  entity,
  invoiceSetting,
  isSentInvoiceEditFlow,
}: {
  invoice: Invoice;
  entity: Entity;
  invoiceSetting: InvoiceSettings;
  isSentInvoiceEditFlow: boolean;
}) => {
  const addUpdatedInEmail = isSentInvoiceEditFlow ? "updated " : "";

  return `Hi,

Please find ${addUpdatedInEmail}attached Invoice ${
    invoice.invoice_number
  } for the amount of ${currency({
    amount: invoice.due_balance || 0,
  })} from ${entity.name}, feel free to reach out if you have any questions.
${
  invoice.payment_link
    ? `
You can make the payment by opening this link - ${invoice.payment_link}.
`
    : ""
}${
    invoiceSetting?.payment_info
      ? `
Payment Instructions:
${invoiceSetting.payment_info}
`
      : ""
  }
Thank you for your prompt payment.

Best Regards,
${entity.name}
${invoiceSetting?.email}`;
};

export const invoiceSubject = ({
  entity,
  invoice,
  isReminder,
  isSentInvoiceEditFlow,
}: {
  entity: Entity;
  invoice: Invoice;
  isReminder?: boolean;
  isSentInvoiceEditFlow?: boolean;
}) => {
  let addUpdatedInSubject = isSentInvoiceEditFlow ? "Updated: " : "";
  if (isReminder) {
    addUpdatedInSubject += "Reminder: ";
  }

  return (
    addUpdatedInSubject +
    (invoice?.title || `You have a new invoice from ${entity.name}`)
  );
};

export const SendInvoiceModal = ({
  isOpen,
  close,
  invoiceIdFromProp,
  isReminder,
}: {
  isOpen: boolean;
  close: () => void;
  invoiceIdFromProp?: string;
  isReminder?: boolean;
}) => {
  const group = useCurrentGroupContext();
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const { successToast, alertToast } = useToast();
  const history = useHistory();
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const invoiceUuid = invoiceIdFromProp || invoiceId;
  const query = useQuery();
  const editFlow = query.get("editflow");
  const entityId = useCurrentEntityId();
  const [sendInvoice, { isLoading: sendingInvoice }] = useSendInvoiceMutation();

  const { data: invoiceSetting } = useGetInvoiceSettingsQuery(
    {
      groupId: group.uuid,
      entityId,
    },
    {
      skip: !group.uuid || !entityId,
    }
  );

  const { data: invoice, isLoading: invoiceLoading } = useGetEntityInvoiceQuery(
    {
      groupId: group?.uuid!,
      entityId: entityId!,
      invoiceId: invoiceUuid,
    },
    { skip: !group?.uuid || !entityId }
  );
  const isSentInvoiceEditFlow = invoice?.status === "SENT" && Boolean(editFlow);

  const [getInvoiceDownloadUrl, { isLoading: isDownloadingInvoice }] =
    useLazyDownloadInvoiceQuery();

  useEffect(() => {
    const maybeDownloadInvoice = async () => {
      if (invoice && !invoice.document && entityId) {
        await getInvoiceDownloadUrl({
          groupId: group.uuid,
          entityId: entityId,
          invoiceId: invoiceUuid,
        }).unwrap();

        dispatch(invoiceApis.util.invalidateTags([{ type: "INVOICES" }]));
      }
    };

    maybeDownloadInvoice();
  }, []);

  const { link } = useConstructInternalLink();

  const onSendInvoice = async (values: {
    attachments: File[];
    body: string;
    subject: string;
    cc_emails: string[];
    from_email_type: string;
  }) => {
    trackEvent(CLICKED_SEND_INVOICE_FROM_EMAIL_PREVIEW_MODAL);
    if (group?.uuid && entityId) {
      try {
        await sendInvoice({
          groupId: group.uuid,
          entityId: entityId,
          invoiceId: invoiceUuid,
          payload: {
            subject: values.subject,
            body: values.body,
            attachments: values.attachments,
            cc_emails: values.cc_emails.join(","),
            from_email_type: values.from_email_type,
          },
        }).unwrap();
        successToast({ message: "Invoice has been sent." });
        if (Boolean(invoiceIdFromProp)) {
          close();
          return;
        }
        history.push(link("/books/invoices-and-customers"));
      } catch (error: any) {
        alertToast({ message: error?.data?.error?.message });
      }
    }
  };

  const entity = group.entities.find((entity) => entity.uuid === entityId);

  if (!invoice || !entity || invoiceLoading || !invoiceSetting) {
    return (
      <Modal.Root>
        <Modal.Content>
          <Modal.Body>
            <div className="t-flex t-w-full t-h-full t-justify-center t-items-center">
              <Loader />
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    );
  }

  const defaultBody = invoiceBody({
    entity,
    invoice,
    invoiceSetting,
    isSentInvoiceEditFlow,
  });

  const subject = invoiceSubject({
    entity,
    invoice,
    isSentInvoiceEditFlow,
    isReminder,
  });

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content>
        <Formik<SendInvoiceForm>
          onSubmit={onSendInvoice}
          initialValues={{
            attachments: [] as File[],
            subject: subject,
            body: defaultBody,
            attachWform: invoice?.should_send_w_form,
            wform: invoice?.w_form || null,
            from_email_type: "INKLE",
            cc_emails: [],
          }}
        >
          {({ submitForm }) => {
            return (
              <>
                <Modal.Header>
                  <Modal.Title>Send Invoice</Modal.Title>
                  <Modal.Close />
                </Modal.Header>

                <Modal.Body>
                  <SendInvoice invoice={invoice} />
                </Modal.Body>
                <Modal.FooterButtonGroup>
                  <Modal.RawClose asChild>
                    <Button>Back</Button>
                  </Modal.RawClose>
                  <Button
                    customType="primary"
                    onClick={submitForm}
                    isLoading={sendingInvoice}
                    disabled={sendingInvoice || isDownloadingInvoice}
                    type="submit"
                  >
                    Send invoice
                  </Button>
                </Modal.FooterButtonGroup>
              </>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
