import React from "react";

export const PaymentRequestedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M13.3333 9.33333C13.3333 10.436 12.436 11.3333 11.3333 11.3333C11.3333 11.7013 11.0353 12 10.6667 12C10.298 12 10 11.7013 10 11.3333H9.82133C9.11 11.3333 8.446 10.9507 8.08933 10.334C7.90467 10.0153 8.014 9.608 8.332 9.42333C8.65133 9.23867 9.05867 9.348 9.24267 9.66667C9.362 9.872 9.58333 10 9.82067 10H11.3327C11.7007 10 11.9993 9.70066 11.9993 9.33333C11.9993 9.08133 11.8187 8.868 11.57 8.82666L9.54267 8.48866C8.648 8.34 7.99933 7.57333 7.99933 6.66667C7.99933 5.564 8.89667 4.66667 9.99933 4.66667C9.99933 4.29867 10.2973 4 10.666 4C11.0347 4 11.3327 4.29867 11.3327 4.66667H11.5113C12.2227 4.66667 12.8867 5.04933 13.2433 5.666C13.428 5.98466 13.3187 6.392 13.0007 6.57666C12.68 6.76066 12.2727 6.65133 12.09 6.33333C11.9707 6.128 11.7493 6 11.512 6H10C9.632 6 9.33333 6.29867 9.33333 6.66667C9.33333 6.91866 9.514 7.132 9.76267 7.17333L11.79 7.51133C12.6847 7.66 13.3333 8.42666 13.3333 9.33333ZM16 4.66667V11.3333C16 13.1713 14.5047 14.6667 12.6667 14.6667H3.33333C1.49533 14.6667 0 13.1713 0 11.3333V4.66667C0 2.82867 1.49533 1.33333 3.33333 1.33333H12.6667C14.5047 1.33333 16 2.82867 16 4.66667ZM14.6667 4.66667C14.6667 3.564 13.7693 2.66667 12.6667 2.66667H3.33333C2.23067 2.66667 1.33333 3.564 1.33333 4.66667V11.3333C1.33333 12.436 2.23067 13.3333 3.33333 13.3333H12.6667C13.7693 13.3333 14.6667 12.436 14.6667 11.3333V4.66667ZM6 4.66667H3.33333C2.96467 4.66667 2.66667 4.96533 2.66667 5.33333C2.66667 5.70133 2.96467 6 3.33333 6H6C6.36867 6 6.66667 5.70133 6.66667 5.33333C6.66667 4.96533 6.36867 4.66667 6 4.66667ZM6 7.33333H3.33333C2.96467 7.33333 2.66667 7.632 2.66667 8C2.66667 8.368 2.96467 8.66667 3.33333 8.66667H6C6.36867 8.66667 6.66667 8.368 6.66667 8C6.66667 7.632 6.36867 7.33333 6 7.33333ZM6 10H3.33333C2.96467 10 2.66667 10.2987 2.66667 10.6667C2.66667 11.0347 2.96467 11.3333 3.33333 11.3333H6C6.36867 11.3333 6.66667 11.0347 6.66667 10.6667C6.66667 10.2987 6.36867 10 6 10Z"
      fill="#706A85"
    />
  </svg>
);
