import React from "react";
import { FileIcon } from "utils/fileTypeIcon";
import { CustomData } from "types/Models/reviewDocumentAndBalancingPayment";
import { Button } from "components/DesignSystem/Button/Button";
import { DownloadIcon } from "components/icons/Download";
import { SmallMultipleFiles } from "components/icons/SmallMultipleFiles";
import classNames from "classnames";
import TinySuccess from "static/images/TinySuccess.svg";
import { pluralize } from "utils/pluralize";
import { ClampedTextWithTooltip } from "utils/ClampedTextWithTooltip";

export const PricingAndDescription = ({
  price,
  description,
}: {
  price: string;
  description?: string | null;
}) => {
  return (
    <div className="t-space-y-1.5">
      <div className="t-text-h5 t-text-text-60">${price}</div>
      {description && (
        <ClampedTextWithTooltip
          text={description}
          lineClampElementClass="t-line-clamp-2 t-text-body-sm t-text-text-30"
          toolTipClass="t-w-64 t-word-break"
        />
      )}
    </div>
  );
};

export const ReviewChatCardBody = ({
  customData,
  downloadFiles,
  isPaymentRequired,
  isDocReviewed,
}: {
  customData: CustomData;
  downloadFiles: () => void;
  isPaymentRequired?: boolean;
  isDocReviewed?: boolean;
}) => (
  <div>
    {customData.price && (
      <>
        <PricingAndDescription
          price={customData.price}
          description={customData.description}
        />
        <div className="t-border t-border-dashed t-border-neutral-10 t-border-t-0 t-border-l-0 t-border-r-0 t-mt-3 t-mb-2"></div>
      </>
    )}

    <div className="t-flex t-justify-between t-items-center">
      <div className="t-flex t-gap-3 t-items-center">
        <div className="t-h-6 t-w-6 t-rounded-full t-border t-border-solid t-border-neutral-0 t-flex t-items-center t-justify-center t-relative">
          <SmallMultipleFiles />
          {customData.card_status === "SUBMITTED" || isDocReviewed ? (
            <img
              src={TinySuccess}
              alt="TinySuccess"
              className="t-bottom-0 t-right-0 t-absolute"
            />
          ) : (
            <div className="t-min-w-1.5 t-min-h-1.5 t-max-h-1.5 t-max-w-1.5 t-rounded-full t-bottom-0 t-right-0 t-absolute t-bg-orange" />
          )}
        </div>

        <div className="t-text-subtext t-text-text-60">
          {pluralize(
            customData?.ctas?.[0]?.metadata?.file_data?.length,
            "attachment",
            "attachments"
          )}
        </div>
      </div>
      <Button customType="ghost_icon" size="small" onClick={downloadFiles}>
        <span className="t-text-text-60">
          <DownloadIcon color="currentColor" strokeWidth="1.5" size={24} />
        </span>
      </Button>
    </div>
  </div>
);
