import { Button } from "components/DesignSystem/Button/Button";
import { Tag } from "components/DesignSystem/Tag/Tag";
import {
  TASK_ADDITIONAL_PAYMENT,
  TASK_REVIEW_AND_PAY,
  TASK_REVIEW_DOCUMENT,
} from "constants/chatType";
import React, { FC, ReactNode } from "react";
import DoubleGreenTick from "static/images/DoubleGreenTick.svg";

type ChatCardButtonProps = {
  urlText: string;
  documentOperation?: string;
  isPaymentRequired?: boolean;
  isDocReviewed?: boolean;
  handleCardAction: () => void;
  disabled?: boolean;
  amount?: string;
};

export const ChatCardButton: FC<ChatCardButtonProps> = ({
  urlText,
  documentOperation,
  isPaymentRequired,
  isDocReviewed,
  disabled,
  handleCardAction,
  amount,
}) => {
  const isBalancePayment = documentOperation === TASK_ADDITIONAL_PAYMENT;
  const isBalancePaymentPaid =
    isDocReviewed && isBalancePayment && !isPaymentRequired;

  const isDisabled = isBalancePaymentPaid || disabled;

  let btnContent: string | ReactNode = urlText;

  if (isBalancePaymentPaid) {
    return (
      <Tag tagType="green" rounded>
        Paid
      </Tag>
    );
  }

  if (
    isDocReviewed &&
    !isBalancePayment &&
    documentOperation === TASK_REVIEW_DOCUMENT &&
    !isPaymentRequired
  ) {
    return (
      <Button
        block
        disabled={isDisabled}
        onClick={handleCardAction}
        size="small"
      >
        View
      </Button>
    );
  }

  if (
    isDocReviewed &&
    !isBalancePayment &&
    documentOperation === TASK_REVIEW_AND_PAY &&
    !isPaymentRequired
  ) {
    return (
      <>
        <Tag tagType="green" rounded>
          Reviewed & Paid
        </Tag>
        <Button
          block
          disabled={isDisabled}
          onClick={handleCardAction}
          size="small"
        >
          View
        </Button>
      </>
    );
  }

  if (isBalancePayment && isPaymentRequired) {
    return (
      <Button
        customType="primary"
        block
        disabled={isDisabled}
        onClick={handleCardAction}
        size="small"
      >
        Pay
      </Button>
    );
  }

  if (isDocReviewed && isPaymentRequired && !isBalancePayment) {
    btnContent = amount ? `Pay $${amount}` : "Payment pending";
  }

  return (
    <Button
      customType="primary"
      block
      disabled={isDisabled}
      onClick={handleCardAction}
      size="small"
    >
      {btnContent}
    </Button>
  );
};
