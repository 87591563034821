import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { MultiValue, SingleValue } from "react-select";
import {
  RAMailroomSubscriptionType,
  SupportStepType,
  useUpdateRAMailroomMutation,
} from "store/apis/RAMailroom";
import { AddMailroomDetails } from "./AddMailroomDetails";
import { AddRA } from "./AddRA";
import { ColorDot } from "./RAMailroomSubscriptions";

export const SupportStep = ({
  subscription,
}: {
  subscription: RAMailroomSubscriptionType;
}) => {
  const { alertToast, successToast } = useToast();
  const [updateRAMailroom, { isLoading }] = useUpdateRAMailroomMutation();
  const mailroomDetailModal = useModal();
  const registerAgentModal = useModal();

  const updateSupportStep = async (value: SupportStepType) => {
    try {
      await updateRAMailroom({
        raMailroomId: subscription.uuid,
        payload: { support_step: value },
      }).unwrap();
      successToast({ message: "Support step successfully updated" });
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  const handleSupportStepUpdate = (value: SupportStepType) => {
    if (value !== "COMPLETED") {
      updateSupportStep(value);
    } else {
      if (subscription.subscription_type == "MAILROOM") {
        mailroomDetailModal.open();
      }
      if (subscription.subscription_type == "REGISTERED_AGENT") {
        registerAgentModal.open();
      }
    }
  };

  const closeAddRA = () => {
    registerAgentModal.close();
    updateSupportStep("COMPLETED");
  };

  const closeAddMailroomDetails = () => {
    mailroomDetailModal.close();
    updateSupportStep("COMPLETED");
  };

  const supportOptions = [
    {
      label: (
        <div className="t-flex t-items-center t-gap-1.5 t-text-body t-text-text-100">
          <ColorDot color="t-bg-red" />
          <div className="t-text-ellipsis t-truncate">
            Subscription initiated
          </div>
        </div>
      ),
      value: "INITIATED",
    },
    {
      label: (
        <div className="t-flex t-items-center t-gap-1.5 t-text-body t-text-text-100">
          <ColorDot color="t-bg-yellow" />
          <div>Processing</div>
        </div>
      ),
      value: "PROCESSING",
    },
    {
      label: (
        <div className="t-flex t-items-center t-gap-1.5 t-text-body t-text-text-100">
          <ColorDot color="t-bg-green-70" />
          <div>Completed</div>
        </div>
      ),
      value: "COMPLETED",
    },
  ];

  const selectedOption =
    supportOptions.find(
      (option) => option.value === subscription.support_step
    ) || null;

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Combobox
        components={{
          ClearIndicator: () => null,
        }}
        size="small"
        menuPortalTarget={document.body}
        value={selectedOption}
        name="supportStep"
        label=""
        placeholder="Select"
        options={supportOptions}
        isLoading={isLoading}
        onChange={(
          selectedOption: MultiValue<OptionData> | SingleValue<OptionData>
        ) =>
          handleSupportStepUpdate(
            (selectedOption as SingleValue<OptionData>)
              ?.value as SupportStepType
          )
        }
        withForm={false}
        fullWidth
      />
      {registerAgentModal.isOpen && (
        <AddRA
          onSuccess={closeAddRA}
          {...registerAgentModal}
          groupId={subscription.company.uuid}
          entityId={subscription.entity.uuid}
        />
      )}
      {mailroomDetailModal.isOpen && (
        <AddMailroomDetails
          {...mailroomDetailModal}
          onSuccess={closeAddMailroomDetails}
          entityId={subscription.entity.uuid}
        />
      )}
    </div>
  );
};
