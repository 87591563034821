import React from "react";
import { AccountInfoContent } from "./AccountInfoContent";
import { Group } from "types/Models/group";

export const AccountInfoTabView = ({ group }: { group?: Group }) => {
  if (!group) {
    return null;
  }
  return (
    <div className="t-p-6">
      <AccountInfoContent group={group} />
    </div>
  );
};
