import React from "react";

export const PendingDuesIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clip-path="url(#clip0_5100_16909)">
      <path
        d="M13.9999 12H13.9793L13.2139 6.336C13.0473 5.004 11.9093 4 10.5679 4H5.43261C4.09061 4 2.95328 5.004 2.78728 6.32933L2.02128 12H2.00061C0.897944 12 0.000610352 12.8973 0.000610352 14V16H16.0006V14C16.0006 12.8973 15.1026 12 13.9999 12ZM4.10928 6.50133C4.19261 5.83533 4.76194 5.33333 5.43261 5.33333H10.5679C11.2386 5.33333 11.8079 5.83533 11.8919 6.508L12.6339 12H3.36661L4.10928 6.50133ZM14.6666 14.6667H1.33328V14C1.33328 13.6327 1.63261 13.3333 1.99994 13.3333H13.9999C14.3673 13.3333 14.6666 13.6327 14.6666 14V14.6667ZM5.43194 6.66667H7.99994V8H6.59728L6.41728 9.33333H5.07194L5.43194 6.66667ZM2.09061 4.21133L0.259277 2.458L1.18194 1.49533L3.01328 3.24933L2.09061 4.21133ZM4.30194 2.75L3.94328 0.222001L5.26394 0.034668L5.62261 2.56267L4.30194 2.75ZM11.6973 2.75L10.3773 2.56267L10.7359 0.034668L12.0559 0.222001L11.6973 2.75ZM13.9086 4.21133L12.9859 3.24867L14.8173 1.49467L15.7399 2.45733L13.9086 4.21133Z"
        fill="#706A85"
      />
    </g>
    <defs>
      <clipPath id="clip0_5100_16909">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
