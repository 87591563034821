import classNames from "classnames";
import { Divider } from "components/design/Divider";
import { Button } from "components/DesignSystem/Button/Button";
import { MobileInput } from "components/DesignSystem/MobileInput/MobileInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { Switch } from "components/DesignSystem/Switch/Switch";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { SUBSCRIBED_TO_DEADLINE_REMINDERS } from "constants/analyticsEvents";
import { SOMETHING_WENT_WRONG } from "constants/apiCallError";
import { Field, FieldProps, Form, Formik, FormikValues } from "formik";
import { calendarReminderSchema } from "formValidations/calendarReminderSchema";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import { useContext } from "react";
import PhoneInput from "react-phone-input-2";
import SubscribeMail from "static/images/SubscribeMail.svg";
import SubscribeSlack from "static/images/SubscribeSlack.svg";
import SubscribeWhatsapp from "static/images/SubscribeWhatsapp.svg";
import {
  useGetCalendarReminderQuery,
  useUpdateCalendarReminderMutation,
} from "store/apis/calendar";
import { CalendarReminder } from "types/Models/calendar";

const RemindInButton = ({
  values,
  remindIn,
  text,
  selected = false,
  setFieldValue,
}: {
  values: FormikValues;
  remindIn: string;
  text: string;
  selected: boolean;
  setFieldValue: (name: string, value: any) => void;
}) => {
  const formValueReminder = values?.reminder_periods.find(
    (uuid: string) => uuid === remindIn
  );

  const handleOnClick = () => {
    const ifAlreadySelected = values?.reminder_periods.find(
      (uuid: string) => uuid === remindIn
    );
    let remindersPeriods;
    if (!ifAlreadySelected) {
      remindersPeriods = [...values?.reminder_periods, remindIn];
    } else {
      remindersPeriods = [...values?.reminder_periods].filter(
        (uuid) => uuid !== remindIn
      );
    }
    setFieldValue("reminder_periods", remindersPeriods);
  };

  return (
    <button
      type="button"
      className={classNames("t-rounded t-px-2 t-h-8", {
        "t-bg-purple-0 t-border t-border-solid  t-text-purple t-border-purple-40":
          formValueReminder === remindIn,
        "t-border t-border-solid t-border-surface-grey t-text-text-60 t-bg-surface-grey hover:t-border-solid hover:t-border-purple-20 hover:t-bg-purple-0":
          formValueReminder !== remindIn,
      })}
      onClick={handleOnClick}
    >
      {text}
    </button>
  );
};

export const ReminderModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { trackEvent } = useAnalytics();
  const { alertToast, successToast } = useToast();
  const entityId = useCurrentEntityId();
  const { data: reminders } = useGetCalendarReminderQuery({ entityId });
  const { authtoken } = useContext(authContext);
  const [updateReminders, { isLoading: updatingReminder }] =
    useUpdateCalendarReminderMutation();
  const { isPublicUser } = useRoleBasedView();

  const onSetReminder = async (
    values: Pick<
      CalendarReminder,
      | "email"
      | "mobile"
      | "send_email_reminders"
      | "send_wa_reminders"
      | "entity_id"
    >
  ) => {
    try {
      trackEvent(SUBSCRIBED_TO_DEADLINE_REMINDERS, values);
      await updateReminders(values).unwrap();
      successToast({ message: "Deadline reminders has been updated" });
      onClose();
    } catch (error: any) {
      alertToast({ message: error?.message || SOMETHING_WENT_WRONG });
    }
  };

  const initialReminderValues =
    reminders?.reminder_periods
      ?.filter(({ reminder_set }) => reminder_set)
      ?.map(({ uuid }) => uuid) || [];

  return (
    <Modal.Root open={isOpen} onOpenChange={onClose}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>
              <div className="t-text-h5">Subscribe to Reminder</div>
            </Modal.Title>
            <Modal.Close />
          </Modal.Header>

          <Formik
            onSubmit={(v) => onSetReminder(v)}
            validationSchema={calendarReminderSchema}
            initialValues={{
              send_wa_reminders: reminders?.send_wa_reminders || false,
              send_email_reminders: reminders?.send_email_reminders || false,
              email: reminders?.email || authtoken?.email || "",
              mobile: reminders?.mobile || authtoken?.mobile || "+1",
              reminder_periods: initialReminderValues,
              entity_id: entityId,
            }}
          >
            {({ values, setFieldValue }) => (
              <Form className="t-m-0 t-w-full">
                <Modal.Body>
                  <div>
                    <div className="t-flex t-flex-col t-gap-5">
                      {isPublicUser && (
                        <div>
                          <div className="t-text-subtitle-sm t-mb-2">
                            Channels
                          </div>

                          <Divider />
                        </div>
                      )}
                      <div className="t-flex t-flex-col t-gap-6">
                        <div className="t-flex t-flex-col t-gap-3">
                          <div className="t-flex t-flex-wrap t-gap-2 t-items-center md:t-flex-nowrap md:t-gap-0">
                            <div className="t-w-full t-flex t-flex-wrap t-gap-2 md:t-flex-nowrap md:t-gap-0 t-items-center">
                              <img
                                src={SubscribeMail}
                                alt="SubscribeMail"
                                height="24px"
                                width="24px"
                                className="t-order-1 t-mr-0 md:t-mr-2.5"
                              />
                              <p className="t-order-2 t-m-0 t-text-body md:t-hidden">
                                Email reminder
                              </p>
                              <div className="t-order-4 t-w-full md:t-order-3">
                                <TextInput
                                  block
                                  name="email"
                                  type="email"
                                  placeholder="Enter email"
                                />
                              </div>
                            </div>
                            <div className="t-order-3 t-ml-auto t-flex md:t-order-4 md:t-ml-6">
                              <Switch
                                checked={values.send_email_reminders}
                                size="small"
                                name="send_email_reminders"
                                onCheckedChange={(v) => {
                                  setFieldValue("send_email_reminders", v);
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="t-flex t-flex-col t-gap-3">
                          <div className="t-flex t-flex-wrap t-gap-2 t-items-center md:t-flex-nowrap md:t-gap-0">
                            <div className="t-flex t-w-full t-items-center t-flex-wrap t-gap-2 md:t-flex-nowrap md:t-gap-0">
                              <img
                                src={SubscribeWhatsapp}
                                alt="SubscribeWhatsapp"
                                height="28px"
                                width="28px"
                                className="t-order-1 t-mr-1 md:t-mr-2.5"
                              />
                              <p className="t-order-2 t-m-0 t-text-body md:t-hidden">
                                WhatsApp reminder
                              </p>

                              <div className="t-order-4 -t-ml-1 t-w-full t-items-center md:t-order-3">
                                <MobileInput name="mobile" />
                              </div>
                            </div>
                            <div className="t-order-3 t-ml-auto t-flex md:t-order-4 md:t-ml-6">
                              <Switch
                                checked={values.send_wa_reminders}
                                size="small"
                                name="send_wa_reminders"
                                onCheckedChange={(v) => {
                                  setFieldValue("send_wa_reminders", v);
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="t-flex t-items-center -t-ml-1">
                          <img
                            src={SubscribeSlack}
                            alt="SubscribeSlack"
                            className="t-w-5 t-h-5 t-mr-1.5 t-shrink-0 t-flex-grow md:t-w-8"
                          />
                          <p className="t-m-0 t-w-full t-whitespace-nowrap t-text-body">
                            Slack reminders
                          </p>
                          <span className="t-ml-6 t-whitespace-nowrap t-rounded t-bg-purple-0 t-px-2 t-py-1 t-text-body-sm t-text-purple">
                            Coming soon!
                          </span>
                        </div>
                      </div>

                      {isPublicUser && (
                        <div>
                          <div>
                            <div className="t-text-subtitle-sm t-mb-2">
                              Frequency
                            </div>
                            <Divider />
                          </div>

                          <div className="t-flex t-gap-3 t-text-body-sm t-mt-4">
                            {reminders?.reminder_periods?.map(
                              ({ name, days, uuid, reminder_set }) => (
                                <RemindInButton
                                  key={uuid}
                                  values={values}
                                  remindIn={uuid}
                                  text={name}
                                  selected={reminder_set}
                                  setFieldValue={setFieldValue}
                                />
                              )
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="t-flex t-justify-end t-gap-3">
                    <Button
                      customType="primary"
                      isLoading={updatingReminder}
                      type="submit"
                      disabled={updatingReminder}
                    >
                      Subscribe
                    </Button>
                  </div>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};
