import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useToast } from "hooks/useToast";
import { useLocation, useRouteMatch } from "react-router-dom";
import { useLazyGetAuthorizationURLGmailQuery } from "store/apis/stakeholderUpdate";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";

export const AuthoriseEmail = ({ isOpen, close }: ModalProps) => {
  const { url } = useRouteMatch();
  const { search } = useLocation();
  const entityId = useCurrentEntityId();
  const { alertToast } = useToast();
  const [getGmailUrl, { isLoading: isGmailUrlLoading }] =
    useLazyGetAuthorizationURLGmailQuery();

  const sendViaGmail = async () => {
    try {
      const { authorization_url } = await getGmailUrl({
        callbackUrl: `${url}${search}`,
        source: "INVOICE",
        entityId,
      }).unwrap();
      window.open(authorization_url);
    } catch (error) {
      alertToast(
        { message: (error as BackendError).data?.error?.message },
        error as Error
      );
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content size="small">
        <Modal.Header>
          <Modal.Title>Authorise email</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body className="t-text-text-60 t-text-body">
          Authorise Gmail to send invoices directly from your personal email
          account
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Modal.RawClose asChild>
            <Button disabled={isGmailUrlLoading}>Cancel</Button>
          </Modal.RawClose>
          <Button
            customType="primary"
            onClick={sendViaGmail}
            isLoading={isGmailUrlLoading}
            disabled={isGmailUrlLoading}
          >
            Authorise
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};
