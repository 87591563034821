import { axiosInstance } from "./apiInstance";

export const putForm = async ({ formId, params }) => {
  const response = await axiosInstance.put(
    `/api/inkle/tasks/form_instance/${formId}/`,
    params
  );
  return response.data;
};

export const put7004Form = async ({ params, session_id }) => {
  const response = await axiosInstance.put(
    `/api/inkle/tasks/form_template/${process.env.PUBLIC_EXTENSION_TASK_TEMPLATE_UUID}/?session_id=${session_id}`,
    params
  );

  return response.data;
};
