export const EmptySalesTax = () => (
  <svg
    width="169"
    height="98"
    viewBox="0 0 169 98"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_3694_59434)">
      <rect
        x="4.5"
        y="3"
        width="145.155"
        height="22.543"
        rx="2.19931"
        fill="white"
      />
      <circle cx="17.6958" cy="13.9969" r="6.59794" fill="#F3F3F5" />
    </g>
    <g filter="url(#filter1_d_3694_59434)">
      <rect
        x="19.3453"
        y="36.5396"
        width="145.155"
        height="22.543"
        rx="2.19931"
        fill="#F3F3F5"
      />
      <rect
        x="47.3865"
        y="43.137"
        width="83.5739"
        height="2.74914"
        rx="1.37457"
        fill="white"
      />
      <rect
        x="47.3865"
        y="49.1851"
        width="52.7835"
        height="2.74914"
        rx="1.37457"
        fill="white"
      />
      <circle cx="32.5412" cy="47.5354" r="6.59794" fill="#CFCDD6" />
      <g clip-path="url(#clip0_3694_59434)">
        <path
          d="M29.2422 48.262L31.0566 50.0438L35.2903 45.8857"
          stroke="white"
          stroke-width="1.13402"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
    <g filter="url(#filter2_d_3694_59434)">
      <rect
        x="4.5"
        y="68.4297"
        width="145.155"
        height="22.543"
        rx="2.19931"
        fill="white"
      />
      <circle cx="17.6958" cy="79.4266" r="6.59794" fill="#F3F3F5" />
    </g>
    <defs>
      <filter
        id="filter0_d_3694_59434"
        x="0.101375"
        y="0.800687"
        width="153.952"
        height="31.3402"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.19931" />
        <feGaussianBlur stdDeviation="2.19931" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.198932 0 0 0 0 0.155113 0 0 0 0 0.347917 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3694_59434"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3694_59434"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_3694_59434"
        x="14.9467"
        y="34.3402"
        width="153.952"
        height="31.3402"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.19931" />
        <feGaussianBlur stdDeviation="2.19931" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.198932 0 0 0 0 0.155113 0 0 0 0 0.347917 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3694_59434"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3694_59434"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_3694_59434"
        x="0.101375"
        y="66.2304"
        width="153.952"
        height="31.3402"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.19931" />
        <feGaussianBlur stdDeviation="2.19931" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.198932 0 0 0 0 0.155113 0 0 0 0 0.347917 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3694_59434"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3694_59434"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_3694_59434">
        <rect
          width="8.79725"
          height="8.79725"
          fill="white"
          transform="translate(28.1425 43.1367)"
        />
      </clipPath>
    </defs>
  </svg>
);
