import { default as classNames, default as cx } from "classnames";
import ToolTip from "components/design/toolTip";
import { ToolTipIcon } from "components/icons/TooltipIcon";
import { Field, FieldProps, getIn } from "formik";
import React, { HTMLAttributes, ReactNode, useId } from "react";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode;
  customSize?: "regular" | "large" | "small";
  customType?: "error" | "success" | "warning";
  block?: boolean;
  name?: string;
  tooltipText?: string;
  icon?: ReactNode;
  customPrefix?: ReactNode;
  rightComponent?: ReactNode;
  isDateInput?: boolean;
  required?: boolean;
  note?: string;
  hideError?: boolean;
  autoResize?: boolean;
  showErrorOnceTouched?: boolean;
}

export const ErrorMessage = ({ children }: { children: ReactNode }) => (
  <div className="t-mt-1.5 t-text-caption t-leading-none t-text-red">
    {children}
  </div>
);

export const NoteMessage = ({ children }: { children: ReactNode }) => (
  <div className="t-mt-1.5 t-text-caption t-text-text-30 t-leading-none">
    {children}
  </div>
);

export const INPUT_CLASSNAMES = (
  customSize: InputProps["customSize"],
  customType: InputProps["customType"],
  autoResize?: boolean
) =>
  /* @tw */
  cx(
    "t-flex t-w-full t-items-center t-rounded t-justify-between t-border t-border-solid t-bg-surface-lighter-grey t-px-3 t-text-body t-text-text-100 t-transition-all focus:t-bg-surface-transparent focus:t-outline-none disabled:t-cursor-no-drop disabled:t-text-neutral-30 t-appearance-textfield placeholder:t-text-text-30 hover:t-border-purple-30 hover:t-bg-surface-grey",

    {
      "t-h-12": customSize === "large",
      "t-h-10": customSize === "regular",
      "t-h-8": customSize === "small",
      "t-border-red focus:t-border-red hover:!t-border-red":
        customType === "error",
      "t-border-yellow focus:t-border-yellow": customType === "warning",
      "t-border-neutral-10 focus:t-border-purple": customType !== "error",
      /* @tw */
      "t-inline-grid t-align-top t-items-center t-relative after:t-max-w-40 after:t-content-[attr(data-after)] after:t-invisible after:t-whitespace-nowrap focus-within:t-w-min":
        autoResize,
      /* @tw */
      "t-flex": !autoResize,
    }
  );

export const BareInput = ({
  type = "text",
  customSize = "regular",
  label,
  customType,
  block,
  tooltipText,
  className,
  required = false,
  autoResize,
  ...props
}: InputProps) => (
  <>
    <input
      id={props.name}
      type={type}
      className={INPUT_CLASSNAMES(customSize, customType, autoResize)}
      required={required}
      autoComplete="off"
      {...props}
    />
  </>
);

export const Label = ({
  htmlFor,
  required = false,
  className,
  ...props
}: HTMLAttributes<HTMLLabelElement> & {
  htmlFor?: string;
  required?: boolean;
}) => (
  <label
    className={classNames(
      "t-text-caption t-text-text-30 t-pb-1.5",
      {
        "after:t-font-bold after:t-text-red after:t-content-['_*']": required,
      },
      className
    )}
    htmlFor={htmlFor}
    {...props}
  />
);

export function TextInput(inputProps: InputProps) {
  const {
    type = "text",
    label,
    tooltipText,
    customType,
    customPrefix,
    icon,
    rightComponent,
    isDateInput,
    required = false,
    note,
    hideError,
    showErrorOnceTouched,
    autoResize,
    ...props
  } = inputProps;

  if (customPrefix || icon || rightComponent) {
    return <TextInputWithPrefix {...inputProps} />;
  }

  return (
    <Field type={type} name={props.name} className="t-w-full" {...props}>
      {({ field, form: { touched, errors, validateOnChange } }: FieldProps) => {
        let hasError = getIn(errors, field.name) && getIn(touched, field.name);

        if (validateOnChange && !showErrorOnceTouched) {
          hasError = getIn(errors, field.name);
        }

        const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const value = e.target.value;
          field.onChange(e);

          if (
            (isDateInput && !isNaN(new Date(value).valueOf())) ||
            !isDateInput
          ) {
            props.onChange?.(e);
          }
        };

        return (
          <span className="t-relative t-block t-w-full">
            {(label || tooltipText) && (
              <div className="t-flex t-items-center ">
                <div className="t-flex t-items-center t-justify-center">
                  {label && (
                    <Label htmlFor={props.name} required={required}>
                      {label}
                    </Label>
                  )}
                  {tooltipText && (
                    <div className="t-ml-1 t-pb-1.5">
                      <ToolTip text={tooltipText} side="right">
                        <span>
                          <ToolTipIcon />
                        </span>
                      </ToolTip>
                    </div>
                  )}
                </div>
              </div>
            )}

            <BareInput
              {...{ ...field, ...props }}
              onChange={onChange}
              type={type}
              customType={hasError ? "error" : customType}
              required={required}
              autoResize={autoResize}
            />

            {!hideError && hasError && (
              <ErrorMessage>{getIn(errors, field.name)}</ErrorMessage>
            )}

            {note && <NoteMessage>{note}</NoteMessage>}
          </span>
        );
      }}
    </Field>
  );
}

function TextInputWithPrefix({
  type = "text",
  customSize = "regular",
  label,
  customType,
  block,
  tooltipText,
  className,
  customPrefix,
  icon,
  rightComponent,
  autoResize,
  hideError,
  showErrorOnceTouched,
  ...props
}: {
  customPrefix?: ReactNode;
  icon?: ReactNode;
} & InputProps) {
  return (
    <div className="t-relative t-w-full">
      <div className="t-flex t-items-center">
        {(label || tooltipText) && (
          <div className="t-flex t-items-center t-justify-center">
            {label && (
              <Label htmlFor={props.name} required={props.required}>
                {label}
              </Label>
            )}
            {tooltipText && (
              <div className="t-ml-1">
                <ToolTip text={tooltipText} side="right">
                  <span>
                    <ToolTipIcon />
                  </span>
                </ToolTip>
              </div>
            )}
          </div>
        )}
      </div>

      <Field type={type} name={props.name} className="w-100" {...props}>
        {({
          field,
          form: { touched, errors, validateOnChange },
        }: FieldProps) => {
          let hasError =
            getIn(errors, field.name) && getIn(touched, field.name);

          if (validateOnChange && !showErrorOnceTouched) {
            hasError = getIn(errors, field.name);
          }

          const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            field.onChange(e);
            props.onChange?.(e);
          };

          return (
            <>
              <div
                data-after={field.value}
                className={INPUT_CLASSNAMES(
                  customSize,
                  hasError ? "error" : customType,
                  autoResize
                )}
              >
                {customPrefix}
                {customPrefix && (
                  <div className="t-h-full t-px-4 t-py-2">
                    <div className="t-h-full t-border-[0.5px] t-border-solid t-border-neutral-20" />
                  </div>
                )}
                <input
                  id={props.name}
                  type={type}
                  className={cx("all:unset t-appearance-textfield", {
                    "t-h-12": customSize === "large",
                    "t-h-10": customSize === "regular",
                    "t-h-8": customSize === "small",
                    "t-w-3/4": icon || rightComponent,
                    "t-w-full": !icon && !rightComponent,
                    "t-w-auto t-min-w-1": autoResize,
                  })}
                  {...{ ...field, ...props }}
                  onChange={onChange}
                />
                {icon && (
                  <div className="t-flex t-h-full t-items-center t-justify-center">
                    {icon}
                  </div>
                )}
                <div
                  className={classNames({
                    "t-col-start-2 t-flex t-h-full t-items-center t-justify-end":
                      autoResize,
                  })}
                >
                  {rightComponent && rightComponent}
                </div>
              </div>

              {!hideError && hasError && (
                <ErrorMessage>
                  {/* @ts-ignore */}
                  {getIn(errors, field.name)}
                </ErrorMessage>
              )}
            </>
          );
        }}
      </Field>
    </div>
  );
}
