import qs from "qs";
import { emptyApi } from "./emptyApi";
import { NexusTracker } from "types/Models/nexusTracker";
import { Pagination } from "types/Models/pagination";

export const salesNexusTracker = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllNexusTrackers: build.query<
      NexusTracker,
      {
        pageNum: number;
        searchTerm?: string | null;
        entityId: string;
      }
    >({
      query: ({ entityId, pageNum, searchTerm }) => {
        let queryUrl = qs.stringify(
          {
            page_num: pageNum,
            search_term: searchTerm,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/tax_compliance/entity/${entityId}/nexus_tracker/${queryUrl}`,
        };
      },
      providesTags: (result) => (result ? ["NEXUSTRACKER"] : []),
    }),
  }),
});

export const { useGetAllNexusTrackersQuery } = salesNexusTracker;
