import QueryString from "qs";
import { TxnCategories, UploadCOAFile } from "types/Models/books";
import { emptyApi } from "./emptyApi";
import { FileUrl } from "types/Models/fileObject";

export type HiddenCategoryTypes =
  | "BANK_TRANSFER"
  | "PAY_DOWN_CREDIT"
  | "BANK_ACCOUNT";

type COAProp = {
  groupId: string;
  entityId: string;
  hiddenCategoryTypes?: HiddenCategoryTypes[];
  root_category_id?: string;
  payload?: {
    parent_uuid?: string;
    name?: string;
    description?: string;
    identifier?: number;
    category_uuid?: string;
    start_date?: string | null;
    opening_balance?: number | null;
    category_tag?: string;
  };
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getChartOfAccounts: build.query<
      TxnCategories[],
      Pick<
        COAProp,
        "entityId" | "groupId" | "hiddenCategoryTypes" | "root_category_id"
      >
    >({
      query: ({ groupId, entityId, hiddenCategoryTypes, root_category_id }) => {
        let queryUrl = QueryString.stringify(
          {
            hidden_category_types: hiddenCategoryTypes?.join(",") || null,
            root_category_id: root_category_id || null,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/transaction_categories/${queryUrl}`,
        };
      },

      providesTags: (result) => (result ? ["CHART_OF_ACCOUNTS"] : []),
    }),

    getTransactionCategory: build.query<
      TxnCategories,
      Pick<COAProp, "groupId" | "entityId"> & { categoryId: string }
    >({
      query: ({ groupId, entityId, categoryId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/transaction_category/?category_uuid=${categoryId}`,
        };
      },

      providesTags: (result) => (result ? ["CHART_OF_ACCOUNTS"] : []),
    }),

    getAutoGenerateNextAvailableIdentifer: build.query<
      { identifier: number },
      { groupId: string; entityId: string; parentId: string; offset?: number }
    >({
      query: ({ groupId, entityId, parentId, offset }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/transaction_category/auto_generate_identifier/`,
          params: {
            parent_id: parentId,
            offset,
          },
        };
      },
    }),

    getDefaultCategoryTag: build.query<
      {
        default_tag: string;
      },
      {
        parentId: string;
        entityId: string;
      }
    >({
      query: ({ entityId, parentId }) => ({
        url: `/api/inkle/bookkeeping/entity/${entityId}/transaction_category/get_category_tag/`,
        params: {
          parent_id: parentId,
        },
      }),
    }),

    addTransactionCategory: build.mutation<TxnCategories, COAProp>({
      query: ({ groupId, entityId, payload }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/transaction_category/`,
          method: "POST",
          body: payload,
        };
      },

      invalidatesTags: (result) => (result ? ["CHART_OF_ACCOUNTS"] : []),
    }),

    updateTransactionCategory: build.mutation<TxnCategories, COAProp>({
      query: ({ groupId, entityId, payload }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/transaction_category/`,
          method: "PUT",
          body: payload,
        };
      },

      invalidatesTags: (result) => (result ? ["CHART_OF_ACCOUNTS"] : []),
    }),

    removeTransactionCategory: build.mutation<
      TxnCategories,
      {
        groupId: string;
        entityId: string;
        payload: {
          replace_category_id: string;
          category_uuid: string;
          name: string;
        };
      }
    >({
      query: ({ groupId, entityId, payload }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/transaction_category/`,
          method: "DELETE",
          body: payload,
        };
      },

      invalidatesTags: ["CHART_OF_ACCOUNTS"],
    }),

    getCategoryUsage: build.query<
      {
        products_and_services: number;
        transactions: number;
        transactions_with_second_suggested_category: number;
        transactions_with_suggested_category: number;
        fixed_assets: number;
        recurring_journal_entry_templates: number;
        rules: number;
        total: number;
      },
      {
        entityId: string;
        categoryId: string;
        groupId: string;
      }
    >({
      query: ({ entityId, categoryId, groupId }) =>
        `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/transaction_category/${categoryId}/usage/`,
    }),

    getTags: build.query<
      string[],
      { groupId: string; entityId: string; parentCategoryId: string }
    >({
      query: ({ groupId, entityId, parentCategoryId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/transaction_category/category_tags/?parent_category_id=${parentCategoryId}`,
        };
      },
    }),

    getStartingDate: build.query<
      { start_date: string },
      {
        groupId: string;
        entityId: string;
      }
    >({
      query: ({ groupId, entityId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/coa_start_date/`,
        };
      },
      providesTags: ["COA_START_DATE"],
    }),

    updateStartDate: build.mutation<
      { start_date: string },
      {
        groupId: string;
        entityId: string;
        startDate: string;
      }
    >({
      query: ({ groupId, entityId, startDate }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/coa_start_date/`,
          method: "PATCH",
          body: { start_date: startDate },
        };
      },

      invalidatesTags: ["CHART_OF_ACCOUNTS", "COA_START_DATE"],
    }),

    getExportCOA: build.query<
      FileUrl,
      {
        groupId: string;
        entityId: string;
        exportType: "PDF" | "EXCEL";
        hiddenCategory?: HiddenCategoryTypes[];
      }
    >({
      query: ({ groupId, entityId, hiddenCategory, exportType }) => {
        let queryUrl = QueryString.stringify(
          {
            hidden_category_types: hiddenCategory?.join(",") || null,
            export_type: exportType,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/transaction_categories/export/${queryUrl}`,
        };
      },
    }),

    getCategoryActions: build.query<
      {
        is_categories_movable: {
          value: boolean;
          error: {
            has_same_parent: boolean;
          };
        };
        is_categories_mergable: {
          value: boolean;
          error: {
            has_same_parent: boolean;
            is_leaf_category: boolean;
          };
        };
      },
      {
        category_ids: string[];
        entityId: string;
      }
    >({
      query: ({ category_ids, entityId }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/category_actions/`,
          params: {
            category_ids: category_ids.join(","),
          },
        };
      },
    }),

    mergeCategories: build.mutation<
      null,
      {
        entityId: string;
        payload: {
          child_category_ids: string[];
          primary_category_id: string;
        };
      }
    >({
      query: ({ entityId, payload }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/merge_categories/`,
          method: "POST",
          body: payload,
        };
      },

      invalidatesTags: (result) => (result ? ["CHART_OF_ACCOUNTS"] : []),
    }),

    moveCategories: build.mutation<
      null,
      {
        payload: {
          move_to_category_id: string;
          child_categories: {
            uuid: string;
            new_identifier: string;
          }[];
        };
        entityId: string;
      }
    >({
      query: ({ payload, entityId }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/move_categories/`,
          method: "PUT",
          body: payload,
        };
      },

      invalidatesTags: (result) => (result ? ["CHART_OF_ACCOUNTS"] : []),
    }),

    uploadCOAfile: build.mutation<
      UploadCOAFile,
      {
        groupId: string;
        entityId: string;
        csv: File;
        mark_opening_balances_zero: boolean;
      }
    >({
      query: ({ entityId, groupId, csv, mark_opening_balances_zero }) => {
        const form = new FormData();
        form.append("csv", csv);
        form.append(
          "mark_opening_balances_zero",
          mark_opening_balances_zero.toString()
        );

        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/coa_import/upload/`,
          method: "POST",
          body: form,
        };
      },
    }),

    updateBankAccountMap: build.mutation<
      UploadCOAFile,
      {
        groupId: string;
        entityId: string;
        coaImportStateId: string;
        bankAccountMap: { name: string; source_id: string }[];
      }
    >({
      query: ({ entityId, groupId, coaImportStateId, bankAccountMap }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/coa_import/${coaImportStateId}/`,
          method: "PUT",
          body: { bank_account_map: bankAccountMap },
        };
      },
    }),

    getCOAImportState: build.query<
      UploadCOAFile,
      {
        groupId: string;
        entityId: string;
        coaImportStateId: string;
      }
    >({
      query: ({ entityId, groupId, coaImportStateId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/coa_import/${coaImportStateId}/?review=true`,
        };
      },
    }),

    finalizeCOAImport: build.mutation<
      void,
      {
        groupId: string;
        entityId: string;
        coaImportStateId: string;
      }
    >({
      query: ({ entityId, groupId, coaImportStateId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/coa_import/${coaImportStateId}/`,
          method: "POST",
        };
      },
      invalidatesTags: ["CHART_OF_ACCOUNTS"],
    }),

    getCOAImportStatus: build.query<
      { status: "NOT_STARTED" | "IN_PROGRESS" | "COMPLETED" },
      {
        groupId: string;
        entityId: string;
      }
    >({
      query: ({ entityId, groupId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/validate_coa_import_status/`,
        };
      },
      providesTags: (result) => {
        return result ? ["CHART_OF_ACCOUNTS"] : [];
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetChartOfAccountsQuery,
  useGetTransactionCategoryQuery,
  useGetAutoGenerateNextAvailableIdentiferQuery,
  useLazyGetAutoGenerateNextAvailableIdentiferQuery,
  useAddTransactionCategoryMutation,
  useUpdateTransactionCategoryMutation,
  useRemoveTransactionCategoryMutation,
  useGetTagsQuery,
  useGetStartingDateQuery,
  useUpdateStartDateMutation,
  useLazyGetExportCOAQuery,
  useGetDefaultCategoryTagQuery,
  useGetCategoryActionsQuery,
  useMoveCategoriesMutation,
  useMergeCategoriesMutation,
  useUploadCOAfileMutation,
  useUpdateBankAccountMapMutation,
  useGetCOAImportStateQuery,
  useFinalizeCOAImportMutation,
  useLazyGetCategoryUsageQuery,
  useGetCategoryUsageQuery,
  useGetCOAImportStatusQuery,
} = extendedApi;
