import React from "react";

export const InfoRequestedHeader = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clip-path="url(#clip0_2182_1015)">
      <path
        d="M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845886 10.121 0.00229405 8 0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.99261 1.46144 6.6994C1.71867 5.40619 2.35361 4.21831 3.28596 3.28596C4.21831 2.35361 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76752 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667Z"
        fill="#706A85"
      />
      <path
        d="M7.99996 6.66602H7.33329C7.15648 6.66602 6.98691 6.73625 6.86189 6.86128C6.73686 6.9863 6.66663 7.15587 6.66663 7.33268C6.66663 7.50949 6.73686 7.67906 6.86189 7.80409C6.98691 7.92911 7.15648 7.99935 7.33329 7.99935H7.99996V11.9993C7.99996 12.1762 8.0702 12.3457 8.19522 12.4708C8.32025 12.5958 8.48981 12.666 8.66663 12.666C8.84344 12.666 9.01301 12.5958 9.13803 12.4708C9.26305 12.3457 9.33329 12.1762 9.33329 11.9993V7.99935C9.33329 7.64573 9.19282 7.30659 8.94277 7.05654C8.69272 6.80649 8.35358 6.66602 7.99996 6.66602Z"
        fill="#706A85"
      />
      <path
        d="M8 5.33398C8.55228 5.33398 9 4.88627 9 4.33398C9 3.7817 8.55228 3.33398 8 3.33398C7.44772 3.33398 7 3.7817 7 4.33398C7 4.88627 7.44772 5.33398 8 5.33398Z"
        fill="#706A85"
      />
    </g>
    <defs>
      <clipPath id="clip0_2182_1015">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
