import { METRICS, PERIOD, REVENUE_BASIS } from "constants/revenueMetrics";
import {
  PeriodicTotalAmount,
  RevenueMetrics,
  TableData,
} from "types/Models/revenueMetrics";
import { emptyApi } from "./emptyApi";
import { Pagination } from "types/Models/pagination";
import { ACCOUNTING_METHODS } from "constants/bookkeeping";

export const revenueMetrics = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllRevenueMetrics: build.query<
      {
        periodic_total_amounts: PeriodicTotalAmount[];
        customers: TableData[];
      } & Pagination,
      {
        groupId: string;
        entityId: string;
        revenue_basis: keyof typeof REVENUE_BASIS;
        period_type?: keyof typeof PERIOD;
        start_date: string;
        end_date: string;
        metric_type: keyof typeof METRICS;
        accounting_method?: keyof typeof ACCOUNTING_METHODS;
        page_num: number;
      }
    >({
      query: ({
        groupId,
        entityId,
        revenue_basis,
        period_type,
        start_date,
        end_date,
        metric_type,
        accounting_method,
        page_num,
      }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/revenue-metrics/all/`,
          params: {
            revenue_basis,
            period_type,
            start_date,
            end_date,
            metric_type,
            accounting_method,
            page_num,
          },
        };
      },
    }),

    getRevenueMetrics: build.query<
      RevenueMetrics[],
      {
        groupId: string;
        entityId: string;
        start_date: string;
        end_date: string;
      }
    >({
      query: ({ groupId, entityId, start_date, end_date }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/revenue-metrics/`,
          params: {
            start_date,
            end_date,
          },
        };
      },
    }),
  }),
});

export const { useGetAllRevenueMetricsQuery, useGetRevenueMetricsQuery } =
  revenueMetrics;
