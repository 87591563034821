import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Connection, ConnectionBankAccount } from "store/apis/booksConnections";
import BankCircular from "static/images/BankCircular.svg";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { Button } from "components/DesignSystem/Button/Button";
import ThreeDots from "static/images/ThreeDots.svg";
import { useModal } from "hooks/useModal";
import { useDispatch } from "react-redux";
import { openEditBankModal } from "store/slices/connections";
import { Cross } from "components/icons/Cross";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import DoubleGreenTick from "static/images/DoubleGreenTick.svg";
import { SliderAccordion } from "components/DesignSystem/SliderAccordion/SliderAccordion";
import * as RECONCILLIATION_STATUS from "constants/reconcilliationStatus";
import dayjs from "dayjs";
import ToolTip from "components/design/toolTip";
import { DisconnectBankModal } from "components/Connections/DisconnectBankModal";
import { DeleteBankModal } from "components/ComplianceTable/DeleteBankModal";
import { EditOpeningBalance } from "components/EditOpeningBalance/EditOpeningBalance";
import { PencilWithLine } from "components/icons/PencilWithLine";
import { useGetEntityBanksQuery } from "store/apis/bankConnections";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { formatDate } from "utils/formatDate";
import { ConditionalLink } from "components/conditionalLink";
import { Redirect } from "components/icons/Redirect";
import { BankStatementSlider } from "./BankStatementSlider";
import Slider from "components/DesignSystem/Slider/Slider";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

const BankAccountItem = ({
  account,
  connection,
}: {
  account: ConnectionBankAccount;
  connection: Connection;
}) => {
  const { search } = useLocation();
  const hasOpeningBalance =
    account.opening_balance || account.opening_balance === 0;

  const accountEdit = useModal();

  return (
    <>
      <SliderAccordion.Item value={account.uuid} key={account.uuid}>
        <SliderAccordion.Trigger>
          <div className="t-flex t-gap-1.5 t-items-center t-w-full">
            {account.name} {account.account_number}
            {!account.balance_matches &&
              account.last_reconcilation_status !==
                RECONCILLIATION_STATUS.COMPLETED && (
                <>
                  <ToolTip text="Reconciliation required">
                    <span className="t-border-red-10 t-border-solid t-border-[3px] t-rounded-full t-w-4 t-h-4 t-overflow-hidden">
                      <span className="t-w-4 t-h-4 t-bg-red t-block" />
                    </span>
                  </ToolTip>

                  <div className="t-ml-auto">
                    <ConditionalLink
                      target="_blank"
                      to={`/books/transactions/reconciliation/start/${account.uuid}${search}`}
                    >
                      <Button customType="ghost" size="small">
                        <span className="t-flex t-gap-1 t-items-center t-text-red">
                          <span>Reconcile</span>
                          <Redirect size={12} color="currentColor" />
                        </span>
                      </Button>
                    </ConditionalLink>
                  </div>
                </>
              )}
          </div>
        </SliderAccordion.Trigger>
        <SliderAccordion.Content>
          <div className="t-grid t-grid-cols-2 t-gap-4">
            <div className="">
              <p className="t-text-text-30 t-text-body-sm t-m-0">
                Account Balance
              </p>
              <p className="t-text-text-100 t-text-h5 t-m-0">
                <AmountSuperScript amount={account.available_balance} />
              </p>
            </div>

            <div />

            <div className="">
              <p className="t-text-text-30 t-text-body-sm t-m-0">
                Opening balance
              </p>
              <p className="t-text-text-100 t-text-subtext t-m-0 t-flex t-items-center t-gap-1">
                {hasOpeningBalance && (
                  <span>
                    <AmountSuperScript
                      amount={Number(account.opening_balance)}
                    />
                  </span>
                )}
                <Button
                  size="small"
                  customType={!hasOpeningBalance ? "ghost" : "ghost_icon"}
                  onClick={accountEdit.open}
                >
                  <span className="t-flex t-gap-1 t-items-center">
                    <span className="t-flex">
                      <PencilWithLine />
                    </span>
                    {!hasOpeningBalance && <span>Add</span>}
                  </span>
                </Button>
              </p>
            </div>

            <div className="">
              <p className="t-text-text-30 t-text-body-sm t-m-0">
                Opening balance date
              </p>
              <p className="t-text-text-100 t-text-subtext t-m-0 t-flex t-gap-1 t-items-center">
                {account.opening_balance_date && (
                  <span>{formatDate(account.opening_balance_date)}</span>
                )}
                <Button
                  size="small"
                  customType={
                    !account.opening_balance_date ? "ghost" : "ghost_icon"
                  }
                  onClick={accountEdit.open}
                >
                  <span className="t-flex t-gap-1 t-items-center">
                    <span className="t-flex">
                      <PencilWithLine />
                    </span>
                    {!account.opening_balance_date && <span>Add</span>}
                  </span>
                </Button>
              </p>
            </div>

            <Slider.Root>
              <Slider.Trigger asChild>
                <Button size="small">View Statements</Button>
              </Slider.Trigger>
              <Slider.Content>
                <BankStatementSlider account={account} />
              </Slider.Content>
            </Slider.Root>
          </div>
        </SliderAccordion.Content>
      </SliderAccordion.Item>

      {accountEdit.isOpen && (
        <EditOpeningBalance
          open={accountEdit.isOpen}
          onClose={accountEdit.close}
          bankAccount={account}
          connection={connection}
        />
      )}
    </>
  );
};

export const DataSourceView = ({
  connection,
  onClose,
}: {
  connection: Connection;
  onClose(): void;
}) => {
  const history = useHistory();
  const { search } = useLocation();
  const { url } = useRouteMatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const { isFetching } = useGetEntityBanksQuery(
    {
      groupId: groupId!,
      entityId: entityId!,
    },
    { skip: !groupId || !entityId }
  );

  const dispatch = useDispatch();

  const disconnectModalState = useModal();
  const deleteBankModalState = useModal();

  return (
    <>
      <Slider.Root onOpenChange={onClose} open={Boolean(connection)}>
        <Slider.Content>
          {connection && (
            <>
              <div className="t-flex t-flex-col">
                <div className="t-flex t-px-5 py-3 t-border-0 t-items-center t-border-b t-border-solid t-border-neutral-0 t-sticky t-top-0 t-z-10 t-bg-surface">
                  {connection?.logo_url ? (
                    <img
                      src={connection.logo_url}
                      className="t-rounded-full t-w-10 t-h-10"
                      alt=""
                    />
                  ) : (
                    <img
                      src={BankCircular}
                      className="t-rounded-full t-w-10 t-h-10"
                      alt=""
                    />
                  )}

                  <p className="t-m-0 t-ml-2">{connection?.name}</p>

                  <div className="t-flex t-ml-auto">
                    <Dropdown.Root>
                      <Dropdown.Trigger asChild>
                        <div>
                          <Button
                            customType="ghost_icon"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <img src={ThreeDots} alt="" />
                          </Button>
                        </div>
                      </Dropdown.Trigger>
                      <Dropdown.Content align="end">
                        {/* <Dropdown.Item>Re-sync</Dropdown.Item> */}
                        {connection.connection_type === "MANUAL" && (
                          <>
                            <Dropdown.Item
                              onSelect={() =>
                                dispatch(
                                  openEditBankModal({
                                    editBankId: connection.uuid,
                                  })
                                )
                              }
                              onClick={(e) => e.stopPropagation()}
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => e.stopPropagation()}
                              onSelect={deleteBankModalState.open}
                            >
                              <p className="t-m-0 t-text-red">Delete</p>
                            </Dropdown.Item>
                          </>
                        )}
                        {(connection.connection_type === "PLAID" ||
                          connection.connection_type === "DIRECT") && (
                          <>
                            {connection.is_connection_available && (
                              <Dropdown.Item
                                onSelect={disconnectModalState.open}
                                onClick={(e) => e.stopPropagation()}
                              >
                                <p className="t-m-0">Disconnect</p>
                              </Dropdown.Item>
                            )}
                            <Dropdown.Item
                              onClick={(e) => e.stopPropagation()}
                              onSelect={deleteBankModalState.open}
                            >
                              <p className="t-m-0 t-text-red">Delete</p>
                            </Dropdown.Item>
                          </>
                        )}
                      </Dropdown.Content>
                    </Dropdown.Root>
                    <Button
                      customType="ghost_icon"
                      onClick={onClose}
                      isLoading={isFetching}
                      disabled={isFetching}
                    >
                      <Cross color="currentColor" />
                    </Button>
                  </div>
                </div>

                <div className="t-p-5 t-flex t-flex-col t-gap-4">
                  <div className="t-flex t-flex-col t-gap-1">
                    <span className="t-text-text-30 t-text-body-sm">
                      Total Balance
                    </span>
                    <span className="t-text-h5 t-text-text-100">
                      <AmountSuperScript
                        amount={
                          connection?.bank_accounts?.reduce(
                            (acc, account) => acc + account.available_balance,
                            0
                          ) || 0
                        }
                      />
                    </span>
                    {connection?.last_successful_transaction_update && (
                      <span className="t-flex t-gap-1">
                        <span className="t-flex">
                          <img src={DoubleGreenTick} alt="Connected" />
                        </span>
                        <span className="t-text-text-30 t-text-body-sm">
                          Updated{" "}
                          {dayjs(
                            connection?.last_successful_transaction_update
                          ).fromNow()}
                        </span>
                      </span>
                    )}
                  </div>

                  <SliderAccordion.Root
                    className="t-flex t-flex-col t-gap-4"
                    type="multiple"
                  >
                    {connection?.bank_accounts?.map((account) => {
                      return (
                        <BankAccountItem
                          key={account.uuid}
                          account={account}
                          connection={connection}
                        />
                      );
                    })}
                  </SliderAccordion.Root>
                </div>
              </div>
            </>
          )}
          <DisconnectBankModal
            show={disconnectModalState.isOpen}
            onClose={() => {
              disconnectModalState.close();
              history.push(`${url}${search}`);
            }}
            connection={connection}
          />
          <DeleteBankModal
            isOpen={deleteBankModalState.isOpen}
            close={deleteBankModalState.close}
            connection={connection}
          />
        </Slider.Content>
      </Slider.Root>
    </>
  );
};
