import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import { KeyDownWrapper } from "components/DesignSystem/KeydownWrapper/KeydownWrapper";
import { NoteCard } from "components/DesignSystem/Notes/NoteCard";
import { SliderAccordion } from "components/DesignSystem/SliderAccordion/SliderAccordion";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { OutlinedPaperPlaneRight } from "components/icons/OutlinedPaperPlaneRight";
import { Form, Formik, FormikValues } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useToast } from "hooks/useToast";
import { useAddFinancialClosingCommentsMutation } from "store/apis/financialClosing";
import { Comment as CommentType } from "types/Models/books";
import { BackendError } from "types/utils/error";

export const Comments = ({
  comments,
  financialClosingId,
}: {
  comments: CommentType[];
  financialClosingId: string;
}) => {
  const [addFinancialClosingComments, { isLoading }] =
    useAddFinancialClosingCommentsMutation();
  const { alertToast } = useToast();
  const entityId = useCurrentEntityId();

  const onSubmit = async (
    values: { description: string },
    { resetForm }: FormikValues
  ) => {
    try {
      await addFinancialClosingComments({
        entityId,
        financialClosingId,
        description: values.description,
      }).unwrap();
      resetForm();
    } catch (error) {
      alertToast({
        message: (error as BackendError).data?.error?.message,
      });
    }
  };

  return (
    <SliderAccordion.Root defaultValue="COMMENT" collapsible type="single">
      <SliderAccordion.Item value="COMMENT">
        <SliderAccordion.Trigger>Comments</SliderAccordion.Trigger>
        <SliderAccordion.Content>
          <span>
            <KeyDownWrapper>
              <div className="t-overflow-auto t-flex t-flex-col t-gap-2 t-max-h-80">
                <div className="t-flex t-flex-col t-gap-2">
                  {comments.map((note) => (
                    <NoteCard note={note} key={note.uuid} />
                  ))}
                </div>
                <Formik initialValues={{ description: "" }} onSubmit={onSubmit}>
                  <Form
                    className={classNames("t-w-full t-m-0 t-sticky t-bottom-0")}
                  >
                    <TextInput
                      placeholder="Add your comment here"
                      name="description"
                      customSize="large"
                      rightComponent={
                        <Button
                          customType="ghost_icon"
                          isLoading={isLoading}
                          disabled={isLoading}
                        >
                          <span className="t-text-text-30">
                            <OutlinedPaperPlaneRight size="20" />
                          </span>
                        </Button>
                      }
                    />
                  </Form>
                </Formik>
              </div>
            </KeyDownWrapper>
          </span>
        </SliderAccordion.Content>
      </SliderAccordion.Item>
    </SliderAccordion.Root>
  );
};
