import { Form, Formik } from "formik";
import { emailSchema } from "../formValidations/emailSchema";
import { MERCHANT_FORM_STATUS_TYPES } from "types/Models/merchant";
import { TextInput } from "./DesignSystem/TextInput/TextInput";
import { Button } from "./DesignSystem/Button/Button";

type WSeriesEmailFormProps = {
  onSubmit: (values: { email: string }) => void;
  email: string;
  formStatus: MERCHANT_FORM_STATUS_TYPES;
  label: string;
  disabled?: boolean;
};

export const WSeriesEmailForm = ({
  onSubmit,
  email,
  formStatus,
  label,
  disabled = false,
}: WSeriesEmailFormProps) => {
  return (
    <Formik
      validateOnChange
      validationSchema={emailSchema}
      onSubmit={onSubmit}
      initialValues={{
        email: email || "",
      }}
    >
      {({ isSubmitting, isValid }) => (
        <Form className="t-w-full">
          <div className="gap-4 t-flex t-w-full t-items-center t-gap-4">
            <TextInput
              label={label}
              block
              placeholder="Enter email"
              name="email"
              required
              customSize="regular"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
