import { BlockedButton } from "components/BlockedButton/BlockedButton";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { ArrowRight } from "components/icons/ArrowRight";
import { PermissionBasedUI } from "components/PermissionBasedUI/PermissionBasedUI";
import { PlaidToDirectMigration } from "components/PlaidToDirectMigration/PlaidToDirectMigration";
import {
  CONNECT_BANK_ACCOUNT,
  CONNECT_REVENUE_SOURCE,
} from "constants/subscriptionPermissionFeatures";
import { onConnectArgs } from "hooks/useBankConnect";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { AccountCard } from "pages/Books/DataSources/DataSourcesList";
import { ComponentProps, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import PlaidMultipleBankLogoCircle from "static/images/PlaidMultipleBankLogoCircle.svg";
import {
  Connection,
  useGetAllConnectionsQuery,
} from "store/apis/booksConnections";
import { OtherBankingInstitution } from "./OtherBankingInstitution";

export type ConnectionsModalProps = {
  onConnect: (args: onConnectArgs) => void;
  close: () => void;
  entityIdFromParent?: string;
  showAddManualBank?: boolean;
  isLoading?: boolean;
  loadingConnectionId?: string | null;
};

export type BankingConnectionsProps = {
  connection: Connection;
  onConnect: (args: onConnectArgs) => void;
  onPlaidToDirectMigration: (connectionId: string) => void;
};

export const RevenueConnections = ({
  connection,
  onConnect,
}: {
  connection: Connection;
} & Pick<ComponentProps<typeof ConnectionsModal>, "onConnect">) => {
  const { isCpa } = useRoleBasedView();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  return (
    <AccountCard
      key={connection.uuid}
      logo={
        <img
          className="t-w-8 t-h-8 t-rounded-full"
          src={connection.logo_url}
          alt=""
        />
      }
      title={connection.name}
      description="Direct API integration for fetching revenue data"
      CTA={
        <PermissionBasedUI
          errorMessage="You need to upgrade to Plus Plan to connect revenue data sources."
          feature={CONNECT_REVENUE_SOURCE}
          blockedUI={<BlockedButton size="small">Connect</BlockedButton>}
        >
          <Button
            size="small"
            onClick={() =>
              onConnect({
                connectionId: connection.uuid,
                invokedFrom: isCpa
                  ? `/books/data-sources?company=${groupId}&entity=${entityId}`
                  : `/books/data-sources?entity=${entityId}`,
              })
            }
          >
            Connect <ArrowRight color="currentColor" />
          </Button>
        </PermissionBasedUI>
      }
    />
  );
};

export const BankingConnections = ({
  connection,
  onConnect,
  isLoading,
  onPlaidToDirectMigration,
}: BankingConnectionsProps &
  Pick<ComponentProps<typeof ConnectionsModal>, "onConnect" | "isLoading">) => {
  const { isCpa } = useRoleBasedView();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const onConnectClick = (connectionId: string) => {
    if (connection?.is_plaid_account_connected) {
      onPlaidToDirectMigration(connectionId);

      return;
    }

    if (connection) {
      onConnect({
        connectionId: connection.uuid,
        invokedFrom: isCpa
          ? `/books/data-sources?company=${groupId}&entity=${entityId}`
          : `/books/data-sources?entity=${entityId}`,
      });
    }
  };

  return (
    <AccountCard
      key={connection.uuid}
      logo={
        <img
          className="t-w-8 t-h-8 t-rounded-full"
          src={connection.logo_url}
          alt=""
        />
      }
      title={connection.name}
      description="Direct API integration for fetching transactions data"
      CTA={
        <>
          {!connection.is_connection_available ? (
            <Button
              size="small"
              isLoading={isLoading}
              onClick={() => onConnectClick(connection.uuid)}
            >
              Connect <ArrowRight color="currentColor" />
            </Button>
          ) : (
            <Tag tagType="green">
              <span className="t-text-body-sm">Connected</span>
            </Tag>
          )}
        </>
      }
    />
  );
};

export const ConnectionsModal = ({
  onConnect,
  close,
  entityIdFromParent,
  showAddManualBank = true,
  onComplete,
  isLoading,
  loadingConnectionId,
  onManualBankAddSuccess,
}: ConnectionsModalProps &
  Pick<
    ComponentProps<typeof OtherBankingInstitution>,
    "onManualBankAddSuccess" | "onComplete"
  >) => {
  const { uuid: groupId, entities } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const entity = entities.find((entity) => entity.uuid === entityId);
  const { data: connections } = useGetAllConnectionsQuery(
    {
      groupId: groupId!,
      entityId: entityId! || entityIdFromParent!,
    },
    { skip: !groupId || (!entityId && !entityIdFromParent) }
  );
  const [currentConnectionId, setCurrentConnectionId] = useState<string | null>(
    null
  );

  const plaidToDirectMigrationStrategyModal = useModal();
  const otherBankingInstitutionModal = useModal();

  const onPlaidToDirectMigration = (connectionId: string) => {
    plaidToDirectMigrationStrategyModal.open();
    setCurrentConnectionId(connectionId);
  };

  const revenueConnections = connections?.filter(
    ({ connection_provider }) => connection_provider === "STRIPE"
  );
  const bankingConnections = connections?.filter(
    ({ connection_provider }) => connection_provider !== "STRIPE"
  );

  return (
    <>
      <div>
        <div className="t-flex t-gap-2.5 t-mb-4 t-text-body t-text-text-100">
          Selecting for:
          {entity && (
            <span className="t-flex t-items-center t-gap-1.5 t-text-text-30 t-text-subtext-sm">
              <ReactCountryFlag
                className="!t-w-5 !t-h-3 t-rounded-sm t-object-cover"
                countryCode={entity.country_code}
                svg
                title={entity.country}
              />
              {entity.name}
            </span>
          )}
        </div>
        <div className="t-flex t-flex-col t-gap-3">
          {bankingConnections?.map((connection) => (
            <BankingConnections
              key={connection.uuid}
              connection={connection}
              onConnect={onConnect}
              onPlaidToDirectMigration={onPlaidToDirectMigration}
            />
          ))}
          {revenueConnections?.map((connection) => (
            <RevenueConnections
              key={connection.uuid}
              connection={connection}
              onConnect={onConnect}
            />
          ))}
          <AccountCard
            logo={
              <img
                src={PlaidMultipleBankLogoCircle}
                className="t-rounded-full t-w-6 t-h-6"
                alt=""
              />
            }
            title="Other banking institutions"
            description="Connect other banks securely via Plaid or add manually"
            CTA={
              groupId && (
                <PermissionBasedUI
                  errorMessage="You need to upgrade to Plus Plan to connect revenue data sources."
                  feature={CONNECT_BANK_ACCOUNT}
                  blockedUI={
                    <BlockedButton size="small">Connect</BlockedButton>
                  }
                >
                  <Modal.Root
                    open={otherBankingInstitutionModal.isOpen}
                    onOpenChange={otherBankingInstitutionModal.toggle}
                  >
                    <Modal.Trigger asChild>
                      <Button size="small">
                        Search <ArrowRight color="currentColor" />
                      </Button>
                    </Modal.Trigger>
                    <Modal.Content size="large">
                      <Modal.Header>
                        <Modal.Title>Other Banking Institution</Modal.Title>
                        <Modal.Close tabIndex={-1} />
                      </Modal.Header>
                      <OtherBankingInstitution
                        onConnect={onConnect}
                        onPlaidToDirectMigration={onPlaidToDirectMigration}
                        onComplete={(args) => {
                          onComplete?.(args);
                          otherBankingInstitutionModal.close();
                        }}
                        onManualBankAddSuccess={(args) => {
                          onManualBankAddSuccess?.(args);
                          otherBankingInstitutionModal.close();
                        }}
                      />
                    </Modal.Content>
                  </Modal.Root>
                </PermissionBasedUI>
              )
            }
          />
        </div>
      </div>

      <Modal.Root
        open={plaidToDirectMigrationStrategyModal.isOpen}
        onOpenChange={plaidToDirectMigrationStrategyModal.close}
      >
        <Modal.Content>
          {currentConnectionId && (
            <PlaidToDirectMigration
              connectionId={currentConnectionId}
              onClose={plaidToDirectMigrationStrategyModal.close}
              entityId={entityIdFromParent || entityId}
            />
          )}
        </Modal.Content>
      </Modal.Root>
    </>
  );
};
