import { ChatPage } from "pages/Chat";

import Loader from "components/design/loader";
import Documents from "components/documents/documents";

import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { RestrictedRoute } from "RestrictedRoute";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { PageNotFound } from "components/design/PageNotFound";
import React, { Suspense } from "react";
import { Ticket } from "components/Ticket/Tickets";
import { Tickets as CustomerTickets } from "components/Ticket/CustomerTicket/Tickets";

export const RoutesAccrossApps = () => {
  const { path } = useRouteMatch();
  const { isCustomer, isCpa } = useRoleBasedView();
  const group = useCurrentGroupContext();
  const unsortedFolderId = group.unsorted_folder_id;
  const inkleDocId = group.documents_group_id;
  const { search } = useLocation();

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <RestrictedRoute exact path={`${path}/chat/tickets`}>
          <Redirect
            to={isCustomer ? `${path}/action-items` : `${path}/tickets`}
          />
        </RestrictedRoute>
        {isCustomer && (
          <RestrictedRoute exact path={`${path}/tickets`}>
            <Redirect to={`${path}/action-items`} />
          </RestrictedRoute>
        )}
        <RestrictedRoute
          path={`${path}/tickets`}
          render={() => {
            if (isCpa) {
              return <Ticket />;
            }

            return null;
          }}
        />
        <RestrictedRoute path={`${path}/action-items`}>
          <CustomerTickets />
        </RestrictedRoute>

        <RestrictedRoute exact path={`${path}/documents/unsorted`}>
          {!unsortedFolderId ? (
            <div className="t-w-full">
              <Loader />
            </div>
          ) : (
            <div>
              <Redirect to={`${path}/documents/${unsortedFolderId}${search}`} />
            </div>
          )}
        </RestrictedRoute>
        <RestrictedRoute path={`${path}/documents/:docId`}>
          <Documents appUrl={path} />
        </RestrictedRoute>
        <RestrictedRoute exact path={`${path}/documents`}>
          {!inkleDocId ? (
            <div className="t-w-full">
              <Loader />
            </div>
          ) : (
            <div>
              <Redirect to={`${path}/documents/${inkleDocId}${search}`} />
            </div>
          )}
        </RestrictedRoute>
        <RestrictedRoute path={`${path}/chat`} component={ChatPage} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </Suspense>
  );
};
