export const Redirect = ({ color = "#5F2EE5", size = 16 }) => {
  return (
    <>
      <span className="t-sr-only">Redirect</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M5.90909 12.1818V8.09091M5.90909 8.09091H1.81818M5.90909 8.09091L1 13M9.18182 11.3636H12.4545C12.5992 11.3636 12.7379 11.3062 12.8402 11.2039C12.9425 11.1016 13 10.9628 13 10.8182V1.54545C13 1.40079 12.9425 1.26205 12.8402 1.15976C12.7379 1.05747 12.5992 1 12.4545 1H3.18182C3.03715 1 2.89842 1.05747 2.79612 1.15976C2.69383 1.26205 2.63636 1.40079 2.63636 1.54545V4.81818"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
