import { Button } from "components/DesignSystem/Button/Button";
import ChatCard from "components/DesignSystem/ChatCard/v2/ChatCard";
import { CLOSING_STATUS } from "constants/financialClosing";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { Period } from "pages/Books/FinancialClosing/FinancialClosing";
import { ReviewFinancialsModal } from "pages/Books/FinancialClosing/ReviewFinancialsModal";
import { FinancialClosingDetail } from "store/apis/financialClosing";
import GreenCheck from "static/images/GreenCheck.svg";
import React from "react";
import { useHistory } from "react-router-dom";
import OpenItemsHeader from "static/images/OpenItemsHeader.svg";
import TinySuccess from "static/images/TinySuccess.svg";

type RaiseClarificationCardProps = {
  customData: {
    action: string;
    card_type: string;
    end_date: string;
    entity_id: string;
    financial_closing_id: string;
    group_id: string;
    start_date: string;
    status: FinancialClosingDetail["status"];
  };
  children?: React.ReactNode;
};

const Action = ({
  status,
  itemId,
  entityId,
  groupId,
}: {
  status: FinancialClosingDetail["status"];
  itemId: string;
  groupId: string;
  entityId: string;
}) => {
  const disabled = status === CLOSING_STATUS.COMPLETED;
  const reviewModal = useModal();
  const { isCustomer } = useRoleBasedView();
  const history = useHistory();

  const handleViewDetails = () => {
    history.push(
      `/books/financial-closing/${itemId}?company=${groupId}&entity=${entityId}`
    );
  };

  if (status === CLOSING_STATUS.COMPLETED) {
    return (
      <div className="t-flex t-gap-1 t-text-body-sm t-text-text-30 t-items-center">
        <img src={GreenCheck} alt="Reviewed" className="t-w-4 t-h-4" />
        Reviewed
      </div>
    );
  }

  if (!isCustomer) {
    return (
      <Button
        block
        customType="primary"
        size="small"
        onClick={handleViewDetails}
      >
        View Details
      </Button>
    );
  }

  if (status === CLOSING_STATUS.REVIEW_PENDING && isCustomer) {
    return (
      <>
        <Button
          block
          customType="primary"
          size="small"
          disabled={disabled}
          onClick={reviewModal.open}
        >
          Review
        </Button>
        <ReviewFinancialsModal
          isOpen={reviewModal.isOpen}
          close={reviewModal.close}
          financialClosingId={itemId}
        />
      </>
    );
  }

  return null;
};

export const ReviewClosingCard = ({
  customData,
  children,
}: RaiseClarificationCardProps) => {
  const isCompleted = customData.status === CLOSING_STATUS.COMPLETED;
  return (
    <>
      <div className="t-min-w-64 t-mb-2">
        <ChatCard.Content addMargin={false}>
          <ChatCard.Header
            icon={
              isCompleted ? (
                <img src={TinySuccess} alt={TinySuccess} />
              ) : (
                <img src={OpenItemsHeader} alt={OpenItemsHeader} />
              )
            }
          >
            <div className="t-text-subtitle-sm t-text-text-30">
              {isCompleted ? "Financials Reviewed" : customData.action}
            </div>
          </ChatCard.Header>
          <ChatCard.Body>
            <div className="t-space-y-4">
              <div className="t-text-subtext t-text-text-60">
                <Period
                  start_date={customData?.start_date}
                  end_date={customData?.end_date}
                />
              </div>
              <div className="t-text-subtext t-text-text-60">{children}</div>
              <Action
                itemId={customData.financial_closing_id}
                status={customData.status}
                entityId={customData.entity_id}
                groupId={customData.group_id}
              />
            </div>
          </ChatCard.Body>
        </ChatCard.Content>
      </div>
    </>
  );
};
