export const MultipleFiles = ({ size = "14px" }: { size?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M11.6845 3.62797L9.49703 1.44047C9.45637 1.39985 9.40811 1.36765 9.355 1.34569C9.30189 1.32373 9.24497 1.31245 9.1875 1.3125H4.8125C4.58044 1.3125 4.35788 1.40469 4.19378 1.56878C4.02969 1.73288 3.9375 1.95544 3.9375 2.1875V3.0625H3.0625C2.83044 3.0625 2.60788 3.15469 2.44378 3.31878C2.27969 3.48288 2.1875 3.70544 2.1875 3.9375V11.8125C2.1875 12.0446 2.27969 12.2671 2.44378 12.4312C2.60788 12.5953 2.83044 12.6875 3.0625 12.6875H9.1875C9.41956 12.6875 9.64212 12.5953 9.80622 12.4312C9.97031 12.2671 10.0625 12.0446 10.0625 11.8125V10.9375H10.9375C11.1696 10.9375 11.3921 10.8453 11.5562 10.6812C11.7203 10.5171 11.8125 10.2946 11.8125 10.0625V3.9375C11.8125 3.88003 11.8013 3.82311 11.7793 3.77C11.7574 3.71689 11.7251 3.66863 11.6845 3.62797ZM9.1875 11.8125H3.0625V3.9375H7.25648L9.1875 5.86852V10.4912C9.1875 10.4945 9.1875 10.4973 9.1875 10.5C9.1875 10.5027 9.1875 10.5055 9.1875 10.5087V11.8125ZM10.9375 10.0625H10.0625V5.6875C10.0625 5.63003 10.0513 5.57311 10.0293 5.52C10.0074 5.46689 9.97515 5.41863 9.93453 5.37797L7.74703 3.19047C7.70637 3.14985 7.65811 3.11765 7.605 3.09569C7.55189 3.07373 7.49497 3.06246 7.4375 3.0625H4.8125V2.1875H9.00648L10.9375 4.11852V10.0625ZM7.875 8.3125C7.875 8.42853 7.82891 8.53981 7.74686 8.62186C7.66481 8.70391 7.55353 8.75 7.4375 8.75H4.8125C4.69647 8.75 4.58519 8.70391 4.50314 8.62186C4.42109 8.53981 4.375 8.42853 4.375 8.3125C4.375 8.19647 4.42109 8.08519 4.50314 8.00314C4.58519 7.92109 4.69647 7.875 4.8125 7.875H7.4375C7.55353 7.875 7.66481 7.92109 7.74686 8.00314C7.82891 8.08519 7.875 8.19647 7.875 8.3125ZM7.875 10.0625C7.875 10.1785 7.82891 10.2898 7.74686 10.3719C7.66481 10.4539 7.55353 10.5 7.4375 10.5H4.8125C4.69647 10.5 4.58519 10.4539 4.50314 10.3719C4.42109 10.2898 4.375 10.1785 4.375 10.0625C4.375 9.94647 4.42109 9.83519 4.50314 9.75314C4.58519 9.67109 4.69647 9.625 4.8125 9.625H7.4375C7.55353 9.625 7.66481 9.67109 7.74686 9.75314C7.82891 9.83519 7.875 9.94647 7.875 10.0625Z"
        fill="currentColor"
      />
    </svg>
  );
};
