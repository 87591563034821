import DashboardContainer from "components/dashboard/DashboardContainer";
import { DashboardLayout } from "components/DashboardLayout";
import { Button } from "components/DesignSystem/Button/Button";
import { Search } from "components/DesignSystem/Search/Search";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useDispatch, useSelector } from "react-redux";
import ArrowsClockwise from "static/images/ArrowsClockwise.svg";
import { useLazyGetVendorsQuery, vendorsApi } from "store/apis/vendors";
import { openAddVendorModal } from "store/slices/vendors";
import { debounce } from "utils/debouncing";
import { Header } from "../../../components/DesignSystem/Header/Header";
import { MerchantsPanel } from "../../../components/Vendors/merchantsPanel";
import { getVendorsFilterStatus } from "store/selector/vendorsFilterStatus";
import { Wform } from "components/icons/Wform";
import { ConditionalLink } from "components/conditionalLink";
import { Icon1099 } from "components/icons/Icon1099";

export const Vendors = () => {
  usePageTitle("Vendors");
  const { uuid: groupId } = useCurrentGroupContext();
  const dispatch = useDispatch();
  const query = useQuery();

  const { filters } = useSelector(getVendorsFilterStatus);
  const selectedSeason = filters.season?.[0]?.value;

  const page = Number(query.get("page")) || 1;
  const wForm = query.get("wForm");
  const searchTerm = query.get("search_term");
  const { updateMultiple } = useUpdateQuery();
  const { alertToast, successToast } = useToast();
  const searchQuery = query.get("search_term");
  let entityId = useCurrentEntityId();
  const [getVendors] = useLazyGetVendorsQuery();
  const { isCustomer } = useRoleBasedView();

  const handleChange = debounce((e) => {
    const { value } = e.target;
    if (value) {
      updateMultiple([
        { query: "search_term", value },
        { query: "page", value: 1 },
      ]);
    } else {
      updateMultiple([
        { query: "search_term", value: null },
        { query: "page", value: 1 },
      ]);
    }
  }, 200);

  const refreshMerchants = async () => {
    try {
      let vendorType = null;
      if (wForm === "wFormNotRequired") {
        vendorType = "NOT_REQUIRED";
      }
      if (wForm === "wFormRequired") {
        vendorType = "VENDORS";
      }

      const queries = {
        groupId: groupId,
        search_term: searchTerm || null,
        type: vendorType,
        page_num: page,
        season: selectedSeason,
        entityId,
        refresh_merchants: true,
      };
      const data = await getVendors(queries).unwrap();
      successToast({
        message: "Vendors refreshed successfully",
      });

      dispatch(
        // @ts-ignore
        vendorsApi.util.updateQueryData(
          "getVendors",
          { ...queries, refresh_merchants: false },
          (draft) => {
            return data;
          }
        )
      );
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  return (
    <div className="t-flex t-flex-col t-gap-5 t-relative">
      <div className="t-rounded-lg t-p-4 t-w-full t-shadow-[0px_1px_8px_-1px_rgba(31,12,92,0.04)] t-border-solid t-border-neutral-0 t-border">
        <div className="t-flex t-gap-6">
          <div className="t-flex t-gap-4 t-items-center t-flex-1">
            <div className="t-shrink-0">
              <Wform />
            </div>
            <p className="t-m-0 t-text-body-sm t-text-text-30">
              A tax document used in the U.S. for reporting income earned by
              U.S. residents or foreign entities.{" "}
              <ConditionalLink
                to="https://www.inkle.io/blog/irs-form-1099#step-by-step-instructions-for-completing-form-w-9"
                className="t-text-text-30 t-font-bold !t-underline"
              >
                Know more
              </ConditionalLink>
            </p>
          </div>

          <div className="t-border-0 t-border-solid t-border-l t-border-neutral-0" />

          <div className="t-flex t-gap-4 t-items-center t-flex-1">
            <div className="t-shrink-0">
              <Icon1099 />
            </div>
            <p className="t-m-0 t-text-body-sm t-text-text-30">
              Form 1099 is an essential tax document that reports non-employment
              income to the Internal Revenue Service (IRS).{" "}
              <ConditionalLink
                to="https://www.inkle.io/blog/irs-form-1099"
                className="t-text-text-30 t-font-bold !t-underline"
              >
                Know more
              </ConditionalLink>
            </p>
          </div>
        </div>
      </div>
      <div className="t-bg-surface t-flex t-gap-4 t-w-full t-items-center t-sticky t-top-0 t-z-header">
        <div className="t-w-1/2">
          <Search
            placeholder="Search..."
            defaultValue={searchQuery || ""}
            onChange={handleChange}
            block
          />
        </div>

        <div className="t-flex t-gap-2 t-ml-auto">
          <Button size="small" onClick={refreshMerchants}>
            <img
              src={ArrowsClockwise}
              alt="ArrowsClockwise"
              className="t-mr-1"
            />
            Refresh
          </Button>

          <Button
            size="small"
            customType="primary"
            onClick={() => dispatch(openAddVendorModal())}
          >
            <span className="t-flex t-gap-2">Add Vendors</span>
          </Button>
        </div>
      </div>
      <MerchantsPanel />
    </div>
  );
};
