import React, { useMemo } from "react";
import { useGetAllNexusTrackersQuery } from "store/apis/salesNexusTracker";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { StateData } from "types/Models/nexusTracker";
import { TableUI } from "components/design/TableUI";
import DashboardContainer from "components/dashboard/DashboardContainer";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { DashboardLayout } from "components/DashboardLayout";
import { Header } from "components/DesignSystem/Header/Header";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { CheckCircleFilled } from "components/icons/CheckCircleFilled";
import { SolidCheck } from "components/icons/SolidCheck";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import ProgressBar from "components/DesignSystem/ProgressBar/ProgressBar";
import { InfoSolid } from "components/InfoSolid";
import { Warning } from "components/icons/Warning";
import { CircleWarning } from "components/icons/CircleWarning";
import { EmptySalesTax } from "components/Illustrations/EmptySalesTax";
import { Button } from "components/DesignSystem/Button/Button";
import { ConditionalLink } from "components/conditionalLink";
import { useGetAllConnections } from "hooks/useGetAllConnections";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { EntitySelector } from "components/EntitySelector/EntitySelector";
import { useRoleBasedView } from "hooks/useRoleBasedView";

const columnHelper = createColumnHelper<StateData>();

const statusMapping = {
  LOW: "Low",
  MEDIUM: "Approaching",
  HIGH: "Exceeded",
};

const statusColorMapping = {
  LOW: "gray" as const,
  MEDIUM: "yellow" as const,
  HIGH: "red" as const,
};

export const SalesNexusTracker = () => {
  const entityId = useCurrentEntityId();
  const group = useCurrentGroupContext();
  const { isAdmin } = useRoleBasedView();
  const {
    data: nexusTrackerData,
    isLoading,
    isSuccess,
  } = useGetAllNexusTrackersQuery({
    pageNum: 1,
    entityId,
  });

  const {
    stripeConnection,
    isLoading: connectionsLoading,
    isSuccess: connectionsLoaded,
  } = useGetAllConnections({
    groupId: group.uuid!,
    entityId: entityId!,
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor("jurisdiction", {
        header: "Jurisdiction",
        cell: (info) => info.getValue(),
        size: 25,
      }),
      columnHelper.accessor("transaction_count", {
        header: () => <div className="t-text-right">Sales & Txns</div>,
        cell: (info) => (
          <div className="t-flex t-flex-col t-text-right">
            <AmountSuperScript amount={info.row.original.transactions_amount} />
            <span className="t-text-text-30 t-text-overline">
              {info.getValue()} Txns.
            </span>
          </div>
        ),
        size: 15,
      }),
      columnHelper.accessor("transaction_count_limit", {
        header: () => <div className="t-text-right">Limit</div>,
        cell: (info) => (
          <div className="t-flex t-flex-col t-text-right">
            {info.row.original.transaction_amount_limit ? (
              <AmountSuperScript
                amount={info.row.original.transaction_amount_limit}
              />
            ) : (
              "-"
            )}

            {info.getValue() ? (
              <span className="t-text-text-30 t-text-overline">
                {info.getValue()} Txns.
              </span>
            ) : (
              "-"
            )}
          </div>
        ),
        size: 15,
      }),
      columnHelper.accessor("threshold_percentage", {
        header: "Threshold",
        cell: (info) => (
          <div className="t-flex t-flex-col t-gap-1.5">
            <span className="t-text-text-30 t-text-overline t-capitalize">
              {info.row.original.threshold_percentage_by.toLowerCase()}
            </span>
            <div className="t-flex t-gap-2 t-items-center">
              <div className="t-flex-1">
                <ProgressBar.Root>
                  <ProgressBar.Indicator progress={info.getValue()} />
                </ProgressBar.Root>
              </div>
              <span className="t-text-body-sm t-text-neutral-40">
                {info.getValue()}%
              </span>
            </div>
          </div>
        ),
        size: 35,
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (info) => (
          <Tag icon={false} tagType={statusColorMapping[info.getValue()]}>
            {statusMapping[info.getValue()]}
          </Tag>
        ),
        size: 10,
      }),
    ],
    []
  );

  const table = useReactTable({
    data: nexusTrackerData?.states_data!,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <DashboardLayout
      header={
        <Header
          v2
          title="Nexus Tracker"
          right={isAdmin && <EntitySelector />}
        />
      }
    >
      <Async.Root
        isLoading={isLoading}
        isEmpty={nexusTrackerData?.states_data.length === 0}
        isSuccess={isSuccess}
      >
        <Async.Empty>
          <div className="t-h-full t-flex t-flex-col t-items-center t-justify-center">
            <div>
              <EmptySalesTax />
            </div>
            {stripeConnection?.is_connection_available ? (
              <>
                <p className="t-text-text-100 t-text-body-sm t-mb-0 t-mt-6">
                  No sales found in any US jurisdiction.
                </p>
                <p className="t-text-text-30 t-text-body-sm t-mb-0 t-mt-1">
                  We'll automatically start tracking as sales get recorded.
                </p>
              </>
            ) : (
              <>
                <p className="t-mt-6 t-mb-0 t-text-body-sm t-text-text-30">
                  Connect Stripe to enable tracking.
                </p>
                <ConditionalLink
                  to={`/books/data-sources?entityId=${entityId}`}
                  className="t-mt-4"
                >
                  <Button customType="primary" size="small">
                    Connect
                  </Button>
                </ConditionalLink>
              </>
            )}
          </div>
        </Async.Empty>
        <Async.Success>
          <DashboardContainer>
            <DashboardContainer.Header>
              <div className="t-grid t-gap-5 t-auto-cols-auto t-grid-flow-col">
                <div className="t-h-[132px] t-bg-[url('static/images/UnitedStatesMap.svg')] t-bg-no-repeat t-bg-right-bottom t-px-4 t-py-5 t-flex t-flex-col t-justify-between t-border t-border-solid t-border-neutral-0 t-rounded-lg t-shadow-light-30 t-group">
                  <div className="t-flex t-flex-col t-gap-1.5">
                    <p className="t-text-subtext t-text-text-60 t-m-0">
                      Jurisdictions Tracked
                    </p>
                    <p className="t-text-body-sm t-text-text-30 t-m-0">
                      Monitor all jurisdictions where your business operates.
                    </p>
                  </div>
                  <div className="t-flex t-gap-1.5 t-items-center">
                    <Tag icon={false} tagType="green">
                      <span className="t-text-green-70 t-flex t-items-center t-gap-1.5">
                        <SolidCheck color="currentColor" />{" "}
                        <span className="t-text-text-60 t-text-syyubtext t-gap-1.5">
                          {nexusTrackerData?.tracked_jurisdictions_count}
                        </span>
                      </span>
                    </Tag>
                  </div>
                </div>

                {Boolean(nexusTrackerData?.exceeding_jurisdictions_count) &&
                  nexusTrackerData?.exceeding_jurisdictions_count &&
                  nexusTrackerData?.exceeding_jurisdictions_count > 0 && (
                    <div className="t-h-[132px] t-bg-[url('static/images/UnitedStatesMap.svg')] t-bg-no-repeat t-bg-right-bottom t-px-4 t-py-5 t-flex t-flex-col t-justify-between t-border t-border-solid t-border-neutral-0 t-rounded-lg t-shadow-light-30 t-group">
                      <div className="t-flex t-flex-col t-gap-1.5">
                        <p className="t-text-subtext t-text-text-60 t-m-0">
                          Jurisdictions Tracked
                        </p>
                        <p className="t-text-body-sm t-text-text-30 t-m-0">
                          Monitor all jurisdictions where your business
                          operates.
                        </p>
                      </div>
                      <div className="t-flex t-gap-1.5 t-items-center">
                        <Tag icon={false} tagType="red">
                          <span className="t-text-red t-flex t-items-center t-gap-1.5">
                            <CircleWarning />{" "}
                            <span className="t-text-text-60 t-text-subtext">
                              {nexusTrackerData?.exceeding_jurisdictions_count}
                            </span>
                          </span>
                        </Tag>
                      </div>
                    </div>
                  )}
              </div>
            </DashboardContainer.Header>
            <DashboardContainer.Content>
              <div className="t-pt-4">
                <TableUI sticky={false} table={table} size="regular" />
              </div>
            </DashboardContainer.Content>
          </DashboardContainer>
        </Async.Success>
      </Async.Root>
    </DashboardLayout>
  );
};
