import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type BankTransferType = {
  show: boolean;
  bankTransferOrderId: string;
  isFromLaunch?: boolean;
  entityId?: string;
};

const initialState: BankTransferType = {
  show: false,
  bankTransferOrderId: "",
  isFromLaunch: false,
  entityId: "",
};

export const bankTransfer = createSlice({
  name: "bankTransfer",
  initialState,
  reducers: {
    openBankTransferDetails: (
      state,
      action: PayloadAction<{
        bankTransferOrderId: string;
        isFromLaunch: boolean;
        entityId?: string;
      }>
    ) => {
      state.bankTransferOrderId = action.payload.bankTransferOrderId;
      state.isFromLaunch = action.payload.isFromLaunch;
      state.show = true;
      if (action.payload.entityId) {
        state.entityId = action.payload.entityId;
      }
    },
    closeBankTransferDetails: (state) => {
      state.show = false;
      state.bankTransferOrderId = "";
      state.isFromLaunch = false;
      state.entityId = "";
    },
  },
});

export const { openBankTransferDetails, closeBankTransferDetails } =
  bankTransfer.actions;

export default bankTransfer.reducer;
