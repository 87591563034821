import React, { useState } from "react";
import { AddTransactionManuallyModal } from "./AddTransactionManuallyModal";
import { CSVColumnMapping } from "./CSVColumnMapping";
import { CSVParsedResponse, CSVResponse } from "types/Models/reconciliation";
import { Transaction } from "types/Models/books";
import randomBytes from "randombytes";

export const AddTransactionModalBody = ({
  close,
  selectedAccountId,
  uploadedCsvData,
  currentStep,
  setCurrentStep,
  defaultTransaction,
}: {
  close: () => void;
  selectedAccountId: string;
  uploadedCsvData?: CSVParsedResponse;
  currentStep: number;
  setCurrentStep: (newState: React.SetStateAction<number>) => void;
  defaultTransaction?: Transaction;
}) => {
  const [csvTransactions, setCsvTransactions] = useState<
    CSVResponse[] | undefined
  >();

  switch (true) {
    case uploadedCsvData && currentStep === 2:
      return (
        <CSVColumnMapping
          close={close}
          uploadedCsvData={uploadedCsvData}
          setCurrentStep={setCurrentStep}
          setCsvTransactions={setCsvTransactions}
        />
      );

    default:
      return (
        <AddTransactionManuallyModal
          close={close}
          selectedAccountId={selectedAccountId}
          csvTransactions={csvTransactions}
          defaultTransactions={
            defaultTransaction
              ? [
                  {
                    id: randomBytes(16).toString("hex"),
                    date: "",
                    merchant: defaultTransaction.merchant_uuid || "",
                    category: defaultTransaction.category?.uuid || "",
                    description: defaultTransaction.description || "",
                    amount: defaultTransaction.amount,
                    invoice: null,
                    linked_transaction: defaultTransaction.linked_transaction,
                    uuid: defaultTransaction.uuid,
                  },
                ]
              : []
          }
        />
      );
  }
};
