import { ConditionalLink } from "components/conditionalLink";
import { Button } from "components/DesignSystem/Button/Button";
import { UnreadChatIcon } from "components/icons/UnreadChatIcon";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import React from "react";
import { useChatContext, usePaginatedChannels } from "stream-chat-react";

export const UnreadChatRow = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const { client } = useChatContext();
  const { channels } = usePaginatedChannels(
    client,
    {
      type: "messaging",
      members: { $in: client.userID ? [client.userID] : [] },
      group_uuid: { $in: [groupId] },
    },
    {
      has_unread: -1,
    },
    {
      limit: 1000,
    },
    () => {}
  );

  if (channels.length === 0) return null;

  return (
    <div className="t-flex t-justify-between t-items-center t-py-4 t-px-0 t-border t-border-solid t-border-neutral-0 t-border-t-0 t-border-l-0 t-border-r-0 last:t-border-b-0">
      <div className="t-flex t-gap-2 t-items-center">
        <div className="t-relative t-min-h-10 t-min-w-10 t-max-h-10 t-max-w-10 t-border t-border-solid t-border-neutral-0 t-rounded-full t-flex t-items-center t-justify-center">
          <UnreadChatIcon />
        </div>
        <div className="t-text-subtext-sm t-text-text-100">
          {channels.length} Unread chats
        </div>
      </div>
      <ConditionalLink to="/chat">
        <Button size="small">View</Button>
      </ConditionalLink>
    </div>
  );
};
