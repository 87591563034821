import React, { ReactNode } from "react";
import { Channel as ChannelProps } from "stream-chat";
import { Channel, DefaultStreamChatGenerics } from "stream-chat-react";

export const UnstyledChannel = ({
  channel,
  children,
}: {
  children: ReactNode;
  channel: ChannelProps<DefaultStreamChatGenerics>;
}) => {
  return (
    <div className="t-group/unstyled">
      <Channel channel={channel}>{children}</Channel>
    </div>
  );
};
