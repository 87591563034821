import { createContext } from "react";
import { CheckoutDataProps } from "./CheckoutModal";
import { BANK_PAYMENT_METHOD, CARD_PAYMENT_METHOD } from "constants/billing";

type UseCreditsProps = {
  useCredits: boolean;
  setUseCredits: (value: boolean) => void;
  useBrexPoints: boolean;
  setUseBrexPoints: (value: boolean) => void;
};

type EntityChangeProps = {
  setEntityId: (value: string) => void;
  isEntityProvided: boolean;
};

type SelectPaymentThrough = {
  paymentThrough: typeof CARD_PAYMENT_METHOD | typeof BANK_PAYMENT_METHOD;
  setPaymentThrough: (
    value: typeof CARD_PAYMENT_METHOD | typeof BANK_PAYMENT_METHOD
  ) => void;
};

type SelectPaymentMethod = {
  paymentMethodId: string | null;
  setPaymentMethodId: (id: string) => void;
};

type Steps = {
  step: "DETAILS" | "PAYMENT";
  setStep: (value: "DETAILS" | "PAYMENT") => void;
};

type CheckoutModalCreditPropsContext = {
  type: "credits";
  creditsToBeAdded: number;
  setCreditsToBeAdded: (credits: number) => void;
} & EntityChangeProps &
  SelectPaymentMethod &
  SelectPaymentThrough;

type CheckoutModalInvoicesPropsContext = {
  type: "invoices";
  paymentMethodId: string | null;
  setPaymentMethodId: (id: string) => void;
} & UseCreditsProps &
  SelectPaymentMethod &
  EntityChangeProps &
  SelectPaymentThrough;

type AcceptAgreement = {
  agreementAccepted: boolean;
  setAgreementAccepted: (value: boolean) => void;
};

type CheckoutModalSubscriptionPropsContext = {
  type: "subscription";
} & SelectPaymentMethod &
  EntityChangeProps &
  SelectPaymentThrough &
  AcceptAgreement;

type CheckoutModalCartPropsContext = {
  type: "cart";
} & UseCreditsProps &
  SelectPaymentMethod &
  SelectPaymentMethod &
  AcceptAgreement &
  SelectPaymentThrough;

type CheckoutModalTaskPropsContext = {
  type: "task";
} & UseCreditsProps &
  SelectPaymentMethod &
  SelectPaymentThrough;

type ContextUnion =
  | CheckoutModalCreditPropsContext
  | CheckoutModalInvoicesPropsContext
  | CheckoutModalSubscriptionPropsContext
  | CheckoutModalCartPropsContext
  | CheckoutModalTaskPropsContext;

export type CheckoutModalPropsContextType = {
  entityId: string;
  payerUserType: "FCA" | "CUSTOMER";
  invoiceRequestId?: string;
} & CheckoutDataProps &
  ContextUnion &
  Steps & {
    groupId: string;
  };

export const CheckoutModalPropsContext = createContext<
  CheckoutModalPropsContextType | undefined
>(undefined);
