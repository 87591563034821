import { useGetEntityBanksQuery } from "store/apis/bankConnections";
import { pluralize } from "utils/pluralize";
import { BankConnectModal } from "./ChartOfAccounts/BankConnectModal";
import Async from "./DesignSystem/AsyncComponents/Async";
import { SliderAccordion } from "./DesignSystem/SliderAccordion/SliderAccordion";
import { useModal } from "hooks/useModal";
import { useBankConnect } from "hooks/useBankConnect";
import { Button } from "./DesignSystem/Button/Button";
import { PermissionBasedUI } from "./PermissionBasedUI/PermissionBasedUI";
import { BlockedButton } from "./BlockedButton/BlockedButton";
import { CONNECT_BANK_ACCOUNT } from "constants/subscriptionPermissionFeatures";

export const TaskConnectBank = ({
  groupId,
  entityId,
}: {
  groupId: string;
  entityId: string;
}) => {
  const {
    data: banksAccounts,
    isLoading,
    isSuccess,
  } = useGetEntityBanksQuery(
    { entityId, groupId },
    { skip: !entityId || !groupId }
  );

  const accounts = banksAccounts?.accounts || [];

  const banksAccountsGroupByBank = accounts.reduce(
    (acc: { [key: string]: typeof accounts }, curr) => {
      const bankName = curr.bank_brand.name;
      if (!acc[bankName]) {
        acc[bankName] = [];
      }
      acc[bankName].push(curr);
      return acc;
    },
    {}
  );

  const connectBank = useModal();

  const { onConnect } = useBankConnect();

  return (
    <Async.Root
      {...{
        isLoading,
        isSuccess,
        isEmpty: banksAccounts?.accounts?.length === 0,
      }}
    >
      <Async.Empty>
        <PermissionBasedUI
          align="start"
          feature={CONNECT_BANK_ACCOUNT}
          blockedUI={
            <BlockedButton size="small">Connect account</BlockedButton>
          }
        >
          <Button
            onClick={connectBank.open}
            customType="primary-outlined"
            size="small"
          >
            Connect account
          </Button>
        </PermissionBasedUI>
      </Async.Empty>
      <Async.Success>
        <SliderAccordion.Root
          defaultValue={Object.keys(banksAccountsGroupByBank)}
          type="multiple"
          className="t-flex t-flex-col t-gap-4 t-w-full"
        >
          {Object.entries(banksAccountsGroupByBank).map(([name, accounts]) => (
            <SliderAccordion.Item value={name} key={name}>
              <SliderAccordion.Trigger>
                <div className="t-flex t-flex-col t-gap-1">
                  <div className="t-text-text-60 t-text-subtext-sm">{name}</div>
                  <div className="t-text-dark_green t-text-caption t-lowercase">
                    {pluralize(
                      accounts.length,
                      "account connected",
                      "accounts connected"
                    )}
                  </div>
                </div>
              </SliderAccordion.Trigger>
              <SliderAccordion.Content>
                <div className="t-flex t-flex-col t-gap-2">
                  {accounts.map(({ account }) => (
                    <div
                      key={account.uuid}
                      className="t-text-text-60 t-text-subtext-sm"
                    >
                      {account.nickname}
                      <span className="t-text-body t-text-start">••••</span>
                      {account.mask}
                    </div>
                  ))}
                </div>
              </SliderAccordion.Content>
            </SliderAccordion.Item>
          ))}
          <div>
            <PermissionBasedUI
              align="start"
              feature={CONNECT_BANK_ACCOUNT}
              blockedUI={
                <BlockedButton size="small">Connect more</BlockedButton>
              }
            >
              <Button
                onClick={connectBank.open}
                customType="primary-outlined"
                size="small"
              >
                Connect more
              </Button>
            </PermissionBasedUI>
          </div>
        </SliderAccordion.Root>
      </Async.Success>
      <BankConnectModal
        isOpen={connectBank.isOpen}
        close={connectBank.close}
        onConnect={onConnect}
      />
    </Async.Root>
  );
};
