import { Agents } from "types/Models/agents";
import { fileAndFolder } from "types/Models/documents";
import { emptyApi } from "./emptyApi";
import qs from "qs";
import { makeFormData } from "utils/makeFormData";
import { AddressAutofill } from "types/Models/addressAutofill";

export type MailroomType = "REGISTERED_AGENT" | "MAILROOM";

export type MailroomDetails = {
  uuid: string;
  name: string | null;
  group_id: string;
  type: MailroomType;
  status: string;
  received_from: string;
  sent_to: string;
  date_received: string;
  document: fileAndFolder & {
    thumbnail_file_id: string | null;
    is_locked: boolean;
  };
  mailbox_channel_url: string;
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getMailroomDetail: build.query<
      MailroomDetails,
      { mailId: string; groupId: string }
    >({
      query: ({ groupId, mailId }) =>
        `/api/inkle/mailroom/group/${groupId}/mailroom/${mailId}/`,
    }),
    getAllMailroom: build.query<
      MailroomDetails,
      {
        page_num?: string | null;
        search_term?: string | null;
        sort_type?: string | null;
        filter_statuses?: string | null;
        filter_type?: string | null;
        groupId: string;
      }
    >({
      query: ({
        groupId,
        page_num,
        search_term,
        sort_type,
        filter_statuses,
        filter_type,
      }) => {
        let queryUrl = qs.stringify(
          { page_num, search_term, sort_type, filter_statuses, filter_type },
          { skipNulls: true, addQueryPrefix: true }
        );
        return `/api/inkle/mailroom/group/${groupId}/mailroom/${queryUrl}`;
      },
      providesTags: ["MAILROOM"],
    }),
    updateMail: build.mutation<
      MailroomDetails,
      { groupId: string; mailId: string; payload: any }
    >({
      query: ({ groupId, mailId, payload }) => {
        let formData = makeFormData(payload);
        return {
          url: `/api/inkle/mailroom/group/${groupId}/mailroom/${mailId}/`,
          method: "put",
          body: formData,
        };
      },
      invalidatesTags: ["MAILROOM"],
    }),
    addMail: build.mutation<MailroomDetails, { groupId: string; payload: any }>(
      {
        query: ({ groupId, payload }) => {
          let formData = makeFormData(payload);
          return {
            url: `/api/inkle/mailroom/group/${groupId}/mailroom/`,
            method: "post",
            body: formData,
          };
        },
        invalidatesTags: ["MAILROOM"],
      }
    ),
    getMailroomAddress: build.query<
      AddressAutofill | null,
      { groupId: string }
    >({
      query: ({ groupId }) =>
        `/api/inkle/mailroom/group/${groupId}/default-address/`,
      providesTags: ["MAILROOM_DEFAULT_ADDRESS", "AUTOFILL"],
    }),
    removeMailroomDefaultAddress: build.mutation<
      MailroomDetails,
      { addressId: string; tagId: string; groupId: string; tagCategory: string }
    >({
      query: ({ addressId, tagId, groupId, tagCategory }) => {
        return {
          url: `/api/inkle/tags/group/${groupId}/object/${addressId}/tag/${tagId}?tag_category=${tagCategory}`,
          method: "delete",
        };
      },
      invalidatesTags: ["MAILROOM_DEFAULT_ADDRESS"],
    }),
    updateClientRecord: build.mutation<
      { message: string },
      {
        entityId: string;
        clientId: string;
      }
    >({
      query: ({ entityId, clientId }) => {
        return {
          url: `/api/inkle/mailroom/entity/${entityId}/client/`,
          method: "post",
          body: { client_id: clientId },
        };
      },
      invalidatesTags: ["MAILROOM_DEFAULT_ADDRESS"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetMailroomDetailQuery,
  useGetAllMailroomQuery,
  useUpdateMailMutation,
  useAddMailMutation,
  useGetMailroomAddressQuery,
  useRemoveMailroomDefaultAddressMutation,
  useUpdateClientRecordMutation,
} = extendedApi;
