import { TableUI } from "components/design/TableUI";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import Slider from "components/DesignSystem/Slider/Slider";
import { SliderAccordion } from "components/DesignSystem/SliderAccordion/SliderAccordion";
import { LockIcon } from "components/icons/LockIcon";
import Pencil from "components/icons/pencil";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import { ReactNode, useState } from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  Row,
  useReactTable,
} from "react-table-8.10.7";
import {
  SensitiveDataFormField,
  SensitiveDataFormFieldOptionType,
  SensitiveDataType,
  SharedProfile,
} from "types/Models/sensitiveData";
import { Label } from "components/DesignSystem/TextInput/TextInput";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import classNames from "classnames";
import Modal from "components/DesignSystem/Modal/Modal";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { PlusIcon } from "components/icons/PlusIcon";
import { Form, Formik, FormikHelpers, useFormikContext } from "formik";
import { CopyIcon } from "components/icons/CopyIcon";
import { useToast } from "hooks/useToast";
import { COPIED_TEXT, FAILED_COPIED_TEXT } from "constants/documents";
import ThreeDots from "static/images/ThreeDots.svg";
import {
  SensitiveDataResponse,
  useCreateSensitiveDataMutation,
  useDeleteSensitiveDataMutation,
  useEditSensitiveDataMutation,
  useGetAllSensitiveDataQuery,
  useGetAllSensitiveDataTypesQuery,
  useGetEmptyFormDataQuery,
  useGetSingleSensitiveDataQuery,
  useGrantAccessToSensitiveDataMutation,
  useRevokeAccessToSensitiveDataMutation,
  useSendChatCardMutation,
} from "apis/sensitiveData";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import ReactCountryFlag from "react-country-flag";
import { BackendError } from "types/utils/error";
import { createDynamicValidationSchema } from "formValidations/sensitiveDataForm";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useUploadFileMutation } from "store/apis/documents";
import { FieldBuilder } from "components/Entity/EntityFormEngine/FieldBuilder";
import { DownloadIcon } from "components/icons/Download";
import { FilePreviewTag } from "components/FilePreviewTag/FilePreviewTag";
import { FileObject } from "types/Models/fileObject";
import { useGetAllCpaTeamsQuery } from "store/apis/serviceTeam";
import { useMultipleFileDetails } from "hooks/useMultipleFileDetails";
import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";

const FilesDetailWrapper = ({
  fileIds,
  children,
}: {
  fileIds: string[];
  children: ({ fileDetails }: { fileDetails: FileObject[] }) => ReactNode;
}) => {
  const fileDetails = useMultipleFileDetails(fileIds);

  return children({ fileDetails });
};

const DeleteSensitiveDataModal = ({
  isOpen,
  close,
  sensitiveDataId,
}: {
  isOpen: boolean;
  close: () => void;
  sensitiveDataId: string;
}) => {
  const [deleteSensitiveData, { isLoading: isDeleting }] =
    useDeleteSensitiveDataMutation();
  const { entityId } = useParams<{ entityId: string }>();
  const { alertToast, successToast } = useToast();
  const handleDelete = async () => {
    try {
      await deleteSensitiveData({
        entityId,
        formId: sensitiveDataId,
      });
      close();
      successToast({ message: "Sensitive Data deleted successfully" });
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };
  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Delete</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          You’re about to delete sensitive data, are you sure you want to
          proceed?
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Button onClick={close}>Cancel</Button>
          <Button
            customType="danger-outlined"
            onClick={handleDelete}
            isLoading={isDeleting}
          >
            Delete
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};
const RowAction = ({ rowData }: { rowData: SensitiveDataResponse }) => {
  const deleteModal = useModal();
  const editModal = useModal();

  return (
    <div
      className="t-text-subtext t-text-text-30"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="t-flex t-justify-center t-items-center t-h-full">
        <Dropdown.Root>
          <Dropdown.Trigger asChild>
            <div>
              <Button size="small" customType="ghost_icon">
                <img src={ThreeDots} alt="Action" className="t-select-none" />
              </Button>
            </div>
          </Dropdown.Trigger>
          <Dropdown.Portal>
            <Dropdown.Content sideOffset={8} side="bottom" className="t-mr-16">
              <Dropdown.Item onSelect={editModal.open}>Edit</Dropdown.Item>
              <Dropdown.Item onSelect={deleteModal.open} type="danger">
                Delete
              </Dropdown.Item>
            </Dropdown.Content>
          </Dropdown.Portal>
        </Dropdown.Root>
      </div>

      <EditSensitiveDataDynamicForm
        close={editModal.close}
        isOpen={editModal.isOpen}
        formId={rowData.uuid}
      />

      <DeleteSensitiveDataModal
        close={deleteModal.close}
        isOpen={deleteModal.isOpen}
        sensitiveDataId={rowData.uuid}
      />
    </div>
  );
};

export const SensitiveDataRequestModal = ({
  formId,
  isOpen,
  close,
  channelUrl,
  entityId,
}: {
  formId: string;
  isOpen: boolean;
  close: () => void;
  channelUrl: string;
  entityId: string;
}) => {
  const [sendChatCard, { isLoading: isSendingChatCard }] =
    useSendChatCardMutation();
  const [createSensitiveData, { isLoading: isRequesting }] =
    useCreateSensitiveDataMutation();
  const { alertToast, successToast } = useToast();
  const createRequest = async (values_obj: any) => {
    const payload = {
      sensitive_data_type_id: formId,
      form_data: Object.keys(values_obj).map((key) => ({
        field_key: key,
        field_value: values_obj[key],
      })),
    };
    try {
      if (entityId) {
        const response = await createSensitiveData({
          entityId,
          payload,
        }).unwrap();

        const { uuid: sensitiveDataInstanceId } = response || {};

        await sendChatCard({
          entityId,
          sensitiveDataInstanceId,
          channelUrl,
        }).unwrap();
        successToast({ message: "Request created successfully" });
        close();
      }
    } catch (error) {
      alertToast(
        { message: (error as BackendError).data?.error?.message },
        error as {}
      );
    }
  };
  const {
    data: formFields = [],
    isLoading,
    isSuccess,
  } = useGetEmptyFormDataQuery(
    {
      entityId,
      formId,
    },
    { skip: !entityId || !formId }
  );
  const commonFormFields = formFields.filter(
    (field) => field.is_common_attribute == true
  );
  const isEmpty = commonFormFields.length == 0;
  return (
    <Formik
      initialValues={{
        name: "",
        description: "",
      }}
      onSubmit={createRequest}
    >
      {({ submitForm }) => (
        <Form>
          <Modal.Root open={isOpen} onOpenChange={close}>
            <Modal.Content>
              <Async.Root {...{ isLoading, isSuccess, isEmpty }}>
                <Async.Empty>-</Async.Empty>
                <Async.Success>
                  <Modal.Header>
                    <Modal.Title>Request</Modal.Title>
                    <Modal.Close />
                  </Modal.Header>

                  <Modal.Body className="t-px-5 t-py-8 ">
                    <div className="t-flex t-flex-col t-gap-4">
                      {commonFormFields.map((formField, index) => {
                        return (
                          <DynamicInputFields
                            formField={formField}
                            key={formField.uuid}
                            index={index}
                          />
                        );
                      })}
                    </div>
                  </Modal.Body>
                  <Modal.FooterButtonGroup>
                    <Button customType="secondary" onClick={close}>
                      Cancel
                    </Button>
                    <Button
                      customType="primary"
                      onClick={submitForm}
                      isLoading={isRequesting || isSendingChatCard}
                    >
                      Send Request
                    </Button>
                  </Modal.FooterButtonGroup>
                </Async.Success>
              </Async.Root>
            </Modal.Content>
          </Modal.Root>
        </Form>
      )}
    </Formik>
  );
};

const RevokeAccessModal = ({
  isOpen,
  close,
  name,
  memberId,
}: {
  isOpen: boolean;
  close: () => void;
  name: string;
  memberId: string;
}) => {
  const [revokeAccessToSensitiveData, { isLoading: isDeleting }] =
    useRevokeAccessToSensitiveDataMutation();
  const { entityId } = useParams<{ entityId: string }>();
  const { alertToast, successToast } = useToast();
  const handleRevokeAccess = async () => {
    try {
      await revokeAccessToSensitiveData({
        entityId,
        sensitiveDataMapId: memberId,
      }).unwrap();
      successToast({ message: "Access revoked successfully" });
      close();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };
  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Revoke Access</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <span>
            You are about to revoke access from <strong>{name}</strong>, are you
            sure you want to proceed?
          </span>
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Button onClick={close}>Cancel</Button>
          <Button
            customType="danger-outlined"
            onClick={handleRevokeAccess}
            isLoading={isDeleting}
          >
            Delete
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};

const SharedProfiles = ({ member }: { member: SharedProfile }) => {
  const {
    isOpen: isAccessModalOpen,
    open: openAccessModal,
    close: closeAccessModal,
  } = useModal();

  return (
    <div
      className={classNames(
        "t-flex t-py-3 t-gap-2 t-items-center t-justify-between t-border t-border-solid  t-border-b-i-surface-grey t-border-t-0 t-border-l-0 t-border-r-0 last:t-border-none"
      )}
    >
      <div className="t-flex t-gap-2 t-items-center">
        <Avatar alt={member.profile.name} />
        <div className=" t-flex t-flex-col">
          <span className="t-text-body">{member.profile.name}</span>
          <span className=" t-text-body-sm">{member.profile.email}</span>
        </div>
      </div>
      <Button onClick={() => openAccessModal()} customType="secondary">
        Revoke
      </Button>

      <RevokeAccessModal
        isOpen={isAccessModalOpen}
        close={closeAccessModal}
        name={member.profile.name}
        memberId={member.uuid}
      />
    </div>
  );
};

const GrantMemberForm = () => {
  const { isCustomer } = useRoleBasedView();
  const { data: membersAccess = [] } = useGetAllCpaTeamsQuery(
    {},
    { skip: isCustomer }
  );
  const {
    values: { invite_profile },
  } = useFormikContext<{ invite_profile: string }>();

  const selectedMember = membersAccess.find(
    ({ uuid }) => uuid === invite_profile
  );
  return (
    <Form>
      <div className="t-flex t-items-end t-gap-2">
        <div className="t-w-full">
          <Combobox
            components={{
              DropdownIndicator: () => null,
            }}
            label="Email"
            required
            withForm
            name="invite_profile"
            menuPlacement="auto"
            menuPortalTarget={document.body}
            options={membersAccess.map((member) => {
              return {
                label: member.name,
                value: member.uuid,
              };
            })}
            value={
              selectedMember
                ? {
                    label: selectedMember.name,
                    value: selectedMember.uuid,
                  }
                : null
            }
          />
        </div>
        <Button customType="primary" size="regular">
          Invite
        </Button>
      </div>
    </Form>
  );
};

const ExternalDetails = ({ sensitiveDataId }: { sensitiveDataId: string }) => {
  const { isAdmin } = useRoleBasedView();

  const { alertToast, successToast } = useToast();

  const { entityId } = useParams<{ entityId: string }>();
  const { data } = useGetSingleSensitiveDataQuery(
    {
      entityId,
      formId: sensitiveDataId,
    },
    {
      skip: !entityId || !sensitiveDataId,
    }
  );

  const { shared_profiles } = data || {};

  const [grantAccessToSensitiveData] = useGrantAccessToSensitiveDataMutation();

  const initialValues = {
    invite_profile: "",
  };

  const handleGrantAccess = async (
    value: { invite_profile: string },
    { resetForm }: FormikHelpers<typeof initialValues>
  ) => {
    try {
      const { invite_profile } = value;
      if (invite_profile) {
        await grantAccessToSensitiveData({
          entityId,
          formId: sensitiveDataId,
          profileId: invite_profile,
        }).unwrap();
        resetForm({ values: initialValues });
        successToast({ message: "Access granted successfully" });
      }
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  if (!isAdmin) {
    return null;
  }

  return (
    <SliderAccordion.Item value="EXTERNAL_DETAILS">
      <SliderAccordion.Trigger>External Details</SliderAccordion.Trigger>
      <SliderAccordion.Content>
        <div className=" t-flex t-flex-col">
          <Formik initialValues={initialValues} onSubmit={handleGrantAccess}>
            {({ submitForm, setFieldValue }) => <GrantMemberForm />}
          </Formik>
        </div>
        {shared_profiles?.map((member) => (
          <SharedProfiles member={member} key={member.uuid} />
        ))}
      </SliderAccordion.Content>
    </SliderAccordion.Item>
  );
};
const SensitiveDataSlider = ({ onClose }: { onClose: () => void }) => {
  const editModal = useModal();
  const { alertToast, successToast } = useToast();
  const copyToClipBoard = async (value: string) => {
    try {
      if (value) {
        await navigator.clipboard.writeText(value);
        successToast({ message: COPIED_TEXT });
      }
    } catch (err) {
      alertToast({ message: FAILED_COPIED_TEXT });
    }
  };
  const { sensitiveDataId, entityId } = useParams<{
    sensitiveDataId: string;
    entityId: string;
  }>();

  const { data, isLoading, isSuccess, originalArgs } =
    useGetSingleSensitiveDataQuery(
      {
        entityId,
        formId: sensitiveDataId,
      },
      {
        skip: !entityId || !sensitiveDataId,
      }
    );
  const isEmpty = false;
  const {
    name,
    description,
    form_data,
    has_edit_access,
    has_delete_access,
    has_share_and_revoke_access,
    shared_profiles,
    sensitive_data_type,
  } = data || {};

  const history = useHistory();

  const { uuid: groupId } = useCurrentGroupContext();
  const { url } = useRouteMatch();

  const hideValueWithSymbol = (text: string) => {
    return text.replace(/./g, "*");
  };

  return (
    <>
      <Slider.Root open={true} onOpenChange={onClose}>
        <Slider.Content open={true} useCustomOverlay>
          <Async.Root {...{ isLoading, isSuccess, isEmpty }}>
            <Async.Empty>-</Async.Empty>
            <Async.Success>
              <Slider.Header>
                <Slider.Title>{name}</Slider.Title>
                <Slider.Close />
              </Slider.Header>
              <Slider.Body>
                <SliderAccordion.Root
                  type="multiple"
                  defaultValue={["DETAILS", "EXTERNAL_DETAILS"]}
                  className="t-space-y-5"
                >
                  <SliderAccordion.Item value="DETAILS">
                    <SliderAccordion.Trigger>
                      <div className="t-flex t-justify-between t-w-full t-items-center">
                        Details
                        {has_edit_access && (
                          <>
                            <Button
                              size="small"
                              customType="icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                editModal.open();
                              }}
                            >
                              <Pencil size="16" color="#706A85" />
                            </Button>
                          </>
                        )}
                      </div>
                    </SliderAccordion.Trigger>
                    <SliderAccordion.Content>
                      <div className=" t-flex t-flex-col t-gap-6 t-overflow-ellipsis">
                        {form_data?.map(
                          (
                            {
                              is_copyable,
                              value,
                              is_encrypted,
                              field_key,
                              key,
                              data_type,
                              uuid: attributeId,
                            },
                            index
                          ) => {
                            switch (data_type) {
                              case "SELECT":
                                return (
                                  <InfoItem label={key} key={index}>
                                    {is_encrypted ? (
                                      <span
                                        className="t-text-subtext t-border-0
                                        t-pointer-events-none t-w-auto "
                                      >
                                        {value && typeof value === "string"
                                          ? hideValueWithSymbol(value)
                                          : null}
                                      </span>
                                    ) : (
                                      <span className=" t-text-subtext">
                                        {value ? value : "-"}
                                      </span>
                                    )}
                                    {is_copyable && (
                                      <Button
                                        customType="ghost_icon"
                                        onClick={() =>
                                          copyToClipBoard(value as string)
                                        }
                                      >
                                        <CopyIcon />
                                      </Button>
                                    )}
                                  </InfoItem>
                                );
                              case "MULTI_FILE_INPUT":
                                return (
                                  <>
                                    {value.length > 0 ? (
                                      <div className="t-flex t-flex-col t-gap-0">
                                        <Label>{key}</Label>
                                        <FilesDetailWrapper
                                          fileIds={value as string[]}
                                        >
                                          {({ fileDetails }) => (
                                            <>
                                              {fileDetails.map(
                                                (file: FileObject) => {
                                                  return (
                                                    <FilePreviewTag
                                                      key={index}
                                                      block
                                                      file={file}
                                                      size="small"
                                                    >
                                                      <DownloadIcon color="currentColor" />
                                                    </FilePreviewTag>
                                                  );
                                                }
                                              )}
                                            </>
                                          )}
                                        </FilesDetailWrapper>
                                      </div>
                                    ) : (
                                      <InfoItem label={key} key={index}>
                                        -
                                      </InfoItem>
                                    )}
                                  </>
                                );
                              default:
                                return (
                                  <InfoItem label={key} key={index}>
                                    <div className="t-flex t-items-center t-gap-2">
                                      {is_encrypted ? (
                                        <span
                                          className="t-text-subtext t-border-0
                                        t-pointer-events-none t-w-auto "
                                        >
                                          {value && typeof value === "string"
                                            ? hideValueWithSymbol(value)
                                            : null}
                                        </span>
                                      ) : (
                                        <span className=" t-text-subtext">
                                          {value ? value : "-"}
                                        </span>
                                      )}

                                      {is_copyable && (
                                        <Button
                                          customType="ghost_icon"
                                          onClick={() =>
                                            copyToClipBoard(value as string)
                                          }
                                        >
                                          <CopyIcon />
                                        </Button>
                                      )}
                                    </div>
                                  </InfoItem>
                                );
                            }
                          }
                        )}
                      </div>
                    </SliderAccordion.Content>
                  </SliderAccordion.Item>
                  <ExternalDetails sensitiveDataId={sensitiveDataId} />
                </SliderAccordion.Root>
              </Slider.Body>
            </Async.Success>
          </Async.Root>
        </Slider.Content>
      </Slider.Root>

      {editModal.isOpen && (
        <EditSensitiveDataDynamicForm
          close={editModal.close}
          isOpen={editModal.isOpen}
          formId={sensitiveDataId}
        />
      )}
    </>
  );
};

const SensitiveDataTable = ({ data }: { data: SensitiveDataResponse[] }) => {
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const { isAdmin, isCpa } = useRoleBasedView();

  const columnHelper = createColumnHelper<SensitiveDataResponse>();

  const columns = [
    columnHelper.accessor("name", {
      size: 40,
      header: "NAME",
      cell: (info) => {
        return (
          <div className="t-text-subtext t-flex t-items-center t-gap-2">
            <LockIcon />
            {info.getValue()}
          </div>
        );
      },
    }),

    columnHelper.accessor("description", {
      size: 60,
      header: "DESCRIPTION",
      cell: (info) => {
        return <div className="t-text-subtext">{info.getValue()}</div>;
      },
    }),

    columnHelper.display({
      id: "action",
      size: 10,
      cell: (info) => {
        const {
          row: { original },
        } = info;
        const { has_delete_access, has_edit_access } = original || {};

        return has_edit_access && has_delete_access ? (
          <RowAction rowData={original} />
        ) : (
          <></>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  const onRowClick = (row: Row<SensitiveDataType>) => {
    history.push(`${url}/${row.original.uuid}`);
  };

  return (
    <>
      <TableUI size="regular" table={table} onRowClick={onRowClick} />
      <Switch>
        <Route path={`${path}/:sensitiveDataId`}>
          <SensitiveDataSlider onClose={() => history.push(url)} />
        </Route>
      </Switch>
    </>
  );
};

const DynamicSelectFieldLabelFactory = ({
  fieldKey,
  selectedOption,
}: {
  fieldKey: string;
  selectedOption: SensitiveDataFormFieldOptionType;
}) => {
  switch (fieldKey) {
    case "country":
      return (
        <div className="t-flex t-gap-2">
          {selectedOption.code_alpha_2 && (
            <ReactCountryFlag
              countryCode={selectedOption.code_alpha_2}
              svg
              title={selectedOption.name}
              style={{
                width: "1.5em",
                height: "1.5em",
              }}
              className="t-rounded"
            />
          )}
          {selectedOption.name}
        </div>
      );
    default:
      return <div className="t-flex t-gap-2">{selectedOption.name}</div>;
  }
};
const DynamicInputFields = ({
  formField,
  index,
}: {
  formField: SensitiveDataFormField;
  index: number;
}) => {
  const {
    data_type,
    field_key,
    key,
    placeholder,
    value,
    is_required,
    uuid,
    options,
  } = formField || {};
  const { unsorted_folder_id, uuid: groupId } = useCurrentGroupContext();
  const [uploadFile, { isLoading }] = useUploadFileMutation();
  const { setFieldValue } = useFormikContext();

  switch (data_type) {
    case "SELECT":
      const selectedOption = options?.find(
        ({ name }) => name === (value as string)
      );

      return (
        <Combobox
          label={key}
          withForm
          placeholder={placeholder}
          menuPortalTarget={document.body}
          options={options?.map((option) => ({
            label: (
              <DynamicSelectFieldLabelFactory
                fieldKey={field_key}
                selectedOption={option}
              />
            ),
            value: option.name,
          }))}
          defaultValue={
            selectedOption
              ? {
                  label: (
                    <DynamicSelectFieldLabelFactory
                      fieldKey={field_key}
                      selectedOption={selectedOption}
                    />
                  ),
                  value: selectedOption?.name,
                }
              : null
          }
          isSearchable={true}
          name={field_key}
          required={is_required}
        />
      );

    default:
      return (
        <FieldBuilder
          field={{
            label: key,
            name: field_key,
            value_regex: "",
            placeholder: placeholder,
            type: data_type === "STRING" ? "INPUT" : data_type,
            is_delete_allowed: false,
            can_copy: false,
            options: [],
            formation_type: "",
            uuid,
            tooltip: "",
            disabled: false,
            value: value,
            show: null,
            is_required: is_required,
            is_onboarding_field: false,
            is_only_onboarding_field: false,
            section_name: null,
          }}
          size="regular"
        />
      );
  }
};

export const EditSensitiveDataDynamicForm = ({
  formId,
  isOpen,
  close,
  entityId: entityIdFromProps,
}: {
  formId: string;
  isOpen: boolean;
  close: () => void;
  entityId?: string;
}) => {
  const { entityId } = useParams<{ entityId: string }>();
  const finalEntityId = entityId || entityIdFromProps;
  const { data, isLoading, isSuccess, originalArgs } =
    useGetSingleSensitiveDataQuery(
      {
        entityId: finalEntityId!,
        formId,
      },
      {
        skip: !formId || !finalEntityId || !isOpen,
      }
    );

  const [editSensitiveData, { isLoading: isUpdating }] =
    useEditSensitiveDataMutation();

  const { form_data: formFields = [], name: formName } = data || {};
  const isEmpty = formFields?.length == 0;
  const { alertToast, successToast } = useToast();
  const initialValues = formFields.reduce((acc, field) => {
    acc[field.field_key] = field.value;
    return acc;
  }, {} as Record<string, string | string[] | null | File>);

  const handleSubmit = async (
    values_obj: Record<string, string | string[] | null | File>
  ) => {
    const payload = {
      form_data: Object.keys(values_obj).map((key: string) => ({
        field_key: key,
        field_value: values_obj[key],
      })),
    };
    try {
      await editSensitiveData({
        entityId: finalEntityId!,
        formId,
        payload,
      }).unwrap();
      successToast({ message: "Sensitive data edited successfully" });
      close();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };
  const validationSchema = createDynamicValidationSchema(formFields);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ submitForm }) => (
        <Form>
          <Modal.Root open={isOpen} onOpenChange={close}>
            <Modal.Content useCustomOverlay>
              <Async.Root {...{ isLoading, isSuccess, isEmpty }}>
                <Async.Empty>-</Async.Empty>
                <Async.Success>
                  <Modal.Header>
                    <Modal.Title>{`Edit ${formName}`}</Modal.Title>
                    <Modal.Close />
                  </Modal.Header>
                  <Modal.Body className="t-flex t-flex-col t-px-8 t-py-5 t-gap-4">
                    {formFields.map((formField, index) => {
                      return (
                        <DynamicInputFields
                          formField={formField}
                          key={formField.uuid}
                          index={index}
                        />
                      );
                    })}
                  </Modal.Body>
                  <Modal.FooterButtonGroup>
                    <Modal.RawClose asChild>
                      <Button>Cancel</Button>
                    </Modal.RawClose>
                    <Button
                      customType="primary"
                      onClick={submitForm}
                      isLoading={isUpdating}
                    >
                      Submit
                    </Button>
                  </Modal.FooterButtonGroup>
                </Async.Success>
              </Async.Root>
            </Modal.Content>
          </Modal.Root>
        </Form>
      )}
    </Formik>
  );
};
const SensitiveDataDynamicForm = ({
  formId,
  formName,
  isOpen,
  close,
}: {
  formId: string;

  formName: string;
  isOpen: boolean;
  close: () => void;
}) => {
  const { entityId } = useParams<{ entityId: string }>();
  const {
    data: formFields = [],
    isLoading,
    isSuccess,
  } = useGetEmptyFormDataQuery(
    {
      entityId,
      formId,
    },
    {
      skip: !formId || !entityId,
      refetchOnMountOrArgChange: true,
    }
  );
  const isEmpty = formFields?.length == 0;
  const { alertToast, successToast } = useToast();
  const initialValues = formFields.reduce((acc, field) => {
    acc[field.field_key] = field.value;
    return acc;
  }, {} as Record<string, string | string[] | number | null | File>);
  const [createSensitiveData, { isLoading: isCreating }] =
    useCreateSensitiveDataMutation();

  const handleSubmit = async (
    values_obj: Record<string, string | string[] | number | null | File>
  ) => {
    const payload = {
      sensitive_data_type_id: formId,
      form_data: Object.keys(values_obj).map((key) => ({
        field_key: key,
        field_value: values_obj[key],
      })),
    };
    try {
      const response = await createSensitiveData({
        entityId,
        payload,
      }).unwrap();
      successToast({ message: "Credentials stored successfully" });

      close();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };
  const validationSchema = createDynamicValidationSchema(formFields);
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(v) => {
        handleSubmit(v);
      }}
      validationSchema={validationSchema}
    >
      {({ submitForm }) => {
        return (
          <Form>
            <Modal.Root open={isOpen} onOpenChange={close}>
              <Modal.Content useCustomOverlay>
                <Modal.Header>
                  <Modal.Title>{formName}</Modal.Title>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body className="t-flex t-flex-col t-px-8 t-py-5 t-gap-4">
                  <Async.Root {...{ isLoading, isSuccess, isEmpty }}>
                    <Async.Empty>-</Async.Empty>
                    <Async.Success>
                      {formFields.map((formField, index) => {
                        return (
                          <DynamicInputFields
                            formField={formField}
                            key={formField.uuid}
                            index={index}
                          />
                        );
                      })}
                    </Async.Success>
                  </Async.Root>
                </Modal.Body>
                <Modal.FooterButtonGroup>
                  <Modal.RawClose asChild>
                    <Button>Cancel</Button>
                  </Modal.RawClose>
                  <Button
                    customType="primary"
                    onClick={submitForm}
                    isLoading={isCreating}
                  >
                    Submit
                  </Button>
                </Modal.FooterButtonGroup>
              </Modal.Content>
            </Modal.Root>
          </Form>
        );
      }}
    </Formik>
  );
};
const AddSensitiveDataDropdown = () => {
  const { data: formTypes } = useGetAllSensitiveDataTypesQuery();
  const [formId, setFormId] = useState<string>("");
  const [formName, setFormName] = useState<string>("");
  const {
    isOpen: isCreateModalOpen,
    open: openCreateModal,
    close: closeCreateModal,
  } = useModal();
  return (
    <div>
      <Dropdown.Root>
        <Dropdown.Trigger asChild>
          <Button customType="primary" size="small">
            <div className="t-flex t-items-center t-gap-2">
              <PlusIcon />
              Add Sensitive Data
            </div>
          </Button>
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <Dropdown.Content
            align="start"
            sideOffset={8}
            className="t-min-w-[176px]"
            asChild
          >
            {formTypes?.map(({ uuid, name }, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  textValue={name}
                  onSelect={() => {
                    setFormId(uuid);
                    setFormName(name);
                    openCreateModal();
                  }}
                >
                  {name}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>
      {/* unmounting here */}
      {isCreateModalOpen && (
        <SensitiveDataDynamicForm
          formId={formId}
          formName={formName}
          isOpen={isCreateModalOpen}
          close={closeCreateModal}
        />
      )}
    </div>
  );
};
export const SensitiveData = () => {
  const { isCustomer, isAdmin } = useRoleBasedView();

  const { entityId } = useParams<{ entityId: string }>();
  const { unsorted_folder_id, uuid: groupId } = useCurrentGroupContext();
  const {
    data = [],
    isLoading,
    isSuccess,
  } = useGetAllSensitiveDataQuery({
    entityId,
  });
  const isEmpty = data.length === 0;
  return (
    <>
      <Header
        v2
        title="Sensitive Data"
        right={
          <div className="t-flex t-gap-3 t-items-center">
            {(isCustomer || isAdmin) && <AddSensitiveDataDropdown />}
          </div>
        }
      />
      <div className="t-mt-3">
        <Async.Root {...{ isLoading, isEmpty, isSuccess }}>
          <Async.Empty>
            <EmptyScreen text="There is no sensitive data being stored" />
          </Async.Empty>
          <Async.Success>
            <SensitiveDataTable data={data} />
          </Async.Success>
        </Async.Root>
      </div>
    </>
  );
};
