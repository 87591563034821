import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Form, Formik } from "formik";
import { useToast } from "hooks/useToast";
import { useUpdateClientRecordMutation } from "store/apis/mailroom";
import { BackendError } from "types/utils/error";

export const AddMailroomDetails = ({
  isOpen,
  close,
  onSuccess,
  entityId,
}: {
  isOpen: boolean;
  close: () => void;
  onSuccess: () => void;
  entityId: string;
}) => {
  const { alertToast, successToast } = useToast();
  const [updateHBSRecord, { isLoading: isUpdating }] =
    useUpdateClientRecordMutation();

  const addMailroomDetails = async ({ clientId }: { clientId: string }) => {
    try {
      await updateHBSRecord({
        clientId,
        entityId,
      }).unwrap();
      successToast({ message: "Mailroom details added!" });
      onSuccess();
    } catch (e) {
      alertToast({ message: (e as BackendError)?.data?.error?.message });
    }
  };
  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content useCustomOverlay>
        <Formik
          onSubmit={addMailroomDetails}
          initialValues={{
            clientId: "",
          }}
          validateOnChange
        >
          <Form>
            <Modal.Header>
              <Modal.Title>Mailroom Details</Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body className="t-flex t-flex-col t-gap-5">
              <TextInput
                name="clientId"
                block
                required
                label="Client ID"
                placeholder="Enter ID Number"
              />
            </Modal.Body>
            <Modal.FooterButtonGroup>
              <Modal.RawClose asChild>
                <Button type="button">Cancel</Button>
              </Modal.RawClose>
              <Button
                customType="primary"
                type="submit"
                isLoading={isUpdating}
                disabled={isUpdating}
              >
                Save
              </Button>
            </Modal.FooterButtonGroup>
          </Form>
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
