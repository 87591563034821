import React from "react";

export const ESignCardHeader = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clip-path="url(#clip0_2123_675)">
      <path
        d="M15.4133 7.92C14.66 7.16667 13.34 7.16667 12.5867 7.92L7.92 12.5867C7.54 12.9667 7.33333 13.4667 7.33333 14V15.3333C7.33333 15.7 7.63333 16 8 16H9.33333C9.86667 16 10.3667 15.7933 10.7467 15.4133L15.4133 10.7467C15.7933 10.3667 16 9.86667 16 9.33333C16 8.8 15.7933 8.3 15.4133 7.92ZM14.4733 9.80667L9.80667 14.4733C9.68 14.6 9.51333 14.6667 9.33333 14.6667H8.66667V14C8.66667 13.8267 8.74 13.6533 8.86 13.5267L13.5267 8.86C13.78 8.60667 14.22 8.60667 14.4667 8.86C14.5933 8.98667 14.66 9.15333 14.66 9.33333C14.66 9.51333 14.5933 9.68 14.4667 9.80667H14.4733ZM9.33333 9C9.33333 8.44667 8.88667 8 8.33333 8C7.78 8 7.33333 8.44667 7.33333 9C7.33333 9.21333 7.4 9.44667 7.52667 9.66C7.31333 9.84667 7.03333 10 6.66667 10C6.37333 10 6.08 9.91333 5.8 9.77333C6.34 9 6.66667 8.07333 6.66667 7.33333C6.66667 6.23333 5.76667 5.33333 4.66667 5.33333C3.56667 5.33333 2.66667 6.23333 2.66667 7.33333C2.66667 8.14667 3.12 9.1 3.80667 9.86667C3.64667 9.95333 3.48667 10 3.33333 10C2.96667 10 2.66667 10.3 2.66667 10.6667C2.66667 11.0333 2.96667 11.3333 3.33333 11.3333C3.88667 11.3333 4.40667 11.1133 4.86667 10.7667C5.42667 11.12 6.04 11.3333 6.66667 11.3333C8.4 11.3333 9.33333 9.68 9.33333 9ZM4.75333 8.93333C4.3 8.40667 4 7.78667 4 7.33333C4 6.96667 4.3 6.66667 4.66667 6.66667C5.03333 6.66667 5.33333 6.96667 5.33333 7.33333C5.33333 7.81333 5.1 8.42 4.75333 8.93333ZM6 14C6 14.3667 5.7 14.6667 5.33333 14.6667H3.33333C1.49333 14.6667 0 13.1733 0 11.3333V3.33333C0 1.49333 1.49333 0 3.33333 0H7.17333C7.88667 0 8.55333 0.28 9.06 0.78L11.22 2.94C11.7267 3.44667 12 4.11333 12 4.82667V6C12 6.36667 11.7 6.66667 11.3333 6.66667C10.9667 6.66667 10.6667 6.36667 10.6667 6V4.82667C10.6667 4.77333 10.6667 4.72 10.6533 4.66667H8.66667C7.93333 4.66667 7.33333 4.06667 7.33333 3.33333V1.34667C7.28 1.34667 7.22667 1.33333 7.17333 1.33333H3.33333C2.23333 1.33333 1.33333 2.23333 1.33333 3.33333V11.3333C1.33333 12.4333 2.23333 13.3333 3.33333 13.3333H5.33333C5.7 13.3333 6 13.6333 6 14Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2123_675">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
