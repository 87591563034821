import { APIProvider } from "@vis.gl/react-google-maps";
import {
  AddressFormFields,
  PlaceAutocomplete,
} from "components/AddressFormFields/AddressFormFields";
import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { MobileInput } from "components/DesignSystem/MobileInput/MobileInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { US } from "constants/regAgent";
import dayjs from "dayjs";
import { Field, FieldProps, Form, Formik, FormikValues } from "formik";
import { registerAgentSchema } from "formValidations/registerAgentSchema";
import { useToast } from "hooks/useToast";
import { useGetCountriesDataQuery } from "store/apis/onboarding";
import { useCreateRegisteredAgentMutation } from "store/apis/registerAgent";

const API_KEY = process.env.PUBLIC_GOOGLE_MAP_KEY;

export const AddRA = ({
  isOpen,
  close,
  onSuccess,
  groupId,
  entityId,
}: {
  isOpen: boolean;
  close: () => void;
  onSuccess?: () => void;
  groupId: string;
  entityId: string;
}) => {
  const { alertToast, successToast } = useToast();
  const [createRegisteredAgent, { isLoading: isCreating }] =
    useCreateRegisteredAgentMutation();
  const { data: countries } = useGetCountriesDataQuery();

  const saveRegisterAgent = async ({
    start_date,
    expiration_date,
    agent_name,
    street_address,
    country,
    state,
    city,
    zipcode,
    phone_number,
  }: FormikValues) => {
    try {
      const payload = {
        agent_name,
        street_address,
        country,
        state,
        city,
        zipcode,
        phone_number,
        expiration_date: dayjs(expiration_date).format("YYYY-MM-DD"),
        start_date: dayjs(start_date).format("YYYY-MM-DD"),
      };

      await createRegisteredAgent({ payload, groupId, entityId }).unwrap();
      successToast({ message: "Registered Agent Added" });

      onSuccess?.();
      close();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Formik
        onSubmit={saveRegisterAgent}
        validationSchema={registerAgentSchema}
        initialValues={{
          agent_name: "",
          start_date: "",
          expiration_date: "",
          street_address: "",
          country: US,
          state: "",
          city: "",
          zipcode: "",
          phone_number: "",
        }}
        validateOnChange
        validateOnBlur={false}
      >
        {({ isSubmitting }) => {
          return (
            <Modal.Content asChild useCustomOverlay>
              <Form>
                <Modal.Header>
                  <Modal.Title>Registered Agent Details</Modal.Title>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body className="t-flex t-flex-col t-gap-5">
                  <TextInput
                    name="agent_name"
                    block
                    label="Agent Name"
                    placeholder="Enter Agent Name..."
                  />
                  <div className="t-flex t-gap-4">
                    <Field name="start_date">
                      {({ field }: FieldProps) => (
                        <DateInput
                          {...field}
                          required
                          portalId={field.name}
                          label="Starting date"
                          placeholder="Enter starting date"
                        />
                      )}
                    </Field>
                    <Field name="expiration_date">
                      {({ field }: FieldProps) => (
                        <DateInput
                          required
                          {...field}
                          portalId={field.name}
                          label="Expiration date"
                          placeholder="Enter expiration date"
                        />
                      )}
                    </Field>
                  </div>

                  <AddressFormFields />
                  <div>
                    <MobileInput
                      block
                      name="phone_number"
                      label="Phone No."
                      placeholder="Enter Phone No..."
                    />
                  </div>
                </Modal.Body>
                <Modal.FooterButtonGroup>
                  <Modal.RawClose asChild>
                    <Button type="button">Cancel</Button>
                  </Modal.RawClose>
                  <Button
                    customType="primary"
                    type="submit"
                    isLoading={isSubmitting || isCreating}
                    disabled={isSubmitting || isCreating}
                  >
                    Save
                  </Button>
                </Modal.FooterButtonGroup>
              </Form>
            </Modal.Content>
          );
        }}
      </Formik>
    </Modal.Root>
  );
};
