import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { OptionDropdown } from "components/icons/optionDropdown";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import {
  useDeleteEntityInvoiceMutation,
  useGetInvoiceSettingsQuery,
  useUpdateInvoiceMutation,
  useLazyDownloadInvoiceQuery,
  invoiceApis,
  Invoice,
  useLazyGetEntityInvoiceQuery,
  useCreateEntityInvoiceMutation,
  useGenrateShortLinkMutation,
} from "store/apis/invoices";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { openLink } from "utils/openLink";
import * as INVOICE_STATUSES from "constants/invoiceStatuses";
import { BackendError } from "types/utils/error";
import ConditionalToolTip from "components/design/conditionalToolTip";
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { useState } from "react";
import { useAnalytics } from "hooks/useAnalytics";
import {
  DUPLICATED_INVOICE_FROM_THREE_DOTS,
  MARKED_INVOICE_AS_PAID_FROM_THREE_DOTS,
  MARKED_INVOICE_AS_SENT_FROM_THREE_DOTS,
} from "constants/analyticsEvents";
import { useModal } from "hooks/useModal";
import { SendInvoiceModal } from "components/SendInvoiceModal/SendInvoiceModal";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { LinkTransactionViaModal } from "./LinkTransactionViaModal";

export const InvoiceActions = ({
  invoice,
  isFromInvoiceDetails,
}: {
  invoice: Invoice;
  isFromInvoiceDetails?: boolean;
}) => {
  const route = useRouteMatch();
  const query = useQuery();
  const isSendInvoiceOpen = Boolean(query.get("is_send_invoice_open"));
  const group = useCurrentGroupContext();
  const history = useHistory();
  const [duplicating, setDuplicating] = useState(false);
  const { search } = useLocation();
  const sendInvoice = useModal(isSendInvoiceOpen);
  const entityId = useCurrentEntityId();
  const isStripeInvoice = invoice.source === "STRIPE";
  const docId = invoice.document?.file_id;

  const linkViaModal = useModal();

  const { customerId: customerIdFromUrl } = useParams<{
    customerId?: string;
  }>();

  const [
    deleteInvoice,
    { isLoading: deletingInvoice, originalArgs: deletingArgs },
  ] = useDeleteEntityInvoiceMutation();

  const [
    updateInvoice,
    { isLoading: updatingInvoice, originalArgs: updateInvoiceArgs },
  ] = useUpdateInvoiceMutation();

  const [
    getInvoiceDownloadUrl,
    { isLoading: downloadingInvoice, originalArgs: invoiceDownloadArgs },
  ] = useLazyDownloadInvoiceQuery();
  const { update } = useUpdateQuery();

  const [getInvoice] = useLazyGetEntityInvoiceQuery();
  const [createInvoice] = useCreateEntityInvoiceMutation();
  const [generateLinkToken] = useGenrateShortLinkMutation();

  const [getPreviewUrl] = useLazyGetPreviewUrlQuery();

  const { alertToast, successToast } = useToast();
  const dispatch = useAppDispatch();
  const { trackEvent } = useAnalytics();

  const invoiceId = invoice.uuid;
  const customerId = invoice.customer_id;
  const status = invoice.status;

  const markInvoiceAs = async (status: Invoice["status"]) => {
    if (group?.uuid && customerId) {
      try {
        const invoice = await updateInvoice({
          entityId: entityId!,
          groupId: group?.uuid!,
          invoiceId,
          payload: {
            status,
          },
        }).unwrap();

        if (invoice.customer) {
          dispatch(
            invoiceApis.util.invalidateTags([
              { type: "INVOICE_CUSTOMERS", id: invoice.customer.uuid },
            ])
          );
        }
      } catch (error) {
        alertToast({ message: (error as BackendError).data?.error?.message });
      }
    }
  };

  const onDelete = async () => {
    try {
      await deleteInvoice({
        groupId: group.uuid,
        entityId: entityId!,
        invoiceId,
      }).unwrap();
      dispatch(
        invoiceApis.util.invalidateTags([
          { type: "INVOICE_CUSTOMERS", id: customerId },
        ])
      );
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const downloadInvoice = async () => {
    try {
      if (group.uuid && entityId) {
        const { doc_id } = await getInvoiceDownloadUrl({
          groupId: group.uuid,
          entityId,
          invoiceId: invoiceId,
        }).unwrap();

        const { download_url } = await getPreviewUrl({
          groupId: group.uuid!,
          fileId: doc_id,
        }).unwrap();
        openLink(download_url, "_blank");
      }
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const downloadStripeInvoice = async () => {
    try {
      if (group.uuid && docId) {
        const { download_url } = await getPreviewUrl({
          groupId: group.uuid!,
          fileId: docId!,
        }).unwrap();
        openLink(download_url, "_blank");
      }
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const duplicate = async () => {
    setDuplicating(true);
    try {
      if (group.uuid && entityId) {
        const {
          uuid,
          customer_id,
          due_date,
          invoice_date,
          updated_at,
          document,
          customer,
          status,
          sent_to,
          sent_on,
          title,
          ...currentInvoice
        } = await getInvoice({
          groupId: group.uuid,
          entityId,
          invoiceId,
        }).unwrap();

        const newInvoice = await createInvoice({
          groupId: group.uuid,
          entityId,
          payload: {
            ...currentInvoice,
            customer_id: customerIdFromUrl || invoice.customer_id,
          },
        }).unwrap();
        if (isFromInvoiceDetails) {
          openLink(
            `/books/invoices-and-customers/${newInvoice.uuid}/${search}`
          );
        } else {
          history.push(
            `/books/invoices-and-customers/${newInvoice.uuid}/${search}`
          );
        }
      }
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
    setDuplicating(false);
  };

  const handleMarkAsSent = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    trackEvent(MARKED_INVOICE_AS_SENT_FROM_THREE_DOTS);
    markInvoiceAs(INVOICE_STATUSES.SENT);
  };

  const handleMarkAsPaid = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    linkViaModal.open();
    e.stopPropagation();
  };

  const handleInvoiceDuplicate = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    trackEvent(DUPLICATED_INVOICE_FROM_THREE_DOTS);
    duplicate();
  };

  const openSentInvoiceEditFlow = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    let currentLink = `/books/invoices-and-customers/${invoiceId}${search}`;
    if (search) {
      currentLink += "&editflow=true";
    } else {
      currentLink += "?editflow=true";
    }
    history.push(currentLink);
  };

  const openSendInvoice = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    sendInvoice.open();
  };

  const closeSendInvoiceModal = () => {
    sendInvoice.close();
    update({
      query: "is_send_invoice_open",
      value: null,
    });
  };

  const copyInvoiceLink = async () => {
    try {
      const { token } = await generateLinkToken({
        entityId,
        invoiceId: invoice.uuid,
        from: "invoice_edit_screen",
      }).unwrap();

      const origin = window.location.origin;
      await navigator.clipboard.writeText(`${origin}/invoice/${token}`);
      successToast({ message: "Invoice link has been copied" });
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  const isLoading =
    (updatingInvoice && updateInvoiceArgs?.invoiceId === invoiceId) ||
    (downloadingInvoice && invoiceDownloadArgs?.invoiceId === invoiceId) ||
    (deletingInvoice && deletingArgs?.invoiceId === invoiceId) ||
    duplicating;

  return (
    <>
      <Dropdown.Root>
        <Dropdown.Trigger asChild>
          <span>
            <Button
              customType={isFromInvoiceDetails ? "icon" : "ghost_icon"}
              size="small"
              isLoading={isLoading}
              disabled={isLoading}
            >
              <OptionDropdown color="currentColor" />
            </Button>
          </span>
        </Dropdown.Trigger>
        {isStripeInvoice ? (
          <Dropdown.Content align="end">
            <Dropdown.Item
              disabled={!docId}
              onClick={(e) => {
                e.stopPropagation();
                downloadStripeInvoice();
              }}
            >
              Download
            </Dropdown.Item>
          </Dropdown.Content>
        ) : (
          <Dropdown.Content align="end">
            {status !== INVOICE_STATUSES.SENT && (
              <ConditionalToolTip
                side="left"
                condition={!customerId && "No customer assigned"}
              >
                <span>
                  <Dropdown.Item
                    disabled={!customerId}
                    onClick={handleMarkAsSent}
                  >
                    Mark as sent
                  </Dropdown.Item>
                </span>
              </ConditionalToolTip>
            )}
            {!(
              status === INVOICE_STATUSES.PAID ||
              status === INVOICE_STATUSES.DRAFT
            ) && (
              <ConditionalToolTip
                side="left"
                condition={!customerId && "No customer assigned"}
              >
                <span>
                  <Dropdown.Item
                    disabled={!customerId}
                    onClick={handleMarkAsPaid}
                  >
                    Mark as paid
                  </Dropdown.Item>
                </span>
              </ConditionalToolTip>
            )}
            <Dropdown.Item
              onClick={(e) => {
                e.stopPropagation();
                downloadInvoice();
              }}
            >
              Download
            </Dropdown.Item>
            {status !== INVOICE_STATUSES.DRAFT && (
              <Dropdown.Item onClick={handleInvoiceDuplicate}>
                Duplicate
              </Dropdown.Item>
            )}
            {invoice.status === INVOICE_STATUSES.DRAFT && (
              <Dropdown.Item
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete();
                }}
              >
                <span className="t-text-red-50">Delete</span>
              </Dropdown.Item>
            )}
            {status === INVOICE_STATUSES.SENT && (
              <Dropdown.Item onClick={openSentInvoiceEditFlow}>
                Edit
              </Dropdown.Item>
            )}
            {status !== INVOICE_STATUSES.DRAFT && (
              <Dropdown.Item onClick={copyInvoiceLink}>
                Copy Invoice link
              </Dropdown.Item>
            )}
            {!isFromInvoiceDetails && status === INVOICE_STATUSES.SENT && (
              <Dropdown.Item onClick={openSendInvoice}>Remind</Dropdown.Item>
            )}
          </Dropdown.Content>
        )}

        {sendInvoice.isOpen && (
          <div onClick={(e) => e.stopPropagation()}>
            <SendInvoiceModal
              isOpen={sendInvoice.isOpen}
              close={closeSendInvoiceModal}
              invoiceIdFromProp={invoiceId}
              isReminder
            />
          </div>
        )}
      </Dropdown.Root>

      <div onClick={(e) => e.stopPropagation()}>
        <LinkTransactionViaModal
          invoiceId={invoiceId}
          invoiceDueAmount={Number(invoice.due_balance)}
          invoiceNumber={invoice.invoice_number}
          isOpen={linkViaModal.isOpen}
          close={linkViaModal.close}
        />
      </div>
    </>
  );
};
