import React, { useState } from "react";
import { Task, TaskCompletedDocuments, TaskFileType } from "types/Models/task";
import TaskSuccessfulGreenTick from "static/images/TaskSuccessfulTick.svg";
import ReportCard from "./ReportCard";
import classNames from "classnames";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { formatDate } from "utils/formatDate";
import TaskButtonBar from "./TaskButtonBar";
import { Button } from "components/DesignSystem/Button/Button";
import { AmendFilingConfirmation } from "./AmendFilingConfirmation";
import { UploadFile } from "components/UploadFile/UploadFile";
import { useToast } from "hooks/useToast";
import { useAmendFilingMutation } from "store/apis/task";
import { LocalFileOrInkleDocs } from "types/Models/reviewDocumentAndBalancingPayment";
import BlueInfo from "static/images/InfoBlue.svg";
import { TASK_AMENDMENT_COMPLETED } from "constants/taskStates";
import { useModal } from "hooks/useModal";
import { WSeriesFormTableForSuccessPage } from "./WSeriesFormTableForSuccessPage";
import { ViewFinancialClosing } from "./FinancialClosing";

const SuccessBox = ({
  task,
  parentRef,
  isAmendInProgress,
  isAmendCompleted,
  isWSeries,
}: {
  task: Task;
  parentRef: any;
  isAmendInProgress?: boolean;
  isAmendCompleted?: boolean;
  isWSeries: boolean;
}) => {
  const { isCustomer, isAdmin, isCpa } = useRoleBasedView();
  const { alertToast } = useToast();
  const [amendTask, { isLoading }] = useAmendFilingMutation();
  const {
    open: openAmendConfirmationModal,
    close: closeAmendConfirmationModal,
    isOpen: showAmendConfirmationModal,
  } = useModal();

  const {
    open: openUploadFileModal,
    close: closeUploadFileModal,
    isOpen: showUploadFileModal,
  } = useModal();

  const paymentAmount = isNaN(Number(task?.payment_amount))
    ? task?.payment_amount
    : `$${task?.payment_amount}`;
  const governmentFee = Boolean(task?.government_fee)
    ? `$${task?.government_fee}`
    : task?.government_fee || "-";

  const onFileSelect = async (attachments: LocalFileOrInkleDocs[]) => {
    try {
      const inkleDocs: string[] = [];
      const localFiles: Blob[] = [];
      attachments.forEach((attachment) => {
        if ("uuid" in attachment) {
          inkleDocs.push(attachment.uuid);
        } else {
          //@ts-ignore
          localFiles.push(attachment);
        }
      });
      const groupId = task?.group_uuid;
      const taskId = task?.uuid;
      const formData = new FormData();
      formData.append("file_ids", inkleDocs.join(","));
      localFiles.forEach((file) => formData.append("files", file));
      formData.append("next_state_type", TASK_AMENDMENT_COMPLETED);

      await amendTask({
        taskId,
        groupId,
        payload: formData,
      }).unwrap();
      closeUploadFileModal();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  return (
    <div className="t-w-full t-h-full">
      <div
        className={classNames(
          "t-ml-0 t-flex t-w-full t-flex-col t-items-start t-justify-start t-h-full t-overflow-auto",
          {
            "t-p-10": !isAmendCompleted,
            "t-px-20 t-pb-0": isAmendCompleted,
            "t-pt-0": !isCustomer,
            "t-pb-20": !isCustomer && isAmendInProgress,
          }
        )}
      >
        {isAmendInProgress || isAmendCompleted ? (
          <div
            className={classNames("t-w-full", {
              "t-mt-8": !isCustomer,
            })}
          >
            {isAmendInProgress && (
              <div className="t-w-full t-text-center t-border t-border-solid t-border-blue-70 t-px-4 t-py-2 t-flex t-gap-3 t-rounded t-bg-blue-0">
                <img src={BlueInfo} alt="BlueInfo" />
                <div className="t-text-subtext t-text-text-60">
                  We are working on your amendment.
                </div>
              </div>
            )}
            <div
              className={classNames("t-w-full", {
                "t-pt-8": isAmendInProgress,
              })}
            >
              <div className="t-text-text-100 t-text-subtitle">
                Your filing is successfully completed as on{" "}
                {formatDate(task?.state_data?.updated_at)}
              </div>
            </div>
          </div>
        ) : (
          <div className="t-mx-auto t-flex t-items-center">
            <img src={TaskSuccessfulGreenTick} alt="TaskSuccessfulGreenTick" />
            <span className="t-ml-2 t-text-subtitle t-text-text-100">
              Your filing is successfully completed
            </span>
          </div>
        )}
        <div className="t-w-full t-border t-border-solid  t-border-[transparent] t-border-b-neutral-10 t-py-6">
          <div className="t-flex t-justify-between t-text-subtitle-sm t-font-medium t-text-text-30">
            <div>Filing Name</div>
            <div>Filing Completion Date</div>
          </div>
          <div className="t-mt-2 t-flex t-justify-between t-text-subtitle-sm t-font-normal t-text-text-100">
            <div>{task?.title}</div>
            <div>{formatDate(task?.state_data?.updated_at)}</div>
          </div>
        </div>
        {isWSeries && (
          <WSeriesFormTableForSuccessPage
            groupId={task.group_uuid}
            taskSeason={task.season}
            noContractorsFound={task.are_contractors_present === false}
            entityId={task.entity.uuid}
          />
        )}
        <div className="t-w-full t-border t-border-solid  t-border-[transparent] t-border-b-neutral-10 t-py-6">
          <div className="t-text-subtitle-sm t-font-medium t-text-text-30">
            Inkle Pricing
          </div>
          <div className="t-mt-2 t-flex t-justify-between t-text-subtitle-sm t-font-normal t-text-text-100">
            <div>Inkle Fee</div>
            <div>{paymentAmount}</div>
          </div>
          <div
            className="t-mt-2 t-flex t-justify-between t-text-subtitle-sm t-font-normal t-text-text-100
t-text-subtitle-sm t-font-normal t-text-text-100"
          >
            <div>Government Fee</div>
            <div>{governmentFee}</div>
          </div>
        </div>
        {task?.task_completed_documents?.length > 0 && (
          <div className="t-w-full t-border t-border-solid  t-border-[transparent] t-border-b-neutral-10 t-py-6">
            <div className="t-mb-2 t-text-subtitle-sm t-font-medium t-text-text-30">
              Documents
            </div>
            {task?.task_completed_documents.map(
              (completedDoc: TaskCompletedDocuments) => (
                <div className="t-mb-2" key={completedDoc.uuid}>
                  <ReportCard fileData={completedDoc} />
                </div>
              )
            )}
          </div>
        )}
        {Boolean(task?.invoices?.length > 0) && (
          <div className="t-w-full t-border t-border-solid  t-border-[transparent] t-border-b-neutral-10 t-py-6 t-pb-10">
            <div className="t-mb-2 t-text-subtitle-sm t-font-medium t-text-text-30">
              Invoices
            </div>
            {task?.invoices?.map((invoice: TaskFileType) => (
              <div className="t-mb-2" key={invoice.uuid}>
                <ReportCard fileData={invoice} />
              </div>
            ))}
          </div>
        )}
        {Boolean(task?.receipts?.length > 0) && (
          <div className="t-mb-8 t-w-full t-pb-10 t-pt-6">
            <div className="t-mb-2 t-text-subtitle-sm t-font-medium t-text-text-30">
              Payment Receipt
            </div>
            {task?.receipts?.map((receipt: TaskFileType) => (
              <div className="t-mb-2" key={receipt.uuid}>
                <ReportCard fileData={receipt} />
              </div>
            ))}
          </div>
        )}
        <div className="t-mt-2">
          <ViewFinancialClosing />
        </div>
      </div>
      {!isAmendCompleted && (isCpa || isAdmin) && (
        <TaskButtonBar
          justifyContent="end"
          parentRef={parentRef}
          addMarginLeft={!isAmendInProgress}
        >
          {isAmendInProgress ? (
            <Button customType="primary" onClick={openUploadFileModal}>
              Complete amendment
            </Button>
          ) : (
            <Button customType="danger" onClick={openAmendConfirmationModal}>
              Amend filing
            </Button>
          )}
        </TaskButtonBar>
      )}
      <AmendFilingConfirmation
        show={showAmendConfirmationModal}
        closeModal={closeAmendConfirmationModal}
        taskId={task.uuid}
        groupId={task.group_uuid}
      />
      {showUploadFileModal && (
        <UploadFile
          title="Upload amendment document"
          showModal={showUploadFileModal}
          closeModal={closeUploadFileModal}
          onFileSelect={onFileSelect}
          prevSelectedFiles={[]}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default SuccessBox;
