import { useModal } from "hooks/useModal";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { MultiValue, SingleValue } from "react-select";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import {
  taskMerchantApis,
  useUpdateMerchantFormMutation,
  useUpdateTaskMerchantMutation,
} from "store/apis/taskMerchants";
import { useLazyVendorFormsQuery, vendorsApi } from "store/apis/vendors";
import { Merchant, W_FORM_TYPES } from "types/Models/merchant";
import { WFormResponse } from "types/Models/vendors";
import { BackendError } from "types/utils/error";
import { debounce } from "utils/debouncing";
import { Button } from "./DesignSystem/Button/Button";
import { Combobox, OptionData } from "./DesignSystem/Combobox/Combobox";
import Modal from "./DesignSystem/Modal/Modal";
import { FileInput } from "./FileInput/FileInput";
import { Preview } from "./PreviewModal";
import { LoadingIcon } from "./icons/LoadingIcon";

const Loader = () => (
  <div className="t-flex t-justify-center t-items-center t-w-full t-gap-2 t-p-2">
    <span className="t-flex t-origin-center t-animate-spin">
      <LoadingIcon />
    </span>
    Loading...
  </div>
);

export const WSeriesFormSearch = ({
  merchantForm,
  setMerchantForm,
}: {
  merchantForm: WFormResponse | null;
  setMerchantForm: (
    newState: React.SetStateAction<WFormResponse | null>
  ) => void;
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const previewModal = useModal();

  const updateSearchTerm = debounce((value: string) => {
    setSearchTerm(value);
  });

  const { data, loadNext, isLoading, isFetching } = usePaginatedQuery<{
    merchants_forms?: WFormResponse[];
  }>(useLazyVendorFormsQuery, "merchants_forms", {
    searchTerm,
  });

  const { merchants_forms = [] } = data || {};

  const options = merchants_forms.map(
    ({ merchant: { name }, w_form: { document, season } }) => ({
      label: `${name} - ${season}`,
      value: document.uuid,
    })
  );
  return (
    <div className="t-w-full t-flex t-flex-col t-gap-4">
      <Combobox
        autoFocus
        label="Select Vendor"
        placeholder="Start typing to search"
        menuPortalTarget={document.body}
        isLoading={isLoading}
        options={options}
        onInputChange={updateSearchTerm}
        onChange={(
          option: SingleValue<OptionData> | MultiValue<OptionData>
        ) => {
          if (!option || option instanceof Array) {
            setMerchantForm(null);
            return null;
          }

          const merchant_form = merchants_forms.filter(
            ({ w_form: { document } }) => document.uuid === option?.value
          )[0];
          setMerchantForm(merchant_form);
        }}
        onMenuScrollToBottom={loadNext}
        actions={isFetching ? <Loader /> : null}
      />

      {merchantForm?.w_form?.document && (
        <>
          <FileInput
            file={{
              uuid: merchantForm?.w_form.document?.uuid!,
              name: merchantForm?.w_form.document?.name!,
              file_type: merchantForm?.w_form.document?.file_type!,
              is_previewable: merchantForm?.w_form.document?.is_previewable!,
            }}
            groupId={merchantForm?.company_group_id}
            onFileClick={previewModal.open}
            label="W Form"
          />
          <Preview
            showModal={previewModal.isOpen}
            closeModal={previewModal.close}
            groupId={merchantForm?.company_group_id}
            previewId={merchantForm?.w_form?.document?.uuid}
          />
        </>
      )}
    </div>
  );
};

export const WSeriesSelectExistingVendor = ({
  isOpen,
  close,
  taskId,
  groupId,
  season,
  merchant,
}: {
  isOpen: boolean;
  close: () => void;
  taskId?: string;
  groupId: string;
  season: string;
  merchant: Merchant;
}) => {
  const { alertToast, successToast } = useToast();
  const dispatch = useDispatch();
  const [merchantForm, setMerchantForm] = useState<WFormResponse | null>(null);
  const { uuid } = merchant || {};

  const [updateTaskMerchant, { isLoading: isTaskMerchantUpdating }] =
    useUpdateTaskMerchantMutation();
  const [updateMerchantForm, { isLoading: isMerchantFormUpdating }] =
    useUpdateMerchantFormMutation();
  const [getPreviewUrl] = useLazyGetPreviewUrlQuery();

  const updateMerchant = () => {
    dispatch(vendorsApi.util.invalidateTags(["VENDORS"]));
    dispatch(taskMerchantApis.util.invalidateTags(["Merchants"]));
  };

  const onUpload = async () => {
    try {
      const { download_url } = await getPreviewUrl({
        groupId: merchantForm?.company_group_id!,
        fileId: merchantForm?.w_form?.document?.uuid!,
      }).unwrap();

      const fileBinary = await fetch(download_url);
      const fileBuffer = await fileBinary.arrayBuffer();
      const fileObject = new File(
        [fileBuffer],
        merchantForm?.w_form?.document?.name || "w_form.pdf",
        {
          type: "application/pdf",
        }
      );

      if (taskId) {
        const { data } = await updateTaskMerchant({
          taskId,
          groupId,
          merchantId: uuid,
          form_type: merchantForm?.w_form?.type as W_FORM_TYPES,
          file: fileObject,
          season: season,
        });
        updateMerchant();
      } else {
        const { data } = await updateMerchantForm({
          groupId,
          merchantId: uuid,
          form_type: merchantForm?.w_form?.type as W_FORM_TYPES,
          file: fileObject!,
          season,
        });
        updateMerchant();
      }
      onClose();
      successToast({
        title: "W Form updated",
        message: "W Form has been updated",
      });
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  const onClose = () => {
    setMerchantForm(null);
    close();
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={onClose} modal={false}>
      <Modal.Content useCustomOverlay>
        <Modal.Header>
          <Modal.Title>Select Existing Vendor</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <WSeriesFormSearch
            merchantForm={merchantForm}
            setMerchantForm={setMerchantForm}
          />
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Modal.RawClose asChild>
            <Button>Cancel</Button>
          </Modal.RawClose>
          <Button
            customType="primary"
            onClick={onUpload}
            disabled={!merchantForm?.w_form?.document}
            isLoading={isTaskMerchantUpdating || isMerchantFormUpdating}
          >
            Upload
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};
