import { Info } from "components/icons/Info";
import dayjs from "dayjs";
import React, { useContext } from "react";
import { formatDate } from "utils/formatDate";
import { CheckoutModalPropsContext } from "./CheckoutModalPropsContext";
import { SmallInfo } from "components/icons/SmallInfo";
import { PERIOD_MAP } from "components/CartModal/CartModal";
import { BOOKKEEPING_V2 } from "constants/addons";

export const SubscriptionRenewalDetails = () => {
  const propsContext = useContext(CheckoutModalPropsContext);

  if (
    propsContext?.type !== "subscription" ||
    !propsContext.subscription.billing_cycle ||
    propsContext.subscription.subscription_type === BOOKKEEPING_V2
  ) {
    return null;
  }

  return (
    <div className="t-text-overline t-flex t-gap-1 t-items-center t-text-text-30">
      <SmallInfo />
      The subscription gets renewed on{" "}
      {formatDate(
        dayjs().add(
          1,
          PERIOD_MAP[
            propsContext.subscription.billing_cycle
          ] as dayjs.ManipulateType
        )
      )}
    </div>
  );
};
