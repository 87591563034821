import React from "react";

export const ReviewDocHeader = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M6.66667 14.6667V14C6.66667 12.8973 5.76933 12 4.66667 12H4.418C4.86 11.1647 5.33333 10.1573 5.33333 9.66667C5.33333 8.746 4.58733 8.03333 3.66667 8.03333C2.746 8.03333 2 8.746 2 9.66667C2 10.1573 2.47333 11.1647 2.91533 12H2.66667C1.564 12 0.666667 12.8973 0.666667 14V14.6667C0.298 14.6667 0 14.9647 0 15.3333C0 15.702 0.298 16 0.666667 16H6.66667C7.03533 16 7.33333 15.702 7.33333 15.3333C7.33333 14.9647 7.03533 14.6667 6.66667 14.6667ZM5.33333 14.6667H2V14C2 13.632 2.29867 13.3333 2.66667 13.3333H4.66667C5.03467 13.3333 5.33333 13.632 5.33333 14V14.6667ZM10.6667 7.33333H8C7.63133 7.33333 7.33333 7.03533 7.33333 6.66667C7.33333 6.298 7.63133 6 8 6H10.6667C11.0353 6 11.3333 6.298 11.3333 6.66667C11.3333 7.03533 11.0353 7.33333 10.6667 7.33333ZM16 3.33333V12.6667C16 14.5047 14.5047 16 12.6667 16H9.33333C8.96467 16 8.66667 15.702 8.66667 15.3333C8.66667 14.9647 8.96467 14.6667 9.33333 14.6667H12.6667C13.7693 14.6667 14.6667 13.7693 14.6667 12.6667V3.33333C14.6667 2.23067 13.7693 1.33333 12.6667 1.33333H7.33333C6.23067 1.33333 5.33333 2.23067 5.33333 3.33333V6.034C5.33333 6.40267 5.03533 6.70067 4.66667 6.70067C4.298 6.70067 4 6.40267 4 6.034V3.33333C4 1.49533 5.49533 0 7.33333 0H12.6667C14.5047 0 16 1.49533 16 3.33333ZM8.66667 11C8.66667 12.2867 9.71333 13.3333 11 13.3333C12.2867 13.3333 13.3333 12.2867 13.3333 11C13.3333 9.71333 12.2867 8.66667 11 8.66667C9.71333 8.66667 8.66667 9.71333 8.66667 11ZM12 11C12 11.5513 11.5513 12 11 12C10.4487 12 10 11.5513 10 11C10 10.4487 10.4487 10 11 10C11.5513 10 12 10.4487 12 11ZM12.6667 4C12.6667 4.36867 12.3687 4.66667 12 4.66667H8C7.63133 4.66667 7.33333 4.36867 7.33333 4C7.33333 3.63133 7.63133 3.33333 8 3.33333H12C12.3687 3.33333 12.6667 3.63133 12.6667 4Z"
      fill="#706A85"
    />
  </svg>
);
