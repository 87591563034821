import { Dayjs } from "dayjs";
import { Merchant } from "./merchant";
import { FileObject } from "./fileObject";

export const CPA_REVIEWED = "CPA_REVIEWED";
export const NOT_REQUIRED = "NOT_REQUIRED";
export const SYSTEM_REVIEWED = "SYSTEM_REVIEWED";

export type Vendor = Merchant & {
  transaction_amount: number;
  linked_transaction_count: number;
};

export type MerchantCSVResponse = {
  amount_paid_in_season: number | Dayjs;
  email: string;
  name: string;
  total_amount: number;
  id: string;
};

export type WFormResponse = {
  merchant: { name: string; uuid: string };
  uuid: string;
  company_group_id: string;
  w_form: {
    document: FileObject;
    season: string;
    type: string;
  };
};
