import React, { useState } from "react";

//components
import EmailAndPhone from "../components/forgetPassword/emailAndPhone";
import OtpVerification from "../components/forgetPassword/otpVerification";
import Password from "../components/forgetPassword/password";
import Left from "../components/landingContent";

//style
import "../static/styles/containers/signUp.css";

function ForgotPassword() {
  const [step, setStep] = useState(0);

  const [data, setData] = useState({
    email_id: "",
    phone_no: "",
    password: "",
    cnf_password: "",
    p_verify: "",
    token: "",
  });

  const handleChange = (input, event) => {
    const value = event;
    setData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  const prev = () => {
    setStep(step - 1);
  };

  const next = () => {
    setStep(step + 1);
  };

  switch (step) {
    case 0:
      return (
        <div className="parentDiv">
          <Left />
          <EmailAndPhone
            next={next}
            handleChange={handleChange}
            values={data}
          />
        </div>
      );
    case 1:
      return (
        <div className="parentDiv">
          <Left />
          <OtpVerification
            prev={prev}
            handleChange={handleChange}
            values={data}
            next={next}
          />
        </div>
      );
    case 2:
      return (
        <div className="parentDiv">
          <Left />
          <Password prev={prev} handleChange={handleChange} values={data} />
        </div>
      );
    default:
      return <div>--- UNDER MAINTENANCE ---</div>;
  }
}

export default ForgotPassword;
