import {
  ACCOUNTING_METHODS,
  QUICK_BOOKS,
  ZOHO_BOOKS,
} from "constants/bookkeeping";
import qs from "qs";
import { emptyApi } from "./emptyApi";

export type SeasonSummary = {
  start_date_year: number;
  name: string;
  season_type: string;
  bookkeeping_software: "QuickBooks" | "Zoho Books" | null;
  status: "Not Started" | "In Progress" | "Pending Review" | "Completed";
};

export type CompanyEntities = {
  uuid: string;
  name: string;
  group: {
    uuid: string;
    name: string;
  };
  subscription_name: string | null;
  assigned_bookkeeper: {
    name: string;
    profile_id: string;
  } | null;
  ledger_check: boolean;
  bookkeeping_software: typeof QUICK_BOOKS | typeof ZOHO_BOOKS | null;
  season_summaries: SeasonSummary[];
  accounting_method: keyof typeof ACCOUNTING_METHODS | null;
};

export type BooksCRMentitiesResponse = {
  company_entities: CompanyEntities[];
  current_page: number;
  per_page: number;
  total_count: number;
  total_pages: number;
};

type GetBookkeeperResponse = {
  uuid: string;
  name: string;
  email: string;
  image_url: string;
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getBookkeeper: build.query<GetBookkeeperResponse[], void>({
      query: () => {
        return {
          url: "/api/inkle/bookkeeping/get_crm_bookkeepers",
        };
      },
    }),

    getBooksCRMentities: build.query<
      BooksCRMentitiesResponse,
      { pageNum: number; searchTerm: string | null }
    >({
      query: ({ searchTerm, pageNum }) => {
        let queryUrl = qs.stringify(
          {
            search_term: searchTerm,
            page_num: pageNum,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/bookkeeping/crm_table/${queryUrl}`,
        };
      },
      providesTags: ["BooksCRM"],
    }),

    updateBooksCRMentities: build.mutation<
      CompanyEntities,
      {
        entityId: string;
        assignedBookkeeperId?: string;
        removeAssignedBookkeeper?: boolean;
        accountingMethod?: "ACCRUAL" | "CASH";
        removeAccountingMethod?: boolean;
      }
    >({
      query: ({
        entityId,
        assignedBookkeeperId,
        removeAssignedBookkeeper,
        accountingMethod,
        removeAccountingMethod,
      }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/update_crm_entity`,
          method: "PATCH",
          body: {
            assigned_bookkeeper_id: assignedBookkeeperId,
            remove_assigned_bookkeeper: removeAssignedBookkeeper,
            accounting_method: accountingMethod,
            remove_accounting_method: removeAccountingMethod,
          },
        };
      },
      invalidatesTags: ["BooksCRM"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetBooksCRMentitiesQuery,
  useGetBookkeeperQuery,
  useUpdateBooksCRMentitiesMutation,
} = extendedApi;
