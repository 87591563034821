import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import Toast from "components/DesignSystem/Toast/Toast";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { parse } from "qs";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useCreateBankTransferPaymentInterMutation } from "store/apis/bankTransfer";
import { BackendError } from "types/utils/error";
import { openLink } from "utils/openLink";
import { openBankTransferDetails } from "store/slices/bankTransfer";

export const LaunchBankTransferOrder = ({
  onConfirm,
  isOpen,
  close,
  entityId,
  bankTransferType,
  paymentFor,
  invoiceIds,
}: {
  onConfirm: () => void;
  isOpen: boolean;
  close: () => void;
  entityId: string;
  bankTransferType: string;
  paymentFor: "INVOICE" | "CART";
  invoiceIds?: string[] | null;
}) => {
  const { alertToast } = useToast();
  const [createBankTransferPayment, { isLoading }] =
    useCreateBankTransferPaymentInterMutation();
  const dispatch = useAppDispatch();
  const [showToast, setShowToast] = useState(false);
  const { link } = useConstructInternalLink();
  const location = useLocation();
  const keysToPreserve = Object.keys(
    parse(location.search, { ignoreQueryPrefix: true })
  );

  const redirectLink = link(`/settings/billing/banktransfer`, {
    keys: keysToPreserve,
    moreQuery: {},
  });

  const createPayment = async () => {
    try {
      const data = await createBankTransferPayment({
        entityId,
        bank_transfer_type: bankTransferType,
        payment_for: paymentFor,
        invoice_ids: invoiceIds,
      }).unwrap();
      dispatch(
        openBankTransferDetails({
          bankTransferOrderId: data.uuid,
          isFromLaunch: true,
          entityId,
        })
      );
      close();
      setShowToast(true);
      onConfirm();
    } catch (e) {
      alertToast({ message: (e as BackendError)?.data?.error?.message });
    }
  };

  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={close}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Launch Bank Transfer Order</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body className="t-text-body t-text-text-60">
            By continuing, we'll create an order for you. Please note that bank
            transfers have to be done by you outside of the Inkle platform to
            the account number shared next. <br />
            <br /> The actual transfer usually takes 1-5 business days to
            reflect post the actual payment. Please contact us on Chat in case
            of any queries.
          </Modal.Body>

          <Modal.FooterButtonGroup>
            <Button onClick={close}>Cancel</Button>
            <Button
              customType="primary"
              onClick={createPayment}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Launch
            </Button>
          </Modal.FooterButtonGroup>
        </Modal.Content>
      </Modal.Root>
      {showToast && (
        <div className="t-fixed t-bottom-20 t-right-0">
          <Toast.Provider>
            <Toast.Root
              open={showToast}
              customType="success"
              size="regular"
              onOpenChange={() => setShowToast(false)}
            >
              <Toast.Title>New Order Added</Toast.Title>
              <Toast.Description>
                You can track the status within the orders tab
              </Toast.Description>
              <Toast.Close onClose={() => setShowToast(false)} />
              <Toast.Action
                onAction={() => openLink(redirectLink)}
                altText="View"
              >
                View
              </Toast.Action>
            </Toast.Root>
          </Toast.Provider>
        </div>
      )}
    </>
  );
};
