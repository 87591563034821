import { DashboardLayout } from "components/DashboardLayout";
import { InvoiceHeader } from "components/design/InvoiceHeader";
import Loader from "components/design/loader";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { FormikListener } from "components/FormikListener/FormikListener";
import { DownloadIcon } from "components/icons/Download";
import { BILL_STATUS_LABEL, TAG_TYPE } from "constants/vendorBills";
import { Form, Formik } from "formik";
import { vendorBillSchema } from "formValidations/vendorBillSchema";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { useToast } from "hooks/useToast";
import { useHistory, useParams } from "react-router-dom";
import DoubleGreenTick from "static/images/DoubleGreenTick.svg";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import {
  UpdatePayload,
  useGetBillOrRequestQuery,
  useLazyDownloadBillOrRequestQuery,
  useUpdateBillOrRequestMutation,
} from "store/apis/vendorBills";
import { BackendError } from "types/utils/error";
import {
  BillDetails,
  ItemDetails,
  PaymentDetails,
  TaxAndDiscount,
  VendorAddress,
  VendorDetails,
} from "./BillConsole";
import { BillPreivew } from "./BillPreview";

type RequestBillProps = {
  close: () => void;
};

export const RequestBill = ({ close }: RequestBillProps) => {
  const { alertToast } = useToast();
  const history = useHistory();
  const { link } = useConstructInternalLink();
  const { billId } = useParams<{ billId: string }>();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { data, isLoading, isSuccess, isError, error } =
    useGetBillOrRequestQuery(
      { groupId, entityId, billId },
      {
        skip: !billId || !entityId || !groupId,
      }
    );

  usePageTitle(
    data?.bill_number ? `Request ${data?.bill_number}` : "Request Bill"
  );

  const [downloadBill, { isFetching: isDownloading }] =
    useLazyDownloadBillOrRequestQuery();
  const [getUrl] = useLazyGetPreviewUrlQuery();

  const [
    updateBillOrRequest,
    { isLoading: isUpdating, isSuccess: isUpdated, isError: isUpdateError },
  ] = useUpdateBillOrRequestMutation();

  const onChange = async (value: Partial<UpdatePayload>) => {
    try {
      await updateBillOrRequest({
        billId,
        entityId,
        groupId,
        payload: value,
      }).unwrap();
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError)?.data?.error?.message,
        },
        error as Error
      );
    }
  };

  const onDownload = async () => {
    try {
      const { doc_id } = await downloadBill({
        entityId,
        groupId,
        billId,
      }).unwrap();
      const { download_url } = await getUrl({
        fileId: doc_id,
        groupId,
      }).unwrap();
      window.open(download_url, "_blank");
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError)?.data?.error?.message,
        },
        error as Error
      );
    }
  };

  const onPreviewAndSend = () => {
    history.push(link(`/books/bills-and-vendors/send/${billId}`));
  };

  return (
    <Modal.Root open={true} modal={false}>
      <Async.Root
        isLoading={isLoading}
        isSuccess={isSuccess}
        isEmpty={false}
        isError={isError}
        customLoader={
          <Modal.Content size="fullscreen">
            <Loader />
          </Modal.Content>
        }
      >
        <Async.ErrorHandler>
          <Modal.Content size="fullscreen">
            <Modal.Body className="t-min-h-80 t-w-full t-h-full t-flex t-justify-center t-flex-col t-items-center t-gap-2 t-text-subtitle-sm t-text-red">
              <>
                {(error as BackendError)?.data?.error?.message ||
                  "Something went wrong, Please try again later."}
              </>
              <Button onClick={close} size="small">
                Go back
              </Button>
            </Modal.Body>
          </Modal.Content>
        </Async.ErrorHandler>
        <Async.Empty>
          <></>
        </Async.Empty>
        <Async.Success>
          {data && (
            <Modal.Content size="fullscreen" className="t-grid t-grid-cols-2">
              <Formik<UpdatePayload>
                initialValues={data}
                enableReinitialize={isUpdateError}
                onSubmit={onPreviewAndSend}
                validationSchema={vendorBillSchema}
                validateOnChange
              >
                {({ values, isValid, submitForm }) => {
                  return (
                    <>
                      <Form className="t-overflow-auto">
                        <FormikListener
                          debounce
                          values={values}
                          callback={onChange}
                        />
                        <DashboardLayout
                          className="t-pt-6"
                          header={
                            <InvoiceHeader
                              onBackClick={close}
                              status={
                                <Tag
                                  rounded
                                  icon={false}
                                  tagType={TAG_TYPE[data?.bill_request_status]}
                                >
                                  {BILL_STATUS_LABEL[data?.bill_request_status]}
                                </Tag>
                              }
                              title={data?.bill_number}
                            />
                          }
                        >
                          <div className="t-flex t-flex-col t-gap-12 t-pb-10">
                            <VendorDetails bill={values} onChange={onChange} />
                            <VendorAddress bill={values} onChange={onChange} />
                            <BillDetails bill={values} />
                            <ItemDetails bill={values} />
                            <TaxAndDiscount bill={values} />
                            <PaymentDetails bill={values} />
                          </div>
                        </DashboardLayout>
                      </Form>

                      <DashboardLayout
                        className="t-pt-6 t-bg-surface-lighter-grey"
                        header={
                          <div className="t-flex t-gap-3 t-justify-end !t-bg-surface-lighter-grey">
                            <Async.Root
                              isLoading={isUpdating}
                              customLoader={
                                <div className="t-flex t-items-center">
                                  <Loader customType="secondary" />
                                </div>
                              }
                              isEmpty={false}
                              isSuccess={isUpdated}
                            >
                              <Async.Empty>
                                <></>
                              </Async.Empty>
                              <Async.Success>
                                <span className="t-flex t-items-center t-gap-2 t-transition-all t-text-text-30 t-text-body-sm">
                                  <img src={DoubleGreenTick} alt="Saved" />
                                  Changes saved
                                </span>
                              </Async.Success>
                            </Async.Root>
                            <Button
                              size="small"
                              type="button"
                              onClick={onDownload}
                              disabled={isDownloading || !isValid}
                              isLoading={isDownloading}
                            >
                              <span className="t-flex t-items-center t-gap-2">
                                <DownloadIcon color="currentColor" />
                                Download
                              </span>
                            </Button>
                            <Button
                              type="submit"
                              size="small"
                              disabled={!isValid}
                              customType="primary"
                              onClick={submitForm}
                            >
                              Preview & Send
                            </Button>
                          </div>
                        }
                      >
                        <BillPreivew bill={data} />
                      </DashboardLayout>
                    </>
                  );
                }}
              </Formik>
            </Modal.Content>
          )}
        </Async.Success>
      </Async.Root>
    </Modal.Root>
  );
};
