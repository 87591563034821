export const CircleWarning = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9.75235" cy="10" r="9.30278" fill="currentColor" />
      <path
        d="M9.08327 14.1352C9.08327 14.5609 9.47361 14.9151 9.96703 14.9151C10.4605 14.9151 10.8508 14.5609 10.8508 14.1352C10.8508 13.7095 10.4605 13.3554 9.96703 13.3554C9.47361 13.3554 9.08327 13.7095 9.08327 14.1352Z"
        fill="white"
        stroke="white"
        stroke-width="0.0930278"
      />
      <path
        d="M10.0446 6.42212V11.5379"
        stroke="white"
        stroke-width="1.86056"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
