import { Button, ButtonProps } from "components/DesignSystem/Button/Button";
import { Cross } from "components/icons/Cross";
import { motion } from "framer-motion";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Table } from "react-table-8.10.7";
import {
  setMessageIdForTransactionList,
  setPopulateTransactionIDs,
} from "store/slices/chat";
import { setSelectedTransactionIds } from "store/slices/transactions";
import { Transactions, TxnAccountType } from "types/Models/books";
import { BulkAssignVendor } from "./BulkAssignVendor";
import { BulkCategorise } from "./BulkCategorise";
import { TxnFilter } from "./Filter";
import { StripeTxnFilter } from "./StripeTxnFilter";
import { UncategoriseModal } from "./UncategoriseModal";
import { useToast } from "hooks/useToast";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import {
  useChangeTransactionStatusMutation,
  useLazyGetTransactionsFilterSetQuery,
  useUpdateMultipleTransactionsDataMutation,
} from "store/apis/transactions";
import { BackendError } from "types/utils/error";
import { pluralize } from "utils/pluralize";
import { useAppSelector } from "hooks/useAppSelector";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";

type TableSelectionControlsProps = {
  txnAccountType?: TxnAccountType;
  table: Table<Transactions>;
  sendToChat: ({
    transactionIds,
  }: {
    transactionIds: string[];
  }) => Promise<void>;
  onRequestInfo: ({
    transactionIds,
  }: {
    transactionIds: string;
  }) => Promise<void>;
  deselectRows: () => void;
  unSelectedRowsIds: string[];
  isAllTxnSelected: boolean;
  selectionCount: number;
};

export const TableSelectionControls = ({
  table,
  sendToChat,
  onRequestInfo,
  deselectRows,
  txnAccountType,
  isAllTxnSelected,
  unSelectedRowsIds,
  selectionCount,
}: TableSelectionControlsProps) => {
  const { alertToast, successToast } = useToast();
  const dispatch = useDispatch();
  const entityId = useCurrentEntityId();
  const { populateTransactionIDsList } = useAppSelector((state) => state.chat);
  const { isAdmin, isCpa, isForeignCA } = useRoleBasedView();
  const { uuid: groupId } = useCurrentGroupContext();

  const {
    filters: {
      uncategorised,
      minAmount,
      maxAmount,
      excludedTransactions,
      ...restFilter
    },
  } = useAppSelector((state) => state.transactionFilter);

  const [changeTransactionStatus, { isLoading }] =
    useChangeTransactionStatusMutation();
  const [getTxnFilterSet, { isLoading: isFilterSetLoading }] =
    useLazyGetTransactionsFilterSetQuery();
  const [updateMultipleTransactionsData, { isLoading: isUpdating }] =
    useUpdateMultipleTransactionsDataMutation();

  const buttonProps = {
    size: "small",
    customType: "primary-outlined",
  } as ButtonProps;

  const transactionsSelected =
    table?.getSelectedRowModel?.()?.flatRows.length > 0;

  const transactionIds = useMemo(() => {
    return table.getSelectedRowModel().flatRows.map(
      ({
        original: {
          transaction: { uuid },
        },
      }) => uuid
    );
  }, [table.getSelectedRowModel()]);

  const bulkCategoriseModal = useModal();
  const bulkUncategoriseModal = useModal();
  const bulkAssignVendorModal = useModal();

  const onBulkTransactionsCategorise = () => {
    bulkCategoriseModal.open();
    dispatch(setSelectedTransactionIds(transactionIds));
  };

  const onBulkUncategorise = () => {
    bulkUncategoriseModal.open();
    dispatch(setSelectedTransactionIds(transactionIds));
  };

  const onBulkAssignVendor = () => {
    bulkAssignVendorModal.open();
    dispatch(setSelectedTransactionIds(transactionIds));
  };

  const resetViewTransactions = () => {
    dispatch(setPopulateTransactionIDs(""));
    dispatch(setMessageIdForTransactionList(""));
  };

  const getHashKey = async () => {
    try {
      return await getTxnFilterSet({
        groupId,
        entityId,
        uncategorized: uncategorised,
        txnStatus: excludedTransactions?.value
          ? ("EXCLUDED" as const)
          : undefined,
        minAmount: ((minAmount?.value || "") as string)
          ?.replaceAll("$", "")
          .replaceAll(",", ""),
        maxAmount: ((maxAmount?.value || "") as string)
          ?.replaceAll("$", "")
          .replaceAll(",", ""),
        filter_operation: "EXCLUDE",
        excluded_txns_list: unSelectedRowsIds,
        ...restFilter,
      }).unwrap();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const onBulkChangeStatus = async ({
    transactionStatus,
  }: {
    transactionStatus: "EXCLUDED" | "RESTORED";
  }) => {
    try {
      if (isAllTxnSelected) {
        const res = await getHashKey();
        await changeTransactionStatus({
          entityId,
          payload: {
            transaction_status: transactionStatus,
            transactions_hash_key: res?.txns_hash_key,
          },
        }).unwrap();
      } else {
        await changeTransactionStatus({
          entityId,
          payload: {
            transaction_ids: transactionIds,
            transaction_status: transactionStatus,
          },
        }).unwrap();
      }
      const pluralizedTransaction = pluralize(
        selectionCount,
        "transaction",
        "transactions"
      );

      successToast({
        message:
          transactionStatus === "EXCLUDED"
            ? `${pluralizedTransaction} excluded`
            : `${pluralizedTransaction} restored`,
      });
      dispatch(setSelectedTransactionIds([]));
      deselectRows();
    } catch (error) {
      alertToast(
        { message: (error as BackendError)?.data?.error?.message },
        error as {}
      );
    }
  };

  const onBulkConfirmCategory = async () => {
    try {
      if (isAllTxnSelected) {
        const res = await getHashKey();
        await updateMultipleTransactionsData({
          groupId,
          entityId,
          ai_category_confirmation: true,
          transactions_hash_key: res?.txns_hash_key,
          transactions: [],
        }).unwrap();
      } else {
        const transactions = table.getSelectedRowModel().flatRows?.map(
          ({
            original: {
              transaction: { uuid, category },
            },
          }) => {
            return {
              uuid,
              category_id: category?.uuid || "",
            };
          }
        );
        await updateMultipleTransactionsData({
          groupId,
          entityId,
          transactions,
          ai_category_confirmation: true,
        }).unwrap();
      }
      const pluralizedTransaction = pluralize(
        selectionCount,
        "transaction",
        "transactions"
      );
      successToast({
        message: `Confirmed categories for ${pluralizedTransaction}`,
      });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  if (!transactionsSelected) {
    return (
      <motion.span
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ ease: "easeInOut", duration: 0.2 }}
        key={transactionsSelected.toString()}
        className="t-flex t-gap-2 t-items-startt-flex-wrap"
      >
        {populateTransactionIDsList && (
          <Button
            customType="secondary"
            onClick={resetViewTransactions}
            size="small"
          >
            <div className="t-flex t-text-body-sm t-text-text-30 t-items-center t-gap-1">
              <Cross />
              {table.getRowModel().rows.length} selected
            </div>
          </Button>
        )}
        {txnAccountType === "STRIPE" ? <StripeTxnFilter /> : <TxnFilter />}
      </motion.span>
    );
  }

  return (
    <>
      <motion.span
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ ease: "easeInOut", duration: 0.2 }}
        key={transactionsSelected.toString()}
        className="t-flex t-gap-2 t-items-center t-flex-wrap t-pb-2"
      >
        {excludedTransactions?.value ? (
          <Button
            {...buttonProps}
            onClick={() =>
              onBulkChangeStatus({
                transactionStatus: "RESTORED",
              })
            }
            disabled={isLoading || isFilterSetLoading}
            isLoading={isLoading || isFilterSetLoading}
          >
            Restore
          </Button>
        ) : (
          <>
            <Button {...buttonProps} onClick={onBulkTransactionsCategorise}>
              Categorise
            </Button>
            <Button {...buttonProps} onClick={onBulkUncategorise}>
              Uncategorise
            </Button>
            {!isAllTxnSelected && (
              <>
                <Button
                  {...buttonProps}
                  onClick={() =>
                    sendToChat({
                      transactionIds,
                    })
                  }
                >
                  Send to chat
                </Button>
                {(isAdmin || isCpa || isForeignCA) && (
                  <Button
                    {...buttonProps}
                    onClick={() =>
                      onRequestInfo({
                        transactionIds: transactionIds.join(","),
                      })
                    }
                  >
                    Request info
                  </Button>
                )}
              </>
            )}

            <Button {...buttonProps} onClick={onBulkAssignVendor}>
              Assign vendor
            </Button>

            <Button
              {...buttonProps}
              onClick={() =>
                onBulkChangeStatus({
                  transactionStatus: "EXCLUDED",
                })
              }
              disabled={isLoading || isFilterSetLoading}
              isLoading={isLoading || isFilterSetLoading}
            >
              Exclude
            </Button>

            <Button
              {...buttonProps}
              onClick={onBulkConfirmCategory}
              isLoading={isUpdating}
              disabled={isUpdating}
            >
              Confirm category
            </Button>
          </>
        )}
      </motion.span>

      <BulkCategorise
        deselectRows={deselectRows}
        close={bulkCategoriseModal.close}
        isOpen={bulkCategoriseModal.isOpen}
        unSelectedRowsIds={unSelectedRowsIds}
        isAllTxnSelected={isAllTxnSelected}
      />

      {bulkUncategoriseModal.isOpen && (
        <UncategoriseModal
          deselectRows={deselectRows}
          close={bulkUncategoriseModal.close}
          isOpen={bulkUncategoriseModal.isOpen}
          unSelectedRowsIds={unSelectedRowsIds}
          isAllTxnSelected={isAllTxnSelected}
        />
      )}
      <BulkAssignVendor
        deselectRows={deselectRows}
        close={bulkAssignVendorModal.close}
        isOpen={bulkAssignVendorModal.isOpen}
        unSelectedRowsIds={unSelectedRowsIds}
        isAllTxnSelected={isAllTxnSelected}
      />
    </>
  );
};
